enum WallType {
    Undefined = 0,
    Rear = 1,
    RightEnd = 2,
    Front = 3,
    LeftCut = 4,
    FrontInsetCut = 5,
    LeftInSetCut = 6,
    FrontCut = 7,
    LeftEnd = 8
}

export default WallType;