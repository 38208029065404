import * as React from "react";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import StorageFacade from "../../services/StorageFacade";

import LoadInfoEquipmentSummary from "./LoadInfoEquipmentSummary";
import ModelInfo from "./ModelInfo";
import ModelOptions from "./ModelOptions";
import ModelDrawing from "./ModelDrawing";
import SystemsLoadSummary from "./SystemsLoadSummary";

import useErrorContext from "../../hooks/useErrorContext";
import useLoadingContext from "../../hooks/useLoadingContext";
import useProjectSystems from "../../hooks/useProjectSystems";

import "./Print.css";
import HelperService from "../../services/HelperService";

function hideElement(elementId: string){
	const element = document.getElementById(elementId);
	if (element) element.style.display = "none";
}

function showElement(elementId: string){
	const element = document.getElementById(elementId);
	if (element) element.style.display = "block";
}

const PrintMultipleReport = () => {
	
    const [exportingPageNumber, setExportingPageNumber] = React.useState(0);
    const [totalPageNumber, setTotalPageNumber] = React.useState(0);
    const [prints, setPrints] = React.useState([] as Array<any>);
    const [isFromLegacy, setIsFromLegacy] = React.useState(false);
	
	const { showError } = useErrorContext();
	const { showLoading, hideLoading } = useLoadingContext();
	const { projectSystems } = useProjectSystems();
	
	const pagesEquipment: Array<string> = [];
	const divisionId = Number(StorageFacade.divisionId);

    React.useEffect(() => {

        setIsFromLegacy(StorageFacade.isFromLegacy);

		if (projectSystems) {
			if (projectSystems.length === 0) {
				showError({
					name: "Print",
					text: "Failed to print, the equipment information is missing."
				});
				return;
			}
			
			let systemCount: number = 0;
			const projectPrints: Array<any> = [];

			projectSystems.forEach(system => {
				systemCount += 1;

                let condensingUnitModelDrawingClassName = "";
                if (systemCount == projectSystems.length && system.equipmentSelection.selectedEvaporator)
                    condensingUnitModelDrawingClassName = "printpage";
                else
                    condensingUnitModelDrawingClassName = "";

                let evapUnitModelDrawingClassName = "";
                if (systemCount == projectSystems.length)
                    evapUnitModelDrawingClassName = "";
                else
                    evapUnitModelDrawingClassName = "printpage";

				projectPrints.push(
					<div className="multiplepages" key={systemCount}>
                        <br />
                        <div className="printpage">
						    <LoadInfoEquipmentSummary 
							    sectionId={`load_design_summary${systemCount}`} 
							    divisionId={divisionId}
							    myInfo={StorageFacade.myInfo}
							    customerInfo={StorageFacade.customerInfo}
							    system={system}
						    />
                        </div>
						{system.equipmentSelection.selectedCondenserUnit && (
							<>
								<br />
                                <div className="printpage">
								    <ModelInfo 
									    sectionId={`ModelInfoCondensor${systemCount}`} 
									    divisionId={divisionId}
									    jobName={StorageFacade.jobName}
									    customerName={StorageFacade.customerInfo?.Contact ?? ""}
									    myName={StorageFacade.myInfo?.Contact ?? ""}
									    equipmentInfo={system.equipmentSelection} 
									    isCondensingUnit
									    selectedUnitItem={system.equipmentSelection.selectedCondenserUnit} 
									    selectedOptionList={system.equipmentSelection.selectedCondenserOptionList} 
                                        excludedOptionIds={null}
                                        selectedUnitAttributes={system.equipmentSelection.selectedCondenserAttributes}
                                        isPrintSingleEquipment={false}
                                        quoteFormInput={null}
                                        itemNumber={StorageFacade.CPMFormInput?.ItemNumber ?? 0}
								    />
                                </div>
								{ 
									system.equipmentSelection.selectedCondenserOptionList 
										&& system.equipmentSelection.selectedCondenserOptionList.length > 0
										&&
									(
										<>
											<br />
                                            <div className="printpage">
											    <ModelOptions 
												    sectionId={`ModelOptionsCondensor${systemCount}`} 
												    divisionId={divisionId}
												    jobName={StorageFacade.jobName}
												    customerName={StorageFacade.customerInfo?.Contact ?? ""}
												    myName={StorageFacade.myInfo?.Contact ?? ""}
												    equipmentInfo={system.equipmentSelection} 
												    isCondensingUnit
												    selectedUnitItem={system.equipmentSelection.selectedCondenserUnit} 
												    selectedOptionList={system.equipmentSelection.selectedCondenserOptionList} 
												    excludedOptionIds={null}
												    selectedUnitAttributes={system.equipmentSelection.selectedCondenserAttributes}
											    />
                                            </div>
										</>
									)
								}
								<br />

                                <div className={condensingUnitModelDrawingClassName}>
								    <ModelDrawing 
									    sectionId={`ModelDrawingCondensor${systemCount}`} 
									    divisionId={divisionId}
									    jobName={StorageFacade.jobName}
									    customerName={StorageFacade.customerInfo?.Contact ?? ""}
									    myName={StorageFacade.myInfo?.Contact ?? ""}
									    interiorTemperature={system.interiorTemperature}
									    equipmentInfo={system.equipmentSelection} 
									    isCondensingUnit
									    selectedUnitItem={system.equipmentSelection.selectedCondenserUnit} 
									    selectedOptionList={system.equipmentSelection.selectedCondenserOptionList} 
									    selectedUnitAttributes={system.equipmentSelection.selectedCondenserAttributes}
                                        isPrintSingleEquipment={false}
                                        capacityHasError={false}
                                        quoteFormInput={null}
                                        itemNumber={StorageFacade.CPMFormInput?.ItemNumber ?? 0}
                                        isForACCD={false}
								    />
                                </div>
							</>
						)}
						{system.equipmentSelection.selectedEvaporator && (
							<>
								<br />
                                <div className="printpage">
								    <ModelInfo 
									    sectionId={`ModelInfoEvap${systemCount}`} 
									    divisionId={divisionId}
									    jobName={StorageFacade.jobName}
									    customerName={StorageFacade.customerInfo?.Contact ?? ""}
									    myName={StorageFacade.myInfo?.Contact ?? ""}
									    equipmentInfo={system.equipmentSelection} 
									    isCondensingUnit={false}
									    selectedUnitItem={system.equipmentSelection.selectedEvaporator} 
                                        selectedOptionList={system.equipmentSelection.selectedEvaporatorOptionList}
                                        excludedOptionIds={null}
                                        selectedUnitAttributes={system.equipmentSelection.selectedEvaporatorAttributes}
                                        isPrintSingleEquipment={true}
                                        quoteFormInput={null}
                                        itemNumber={StorageFacade.CPMFormInput?.ItemNumber ?? 0}
								    />
                                </div>
								{
									system.equipmentSelection.selectedEvaporatorOptionList 
										&& system.equipmentSelection.selectedEvaporatorOptionList.length > 0
										&&
									(
										<>
											<br />
                                            <div className="printpage">
											    <ModelOptions 
												    sectionId={`ModelOptionsEvap${systemCount}`} 
												    divisionId={divisionId}
												    jobName={StorageFacade.jobName}
												    customerName={StorageFacade.customerInfo?.Contact ?? ""}
												    myName={StorageFacade.myInfo?.Contact ?? ""}
												    equipmentInfo={system.equipmentSelection} 
												    isCondensingUnit={false}
												    selectedUnitItem={system.equipmentSelection.selectedEvaporator} 
												    selectedOptionList={system.equipmentSelection.selectedEvaporatorOptionList} 
												    excludedOptionIds={null}
												    selectedUnitAttributes={system.equipmentSelection.selectedEvaporatorAttributes}
											    />
                                            </div>
										</>
									)
								}
								<br />
                                <div className={evapUnitModelDrawingClassName}>
								    <ModelDrawing 
									    sectionId={`ModelDrawingEvap${systemCount}`} 
									    divisionId={divisionId}
									    jobName={StorageFacade.jobName}
									    customerName={StorageFacade.customerInfo?.Contact ?? ""}
									    myName={StorageFacade.myInfo?.Contact ?? ""}
									    interiorTemperature={system.interiorTemperature}
									    equipmentInfo={system.equipmentSelection}
									    isCondensingUnit={false}
									    selectedUnitItem={system.equipmentSelection.selectedEvaporator} 
									    selectedOptionList={system.equipmentSelection.selectedEvaporatorOptionList} 
									    selectedUnitAttributes={system.equipmentSelection.selectedEvaporatorAttributes}
                                        isPrintSingleEquipment={true}
                                        capacityHasError={false}
                                        quoteFormInput={null}
                                        itemNumber={StorageFacade.CPMFormInput?.ItemNumber ?? 0}
                                        isForACCD={false}
								    />
                                </div>
							</>
						)}
					</div>
				)
			});

			setPrints(projectPrints);
		}
	}, [projectSystems])
	
	function addPDFPage(
		pdf: any, 
		currentPageIndex: number
	) {
		if (currentPageIndex >= pagesEquipment.length) {
			pdf.save("certified_print.pdf"); // Generated PDF
			
			showElement("systems-load-summary");
			pagesEquipment.forEach(pageId => {
				showElement(pageId);
			});

            setExportingPageNumber(-1);
            // setTimeout(() => { setExportingPageNumber(0); }, 8000)			
			hideLoading();

			if (document.getElementsByTagName("html")) {
				document.getElementsByTagName("html")[0].style.overflow = "auto";
			}

			return;
		}

		if (currentPageIndex === 0) {
			hideElement("systems-load-summary");
		}
		else {
			hideElement(pagesEquipment[currentPageIndex - 1]);
		}

		pdf.addPage();	
		const dataPage2: any = document.getElementById(pagesEquipment[currentPageIndex]);
		window.scrollTo(0,0);
		setExportingPageNumber(currentPageIndex + 2);
		html2canvas(dataPage2, { 
			scale: 1.25, 
			scrollX: -window.scrollX,
			scrollY: -window.scrollY,
			windowWidth: document.documentElement.offsetWidth,
			windowHeight: document.documentElement.offsetHeight
		}).then(canvas2 => {
			const imgWidth = 420;			
			const imgHeight = Math.ceil((canvas2.height * imgWidth) / canvas2.width);

			pdf.addImage(canvas2, "PNG", 12, 15, imgWidth, imgHeight, undefined, "FAST");

			addPDFPage(pdf, currentPageIndex + 1);
		});
	}

	function exportPDF() {
        if (!projectSystems || projectSystems.length === 0) {
			return;
		}
		
		showLoading();
		
		pagesEquipment.length = 0;

		let systemCount: number = 0;
		
        projectSystems.forEach(system => {
            systemCount += 1;
			pagesEquipment.push(`load_design_summary${systemCount}`);
			
			if (system.equipmentSelection.selectedCondenserUnit) {
				pagesEquipment.push(`ModelInfoCondensor${systemCount}`);
				
				if (system.equipmentSelection.selectedCondenserOptionList 
					&& system.equipmentSelection.selectedCondenserOptionList.length > 0
				) {
					pagesEquipment.push(`ModelOptionsCondensor${systemCount}`);
				}

				pagesEquipment.push(`ModelDrawingCondensor${systemCount}`);
			}

			if (system.equipmentSelection.selectedEvaporator) {
				pagesEquipment.push(`ModelInfoEvap${systemCount}`);
				
				if (system.equipmentSelection.selectedEvaporatorOptionList 
					&& system.equipmentSelection.selectedEvaporatorOptionList.length > 0
				) {
					pagesEquipment.push(`ModelOptionsEvap${systemCount}`);
				}
				
				pagesEquipment.push(`ModelDrawingEvap${systemCount}`);
			}
        });		

		setTotalPageNumber(pagesEquipment.length + 1);
		
		const dataPage1: any = document.getElementById("systems-load-summary");
		window.scrollTo(0,0);
		setExportingPageNumber(1);
		if (document.getElementsByTagName("html")) {
			document.getElementsByTagName("html")[0].style.overflow = "hidden";
		}
		html2canvas(dataPage1, { 
			scale: 1.25, 
			scrollX: -window.scrollX,
			scrollY: -window.scrollY,
			windowWidth: document.documentElement.offsetWidth,
			windowHeight: document.documentElement.offsetHeight
		}).then(canvas1 => {
			// Few necessary setting options
			const imgWidth = 420;			
            const imgHeight = Math.ceil((canvas1.height * imgWidth) / canvas1.width);

			const pdf = new jsPDF({
				unit: "px",
				compressPdf: true
			}); // A4 size page of PDF

			pdf.addImage(canvas1, "PNG", 12, 15, imgWidth, imgHeight, undefined, "FAST");

			addPDFPage(pdf, 0);	
		});
	}

	if (projectSystems === null) {
		return null;
	}

	return (
		<>
			<nav className="level hidden-for-print">
				<div className="level-left">
					<div className="level-item">
                        <button type="button" className="button is-link" onClick={() => window.print()}>Export to PDF</button>
                    </div>
                    <div className="level-item">
                        <button type="button" className="button is-link" onClick={() => { window.close(); }}>Close Preview</button>
                    </div>
					<div className="level-item" style={{fontSize:"30px"}}>
						{
							((exportingPageNumber > 0) && (
								<>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<b>{`Exporting page ${exportingPageNumber} / ${totalPageNumber} ...`}</b>
								</>
							))
                        }
                        {
                            ((exportingPageNumber < 0 && !isFromLegacy) && (
                                <>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
									<b>Please go to the Downloads folder to find your certified print.</b>
                                </>
                            ))
                        }
					</div>
				</div>
			</nav>
			<div className="printarea">
				<div id="multiSystemSummary">					
					<SystemsLoadSummary
						divisionId={+StorageFacade.divisionId}
						myInfo={StorageFacade.myInfo}
						customerInfo={StorageFacade.customerInfo}
						projectSystems={projectSystems}
					/>					
					<div style={{height: "2px"}}>&nbsp;</div>
				</div>
				<br />
				{prints}
			</div>
		</>
	);
	
};

export default PrintMultipleReport;

