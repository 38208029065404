/* eslint-disable no-underscore-dangle */
import * as React from "react";

import Button from "../helpers/Button";
import Modal from "../helpers/Modal";

import BoxType from "../../enums/BoxType";

import useTranslationContext from "../../hooks/useTranslationContext";

import "./BoxShapesModal.css";

export interface BoxShapesModalProps {
    show: boolean;
    onClose: any;
    onSelect: any;
}

const BoxShapesModal: React.FunctionComponent<BoxShapesModalProps> = (
	props: BoxShapesModalProps
) => {
    const [showShapes, setShowShapes] = React.useState(false);

    const { translations } = useTranslationContext();

    React.useEffect(() => {
        setShowShapes(props.show);
    }, [props.show]);

	const handleCloseClick = () => {
        setShowShapes(false);
        if (props.onClose) {
            props.onClose();
        }
    };

    const handleShapeButtonClick = (selectedBoxType: BoxType) => {
        setShowShapes(false);
        if (props.onSelect) {
            props.onSelect(selectedBoxType);
        }
    }

    const modalBody = (
        <div id="box-shapes-modal" className="shape-buttons">
            <div className="level button is-light shape-button" role="button" tabIndex={0} onClick={() => handleShapeButtonClick(BoxType.Rectangular)}>
                <p className="image shape-image">
                    <img src="/images/boxtypes/Rectangular.gif" alt="Rectangular-shaped room" width="85" height="55" />
                </p>
                <div className="media-content">
                    <div className="content is-small">
                        <label className="has-text-weight-bold">                                            
                            {translations.rECTANGULAR}
                        </label>
                    </div>
                </div>
            </div>
            <div className="level button is-light shape-button" role="button" tabIndex={0} onClick={() => handleShapeButtonClick(BoxType.LShape)}>
                <p className="image shape-image">
                    <img src="/images/boxtypes/LShaped.gif" alt="L-shaped Room" width="85" height="55" />
                </p>
                <div className="media-content">
                    <div className="content is-small">
                        <label className="has-text-weight-bold">                                            
                            {translations.l_SHAPED}
                        </label>
                    </div>
                </div>
            </div>
            <div className="level button is-light shape-button" role="button" tabIndex={0} onClick={() => handleShapeButtonClick(BoxType.UShape)}>
                <p className="image shape-image">
                    <img src="/images/boxtypes/UShaped.gif" alt="U-shaped Room" width="85" height="55" />
                </p>
                <div className="media-content">
                    <div className="content is-small">
                        <label className="has-text-weight-bold">                                            
                            {translations.u_SHAPED}
                        </label>
                    </div>
                </div>
            </div>
            <div className="level button is-light shape-button" role="button" tabIndex={0} onClick={() => handleShapeButtonClick(BoxType.Custom)}>
                <p className="image shape-image">
                    <img src="/images/boxtypes/SectionTable.gif" alt="Section List" width="85" height="55" />
                </p>
                <div className="media-content">
                    <div className="content is-small">
                        <label className="has-text-weight-bold">                                            
                            {translations.sECTIONLIST}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );

    const modalFooter = (
        <div className="is-fullwidth">
            <Button
                name="cancel-shapes"
                label={translations.cancel}
                className="button is-primary is-fullwidth"
                onClick={handleCloseClick}
            />
        </div>
    );

	return (
        <Modal 
            show={showShapes} 
            title={translations.selectRoomShape}
            body={modalBody}
            footer={modalFooter}
            width={320}
            onCancelClick={handleCloseClick}
        />
    );
};

export default BoxShapesModal;
