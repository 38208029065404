import * as React from "react";

import Button from "../helpers/Button";
import Modal from "../helpers/Modal";

import IOptionConfigurationSet from "../../models/interfaces/IOptionConfigurationSet";
import IOptionConfigurationVariant from "../../models/interfaces/IOptionConfigurationVariant";

import useTranslationContext from "../../hooks/useTranslationContext";

import "./OptionConfigurations.css";

export interface OptionConfigurationsProps {
	show: boolean;
	optionId: number;
	optionName: string;
	data?: IOptionConfigurationSet;
	onClose: any;
	onSelectConfiguration: any;
}

const OptionConfigurations: React.FunctionComponent<OptionConfigurationsProps> = (
	props: OptionConfigurationsProps
) => {

	const { translations } = useTranslationContext();

	const handleCloseClick = () => {
		if (props.onClose) {
			props.onClose();
		}
	};

	const handleSetConfigurationClick = (
		id: number | undefined,
		variant: IOptionConfigurationVariant
	) => {
		if (id) {
			if (props.onSelectConfiguration) {
				props.onSelectConfiguration(id, variant);
			}
		}
	};

	const modalTitle = props.data && props.data.name.trim().length > 0
		? props.data.name
		: props.optionName;

	const modalBody = (
		<>
		{props.data && (
			<div id="configuration-set" className="content">
				<div className="has-text-weight-bold">
					{props.data.variants.length === 1
						? "Normal Configuration"
						: "Available Configurations"}
				</div>
				{props.data.variants &&
					props.data.variants.map(v => {
						const options =
							v.options &&
							v.options.map(o => {
								return {
									...o,
									name:
										o.groupName +
										(o.name.trim() === "" ? "" : ` - ${o.name}`)
								};
							});
						const requiredOptions =
							options && options.filter(o => o.state === 1);
						const excludedOptions =
							options && options.filter(o => o.state === 2);
						const recommendedOptions =
							options && options.filter(o => o.state === 3);
						const notRecommendedOptions =
							options && options.filter(o => o.state === 4);
						const includedOptions =
							options && options.filter(o => o.state === 5);
						return (
							<div key={v.index}>
								<hr />
								{v.name.trim().length > 0 && (
									<>
										<div className="has-text-weight-bold">
											<span>
												{v.index + 1}
												.) &nbsp;
											</span>
											<span>{v.name}</span>
										</div>
										<div>{v.description}</div>
									</>
								)}
								{requiredOptions && requiredOptions.length > 0 && (
									<div className="variant-option">
										<div className="state-label">
											Required Options
										</div>
										<ul>
											{requiredOptions.map(r => (
												<li key={r.index}>{r.name}</li>
											))}
										</ul>
									</div>
								)}
								{excludedOptions && excludedOptions.length > 0 && (
									<div className="variant-option">
										<div className="state-label">
											Can&apos;t have Options
										</div>
										<ul>
											{excludedOptions.map(r => (
												<li key={r.index}>{r.name}</li>
											))}
										</ul>
									</div>
								)}
								{recommendedOptions && recommendedOptions.length > 0 && (
									<div className="variant-option">
										<div className="state-label">
											Recommended Options
										</div>
										<ul>
											{recommendedOptions.map(r => (
												<li key={r.index}>{r.name}</li>
											))}
										</ul>
									</div>
								)}
								{notRecommendedOptions &&
									notRecommendedOptions.length > 0 && (
										<div className="variant-option">
											<div className="state-label">
												Not Recommended Options
											</div>
											<ul>
												{notRecommendedOptions.map(r => (
													<li key={r.index}>{r.name}</li>
												))}
											</ul>
										</div>
									)}
								{includedOptions && includedOptions.length > 0 && (
									<div className="variant-option">
										<div className="state-label">
											Included Options
										</div>
										<ul>
											{includedOptions.map(r => (
												<li key={r.index}>{r.name}</li>
											))}
										</ul>
									</div>
								)}
								<Button
									name="set-config"
									label={translations.setConfiguration ?? "Set Configuration"}
									className="button is-link"
									onClick={() => {
										handleSetConfigurationClick(props.optionId, v);
									}}
								/>
							</div>
						);
					})}
			</div>
		)}
		</>
	);

	return (
		<div>
			{props.data && (
				<Modal 
					show={props.show} 
					title={modalTitle}
					body={modalBody}
					width={500}
					onCancelClick={handleCloseClick}
				/>
			)}
		</div>
	);
};

export default OptionConfigurations;
