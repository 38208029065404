enum OpeningType {
    None = "0",
    Door = "1",
    GlassDoor = "2",
    TrailerDockingDoor = "3",
    LoadingPlatformDoor = "4",
    PermanentOpening = "5",
    Window = "6",
    ExcludedArea =" 7",
    Panel = "8"
}

export default OpeningType;