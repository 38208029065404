/* eslint-disable no-underscore-dangle */
import * as React from "react";

import Button from "../helpers/Button";
import ConstructionLayersGrid from "./ConstructionLayersGrid";
import FormField from "../helpers/FormField";
import Measurement from "../helpers/Measurement";
import Modal from "../helpers/Modal";
import TextBox from "../helpers/TextBox";

import IConstructionLayer from "../../models/interfaces/IConstructionLayer";
import IMaterial from "../../models/interfaces/IMaterial";

import MeasurementType from "../../enums/MeasurementType";

import * as ConstructionLayerCalculations from "../../models/helpers/ConstructionLayerCalculations";
import UnitOfMeasure from "../../models/helpers/UnitOfMeasure";

import useApplicationConfigs from "../../hooks/useApplicationConfigs";
import useTranslationContext from "../../hooks/useTranslationContext";

import "./ConstructionModal.css";

interface ConstructionModalProps {
    show: boolean;
	onOkClick: (construction: IMaterial, isAddMode: boolean) => void;
    onCancelClick: () => void;
    constructionToEdit: IMaterial | null
}

const ConstructionModal: React.FunctionComponent<ConstructionModalProps> = (
	props: ConstructionModalProps
) => {

    const { translations } = useTranslationContext();
    const { getBoundaryConstructionLayers } = useApplicationConfigs();

    const initialConstruction: IMaterial = {
        id: 0,
        name: "",
        example: "",
        locked: false,
        showAsSectionType: true,
        showAsBuildingMaterial: false,
        kFactor: undefined,
        stdThickness: undefined,
        stdWidth: undefined,
        rValue: undefined,
        thickness: undefined,
        isConstruct: true,
        layerMaterials: getBoundaryConstructionLayers()
    };

    const constructionState = props.constructionToEdit
        ? props.constructionToEdit
        : initialConstruction;
    
    const [show, setShow] = React.useState(false);
    const [construction, setConstruction] = React.useState(constructionState);

    React.useEffect(() => {       
        setShow(props.show);
        if (props.show) {
            setConstruction(constructionState);
        }
    }, [props.show]);
    
    const handleCloseClick = () => {
        setShow(false);
        if (props.onCancelClick) {
            props.onCancelClick();
        }
    };

    const isAddMode = !props.constructionToEdit;

    const handleOkClick = () => {
        setShow(false);
        if (props.onOkClick) {
            props.onOkClick(construction, isAddMode);
        }
    };

    const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
		const fieldName = event.currentTarget.name;
        const fieldValue = event.currentTarget.value;

        setConstruction({
            ...construction,
            [fieldName]: fieldValue
        });
    };

    const handleMeasurementChange = (fieldName: string, fieldValue: any) => {
        setConstruction({
            ...construction,
            [fieldName]: fieldValue
        });
    };

    const handleConstructionLayersGridUpdated = (layers: IConstructionLayer[]) => {

        const thickness = ConstructionLayerCalculations.calculateConstructionThickness(layers);
        const rValue = ConstructionLayerCalculations.calculateConstructionRValue(layers);
        const kFactor = ConstructionLayerCalculations.calculateConstructionKFactor(thickness, rValue);

        setConstruction({
            ...construction,
            stdThickness: thickness,
            thickness,
            rValue,
            kFactor,
            layerMaterials: layers
        });
    };

    const rValuePerInch = (construction.rValue && construction.thickness)
        ? (construction.rValue / construction.thickness)
        : 0;

    const modalBody = (
        <div id="construction-modal" className="content">
            <ConstructionLayersGrid 
                layers={construction.layerMaterials ?? []}
                onGridUpdated={handleConstructionLayersGridUpdated}
            />
            <div className="block">
                <FormField label="Name">
                    <TextBox 
                        name="name"
                        value={construction.name}
                        onChange={handleInputChange}
                    />
                </FormField>
                <FormField label={`${translations.totalThickness} (${UnitOfMeasure.getSmallerDimensionUnitLabel()})`}>
                    <Measurement
                        name="thickness"
                        defaultValue={0}
                        actualValue={construction.thickness}
                        onChange={handleMeasurementChange}
                        type={MeasurementType.SmallerDimension}
                        decimals={3}
                        disabled
                    />
                </FormField>
                <FormField label={translations.compositeK_Factor}>
                    <Measurement
                        name="kFactor"
                        defaultValue={0}
                        actualValue={construction.kFactor}
                        onChange={handleMeasurementChange}
                        type={MeasurementType.PositiveDecimal}
                        decimals={3}
                        disabled
                    />
                </FormField>
                <FormField label={translations.compositeR_Value}>
                    <Measurement
                        name="rValuePerInch"
                        defaultValue={0}
                        actualValue={construction.rValue}
                        onChange={handleMeasurementChange}
                        type={MeasurementType.PositiveDecimal}
                        decimals={3}
                        disabled
                    />
                </FormField>
                <FormField label={translations.effectiveR_ValuePerUnitDepth}>
                    <Measurement
                        name="rValueEffective"
                        defaultValue={0}
                        actualValue={rValuePerInch}
                        onChange={handleMeasurementChange}
                        type={MeasurementType.PositiveDecimal}
                        decimals={3}
                        disabled
                    />
                </FormField>
            </div>
        </div>
    );

    const modalFooter = (
        <>
            <Button
                name="submit-construction"
                label={translations.oK}
                className="button is-link"
                onClick={handleOkClick}
            />
            <Button
                name="cancel-construction"
                label={translations.cancel}
                className="button is-primary"
                onClick={handleCloseClick}
            />
        </>
    );
    
    return (
        <Modal 
            show={show} 
            title={translations.compositeInsulationConstruction}
            body={modalBody}
            footer={modalFooter}
            width={800}
            onCancelClick={handleCloseClick}
        />
    );
};

export default ConstructionModal;
