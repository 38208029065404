import React from "react";

import IFormState from "../models/interfaces/IFormState";
import IConstructionLayer from "../models/interfaces/IConstructionLayer";

import useConstructionLayerForm from "./useConstructionLayerForm";

const useConstructionLayerReducer = () => {

    const constructionLayerForm = useConstructionLayerForm();

    const reducer = (state: IFormState<IConstructionLayer>, action: any) => {        
        if (action.type === "set-initial-state") {
            return constructionLayerForm.setInitialState(action.formValues);
        }
        if (action.type === "recalculate-form") {
            return constructionLayerForm.recalculateConstruction(
                state,
                { [action.fieldName]: action.fieldValue });
        }
        if (action.type === "change-value") {
            return {
                ...state,
                actualValues: {
                    ...state.actualValues,
                    [action.fieldName]: action.fieldValue
                }
            };
        }
        return state;
    };

    const [formState, dispatch] = React.useReducer(reducer, constructionLayerForm.setInitialState(undefined));

    return {
        formState,
        dispatch
    };
};

export default useConstructionLayerReducer;
