import * as React from "react";

import BoxContext from "./BoxContext";

import ConfigurationService from "../services/ConfigurationService";

import useErrorContext from "../hooks/useErrorContext";
import useLoadingContext from "../hooks/useLoadingContext";

interface BoxContextProviderProps {
	children: any;
}

const BoxContextProvider: React.FunctionComponent<BoxContextProviderProps> = ({
	children,
}: BoxContextProviderProps) => {

    const [boxId, setBoxId] = React.useState(null as number | null);
    const [boxData, setBoxData] = React.useState(null);
    const [isBoxDataLoaded, setIsBoxDataLoaded] = React.useState(false);

    const { showLoading, hideLoading } = useLoadingContext();
    const { showError } = useErrorContext();

    React.useEffect(() => {
        setIsBoxDataLoaded(false);

        if (boxId) {
            showLoading();

            try {
                ConfigurationService.getApplicationById(boxId.toString(), (data: any) => {
                    if (data) {
                        data.isBoxFreezer = data.isFreezer === "Y";
                    }

                    setBoxData(data);
                    setIsBoxDataLoaded(true);
                    hideLoading();
                });
            } catch (error: any) {
                showError({
                    name: "Error",
                    text: `An error was encountered. ${error.message ?? ""}`
                });
                hideLoading();
            }
        }
    }, [boxId]);

    const loadBox = (id: number) => {
        setBoxId(id);
    };

    const contextValue = {
        boxId,
        boxData,
        isBoxDataLoaded,
        loadBox
    };

    return (
        <BoxContext.Provider value={contextValue}>
            {children}
        </BoxContext.Provider>
    );
};

export default BoxContextProvider;