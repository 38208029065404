import * as React from "react";

import IMessage from "../../models/interfaces/IMessage";

interface FormErrorNotificationProps {
    errorMessages?: IMessage[]
}

const FormErrorNotification: React.FunctionComponent<FormErrorNotificationProps> = (
    props: FormErrorNotificationProps
) => {

    const [errorMessages, setErrorMessages] = React.useState([] as IMessage[]);

    React.useEffect(() => {
        setErrorMessages(props.errorMessages ?? []);
    }, [props.errorMessages]);

    const handleClick = () => {
        setErrorMessages([]);
    };

    return (
        <>
            { errorMessages && errorMessages.filter(e => e.text !== "").length > 0 &&
            (
                <div className="notification is-danger">
                    <button name="close" className="delete" aria-label="close" onClick={handleClick} type="button">
                        &nbsp;
                    </button>
                    {errorMessages.filter(e => e.text !== "").map(e => (
                        <div key={e.name}>
                            {e.text}
                        </div>
                    ))}
                </div>
            )}
        </>
    )
};

export default FormErrorNotification;