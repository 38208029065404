import IAirProperty from "../models/interfaces/IAirProperty";
import ICurtain from "../models/interfaces/ICurtain";
import IMaterial from "../models/interfaces/IMaterial";
import IEquipmentSelectionParameters from "../models/interfaces/IEquipmentSelectionParameters";
import UnitOfMeasure from "../models/helpers/UnitOfMeasure";
import HelperService from "./HelperService";
import { Config } from "../Constants";

class ConfigurationService {
	private static baseURL = Config.API_URL;

	public static getBoxApplications(callback: (data: any) => void) {
		const url = `${this.baseURL}/configuration/applications`;
		HelperService.callAPI(url, callback);
	}

	public static getApplicationById(id: string, callback: (data: any) => void) {
		const url = `${this.baseURL}/configuration/applications?id=${id}`;
		HelperService.callAPI(url, callback);
	}

	public static getApplicationByIdAsync = (
        boxApplicationId: number
    ) => {
        return new Promise<any>((resolve, reject) => {
            try {
                ConfigurationService.getApplicationById(boxApplicationId.toString(), (data: any) => {
                    resolve(data);
                });
            } catch (error) {
                reject(error);
            }
        });
	};

	public static getAppConstants(callback: (data: any) => void) {
		const url = `${this.baseURL}/configuration/constants`;
		HelperService.callAPI(url, callback);
	}

	public static getBoxMaterials(callback: (data: IMaterial[]) => void) {
		const url = `${this.baseURL}/configuration/materials`;
		HelperService.callAPI(url, callback);
	}

	public static getAirProperties(callback: (data: IAirProperty[]) => void) {
		const url = `${this.baseURL}/configuration/airproperties`;
		HelperService.callAPI(url, callback);
	}

	public static getCurtains(callback: (data: ICurtain[]) => void) {
		const url = `${this.baseURL}/configuration/curtains`;
		HelperService.callAPI(url, callback);
	}
	
	public static getApplicationUrlById(appId: number): string {
		// return `/images/box_applications/Application_${appId}.jpg`;
		return `https://storageaccountkeeprbb51.blob.core.windows.net/load-calculator-images/Application_${appId}.jpg`;
	}

	public static getApprovalImageByNum(approvalImgNumber: number, isLarge:boolean):string {
		let num: string = "";
		if (approvalImgNumber >= 10)
			num = approvalImgNumber.toString();
		else
			num = "0" + approvalImgNumber.toString();

		if (isLarge)
			return `/images/approvals/Approval_${num}.jpg`;
		else
			return `/images/approvals/Approval_${num}s.jpg`;
	}

	public static getModelDrawings(fileName: string):string {
		return `/images/drawings/${fileName}`;
	}

	public static getDivisionLogo(divisionId: number): string {
		//CASE WHEN @DivisionID in (1, 11) THEN c9.PartNoB WHEN @DivisionID in (2, 12) THEN c9.PartNoK WHEN @DivisionID in (3, 13) THEN c9.PartNoT END AS PN

		//Bally
		if (divisionId === 1 || divisionId == 11) return "/images/logos/LogoB.jpg";

		//KeepRite
		if (divisionId === 2 || divisionId == 12) return "/images/logos/LogoK.jpg";

		//Trendon
		if (divisionId === 3 || divisionId == 13) return "/images/logos/LogoT.jpg";

		return "";
	}

	public static getDivisionLogoAltString(divisionId: number): string {
		//CASE WHEN @DivisionID in (1, 11) THEN c9.PartNoB WHEN @DivisionID in (2, 12) THEN c9.PartNoK WHEN @DivisionID in (3, 13) THEN c9.PartNoT END AS PN

		//Bally
		if (divisionId === 1 || divisionId == 11) return "Bally";

		//KeepRite
		if (divisionId === 2 || divisionId == 12) return "KeepRite";

		//Trendon
		if (divisionId === 3 || divisionId == 13) return "Trendon";

		return "";
	}

	private static getQueryString(request: any): string {
		const queryString = Object.keys(request)
			.filter(key => request[key])
			.map(key => `${key}=${request[key]}`)
			.join("&");

		return queryString;
	}
}

export default ConfigurationService;
