import * as React from "react";

import TranslationContext from "./TranslationContext";
import TranslationService, {LocaleStrings} from "../services/translations/TranslationService";

interface TranslationContextProviderProps {
	children: any;
}

const TranslationContextProvider: React.FunctionComponent<TranslationContextProviderProps> = ({
	children,
}: TranslationContextProviderProps) => {

    const [translations] = React.useState(TranslationService.getTranslations());
    
    const contextValue = {
        translations
    };

    return (
        <TranslationContext.Provider value={contextValue}>
            {children}
        </TranslationContext.Provider>
    );
};

export default TranslationContextProvider;