import * as React from "react";

import Button from "../helpers/Button";
import Modal from "../helpers/Modal";

interface LoadCalConfirmationProps {
    show: boolean,
	onCloseClick: any,
	onLoadCalcButtonClick: any,
	onEquipmentSelectorButtonClick: any
}

const LoadCalcConfirmation: React.FunctionComponent<LoadCalConfirmationProps> = (
	props: LoadCalConfirmationProps
) => {
	const title = "New Project";
	
	const body = (
		<div className="content block-padded">
			<div>
				<Button
					name="equipment-link"
					label="I WANT TO RUN A LOAD CALCULATION AND SELECT EQUIPMENT"
					className="button is-primary is-fullwidth long-button"
					onClick={props.onLoadCalcButtonClick}
				/>
			</div>
			<div className="padtop-medium">
				<Button
					name="equipment-link"
					label="I KNOW MY LOAD ALREADY, I WANT TO SELECT EQUIPMENT"
					className="button is-primary is-fullwidth long-button"
					onClick={props.onEquipmentSelectorButtonClick}
				/>
			</div>
		</div>
	);

	return (
		<Modal 
			show={props.show} 
			title={title}
			body={body}
			onCancelClick={props.onCloseClick}
		/>
	);
};

export default LoadCalcConfirmation;