import * as React from "react";
import { TreeView } from "devextreme-react";

import Button from "../helpers/Button";
import EquipmentOptions from "../EquipmentOptions/EquipmentOptions";
import FormField from "../helpers/FormField";
import TextBox from "../helpers/TextBox";

import UnitAttributes from "./UnitAttributes";
import UnitCapacity from "./UnitCapacity";
import WarningBox from "./WarningBox";

import { measure } from "../../types/measure";

import useErrorContext from "../../hooks/useErrorContext";
import useLoadingContext from "../../hooks/useLoadingContext";
import usePrevious from "../../hooks/usePrevious";
import useTranslationContext from "../../hooks/useTranslationContext";

import IEquipmentOption from "../../models/interfaces/IEquipmentOption";
import IEquipmentUnitAttributes from "../../models/interfaces/IEquipmentUnitAttributes";
import IEquipmentUnitItem from "../../models/interfaces/IEquipmentUnitItem";
import IOption from "../../models/interfaces/IOption";

import * as EquipmentUtils from "./EquipmentUnit.utils";
import * as OptionUtils from "../EquipmentOptions/EquipmentOptions.utils";

import "./EquipmentUnit.css";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

interface EquipmentUnitProps {
    equipmentUnitKey: string;
    isPrimary: boolean | null;
    isPrimaryReloaded: boolean;
    isMatchedReloaded: boolean;
    requireAwef: boolean;
    divisionId: number;
    equipmentType: string;
    refrigerant: number;
    siteLineFrequency: number;
    interiorTemperature: measure;
    capacityMin: number;
    capacityMax: number;
    capacityRequired: measure;
    condensingUnitAmbientTemperature: measure;
    condensingTemperature: measure;
    designTd: measure;
    suctionLineLoss: measure;
    tdPlus: measure;
    tdMinus: measure;
    condenserCount: number;
    evaporatorCount: number;
    modelIdFilter: number | null;
    class9IdFilter: number | null;
    specialModels: string;
    selectedUnitItem: IEquipmentUnitItem | null;
    selectedOptions: IEquipmentOption[] | null;
    unitAttributes: IEquipmentUnitAttributes;
    onEquipmentSelected: any;
    onOptionsSelected: any;
    onAttributeChanged: any;
}

const EquipmentUnit: React.FunctionComponent<EquipmentUnitProps> = (
    props: EquipmentUnitProps
) => {
    const isMountedRef = React.useRef(null as boolean | null);

    const [unitSelections, setUnitSelections] = React.useState([] as IEquipmentUnitItem[]);

    const { showLoading, hideLoading } = useLoadingContext();
    const { showError } = useErrorContext();
    const { translations } = useTranslationContext();

    const [showOptions, setShowOptions] = React.useState(false);

    const treeViewRef = React.useRef<TreeView>(null);
    const prevProps = usePrevious<EquipmentUnitProps>(props);

    const getTreeView = () => {
        return treeViewRef.current?.instance;
    }

    const handleItemSelected = async (itemSelected: IEquipmentUnitItem) => {
        try
        {
            showLoading();

            const equipment = await EquipmentUtils.getEquipmentDetails(
                itemSelected,
                props.divisionId,
                props.interiorTemperature,
                props.condensingUnitAmbientTemperature === null
                    ? props.condensingTemperature
                    : props.condensingUnitAmbientTemperature,
                props.refrigerant,
                props.suctionLineLoss,
                props.designTd,
                "");

            let selectedOptions = [] as IOption[];
            if (equipment.class9ID) {
                const optionsData = await OptionUtils.loadOptions(
                    equipment.class9ID,
                    props.divisionId,
                    props.selectedOptions ?? [],
                    null
                );

                selectedOptions = optionsData.selected;
            }

            const selectedOptionQuantities = OptionUtils.getSelectedOptionQuantities(selectedOptions);

            props.onEquipmentSelected(equipment, selectedOptionQuantities);

            hideLoading();
        }
        catch (error: any) {
            showError({
                name: "Error",
                text: `An error was encountered while loading the equipment. ${error.message ?? ""}`
            });

            hideLoading();
        }
    };

    const populateTreeView = async (
        currentSelectedUnitItem: IEquipmentUnitItem | null,
        clearSelectedItem: boolean
    ) => {
        if (clearSelectedItem) {
            currentSelectedUnitItem = null;
        }
        
        if (props.divisionId > 0 && props.equipmentType !== "") {
            try {
                showLoading();

                const equipmentUnits = await EquipmentUtils.getEquipmentUnits(
                    props.divisionId,
                    props.requireAwef,
                    props.equipmentType,
                    props.refrigerant,
                    props.siteLineFrequency,
                    props.interiorTemperature,
                    props.designTd,
                    props.suctionLineLoss,
                    props.condensingUnitAmbientTemperature,
                    props.condensingTemperature,
                    props.capacityRequired,
                    props.capacityMin,
                    props.capacityMax,
                    props.tdPlus,
                    props.tdMinus,
                    props.condenserCount,
                    props.evaporatorCount,
                    props.modelIdFilter,
                    props.class9IdFilter,
                    props.specialModels);
                
                if (isMountedRef.current)
                {
                    setUnitSelections(equipmentUnits);
                
                    const matchingUnitItem = EquipmentUtils.findMatchingEquipment(currentSelectedUnitItem, equipmentUnits);
                    
                    if (matchingUnitItem) {
                        const treeView = getTreeView();
                        if (treeView) {
                            treeView.expandItem(matchingUnitItem.id);
                            treeView.selectItem(matchingUnitItem.id);
                        }
                        handleItemSelected(matchingUnitItem);
                    }
                    else {
                        props.onEquipmentSelected(null, null);
                    }
                }
                
                hideLoading();
            }
            catch (error: any) {
                showError({
                    name: "Error",
                    text: `An error was encountered while loading the equipment list. ${error.message ?? ""}`
                });

                if (isMountedRef.current) {
                    setUnitSelections([]);
                    props.onEquipmentSelected(null, null);
                }
                
                hideLoading();
            }
        }
    };

    React.useEffect(() => {
        isMountedRef.current = true;

        if (prevProps) {
            const clearSelectedItem =
                prevProps.isPrimary !== props.isPrimary 
                    && props.isPrimary == null;

            if (
                ((props.isPrimary == null || props.isPrimary === true) && // if this is primary, reload tree view when any input is changed
                    (prevProps.requireAwef !== props.requireAwef ||
                        prevProps.equipmentType !== props.equipmentType ||
                        prevProps.refrigerant !== props.refrigerant ||
                        prevProps.siteLineFrequency !== props.siteLineFrequency ||
                        prevProps.interiorTemperature !== props.interiorTemperature ||
                        prevProps.capacityMin !== props.capacityMin ||
                        prevProps.capacityMax !== props.capacityMax ||
                        prevProps.capacityRequired !== props.capacityRequired ||
                        prevProps.condensingUnitAmbientTemperature !== props.condensingUnitAmbientTemperature ||
                        prevProps.condensingTemperature !== props.condensingTemperature ||
                        prevProps.designTd !== props.designTd ||
                        prevProps.suctionLineLoss !== props.suctionLineLoss ||
                        prevProps.tdPlus !== props.tdPlus ||
                        prevProps.tdMinus !== props.tdMinus ||
                        prevProps.condenserCount !== props.condenserCount ||
                        prevProps.evaporatorCount !== props.evaporatorCount ||
                        prevProps.modelIdFilter !== props.modelIdFilter ||
                        prevProps.class9IdFilter !== props.class9IdFilter)) ||
                (props.isPrimary != null 
                    && props.isPrimary === false
                    && props.isPrimaryReloaded // if this is matched, only reload tree view when primary has reloaded
                )
                || clearSelectedItem // or if primary setting is cleared, reload the tree view
            ) {
                populateTreeView(props.selectedUnitItem, clearSelectedItem);
            }
        }
        else { // if first time loading
            populateTreeView(props.selectedUnitItem, false);
        }

        return () => { isMountedRef.current = false; };
    }, [
        props.isPrimary,
        props.isPrimaryReloaded,
        props.requireAwef,
        props.equipmentType,
        props.refrigerant,
        props.siteLineFrequency,
        props.interiorTemperature,
        props.capacityMin,
        props.capacityMax,
        props.capacityRequired,
        props.condensingUnitAmbientTemperature,
        props.condensingTemperature,
        props.designTd,
        props.suctionLineLoss,
        props.tdPlus,
        props.tdMinus,
        props.condenserCount,
        props.evaporatorCount,
        props.modelIdFilter,
        props.class9IdFilter,
        props.selectedUnitItem
    ]);

    const handleItemClick = (event: any) => {
        if (event.itemData.items == null) {
            const treeView = getTreeView();
            if (treeView) {
                treeView.unselectAll();
                treeView.selectItem(event.itemElement);
            }

            if (props.selectedUnitItem?.partNumber !== event.itemData.partNumber) {
                handleItemSelected(event.itemData);
            }
        }
    };

    const handleAttributeChange = (event: React.FormEvent<HTMLInputElement>) => {
		const fieldName = event.currentTarget.name;
        const fieldValue = event.currentTarget.value;

        const updatedAttributes = {
            ...props.unitAttributes,
            [fieldName]: fieldValue
        };

        if (props.onAttributeChanged) {
            props.onAttributeChanged(updatedAttributes);
        }
	};

    const handleSelectOptionsClick = () => {
        setShowOptions(true);
    };

    const handleSubmitOptionsClick = (options: IEquipmentOption[]) => {
        setShowOptions(false);
        props.onOptionsSelected(options);
    };

    const handleCancelOptionsClick = () => {
        setShowOptions(false);
    };

    return (
        <div id={props.equipmentUnitKey} className="equipment-unit">
            <div className="field">
                <div className="box">
                    <TreeView
                        ref={treeViewRef}
                        items={unitSelections}
                        onItemClick={handleItemClick}
                        focusStateEnabled={false}
                    />
                </div>
            </div>
            <FormField label={translations.partNumber}>
                <TextBox 
                    name="partNumber"
                    value={props.selectedUnitItem?.partNumber ?? ""}
                    readOnly
                />
            </FormField>
            <FormField label="">
                <Button
                    name="select-options"
                    label={translations.selectOptions}
                    className="button is-light is-fullwidth"
                    disabled={!props.selectedUnitItem}
                    onClick={handleSelectOptionsClick}
                />
            </FormField>
            <UnitAttributes
                {...props.unitAttributes}
                onAttributeChange={handleAttributeChange}
            />
            <WarningBox warnings={props.selectedUnitItem?.warnings} />
            <UnitCapacity 
                capacityPerUnit={props.selectedUnitItem?.capacityPerUnit}
                unitCount={(props.equipmentType === "Evaporators") ? props.evaporatorCount : props.condenserCount}
            />
            <EquipmentOptions
                show={showOptions}
                title={`${translations.availableOptions} ${props.selectedUnitItem?.partNumber}`}
                id={props.selectedUnitItem?.id}
                divisionId={props.divisionId}
                selectedOptionList={props.selectedOptions ?? []}
                excludedOptionIds={null}
                onOkClick={handleSubmitOptionsClick}
                onCancelClick={handleCancelOptionsClick}
            />
        </div>
    );
}

export default EquipmentUnit;