import ModelSelectionService from "../../services/ModelSelectionService";
import EquipmentByIdRequest from "../../services/requests/EquipmentByIdRequest";

import { measure } from "../../types/measure";

import { Config } from "../../Constants";

import IEquipmentUnitItem from "../../models/interfaces/IEquipmentUnitItem";

export const setPath = (
    items: IEquipmentUnitItem[], 
    parentPath: string = ""
) => {
    const treeItems = items;
    if (treeItems) {
        treeItems.forEach((item: IEquipmentUnitItem) => {
            const treeItem = item;
            const path = `${parentPath}/${treeItem.id}`;
            if (treeItem.items == null) {
                treeItem.path = path;
            }
            setPath(treeItem.items, path);
        });
    }
};

export const simplifyTreeData = (
    items: IEquipmentUnitItem[], 
    index: number = 0
) => {
    const treeItems = items;
    if (treeItems) {
        treeItems.forEach((item: IEquipmentUnitItem) => {
            const treeItem = item;
            simplifyTreeData(treeItem.items, index + 1);
            if (index > 0) {
                if (
                    treeItem.items &&
                    treeItem.items.length === 1 &&
                    treeItem.items[0].items &&
                    treeItem.items[0].items.length > 0
                ) {
                    // combine nodes with single child
                    treeItem.text =
                        treeItem.text +
                        (treeItem.text.trim() !== "" &&
                            treeItem.items[0].text.trim() !== ""
                            ? " - "
                            : "") +
                        treeItem.items[0].text;
                    treeItem.items = treeItem.items[0].items;
                }
            }
        });
    }
};

export const fixTreeData = (
    items: IEquipmentUnitItem[], 
    index: number = 0
) => {
    const treeItems = items;
    if (treeItems) {
        treeItems.forEach((item: IEquipmentUnitItem) => {
            const treeItem = item;
            if (treeItem.text.toUpperCase() === "N.A.") {
                treeItem.text = ""; // hide N.A.
            }
            if (treeItem.items != null) {
                treeItem.id = `${index.toString()}_${treeItem.id}`; // make sure the ids are unique
            }
            treeItem.expanded = index === 0; // expand the first node
            fixTreeData(treeItem.items, index + 1);
        });
    }
};

export const findEquipment = (
    item: IEquipmentUnitItem, 
    idToFind: string
): IEquipmentUnitItem | null => {
    if (item == null) return null;

    if (item.id.toString() === idToFind) {
        return item;
    }

    if (!item.items) {
        return null;
    }
    
    for (let i = 0; i < item.items.length; i += 1) {
        let result: IEquipmentUnitItem | null = null;
        result = findEquipment(item.items[i], idToFind);
        if (result) {
            return result;
        }
    }

    return null;
};

export const getEquipmentUnits = async (
    divisionId: number,
    requireAwef: boolean,
    equipmentType: string,
    refrigerant: number,
    siteLineFrequency: number,
    interiorTemperature: measure,
    designTd: measure,
    suctionLineLoss: measure,
    condensingUnitAmbientTemperature: measure,
    condensingTemperature: measure,
    capacityRequired: measure,
    capacityMin: number,
    capacityMax: number,
    tdPlus: measure,
    tdMinus: measure,
    condenserCount: number,
    evaporatorCount: number,
    modelIdFilter: number | null,
    class9IdFilter: number | null,
    specialModels: string
) => {
    
    const getUrl = `${Config.API_URL}/equipment`;
    const getParams: any = {
        RequireAwef: requireAwef ? 1: 0,
        EqType: equipmentType,
        Herz: siteLineFrequency,
        Fluid: refrigerant,
        DivID: divisionId,
        InTemp: interiorTemperature,
        TD: designTd,
        SucLoss: suctionLineLoss,
        AmbTemp: condensingUnitAmbientTemperature,
        ConTemp: condensingTemperature,
        Cap: capacityRequired ?? 0,
        CapMin: capacityMin,
        CapMax: capacityMax,
        TDPlus: tdPlus,
        TDMinus: tdMinus,
        NoCon: condenserCount,
        NoEvap: evaporatorCount,
        modid: modelIdFilter,
        c9id: class9IdFilter,
        spMods: specialModels
    };

    const queryString = Object.keys(getParams)
        .filter(key => getParams[key] !== null && getParams[key] !== undefined)
        .map(key => `${key}=${getParams[key]}`)
        .join("&");
    
    const response = await fetch(`${getUrl}?${queryString}`);
    const responseText = await response.text();

    if (responseText && responseText.length > 0){
        const data = JSON.parse(responseText);
        const equipmentUnits: IEquipmentUnitItem[] = data.items;

        fixTreeData(equipmentUnits);
        simplifyTreeData(equipmentUnits);
        setPath(equipmentUnits);

        return equipmentUnits;
    }

    return [] as IEquipmentUnitItem[];
};

export const getEquipmentDetails = async (
    equipmentUnit: IEquipmentUnitItem,
    divisionId: number,
    interiorTemperature: measure,
    ambientTemperature: measure,
    refrigerantFluidId: number,
    suctionLineLoss: measure,
    designTd: measure,
    optionIds: string // todo: pass option ids
) => {
    const request: EquipmentByIdRequest = {
        ambientTemp: ambientTemperature,
        divisionId,
        interiorTemp: interiorTemperature,
        refrigerantFluidId,
        suctionLineLoss,
        td: designTd,
        selectedOptions: optionIds
    };

    const equipmentDetails = await ModelSelectionService.getEquipmentByIdAsync(
        equipmentUnit.id,
        request);

    equipmentUnit = {
        ...equipmentUnit,
        capacityPerUnit: equipmentDetails.capacityPerUnit,
        description: equipmentDetails.description,
        features: equipmentDetails.features,
        requiredOptions: equipmentDetails.requiredOptions,
        class0ID: equipmentDetails.class0ID,
        class8ID: equipmentDetails.class8ID,
        class9ID: equipmentDetails.class9ID,
        awefCompliant: equipmentDetails.awefCompliant,
        warnings: [...equipmentDetails.warnings ?? []]
    }

    return equipmentUnit;
}

export const findMatchingEquipment = (
    currentSelectedUnitItem: IEquipmentUnitItem | null,
    equipmentSelections: IEquipmentUnitItem[]
) => {
    let matchingEquipment = null as IEquipmentUnitItem | null;
        
    if (currentSelectedUnitItem != null
        && equipmentSelections
        && equipmentSelections.length > 0
    ) {
        const rootItem = equipmentSelections[0];
        const unitItem = findEquipment(rootItem, currentSelectedUnitItem.id.toString());
        if (unitItem) {
            matchingEquipment = currentSelectedUnitItem;
        } else {
            const pathIds = currentSelectedUnitItem.path.split("/");
            if (pathIds) {
                let matchingNode: any = null;

                pathIds
                    .filter(a => a !== "")
                    .forEach(nodeId => {
                        const nodeItem = findEquipment(rootItem, nodeId);
                        if (nodeItem) {
                            matchingNode = nodeItem;
                        }
                    });

                if (matchingNode) {
                    while (matchingNode.items 
                        && matchingNode.items.length > 0
                    ) {
                        let matchingItem = matchingNode.items[0]; // set first node as last selected
                        if (matchingNode.items[0].items == null) {
                            // if that first node has no children
                            for (let i = 0; i < matchingNode.items.length; i += 1) {
                                // check if there's a better match in the current node
                                if (matchingNode.items[i].text === currentSelectedUnitItem.text) {
                                    matchingItem = matchingNode.items[i];
                                    break;
                                }
                            }
                        }

                        matchingNode = matchingItem;
                    }

                    matchingEquipment = matchingNode;
                }
            }
        }
    }
        
    return matchingEquipment;
};