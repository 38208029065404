import * as React from "react";

import LoadingContext from "../context/LoadingContext";

const useLoadingContext = () => {
    const { loadingCount, showLoading, hideLoading } = React.useContext(LoadingContext);
    return { loadingCount, showLoading, hideLoading };
};

export default useLoadingContext;
