import * as React from "react";

import SystemContext from "../context/SystemContext";

const useSystemContext = () => {

    const {
        systemState,
        dispatch
    } = React.useContext(SystemContext);

    return {
        systemState,
        dispatch
     };
};

export default useSystemContext;
