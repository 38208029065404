/* eslint-disable no-underscore-dangle */
import * as React from "react";
import { useHistory } from "react-router-dom";

import IMessage from "../../models/interfaces/IMessage";

import BoxLocation from "../../enums/BoxLocation";
import BoxType from "../../enums/BoxType";
import FloorType from "../../enums/FloorType";

import BoxShapesModal from "../WallLoad/BoxShapesModal";
import ChangeBoxApplication from "../BoxApplications/ChangeBoxApplication";
import LoadCalculatorComponent from "./LoadCalculatorComponent";
import StorageFacade from "../../services/StorageFacade";

import LoadCalculationService from "../../services/LoadCalculationService";

import useBoxContext from "../../hooks/useBoxContext";
import useErrorContext from "../../hooks/useErrorContext";
import useLoadingContext from "../../hooks/useLoadingContext";
import useSystemContext from "../../hooks/useSystemContext";
import useSystemState from "../../hooks/useSystemState";
import useTranslationContext from "../../hooks/useTranslationContext";

interface LoadCalculatorProps {}

const LoadCalculator: React.FunctionComponent<LoadCalculatorProps> = () => {

	const history = useHistory();

	const [showShapes, setShowShapes] = React.useState(false);
	const [showChangeBoxApplicationModal, setShowChangeBoxApplicationModal] = React.useState(false);

	const { loadBox, boxData } = useBoxContext();
	const { systemState, dispatch } = useSystemContext();

	const { showError, showErrorList, errorMessages } = useErrorContext();
	const { showLoading, hideLoading } = useLoadingContext();
	const { translations } = useTranslationContext();

	const { validateLoadCalc } = useSystemState();

	const showErrors = (
        errors: IMessage[],
        currentFieldError?: IMessage
    ) => {
        let newList = [] as IMessage[];
        
        const currentList = [...errorMessages];
        currentList.forEach(c => {
            const errorText = errors.find(e => e.name === c.name)?.text ?? "";
            newList.push({
                name: c.name,
                text: errorText
            });
        });

        if (currentFieldError) {
            if (currentList.find(c => c.name === currentFieldError.name)) {
                newList = newList.map(n => {
                    return {
                        name: n.name,
                        text: (n.name === currentFieldError.name) ? currentFieldError.text : n.text
                    }
                });
            }
            else {
                newList.push(currentFieldError);
            }
        }
    
        showErrorList(newList, false);
    };

    const validateField = (
        fieldName: string,
        fieldValue: any
        ) => {

        const system = {
            ...systemState.formState.actualValues,
            [fieldName]: fieldValue
        };

        const errors = validateLoadCalc(system);

        const fieldError: IMessage = {
            name: fieldName,
            text: errors.find(e => e.name === fieldName)?.text ?? ""
        };
        
        showErrors(errors, fieldError);
        
        return fieldError.text;
    };

	React.useEffect(() => {

        const getLoads = async () => {
            const loads = await LoadCalculationService.fetchCalculatedLoadsAsync(
                systemState.formState.actualValues.boxApplicationId ?? 0,
                systemState.formState.actualValues.boxDesign.floorArea,
                systemState.formState.actualValues.boxDesign.volume,
                systemState.formState.actualValues.boxDesign.totalInfiltrationLoad,
                systemState.formState.actualValues.exteriorTemperature,
				systemState.formState.actualValues.exteriorHumidity,
				systemState.formState.actualValues.interiorTemperature,
				systemState.formState.actualValues.interiorHumidity,
				systemState.store.miscLoad,
                systemState.store.infiltrationLoad,
                systemState.store.productLoad);

			const {totalMiscLoad, totalInfiltrationLoad, totalProductLoad, miscLoadItems, infiltrationLoadItems, productLoadItems} = loads;

            dispatch({ 
				type: "recalculate-loads", 
                totalMiscLoad: totalMiscLoad > 0 ? totalMiscLoad : undefined, 
                totalInfiltrationLoad: totalInfiltrationLoad > 0 ? totalInfiltrationLoad : undefined, 
				totalProductLoad: totalProductLoad > 0 ? totalProductLoad : undefined,
				miscLoadItems,
				productLoadItems,
				infiltrationLoadItems,
				updateStore: true
            });

            hideLoading();
        };

		if (systemState.formState.actualValues.boxApplicationId
			&& systemState.formState.actualValues.boxApplicationId > 0
		) {
            showLoading();
            getLoads();
        }
	}, [
		systemState.formState.actualValues.boxApplicationId,
		systemState.formState.actualValues.boxDesign.floorArea,
		systemState.formState.actualValues.boxDesign.volume,
		systemState.formState.actualValues.boxDesign.totalInfiltrationLoad,
		systemState.formState.actualValues.exteriorTemperature,
		systemState.formState.actualValues.exteriorHumidity,
		systemState.formState.actualValues.interiorTemperature,
		systemState.formState.actualValues.interiorHumidity,
		systemState.store.miscLoad,
		systemState.store.infiltrationLoad,
		systemState.store.productLoad
    ]);

	const showWallLoad = () => {
		history.push("/wallload");
	};

	const handleBoxApplicationChange = (
		boxApplicationId: number
	) => {
		setShowChangeBoxApplicationModal(false);

		if (boxApplicationId > -1) {
			dispatch({ 
				type: "change-value", 
				fieldName: "boxApplicationId", 
				fieldValue: boxApplicationId,
				updateStore: true
			});
			loadBox(boxApplicationId);
		}
	};

	const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
		const fieldName = event.currentTarget.name;
		const fieldValue = event.currentTarget.value;

		dispatch({ 
			type: "change-value", 
			fieldName, 
			fieldValue,
			updateStore: true
		});

		validateField(fieldName, fieldValue);
	};

	const handleMeasurementChange = (fieldName: string, fieldValue: any) => {
		dispatch({ 
			type: "customize-property", 
			fieldName, 
			fieldValue,
			updateStore: true
		});
	};

	const handleBoxLocationChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (event.target.checked) {
			const boxLocation = event.target.value as BoxLocation;
			dispatch({ 
				type: "change-value", 
				fieldName: "boxLocation", 
				fieldValue: boxLocation,
				updateStore: true
			});

			validateField("boxLocation", boxLocation);
		}
    };

	const handleFloorTypeChange = (value: FloorType) => {
		if (value === FloorType.None) {
			value = systemState.formState.defaultValues.floorType ?? FloorType.OnGrade;
		}
		dispatch({ 
			type: "change-value", 
			fieldName: "floorType", 
			fieldValue: value,
			updateStore: true
		});

		validateField("floorType", value);
	};

	const handleBoxTypeSelect = (boxType: BoxType) => { 
		dispatch({ 
			type: "reset-boxType", 
			boxType,
			updateStore: true
		});

		validateField("boxType", boxType);
	};

	React.useEffect(() => {
		// Make sure boxType in system state has been updated before showing wall load.
		if (showShapes && systemState.formState.actualValues.boxType !== BoxType.Undefined) {
			setShowShapes(false); 
			showWallLoad();
		}

		if (systemState.formState.actualValues.boxType !== null 
			&& systemState.formState.actualValues.boxType !== BoxType.Undefined
		) {
			showError({
				name: "coolingLoad",
				text: ""
			});
		}
	}, [systemState.formState.actualValues.boxType]);

	const handleChangeBoxApplicationButtonClick = () => {
		setShowChangeBoxApplicationModal(true);
	};

	const handleWallLoadButtonClick = () => {
		if (systemState.formState.actualValues.boxType === BoxType.Undefined) {
			setShowShapes(true);
		}
		else {
			showWallLoad();
		}
	};

	const handleCoolingLoadButtonClick = (
		redirectPath: string
	) => {
		if (systemState.formState.actualValues.boxType === BoxType.Undefined) {
			showError({
				name: "coolingLoad",
				text: "Please define the room dimensions before proceeding."
			});
		}
		else {
			window.location.href = redirectPath;
		}
	};

	const handleSaveButtonClick = () => {
		if (systemState.store.isEditing) {
			window.location.href = "/project/edit";
		}
		else {
			window.location.href = "/project/add";
		}
	};

	const handlePrintPreviewButtonClick = () => {		
        StorageFacade.transferLoadCalcSystemFromSessionToLocal();
        StorageFacade.transferProjectFromSessionToLocal();

        window.open("/printequipment", '_blank', 'height=' + window.screen.height + ', width=' + window.screen.width);
	};

	const handleCancelButtonClick = () => {
		window.location.href = "/project/cancel";
	};

	if (systemState.formState.actualValues.boxApplicationId) {
		return (
			<>
				<LoadCalculatorComponent 
					isBoxFreezer={boxData?.isBoxFreezer}
					formState={systemState.formState}
					onInputChange={handleInputChange}
					onMeasurementChange={handleMeasurementChange}
					onFloorTypeChange={handleFloorTypeChange}
					onBoxLocationChange={handleBoxLocationChange}
					onWallLoadButtonClick={handleWallLoadButtonClick}
					onCoolingLoadButtonClick={handleCoolingLoadButtonClick}
					onChangeBoxApplicationButtonClick={handleChangeBoxApplicationButtonClick}
					onValidate={validateField}
					onSaveButtonClick={handleSaveButtonClick}
					onCancelButtonClick={handleCancelButtonClick}
					onPrintPreviewButtonClick={handlePrintPreviewButtonClick}
				/>
				<ChangeBoxApplication 
					show={showChangeBoxApplicationModal}
					onOkClick={handleBoxApplicationChange}
					onCancelClick={() => setShowChangeBoxApplicationModal(false)}
				/>
				<BoxShapesModal
					show={showShapes}
					onSelect={handleBoxTypeSelect}
					onClose={() => { setShowShapes(false); }}
				/>
			</>
		);
	}

	return null;
};

export default LoadCalculator;
