import { enTooltips } from "./en-tooltips"; // TODO: Change later when translations are available.

export const esStrings = {
	...enTooltips,
	_Feeds: "# Se alimenta",
	_threeDollarSigns: "$$$",
	_Cancel: "&Cancelar",
	_Feed: "/Alimentar",
	_RDPARTY: "3RD PARTIDO",
	_PreviousModel: "< Modelo anterior",
	_SelectPreviousModel: "< Seleccione el modelo anterior",
	_TD: "<TD",
	a_ZDescription: "A - Descripción Z",
	a_ZName: "A - Z Nombre",
	aConfidentialErrorReportWillBeSentToTheSoftwareDevelopmentTeam_:
		"Un informe confidencial de error se enviará al equipo de desarrollo de software.",
	aConstructionTypeNameIsNotEntered_:
		"No se introduce un nombre de tipo de construcción 1",
	aCriticalErrorOccurredInTheApplication_:
		"Un error crítico se produjo en la aplicación!",
	aDescriptionHasNotBeenEntered_: "No ha entrado una descripción!",
	aProblemOccurredWhileRegisteringYourNewPassword_:
		"Se produjo un problema al registrar la nueva contraseña!",
	aProblemOccurredWhileRegisteringYourNewUserInformation_:
		"Se produjo un problema al registrar la información de usuario nuevo.",
	aProgramOrDataUpdateIsAvailable_:
		"Hay disponible una actualización de programa o datos.",
	aProgramUpdateIsAvailable_: "Una actualización del programa está disponible.",
	aPurchaseOrderNumberIsRequired_:
		"Un número de orden de compra que se necesita!",
	aSurchargeWillApply_: "Un recargo se aplicará.",
	a_C_L_: "ACL",
	a_C_T_: "ACT",
	a_T_D_: "ATD",
	aCT: "ACTUAR",
	aIRCOOLEDCONDENSOR: "CONDENSADOR REFRIGERADO POR AIRE",
	aIRFLOW: "FLUJO DE AIRE",
	aLL: "TODOS",
	aLLAPPLICABLETAXESEXTRA: "TODOS LOS IMPUESTOS ADICIONALES APLICABLES",
	aLLCLASSES: "TODAS LAS CLASES",
	aLLMODELS: "TODOS LOS MODELOS",
	aLTITUDE: "ALTITUD",
	aLTITUDEFACTOR: "FACTOR DE ALTITUD",
	aMBIENT: "AMBIENTE",
	aMPS: "Amperios",
	aNY: "CUALQUIER",
	aPPLICATIONFAILURE: "SOLICITUD DE FALLA",
	aT: "EN",
	aTHR: "ATHR",
	aTTENTION: "ATENCIÓN",
	abort: "Abortar",
	aborted_NoUPDATESFolderFound_:
		"Anulado, no encontró ninguna carpeta de actualizaciones.",
	about: "Acerca de",
	aboutNROES: "Acerca de NROES",
	aboutThisApplication: "Acerca de esta aplicación",
	active: "Activo",
	activeOrderManager: "Active Manager Orden",
	activeOrders: "Active órdenes",
	activePurchaseOrderManager: "Orden de Compra de Active Manager",
	activeRecordsShown: "Se muestran los registros activos",
	activity: "Actividad",
	actual: "Real",
	actualAir_T: "Real ΔT de aire",
	actualCondensingTemperature: "Temperatura de condensación real",
	actualFluidFlowRate: "Tasa de flujo fluido real",
	actualFluidOutletTemperature: "Temperatura de salida de fluido real",
	actualFluidTD: "Fluido real TD",
	actualFluid_T: "Real ΔT fluido",
	actualTDCanNotBeGreaterThan: "TD real no puede ser superior a",
	actualTDCanNotBeLessThan: "TD real no puede ser inferior a",
	actualTDMustBeGreaterThanZero_: "TD real debe ser mayor que cero!",
	add: "Añadir",
	add_EditOptions: "Agregar / Editar Opciones",
	addBlankQuoteRevision: "Añadir Revisión Cita en blanco",
	addCatalogueModel: "Añadir Modelo Catálogo",
	addConnection: "Agregar conexión",
	addCustomSection: "Agregar sección personalizada",
	addFeed: "Añadir Feed",
	addLoad: "Añadir carga",
	addModel: "Agregar Modelo",
	addModelPriceAdjustment: "Añadir Ajuste Modelo Precio",
	addMoreConnections: "Añadir más conexiones",
	addNon_CatalogueItem: "Añadir artículo no Catálogo",
	addNon_CatalogueOption: "Añadir opción para no Catálogo",
	addParts: "Agregar elementos",
	addProduct: "Añadir producto",
	addSectionAndStartAnotherNewOne_Insert_:
		"Agregar sección y empezar otro nuevo uno [Insert]",
	addSelectedPartTypesToOrder:
		"Agregar seleccionados los tipos de pieza para ordenar",
	addServiceParts: "Agregar elementos de servicio",
	addSystem: "Añadir sistema",
	addSystemFreightAllowance: "Añadir Sistema de Asignación de Carga",
	addSystemOption: "Añadir la opción del sistema",
	addToOrder: "Agregar a la Orden",
	addWall: "Añadir pared",
	addANewSystem_: "Añadir un nuevo sistema.",
	addAsNewItems_: "Añadir como nuevos elementos.",
	addBlankQuoteRevision_: "Agregar revisión citan en blanco.",
	addClipboardSystemItemsToCurrentSystem_:
		"Agregar elementos portapapeles del sistema con el sistema actual.",
	addContactToYourDatabase_: "Añadir contacto a la base de datos.",
	addNewQuoteRevision: "Añadir la revisión nueva cotización",
	addOrEditOptions: "Agregar o Editar Opciones",
	additionalE_MailText: "Texto adicional E-Mail",
	additionalInstructions_Notes: "Instrucciones adicionales / Notas",
	additionalOrderingInstructions: "Instrucciones adicionales pedidos",
	address: "Dirección",
	adjustAllMultipliers: "Ajustar todos los multiplicadores",
	adjustOrderPrice: "Ajuste de precios Orden",
	adjustPrice: "Ajuste de precios",
	adjustM: "Reg",
	adjustedPrice: "Precio ajustado",
	adjustment: "Ajuste",
	again: "Una vez más",
	airCooledCondenserDesignTools:
		"Refrigerado por aire del condensador Herramientas de diseño",
	airCooledCondenserDesigner_FitAModel:
		"Refrigerado por aire de diseño del condensador: ajustar un modelo",
	airCooledCondenserDesigner_FitAJob:
		"Condensador refrigerado por aire de diseño: En forma de un trabajo",
	airCooledCondenserModelSelector:
		"Selector de modelo de condensador refrigerado por aire",
	airCooledCondensingUnit: "Unidad condensadora refrigerado por aire",
	airFlow: "Flujo de Aire",
	airFlowDirection: "Dirección del flujo de aire",
	airIn: "Aire en",
	airIn_DB_: "Aire en (DB)",
	airIn_WB_: "Aire (WB)",
	airInletTemperature: "Temperatura de entrada de aire",
	airInletTemperature_DB_: "Temperatura de entrada de aire (DB)",
	airInletTemperature_WB_: "Temperatura de entrada de aire (WB)",
	airInletTemperature_Dry: "Temperatura de entrada de aire - seco",
	airInletTemperature_Wet: "Temperatura de entrada de aire - mojado",
	airInletTemperatureExceedsFanRating_:
		"Temperatura de entrada de aire excede la clasificación de ventilador!",
	airOut: "Aire hacia fuera",
	airOutletTemperature: "Temperatura de salida de aire",
	airOutletTemperatureExceedsFanRating_:
		"Temperatura de salida de aire excede la clasificación de ventilador!",
	airOutletTemperatureLessThanWetBulbTemperature_:
		"Temperatura de salida de aire menos de temperatura de bulbo húmedo!",
	airflow: "Flujo de aire",
	allImperial: "Todos Imperial",
	allMetric_SI_: "Todas las métricas (SI)",
	allOrders: "Todas las órdenes",
	allCertifiedPrintsHaveBeenSigned:
		"Todas las copias certificadas se han firmado",
	allListedOrdersWereMarkedAsClosed_:
		"Todos los pedidos que figuran se han marcado como cerrado.",
	allListedOrdersWereMarkedAsShipped_:
		"Todos los pedidos que figuran fueron marcados de fábrica.",
	allOrderItemsWillBeShippedAtTheLongestLeadTime_:
		"Todos los artículos de la orden será enviada en el momento más largo de construcción.",
	allParametersChecked_: "Todos los parámetros de marcado.",
	allPricesWereUpToDate_: "Todos los precios estaban al día!",
	allSelectedPartsAlreadyExistOnTheOrder_:
		"Todas las piezas seleccionadas ya existen en el orden.",
	allSystemDesignTemperaturesHaveBeenChecked:
		"Todas las temperaturas de diseño de sistemas se han comprobado",
	altitude: "Altitud",
	altitudeFactor: "Altitud Factor",
	altitudeIsNotSet_: "No se ha establecido la altitud!",
	aluminum: "Aluminio",
	ambient: "Ambiente",
	ambientTemp: "Temperatura ambiente",
	ambientTemperature_BoxTemperature_:
		"Temperatura ambiente < cuadro temperatura!",
	ambientTeperatureMustBeGraterThan: "Teperature ambiente debe ser mayor que",
	ambientTeperatureMustBeLessThan: "Teperature ambiente debe ser menos de",
	amount: "Cantidad",
	amountPer: "Cantidad por",
	anApplicationUpdateIsAvailable_:
		"Hay disponible una actualización de la aplicación!",
	anAuthorizationRequestHasBeenSentToTheRegistrar_:
		"Se ha enviado una solicitud de autorización al registrador.",
	anErrorOccurredRunningTheJob_ScopeTranslation_:
		"Se produjo un error de ejecutar el trabajo de traducción-Scope!",
	anIssueOccuredTryingToApplyForRegistration_:
		"Un problema ha producido al intentar solicitar el registro.",
	anIssueOccurredWhileInstallingDataUpdates_:
		"Un problema al instalar actualizaciones de datos.",
	anUnexpectedErrorOccured_: "Error inesperado!",
	anUnexpectedErrorOccurred_: "Error inesperado!",
	anUnexpectedErrorOcurred_: "Error inesperado!",
	anUpdatedPurchaseOrderIsAvailableOnRequest:
		"Una orden de compra actualizado está disponible bajo petición",
	applicationRegistrationCodeRequested_:
		"Aplicación código de registro solicitado.",
	applicationTitle: "Título de la aplicación",
	applicationDataFileHaveNotBeenDownloaded_:
		"Solicitud de archivo de datos no se han descargado!",
	applicationMustBeOn_line_: "La solicitud debe estar en línea.",
	applicationWillExpireAfter__Days_: "Aplicación expirará después de 30 días.",
	applicationWillNowClose_: "Aplicación se cerrará.",
	archivedRecordsShown: "Se muestran los registros archivados.",
	areYouARegisteredUser_: "Si eres un usuario registrado?",
	areYouSureThisIsTheRightNumber_:
		"¿Estás seguro de que este es el número correcto?",
	areYouSureYouWantToCreateABrandNewOrder_:
		"¿Está seguro que desea crear una marca nueva orden.",
	areYouSureYouWantToDeleteTheSelectedItems_:
		"¿Está seguro que desea eliminar los elementos seleccionados?",
	areYouSureYouWantToDeleteTheSelectedSystem_:
		"¿Está seguro que desea eliminar el sistema seleccionado?",
	areYouSureYouWantToDeleteThisChangeOrder_:
		"¿Estás seguro que quieres eliminar este orden de cambio?",
	areYouSureYouWantToDeleteThisContact_:
		"¿Está seguro que desea eliminar este contacto?",
	areYouSureYouWantToDeleteThisQuoteRevision_:
		"¿Está seguro que desea eliminar esta revisión de presupuesto?",
	areYouSureYouWantToDoThis_: "¿Estás seguro que quieres hacer esto.",
	areYouSureYouWantToOpenTheServerVersion_:
		"¿Está seguro que desea abrir la versión del servidor?",
	areYouSureYouWantToPlaceThisOrderOnHold_:
		"¿Seguro que quieres que este pedido en espera?",
	areYouSureYouWantToRejectThisOrder_:
		"¿Estás seguro de que desea rechazar esta orden?",
	areYouSureYouWantToSetThisValue_:
		"¿Está seguro que desea establecer este valor?",
	areYouSure_: "¿Estás seguro?",
	area: "Área",
	areaIsNotCalculatedOrEntered_: "Área no es calculado ni entró!",
	at: "En",
	atLeastOneConnectionErrorExists_: "Error al menos una conexión existe!",
	atLeastOneConnectionWarningExists_: "Existe al menos un aviso de conexión!",
	atLeastOneDoorHasAnEntryOrCalculationError_:
		"Al menos una puerta tiene un error de entrada o cálculo!",
	atLeastOneDoorHasAnEntryOrCalculationWarning_:
		"Al menos una puerta tiene una entrada o cálculo ADVERTENCIA!",
	atLeastOneLayerErrorExists_: "Error al menos una capa existe!",
	atLeastOneLayerWarningExists_: "Existe al menos un aviso de la capa!",
	atLeastOneListedProductHasAWarning_:
		"Por lo menos un producto indicado tiene una advertencia!",
	atLeastOneListedProductHasAnError_:
		"Por lo menos un producto indicado tiene un error!",
	atLeastOneOtherLoadHasAWarning_:
		"Al menos una otra carga tiene una advertencia! ",
	atLeastOneOtherLoadHasAnError_: "Al menos una otra carga tiene un error! ",
	atLeastOneRequestedDateIsSetToPriorToThe:
		"Al menos una fecha solicitada se configura como antes para el",
	atLeastOneRevisionWasAlteredByAnotherUserOrOnAnotherWorkstation_:
		"Por lo menos una revisión se modificó por otro usuario o en otra estación de trabajo!",
	atLeastOneSectionHasErrors_: "Por lo menos una sección tiene errores!",
	atLeastOneSectionHasErrorsOrWarnings_:
		"Por lo menos una sección tiene errores o advertencias.",
	atLeastOneSectionHasWarnings_: "Por lo menos una sección tiene advertencias!",
	atThisTime_YourOriginalOrderHasNotBeenProcessedByOurOrderDepartment_:
		"En este momento, su pedido original no ha sido procesada por nuestro Departamento de Pedidos.",
	atThisTime_YourOriginalRequestHasNotBeenProcessedByThePurchaser_:
		"En este momento, su petición original no ha sido procesada por el comprador.",
	atThisTime_YourPreviousChangeRequestHasNotBeenProcessedByOurOrderDepartment_:
		"En este momento, su solicitud de cambio anterior no ha sido procesada por nuestro Departamento de Pedidos.",
	atThisTime_YourPreviousChangeRequestHasNotBeenProcessedByThePurchaser_:
		"En este momento, su solicitud de cambio anterior no ha sido procesada por el comprador.",
	availableOptions: "Las opciones disponibles",
	availablePriceBooks: "Libros Disponibles Precio",
	averageWork_: "Trabajo promedio.",
	bUILDING: "EDIFICIO",
	back: "Atrás",
	badRatingColumnCount_: "Número de columnas de mala calificación!",
	badRatingRowCount_: "Recuento de filas de mala calificación!",
	ballyLoadCalcFiles: "Bally Cargar archivos Calc",
	ballyRefrigeratedBoxes: "Bally cajas refrigeradas",
	baseModel: "Modelo base",
	belowFluid_sRatedMinimumTemperature_:
		"Por debajo de la temperatura mínima del fluido!",
	bestFit: "Mejor ajuste",
	billOfLading: "Bill of Lading",
	blankTubes: "Tubos del blanco",
	blanks: "Espacios en blanco",
	both: "Ambos",
	box: "Caja",
	boxDesigner: "Diseñador de caja",
	boxExteriorRelativeHumidity___: "Caja Exterior (% de humedad relativa )",
	boxExteriorTemperature: "Temperatura Exterior de la caja",
	boxInteriorRelativeHumidity___: "Caja Interior (% de humedad relativa )",
	boxInteriorTemperature: "Temperatura Interior de la caja",
	boxLocation: "Ubicación de la caja",
	boxTemp: "Caja Temp",
	boxTemperature: "Caja de temperatura",
	boxInformationIsMissing_: "Información de la caja está ausente.",
	browse: "Examinar",
	building: "Edificio",
	button: "Botón",
	by: "Por",
	cALCULATEDDETAILS: "DATOS CALCULADOS",
	cALCULATOR: "CALCULADORA",
	cALL: "CONVOCATORIA",
	cAPACITY: "CAPACIDAD",
	cERTIFIEDPRINTWIZARD: "CERTIFICADO DE IMPRESIÓN WIZARD",
	cHARGE: "CARGA",
	cHARGE_FORCED_: "CARGA (forzada)",
	cIRCUITTOTAL: "Total del circuito",
	cL: "CL",
	cOLLECT: "RECOGER",
	cOMP: "UN BORRADOR",
	cOMPLETE: "COMPLETO",
	cOMPLEXFLOORPLANNER: "PISO COMPLEJO PLANNER",
	cOMPRESSOR: "COMPRESOR",
	cOMPRESSORUNITSELECTION: "SELECCIÓN DE LA UNIDAD DE COMPRESOR",
	cONDENSINGUNITSELECTION: "SELECCIÓN DE LA UNIDAD DE CONDENSACIÓN",
	cONNECTIONDIAGRAM: "DIAGRAMA DE CONEXIÓN",
	cONNECTIONS: "CONEXIONES",
	cONTINUEDONPAGE: "CONTINÚA EN LA PÁGINA",
	cOULDNOTLOADPRICEBOOK: "NO PUDO CARGAR lista de precios",
	cOULDNOTREADCURRENTORDERSUPLOADDATA_:
		"No se pudo leer ORDENA PUBLICAR DATOS ACTUALES!",
	cPWIZ_Splash: "CPWIZ_Splash",
	cREATENEWORDER: "CREAR NUEVO ORDEN",
	cUSTOMER: "CLIENTE",
	calculate: "Calcular",
	calculatedDetails: "Datos calculados",
	calculatedFluidFlowRate: "Calcula el caudal de fluido",
	calculatedValue: "Valor calculado",
	calculatedCondensingTemperatureCanNotBeGreaterThan:
		"Temperatura de condensación calculada no puede ser superior a",
	calculatedCondensingTemperatureCanNotBeLessThan:
		"Calcula la temperatura de condensación no puede menos de",
	calculatedCondensingTemperatureMustBeGreaterThanTheAmbientTemperature_:
		"Temperatura de condensación calculada debe ser mayor que la temperatura ambiente!",
	calculatedCondensingTemperatureMustBeGreaterThanTheSuctionTemperature_:
		"Temperatura de condensación calculada debe ser mayor que la temperatura de succión!",
	calculatedShipDatesMayBeAnApproximation_:
		"fecha calculada buque puede ser una aproximación.",
	calculateSystemCharge: "Calcular cargo del sistema",
	calendar: "Calendario",
	canNotCalculateAirOutTemperature_:
		"No se puede calcular el aire hacia fuera de la temperatura!",
	canNotCalculateFluidOutTemperature_:
		"No se puede calcular el líquido a temperatura!",
	canNotCalculateTotalHeatTransmission_:
		"No se puede calcular la transmisión Total de calor!",
	canNotAddServiceParts_: "No se puede agregar piezas de servicio.",
	canNotBeGreaterThanFluidTemperatureRange_:
		"No puede ser mayor que el rango de temperatura del fluido.",
	canNotBeGreaterThanWetBuldTemperature_:
		"No puede ser mayor que la temperatura buld mojado!",
	canNotCheckPrices_: "No se puede verificar los precios!",
	canNotContinue_: "No se puede continuar!",
	canNotCopy_: "No se puede copiar.",
	canNotDeleteFactoryOrderFile_:
		"No se puede eliminar el archivo de fábrica orden.",
	canNotDeleteOldOrder_: "No se puede eliminar el viejo orden!",
	canNotDeleteOrder_: "No se puede eliminar el orden.",
	canNotDeleteThisOrder_: "No se puede eliminar este orden.",
	canNotFetchOrderFile_: "No se puede recuperar archivos orden!",
	canNotOpenOrder_: "No se puede abrir la orden!",
	canNotPaste_: "No se puede pegar.",
	canNotPreview_: "No se puede escuchar un fragmento.",
	canNotPrint_: "No se puede imprimir.",
	canNotReadFile_: "No se puede leer el archivo.",
	canNotReadOrderFile_: "No se puede leer el archivo de orden!",
	canNotRecoverEdits_: "No se pueden recuperar ediciones.",
	canNotSetRejectedStatus_: "No se puede establecer el estado rechazó.",
	canNotStartApplicationShell_: "No se puede iniciar el shell de aplicación.",
	canNotStartApplication_: "No se puede iniciar la aplicación.",
	canNotStopRequest_: "No puedo dejar de solicitud.",
	canNotStoreOrderFile_: "No se puede guardar archivo de orden!",
	canNotSubmitChange_: "No se puede presentar el cambio!",
	canNotSubmitOrder_: "No se puede presentar la orden!",
	canNotSwitchPriceBooks_: "No se puede cambiar listas de precios.",
	canNotUploadOrder_: "No se puede cargar el fin!",
	canadian: "Canadá",
	canadianEnglish: "Inglés canadiense",
	cancel: "Cancelar",
	cancelThisOperation: "Cancelar esta operación",
	cancelThisOperation_: "Cancelar esta operación.",
	cancelUpload_: "Cancelar carga.",
	cancellingDuringTheQuickboxWalkThroughWillCloseTheTool_:
		"Cancelación durante la quickbox paseo por cerrará la herramienta.",
	cannotContinue_: "No se puede continuar!",
	cannotCreateBackupFolder:
		"No se puede crear la carpeta de copia de seguridad",
	cannotDeleteBackupFolder_:
		"No se puede eliminar la carpeta de copia de seguridad.",
	cannotInstallUpdateFolder: "No se puede instalar la carpeta update ",
	cannotStartTool_: "No se puede iniciar la herramienta.",
	cannotUpdate_: "No se puede actualizar!",
	capacity: "Capacidad",
	capacityExceedsRequestedRange_: "Excede la capacidad solicitada gama!",
	capacityPerUnit: "Capacidad por unidad",
	capacityRange___: "Gama de la capacidad (%)",
	capacityTooLow_: "Capacidad insuficiente!",
	capacityVariance: "Variación de capacidad",
	capacityCanNotBeBlank_: "Capacidad no puede estar en blanco.",
	capacityIsNotCalculated_: "No se calcula la capacidad! ",
	capacityIsZero_: "La capacidad es cero!",
	capacityMustBeGreaterThanZero_: "Capacidad debe ser mayor que cero.",
	caption: "Pie de foto",
	carrier: "Transportista",
	cascade: "Cascada",
	catalogModel: "Catálogo de modelos",
	catalogueNotLoaded_: "Catálogo no se carga!",
	ceiling: "Techo",
	centreDistance: "Distancia de centro",
	certainOptionsMayNoLongerBeAvailable_:
		"Algunas opciones pueden no estar disponibles.",
	certifiedDrawing: "Certificado de dibujo",
	certifiedPrint: "Certificado de impresión",
	certifiedPrintWizard: "Certificado de Asistente de impresión",
	certifiedPrints: "Certificado impresiones",
	challengeAnswer: "Desafío Respuesta",
	challengeQuestion: "Pregunta de",
	change_Shape: "Forma & de cambio",
	changeAllMultipliers: "Cambiar todos los multiplicadores",
	changeOrderForm: "Formulario de Cambio de Orden",
	changePassword: "Cambiar contraseña",
	changeReq: "Cambio Req",
	changeRequest: "Solicitud de cambio de",
	changesNotYetAppliedToCurrentOrder_:
		"Los cambios no se aplica todavía a fin de curso.",
	changesToTheCurrentOrderHaveNotBeenSaved_:
		"Los cambios en el orden actual no se han salvado!",
	checkingForUpdates: "Comprobación de actualizaciones",
	circuitLoadCanNotBeCalculated_: "No se puede calcular la carga del circuito.",
	class: "Clase",
	clear: "Claro",
	clearDate: "Claro Fecha",
	clickHereToAdjustTheMultipliersToGenerateTheNewPrice_:
		"Haga clic aquí para ajustar el multiplicador para generar el nuevo precio.",
	clickHereToApplyThePriceChangeAndReturnToTheOrderWindow_:
		"Haga clic aquí para solicitar el cambio de precio y volver a la ventana de órdenes.",
	clickHereToCloseThisFormWithoutChangingTheOrder_:
		"Haga clic aquí para cerrar esta forma sin alterar el orden.",
	clickHereToResetAllMultipliersToTheirDefaultValues_:
		"Haga clic aquí para restablecer todos los multiplicadores en sus valores predeterminados.",
	clickOnNOToCancelThisSaveOperation_:
		"Haga clic en NO para cancelar esta operación de almacenamiento,",
	clickingOnNoWillKeepTheCurrentOwner_sMultiplierStructure_:
		"Al hacer clic en ningún caso se mantendrá la estructura multiplicador del propietario actual.",
	clipboardContainsACompleteSystem_:
		"Portapapeles contiene un sistema completo.",
	close: "Cerrar",
	closeAllOrders: "Cerrar todas las órdenes",
	closeOrder: "Cerca Orden",
	closeOrderManager: "Cierre el Administrador de la Orden",
	closeShellWhenICloseTheLastDesignerWindow:
		"Cerrar la concha cuando cierre la última ventana diseñador",
	closed: "Cerrado",
	closingThemMayResultInLossOfData_:
		"Cerrarlas pueden resultar en pérdida de datos.",
	code: "Código",
	collect: "Recoger",
	columns: "Columnas",
	compCapacity: "Un borrador de la capacidad",
	company: "Empresa",
	companyName: "Nombre de la empresa",
	company_Address_Country_City_Region_PhoneFieldsAreAllRequired_:
		"Empresa, Dirección, País, Ciudad, Región, Teléfono campos son necesarios.",
	completeDirect: "Completar directamente",
	completeSquare: "Plaza completa",
	composite: "Compuesto",
	compositeInsulationConstruction: "Construcción de aislamiento compuesto",
	compositeK_Factor: "Factor compuesto K",
	compositeR_Value: "Valor-r compuesto",
	compressor: "Compresor",
	compressorBalanceSSTTooHigh_: "Compresor SST de equilibrio demasiado alto!",
	compressorBalanceSSTTooLow_: "Compresor equilibrio SST demasiado bajo!",
	compressorCapacityIsRequired_: "Es necesaria la capacidad del compresor.",
	compressorCapacityMustBeGreaterThanZero_:
		"Capacidad del compresor debe ser mayor que cero!",
	compressorUnitCapacityError_: "Error de capacidad de unidad de compresor.",
	compressorUnitCapacityNotCalculated_:
		"Capacidad de la unidad de compresor no calculado!",
	compressorUnitNotSelected_: "Compresor no seleccionado!",
	compressorWithCondenser: "Compresor con condensador",
	concentration: "Concentración",
	condTemp: "Temp Cond",
	cond_Temp: "Temp Cond.",
	condenserBalanceSSTTooHigh_: "Condensador SST de equilibrio demasiado alto!",
	condenserBalanceSSTTooLow_: "Condensador equilibrio SST demasiado bajo!",
	condenserCapacityError_: "Error de capacidad de condensador.",
	condenserCapacityNotCalculated_: "Capacidad del condensador no calculado!",
	condenserContainsAtLeastOneError_: "Condensador contiene al menos un error!",
	condenserContainsAtLeastOneWarning_:
		"Condensador contiene por lo menos una advertencia!",
	condenserNotSelected_: "Condensador no seleccionado!",
	condensing: "Condensación",
	condensingTemp: "Temperatura de condensación",
	condensingTemp_S_C_T__: "Temperatura de condensación (SCT)",
	condensingTemperature: "La temperatura de condensación",
	condensingUnit: "La unidad de condensación",
	condensingUnitAmbientTemperature: "Temperatura de unidad de condensación",
	condensingTemperature_AmbientTemperature_:
		"Temperatura de condensación < temperatura ambiente!",
	condensingTemperature_BoxTemperature_:
		"Temperatura de condensación < cuadro temperatura!",
	condensingTemperatureCanNotBeGreaterThan:
		"Temperatura de condensación no puede ser superior a",
	condensingTemperatureCanNotBeLessThan:
		"Temperatura de condensación no puede menos de",
	condensingTemperatureIsLessThanSiteAmbientTemperature_:
		"Condensación de la temperatura es inferior a la temperatura ambiente del sitio.",
	condensingTemperatureIsMissing_: "Temperatura de condensación es falta.",
	condensingTemperatureIsRequired_: "Temperatura de condensación es necesario!",
	condensingTemperatureMustBeGreaterThanTheAmbientTemperature_:
		"Temperatura de condensación debe ser mayor que la temperatura ambiente!",
	condensingTemperatureMustBeGreaterThanTheSuctionTemperature_:
		"Temperatura de condensación debe ser mayor que la temperatura de succión!",
	condensingUnitBalanceSSTTooHigh_:
		"Condensación equilibrio unidad SST demasiado alto!",
	condensingUnitBalanceSSTTooLow_:
		"Equilibrio SST demasiado baja de la unidad de condensación!",
	condensingUnitCapacityError_: "Error de capacidad de unidad condensadora.",
	condensingUnitCapacityNotCalculated_:
		"Capacidad de la unidad de condensación no calcula!",
	condensingUnitNotSelected_: "Unidad condensadora no seleccionado!",
	conduction: "Conducción",
	configuration: "Configuración",
	confirmationIsRequired_: "La confirmación es necesaria!",
	confirmationRequired_: "Requiere confirmación!",
	connected: "Conectados",
	connectionDetails: "Detalles de la conexión",
	connectionSize: "Tamaño de la conexión",
	connectionCrossesTheCentreLineOfTheUnit_SomeOptionsMayNotBeAvailable_:
		"Conexión cruza la línea central de la unidad, algunas opciones pueden no estar disponibles!",
	connectionIsOutsideBoundsOfTheUnit_:
		"Conexión es límites exteriores de la unidad!",
	connectionNotAssignedToFeeds_: "Conexión no asignado a los feeds!",
	connections: "Conexiones",
	construction_Insulation: "Construcción / aislamiento",
	constructionLayer: "Capa de construcción",
	constructionLayers: "Capas de construcción",
	contact: "Póngase en contacto con",
	contactName: "Nombre de contacto",
	contacts: "Contactos",
	continued: "Continuación",
	copper: "Cobre",
	copy: "Copiar",
	copyCurrentQuoteAsNewRevision: "Copia actual Cita como Nueva Versión",
	copyCurrentRevisionEditsAsNewRevision_AndRestoreThisRevisionToLastSavePoint:
		"Revisión de copia actual Ediciones Como nueva revisión, y restauración de esta revisión al último punto de guardado",
	copySection: "Sección de copia",
	copyAsNewOrder: "Copiar como Nuevo Orden",
	copyLocalOrderWithNewOrderNumber_ThenDownloadMasterVersion_:
		"Copia local para con número de orden nuevo, a continuación, descarga la versión principal.",
	copyMasterVersionWithNewOrderNo_ThenReplaceMasterVersionWithLocalCopy:
		"Copiar la versión principal con nuevo orden que no, luego vuelva a la versión principal con copia local",
	copyQuote: "Copia citan",
	copyToShipToAddress: "Copia para enviar a Dirección",
	copyToSoldToAddress: "Copiar a Vendido Dirección",
	couldNotAccessRecoveryData_:
		"No se pudo acceder a los datos de recuperación.",
	couldNotAddNewContactRecord_:
		"No se pudo agregar el nuevo registro de contacto!",
	couldNotCalculateActualTD_: "No se podía calcular TD real!",
	couldNotCalculateDesignTD_: "No se podía calcular diseño TD!",
	couldNotCalculateDesignCircuitLoad_:
		"No se podría calcular la carga de circuito de diseño!",
	couldNotCalculateDesignHeatOfCompressionFactor_:
		"No se podía calcular calor diseño de factor de compresión!",
	couldNotCalculateDesignTotalHeatRejected_:
		"No se podía calcular calor total diseño rechazado!",
	couldNotCalculateRefrigerantFactor_:
		"No se podía calcular factor refrigerante!",
	couldNotCalculateSiteAltitudeFactor_:
		"No se podría calcular el factor de altitud del sitio!",
	couldNotCalculateTheTotalHeatRejected_:
		"No se podía calcular el calor total rechazado!",
	couldNotChangeMasterRevision_: "No se pudo cambiar la revisión principal.",
	couldNotChangePassword_: "No se pudo cambiar la contraseña!",
	couldNotConnectToServer_: "No se pudo conectar al servidor.",
	couldNotCreateArchivePath_: "No se pudo crear el archivo de ruta!",
	couldNotCreateFactoryOrder_: "No se puede crear para la fábrica!",
	couldNotCreateNewContactRecord_:
		"No se pudo crear un registro nuevo contacto!",
	couldNotCreateOrderStorePath_: "No se pudo crear el camino para tienda!",
	couldNotDeleteFile: "No se pudo eliminar el archivo",
	couldNotDeleteFile_: "No se pudo eliminar el archivo.",
	couldNotDeleteOneOfTheSelectedItems_:
		"No se puede eliminar uno de los elementos seleccionados.",
	couldNotDeleteOrderStorePath_: "No se pudo eliminar ruta para guardar!",
	couldNotDeleteSomeOfTheSelectedItems_:
		"No se puede eliminar algunos de los elementos seleccionados.",
	couldNotDeleteTheSelectedItem_:
		"No se puede eliminar el elemento seleccionado.",
	couldNotDownloadThisOrder_: "No se pudo descargar este orden!",
	couldNotExportOrderFile_: "No se puede exportar el archivo pedido.",
	couldNotExtrapolateCompressorRating_:
		"No se podría extrapolar calificación compresor!",
	couldNotExtrapolateCondenserRating_:
		"No se podría extrapolar calificación condensador!",
	couldNotExtrapolateCondensingUnitRating_:
		"No se puede extrapolar unidad condensadora valorar!",
	couldNotExtrapolateEvaporatorRating_:
		"No se puede extrapolar evaporador valorar!",
	couldNotFindArchivePath_: "No se pudo encontrar el camino de archivo!",
	couldNotFindOrderFile_: "No se pudo encontrar el archivo de orden!",
	couldNotGenerateTargetValue_: "No se pudo generar valor objetivo!",
	couldNotImportOrderFile_: "No se pudo importar el archivo de solicitud.",
	couldNotListLocalOrders_: "No se pudo lista de pedidos locales!",
	couldNotLoadModelCatalogue_: "No se pudo cargar el catálogo de modelos!",
	couldNotLoadServicePartsDatabase_:
		"No se pudo cargar el servicio de base de datos de las partes!",
	couldNotLockLocalOrderFile_: "No se pudo bloquear el archivo de orden local!",
	couldNotLockOrderFile_: "No se pudo bloquear el archivo de orden!",
	couldNotLockRemoteOrder_: "No se pudo bloquear fin a distancia!",
	couldNotLockSubmittedOrder_: "No se pudo bloquear orden presentado.",
	couldNotOpenDatabase_: "No se pudo abrir la base de datos.",
	couldNotOpenExportFile_: "No se pudo abrir el archivo de exportación!",
	couldNotOpenOrder_: "No se pudo abrir el orden!",
	couldNotReadCalculatorFile_: "No se pudo leer el archivo de calculadora!",
	couldNotReadDatabase_: "No se pudo leer la base de datos.",
	couldNotReadFactoryCalendar_: "No se pudo leer calendario de fábrica!",
	couldNotReadFile_: "No se pudo leer el archivo!",
	couldNotReadOrderData_: "No se pudo leer los datos del pedido.",
	couldNotReadOrderHeader_: "No se pudo leer cabecera de la orden.",
	couldNotReadOrderStatusFile_:
		"No se pudo leer el archivo de estado de los pedidos!",
	couldNotReadResponse_: "No se pudo leer la respuesta.",
	couldNotReadSynchronizedOrderStatusFile_:
		"No se pudo leer archivo sincronizado para el estado!",
	couldNotRetrieveAssociatedPurchaseRequest_:
		"No se pudo recuperar solicitud asociada compra.",
	couldNotRetrieveOrderFile_: "No se pudo obtener el archivo pedido!",
	couldNotRetrieveOrderHeader_: "No se pudo recuperar cabecera de la orden.",
	couldNotStartShellApplication_: "No se pudo iniciar la aplicación shell.",
	couldNotStartShell_: "No se pudo iniciar el shell.",
	couldNotStoreOrderFileToPath: "No se pudo guardar el archivo a fin de ruta",
	couldNotSubmitOrder_: "No se pudo entregar el pedido.",
	couldNotSynchroniseRemoteOrderFile_:
		"No se pudo sincronizar el archivo de orden a distancia!",
	couldNotSynchronizeAddressBook_:
		"No se puede sincronizar la libreta de direcciones!",
	couldNotSynchronizeOrder_: "No se pudo sincronizar el fin!",
	couldNotSynchronizeOrders_: "No se puede sincronizar las órdenes!",
	couldNotSynchronizeServerOrders_:
		"No se puede sincronizar el servidor órdenes!",
	couldNotUpdateContactRecord_:
		"No se pudo actualizar el registro en contacto con!",
	couldNotUpdateOrderDatabase_: "No se pudo actualizar la base de datos a fin!",
	couldNotUpdateOrderOnNROESServer_:
		"No se puede actualizar en el servidor para NROES.",
	couldNotUpdatePurchaseRequestStatusOnServer_:
		"No se pudo actualizar el estado de solicitud de compra en el servidor.",
	couldNotUploadLocalVersion_: "No se pudo cargar la versión local.",
	couldNotUploadOrder: "No se pudo cargar el fin",
	couldNotUploadRevision_: "No se pudo cargar la revisión.",
	couldNotWriteOrderFile_: "No se pudo escribir el archivo de orden!",
	country: "País",
	countryName: "Nombre del país",
	countyOrRegion: "Condado o región",
	coveredOpening: "Apertura cubierta",
	createAnotherChangeOrder: "Crear otro Orden de Cambio",
	createChangeOrder: "Crear Orden de Cambio",
	createANewListing_: "Crear una nueva lista.",
	criticalError_: "Crítica de error!",
	currentPrice: "Precio actual",
	currentMargin: "Margen actual",
	currentOrderPrice: "Precio actual orden",
	currentQuotePrice: "Cotización actual",
	currentChangesWillBeLost_: "Los cambios actuales se perderán.",
	currentManufacturersMinimumPrice_: "fabricantes actual precio mínimo.",
	currentManufacturersRecommendedPrice_:
		"fabricantes actual precio recomendado.",
	currentOrderPrice_: "de precios para la actual.",
	currentSetting_: "configuración actual.",
	currentlyThereAreNoServicePartsListedForThisModel_:
		"De momento no hay piezas de servicio disponible para este modelo.",
	cust_: "Cust #",
	custName: "Cust Nombre",
	custom: "Personalizado",
	customCeiling: "Techo personalizado",
	customFloor: "Piso de encargo",
	customWall: "Pared personalizado",
	customer: "Cliente",
	customer_: "Cliente #",
	customerContact: "Contacto con el Cliente",
	customerInfo: "Información del cliente",
	customerName: "Nombre del cliente",
	customerREF_: "Cliente REF #",
	customerRef: "Ref. Cliente",
	customerDatabaseIsMissing_: "base de datos del cliente no se encuentra.",
	customerNumberEnteredIsAlreadyListedAbove__:
		"Número de cliente entró ya mencionadas anteriormente.!",
	customerNumberEnteredIsNotInTheListShown_:
		"Número de cliente introducido no está en la lista que se muestra!",
	customerReferenceMustBeNumeric_: "referencia de cliente debe ser numérico!",
	customerReferenceMustBeSixCharacters_:
		"de referencia del cliente deberá ser de seis caracteres!",
	cut: "Cortar",
	cutItemsRequested_: "Corte los elementos solicitados.",
	cutSystemRequested_: "sistema de corte requerido.",
	dESCRIPTION: "DESCRIPCIÓN",
	dESCRIPTION_REMARKS: "DESCRIPCIÓN / COMENTARIOS",
	dESIGNDATA: "DISEÑO DE DATOS",
	dESIGNTOOLS: "HERRAMIENTAS DE DISEÑO",
	dESIGNER: "DISEÑADOR",
	dETAILS: "DETALLES",
	dISTRIBUTOR: "DISTRIBUIDOR",
	dIV: "DIV",
	dIVISION: "DIVISIÓN",
	dONOTUseThisFunctionToChangeAnExistingOrder_:
		"NO utilice esta función para cambiar una orden existente!",
	dOCUMENTATIONBROWSER: "NAVEGADOR DE DOCUMENTACIÓN",
	dRAFT: "PROYECTO",
	dataUpdatesFound: "Actualizaciones de datos encontradas",
	dataRecovery_: "La recuperación de datos.",
	date: "Fecha",
	dateCreated: "Fecha de creación",
	dateModified: "Fecha de modificación",
	dateOK: "Fecha en Aceptar",
	dateQuoted: "Fecha Citado",
	dateCanNotBeOnAWeekend_: "La fecha no puede estar en un fin de semana.",
	dateChanged: "Cambio de fecha",
	dateIsPriorToToday_: "Fecha sea anterior al día de hoy.",
	dateMustBeInTheFuture_: "La fecha debe estar en el futuro!",
	dateVerifiedChanged: "Fecha en que se verificó cambiado",
	days_: "Días!",
	defaultNumericUnits: "Numérico por defecto unidades",
	defaultUnits: "Unidades por defecto",
	delete: "Eliminar",
	deleteChangeOrder: "Orden de Cambio Eliminar",
	deleteCustomSection: "Eliminar la sección personalizada",
	deleteCustomSections: "Eliminar secciones personalizadas",
	deleteLayer: "Eliminar la capa",
	deleteLayers: "Eliminar capas",
	deleteLoad: "Eliminar la carga",
	deleteOrder: "Eliminar Orden",
	deleteProduct: "Eliminar el producto",
	deleteTheSelectedSystem_: "Eliminar el sistema seleccionado.",
	deltaX: "Delta de X",
	deltaY: "Delta Y",
	dep_Div: "Dep. / Div",
	department: "Departamento",
	depth: "Profundidad",
	depth_IsARequiredValue_: "Profundidad 1 es un valor deseado!",
	description: "Descripción",
	descriptionCanNotBeBlank_: "Descripción no puede estar en blanco!",
	descriptionIsMissing_: "Descripción es falta!",
	deselectingThisPackageWillAlsoDeselectALLThoseOptionsIncludedInThatPackage_:
		"Anulación de la selección este paquete también se desactive todas las opciones incluidas en ese paquete.",
	designAmbient: "Diseño ambiental",
	designConnections: "Conexiones de diseño",
	designRequirements: "Requisitos de diseño",
	designTD: "Diseño TD",
	designTDCanNotBeGreaterThan: "Diseño TD no puede ser superior a",
	designTDCanNotBeLessThan: "Diseño TD no puede menos de",
	designTDMustBeGreaterThanZero_: "Diseño TD debe ser mayor que cero!",
	designTHRNotEntered_: "Diseño THR no entrado!",
	designTools: "Herramientas de diseño",
	designToolsShellOptions: "Opciones de Shell de herramientas de diseño",
	designCondensingTemperatureCanNotBeGreaterThan:
		"Temperatura de condensación de diseño no puede ser mayor que",
	designCondensingTemperatureCanNotBeLessThan:
		"Temperatura de condensación el diseño no puede ser inferior a",
	designConnectionContainsErrorsOrWarnings_:
		"Diseño de conexión contiene errores o advertencias!",
	designContainsErrorsOrWarnings_: "Diseño contiene errores o advertencias!",
	designHasConfigurationErrors_: "El diseño tiene errores de configuración!",
	designHasConfigurationWarnings_:
		"El diseño tiene advertencias de configuración!",
	designHasRequirementErrors_: "El diseño tiene errores de requisito!",
	designHasRequirementWarnings_: "El diseño tiene advertencias de requisito!",
	designNotSaved: "El diseño no guardan",
	designer: "Diseñador",
	details: "Detalles",
	detailsLabel: "DetailsLabel",
	dialog: "De diálogo",
	dialog_Product_Selector: "Dialog_Product_Selector",
	differenceBetweenCurrentPriceAndFloorPrice_:
		"Diferencia entre el precio actual y el precio del piso.",
	differenceBetweenNewPriceAndCurrentPrice_:
		"Diferencia entre el precio nuevo y el precio actual.",
	differenceBetweenNewPriceAndFloorPrice_:
		"Diferencia entre el precio nuevo y el precio del piso.",
	dim_: "Dim 1",
	dimension: "Dimensión",
	dimension_IsNotEntered_: "No se ha introducido la dimensión 1!",
	dimensions: "Dimensiones",
	discardMyChanges_: "Descartar cambios.",
	distance: "Distancia",
	divisionsAvailable: "Divisiones disponible",
	doNotCheckTheAboveIfThis_WorkstationIsUsedByOtherPeople:
		"No comprobar lo anterior, si esta estación de trabajo ~ es utilizado por otras personas",
	doNotShowThisMessageAgain: "No mostrar este mensaje",
	doYouWantChangeAttachedOptionsToTheNewSetting_:
		"¿Quieres cambiar las opciones de adjunto a la nueva configuración?",
	doYouWantChangeTheAttachedOptionsToTheNewSetting_:
		"¿Quieres cambiar las opciones de adjunto a la nueva configuración?",
	doYouWantChangeTheseOptionsToTheNewIsSetting_:
		"¿Quieres cambiar estas opciones a la nueva configuración?",
	doYouWantChangeTheseOptionsToTheNewSetting_:
		"¿Quieres cambiar estas opciones para la nueva configuración?",
	doYouWantToApplyTheseSettingToTheCurrentOrder_:
		"¿Usted desea aplicar esta configuración en el orden actual?",
	doYouWantToApplyYourPersonalMultiplierStructure_:
		"¿Usted desea aplicar la estructura de su multiplicador de personal?",
	doYouWantToBuildItNow_: "¿Quieres que construir ahora?",
	doYouWantToChangeTheSoldByInformationToYourName_:
		"¿Quieres cambiar la venden con la información de su nombre.",
	doYouWantToDeleteTheSelectedOrderFromTheServerAndYourComputer_:
		"¿Desea eliminar el pedido seleccionado desde el servidor y su equipo.",
	doYouWantToDeleteTheSelectedOrderFromYourComputerOny_:
		"¿Desea eliminar el pedido seleccionado del equipo ony.",
	doYouWantToOpenTheOrderAnyway_: "¿Desea abrir el fin de todos modos?",
	doYouWantToOpenTheOrderNow_: "¿Desea abrir el pedido ahora?",
	doYouWantToRemoveTheOrderFromTheLocalStore_:
		"¿Quieres eliminar el orden de la tienda local?",
	doYouWantToReplaceYourExistingQuoteInformation_:
		"¿Quieres cambiar la información de su cotización existente.",
	doYouWantToReplicateThisChangeToAllItems_:
		"¿Quieres repetir este cambio a todos los elementos?",
	doYouWantToReplicateThisDateToAllItems_:
		"¿Quiere replicar esta fecha a todos los elementos?",
	doYouWantToRestoreTheseOrders_: "¿Desea restaurar estas órdenes?",
	doYouWantToReviewYourErrorsNow_: "¿Desea revisar errores ahora?",
	doYouWantToRunInOff_lineMode_: "¿Quieres que se ejecute en modo off-line?",
	doYouWantToSaveItNow_: "¿Desea guardar ahora?",
	doYouWantToSaveYourDataAnyway_: "¿Desea guardar los datos de todos modos?",
	doYouWantToShutdownAnyway_: "¿Desea cerrar de todas formas?",
	doYouWantToSubmitAnyway_: "¿Quiere que le presente de todos modos?",
	doYouWantToSubmitThisOrderAnyway_:
		"¿Quieres enviar esta orden de todos modos?",
	doYouWantToUpdateTheOrderNow_: "¿Desea actualizar el pedido ahora?",
	doYouWantToUpdateTheOrderToUseYourNewCatalogueProfileSettings_:
		"¿Desea actualizar el fin de utilizar la nueva configuración de perfil catálogo?",
	doYouWantToUpdateTheOrderToUseYourNewMultiplierStructure_:
		"¿Desea actualizar el fin de utilizar la estructura de su multiplicador de nuevo?",
	doYouWantToUpdateThisRevisionToUseTheLatestPricebook_:
		"¿Desea actualizar esta revisión con la última pricebook?",
	doYouWishToRetry_: "¿Desea volver a intentarlo?",
	dockDoor: "Puerta de muelle",
	documentGenerator: "Documento generador",
	documentContainsNoPages_: "El documento no contiene páginas.",
	door: "Puerta",
	door_OpeningDetails: "Puerta abertura detalles",
	doorAndOpeningInfiltrationEstimate:
		"Puerta y estimación de la infiltración de apertura",
	doorsAndOpenings: "Puertas y aberturas",
	down: "Abajo",
	downloadingApplicationShell: "Descarga de aplicación Shell",
	downloadingDataUpdate_: "La descarga de datos de actualización:",
	downloadingProgramUpdate_: "Programa de descarga de actualización:",
	downloadingUpdates: "Descarga de actualizaciones",
	draft: "Proyecto de",
	draftC_O_: "Proyecto de CO",
	dualEnteringService: "Servicio de entrada dual",
	dualEnteringService__FanWideUnitWithSeperateControlsPerSide_:
		"Doble entrar en servicio (2 ventilador unidad amplia con controles separados por cada lado)",
	dualEnteringService__FanWideUnitsWithSeperateControlsPerSide_:
		"Doble entrar en servicio (ventilador 2 unidades amplia con controles separados por cada lado)",
	e_Mail: "Correo electrónico",
	e_Mail_UsernameCombinationAlreadyExists_:
		"Correo electrónico / combinación de nombre de usuario ya existe.",
	e_MailAddressAgain: "Dirección de correo electrónico nuevo",
	e_MailAddresses: "Direcciones de correo electrónico",
	e_mailAddressesDoNotMatch_: "Direcciones de correo electrónico no coinciden.",
	eACH: "CADA",
	eDIT: "EDITAR",
	eNTERED: "CELEBRADO",
	eNTEREDDESIGNREQUIREMENTS: "ENTRÓ EN LOS REQUISITOS DE DISEÑO",
	eRROR: "ERROR",
	eRROR_: "¡ ERROR!",
	eRRORS: "ERRORES",
	eULA: "EULA",
	eVAPORATORMOTORS: "MOTORES DE EVAPORADOR",
	eVAPORATORUNITSELECTION: "SELECCIÓN DE LA UNIDAD DE EVAPORADOR",
	eXITNROES: "NROES DE SALIDA",
	edit: "Editar",
	editConstruction: "Editar construcción",
	editLayer: "Editar la capa",
	editLoad: "Editar carga",
	editMode_: "El modo de edición:",
	editModel: "Editar modelo",
	editProduct: "Editar productos",
	editQuoteInformation: "Editar información de Presupuesto",
	editSection: "Editar sección",
	editSections: "Editar secciones",
	effectiveR_Value: "Valor-r eficaz",
	effectiveR_ValuePerUnitDepth:
		"Valor-r eficaz por la profundidad de la unidad",
	either_ShippedClosedOrProcessedIsSet_:
		"O bien, Enviado cerrados o transformados se establece.",
	electricalPower: "Energía Eléctrica",
	elevationIsTooHigh_: "Elevación es demasiado alto!",
	elevationIsTooLow_: "Elevación es demasiado baja.",
	emailAddress: "Dirección de correo electrónico",
	ending: "Final",
	enter: "Entrar",
	enterANumberEndingInAPercent_SymbolIfRequired_:
		"Introduzca un número que termina en un símbolo% por ciento si es necesario.",
	entered: "Entró",
	enteredDesignRequirements: "Requisitos de diseño introducidos",
	enteredValue_: "Valor Entrada:",
	enteredMultiplierIsLessThanTheRecommendedMinimumValue_:
		"Entró multiplicador es menor que el valor mínimo recomendado.",
	enteredPasswordDoNotMatch_: "Contraseña ingresado no coinciden.",
	enteringTemperature: "Temperatura de entrada",
	equipmentList: "Lista de Equipamientos",
	equipmentType: "Tipo de equipo",
	equipmentChangesOccurredOn: "cambios en el equipo llevó a cabo el",
	equipmentChangesOccurredOnOneRevision_:
		"cambios en el equipo se produjo en una revisión.",
	error: "Error",
	errorDetails_: "Detalles del error.",
	errorsExistOnThisOrder_: "Los errores existen en este orden.",
	evapTemp: "Evap Temperatura",
	evap_Temp: "Evaporador. Temp",
	evaporatorBalanceSSTTooHigh_: "Evaporador SST de equilibrio demasiado alto!",
	evaporatorBalanceSSTTooLow_: "Evaporador equilibrio SST demasiado bajo!",
	evaporatorUnitCapacityError_: "Error al evaporador unidad capacidad.",
	evaporatorUnitCapacityNotCalculated_:
		"Capacidad de la unidad de evaporador no calculado!",
	evaporatorUnitNotSelected_: "Unidad del evaporador no seleccionado!",
	exceedsAllModelMaxFanTemperatureSpecifications_:
		"Supera todas las especificaciones de temperatura modelo Max ventilador!",
	exceedsDryBulbTemperature_: "Excede la temperatura de bulbo seco.",
	exceedsFluid_sRatedMaximumTemperature_:
		"Supera la temperatura máxima del líquido!",
	excelCSVTextFiles: "Archivos de Excel CSV texto",
	excelFile_AllListedParts_:
		"Archivo de Excel - Todos los elementos de publicación.",
	excelFile_SelectedPartsOnly_:
		"archivo de Excel - Selección de las partes solamente.",
	excludeDraftOrders: "Excluir los proyectos de Decreto",
	excludedArea: "Excluidos de la zona",
	exit: "Salir",
	exitNROES: "NROES salida",
	exitOrderManager: "Salga de Administrador de la Orden",
	expectedCarrier: "Espera Transportista",
	expires: "Expira",
	export: "Exportación",
	exportOrderFile: "Orden de archivo de exportación",
	exportServicePartInformation:
		"servicios de exportación correspondiente a la información",
	extTemp: "Temp ext",
	extension: "Extensión",
	exteriorRH: "Exterior RH",
	exteriorTemperature: "Temperatura exterior",
	exteriorBoundaryLayer: "Capa límite exterior",
	externalRH: "RH externa",
	externalTemperature: "Temperatura exterior",
	fANLENGTH: "FAN LONGITUD",
	fANMOTOR: "MOTOR DE VENTILADOR",
	fANWIDTH: "FAN ANCHO",
	fANS: "LOS FANS",
	fAX: "FAX",
	fAXNumbers: "Fax",
	fIN: "ALETA",
	fINSPACING: "Paso de aletas",
	fINISH: "ACABADO",
	fLA_FAN: "FLA / FAN",
	fLUIDCOOLER: "LÍQUIDO REFRIGERADOR",
	fORKLIFTS: "CARRETILLAS ELEVADORAS",
	fPI: "FPI",
	faceArea: "Área de la cara",
	factoryInv_To: "Fábrica de Inv. Para",
	factoryJob: "Fábrica de Empleo",
	factoryJob_: "Fábrica de Trabajo #",
	factoryOrder: "La Orden de Fabricación",
	factorySettings: "Los ajustes de fábrica",
	factoryStyle: "Fábrica de Estilo",
	factoryCalendarNotFound_: "calendario de fábrica no se encuentra!",
	factoryOrderNotYetCreated_: "para la fábrica aún no creado.",
	failedToDownloadRequiredDataFiles_:
		"No se ha podido descargar los archivos de los datos requeridos!",
	familyMinimumOrderPriceNotReached_:
		"precio mínimo de compra de Familia no llegó.",
	fanConfiguration: "Configuración del ventilador",
	fanCount: "Ventilador cuenta",
	fans: "Fanáticos",
	fansLong: "Los aficionados a largo",
	fansWide: "Los aficionados Amplio",
	fansL: "Sudo",
	fansW: "FansW",
	faxNo: "Fax",
	feeds: "Alimenta",
	feedsAvailable: "Feeds disponibles",
	file: "Archivo",
	fileError_: "Archivo de error.",
	fileIsNotAValidOrderFile_: "El archivo no es válida para un archivo!",
	fileRevisionNoLongerSupportByNROES_:
		"Revisión del archivo ya no apoyan por NROES.",
	fileStructureNotRecognized_: "Estructura de archivo no reconocida.",
	fileWasCreatedWithANewerVersionOfNROES_:
		"El archivo se creó con una versión más reciente de NROES.",
	filesDownloaded: "Los archivos descargados",
	filter: "Filtro",
	finBlockDimensions: "Dimensiones del bloque de aleta",
	finConstruction: "Construcción de aleta",
	finStyle: "Estilo de la aleta",
	final: "Final",
	finalTemperature: "Temperatura final",
	findCustomer: "Encuentra cliente",
	finish: "Finalizar",
	finsPerInch: "Aletas por pulgada",
	fitAModel: "Ajustar un modelo",
	fitAnApplication: "Ajuste de una aplicación",
	flat: "Plana",
	floor: "Piso",
	floorArea: "Área de piso",
	floorPrice: "Piso Precio",
	floorPlanIsNotCompleted_: "No se ha completado la planta!",
	fluid: "Líquido",
	fluidConcentration: "Concentración de líquido",
	fluidConcentrationNotSelected_: "Concentración de líquido no seleccionada!",
	fluidCooler: "Líquido refrigerador",
	fluidCoolerDesignTools: "Líquido refrigerador herramientas de diseño",
	fluidCoolerDesigner: "Líquido refrigerador diseñador",
	fluidCoolerDesigner_FitAJob:
		"Líquido enfriador diseñador: Montar un puesto de trabajo",
	fluidCoolerDesigner_FitAModel:
		"Líquido refrigerador Diseñador: Ajustar un modelo",
	fluidCoolerModelSelector: "Líquido refrigerador Modelo Selector",
	fluidFlowRate: "Caudal de fluido",
	fluidFlowRateIsNotSet_: "No se ha establecido el caudal de fluido!",
	fluidFlowRatePerUnit: "Caudal de fluido por unidad",
	fluidFoulingFactor: "Factor de ensuciamiento de fluido",
	fluidInletTemperature: "Temperatura de entrada de fluido",
	fluidInletTemperatureIsNotSet_:
		"No se ha establecido la temperatura de entrada de fluido!",
	fluidInletTemperatureMustBeSet_:
		"Temperatura de entrada de fluido debe establecerse!",
	fluidInletTemperatureNotCalculated_PleaseEnterOtherThreeValues_:
		"Temperatura de entrada de fluido no calculado! Introduce otros tres valores.",
	fluidNotSelected_: "Fluido no seleccionada!",
	fluidOutTemperature_AirTemp____C:
		"Fluido Temperatura < Temp del aire + 0,2 C",
	fluidOutTemperatureGreaterThanFluidMaximumTemperature__C_:
		"Líquido a temperatura superior a la temperatura máxima del fluido - 2C!",
	fluidOutTemperatureLessThanFluidMinimumTemperature__C_:
		"Líquido a temperatura de menos de fluido mínimo + 2 C!",
	fluidOutletTemperature: "Temperatura de salida del fluido",
	fluidOutletTemperatureIsNotSet_:
		"No se ha establecido la temperatura de salida del fluido!",
	fluidPressureDrop: "Caída de presión de fluido",
	fluidTemperatureRange: "Rango de temperatura de líquido",
	fluidType: "Tipo de fluido",
	fluidVelocity: "Velocidad del fluido",
	fluidVelocityAboveRecommendedNominalRange_:
		"Por encima de la velocidad del fluido había recomendado gama Nominal!",
	fluidVelocityBelowRecommendedNominalRange_:
		"Velocidad del fluido a continuación recomienda gama Nominal!",
	fluidVelocityTooHigh_: "Velocidad del fluido demasiado alto!",
	fluidVelocityTooLow_: "Velocidad del fluido demasiado bajo!",
	followTheInstructionsInTheEmailToCompleteYourNewUserRegistration_:
		"Siga las instrucciones en el correo electrónico para completar el registro de usuario nuevo.",
	forceUpdateWaiting: "ForceUpdateWaiting",
	forkliftLoad: "Carretilla elevadora carga",
	form_App_Menu: "Form_App_Menu",
	forms: "Formularios",
	formsMethod: "Método de formas",
	fouling: "Ensuciamiento",
	foulingFactor: "Factor de ensuciamiento",
	freeShippingIsAvailableOnThisOrder_SetTermsToPre_Paid_:
		"Envío gratuito está disponible en este orden!  Establece términos en prepago.",
	freezeTemperature: "Temperatura de congelación",
	freightTermsFOBBrantfordOntarioUnlessOtherwiseSpecified_:
		"términos de mercancías FOB Brantford Ontario, a menos que se especifique lo contrario.",
	french: "Francés",
	from: "Desde",
	front: "Frente",
	frontCut: "Corte frontal",
	frontInset: "Detalle frontal",
	fuildFlow: "Flujo de Fuild",
	fuildVolume: "Volumen de Fuild",
	fullName: "Nombre y apellidos",
	functionalityWillBeLimited_: "Funcionalidad será limitado.",
	fx_: "FX: ",
	gLYCOLCOIL: "BOBINA DE GLICOL",
	general: "Generales",
	german: "Alemán",
	glycoilCoil_FitAJob: "Glycoil bobina: Montar un puesto de trabajo",
	glycoilCoil_FitAModel: "Glycoil bobina: Ajustar un modelo",
	glycolCoil: "Glicol de la bobina",
	glycolCoilDesignTools: "Herramientas de diseño de bobina de glicol",
	glycolCoilDesigner: "Diseñador de bobina de glicol",
	glycolCoilModelSelector: "Selector de modelo de bobina de glicol",
	greek: "Griega",
	guest: "Comentarios",
	hEATERS: "CALENTADORES",
	hELLO_WORLD_: "HOLA ~ MUNDO ~",
	hOCFactorNotAvailable_: "ESPECIAL factor no disponible.",
	hOLDING: "EXPLOTACIÓN",
	heatOfRespiration: "Calor de respiración",
	heavyUse: "Uso intensivo",
	heavyWork_: "Trabajo pesado.",
	height: "Altura",
	heightIsARequiredValue_: "La altura es un valor deseado!",
	held: "Lugar:",
	help: "Ayuda",
	holdChangeOrder: "Mantenga Orden de Cambio",
	holdOrder: "Mantenga Orden",
	holding: "Explotación",
	holdingAnOrderWillSignalTheOriginatorThatTheyNeedToCorrectSomething_:
		"La celebración de una orden de la señal del emisor que necesitan para corregir algo!",
	horizontal: "Horizontales",
	howDoYouWantToHandleThis_: "¿Cómo quieres que manejar esto?",
	howDoYouWantToResolveThisIssue_: "¿Cómo quieres resolver este problema!",
	howDoYouWantToStartTheNewQuote_:
		"¿Cómo desea para iniciar la nueva cotización.",
	howeverYouMayLooseChangesIfTheOrderIsBeingEditedByAnotherUser_:
		"Sin embargo, usted puede perder los cambios si la orden está siendo editado por otro usuario!",
	hz: "Hz",
	iACCEPTTHESETERMS: "Acepto estos términos",
	iDONOTACCEPTTHESETERMS: "NO ACEPTO LAS CONDICIONES",
	iForget: "Se me olvida",
	iHaveConfirmedItIsOkToUploadThisOrder_:
		"Me han confirmado que está bien para cargar esta orden.",
	iD: "IDENTIFICACIÓN DEL",
	iMPORTORDERFILE: "IMPORTAR ARCHIVO DE ORDEN",
	iNREVIEW: "EN EXAMEN",
	iNFORMATIONISGIVENGRATISANDTHEMANUFACTURERASSUMESNOOBLIGTIONORLIABILITYFORACTUALRESULTSOBTAINED_:
		"SE DA INFORMACIÓN GRATIS Y EL FABRICANTE NO ASUME RESPONSABILIDAD NI OBLIGTION PARA RESULTADOS REALES OBTENIDOS.",
	iNIFileMissingOrInvalid_: "Archivo INI falta o no es válido!",
	iNVALIDREFRIGERANT: "NO VÁLIDO REFRIGERANTE",
	iNVOICETO: "FACTURA A",
	iTEM: "PUNTO",
	iTEMCHANGES: "PUNTO CAMBIOS",
	iTM: "ITM",
	identityVerificationChallenge_E_g_FavouriteHockeyTeam:
		"Desafío de Verificación de Identidad. Favorito por ejemplo, equipo de hockey",
	ifTheLoadIsNotEnteredManually_TheDesignMustIncludeAFloor_ACeiling_AndAtLeastFourWalls__:
		"Si la carga no es introducida manualmente, el diseño debe incluir por lo menos cuatro paredes, un piso y un techo.!",
	ifThisProblemPersistsPleaseContactTheFactory_:
		"Si este problema persiste, póngase en contacto con la fábrica.",
	ifYouCanReplicateTheError_PleaseProvideDetailedStepByStepInstructions_:
		"Si puede reproducir el error, por favor proporcionar instrucciones detalladas paso a paso.",
	ifYouChoseNoYouWillNotBeAbleToRecoverTheseOrdersLater_:
		"Si elige no no podrás recuperar más tarde estas órdenes.",
	ifYouContinue_YourOutstandingChangeRequestWillBeStopped_:
		"Si continúa, su solicitud de cambio de circulación se detendrá.",
	ifYouContinue_YourOutstandingOrderWillBeStopped_:
		"Si continúa, su pedido pendiente será detenido.",
	ifYouContinue_YourOutstandingRequestWillBeStopped_:
		"Si continúa, su solicitud pendiente se detendrá.",
	ifYouHaveIssuesWithTheTranslations_AndWouldLikeToHelp_PleaseClickOnTheButtonBelow_:
		"Si usted tiene problemas con las traducciones, y me gustaría ayudar, por favor haga clic en el botón de abajo.",
	ifYouHaveVerifiedThatItIsOkToUploadThisOrder_YouMayStillDoSo_:
		"Si ha comprobado que está bien para cargar esta orden, usted todavía puede hacerlo.",
	ifYouThinkTheNetworkIsAvailablePleaseContinueWaiting_:
		"Si cree que la red está disponible, por favor seguir esperando.",
	ifYouWantToSaveYourChangesAsANewQuoteRevision_DoTheFollowing_:
		"Si desea guardar los cambios como una revisión nueva cotización, haga lo siguiente.",
	ignore: "Ignorar",
	ignoreForNow_: "No haga caso, por ahora!",
	imageFiles: "Archivos de imagen",
	imperial: "Imperial",
	importExistingCondenserJobFile:
		"Importación de archivos de trabajo existentes condensador",
	importJobFile: "Importar archivo de trabajo",
	importLoadCalcParameters: "Importar los parámetros de carga de Calc",
	importLoadCalculatorFile: "Calculadora de carga de importación de archivos",
	importLoadCalculatorModels: "Importar modelos de carga calculadora",
	importOrder: "Orden de importación",
	importOrderFile: "Importación de archivos Orden",
	includeAllCompanyOrders: "Incluir todos los órdenes de la empresa",
	includeAllOfTheAbove: "Incluye todo lo anterior",
	includeAllOrders: "Incluir todos los pedidos",
	includeClosedOrders: "Incluya cerrado pedidos",
	includeDeletedOrders: "Los pedidos incluyen eliminados",
	includeHeldOrders: "Incluya Lugar: Pedidos",
	includeOrdersClosedWithinThePast__Days:
		"Los pedidos incluyen cerrado en los últimos 60 días",
	includeOrdersRejectedWithinThePast__Days:
		"Los pedidos incluyen rechazados en los últimos 60 días",
	includeOrdersShippedWithinThePast__Days:
		"Incluir las órdenes enviadas dentro de los últimos 60 días",
	includeProcessedOrders: "Incluya transformados Pedidos",
	incompatibleServerVersion_: "versión del servidor incompatibles!",
	indicatesOptionIsShippedLoose: "Indica opción es enviado suelto",
	indicatesRevisionSpecificStatus: "Indica el Estado de la revisión específica",
	indicatesRequiredEntries: "Indica las entradas necesarias",
	indoors: "En el interior",
	infiltratingAirRH: "La infiltración de aire RH",
	infiltration: "Infiltración",
	infiltrationLoad: "Carga de infiltración",
	infiltrationLoadIsNotCalculatedOrEntered_:
		"Carga de infiltración no es calculado ni entró!",
	infiltrationLoadIsRarelyZero_: "Carga de infiltración es raramente cero!",
	informationIncomplete_: "Información incompleta.",
	informationRetreivalFailed_: "Recuperación de información no se pudo.",
	initializing: "Inicializando",
	insertLayer: "Insertar capa",
	insulation: "Aislamiento",
	insulation_Construction: "Aislamiento / construcción",
	insulationName: "Nombre de aislamiento",
	interiorBoundaryLayer: "Capa límite interior",
	internalRH: "Interna RH",
	internalTemperature: "Temperatura interna",
	internalVolume: "Volumen interno",
	invalidPassword_: "Contraseña no válida!",
	invalidChecksum_: "Suma de comprobación no válida.",
	invalidDateEntered_: "fecha de entrada no válido!",
	invalidE_mailAddress_: "Dirección de correo electrónico válida.",
	invalidFileHeader_: "Encabezado del archivo no válido.",
	invalidFileLength_: "Longitud no válida del archivo.",
	invalidHeaderLength_: "Longitud no válida de cabecera.",
	invalidPromiseDate_: "Fecha no válida promesa.",
	invalidQuantityEntered_: "cantidad no válida entrado!",
	invoice: "Factura",
	italian: "Italiano",
	item_: "Artículo #",
	itemAdjustmentDetails: "Detalles del ajuste del artículo",
	itemDescription: "Descripción del artículo",
	itemListPrice: "Precio de lista del artículo",
	itemMultiplier: "Multiplicador del artículo",
	itemName: "Nombre del artículo",
	itemNet: "Punto Net",
	itemNo: "No. del artículo",
	itemPrice: "Precio del artículo",
	itemQuantity: "Cantidad del artículo",
	itemRequestedDate: "Fecha del artículo solicitado",
	itemTotal: "Total del artículo",
	itemTotalAfterAdjustments: "Del artículo después de los ajustes",
	itemWeight: "Peso del artículo",
	itemsInBOLDAreRequired: "Los elementos en negrita son obligatorios",
	itemsOnTheClipboard_: "Elementos en el portapapeles:",
	itemsOnThisSystemHaveErrors_: "Elementos de este sistema tienen errores!",
	itemsOnThisSystemHaveWarnings_:
		"Artículos en este sistema tienen advertencias!",
	itm_OptNotes: "ITM / opt Notas",
	itm_OptTag: "ITM / opt etiqueta",
	job_SiteDetails: "Trabajo / sitio detalles",
	job_SiteDetails_Preferences:
		"Trabajo / Información sobre el emplazamiento y preferencias",
	jobMayBeUploaded_: "Trabajo puede ser subido!",
	jobNotBeUploaded_: "Job no cargar!",
	job_ScopeREF_: "Trabajo-Scope REF #",
	job_ScopeReferenceNotReturned_:
		"de referencia del empleo-Ámbito de aplicación no se devuelve!",
	job_ScopeReturnFileNotFound_:
		"archivo Job-Ámbito de aplicación de retorno no encontrado!",
	kFactor: "Factor K",
	k_Factor: "Factor K",
	keepRiteLoadCalcFiles: "KeepRite Cargar archivos Calc",
	keepRiteRefrigeration: "KeepRite refrigeración",
	keepriteRefrigeration: "Keeprite refrigeración",
	l_SHAPED: "L - EN FORMA",
	lIGHTING: "ILUMINACIÓN",
	lIQUID: "LÍQUIDO",
	lISTPRICE: "LISTA DE PRECIOS",
	lOAD: "CARGA",
	lOADCONNECTIONS: "Conexiones de carga",
	lOADINGCATALOGUE: "CATÁLOGO DE CARGA",
	lOC: "LOC",
	lOCATION: "UBICACIÓN",
	lOCKED: "BLOQUEADO",
	lRA: "LRA",
	label: "Etiqueta",
	label__Text: "Label1.Text",
	language: "Idioma",
	largeToolbarIcons: "Barra de herramientas Iconos grandes",
	layer: "Capa",
	leadTime: "Tiempo de entrega",
	leadTimeIsDependentOnPartAvailabilityAndWillBeConfirmedAtTimeOfOrder_:
		"Tiempo de entrega depende de la disponibilidad de piezas y se confirmará en el momento del pedido.",
	leaveQuoteDateAsIs_: "Deja citan la fecha como es.",
	leftCut: "Corte de izquierda",
	leftEnd: "Extremo izquierdo",
	leftInset: "Recuadro izquierdo",
	length: "Longitud",
	length_IsARequiredValue_: "Longitud 1 es un valor deseado!",
	less: "Menos",
	lightWork_: "Trabajos ligeros.",
	lightingLoad: "Carga de iluminación",
	lineFrequency: "Frecuencia de línea",
	lineItem: "Línea de Partida",
	liquidOut: "Salida de líquido",
	listPrice: "Lista de precios",
	listPricesAreNotCurrentAndTheQuoteIsOlderThan__Days_:
		"Las listas de precios no están al día y la cita es de más de 30 días!",
	listPricesAreNotCurrent_: "Las listas de precios no están al día!",
	load: "De carga",
	loadCalculator: "Calculadora de carga",
	loadCalculatorDesignTool: "Herramienta de diseño de la calculadora de carga",
	loadDifferentFile: "Cargar archivo diferente",
	loadFactor: "Factor de carga",
	loadingPriceBook: "Cargando lista de precios",
	localVersionUploadTimestamp_: "La versión local de carga marca de tiempo:",
	localOrdersQueried_: "órdenes locales consultados!",
	localVersionModificationTimestamp_:
		"la versión local de fecha y hora de modificación:",
	location: "Localización",
	locations: "Ubicaciones",
	longStorage: "Almacenamiento prolongado",
	lookingForUserProfile_: "Buscando el perfil del usuario.",
	m_R_P: "MRP",
	mANUALOPTIONS: "MANUAL DE OPCIONES",
	mAXSPARES: "REPUESTOS MAX",
	mAY: "MAYO",
	mCA_MOPAreForTheCondensingUnitONLY_SinglePointConnectionsWILLShowDifferentOnDataplate_:
		"FREGONA & MCA son para la unidad de condensación solamente. Conexiones de punto único mostrará diferentes en placa.",
	mCA_: "MCA†",
	mESSAGENOTFOUND: "MENSAJE NO ENCONTRADO",
	mODELFEATURES: "MODELO CARACTERÍSTICAS",
	mODELINFORMATION: "INFORMACIÓN DEL MODELO",
	mODELOPTIONS: "MODELO DE OPCIONES",
	mODELTYPE: "TIPO DE MODELO",
	mODIFIED: "MODIFICADO",
	mOP_: "MOP‡",
	mOREINFO: "Moreinfo",
	mULT: "MULT",
	mULTI_CIRCUIT: "MULTI-CIRCUITO",
	manufacturer: "Fabricante",
	manufacturing: "Fabricación",
	margin: "Margen",
	markAllAsRead: "Marcar todos como leídos",
	markAsShipped: "Marcar como Enviado",
	markRequestAsProcessed: "Solicitud de marca como procesado",
	markUp: "Marcar",
	markAsClosed: "Marcar como cerrado",
	markAsRead: "Marcar como leído",
	masterCatalogNotLoaded: "Maestro de catálogo no se carga",
	match: "Partido",
	matched: "Emparejado",
	matches: "Partidos",
	matchingModels: "Comparable a los modelos",
	material: "Material",
	materialName: "Nombre del material",
	materialLoadIsNotCalculatedOrEntered_:
		"Carga de material no es calculado o entró!",
	materialNameMustBeEntered_: "Debe especificarse el nombre del material!",
	materialTypeMustBeSelected_: "Debe seleccionarse el tipo de material!",
	maximum: "Máximo",
	maximumFluidFlowRateExceeded_: "Fluido caudal máximo superado!",
	maximumOvercurrentProtection: "Máxima protección contra sobrecorriente",
	measurementUnits: "Unidades de medida",
	menuStrip: "Menustrip",
	messages: "Mensajes",
	metric_SI_: "Métricas (SI)",
	minimum: "Mínimo",
	minimumCircuitAmpacity: "Capacidad de corriente mínima del circuito",
	minimumValue_: "Valor mínimo:",
	minimumOrderPriceNotReached_: "Precio mínimo de la orden no alcanzado!",
	minus: "Menos",
	miscelaneousRefrigerationEquipment: "Reparacion de equipos de refrigeración",
	miscellaneousLoad: "Carga varios",
	miscellaneousLoads: "Diversas cargas",
	miscellaneousSelected: "Seleccionan varios",
	missingPriceBook: "Falta lista de precios",
	model: "Modelo",
	model_: "Modelo #",
	modelCapacity: "Modelo capacidad",
	modelConfiguration: "Modelo de configuración",
	modelFeatures: "Características del modelo",
	modelMatchingCriterea: "Modelo Criterea coincidente",
	modelNumber: "Número de modelo",
	modelOptions: "Opciones de modelo",
	modelPartNumber: "Modelo Número de pieza",
	modelSelector: "Selector de modelos",
	modelIsNoLongerAvailable_: "Modelo ya no está disponible!",
	modelIsNoLongerListedInOurInventory_:
		"Modelo ya no aparece en nuestro inventario!",
	modelMatch: "Modelo de partido",
	modelMultiplierChanged_: "multiplicador Modelo cambiado.",
	modelsAvailable_: "Modelos disponibles:",
	modifyStatus: "Modificar estado",
	moistureContent: "Contenido de humedad",
	motor: "Motor",
	motorLoad: "Carga del motor",
	motorType: "Motor Tipo",
	moveConnectionLeft: "Mover a la izquierda conexión",
	moveConnectionRight: "Mover hacia la derecha de la conexión",
	moveDown: "Bajar",
	moveFirst: "Primer movimiento",
	moveLast: "Mover Última",
	moveLeft: "Moverse a la izquierda",
	moveRight: "Mover a la derecha",
	moveToSide: "Hacia el lado",
	moveUp: "Subir",
	multiplier: "Multiplicador",
	multiplierHasBeenResetToDefaultForThisModelType_:
		"Multiplicador se ha restablecido a los valores predeterminados para este tipo de modelo.",
	multiplierIsLessThanDefaultValue_:
		"Multiplicador es inferior al valor por defecto: ",
	multiplierIsLessThanMinimumValue_:
		"Multiplicador es inferior al valor mínimo:",
	multipliersUpdated_: "Multiplicadores de Actualización.",
	mustBeGreaterThanAirInletTemperature_DB__:
		"Debe ser mayor que la temperatura de entrada de aire (DB)!",
	mustBeGreaterThanFluidInletTemperature_:
		"Debe ser mayor que la temperatura de entrada de fluido!",
	mustBeGreaterThanOrEqualToZero_: "Debe ser mayor o igual a cero!",
	mustBeGreaterThenOrEqualToMinimumValue_:
		"Debe ser mayor o igual al valor mínimo!",
	mustBeLessThanAirInletTemperature_DB__:
		"Debe ser menor que la temperatura de entrada de aire (DB)!",
	mustBeLessThanFluidInletTemperature_:
		"Debe ser la temperatura de entrada de fluido menos!",
	mustBeLessThenOrEqualTo_____: "Debe ser igual al 100% o menos entonces!",
	mustBeLessThenOrEqualToMaximumValue_:
		"Debe ser igual al valor máximo o menos entonces!",
	myInfo: "Mi información",
	myProfile: "Mi Perfil",
	nToN: "N a n",
	n_A_: "NA",
	n_A: "N / A",
	nEW: "NUEVO",
	nEWCOMPOSITE: "NUEVO COMPUESTO",
	nOPARTSFOUND_: "PIEZAS QUE NO!",
	nOParametersChanged_: "NO parámetros modificados.",
	nONE: "NINGUNO",
	nOTORDERED: "NO SE ORDENA",
	nOTORDEREDQUOTE: "NO SE ORDENA CITA",
	nOTPRINTED_SET: "NO IMPRESO / SET",
	nOTQUOTED: "Que no cotizan",
	nOTSAVED: "NO SALVO",
	nOTSUBMITTEDORDER: "NO PRESENTADOS POR ORDEN",
	nOTE: "NOTA",
	nR_Shell: "Nr-Shell",
	nRDOCS_Splash: "NRDOCS_Splash",
	nROES: "NROES",
	nROES_NotesEditor: "NROES - Editor de Notas",
	nROESContact: "Contacto NROES",
	nROESSERVER: "NROES SERVIDOR",
	nROESServerInterface: "NROES interfaz de servidor",
	nROESServerIsNotAvailable_: "NROES servidor no está disponible.",
	nROESIsHavingIssuesCommunicatingWithTheServer_:
		"NROES está teniendo problemas de comunicación con el servidor!",
	nROES__: "NROES-8",
	nR_DOCS_BROWSER: "NR_DOCS_BROWSER",
	name: "Nombre",
	nationalRefrigeration: "Refrigeración nacional",
	nationalRefrigerationAndAirConditioning:
		"Nacional de Refrigeración y Aire Acondicionado",
	nationalRefrigerationDesignTools:
		"Herramientas de diseño nacional de refrigeración",
	nationalRefrigerationOrderEntrySystem_Bally:
		"Refrigeración Orden Nacional Sistema de Entrada - Bally",
	nationalRefrigerationOrderEntrySystem_KeepRite:
		"Refrigeración Orden Nacional Sistema de Entrada - KeepRite ",
	nationalRefrigerationOrderEntrySystem_Trenton:
		"Refrigeración Orden Nacional Sistema de Entrada - Trenton ",
	nationalRefrigerationOrderFiles_:
		"Nacional de Archivos de refrigeración Orden.",
	negativeTemperatureDifference_: "diferencia de temperatura negativo!",
	neitherTheKFactorNorTheR_ValueHasBeenEntered_:
		"Se ha introducido el Factor K ni el valor de r!",
	neitherTheK_FactorNorTheR_ValueAreEnteredForTheMaterialMaterial_:
		"El Factor K ni el valor de r se introducen para el material!",
	neitherTheK_FactorNorTheR_ValueAreEnteredForTheDividerMaterial_:
		"El Factor K ni el valor de r se introducen para el material del divisor!",
	neitherTheK_FactorNorTheR_ValueHaveBeenSet_:
		"El Factor K ni el valor de r se han creado!",
	netPrice: "Precio Neto",
	new: "Nuevo",
	newJob_KnownLoads: "Nuevo trabajo - cargas conocidas",
	newJob_KnownModel: "Nuevo trabajo - modelo conocido",
	newMargin: "Nueva Margen",
	newMultipler: "Nueva Multiplicador",
	newOrder: "Nuevo Orden",
	newOrderPrice: "Precio del Nuevo Orden",
	newPassword: "Nueva Contraseña",
	newPrice: "Nuevo Precio",
	newQuotePrice: "Cotización nuevo",
	newSystem_KnownLoads: "Nuevo Sistema - cargas conocidas",
	newSystem_KnownModel: "Nuevo sistema - conocido modelo",
	newUser: "Nuevo Usuario",
	newNetPrice_: "Nuevo precio neto:",
	newPasswordMustBeAtLeastSixCharactersInLength_:
		"La nueva contraseña debe tener al menos seis caracteres de longitud!",
	newPasswordsDoNotMatch_: "Las nuevas contraseñas no son iguales!",
	newServicePartsAddedToOrder_:
		"Las piezas nuevas servicios adicionales a fin de:",
	newUserNameRegistered_: "Nuevo nombre de usuario registrado!",
	next: "Siguiente",
	nextModel_: "Siguiente modelo >",
	nextWallConstruction: "Próxima construcción de pared",
	no: "N",
	noCountryEntered_: "Ningún país entrado!",
	noDetails: "No hay detalles",
	noItemsOrSystemsSelected_: "No hay elementos o sistemas seleccionados.",
	noModelSelected: "No hay modelo seleccionado",
	noModelSelected_: "No hay modelo seleccionado!",
	noModelsAvailable: "No hay modelos disponibles",
	noPartNameEntered_: "Ningún nombre de parte entrado!",
	noAddressEntered_: "Ninguna dirección!",
	noAlternativePriceBooksOfThisTypeAreAvailable_:
		"No hay listas de precios alternativa de este tipo están disponibles.",
	noAmbientTempEntered_: "No temp ambiente entrado.",
	noApplicationRegistrationOnformationWasFoundForYourUserID_:
		"No onformation de registro de aplicación fue encontrado por su seudónimo.",
	noBoxTempEntered_: "No temporal del cuadro entrado.",
	noCalculationMethodSelected_: "Ningún método de cálculo seleccionado!",
	noChangesDetectedOnTheOrder_: "No hubo cambios detectados en la orden.",
	noCityEntered_: "Ninguna ciudad entrada!",
	noCondenserModelSelected_: "Ningún modelo de condensador seleccionado!",
	noCondensingTempEntered_: "No condensación temporal entrado.",
	noConnectionsDefined_: "No hay conexiones definidas!",
	noConstructionLayersHaveBeenDefined_:
		"No hay capas de construcción se han definido!",
	noContactNameGiven_: "No nombre contacto!",
	noContactsListed_: "No hay contactos en la lista.",
	noDeratingFactorListed_: "No hay factor de corrección en la lista!",
	noDescriptionEntered_: "No hay Descripción entrada!",
	noDoorsEstimateCalculatedOrEntered_:
		"Ninguna puerta estimar calculados o entrado!",
	noDoorsHaveBeenDefined_: "No se han definido puertas!",
	noDoorsHaveBeenDefined_DefaultDoorLoadValueUsedForOneManDoor_:
		"No se han definido puertas! Por defecto utilizado para la puerta de un hombre de valor de la carga de puerta.",
	noEquipmentChangesFound_: "No hay cambios en el equipo que se encuentran.",
	noEquipmentChangesOccurred_: "No hay cambios en el equipo se produjo.",
	noEvaporatorMotorLoadHasBeenCalculatedOrEntered_:
		"Sin carga de motor evaporador ha sido calculada o entrado!",
	noFeedsAssigned_: "No asignados los feeds!",
	noFluidSelected_: "Ningún fluido seleccionado!",
	noFutureNonWorkingDaysWereListed_:
		"No hay futuro día de trabajo no se incluyeron.",
	noInternetConnection_: "Sin conexión a internet.",
	noItemDeliveryDatesHaveBeenSelected_:
		"No hay fechas de entrega del artículo se han seleccionado!",
	noLightingLoadHasBeenCalculatedOrEntered_:
		"Sin carga de iluminación ha sido calculada o entrado!",
	noLoadHasBeenCalculatedOrEntered_: "Sin carga ha sido calculada o entrado!",
	noModelVARatingValuesListed_:
		"Sin puntuaciones VA modelo de valores que se muestran.",
	noModelRatingValuesListed_:
		"Sin puntuaciones modelo de valores que se muestran.",
	noModelsAvailableForThisHighAFlowRate_:
		"No hay modelos disponibles para este alto un caudal!",
	noOrdersListed_: "Ninguna orden de lista!",
	noPartsSelected_: "No hay piezas seleccionadas!",
	noPhoneNumberEntered_: "Ningún número de teléfono introducido!",
	noPowerValueEntered_: "Ningún valor de energía entrado!",
	noPriceBooksHaveBeenAssignedToYourProfile_:
		"No hay listas de precios se han asignado a su perfil.",
	noPriceChangesOccurred_: "No hay cambios en los precios se produjo.",
	noPricesWereChanged_: "No se modificaron los precios.",
	noPricingChangesOccurred_: "No se produjeron cambios en los precios.",
	noQuantityEntered_: "Ninguna cantidad de entró!",
	noRefrigerantSelected_: "Ningún refrigerante seleccionado!",
	noRevisionsWereUpdated_: "No hay revisiones se han actualizado.",
	noShippingAndHandlingCostEntered_: "No envío y manejo costo entrado!",
	noState_ProvinceOrCountyEntered_:
		"Ningún Estado, provincia o condado entrado!",
	noSuctionTempEntered_: "Ninguna aspiración temp entrado.",
	noSuitableFeedConfigurationFound_:
		"Ninguna configuración de alimentación adecuada que se encuentra.",
	noTotalLoadHasBeenCalculatedOrEntered_:
		"Sin carga total ha sido calculada o entrado!",
	noTotalProductLoadHasBeenCalculatedOrEntered_:
		"Sin carga de producto total ha sido calculada o entrado!",
	noTotalProductWeightHasBeenCalculatedOrEntered_:
		"Ningún peso total del producto ha sido calculada o entrado!",
	noUsageEstimateCalculatedOrEntered_: "No uso calculado o entrado!",
	noUsageLevelSelected_: "No uso grado seleccionado!",
	noVentilationEstimateCalculatedOrEntered_:
		"Ninguna estimación de ventilación calculado o entrado!",
	noVersion_OrdersFoundInThisList_:
		"No hay versión 8 órdenes que se encuentran en esta lista!",
	noZipOrPostalCodeEntered_: "No zip o código postal ingresado!",
	no_CancelThisOperation: "No, Cancelar esta operación",
	no_IAmANewUser_: "No, yo soy un nuevo usuario.",
	no_I_llDoItManually_: "No, lo haré manualmente.",
	no_TryToConnectAgain_: "No, intente conectarse de nuevo.",
	no_LeaveTheExistingOptionMultipliersUnchanged_:
		"No, dejar que los multiplicadores de opción existente sin cambios.",
	no_RestorePreviousValue_: "No, restaurar el valor anterior.",
	non_CatalogItemDetails: "Catálogo no Detalles del artículo",
	non_CatalogOptionDetails: "Catálogo no Detalles Opción",
	non_numericJob_ScopeReferenceReturned_:
		"de referencia del empleo-Ámbito de aplicación no numéricos vuelto!",
	none: "Ninguno",
	notOwner: "No Propietario",
	notFound_: "No se ha encontrado!",
	notLatestCO: "No más de CO",
	note_AnInternetConnectionIsRequiredToCompleteThisProcedure:
		"Nota: Una conexión a Internet es necesario para completar este procedimiento",
	note_: "Tenga en cuenta:",
	note_IfYouAnswerNO_NewProductsAddedToThisOrderMayNotUseTheCorrectMultiplier_:
		"Nota: Si su respuesta es NO, nuevos productos añadidos a esta orden no podrá usar el multiplicador correcto?",
	note_IndoorValueOnly__TheExteriorBoundaryLayerIsAdjusted_AutomaticallyIfSectionIsMarkedAsOutdoors:
		"Nota: Sólo valor interior.  ~ La capa de límite exterior se ajusta ~ automáticamente si la sección está marcado como al aire libre",
	note_OrderOriginatorsAndOrderDeskWillAutomaticallyBeIncludedInTheNotifyList:
		"Nota: Los autores Orden y escritorio pedido será automáticamente incluido en la lista de notificación",
	notes: "Notas",
	notes_Comments: "Notas y Comentarios",
	notes_Remarks: "Notas / Observaciones",
	nothingToDo_: "Nada que ver.",
	numberOfUnits: "Número de unidades",
	numericValueRequired_: "Valor numérico que sea necesario.",
	o_ptions: "O & pciones",
	oE: "OE",
	oELIST: "OE LISTA",
	oEList: "Lista de OE",
	oEM: "OEM",
	oFFLINE: "EN LÍNEA",
	oK: "Aceptar",
	oNHOLD: "EN ESPERA",
	oPENACTIVEORDERMANAGER: "ABRA EL ADMINISTRADOR DE ORDEN ACTIVO",
	oPENEXISTINGORDER: "ORDEN EXISTENTE ABIERTO",
	oPENPURCHAREORDERMANAGER: "ABRA EL ADMINISTRADOR DE LA ORDEN DE PURCHARE",
	oRDER: "ORDEN",
	oRDERCONTAINS: "Orden contiene",
	oRDERCONTAINSCALLPRICING_: "CONTIENE LA ORDEN DE PRECIOS DE LLAMADA!",
	oRDERENTRYSYSTEM: "ORDEN DEL SISTEMA DE ENTRADA",
	oRDERNO: "ORDEN NO",
	oRDERNOTES: "ORDEN DE NOTAS",
	oRDERSTATUS: "PEDIDO",
	oRDERVERIFIED: "ORDEN VERIFICADO",
	oRDERED: "ORDENADO",
	oTHER: "OTROS",
	oTHERLOADS: "OTRAS CARGAS",
	obsolete: "Obsoletos",
	off_LineWarning_: "Fuera de línea de advertencia.",
	oldPassword: "Contraseña antigua",
	oldPasswordDoesNotMatch_: "La antigua contraseña no coincide!",
	oneEquipmentChangeFound_: "Un cambio de equipo que se encuentran.",
	onePriceChanged_: "Un precio cambiado.",
	oneRevisionUpdated: "Una revisión actualizada",
	open: "Abierto",
	openBallyTools: "Abrir herramientas Bally",
	openExistingJob: "Abrir trabajo existente",
	openFromWeb: "Abierto de Web",
	openKeepRiteTools: "Abrir herramientas KeepRite",
	openOrder: "Abrir Orden",
	openOrder_ForceSynch: "Pedido abierto - sincronización de fuerza",
	openTrentonTools: "Abrir herramientas de Trenton",
	openAsNewOrder: "Abrir como Nuevo Orden",
	openFileInEXCEL_: "Abrir el archivo en Excel?",
	openFileInTextViewer_: "Abrir el archivo en el visor de texto?",
	openLastSavedEditOrder_: "Abrir guardó por última vez EditOrder.",
	openingType: "Tipo de apertura",
	openingDeletionRequested_: "Eliminación de apertura solicitada.",
	openingsSelected: "Aberturas seleccionadas",
	oppositeEnds: "Extremos opuestos",
	option: "Opción",
	optionPrice: "Opción de Precio",
	optionSelector: "Opción de selección",
	optionIsNoLongerAvailable_: "Opción ya no está disponible!",
	optionNeedsFactoryLeadTime_PleaseCall_:
		"Opción necesita plazo de ejecución de la fábrica. Por favor llame!",
	optionNeedsFactoryPricing_PleaseCall_:
		"Opción necesita precios de fábrica. Por favor llame!",
	optional: "Opcional",
	options: "Opciones",
	optionsOnThisItemHaveErrors_: "Opciones sobre este tema tienen errores!",
	optionsOnThisItemHaveWarnings_:
		"Opciones sobre este tema tienen advertencias!",
	orEnterAModelNumber: "O escriba un número de modelo",
	order: "Orden",
	orderDate: "Fecha del pedido",
	orderEntry: "Orden de entrada",
	orderEntryListPrice: "Orden de Entrada Lista de precios",
	orderEntryMultiplier: "Orden de entrada Multiplicador",
	orderEntrySystem: "Sistema de entrada de pedidos",
	orderForm: "Formulario de pedido",
	orderItemNo: "Orden No. del artículo",
	orderManager: "Orden Manager",
	orderManager_COLLATING: "Administrador de la Orden - cotejar",
	orderNumericUnits: "Orden numérico unidades",
	orderPreview: "Orden Prevista",
	orderPreview_NoOrderSelected:
		"Vista previa de la Orden - No Ordenado seleccionados",
	orderPreview_NotAvailable: "Vista previa de la Orden - No disponible",
	orderPrice: "Orden de precios",
	orderStatus: "Estado del pedido",
	orderUploadConfirmationCheck: "Orden Subir Compruebe Confirmación",
	orderCanNotBeImported_: "Orden no puede ser importado.",
	orderChangeStopRequested_: "cambiar el orden de parada solicitada.",
	orderChangesHaveYetToBeUploadedToTheServer_:
		"Orden cambios aún no se han subido al servidor.",
	orderImported_: "Orden importados.",
	orderIncludesIncompleteFactoryPricingInformation_:
		"Orden incluye información incompleta precios de fábrica!",
	orderInformationAlreadyExistsInTheProgramOrderStore_:
		"La información de pedidos ya existe en el almacén de orden del programa.",
	orderIsAlreadyLoadedLocally_: "Orden ya se ha cargado a nivel local.",
	orderIsOpenForReviewAndPrintOnly_:
		"Orden está abierta para su examen e imprimir sólo!",
	orderPriceTooLowForPre_paidShippingTerms_:
		"Precio de la orden demasiado bajo para términos del envío prepago! ",
	orderStopRequested_: "Orden de suspensión solicitada.",
	orderWasDeleted_: "Orden se ha eliminado.",
	orderWillBeUploadedAfterNextProgramUpdate_:
		"La orden será subido después de la actualización del programa siguiente.",
	ordered: "Ordenó",
	orders: "Órdenes",
	orientation: "Orientación",
	original: "Texto original en",
	other: "Otros",
	otherCountryName: "Nombre de Otro País",
	otherEquipmentManufacturer_sModel: "Fabricante de equipos de otros modelos",
	otherHeatSources: "Otras fuentes de calor",
	otherName: "Otro nombre",
	otherDeletionRequested_: "Otra eliminación solicitado.",
	outOfRange_ShouldBe__And______: "Fuera de rango, debe ser &gt; 0 y < 0.01!",
	outdoors: "Al aire libre",
	p_O: "PO",
	p_O_: "PO",
	pARAMETERSREQUIRECHECKINGORADJUSTMENT:
		"Parámetros requieren CONTROL O REGULACION",
	pARSING: "ANÁLISIS",
	pARTNOTFOUNDINDATABASE_: "PARTE NO SE ENCUENTRA EN LA BASE DE DATOS!",
	pARTIAL: "PARCIAL",
	pENDING: "PENDIENTE",
	pERSONNEL: "PERSONAL",
	pICK_UP: "PICK-UP",
	pO_: "PO #",
	pOWER: "PODER",
	pRD: "PRD",
	pRE_PAID: "PRE-PAGADO",
	pRICEBOOKNOTAVAILABLE: "LIBRO Precio no disponible",
	pRICEEACH: "PRECIO DE CADA",
	pROCESSED: "PROCESADOS",
	pRODUCTLINE: "LÍNEA DE PRODUCTOS",
	pRODUCTNO: "PRODUCTO NO",
	pROJECT: "PROYECTO",
	pROTECTED: "PROTEGIDAS",
	package: "Paquete",
	packageOptionsAreHighlightedInGreen_:
		"Paquete de opciones se destacan en verde.",
	panel: "Panel",
	panelExteriorIsOutdoors: "Panel Exterior es al aire libre",
	parametersCheckCompleted_: "Parámetros de la comprobación completa.",
	params: "Params",
	partDescription: "Parte Descripción",
	partDescriptions: "Parte Descripciones",
	partNo: "El n °",
	partDescriptionRequired_: "Descripción de la parte requerida.",
	partialShipmentSelected_: "envío parcial seleccionado!",
	partialShippingRequested_ButNoSpecificShipDatesListedOnSystemsOrItems_:
		"envío parcial solicitado, pero ningún barco específica fechas que aparecen en los sistemas o elementos!",
	parts: "Piezas",
	password: "Contraseña",
	passwordAgain: "Contraseña otra vez",
	passwordRequired_: "Contraseña requerida.",
	paste: "Pegar",
	pasteOptions_: "Pegar opciones.",
	pastingNonStockingModelsOnAStockingOrderIsProhibited_:
		"Está prohibido pegar modelos no media una orden de la siembra.",
	pastingStockingModelsOnAStandardOrderIsProhibited_:
		"Está prohibido pegar modelos de siembra en un orden estándar.",
	perUnit: "Por unidad",
	percentageChangeCausedByEnteredAdjustmentValue_:
		"Porcentaje de cambio causado por valor de ajuste introducido.",
	percentageDifferenceBetweenCurrentPriceAndFloorPrice_:
		"Porcentaje de diferencia entre el precio actual y el precio del piso.",
	percentageDifferenceBetweenNewPriceAndCurrentPrice_:
		"Porcentaje de diferencia entre el precio nuevo y el precio actual.",
	percentageDifferenceBetweenNewPriceAndFloorPrice_:
		"Porcentaje de diferencia entre el precio nuevo y el precio del piso.",
	perimeterLength: "Longitud del perímetro",
	permanentOpening: "Apertura permanente",
	personalInformation: "Información personal",
	personnelLoad: "Personal de carga",
	ph_: "Tel: ",
	phone: "Teléfono",
	phoneNo: "Teléfono",
	phoneNumbers: "Números de teléfono",
	pick_Up: "Pick-up",
	pleaseDescribe: "Describa por favor",
	pleaseNote_: "Tenga en cuenta:",
	pleaseSelect: "Por favor, seleccione",
	pleaseSelectAModelType: "Por favor seleccione un tipo de modelo",
	pleaseSelectObjectsToInclude: "Seleccione objetos que se incluirán",
	pleaseSelectRatingOptions: "Por favor, seleccione Opciones de valorar",
	pleaseAdjustTemperaturesOrChangeRefrigerant_:
		"Por favor ajustar temperaturas o cambiar refrigerante.",
	pleaseAdjustTemperaturesOrSelectAndEnterT_H_R_Value_:
		"Ajustar temperaturas o seleccione y escriba el valor T.H.R..",
	pleaseCheckAllItems_: "Por favor, revise todos los artículos!",
	pleaseCheckInternetAndTryAgain_:
		"Por favor, consulte a Internet y vuelva a intentarlo.",
	pleaseCheckServerConnectionAndTryAgain_:
		"Por favor, compruebe la conexión del servidor y vuelva a intentarlo.",
	pleaseCheckThemIndividually_: "Por favor comprobar individualmente.",
	pleaseCheckYourPermissionsAndOrCloseThisApplicationAndRunTheBootstrapManually_:
		"Compruebe sus permisos y / o cerca de esta aplicación y ejecutar el sistema de arranque manual.",
	pleaseCheckYourPermissionsAndOrDeleteThisFileManually_:
		"Compruebe sus permisos y / o eliminar este archivo manualmente.",
	pleaseCheckYourPermissionsAndOrMoveThisFileManually_:
		"Compruebe sus permisos y / o mover este archivo manualmente.",
	pleaseCheckYourProfileAndUpdateTheSoldByAddressPanel_:
		"Por favor, revise su perfil y actualizar los vendidos por el panel de direcciones.",
	pleaseClickOnTheNEWButtonToBeginAddingYourFirstContact_:
		"Por favor, haga clic en el botón NUEVO para empezar a añadir su primer contacto.",
	pleaseCloseAllInstancesOfNROESAndRestartTheApplication_:
		"Por favor, cierre todas las instancias de NROES y reiniciar la aplicación!",
	pleaseCloseAllRefrigerationAppsClickOnRetry_:
		"Cierre todas las aplicaciones de refrigeración pulsen Reintentar!",
	pleaseCloseAllRefrigerationToolsAndRestartTheApplication_:
		"Por favor, cierre todas las herramientas de refrigeración y reiniciar la aplicación.",
	pleaseCloseAllToolsAndRestartTheApplication_:
		"Por favor, cierre todas las herramientas y reiniciar la aplicación!",
	pleaseCompleteRequiredInformation_:
		"Por favor, complete la información requerida.",
	pleaseConfirmTheFollowing: "Por favor, confirme lo siguiente",
	pleaseConfirm_: "Por favor confirmar.",
	pleaseContact: "Póngase en contacto con",
	pleaseContactSupportTeam_: "Póngase en contacto con equipo de apoyo.",
	pleaseContactTheDevelopmentTeam_:
		"Póngase en contacto con el equipo de desarrollo.",
	pleaseContactTheFactoryToHaveYourProfileAdjusted_:
		"Póngase en contacto con la fábrica que su perfil ajustado.",
	pleaseContactTheManufacturerForServicePartInformation_:
		"Póngase en contacto con el fabricante para información de servicio parte.",
	pleaseDescribeTheReasonForThisExceptionBelowAnd:
		"Describa el motivo de esta excepción infra y",
	pleaseEnterJob_ScopeReference_:
		"Por favor, introduzca la referencia de trabajo-Scope!",
	pleaseEnterANumericValueGreaterZero_:
		"Por favor, introduzca un valor numérico mayor a cero.",
	pleaseEnterAllThePrimaryDimensionInformationFirst_:
		"Introduzca toda la información de la dimensión primaria primero.",
	pleaseEnterCustomerReferenceNumber_:
		"Por favor, introduzca el número de clientes de referencia!",
	pleaseEnterExpectedShippingCarried_: "Por favor, introduzca espera navieras!",
	pleaseEnterYourPersonalApplicationRegistrationCode:
		"Por favor ingrese su código de registro de aplicación personal",
	pleaseInstallCustomerDatabaseFileIntoYourApplicationProgramDataFolder_:
		"Por favor, instale el archivo de base de datos del cliente en su programa de aplicación de la carpeta de datos!",
	pleaseQuoteFACTORYORDER__: "Por favor cite la Orden de Fabricación #.",
	pleaseRestartTheApplication: "Por favor, reinicie la aplicación",
	pleaseRestartTheApplicationWithTheInternetConnected_:
		"Por favor, reinicie la aplicación con el conectado a internet.",
	pleaseSaveYourWorkAndCloseAllDesignWindows_:
		"Guarde su trabajo y cierre todas las ventanas de diseño.",
	pleaseSelectACompressor_: "Por favor, seleccione un compresor!",
	pleaseSelectACountryFromTheList_: "Por favor seleccione un país de la lista!",
	pleaseSelectADivision: "Por favor, seleccione una división",
	pleaseSelectAMatchingModelNumberFirst_:
		"Por favor, seleccione un número de modelo del primer apareamiento.",
	pleaseSelectAPriceBook: "Por favor, seleccione un libro de precios",
	pleaseSelectAnotherFile_: "Por favor, seleccione otro archivo!",
	pleaseSelectOrderType_: "Por favor, seleccionar el tipo de orden!",
	pleaseSelectServicePartTypesToAddToOrder_:
		"Por favor, seleccione el tipo de servicio de parte para añadirlo al pedido.",
	pleaseSelectTheSameSystemModelType_:
		"Por favor seleccione el mismo tipo de modelo de sistema.",
	pleaseSelectTheTypeOfSystemYouWantToAdd:
		"Por favor, seleccione el tipo de sistema que desea agregar",
	pleaseTryAgainLater_: "Por favor, inténtelo de nuevo más tarde!",
	pleaseTryAgain_: "Por favor, inténtelo de nuevo.",
	pleaseUpdateYourPrices_: "Por favor, actualice sus precios!",
	pleaseUseTheOptionsSelectorToAdjustTheseOptions_:
		"Por favor, utilice el selector de opciones para ajustar estas opciones.",
	pleaseUseTheOrderManagerToOpenThisOrder_:
		"Por favor, use el administrador de pedidos para abrir este fin.",
	pleaseWait_: "¡Espera!",
	plus: "Más",
	popup_Tooltip: "Popup_Tooltip",
	postal: "Postales",
	postal_ZIPCode: "Código Postal",
	postalCode: "Código postal",
	power: "Potencia",
	powerDensity: "Densidad de energía",
	powerSearch: "Búsqueda avanzada",
	power_Deg: "Alimentación/Deg",
	pr_St: "Pr / St",
	pre_view: "Pre y ver",
	pre_Paid_Charge: "Pre-pago y de carga",
	pre_paid: "Pre-pago",
	pressure: "Presión",
	pressureDropExceedsRequestedRange_: "Caída de presión excede pidió gama!",
	pressureDropRange: "Gama de presión de la gota",
	pressureDropTolerance: "Tolerancia de caída de presión",
	pressureDropTooLow_: "Caída de presión demasiado baja!",
	pressureRange: "Gama de presión",
	preview: "Prevista",
	previousNetPrice_: "precio neto anterior:",
	price: "Precio",
	priceBook: "Precio del libro",
	priceChange: "Cambio en el precio",
	priceOK: "Precio en Aceptar",
	priceBookUpdated_: "Precio Actualización libro.",
	priceBookHasExpired_: "Lista de precios ha caducado.",
	priceBookHasExpiredAndTheQuoteIsOlderThan__Days_:
		"Precio libro ha vencido y la cita es de más de 30 días!",
	priceBookIsMissing_: "lista de precios que falta!",
	priceBookNotUpdated_: "lista de precios no se actualizan.",
	priceDidNotChange_: "El precio no ha cambiado!",
	pricebookHasExpired: "Pricebook ha caducado",
	pricebookHasExpired_: "Pricebook ha caducado.",
	pricebookMissing: "Pricebook falta",
	pricebookNotLoaded_: "Pricebook no se carga.",
	pricebookWillExpireIn: "Pricebook expirará en",
	pricingChangeOccurredOnOneRevision_:
		"el cambio de precios se produjo en una revisión.",
	pricingChangesOccurredOn: "cambios de precios se produjo en",
	pricingShownIncludesAllOptionsListedOnCertifiedPrints_:
		"Precios muestra incluye todas las opciones que aparecen en copias certificadas.",
	primary: "Primaria",
	print: "Imprimir",
	printPreview: "Vista previa de impresión",
	printSelectedModelOnly: "Imprimir seleccionados sólo modelo",
	printing: "Impresión",
	processChangeOrderRequest: "Proceso de Cambio Pedir",
	processOrder: "Proceso de Orden",
	processingNotes: "Procesamiento de Notas",
	product: "Producto",
	productDetails: "Detalles del producto",
	productLine: "Línea de productos",
	productList: "Lista de productos",
	productLoad: "Carga de producto",
	productType: "Tipo de producto",
	productDeletionRequested_: "Eliminación del producto solicitado.",
	productsSelected: "Productos seleccionados",
	profitRate: "Tasa de beneficio",
	programUpdateAvailable_: "Actualización de programa disponible.",
	programUpdatesFound: "Actualizaciones del programa se encuentra",
	programUpdatesShouldBeAvailableShortly_:
		"Las actualizaciones del programa debería estar disponible en breve.",
	project: "Proyecto",
	promise: "Promesa",
	promiseDate: "Promesa Fecha",
	promised: "Prometió",
	protectCurrentRevision: "Proteger actual revisión",
	province: "Provincia",
	pulldownTime: "Tiempo de desconexión",
	purchaseOrder: "Orden de Compra",
	purchaseOrders: "Órdenes de Compra",
	purchaseRequests: "Las solicitudes de compra",
	purchaser: "Comprador",
	qTY: "CANT",
	qUOTE: "CITA",
	qUOTEDAYS: "CITA DÍAS",
	qtty: "Qtty",
	qty: "Cantidad",
	quantity: "Cantidad",
	quantityPerUnit: "Cantidad por unidad",
	quickBox: "Caja rápida",
	quotationValidFor__DaysFromQuoteDate_:
		"una validez de 30 días a partir de la fecha de cotización Cotización.",
	quote: "Citar",
	quote_: "Cita #",
	quoteDate: "Cita Fecha",
	quoteNo: "Cita n",
	quotePrice: "Cotización",
	quoteSetup: "Cita el programa de instalación",
	quoteDateIsNotSetToCurrentDate_:
		"fecha de cita no se ha establecido hasta la fecha actual!",
	rValue: "Valor de R",
	r_Value: "Valor r",
	r____A_R____CombinationNoLongerAvailable_R____ASelected_:
		"Combinación de R404-A/R-507 ya no está disponible, R-404A seleccionado.",
	rATING: "EVALUACIÓN",
	rEADONLY: "SÓLO LECTURA",
	rEADY: "LISTO",
	rECTANGULAR: "RECTANGULAR",
	rEF: "REF",
	rEFf: "REFF",
	rEQDate: "REQ Fecha",
	rEQSHIPDATE_: "REQ Fecha de envío:",
	rETRIEVENROESORDERFROMSERVER: "RECUPERAR ORDEN NROES DE SERVIDOR",
	rLA: "RLA",
	rOUTING: "RUTA",
	rUN: "EJECUTAR",
	rUNNUMBER: "RUN NÚMERO",
	range: "Gama",
	ratedAt: "Clasificado en",
	rating: "Clasificación",
	ratingOptions: "Valorar opciones",
	rear: "Parte posterior",
	rearrangeItemOrder: "Orden del artículo Reorganizar",
	rearrangeItems: "Reorganización de los elementos",
	rearrangeSystemOrder_RenameSystems:
		"Reorganizar el orden del sistema / Sistema Cambiar el nombre de",
	rearrangeSystems: "Reorganizar los sistemas de",
	rebuildFactoryOrder: "Reconstruir Orden de fábrica",
	rebuildFactoryOrderRequested_: "Para reconstruir la fábrica solicitado.",
	recentOrders: "Los pedidos recientes",
	recommendedQuantity: "Cantidad recomendada",
	recoverMyEdits_: "Recuperar mis ediciones.",
	rectangularBoxDimensions: "Dimensiones de la caja rectangular",
	redo: "Rehacer",
	reference: "Referencia",
	refresh: "Actualizar",
	refreshOrderList: "Actualizar lista de Orden",
	refrigerant: "Refrigerante",
	refrigerantFactor: "Factor de refrigerante",
	refrigerantFactorNotAvailable_: "Factor de refrigerante no está disponible.",
	refrigerantFactorOutOfRange_: "Factor refrigerante fuera del alcance.",
	refrigerantNotSelected_: "Refrigerante no seleccionada!",
	refrigerants: "Refrigerantes",
	refrigerationDesignTools: "Herramientas de diseño de refrigeración",
	refrigerationSystemDetails: "Detalles del sistema de refrigeración",
	regBuild: "Reg. Construir",
	region: "Región",
	regularShipDate_: "Fecha de envío regular: ",
	regularUse: "Uso regular",
	rejectOrder: "Rechazar Orden",
	rejected: "Rechazada",
	rejectingAnOrderWillPreventTheOriginatorFromResubmittingThisOrder_:
		"El rechazo de un pedido evitará que el autor de volver a presentar este orden!",
	releaseNotes: "Notas de la publicación",
	remarks_Notes: "Observaciones / Notas",
	remarks_TermsAndConditions: "Comentarios / Términos y Condiciones",
	rememberMeOnThisComputer: "Recordarme en este equipo",
	remo_ve: "Remo & View",
	remoteOrderLockNotEstablished_: "fin de bloqueo a distancia no establecido.",
	remoteVersionModificationTimestamp_:
		"versión a distancia modificación de marca de hora:",
	remoteVersionUploadTimestamp_: "versión Carga remota fecha y hora:",
	remove: "Quitar",
	remove_LocalOrder: "Orden de quitar & Local",
	removeFeed: "Quitar alimentación",
	removeLastWall: "Eliminar la última pared",
	removeLocalOrder: "Quitar orden Local",
	removeModel_Item: "Quitar Modelo / artículo",
	removeSystem: "Retire del sistema",
	renumberIDs: "Cambiar el número de ID",
	replaceAllItems_: "Vuelva a colocar todos los elementos.",
	replaceLocalOrderWithMasterVersion_:
		"Vuelva a colocar el orden local con la versión principal.",
	replaceSelectedItems_: "Vuelva a colocar los elementos seleccionados.",
	replaceSelectedSystem_: "Vuelva a colocar el sistema seleccionado.",
	replaceTheOrderInformationFromTheSelectedFile_:
		"Vuelva a colocar la información del pedido desde el archivo seleccionado.",
	requested: "Pidió",
	requestedChanges_PleaseUpdateJob_ScopeAndCheckOffEachItem:
		"Los cambios solicitados - Por favor, actualice Trabajo-Ámbito de aplicación y comprobación de cada artículo",
	requestedDate_: "Fecha solicitada: ",
	requestedDateException: "Fecha de excepción solicitada",
	requestedItemShipDateIsLessThanItemLeadTime_:
		"Pidió a la fecha de envío del artículo es menor que el tiempo de entrega artículo!",
	requestedOrderShipDateIsLessThanLeadTime_:
		"Pidió a la fecha del buque pedido es menor de tiempo de espera!",
	requestedSystemShipDateIsLessThanLeadTime_:
		"Pidió a la fecha del buque sistema es menor que el tiempo de entrega!",
	required: "Requerido",
	requiredCapacity: "Capacidad requerida",
	requiredCapacity_Unit: "Requiere capacidad / unidad",
	requiredCapacityIsNotSet_: "No se ha establecido la capacidad requerida!",
	requiredCapacityPerUnit: "Capacidad requerida por unidad",
	requiredCapacityTolerance: "Requiere de capacidad de tolerancia",
	requiredOptions: "Opciones necesarias",
	requiredRatingOptions: "Requiere Rating opciones",
	requiredShipDate: "Fecha de envío requeridos",
	requiredValue_: "valor necesario!",
	requirementDatesInThePast_: "Requisito de fechas en el pasado!",
	resetColumnWidths: "Restablecer Anchos de columna",
	resizeColumns: "Cambiar el tamaño de las columnas",
	restoreQuoteRevisionRequested_:
		"revisión de restauración presupuesto solicitado.",
	restoreTheOrderInformationFromTheOrderStore_:
		"Restaurar la información del pedido desde el almacén de orden.",
	resultingPriceIsLowerThanFloorPrice_:
		"precio resultante es menor que el precio del piso!",
	retrieveOrder: "Recuperar Orden",
	retry: "Reintentar",
	rev: "Rev",
	revision: "Revisión",
	revisionContainsErrors_: "Revisión contiene errores!",
	revisionContainsWarnings_: "Revisión contiene advertencias!",
	rightEnd: "Extremo derecho",
	rightClickOnTheQuoteTabsOnTheTopLeftOfTheOrderWindow_:
		"Haga clic en las fichas Cita en la parte superior izquierda de la ventana de órdenes.",
	rounding: "Redondeo",
	runNumber: "Ejecutar Número",
	runTime: "Tiempo de ejecución",
	sAndH: "S y H",
	s_C_T_: "SCT",
	s_S_T_: "SST",
	sCT: "SCT",
	sECTIONLIST: "LISTA DE LA SECCIÓN",
	sEEITEMS: "VER ARTÍCULOS",
	sELECTEDMODELDETAILS: "DETALLES DEL MODELO SELECCIONADO",
	sERVERERROR_: "ERROR DEL SERVIDOR.",
	sERVICEPARTSCATALOG: "CATÁLOGO DE PIEZAS DE SERVICIO",
	sHIPTO: "ENVIAR A",
	sHIPPEDLOOSE: "ENVIADO SUELTOS",
	sIDE_: "LADO 1",
	sITEVOLTAGES: "SITIO VOLTAJES",
	sOLDBY: "VENDIDO POR",
	sOLDTO: "VENDIDO AL",
	sST: "SST",
	sTARTING: "A PARTIR",
	sTATE: "ESTADO",
	sTOPSUBMITTEDORDERORCHANGE: "PARADA PRESENTADOS PARA O CAMBIO",
	sUBMITNOTES: "ENVIAR NOTAS",
	sUBMITORDER: "ENVIAR PEDIDO",
	sUBMITTED: "PRESENTADOS",
	sUBMITTEDC_O_: "PRESENTADOS CO",
	sUITABLEREFRIGERANTS: "REFRIGERANTES CONVENIENTE",
	sYNCHRONIZING: "SINCRONIZAR",
	sYS: "SISTEMAS",
	sYSTEM: "SISTEMA",
	sYSTEMBALANCE: "SISTEMA DE EQUILIBRIO",
	sYSTEMBULLETIN_: "BOLETÍN DEL SISTEMA!",
	sYSTEMREFRIGERANT: "Sistema de refrigeración",
	sales: "Ventas",
	salesman: "Vendedor",
	sameEnd: "Mismo extremo",
	saturatedCondensingTemperature: "Temperatura de condensación saturada",
	saturatedSuctionTemperature: "Temperatura de succión saturado",
	save: "Guardar",
	saveAllOrders: "Guardar todas las órdenes",
	saveAsDefault: "Guardar como predeterminado",
	saveAsDefaults: "Guardar como valores por defecto",
	saveAsSiteDefaults: "Guardar como valores por defecto de sitio",
	saveChanges: "Guardar cambios",
	saveOrder: "Guardar Orden",
	saveOrderParameterChanges_: "Guardar orden de los parámetros cambios?",
	saveChangedContactInformation_:
		"Guardar cambiado la información de contacto.",
	saveFailed_: "Error al guardar!",
	saveMyChanges_: "Guardar mis cambios.",
	saveTheseChangesAsANewContact_:
		"Guardar estos cambios como un nuevo contacto.",
	search: "Búsqueda",
	searchServiceParts: "Partes del servicio de búsqueda",
	searchingForMatchingAirCooledCondensers_:
		"Búsqueda para emparejar condensadores refrigerados por aire refrescado.",
	searchingForMatchingCompressors_: "Búsqueda para compresores que empareja.",
	searchingForMatchingCondensingUnits_:
		"Búsqueda por coincidencia unidades condensadoras.",
	searchingForMatchingEvaporatorUnits_:
		"Búsqueda para las unidades evaporador que empareja.",
	section: "Sección",
	sectionConstruction: "Construcción de la sección",
	sectionDivider: "Divisor de la sección",
	sectionExteriorIsOutdoors: "Sección Exterior es al aire libre",
	sectionLoad: "Carga de la sección",
	sectionType: "Tipo de sección",
	sectionDeletionRequested_: "Eliminación de la sección solicitada.",
	sectionHasOpeningErrors_: "Sección tiene errores de apertura!",
	sectionHasOpeningWarning_: "Sección tiene apertura ADVERTENCIA!",
	sectionNameNotProvided_: "Nombre de sección no proporcionado.",
	sectionTypeMustBeSelected_: "Se debe seleccionar tipo de sección!",
	sectional_E_g_StudWall_: "Seccional (e.g. pared)",
	sections: "Secciones",
	sectionsSelected: "Secciones seleccionadas",
	selectAll: "Seleccionar todo",
	selectApplicationLanguage: "Seleccione el idioma de la aplicación",
	selectEquipment: "Seleccione el equipo",
	selectModel: "Seleccione el modelo",
	selectNextModel_: "Seleccione el modelo siguiente &gt;",
	selectOptions: "Seleccione Opciones",
	selectRequestRevision: "Seleccione Solicitud de Revisión",
	selectRoomShape: "Seleccione la forma de la habitación",
	selectWhichPowerOfTenToRoundTo_:
		"Seleccione el que el poder de diez a redondear.",
	selectedItemDetails: "Detalles del artículo seleccionado",
	selectedModelDetails: "Detalles del modelo seleccionado",
	selectedQuoteDetails: "Datos seleccionados Citar",
	selectedConstructionNotFound_: "Construcción seleccionada no encontrada!",
	selectedItemCountDiffersFromPasteBufferCount_:
		"cuenta seleccionada tema difiere del recuento de pasta de buffer!",
	selectedModelNotFoundInActivePriceBook_:
		"modelo seleccionado no se encuentran en el libro de precios de activos!",
	selectedPriceBookHasExpired_: "datos seleccionados de precios ha caducado!",
	selectionError_: "error de selección!",
	sendNotification: "Enviar Notificación",
	sendNotifications: "Enviar notificaciones",
	sendItToMeAgain: "Enviarmelo otra vez",
	sendNotificationsTo__SelectAllThatApply_:
		"Enviar notificaciones a: (Seleccione todo lo que corresponda)",
	separatePrice: "Precio por separado",
	seperatePrice: "Precio por separado",
	serverConnected: "Servidor conectado",
	serverNotAvailable: "Servidor no disponible",
	serverCopyHasDifferentSaveHistory_:
		"copia Server tiene diferentes salvar la historia.",
	serverOrdersQueried_: "órdenes servidor consultado!",
	serverVersionDiffersFromApplication_:
		"Server versión difiere de la aplicación!",
	serviceParts: "Piezas de repuesto",
	setDefaults: "Establecer valores predeterminados",
	setStatus: "Set en el estado",
	setQuoteDateToToday_: "Establecer fecha de cotización el día de hoy.",
	shellApplicationFailedToAcceptCommand_:
		"Aplicación Shell ha podido aceptar comandos!",
	shellApplicationFailedToStart_: "Shell aplicación no se pudo iniciar!",
	shellApplicationFilesAreMissing_:
		"Faltan los archivos de la aplicación de Shell!",
	shellTerminatedUnexpectedy_: "Shell termina Unexpectedy!",
	shellDidNotAcceptCommand_: "Shell no aceptó el comando.",
	shellDidNotRespond_: "Shell no respondió.",
	shellIsLoading: "Shell está cargando",
	shipOrdersFromFile: "Las órdenes se envían desde archivo",
	shipTo: "Enviar a",
	shipToInformationIsIncomplete: "Barco a la información es incompleta",
	shipToRegionMustBeTwoCharacterCode_:
		"Barco a la región debe ser el código de dos caracteres!",
	shipment: "Envío",
	shipped: "Enviado",
	shippedInformation: "Enviado información",
	shippedLoose: "Enviado sueltas",
	shippedLoose_LineItem_: "Envían sueltos (de partidas )",
	shippingNotes: "Toma nota de envío",
	shippingTerms: "Términos de envío",
	shippingWeight: "Peso para el envío",
	showAll: "Mostrar todo",
	showChangeDetails: "Mostrar detalles de Cambio",
	showExtendedParameters: "Mostrar parámetros extendidos",
	showItemPrices: "Mostrar artículo Precios",
	showLocalOrders: "Mostrar local órdenes",
	showMyOrders: "Mostrar Mis pedidos",
	showNotes_Tags_AndDates: "Mostrar notas, etiquetas y fechas",
	showOptionPrices: "Mostrar precios de las opciones",
	showOptions: "Mostrar opciones",
	showSplashScreenAtStartup: "Mostrar pantalla de bienvenida al iniciar",
	showToolbarText: "Mostrar barra de herramientas de Texto",
	showWhereUsedList: "Mostrar lista Dónde se utiliza",
	showMeMoreInformation_: "Mostrar más información.",
	shutdown: "Apagado",
	side: "Lado",
	side_: "Lado 1",
	signIn: "Iniciar sesión",
	signedIn: "Firmado en",
	signedIn_: "Firmado en.",
	signingInAsGuest_: "Firma en como invitado.",
	sineWave: "Onda sinusoidal",
	siteAltitude: "Sitio de gran altitud",
	siteLineFrequency: "Frecuencia de la línea de sitio",
	siteAltitudeCanNotBeGreaterThan: "Altitud del sitio no puede ser superior a",
	siteAltitudeCanNotBeLessThan: "Altitud del sitio no puede ser inferior a",
	siteAltitudeNotEntered_: "Altitud del sitio no entrado!",
	smallDimensions: "Pequeñas dimensiones",
	softwarePatches: "Parches de Software",
	soldBy: "Vendido por",
	soldByNameChangeRequested_: "Se vende por cambio de nombre solicitado.",
	soldTo: "Vendido",
	soldByInformationIsIncomplete: "Vendido por información es incompleta",
	soldToInformationIsIncomplete: "Vendido a la información es incompleta",
	soldToRegionMustBeTwoCharacterCode_:
		"Vendido a la región debe ser el código de dos caracteres!",
	someDescriptionsHaveNotBeenEntered_:
		"Algunas descripciones no se han introducido!",
	someDesignWindowsAreStillOpen_:
		"Algunas ventanas de diseño están todavía abiertas.",
	someFactoryCallLeadTimeSettingsAreStillNotSet_:
		"Algunas configuraciones de llamadas de fábrica plazo de ejecución aún no se establece!",
	someFilesDidNotTransferToTheServer_:
		"Algunos archivos no se hizo la transferencia al servidor!",
	someFunctionalityMayBeDisabled_: "La funcionalidad puede ser desactivada!",
	someItemsNeedFactoryLeadTimes_PleaseCall_:
		"Algunos artículos de necesidad de los tiempos de fábrica de plomo. Por favor llame.",
	someItemsNeedFactoryPricing_PleaseCall_:
		"Algunos elementos necesitan precios de fábrica. Por favor llame!",
	someOptionsWerePreviouslySetToAnotherValue_:
		"Algunas opciones se establecieron con anterioridad a otro valor.",
	someOrdersNotSaved_: "Algunas órdenes no se guarda!",
	someOrdersWereNotProperlyClosed_: "Algunas órdenes no fueron bien cerradas.",
	someParametersChanged_: "Algunos parámetros cambiado.",
	somePriceChangesOccurred_: "Algunos cambios en los precios se produjo.",
	somePricesOrOptionsMayHaveChanged_:
		"Algunos precios u opciones pueden haber cambiado!",
	somePricesWereUpdated_: "Algunos precios se han actualizado!",
	somePrintFunctionsAreDisabled_:
		"Algunas funciones de impresión están desactivadas.",
	spanish: "Español",
	spareFeeds: "Feeds de repuesto",
	spares: "Repuestos",
	specificHeatAboveFreezing: "Calor específico sobre congelación",
	specificHeatBelowFreezing: "Calor específico bajo cero",
	standard: "Norma",
	standardOrder: "Pedido estándar",
	standardTextFile_AllListedParts_:
		"Estándar de archivo de texto - Todos los elementos de publicación.",
	standardTextFile_SelectedPartsOnly_:
		"archivo de texto estándar - sólo piezas seleccionadas.",
	startNROES: "Inicio NROES",
	startUpOptions: "Opciones de inicio",
	startShellWhenILogInToWindows: "Iniciar el shell al iniciar sesión windows",
	starting: "A partir",
	startingTools: "Herramientas de partida",
	state: "Estado",
	status: "Condición Jurídica y Social",
	status_: "Estado:",
	statusStrip: "Statusstrip",
	stockingModelOrder: "Orden del modelo de siembra",
	storedOrderIsMoreRecentThanRecoveryData_:
		"Para almacenados es más reciente que la recuperación de datos.",
	stripCurtains: "Cortinas de la tira",
	style: "Estilo",
	sub_Total: "Subtotal",
	subject: "Asunto",
	submitChangeOrderNow: "Envíe el Cambio pedido ahora",
	submitChangeOrderRequestToManufacturer:
		"Presentar solicitud de cambio de orden a un fabricante",
	submitChangeRequestToPurchaser:
		"Presentar solicitud de cambio para el comprador",
	submitNotes: "Presentar Notas",
	submitOrder: "Enviar pedido",
	submitOrderNow: "Envíe su pedido ahora",
	submitted: "Enviado",
	submittedOrdersMayNotBeEditedInOff_lineMode_:
		"pedidos enviados no pueden ser editadas en el modo fuera de línea.",
	success_: "Éxito!",
	suction: "Aspiración",
	suctionTemp: "Temperatura de aspiración",
	suctionTemp_S_S_T__: "Temperatura de aspiración (SST)",
	suctionTemperatureCanNotBeGreaterThan:
		"Temperatura de aspiración no puede ser mayor que",
	suctionTemperatureCanNotBeLessThan:
		"Temperatura de aspiración no puede ser inferior a",
	suctionTemperatureIsGreaterThanCondensingTemperature_:
		"Temperatura de succión es mayor que la temperatura de condensación.",
	suctionTemperatureIsMissing_: "Temperatura de aspiración es falta.",
	suctionTemperatureIsRequired_: "Temperatura de succión es necesaria.",
	suitableModels: "Modelos adecuados",
	switchPriceBook: "Interruptor PriceBook",
	synchronizingOrder_: "Sincronización de la Orden:",
	syncronizingContacts: "Sincronizar contactos",
	system: "Sistema de",
	systemLoad: "Sistema de carga",
	systemName: "Sistema de Nombres de",
	systemNetPrice: "Sistema de precios netos",
	systemNotes: "Sistema de Notas",
	systemRun_Time: "Sistema Run-Time",
	systemTagging: "Sistema de etiquetado",
	systemWeight: "Peso del sistema",
	systemLoadIsMissing_: "Carga del sistema es falta!",
	systemOptionIsNoLongerAvailable_: "Opción de sistema ya no está disponible!",
	systemReturned_: "Devuelve el sistema.",
	systemsOnThisOrderContainErrors_: "Sistemas de este fin de contener errores.",
	systemsOnThisOrderContainWarnings_:
		"Sistemas de este fin de contener las advertencias.",
	t_D_: "TD",
	t_D_Error: "TD Error",
	t_H_R: "THR",
	tD: "TD",
	tDCanNotBeCalculated_: "No se puede calcular el TD.",
	tDCanNotBeGreaterThan: "TD no puede ser mayor que",
	tDCanNotBeLessThan: "TD no puede ser inferior a",
	tDOutOfEnteredToleranceRange_:
		"TD fuera del rango de tolerancia introducido.",
	tDOutOfMaximumRange_: "TD fuera del alcance máximo.",
	tERMS_CONDITIONSANDREMARKS: "TÉRMINOS Y CONDICIONES Y COMENTARIOS",
	tHEVALUESCONTAINEDHERINAREBASEDONLABORATORYFINDINGSANDTHEINITIALCONDITIONSENTEREDBYTHEUSER_:
		"EL HERIN CONTENIDOS DE VALORES SE BASAN EN RESULTADOS DE LABORATORIO Y LAS CONDICIONES INICIALES INTRODUCIDAS POR EL USUARIO.",
	tHR: "THR",
	tHRCanNotBeCalculated_: "No se puede calcular la THR.",
	tOTAL: "TOTAL",
	tOTALINFILTRATIONLOAD: "CARGA TOTAL DE INFILTRACIÓN",
	tOTALLISTPRICE: "LISTA DE PRECIOS TOTAL",
	tOTALNET: "NET TOTAL",
	tOTALQUOTEPRICE: "PRECIO TOTAL PRESUPUESTO",
	tYP: "TIP",
	tYPE: "TIPO",
	tabSeparatedTextFiles: "Ficha Archivos de texto separados",
	tagging: "Etiquetado",
	taggingID: "Etiquetado de identificación",
	taggingID_: "Etiquetado ID #",
	taggingText: "Etiquetado de texto",
	takeOwnership: "Tomar posesión",
	targetPriceAccordingToAdjustmentAndRoundingFactors_:
		"Precio objetivo de acuerdo a los factores de ajuste y el redondeo.",
	tax: "Impuestos",
	taxes: "Impuestos",
	temperature: "Temperatura",
	temperatures: "Temperaturas",
	terminationOptions: "Opciones de terminación",
	terms: "Condiciones",
	test: "Prueba",
	textBox: "Cuadro De Texto",
	theInternalRelativeHumidityNotEntered_: "La higrometría interna no entrada!",
	theMaterialLoadHasNotBeenCalculatedOrEntered_:
		"La carga de Material no ha sido calculada o entrado!",
	theMaterialMaterialNameIsNotEntered_:
		"No se especifica el nombre del material Material!",
	theMaterialMaterialTypeIsNotSelected_:
		"No se ha seleccionado el tipo de material Material!",
	theOriginatingCustomersInformationIsAlreadyListedInYourContactDatabase_:
		"La información procedente de clientes ya está en la base de datos de contacto.",
	theR_ValueHasNotBeenEntered_: "No ha entrado el valor-r!",
	theActualCondensingTemperatureCouldNotBeCalculated_:
		"No pudo calcularse la temperatura de condensación real!",
	theAmbientTemperatureIsNotEntered_: "No se ha introducido la temperatura!",
	theApplicationCouldNotMoveFile_BootStrap_exeFromTheUpdatesFolderToTheApplicationFolder_:
		"La aplicación no se podía mover BootStrap.exe archivo 'de la carpeta de actualizaciones a la carpeta de la aplicación.",
	theApplicationCouldNotRemoveFile_BootStrap_exe_FromTheApplicationFolder_:
		'La aplicación no se pudo eliminar "BootStrap.exe" archivo de la carpeta de la aplicación.',
	theApplicationCouldNotSpawn_BootStrap_exe_InTheApplicationFolder_:
		"La aplicación no podría generar \"BootStrap.exe 'en la carpeta de la aplicación.",
	theAreaHasNotBeenCalculatedOrEntered_:
		"La zona no ha sido calculada o entrado!",
	theAreaIsGreaterThanTheSectionArea_:
		"El área es mayor que el área de la sección!",
	theBoxTypeMustBeSelected_: "Se debe seleccionar el tipo de caja!",
	theCentreDistanceIsNotEntered_: "No se especifica la distancia de centro!",
	theChangeCanNotBeProcessedUntilYouCloseTheWorkbookInThisApplication_:
		"El cambio no puede ser procesada hasta que cierre el libro en esta aplicación.",
	theConstructionK_FactorIsNotCalculatedOrEntered_:
		"La construcción, el Factor K es calculada ni entró!",
	theConstructionR_ValueIsNotCalculatedOrEntered_:
		"La construcción valor-r no calcula ni entrado!",
	theConstructionNameIsNotEntered_:
		"No se ha introducido el nombre de construcción!",
	theConstructionThicknessHasNotBeenEntered_:
		"El grueso de la construcción no ha entrado!",
	theConstructionTypeIsNotSelected_:
		"No se ha seleccionado el tipo de construcción!",
	theCorrectQuoteRevisionIsSelected:
		"La revisión presupuesto correcto se selecciona",
	theCurrentPricebookHasBeenSuperseded_:
		"El pricebook actual ha sido reemplazado.",
	theCurrentPricebookHasExpired_: "El pricebook actual ha caducado.",
	theCurrentPricebookNoLongerExists_: "El pricebook actual ya no existe.",
	theCurrentPricebookWillExpireInLessThanAMonth_:
		"El pricebook actual expirará en menos de un mes.",
	theCurrentSettingsWillBeUsedOnAllNewOrdersForThisDivision_:
		"La configuración actual se utilizará en todos los nuevos pedidos de esta división.",
	theCurrentSettingsWillBeUsedOnAllNewOrders_:
		"La configuración actual se utilizará en todos los pedidos nuevos.",
	theDividerMaterialNameIsNotEntered_:
		"No se especifica el nombre del material divisor!",
	theDividerMaterialTypeIsNotSelected_:
		"No se ha seleccionado el tipo de material del divisor!",
	theDividerWidthIsNotEntered_: "No se especifica la anchura del divisor!",
	theEffectiveR_ValueIsNotCalculatedOrEntered_:
		"El valor-r eficaz no es calculado ni entró!",
	theEmailAddressGivenIsNotListed_:
		"La dirección de correo electrónico indicada no aparece.",
	theExternalRelativeHumidityMustBeGreaterThanZero_:
		"La humedad relativa externa debe ser mayor que cero!",
	theExternalRelativeHumidityMustBeLessThan____:
		"La humedad relativa externa debe ser menos de 100!",
	theExternalRelativeHumidityNotEntered_: "La higrometría externa no entrada!",
	theExternalTemperatureIsLowerThanTheInternalTemperature_:
		"La temperatura exterior es menor que la temperatura interna!",
	theExternalTemperatureIsNotEntered_:
		"No se ha introducido la temperatura exterior!",
	theExternalTemperatureIsTooHigh_:
		"La temperatura exterior es demasiado alta!",
	theExternalTemperatureIsTooLow_: "La temperatura exterior es muy baja!",
	theExternalTemperatureNotEntered_: "La temperatura exterior no entrada!",
	theFactoryInvoiceRecipientIsCorrect:
		"El destinatario de la factura de fábrica es correcta",
	theFactoryInvoiceWillBeSentTo: "La factura de fábrica será enviado a",
	theFactoryIsNotShippingOnTheSelectedDate_:
		"La fábrica no es el envío en la fecha seleccionada.",
	theFloorAreaIsNotCalculatedOrEntered_:
		"La superficie no es calculada ni entró!",
	theFollowingChangesHaveBeenVerified:
		"Los siguientes cambios han sido verificadas",
	theFollowingOptionIsNotAllowedWithThisPackage_:
		"La siguiente opción no está permitido en este paquete.",
	theFollowingOrdersCouldNotBeMarkedAsClosed_:
		"Las órdenes siguientes no se pudieron marcar como cerrado.",
	theFollowingOrdersCouldNotBeMarkedAsShipped_:
		"Las siguientes órdenes no podían ser marcados de fábrica.",
	theFollowingOrdersCouldNotBeSynchronized_:
		"Las órdenes siguientes no se pudieron sincronizar!",
	theInfiltrationLoadHasNotBeenCalculatedOrEntered_:
		"La carga de la infiltración no ha sido calculada o entrado!",
	theInformationInTheStoreIsOlderThanTheSelectedFile_:
		"La información de la tienda es más antiguo que el archivo seleccionado.",
	theInternalTemperatureIsNotEntered_:
		"No se ha introducido la temperatura interna!",
	theItemOrSomeOptionsNeedFactoryLeadTimes_PleaseCall_:
		"El elemento o algunas opciones necesitan plazos de fábrica. Por favor llame!",
	theLayerThicknessIsNotCalculatedOrEntered_:
		"El espesor de la capa no es calculado o entró!",
	theLayerThicknessIsNotEntered_: "No se ha introducido el grueso de la capa!",
	theLoadIsNotCalculatedOrEntered_: "La carga no es calculada ni entró!",
	theOpeinngWidthIsGreaterThanTheSectionWidth_:
		"El ancho de opeinng es mayor que el ancho de la sección!",
	theOpeningHeightHasNotBeenEntered_: "La altura de la abertura no ha entrado!",
	theOpeningHeightIsGreaterThanTheSectionHeight_:
		"La altura de la abertura es mayor que la altura de la sección!",
	theOpeningTypeIsNotSelected_: "No se ha seleccionado el tipo de apertura!",
	theOpeningWidthHasNotBeenEntered_: "El ancho del abertura no ha entrado!",
	theOrderCanNotBeProcessedUntilYouCloseTheWorkbookInThisApplication_:
		"El orden no puede ser procesada hasta que cierre el libro en esta aplicación.",
	theOrderFileAppearsCorrupt_: "El archivo de orden aparece corrupto.",
	theOrderIsAlreadyListedInTheLocalOrderDatabase_:
		"El orden ya está incluida en la base de datos de orden local.",
	theOrderIsLockedByAnotherUser_: "El orden está bloqueado por otro usuario.",
	theOrderWindowWillUpdateWhenYouCloseTheServicePartsWindow_:
		"La ventana de órdenes se actualizará cuando se cierra la ventana de partes de servicio.",
	theOriginalOrderHasNotBeenProcessedYet_:
		"El pedido original no ha sido procesado aún.",
	thePerimeterLengthMustBeEnteredForSlabOnGradeFloors_:
		"La longitud del perímetro debe introducirse para losa en plantas de grado!",
	thePowerUnitsAreNotEntered_: "No se introducen las unidades de energía!",
	thePriceBookSelectedForThisOrderHasBeenUpdated_:
		"Se ha actualizado la lista de precios seleccionada para este pedido!",
	thePriceBookUsedForTheOpenChangeRequestHasBeenRevised_:
		"El precio de los libros utilizados para la solicitud de cambio libre ha sido revisado.",
	thePriceBookUsedForTheOpenChangeRequestHasBeenSuperseded_:
		"El precio de los libros utilizados para la solicitud de cambio libre ha sido sustituida.",
	thePriceBookUsedForTheOpenChangeRequestIsExpiring_:
		"El precio de los libros utilizados para la solicitud de cambio abierta va a expirar.",
	thePriceBookUsedForThisOrderHasBeenRevised_:
		"El libro de precios utilizado para esta orden ha sido revisado.",
	thePriceBookUsedForThisOrderHasBeenSuperseded_:
		"El libro de precios utilizado para esta orden ha sido superado.",
	thePriceBookUsedForThisOrderIsExpiring_:
		"El precio de los libros utilizados para este fin va a expirar.",
	thePriceBookUsedOnOneQuoteHasBeenRevised_:
		"El precio de los libros utilizados en una cita ha sido revisado.",
	thePriceBookUsedOnOneQuoteHasBeenSuperseded_:
		"El precio de los libros utilizados en una cita ha sido reemplazado.",
	thePriceBookUsedOnOneQuoteHasExpired_:
		"El precio de los libros utilizados en una cita ha caducado.",
	thePriceBookUsedOnOneQuoteIsMissing_:
		"El precio de los libros utilizados en una cita que falta.",
	thePriceBookWasUpdated_: "El precio de los libros ha sido actualizado!",
	thePriceBooksUsedForThisOrderAreExpiring_:
		"Los libros de precio utilizado para este fin se terminaría.",
	thePriceBooksUsedForThisOrderHaveBeenRevised_:
		"Los libros de precio utilizado para este fin se han revisado.",
	thePriceBooksUsedForThisOrderHaveBeenSuperseded_:
		"Los libros de precio utilizado para este fin, han sido sustituidos.",
	thePriceBooksUsedOn: "El precio de los libros utilizados en",
	thePriceBooksUsedOnOneQuoteIsExpiring_:
		"El precio de los libros utilizados en una cita es que expira.",
	theProductFreezingTemperatureIsNotEntered_:
		"No se introduce el producto a temperaturas de congelamiento.",
	theProductIsEnteringAtTheInternalTemperature_TheFinalTemperatureMustAlsoBeTheInternalTemperature_:
		"El producto está entrando en la temperatura interna, la temperatura final debe ser también la temperatura interna!",
	theProductTypeIsNotSelected_: "No se ha seleccionado el tipo de producto!",
	theProduct_sEnteringTemperatureIsNotSet_:
		"Temperatura de entrada del producto no ha sido programado!",
	theProduct_sFinalTemperatureCanNotBeGreaterThanTheEnteringTemperature_:
		"Temperatura final del producto no puede ser mayor que la temperatura de entrada!",
	theProduct_sFinalTemperatureCanNotBeLowerThanTheEnteringTemperature_:
		"Temperatura final del producto no puede ser inferior a la temperatura de entrada!",
	theProduct_sFinalTemperatureCanNotBePulledHigherThanTheInternalTemperature_:
		"Temperatura final del producto no puede tirar más alto que la temperatura interna!",
	theProduct_sFinalTemperatureCanNotBePulledLowerThanTheInternalTemperature_:
		"Temperatura final del producto no puede tirar más baja que la temperatura interna!",
	theProduct_sFinalTemperatureIsNotSet_:
		"No se ha establecido la temperatura final del producto.",
	theProduct_sLoadFactorIsNotSet_:
		"No se establece el factor de carga del producto!",
	theProduct_sLoadFactorShouldBeOneUnlessRapidCoolingIsRequired__:
		"Factor de carga del producto debe ser uno a menos que el enfriamiento rápido se requiere. !",
	theProduct_sLoadIsNotCalculatedNotEntered_:
		"Carga del producto no se calcula no entró!",
	theProduct_sMoistureContentIsNotEntered_:
		"No se especifica el contenido de humedad del producto!",
	theProduct_sPullDownTimeIsNotSet_:
		"No se ha establecido la extracción del producto tiempo!",
	theProduct_sSpecificHeatAboveFreezingIsNotEntered_:
		"Calor específico del producto por encima de la congelación no se entra!",
	theProduct_sSpecificHeatBelowFreezingIsNotEntered_:
		"No se introduce calor del específico del producto bajo cero!",
	theProduct_sWeightIsNotEntered_: "Peso del producto no se entra!",
	theProgramStoreIsNewerThanTheFileYouSelected_:
		"El almacén de programa es más reciente que el archivo que ha seleccionado!",
	theQuoteHasBeenSignedByTheCustomer: "La cita ha sido firmado por el cliente",
	theRunTimeIsNotEntered_: "No se especifica el tiempo de ejecución.",
	theSelectedChangeOrderHasBeenProcessedByOurOrderDepartment_:
		"La orden de cambio seleccionada ha sido procesado por nuestro Departamento de Pedidos.",
	theSelectedChangeOrderHasBeenProcessedByThePurchaser_:
		"La orden de cambio seleccionada ha sido procesado por el comprador.",
	theSelectedOrderHasBeenProcessedByOurOrderDepartment_:
		"El orden elegido ha sido procesado por nuestro Departamento de Pedidos.",
	theSelectedOrderHasBeenProcessedByThePurchaser_:
		"El orden elegido ha sido procesado por el comprador.",
	theSelectedOrderIsOpenInAnotherSessionOfNROES_:
		"El orden elegido está abierto en otra sesión de NROES.",
	theSelectedRefrigerantIsNotAvailableOnThisUnit_:
		"El refrigerante seleccionado no está disponible en esta unidad!",
	theServerReturnedTheFollowing_: "El servidor devolvió el siguiente.",
	theSystemReportedError: "El sistema ha notificado el error",
	theSystemReportedTheFollowingError_: "El sistema arrojó el siguiente error.",
	theTotalInfiltrationLoadIsNotCalculatedOrEntered_:
		"La carga total de infiltración no es calculada ni entró!",
	theTotalVolumeIsNotCalculatedOrEntered_:
		"El volumen total no es calculado ni entró!",
	theUserNameYouEnteredIsNotRegisteredOnThisWorkstation_:
		"El nombre de usuario que ha introducido no está registrado en esta estación de trabajo!",
	theValueWillBeAddedOrSubtractedFromTheCurrentPrice_:
		"El valor se suma o se resta del precio actual.",
	thenClickOnSaveAgain_: "Luego haga clic en guardar de nuevo.",
	thereAreNoItemsOnThisOrder_: "No hay elementos en este orden!",
	thereAreNoOptionsAvailableForThisUnit_:
		"No hay opciones disponibles para esta unidad!",
	thickness: "Grueso",
	thicknessIsNotEntered_: "No se ha introducido grueso!",
	thirdParty: "Tercera Parte",
	thisMayTakeAFewMinutes_: "Esta operación puede tardar unos minutos.",
	thisModelHasOptionsAttached_: "Este modelo cuenta con opciones instaladas.",
	thisOrderHasBeenPutOnHoldByTheOrderDepartment_:
		"Esta orden ha quedado en suspenso por el departamento de pedidos.",
	thisOrderHasBeenRejectedByTheOrderDepartment_:
		"Este pedido ha sido rechazado por el departamento de pedidos.",
	thisOrderHasPriceBookIssues_:
		"Esta orden tiene problemas de precio de los libros.",
	thisOrderWasNotClosedProperlyTheLastTimeYouOpenedItOnThisWorkstation_:
		"Esta orden no se cerró correctamente la última vez que se abrió en esta estación de trabajo.",
	thisOrderWasSavedUsingAPreviousVersionOfYourMultiplierStructure_:
		"Esta orden se guardó con una versión anterior de la estructura de su multiplicador!",
	thisOrderWasSavedUsingAPreviousVersionOfYourUserCatalogueProfile_:
		"Esta orden se guardó con una versión anterior de su perfil de usuario catálogo!",
	tileHorizontally: "Mosaico horizontal",
	tileVertically: "Mosaico vertical",
	time: "Tiempo",
	title: "Título",
	toNearest: "Para más cercana",
	toNearest_: "Para más cercana 1",
	toNearest_____: "Para más cercana 1,000",
	toNearest__: "Para más cercana 10",
	toNearest______: "Para más cercana 10,000",
	toNearest___: "Para más cercana 100",
	toNearest_______: "Para más cercana 100,000",
	toAssistUs_PleaseProvideAnyFurtherInformationYouMayThinkIsRelevant_:
		"Para ayudarnos, por favor proporcione cualquier información adicional que considere relevante.",
	toChangeThePartQuantities_PleaseEditThemInTheOrderWindow_:
		"Para cambiar las cantidades parte, por favor, editarlos en la ventana de órdenes.",
	tolerance: "Tolerancia",
	tools: "Herramientas",
	total: "Total",
	totalCapacityAtDesignTD: "Capacidad total en diseño TD",
	totalEquipmentPrice: "Precio total del equipo",
	totalHeatRejected: "El calor total rechazado",
	totalHourlyLoad: "Carga horaria total",
	totalLoadRequired: "Carga total requerida",
	totalMiscellaneousLoad: "Carga total de varios",
	totalOpeningInfiltration: "Infiltración de apertura total",
	totalOtherLoads: "Total otras cargas",
	totalPrice: "Precio Total",
	totalProductLoad: "Carga total del producto",
	totalProductWeight: "Peso total del producto",
	totalSystemCapacity: "Capacidad total del sistema",
	totalThickness: "Espesor total",
	totalVolume: "Volumen total",
	totalWallLoad: "Carga total del muro",
	totalDoorWidthExceedsSectionWidth:
		"Anchura total de la puerta supera el ancho de la sección",
	totalHeatRejectedOutOfRange_: "Calor total rechazado fuera de rango.",
	totalOpeningAreaExceedsSectionArea:
		"Área de apertura total excede el área de sección",
	translationsAreUNDERCONSTRUCTIONAndMayBeLiteralByNatureAndMayBeContextuallyInError_:
		"Las traducciones están en construcción y puede ser literal, por naturaleza, y puede ser contextualmente en el error.",
	trentonLoadCalcFiles: "Trenton Cargar archivos Calc",
	trentonRefrigeration: "Refrigeración de Trenton",
	tubeConfiguration: "Configuración de tubo",
	tubeRows: "Filas de tubo",
	tubes: "Tubos",
	tubesPerFeed: "Tubos por Feed",
	tubesPerRow: "Tubos por fila",
	typ: "Typ",
	type: "Tipo",
	u_SHAPED: "U - EN FORMA",
	u_S_: "EE.UU.",
	u_S_English: "EE.UU. Inglés",
	uNIT: "UNIDAD",
	uNITLIST: "UNIDAD DE LA LISTA",
	uNITNET: "UNIDAD NET",
	uNLESSOTHERWISESPECIFIED: "A MENOS QUE SE INDIQUE LO CONTRARIO",
	uNUSEDCONNECTIONS: "CONEXIONES NO UTILIZADAS",
	uPDATED: "ACTUALIZADO",
	uSERINFORMATION: "INFORMACIÓN DE USUARIO",
	uSERPREFERENCES: "PREFERENCIAS DEL USUARIO",
	undo: "Deshacer",
	unit_: "Unidad #",
	unitCooler: "Unidad de refrigerador",
	unitList: "Lista de Unidad",
	unitNet: "Neto de la unidad",
	unitPrice: "Precio unitario",
	unitTotal: "Total de la unidad",
	unitIsNotCompatibleWithSelectedSystemRefrigerant_:
		"Unidad no es compatible con el refrigerante del sistema seleccionado!",
	units: "Unidades",
	unknownReason_: "Razón desconocida.",
	untilTheChangeIsProcessedByTheManufacturer_YouMayStopYourRequest_:
		"Hasta que el cambio es procesado por el fabricante, que puede dejar su solicitud.",
	untilThen_FunctionalityWillBeLimited_:
		"Hasta entonces, funcionalidad será limitado.",
	unusedFeeds: "Sin usar los Feeds",
	upOrDown: "Arriba o hacia abajo",
	updateAllMultipliers: "Actualizar todos los multiplicadores",
	updateAllParameters: "Actualizar todos los parámetros",
	updateAllPrices: "Actualizar todos los precios",
	updateNow: "Actualizar ahora",
	updatePricebook: "Actualizar Pricebook",
	updatePending_: "Actualización pendiente.",
	updateTheCurrentInformation_Or: "Actualización de la información actual. o ",
	updateTheExistingListing_: "Actualización de la lista existente.",
	updatesCanNotBeInstalled_: "Las actualizaciones no se puede instalar.",
	uploadToJOB_SCOPE: "Subir Para TRABAJO-SCOPE",
	uploadingBuffer: "Carga de Buffer",
	uploadingPreviouslySavedFiles: "Subida de archivos guardados previamente",
	uploadingQueuedOrders: "Carga en la cola de pedidos",
	uploadingQueuedOrder_: "Subida de cola Orden:",
	useLogoImageFile: "Uso del Logo de archivo de imagen",
	useMyInformation: "Use mi información",
	useBoxSetting: "Utilice la configuración del cuadro de",
	useMenuFunctionCreateChangeOrderInstead_:
		"Utiliza la función de menú Crear Orden de Cambio en su lugar.",
	useTheHOLDFunctionIfYouExpectTheOriginatorToCorrectTheOrder_:
		"Utilice la función de si espera que el emisor para corregir el orden!",
	useTheSTOPSUBMITTEDCHANGESelectionInTheOrderMenu_:
		"Utilice el botón STOP CAMBIO PRESENTADOS selección en el menú de la Orden.",
	useTheSTOPSUBMITTEDORDERSelectionInTheOrderMenu_:
		"Utilice el botón STOP PRESENTADOS PARA selección en el menú de la Orden.",
	useTheMenuToCreateNewRevision_s_FromYourEditsAsDesired_:
		"Utilice el menú para crear nueva revisión (s) de las modificaciones que desee.",
	userID: "ID de usuario",
	userInformation: "Información de usuario",
	userInformationRequested_: "Usuario información solicitada.",
	userName: "Nombre de usuario",
	userRegistrationInformation: "Información de Registro de Usuario",
	userVerification: "Verificación del usuario",
	userNameRequired_: "Nombre de usuario requerido.",
	userSelectablePriceAdjustmentValue_:
		"El usuario puede seleccionar el valor de ajuste de precios.",
	usernameNotFound_: "Nombre de usuario no se encuentra!",
	usingLocalVersion_: "Al usar la versión local.",
	vWaffle: "Wafles de v",
	vOLTAGE: "TENSIÓN",
	valueRequired_: "Valor requerido!",
	valueMustBeGreaterThanZero_: "Valor debe ser mayor que cero!",
	various: "Varios",
	velocity: "Velocidad",
	ventilationAirRelativeHumidity___:
		"Aire de ventilación de la humedad relativa (%)",
	ventilationAirTemperature: "Temperatura del aire de ventilación",
	ventilationRate: "Tasa de ventilación",
	ventilationAirRelativeHumidityIsNotEntered_:
		"No se ha introducido la higrometría del aire de ventilación!",
	ventilationAirTemperatureIsNotEntered_:
		"No se ha introducido la temperatura del aire de ventilación!",
	ventilationRateIsLessThanTheRecommendedMinimumValue_:
		"Tasa de ventilación es menor que el valor mínimo recomendado!",
	verifyDesign: "Compruebe Diseño",
	version: "Versión",
	version_OfNROESUsesADifferentSaveStrategy_:
		"La versión 8 de NROES utiliza una diferente estrategia de salvar.",
	version_UsesAWorkbookForYourJobInsteadOfMultipleQuoteRevisionFiles_:
		"Versión 8 utiliza un libro por su trabajo en lugar de varios archivos citan revisión.",
	vertical: "Vertical",
	via: "A través de",
	view_ArchivedFiles: "Vista & archivos archivados",
	viewCSV: "Ver CSV",
	visitUsOn_Line: "Visítenos en línea",
	voltage: "Voltaje",
	volume: "Volumen",
	wAITINGFORSERVICEPARTSCATALOGUETOLOAD:
		"EN ESPERA DE CATÁLOGO DE SERVICIOS DE PIEZAS PARA CARGAR",
	wARNING: "ADVERTENCIA",
	wARNING_: "ADVERTENCIA!",
	wARNINGS: "ADVERTENCIAS",
	wATTS: "VATIOS",
	wO: "WO",
	wORKORDER: "ORDEN DE TRABAJO",
	waitingForShellToComeReady: "Esperando Shell venir listo",
	waitingForShellToComeReadyForFirstTime:
		"Esperando Shell venir preparado por primera vez",
	waitingForShellToStart: "Esperando Shell inicio",
	waitingForANewPassword_: "En espera de una nueva contraseña.",
	waitingForRegistrationKey_: "¡Lugar para una clave de registro.",
	waitingForShell_: "A la espera de shell.",
	waitingForSign_in: "Esperando el inicio de sesión en",
	wall: "Pared",
	wallLoad: "Carga de la pared",
	wallSection: "Sección de pared",
	wallsCross_PleaseCheckYourDimensions_:
		"Paredes cruzan! Por favor revise sus dimensiones.",
	warning: "Advertencia",
	warning_PriceBookHasExpired_: "Advertencia - lista de precios ha caducado!",
	warningOppositeEndConnections_DimensionDrawingWillOnlyShowSameEndHeaders:
		"ADVERTENCIA conexiones de extremo opuesto. Dimensión de dibujo sólo mostrará el mismo encabezados de final",
	warning_: "¡Atención!",
	warnings: "Advertencias",
	waterCooledCondensingUnit: "Unidad condensadora refrigerado por agua",
	webMessagesAndBulletins: "Web de los mensajes y boletines",
	weight: "Peso",
	whatToPrint: "¿Qué impresión",
	whatDoYouWantToDo_: "¿Qué quieres hacer?",
	whatTypeOfFileDoYouRequire_: "¿Qué tipo de archivo que usted requiere?",
	whatTypeOfOrderDoYouWishToCreate_: "¿Qué tipo de orden se desea crear.",
	whereUsed: "Utilizaciones",
	whereUsed_: "Dónde se utiliza:",
	wholesale: "Venta al por mayor",
	width: "Ancho",
	window: "Ventana",
	with: "Con",
	withTheInternetConnected_: "Con el Internet conectado.",
	workHours: "Horas de trabajo",
	workOrder: "Orden de Trabajo",
	workingDays: "Días laborables",
	workingOn_Line: "Trabajo en línea",
	wouldYouLikeToDo_: "¿Te gustaría hacer?",
	wrapSystemBar: "Envuelva barra del sistema",
	yes: "Sí",
	yesPlease: "Sí, por favor",
	yes_ChangeTheModelToTheOneISelected_:
		"Sí, cambiar el modelo al que he seleccionado.",
	yes_IAmARegisteredUser_: "Sí, soy un usuario registrado.",
	yes_SetOptionsToTheNewMultiplier_:
		"Sí, establecer opciones para el nuevo multiplicador.",
	yes_SetTheValueIEntered_: "Sí, establezca el valor entré.",
	yes__SomeFunctionalityWillBeDisabled_:
		"Sí. (Parte de la funcionalidad se puede desactivar)",
	youAreNotTheOriginalOwnerOfThisOrder_:
		"Usted no es el propietario original de la presente orden.",
	youAreProhibitedFromSettingTheMultiplierThisLow_:
		"Está prohibido fijar el multiplicador de esta baja.",
	youAreWorkingOffline_: "Usted está trabajando fuera de línea!",
	youCanNoLongerStopThisChangeRequest_:
		"Ya no se puede poner fin a esta solicitud de cambio.",
	youCanNoLongerStopThisOrder_: "Ya no se puede poner fin a esta solicitud.",
	youCanNoLongerStopThisPurchaseRequest_:
		"Ya no se puede poner fin a esta solicitud de compra.",
	youCanNotDeleteTheLastSystemOnAnQuote_:
		"No se puede eliminar el sistema por última vez una cita?",
	youCanNotDeleteThisOrderFromTheServer_:
		"No se puede eliminar este orden desde el servidor.",
	youCanNotEditTheDoorInformationAtThisTime_:
		"No se puede editar la información de la puerta en este momento!",
	youCanNotEditTheSectionInformationAtThisTime_:
		"No se puede editar la información de la sección en este momento!",
	youCanNotEditThisOrderAtThisTime_:
		"Usted no puede editar este orden en este momento.",
	youCanNotRejectAnOrderUsingNROESOnceItHasBeenSubmittedToJob_Scope_:
		"No se puede rechazar una orden usando NROES una vez que se ha presentado a Job-Ámbito de aplicación.",
	youChangedTheCondensingUnitModelNumber_:
		"Se cambió el número de modelo de unidad condensadora!",
	youChangedTheModelNumber_: "Ha cambiado el número de modelo!",
	youDoNotCurrentlyHaveRightsToThisTool_:
		"Actualmente no tienes derechos a esta herramienta.",
	youDoNotHaveTheOriginalPriceBookLoaded_:
		"Usted no tiene la lista de precios original cargado.",
	youDoNotHaveTheRequiredPriceBookForTheOpenChangeRequest_:
		"Usted no tiene el precio de los libros necesarios para la solicitud de cambio abierta.",
	youDoNotHaveTheRequiredPriceBookForThisOrder_:
		"No tienes el libro de precios necesario para este pedido!",
	youDoNotHaveTheRequiredPriceBooksForThisOrder_:
		"Usted no tiene precio de los libros necesarios para este fin.",
	youHaveFailedToReceiveACatalogueDataUpdateForMoreThan__Days_:
		"Que no han podido recibir una actualización de datos del catálogo de más de 30 días!",
	youHaveFailedToReceiveAProgramUpdateForMoreThan__Days_:
		"Que no han podido recibir una actualización del programa por más de 30 días!",
	youHaveNotBeenAssignedAChannelForDirectEntryToTheAS_____:
		"No ha sido asignado un canal para la entrada directa al AS/400.",
	youHaveNotConfirmedYourEmailAddressYet_:
		"Usted no ha confirmado aún su dirección de correo electrónico!",
	youHaveNotReceivedACatalogueDataUpdateForMoreThan__Days_:
		"Usted no ha recibido una actualización de catálogo de datos de más de 15 días!",
	youHaveNotReceivedAProgramUpdateForMoreThan__Days_:
		"Usted no ha recibido una actualización del programa por más de 15 días!",
	youHaveNotStartedTheApplicationOn_lineForMoreThan__Days_:
		"No ha iniciado la aplicación en línea para más de 15 días!",
	youMayCreateANewChangeOrder_OrContactUsByTelephone_:
		"Usted puede crear un orden nuevo cambio, o póngase en contacto con nosotros por teléfono.",
	youMayCreateANewChangeOrder_OrContactYourPurchaser_:
		"Usted puede crear un orden nuevo cambio, o en contacto con su comprador.",
	youMayCreateANewOrderAndCorrectTheListedIssues_:
		"Usted puede crear un nuevo orden y corregir los problemas enumerados.",
	youMayEditThisOrderOff_line_:
		"Usted puede modificar este orden fuera de línea.",
	youMayEnterAPositiveOrNegativeValueHere_:
		"Usted puede entrar en un valor positivo o negativo aquí.",
	youMayEnterAValueHereToRoundToAValueOtherThanThatEndingInAZero_:
		"Puede introducir un valor aquí para redondear a un valor distinto de aquel que terminan en cero.",
	youMayHaveTheOrderOpenInAnotherApplication_:
		"Usted puede tener el fin de abrir en otra aplicación.",
	youMayNotCopyThisOrder_: "Usted no puede copiar este orden!",
	youMayNowEditYourData_: "Ahora puede modificar sus datos.",
	youMayPreferToSimplyDeselectTheSpecificOptionsYouDoNotRequire_:
		"Es posible que prefiera para anular la selección simplemente las opciones específicas que no requieren.",
	youMaySelectRoundingOptionsHere_:
		"Usted puede seleccionar opciones de redondeo aquí.",
	youMayTakeOwnershipOfThisOrderUsingTheOrderMenu_:
		"Usted puede tomar la propiedad de esta orden mediante el menú de orden.",
	youMayThenEditTheServicePartQuantitiesInTheOrderItself_:
		"A continuación, puede modificar las cantidades parte de servicio en la propia solicitud.",
	youMayWorkOff_lineIfYouWish_:
		"Usted puede trabajar fuera de línea si lo desea.",
	youMustBeConnectedToTheInternetToUseThisTool_:
		"Debe estar conectado a internet para utilizar esta herramienta.",
	youRequestedIDeleteASystemFromTheOrder_:
		"Usted pidió que eliminar un sistema de la orden.",
	youShouldCloseThisOrderAndTryToReopenItForSynchronizationOptions_:
		"Usted debe cerrar esta orden y tratar de volver a abrirla para conocer las opciones de sincronización.",
	youShouldReceiveAnE_mailShortly_: "Pronto recibirás un correo electrónico.",
	youShouldReceiveMoreInformationWithinTheNextFewWorkingDays_:
		"Usted debe recibir más información dentro de los próximos días trabajo.",
	youShouldReceiveYourCodeShortlyAtTheFollowingE_mailAddress_:
		"Usted debe recibir su código de pronto en la siguiente dirección de correo electrónico.",
	youShouldReceiveYourLoginInformationViaE_mailShortly_:
		"Pronto recibirá su información de inicio de sesión por correo electrónico.",
	youShouldStopTheRequest_CorrectTheIssuesListedBelow_AndResubmit_:
		"Usted debe dejar la solicitud, corregir los problemas enumerados a continuación, y vuelva a enviar.",
	youWillBeTheOnlyOneAbleToMakeChanges_:
		"Usted será el único capaz de hacer cambios.",
	yourAuthorizationHasBeenRecinded_: "Su autorización ha sido recinded.",
	yourAuthorizationIsNowUnderReview_:
		"Su autorización está ahora bajo revisión.",
	yourAuthorizationIsStillUnderReview_:
		"Su autorización todavía está bajo revisión.",
	yourChangeRequestWasSubmitted_: "Su solicitud de cambio se presentó.",
	yourDataContainsWarnings_: "Los datos contienen advertencias.",
	yourNeedToBeOn_lineToCompleteTheRegistrationProcess_:
		"Su necesidad de estar on-line para completar el proceso de registro.",
	yourNewChangeRequestWillReplaceThePreviousOne_AfterYouCompleteYourChangesAndRe_submit_:
		"Su solicitud de cambio de nueva sustituirá a la anterior, después de completar los cambios y volver a presentar.",
	yourNewOrderWillReplaceThePreviousOne_AfterYouCompleteYourChangesAndRe_submit_:
		"Su nuevo orden sustituirá a la anterior, después de completar los cambios y volver a presentar.",
	yourNewRequestWillReplaceThePreviousOne_AfterYouCompleteYourChangesAndRe_submit_:
		"Su solicitud nueva sustituirá a la anterior, después de completar los cambios y volver a presentar.",
	yourOrderWasSubmitted_: "Su pedido fue presentado.",
	yourPasswordHasBeenSuccessfullyUpdated:
		"Su contraseña se ha actualizado correctamente",
	yourPreviouslySubmittedChangeOrderHasBeenStopped_:
		"Su pedido presentado previamente el cambio se ha parado!",
	yourPreviouslySubmittedChangeRequestHasBeenStopped_:
		"Su solicitud presentada anteriormente cambio se ha parado!",
	yourPreviouslySubmittedOrderHasBeenStopped_:
		"Su solicitud presentada anteriormente se ha parado!",
	yourPreviouslySubmittedRequestHasBeenStopped_:
		"Su solicitud presentada anteriormente se ha parado!",
	yourPurchaserHasBegunWorkOnYourOrderRequest_:
		"Su comprador ha comenzado a trabajar en su solicitud de pedido.",
	yourRequestCouldNotBeRetractedAtThisTime_:
		"Su solicitud no puede ser contraído en este momento!",
	yourSoldByRegionMustBeTwoCharacterCode_:
		"Su vendidos por región debe ser el código de dos caracteres!",
	yourUserAccountHasBeenRejected_: "Su cuenta de usuario ha sido rechazada.",
	yourUserAccountIsCurrentlyDisabled_:
		"Su cuenta de usuario está actualmente desactivada!",
	yourUserProfileHasBeenSuccessfullyUpdated_:
		"Su perfil de usuario se ha actualizado correctamente!",
	yourVendorAccountIsCurrentlyDisabled_:
		"Tu cuenta de vendedor está actualmente desactivado!",
	zip_PostalCode: "Zip / Postal Código",
	zipCode: "Código postal",
	zoneBoundary: "Límite de la zona",
	zoneBoundary_TD___: "Límite de la zona (TD = 0 )",
	asNewRevision_AndRestore: "como nueva revisión, y restaurar",
	asNewRevision_: "como nueva revisión.",
	atFixtureRating: "en la calificación de accesorio",
	confirmYourRequestByClickingOnTheOKButton_:
		"confirmar tu solicitud haciendo clic en el botón Aceptar.",
	e_g_ToRoundTo______SetRoundingToNearest____AndEnter__Here_:
		"Para redondear por ejemplo, a 99.99, juego de redondeo a pie hasta la 100, y entre -5 aquí.",
	equipmentChangesFound_: "cambios en el equipo que se encuentran.",
	feedIsNotUsed_: "no se utiliza la alimentación!",
	feedsAreNotUsed_: "no se utilizan alimentos!",
	freightTerms: "términos de carga",
	frmOptions: "frmOptions",
	haveBeenRevised_: "se han revisado.",
	haveBeenSuperseded_: "han sido sustituidos.",
	lblBOLD: "lblBOLD",
	orQuantity: "o cantidad",
	orR_Value_UnitThickness: "o valor r / grueso de la unidad",
	orTotalLightingPower: "o potencia de iluminación Total",
	pricesChanged_: "precios cambiado.",
	quotesAreExpiring_: "las citas son de expirar.",
	quotesAreMissing_: "citas que faltan.",
	quotesHaveExpired_: "cotizaciones han expirado.",
	regularShipDateOfTheSelectedItem_:
		"fecha de envío regular del elemento seleccionado.",
	revisionsUpdated_: "revisiones actualizadas.",
	revisions_: "revisiones.",
	toLastSavedVersion_: "a la última versión guardada.",
	youCanNoLongerCreateQuoteRevisionsDuringTheSaveOperation_:
		"ya no se puede crear revisiones citan durante la operación de salvar.",
	_LessOptions_: "( Opciones menos )",
	airCooledCondenserDesigner: "Diseñador de condensador refrigerado por aire",
	approvalOfThisDrawingSignifiesThatTheEquipmentIsAcceptableUnderTheProvisionOfTheJobSpecifications_AnyChangeMadeHereonByAnyPersonWhomsoeverSubjectToAcceptanceByNATIONALREFRIGERATIONAtItsHomeOffice_:
		"Aprobación de este dibujo significa que el equipo sea aceptable bajo la provisión de las especificaciones del trabajo. Cualquier cambio realizado en por cualquier persona quien sujeto a la aceptación por refrigeración nacional en su oficina.",
	aPPROVALS: "APROBACIONES",
	aPPROVEDBY_: "APROBADO POR: ",
	cARRIERPREFERENCE: "PREFERENCIA DE PORTADOR",
	certifiedPrintMaker: "Máquina de impresión de certificados",
	cHANGEORDERREQUESTFORM: "FORMULARIO DE SOLICITUD DE ORDEN DE CAMBIO",
	cUSTOMERORDER__: "CLIENTE PEDIDO #:",
	dATE_: "FECHA:",
	dimA: "Dim Un",
	dimB: "Dim B",
	dimC: "Dim C",
	dimD: "Dim D",
	dIMENSIONA: "A LA DIMENSIÓN",
	dIMENSIONB: "DIMENSIÓN B",
	dIMENSIONC: "DIMENSIÓN C",
	dIMENSIOND: "DIMENSIÓN D",
	dIMENSIONE: "DIMENSIÓN E",
	dIMENSIONF: "DIMENSIÓN F",
	dIMENSIONG: "DIMENSIÓN G",
	dIMENSIONH: "DIMENSIÓN H",
	dIMENSIONS: "DIMENSIONES",
	dimensionsShownAreForStandardUnitLessOptions_:
		"Las dimensiones indicadas son por unidad estándar menos opciones.",
	dimensionsShownAreForStandardUnitLessOptions_SeeCertifiedDrawingForMoreDetails_:
		"Las dimensiones indicadas son por unidad estándar menos opciones. Ver dibujo certificada para más detalles.",
	dISCHARGE: "DESCARGA",
	documentBrowser: "Documento navegador",
	dRAIN: "DESAGÜE",
	eLECTRONICORDERFORM: "FORMULARIO DE PEDIDO ELECTRÓNICO",
	eLECTRONICORDERFORM_CONTINUED:
		"FORMULARIO DE PEDIDO ELECTRÓNICO - CONTINUADA",
	eQUIPMENTSUMMARY: "RESUMEN DE EQUIPO",
	eSTSHIPWEIGHT_LessOptions_: "EST nave peso (menos opciones)",
	eSTSHIPWEIGHT_: "PESO DE LA NAVE EST:",
	fACTORYINVOICETO_: "FACTURA DE FÁBRICA PARA:",
	fACTORYORDER__: "FÁBRICA PEDIDO #:",
	fREIGHT: "TRANSPORTE DE MERCANCÍAS",
	fREIGHTTERMS_: "CONDICIONES DE CARGA:",
	hOTGASINLET: "ENTRADA DE GAS CALIENTE",
	hOTGASOUTLET: "SALIDA DE GAS CALIENTE",
	hOTGASSIDEPORT: "PUERTO DE LADO DE GAS CALIENTE",
	iD__: "ID #:",
	iTEM__: "ARTÍCULO #:",
	lbs_LessOptions_: "Lbs (menos opciones )",
	lead: "Plomo",
	list: "Lista",
	mANUALENTRY: "ENTRADA MANUAL",
	mANUALOPTION: "OPCIÓN MANUAL",
	mult: "Mult",
	nATIONALREFRIGERATIONWillFurnishEquipmentInAccordanceWithThisDrawingAndSpecifications_AndSubjectToItsPublishedWarranty_ApprovalOfThisDrawingSignifiesThatTheEquipmentIsAcceptableUnderTheProvisionOfTheJobSpecifications_AnyChangeMadeHereonByAnyPersonWhomsoeverSubjectToAcceptanceByNATIONALREFRIGERATIONAtItsHomeOffice_:
		"REFRIGERACIÓN nacional proporcionará equipo de conformidad con este dibujo y especificaciones y conforme a su garantía publicado. Aprobación de este dibujo significa que el equipo sea aceptable bajo la provisión de las especificaciones del trabajo. Cualquier cambio realizado en por cualquier persona quien sujeto a la aceptación por refrigeración nacional en su oficina.",
	nEWSYSTEM: "NUEVO SISTEMA DE",
	nOTES_: "NOTAS:",
	oEMult: "Oe Mult",
	oF: "DE",
	oPTION: "OPCIÓN",
	oRDER__: "ORDEN #:",
	oRDERDATE_: "FECHA DE PEDIDO:",
	pACKAGE: "PAQUETE",
	pACKAGEITEM: "PAQUETE ARTÍCULO",
	pACKAGEOPTION: "OPCIÓN DE PAQUETE",
	pAGE: "PÁGINA",
	pANLOOP: "ASA CACEROLA",
	pRICEBOOK_: "LISTA DE PRECIOS:",
	priceTotal: "Precio Total",
	pROD: "PROD",
	pROJECT_: "PROYECTO:",
	pURCHASER_: "COMPRADOR:",
	pURCHASER_SPO__: "PO DEL COMPRADOR #:",
	qUOTE__: "CITO #:",
	qUOTEDATE_: "FECHA DE CITA:",
	rEF__: "REF #:",
	rEMARKS_SPECIALSHIPPINGINSTRUCTIONS:
		"OBSERVACIONES / ESPECIALES INSTRUCCIONES DE ENVÍO",
	s_H: "S H &",
	servicePartsList: "Lista de piezas de repuesto",
	sHIPMENT_: "ENVÍO:",
	shippingAndHandling: "Gastos de envío",
	sHIPPINGWEIGHT: "PESO DE ENVÍO",
	sOUND: "SONIDO",
	sTOCKINGMODELS: "MODELOS DE MEDIA",
	sUBMITTEDBY_: "ENVIADO POR:",
	sUBTOTAL: "SUBTOTAL",
	sUCTION: "SUCCIÓN",
	systemNet: "Sistema de red",
	tAGGING_: "ETIQUETADO:",
	totalNet: "Red total",
	uSERID_: "ID DE USUARIO:",
	vENDORID_: "ID DE PROVEEDOR:",
	wATER: "AGUA",
	wEIGHT: "PESO",
	aSuiteOfSoftwareApplicationsCreatedToHelpRefrigerationProfessionalsMakeAccurateCalculations_AccessProductInformation_ChooseComponentsAndPrintTechnicalDrawings:
		"Una suite de aplicaciones de software creada para ayudar a los profesionales de refrigeración hacer cálculos precisos, acceso a la información de producto, seleccione componentes e imprimir dibujos técnicos",
	pleaseUpdateAllPrices_: "Por favor actualice todos los precios.",
	pleaseEnterYourPersonal: "Por favor ingrese su personal",
	applicationRegistrationKey_: "clave de registro de aplicación.",
	saveAsNewContact: "Guardar como nuevo contacto",
	saveContact: "Guardar contacto",
	saveNewContact: "Guardar nuevo contacto",
	partNumber: "Número de parte",
	loadCalculatorII: "Calculadora de carga II",
	newJob: "Nuevo trabajo",
	systemCapacity: "Capacidad del sistema",
	descriptions: "Descripciones",
	boxApplication: "Aplicación de caja",
	application: "Aplicación",
	theExternalRelativeHumidityMustBeGreaterThan:
		"La humedad relativa externa debe ser mayor que",
	theExternalRelativeHumidityMustBeLessThan___:
		"La humedad relativa externa debe ser menos de",
	theInternalRelativeHumidityMustBeGreaterThan:
		"La higrometría interna debe ser mayor que",
	theInternalRelativeHumidityMustBeLessThan:
		"La higrometría interna debe ser menos de",
	lineLoss: "Línea pérdida",
	systemRunTime: "Sistema de tiempo de ejecución",
	systemRunTimeMustBeGreaterThanOneHour_:
		"Sistema de tiempo de ejecución debe ser superior a una hora.",
	systemRunTimeMustBeLessThanTwentyfourHours_:
		"Sistema de tiempo de ejecución debe ser inferior a veinticuatro horas.",
	theSystemLineLossIsNotEntered_:
		"La pérdida de la línea de sistema no se ha introducido.",
	theSystemLineLossIsTooHigh_:
		"La pérdida de línea del sistema es demasiado alta.",
	theSystemLineLossIsTooLow_:
		"La pérdida de línea del sistema es demasiado baja.",
	averagePassageTime_Seconds_: "Pasaje medio tiempo (segundos)",
	passagesPerDay: "Pasajes por día",
	curtainEffectiveness: "Eficacia de la cortina",
	standOpenTimePerDay_Minutes_: "Horario de soporte al día (minutos)",
	cONSTRUCTIONANDDIMENSIONS: "CONSTRUCCIÓN Y DIMENSIONES",
	volumeAndTemperatures: "Volumen y temperaturas",
	airFlowRates: "Tasas de flujo de aire",
	volumeAndTemperature: "Volumen y la temperatura",
	hasConfigurationErrors: "tiene errores de configuración",
	optionConfigurations: "Opción configuraciones",
	setConfiguration: "Configuración conjunto",
	can_tHaveOptions: "No puedes tener opciones",
	notRecommendedOptions: "No se recomienda opciones",
	recommendedOptions: "Opciones recomendadas",
	availableConfigurations_: "Configuraciones disponibles.",
	normalConfiguration_: "Configuración normal.",
	hasConfigurationWarning: "ADVERTENCIA de configuración de cuenta",
	optionConfigurationError_: "Error de configuración de la opción.",
	optionConfigurationWarning_: "ADVERTENCIA de configuración opción!",
	optionConfigurtionErrorsDetected_:
		"Errores de configurtion opción detectados.",
	pleaseSelectOneOfTheAvailableConfigurations_:
		"Por favor, seleccione una de las configuraciones disponibles.",
	thisOptionHasMultipleConfigurationsAvailable_:
		"Esta opción tiene múltiples configuraciones disponibles.",
	includesOptions: "Incluye opciones",
	over_Ride_SubmitErrors: "Over_Ride & presentar errores",
	cHeckPRicesOK: "CHeckPRicesOK",
	errorLabel: "ErrorLabel",
	noRefrigerantRatingValuesListed_:
		"No hay valores refrigerante Clasificación enumerados.",
	termsOfSale: "Condiciones de venta",
	warranty: "Garantía",
	lOGO: "LOGOTIPO",
	tERMSOFSALE: "CONDICIONES DE VENTA",
	vENDORINFORMATION: "INFORMACIÓN DEL PROVEEDOR",
	useTOSImageFile: "Utilice el archivo de imagen TOS",
	checkAllErrors: "CheckAllErrors",
	drawings: "Dibujos",
	optionList: "Lista de opciones",
	specificationsPage: "Página de especificaciones",
	warningLabel: "Etiqueta de advertencia",
	clickCANCELToAdjustYourSelectedOptions_:
		"Haga clic en Cancelar para ajustar las opciones seleccionadas.",
	youWillNotBeAbleToSubmitThisOrderUsingThisOptionConfiguration_:
		"Usted no será capaz de enviar este pedido usando esta configuración de opción.",
	oPTIONDESCRIPTIONAPPENDIX: "OPCIÓN DESCRIPCIÓN APÉNDICE",
	requestedOrderShipDateIsBeforeToday_:
		"Fecha de envío del pedido solicitado es antes de hoy!",
	ordersListed_: "Listadas de pedidos.",
	sEARCHINGANDSORTING: "BÚSQUEDA Y CLASIFICACIÓN",
	synchronizingOrders_PleaseWait:
		"Sincronización de órdenes ~ por favor, espere",
	archive_: "Archivo - ",
	synchronizingOrders: "Sincronización de las órdenes",
	itemPartDescriptionRequired_: "Artículo Descripción de la parte requerido.",
	doYouWantToAttemptToOpenThisFileAnyway_:
		"¿Quieres intentar abrir este archivo de todos modos?",
	oldStyleChecksumDoesNotMatch_: "Comprobación de estilo antiguo no coincide.",
	failedToOpenDataCatalog_: "Error al abrir el catálogo de datos.",
	oPTIONDESCRIPTIONAPPENDIXCONTINUED: "OPCIÓN DESCRIPCIÓN APÉNDICE CONTINUADA",
	includeUnselectedOptions: "Incluir opciones no seleccionadas",
	sELECTEDOPTIONDESCRIPTIONS: "DESCRIPCIONES DE LA OPCIÓN SELECCIONADA",
	sELECTEDOPTIONDESCRIPTIONSCONTINUED:
		"DESCRIPCIONES DE LA OPCIÓN SELECCIONADA CONTINUADAS",
	setRevisionSUbmitted: "Revisión de conjunto presentado",
	uNsubmitQuote: "UNsubmit presupuesto",
	addDoors_Openings: "Añadir puertas / aberturas",
	deleteDoors_Openings: "Eliminar puertas / aberturas",
	dOORUSAGE: "USO DE LA PUERTA",
	editDoor_Openings: "Editar puerta / aberturas",
	curtainR_Value: "Valor-r de cortina",
	loadingPlatformRoll_UpDoor: "Puerta enrollable de plataforma de carga",
	trailerDockRoll_UpDoor: "Remolque muelle puerta enrollable",
	reach_InGlassDoor: "Alcanzar-en la puerta de cristal",
	boxUsage: "Caja de uso",
	floorType: "Tipo de piso",
	theRefrigeratedFloorIsBuiltDirectlyOnTheGround__OrTheRoomInWhichTheRefrigeratedBoxIsLocatedIsBuiltDirectlyOnTheGround__:
		"Se construye la planta refrigerada directamente sobre el terreno. ~ O la habitación donde se encuentra la caja refrigerada está construida directamente sobre el terreno. ~",
	orTheRoomInWhichTheRefrigeratedBoxIsLocatedIsBuiltDirectlyOnTheGround_:
		"O la habitación donde se encuentra la caja refrigerada está construida directamente sobre el terreno,",
	orThereIsAVentilatedSpaceUnderTheRoomInWhichTheRefrigeratedSpaceIsLocated_:
		"O hay un espacio ventilado debajo de la habitación donde se encuentra el espacio refrigerado.",
	theRefrigeratedFloorIsBuiltDirectlyOnTheGround_:
		"Se construye la planta refrigerada directamente sobre el terreno.",
	thereIsAVentilatedSpaceUnderTheRefrigeratedFloor_:
		"Hay un espacio ventilado debajo del piso refrigerado.",
	thisIncludesCrawlSpacesOrUpperFloorInstallations_:
		"Esto incluye espacios o instalaciones de la planta superior.",
	coolingLoads: "Las cargas de enfriamiento",
	checkBox: "Casilla de verificación",
	aboveGrade: "Sobre el grado",
	onGrade: "En grado",
	heatingTemperature: "Temperatura de calefacción",
	surroundingAirTemperature: "Temperatura del aire circundante",
	onGrade_FrostHeaveProtected: "Grado - Frost Heave protegido",
	aNDAControlledFrostHeavePreventionHeaterSystemIsOrWillBeInstalled_:
		"Y un sistema de calentador de prevención controlados frost heave es o será instalado.",
	construction: "Construcción",
	wALL_FLOORANDCEILINGLOADS: "CARGAS DE LA PARED, PISO Y TECHO",
	cONTINUED: "CONTINUÓ",
	pRODUCTLOADS: "CARGAS DE PRODUCTO",
	finalTemp: "Temperatura final",
	enterTemp: "Escriba Temp",
	pullDown: "Tire hacia abajo",
	tOTALPRODUCTLOAD: "CARGA TOTAL DEL PRODUCTO",
	tOTALPRODUCTWEIGHT: "PESO TOTAL DEL PRODUCTO",
	tOTALWALL_FLOORANDCEILINGLOAD: "TOTAL PARED, PISO Y TECHO CARGA",
	vOLUME: "VOLUMEN",
	mISCELLANEOUSLOADS: "DIVERSAS CARGAS",
	tOTALMISCELLANEOUSLOAD: "CARGA TOTAL MISCELÁNEO",
	aVERAGE: "MEDIA",
	fORKLIFTS_TOWMOTORS: "CARRETILLAS ELEVADORAS DE MOTORES DE REMOLQUE",
	hEAVY: "PESADO",
	lIGHT: "LUZ",
	externalRh: "Rh externa",
	externalTemp: "Temperatura externa",
	heavy: "Pesado",
	internalRh: "Interna Rh",
	internalTemp: "Temperatura interna",
	tOTALINFILTRATIONLOADESTIMATEDBYDOORSANDOPENINGS:
		"CARGA DE INFILTRACIÓN TOTAL ESTIMADO POR LAS PUERTAS Y ABERTURAS",
	ventilation: "Ventilación",
	iNFILTRATIONLOAD_ESTIMATEDBYDOORSANDOPENINGS:
		"CARGA DE INFILTRACIÓN: ESTIMADO POR LAS PUERTAS Y ABERTURAS",
	iNFILTRATIONLOAD_ESTIMATEDBYVENTILATION:
		"CARGA DE INFILTRACIÓN: ESTIMADO POR VENTILACIÓN",
	iNFILTRATIONLOAD_ESTIMATEDBYVOLUMEANDUSAGE:
		"CARGA DE INFILTRACIÓN: ESTIMADO POR EL VOLUMEN Y USO",
	curtains: "Cortinas",
	doorOrOpeningType: "Puerta o abertura tipo",
	openTime: "Hora de apertura",
	preparedBy: "Preparado por",
	preparedFor: "Preparado para",
	customerInformation: "Información del cliente",
	job_ProjectName: "Nombre del proyecto/trabajo",
	cOOLINGREQUIREMENTS: "REQUISITOS DE ENFRIAMIENTO",
	designLoad: "Carga de diseño",
	equipmentLoad: "Carga de equipo",
	hours: "Horas",
	safetyLoad: "Carga de seguridad",
	safetyFactor: "Factor de seguridad",
	capacityRequired: "Capacidad requerida",
	condenserAmbient: "Condensador de ambiente",
	sYSTEMDESIGNSUMMARY: "RESUMEN DE DISEÑO DE SISTEMA",
	sYSTEMEQUIPMENTSELECTION: "SELECCIÓN DEL SISTEMA DE EQUIPOS",
	evaporator: "Evaporador",
	nOTSELECTED_: "NO SELECCIONADO.",
	airCooledCondenser: "Condensador refrigerado por aire",
	condenser: "Condensador",
	evaporatorTD: "Evaporador TD",
	fanLoad: "Ventilador carga",
	deleteSystem: "Eliminar sistema",
	duplicateSystem: "Sistema duplicado",
	editSystem: "Editar sistema",
	area_ZoneOrSystemName: "Área, zona o nombre del sistema",
	areaDeletionRequested_: "Canceladura del área solicitada.",
	systemDetails: "Detalles del sistema",
	equipmentCapacity: "Capacidad de equipo",
	equipmentTD: "Equipo de TD",
	design: "Diseño",
	miscellaneous: "Misceláneo",
	products: "Productos",
	safety: "Seguridad",
	openBoxDesigner: "Diseñador de caja abierta",
	sYSTEMSLOADSUMMARY: "EXTRACTO DE CARGA DE LOS SISTEMAS",
	importLoadCalculatorIDesignFile:
		"Calculadora de carga de importación diseño archivo",
	importLoadCalculatorIIDesignFile:
		"Importar archivo de diseño de carga calculadora II",
	newCondenserDesign: "Nuevo diseño del condensador",
	newFluidCoolerDesign: "Nuevo diseño líquido refrigerador",
	newGlycolCoilDesign: "Nuevo diseño de la bobina del glicol",
	newLoadCalculatorIIDesign: "Nuevo diseño de carga calculadora II",
	newSystemNamed: "Nuevo sistema llamado",
	otherEquipment: "Otros equipos",
	safetyFactor___: "Factor de seguridad (%)",
	quantityPERCompressorUnit: "Cantidad por unidad del compresor",
	quantityPERCondensingUnit: "Cantidad por unidad de condensación",
	hourlyLoadRequired: "Carga horaria requerida",
	suctionLineLoss: "Pérdida de la línea de succión",
	connectionCount: "Cuenta de conexión",
	pleaseSelectAndEditTheAppropriateSection_:
		"por favor, seleccionar y editar la sección apropiada.",
	toEnterOrEditDoorOrOpeningDetails_:
		"Para ingresar o editar puerta o detalles de apertura,",
	clearAllSelections: "Borrar todas las selecciones",
	interiorTemperature: "Temperatura interior",
	areYouSureYouWantToSubmitThisMyEditOrder_:
		"¿Está seguro de que desea enviar este myEditOrder.",
	doYouWantToProcessThisChangeOrderAsAnOriginalMyEditOrder_:
		"¿Quieres procesar este pedido cambio como un myEditOrder original.",
	ifYouSubmitThisOrder_YouAreOfficiallyTakingResponsibilityForTheMyEditOrder_:
		"Si usted envía esta orden, oficialmente está tomando responsabilidad por la myEditOrder.",
	untilTheOrderIsProcessedByTheManufacturerYouMayStopThisMyEditOrder_:
		"Hasta que la orden es procesada por el fabricante puede dejar esta myEditOrder.",
	untilTheOrderIsProcessedByThePurchaserYouMayStopThisMyEditOrder_:
		"Hasta que la orden es procesada por el comprador puede dejar esta myEditOrder.",
	wARNING_YOUARENOTTHEORIGINATOROFTHISMyEditOrder_:
		"ADVERTENCIA: Que no son del autor de esta myEditOrder.",
	youCanNoLongerStopThisMyEditOrder_: "Ya no puedes dejar de este myEditOrder.",
	youDoNotHaveOwnershipOfThisOrder_:
		"Usted no tiene la propiedad de esta orden.",
	testCloneCompare: "TestCloneCompare",
	modelNotLoaded_: "Modelo no cargada!",
	temperature_TooHigh_: "Temperatura demasiado alta de 1.",
	temperature_TooLow_: "Temperatura demasiado baja 1.",
	noRatingFound_: "Ninguna calificación encontrado!",
	temperature_IsTooHigh_: "1 la temperatura es demasiado alta.",
	temperature_IsTooLow_: "1 la temperatura es demasiado baja.",
	balancedCapacity: "Capacidad equilibrada",
	evap_TD: "Gas TD",
	ambientTemperature_EvapTemperature_:
		"Temperatura ambiente < Evap temperatura!",
	boxTemperature_EvapTemperature_: "Temperatura de la caja < Evap temperatura!",
	condensingTemperature_EvapTemperature_:
		"Temperatura de condensación < Evap temperatura!",
	evap: "EVAP",
	evapTemperature: "Temperatura EVAP",
	isInvalid_: "No es válido!",
	isMissing_: "Faltan!",
	isOutOfRange_: "está fuera del intervalo.",
	systemLoadIsInvalid_: "Carga del sistema no es válido!",
	systelLoadNotGiven_: "Systel carga no dado.",
	temperature_NotGiven_: "Temperature1 que no se da.",
	productName: "Nombre de producto",
	ambientTemperature: "La temperatura ambiente",
	loadCalculationDesignHasErrors_: "Carga cálculo diseño tiene errores!",
	loadCalculationDesignHasWarnings_: "Carga cálculo diseño tiene advertencias!",
	equipmentSelectionIsIncomplete_: "Selección del equipo está incompleto!",
	systemIsNotBalanced_: "Sistema no está equilibrada.",
	designHasErrors_: "El diseño tiene errores!",
	designHasWarnings_: "El diseño tiene advertencias!",
	loadCalculationSystemHasDesignErrors_:
		"Sistema de cálculo de carga tiene errores de diseño!",
	loadCalculationSystemHasDesignWarnings_:
		"Sistema de cálculo de carga tiene advertencias de diseño!",
	testMail: "Testmail",
	oneOrMoreRequestedDatesAreInThePast_:
		"Una o más fechas solicitadas están en el pasado.",
	shipmentDateErrorsFound_: "Envío fecha errores encontrados.",
	reset: "RESET",
	currency: "Moneda",
	edit_AddDoorsAndOpenings: "Editar/añadir las puertas y aberturas",
	thePriceBookUsedForTheOpenChangeRequestHasExpired_:
		"El libro de precios utilizado para la solicitud de cambio abierta ha caducado.",
	thePriceBookUsedForThisOrderHasExpired_:
		"El libro de precios utilizado para esta orden ha expirado.",
	thePriceBooksUsedForThisOrderHaveExpired_:
		"Los libros de precio utilizados para este orden han caducado.",
	alwaysOpenAreaOfWallLeadingToAnotherZone_:
		"Área de pared principal a otra zona abierta siempre.",
	areaToBeExcludedFromWallDefinition_:
		"Área a ser excluidos de la definición de la pared.",
	nonOpeningGlassWindow_: "No abrir la ventana de cristal.",
	normallyClosedDoorForStaffOrGoodsTraffic_:
		"Normalmente cerrado la puerta para el tráfico de personal o de bienes.",
	roll_UpDoorOpenToOutsideEnvironmentOrOtherZone_:
		"Enrollable puerta abierta a fuera de ambiente o de otra zona.",
	sectionOfWallWithDifferentInsulationOrExternalEnvironmentFromRestOfWall_:
		"Sección de pared con aislamiento diferente o ambiente externo del resto de la pared.",
	pleaseSelectAnOpeningType_: "Por favor seleccione un tipo de apertura.",
	lOADCALCULATION: "CÁLCULO DE CARGA DE",
	reach_InSelfServeGlassDoors_: "Llegar en auto servicio puertas de cristal.",
	roll_UpDoorDesignedToBeNormallyClosedOrSealedByTransportTrailer_:
		"Puerta enrollable diseñada normalmente cerrado o sellado por el remolque de transporte.",
	areYouSureYouWantToUseTheSeletedValue_:
		"¿Confirma que desea utilizar el valor seleccionado?",
	theInfiltrationCalculatedForTheseIsGreaterThanTheVentilationInfiltration_:
		"La infiltración calculada para éstos es mayor que la infiltración de la ventilación.",
	theInfiltrationCalculatedForTheseIsGreaterThanTheVolumeEstimatedInfiltration_:
		"La infiltración calculada para éstos es mayor que la infiltración de volumen estimado.",
	valueWarning_: "Valor de advertencia!",
	youHaveDoorsAndOpeningsDefinedInTheBoxDesign_:
		"Tiene puertas y aberturas definidas en el diseño de la caja.",
	conductionLoad: "Carga de la conducción",
	iNFILTRATIONLOADPERUNIT: "CARGA DE INFILTRACIÓN POR UNIDAD",
	iNSULATIONLOADPERUNIT: "AISLAMIENTO DE CARGA POR UNIDAD DE",
	indoor: "Interior",
	outdoor: "Al aire libre",
	outdoorCool: "Enfriar al aire libre",
	outdoorWarm: "Caliente al aire libre",
	outdoors_ColdClimate_: "(Clima frío en el exterior)",
	eSTIMATEDBYVENTILATION: "ESTIMADO POR LA VENTILACIÓN",
	eSTIMATEDBYBOXVOLUME_USAGE: "ESTIMADO DE VOLUMEN DE LA CAJA / USO",
	eSTIMATEDBYDOORSANDOPENINGS: "ESTIMADO POR PUERTAS Y ABERTURAS",
	ventilationInfiltrationEstimate:
		"Estimación de la infiltración de ventilación",
	volume_UsageInfiltrationEstimate:
		"Estimación de la infiltración de volumen, uso",
	infiltrationMethodChosenIsNotTheMaximumLoadEstimateEntered_:
		"Método de infiltración elegida no es la estimación de la carga máxima entrada.",
	calculationMethod: "Método de cálculo",
	useMaximum: "Máximo de uso",
	lOADCONNECTIONSCONTINUED: "CONEXIONES DE CARGA CONTINUÓ",
	insulationLayerIsRecommendedForSlabOnGradeFreezers_:
		"Capa de aislamiento se recomienda para losa en congeladores de grado!",
	aDDNEWSYSTEMUSING: "AÑADIR NUEVO SISTEMA UTILIZANDO",
	iMPORTFROMFILE: "IMPORTACIÓN DE ARCHIVO",
	miscelaneousUnits: "Varios unidades",
	importSystemsFromALoadCalculatorIIFile_:
		"Importar los sistemas desde un archivo de carga calculadora II.",
	ballyBoxWizardFile: "Archivo de asistente de caja Bally",
	importSystemsFromABallyBoxWizardFile_:
		"Importar los sistemas desde un archivo de asistente de caja Bally.",
	importSystemsFromAKeepriteCalc_RiteFile_:
		"Importar los sistemas desde un archivo de Calc-rito de Keeprite.",
	importSystemsFromATretonCalculoadFile_:
		"Importar los sistemas desde un archivo de Treton Calcuload.",
	keepriteCalc_RiteFile: "Archivo de METALGAS Calc-Rite",
	tretonCalculoadFile: "Treton Calcuload archivo",
	abortAddingASystem_: "Incorporación de un sistema de la interrupción.",
	saveSystemAsTemplate: "Guardar como plantilla de sistema",
	sTANDARDAPPLICATIONS: "APLICACIONES ESTÁNDAR",
	uSERTEMPLATES: "PLANTILLAS DE USUARIO",
	rH_TD: "RH / TD",
	createSystem_PleaseSelectApplicationTypeOrTemplate:
		"Crear sistema de: Por favor seleccione tipo de aplicación o de",
	fACTORYTEMPLATES: "FÁBRICA DE PLANTILLAS",
	saveAsTemplate: "Guardar como plantilla",
	change: "Cambio",
	evapTDIsTooHighForTheSelectedApplication_:
		"TD de EVAP es demasiado alta para la aplicación seleccionada.",
	evapTDIsTooLowForTheSelectedApplication_:
		"TD de EVAP es demasiado baja para la aplicación seleccionada.",
	theInternalTemperatureIsTooHighForTheSelectedApplication_:
		"La temperatura interna es demasiado alta para la aplicación seleccionada.",
	theInternalTemperatureIsTooLowForTheSelectedApplication_:
		"La temperatura interna es demasiado baja para la aplicación seleccionada.",
	newMulti_SystemJob: "Nuevo trabajo de múltiples sistema",
	betaVersionDesignDetected_: "Diseño de versión beta detectado.",
	doYouWantToUpdateTheDesignToTheNewerVersion_:
		"Desea actualizar el diseño a la versión más reciente.",
	newerCalculatorVersionDetected_: "Nueva versión de la calculadora detectada.",
	pleaseVerifyYourDesign_: "Por favor verifique su diseño.",
	theLoadCalculationHASCHANGEDWithTheNewerVersion_:
		"El cálculo de la carga ha cambiado con la nueva versión.",
	theLoadCalculationHASNOTChangedWithTheNewerVersion_:
		"El cálculo de la carga no ha cambiado con la nueva versión.",
	theLoadCalculationsMayHaveChangedSinceYouFirstCreatedThisJob_:
		"Los cálculos de carga pueden haber cambiado desde que primero la creación de este trabajo.",
	thisDesignWasCreatedWithTheBetaVersionOfTheSoftware_:
		"Este diseño fue creado con la versión Beta del software.",
	export_CSV: "Export_CSV",
	loadCalculation: "Cálculo de carga de",
	optionDescriptionList: "Opción Descripción lista",
	pleaseCheckYourEquipmentSelection_:
		"Por favor revise su selección de equipo.",
	ifThisProblemPersists_PleaseContactTheFactory_:
		"Si el problema persiste, póngase en contacto con la fábrica.",
	selectedEquipmentNoLongerBalances_: "Equipo seleccionado ya No equilibra!",
	thisDesignWasCreatedWithAnEarlierVersionOfThisSoftware_:
		"Este diseño fue creado con una versión anterior de este software.",
	newerVersionDesignDetected_: "Nuevo diseño de la versión detectada!",
	pleaseRestartYourSoftwareToUpdateYourSystemAndDataFiles_:
		"Reinicie su software para actualizar tus sistema y archivos de datos.",
	lade: "Lade",
	pending: "Pendiente",
	outdoors_ColdClimate___: "Al aire libre (clima frío) ~ ~",
	loadingServicePartCatalog_: "Carga de catálogo de parte del servicio.",
	clearFilter: "Borrar filtro",
	category: "Categoría",
	partName_No: "Nombre de parte / No",
	qTTY: "QTTY",
	modelNumber_WhereUsed: "Número de modelo / donde",
	equipmentDetails: "Detalles del equipo",
	leadTimeChangesFound_: "cambios de tiempo de plomo encontrados.",
	leadTimesChangedOn: "Llevan tiempos cambiados en",
	leadTimesChangedOnOneRevision_: "Tiempos de plomo cambiados en una revisión.",
	noLeadTimeChangesFound_: "No hay cambios de tiempo de plomo encontrados.",
	noLeadTimeChangesOccurred_:
		"Se produjo ningún cambio de tiempo de ejecución.",
	oneLeadTimeChangeFound_: "Plazo de ejecución de un cambio encontrado.",
	copyShippingDateInformationTo: "Copiar información de fecha envío a",
	forComplexFloorsWeSuggestYouUseTheFloorPlanner:
		"Para suelos complejos le sugerimos que utilice el planificador de planta",
	onlyOneFloorSectionMayBeAddedWithThisTool_:
		"Sección de sólo un piso puede añadirse con esta herramienta.",
	sorry: "Lo siento",
	orEnterTheTotalFloorAreaAndPerimeterValuesInTheExistingFloorDefinition_:
		"o introduzca los valores de área y el perímetro total del suelo en la definición de piso existente.",
	boxDesign: "Diseño de la caja",
	canNotSubmit_: "No puede enviar!",
	invalidEmail_Format_: "Formato de Email inválido 1!",
	OrdersWillBeMarkedAsShipped_: " órdenes se marcará como enviado!",
	OrdersWillBeMarkedClosed_: " órdenes se marcará cerradas!",
	calendarDays: "días calendario",
	leadTimeCalendarDays: "Conducir tiempo días",
	leadTimeAddWeeks: "Plazo de ejecución agregar semanas",
	leadTimeWeeksAdded: "Plazo de ejecución de semanas añadido",
	pleaseContactFactory: "Por favor, póngase en contacto con fábrica",
	wARNING_SUBMITTEDQUOTENOTFOUND_:
		"¡ADVERTENCIA! ENVIADO COTIZACIÓN NO ENCONTRADO.",
	CalandarDays_: " ¡Días del calendario!",
	sorry_: "¡Lo siento!",
	youCanNotDeleteTheOrderedQuoteRevision_:
		"¿No se puede borrar la revisión pedida cita?",
	deleteCurrentQuoteRevision: "Eliminar la actual revisión del presupuesto",
	restoreCurrentQuoteRevisionToLastSavePoint:
		"Restaurar la actual revisión del presupuesto al ultimo Save Point",
	canNotRestoreTheOrderedQuoteRevision_:
		"¿No puedes restaurar la revisión pedida cita?",
	thePackage: "El paquete ",
	standardFeatures: "CARACTERÍSTICAS ESTÁNDAR",
	additionalOptionsAndAccessories: "OPCIONES Y ACCESORIOS ADICIONALES"
	
};
