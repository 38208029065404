import * as React from "react";

import TranslationContext from "../context/TranslationContext";

const useTranslationContext = () => {
    const { translations } = React.useContext(TranslationContext);
    return { translations };
};

export default useTranslationContext;
