import EquipmentByIdRequest from "./requests/EquipmentByIdRequest";
import HelperService from "./HelperService";
import { Config } from "../Constants";
import IEquipmentUnitItem from "../models/interfaces/IEquipmentUnitItem";
import StorageFacade from "./StorageFacade";

class ModelSelectionService {
	private static baseURL = Config.API_URL;

	public static getEquipmentById(
		class9Id: number,
		request: EquipmentByIdRequest,
		callback: (data: any) => void
    ) {
		let queryString = this.getQueryString(
			request
		);

		if (StorageFacade.quote?.PriceBookId) {
			if (queryString.length > 0) {
				queryString += '&';
			}
			queryString += `priceBookId=${StorageFacade.quote?.PriceBookId}`;
		}

		const url = `${this.baseURL}/equipment/${class9Id}?${queryString}`;		
		HelperService.callAPI(url, callback);
	}

	public static getEquipmentByIdAsync = (
        class9Id: number,
		request: EquipmentByIdRequest
    ) => {
        return new Promise<IEquipmentUnitItem>((resolve, reject) => {
            try {
				ModelSelectionService.getEquipmentById(
					class9Id,
					request,
					(data) => {
						if (data.warnings) {
							data.warnings = data.warnings.map((w: any) => {
								return {
									name: w.title,
									text: w.body
								}
							});
						}
                        resolve(data);
                    }
				);
            }
            catch (error) {
                reject(error);
            }
        });
    };

	private static getQueryString(request: any): string {
		const queryString = Object.keys(request)
            .filter(key => request[key] !== null && request[key] !== undefined)
			.map(key => `${key}=${request[key]}`)
            .join("&");        

		return queryString;
	}
}

export default ModelSelectionService;
