import React from "react";

import IFormState from "../models/interfaces/IFormState";
import ISection from "../models/interfaces/ISection";

import { measure } from "../types/measure";

import BoxLocation from "../enums/BoxLocation";
import BoxType from "../enums/BoxType";
import FloorType from "../enums/FloorType";

import useBoxSectionForm from "./useBoxSectionForm";

const useBoxSectionReducer = (
    boxLocation: BoxLocation,
    boxFloorType: FloorType,
    boxHeight: measure,
    boxDepth: measure,
    boxDepth2: measure,
    boxDepth3: measure,
    boxLength: measure,
    boxLength2: measure,
    boxLength3: measure,
    boxTypeId: BoxType,
    boxExteriorTemperature: measure,
    boxExteriorHumidity: measure,
    boxInteriorTemperature: measure,
    boxInteriorHumidity: measure
) => {

    const boxSectionForm = useBoxSectionForm();

    const reducer = (state: IFormState<ISection>, action: any) => {
        if (action.type === "set-initial-state") {
            return boxSectionForm.setInitialState(
                action.formValues,
                boxLocation,
                boxFloorType,
                boxHeight,
                boxDepth,
                boxDepth2,
                boxDepth3,
                boxLength,
                boxLength2,
                boxLength3,
                boxTypeId,
                boxExteriorTemperature,
                boxExteriorHumidity,
                boxInteriorTemperature,
                boxInteriorHumidity);
        }
        if (action.type === "recalculate-form") {
            const updatedFormState = boxSectionForm.recalculateSection(
                state,
                { [action.fieldName]: action.fieldValue },
                boxLocation,
                boxFloorType,
                boxHeight,
                boxDepth,
                boxDepth2,
                boxDepth3,
                boxLength,
                boxLength2,
                boxLength3,
                boxTypeId,
                boxExteriorTemperature,
                boxExteriorHumidity,
                boxInteriorTemperature,
                boxInteriorHumidity);
            return {
                defaultValues: {
                    ...updatedFormState.defaultValues
                },
                actualValues: {
                    ...updatedFormState.actualValues,
                    isModified: true
                }
            };
        }
        if (action.type === "change-value") {
            return {
                ...state,
                actualValues: {
                    ...state.actualValues,
                    [action.fieldName]: action.fieldValue,
                    isModified: true
                }
            };
        }
        return state;
    };

    const [formState, dispatch] = React.useReducer(reducer, boxSectionForm.setInitialState(
        undefined, 
        boxLocation, 
        boxFloorType,
        boxHeight,
        boxDepth,
        boxDepth2,
        boxDepth3,
        boxLength,
        boxLength2,
        boxLength3,
        boxTypeId,
        boxExteriorTemperature,
        boxExteriorHumidity,
        boxInteriorTemperature,
        boxInteriorHumidity));

    return {
        formState,
        dispatch
    };
};

export default useBoxSectionReducer;
