export type measure = number | null | undefined;

export const hasValue = (
    value: any
): boolean => {
    return (value !== null && value !== undefined && value !== "");
}

export const getNumericValue = (
    value: measure
): number => {
    // If you are sure that value is not null and not undefined, 
    //  use this function to prevent the linter error "Object is possibly 'null' or 'undefined'.ts(2533)".
    return (value === null || value === undefined)
        ? 0
        : Number(value);
}

export const roundOff = (
    value: measure,
    decimalPlaces?: number
): measure => {
    if (value !== null && value !== undefined) {
        if (decimalPlaces === undefined) {
            return value;
		}
		const factor = 10 ** decimalPlaces;
		return Math.round(value * factor) / factor;
    }
    
    return undefined;
}

export const localeString = (
    value: measure,
    decimalPlaces: number
) => {
    if (!hasValue(value)) {
        return "";
    }
    return getNumericValue(value)
        .toLocaleString(
            navigator.language, 
            { 
                maximumFractionDigits: decimalPlaces
            }
        );
};

export const countDecimals = (
    value: measure
) => {
    if (!hasValue(value)) {
        return 0;
    }
    if (Math.floor(getNumericValue(value)) === getNumericValue(value)) {
        return 0;
    }
    return getNumericValue(value).toString().split(".")[1].length || 0;
}