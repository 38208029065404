import { enTooltips } from "./en-tooltips"; // TODO: Change later when translations are available.

export const frStrings = {
	...enTooltips,
	_Feeds: "# RSS",
	_threeDollarSigns: "$$$",
	_Cancel: "&Annuler",
	_Feed: "/Flux des",
	_RDPARTY: "3RD PARTIE",
	_PreviousModel: "< Modèle précédent",
	_SelectPreviousModel: "< Sélectionnez le modèle précédent",
	_TD: "<TD",
	a_ZDescription: "A - Description Z",
	a_ZName: "A - Z Nom",
	aConfidentialErrorReportWillBeSentToTheSoftwareDevelopmentTeam_:
		"Un rapport d'erreur confidentiels seront envoyés à l'équipe de développement de logiciels.",
	aConstructionTypeNameIsNotEntered_:
		"Un nom de type de construction n'est pas entré 1",
	aCriticalErrorOccurredInTheApplication_:
		"Une erreur critique s'est produite dans l'application!",
	aDescriptionHasNotBeenEntered_: "Une description n'est pas intervenue !",
	aProblemOccurredWhileRegisteringYourNewPassword_:
		"Un problème est survenu lors de l'enregistrement de votre nouveau mot de passe!",
	aProblemOccurredWhileRegisteringYourNewUserInformation_:
		"Un problème est survenu lors de l'enregistrement de vos informations utilisateur nouvelle.",
	aProgramOrDataUpdateIsAvailable_:
		"Une mise à jour du programme ou de données est disponible.",
	aProgramUpdateIsAvailable_: "Une mise à jour du programme est disponible.",
	aPurchaseOrderNumberIsRequired_: "Un numéro de ordre est nécessaire!",
	aSurchargeWillApply_: "Un supplément sera applicable.",
	a_C_L_: "ACL",
	a_C_T_: "LOI",
	a_T_D_: "ATD",
	aCT: "LOI",
	aIRCOOLEDCONDENSOR: "AIR COOLED CONDENSEUR",
	aIRFLOW: "FLUX D'AIR",
	aLL: "TOUS",
	aLLAPPLICABLETAXESEXTRA: "Taxes en sus",
	aLLCLASSES: "TOUTES LES CATEGORIES",
	aLLMODELS: "TOUS LES MODÈLES",
	aLTITUDE: "ALTITUDE",
	aLTITUDEFACTOR: "Facteur d'altitude",
	aMBIENT: "AMBIANTE",
	aMPS: "AMPÈRE",
	aNY: "TOUTE",
	aPPLICATIONFAILURE: "APPLICATION NON",
	aT: "À",
	aTHR: "ATHR",
	aTTENTION: "ATTENTION",
	abort: "Avorter",
	aborted_NoUPDATESFolderFound_:
		"Avorté, ne trouvé aucun dossier de mises à jour.",
	about: "A propos",
	aboutNROES: "A propos de NROES",
	aboutThisApplication: "À propos de cette demande",
	active: "Active",
	activeOrderManager: "Order Manager Active",
	activeOrders: "Les ordres Active",
	activePurchaseOrderManager: "Manager Active Commande",
	activeRecordsShown: "Enregistrements actifs",
	activity: "Activité",
	actual: "Réelles",
	actualAir_T: "Courant d'Air ΔT",
	actualCondensingTemperature: "Température de condensation réelle",
	actualFluidFlowRate: "Débit fluide réel",
	actualFluidOutletTemperature: "Température de sortie de fluide réel",
	actualFluidTD: "Fluide réel TD",
	actualFluid_T: "ΔT fluide réel",
	actualTDCanNotBeGreaterThan: "TD réelle ne peut pas être supérieure à",
	actualTDCanNotBeLessThan: "TD réelle ne peut pas être inférieure à",
	actualTDMustBeGreaterThanZero_: "TD réelle doit être supérieure à zéro !",
	add: "Ajouter",
	add_EditOptions: "Ajouter / Modifier les options",
	addBlankQuoteRevision: "Ajouter des versions Citation Nuls",
	addCatalogueModel: "Ajouter Modèle catalogue",
	addConnection: "Ajouter une connexion",
	addCustomSection: "Ajouter la Section personnalisée",
	addFeed: "Ajouter un flux",
	addLoad: "Ajouter charge",
	addModel: "Ajouter Modèle",
	addModelPriceAdjustment: "Ajouter de rajustement du prix du modèle",
	addMoreConnections: "Ajouter plus de connexions",
	addNon_CatalogueItem: "Ajouter un élément non-Catalogue",
	addNon_CatalogueOption: "Ajouter une option non-Catalogue",
	addParts: "Ajouter des composants",
	addProduct: "Ajouter un produit",
	addSectionAndStartAnotherNewOne_Insert_:
		"Ajouter la Section et commencer une autre nouvelle un [Insert]",
	addSelectedPartTypesToOrder: "Ajouter Types pièce sélectionnée Pour ordrer",
	addServiceParts: "Ajouter des composants de service",
	addSystem: "Ajouter système",
	addSystemFreightAllowance: "Ajouter Allocation Freight System",
	addSystemOption: "Ajouter option du système",
	addToOrder: "Ajouter à la ordre",
	addWall: "Ajouter mur",
	addANewSystem_: "Ajouter un nouveau système.",
	addAsNewItems_: "Ajouter de nouveaux éléments.",
	addBlankQuoteRevision_: "Ajouter révision devis vierge.",
	addClipboardSystemItemsToCurrentSystem_:
		"Ajouter des éléments du système de presse-papiers système actuel.",
	addContactToYourDatabase_: "Ajouter un contact à votre base de données.",
	addNewQuoteRevision: "Ajouter révision nouveau devis",
	addOrEditOptions: "Ajouter ou modifier les options",
	additionalE_MailText: "Texte complémentaire E-Mail",
	additionalInstructions_Notes: "Instructions complémentaires / Notes",
	additionalOrderingInstructions: "D'autres instructions de ordre",
	address: "Adresse",
	adjustAllMultipliers: "Régler tous les multiplicateurs",
	adjustOrderPrice: "Réglez Prix Ordre",
	adjustPrice: "Ajuster le prix",
	adjustM: "Ajustem",
	adjustedPrice: "Prix ajusté",
	adjustment: "Ajustement",
	again: "Encore une fois",
	airCooledCondenserDesignTools:
		"Refroidi par air Outils de conception du condenseur",
	airCooledCondenserDesigner_FitAModel:
		"Refroidi par air du condenseur Concepteur: ajuster un modèle",
	airCooledCondenserDesigner_FitAJob:
		"Refroidi par air du condenseur Concepteur: Monter un emploi",
	airCooledCondenserModelSelector:
		"Sélecteur de modèle de condenseur refroidi à l'air",
	airCooledCondensingUnit: "Condenseur refroidi à l'air",
	airFlow: "Débit D'air",
	airFlowDirection: "Direction de flux d'air",
	airIn: "Dans l'air",
	airIn_DB_: "Air (DB)",
	airIn_WB_: "Air (WB)",
	airInletTemperature: "Température d'entrée d'air",
	airInletTemperature_DB_: "Température d'entrée d'air (DB)",
	airInletTemperature_WB_: "Température d'entrée d'air (WB)",
	airInletTemperature_Dry: "Température d'entrée d'air - sec",
	airInletTemperature_Wet: "Température d'entrée d'air - Wet",
	airInletTemperatureExceedsFanRating_:
		"Température d'entrée d'air dépasse la cote Fan !",
	airOut: "Aérer",
	airOutletTemperature: "Température de sortie d'air",
	airOutletTemperatureExceedsFanRating_:
		"Température de sortie d'air dépasse la cote Fan !",
	airOutletTemperatureLessThanWetBulbTemperature_:
		"Température de prise de l'air inférieure à la température du thermomètre mouillé !",
	airflow: "Débit d'air",
	allImperial: "Tous Imperial",
	allMetric_SI_: "Toutes les métriques (SI)",
	allOrders: "Toutes les ordres",
	allCertifiedPrintsHaveBeenSigned: "Tous les tirages certifiés ont été signés",
	allListedOrdersWereMarkedAsClosed_:
		"Toutes les ordres énumérées ont été marqués comme fermé.",
	allListedOrdersWereMarkedAsShipped_:
		"Toutes les ordres énumérées ont été marqués en usine.",
	allOrderItemsWillBeShippedAtTheLongestLeadTime_:
		"Tous les articles ordre sera livrée à la plus longue moment de la construction.",
	allParametersChecked_: "Tous les paramètres enregistrés.",
	allPricesWereUpToDate_: "Tous les prix sont à jour!",
	allSelectedPartsAlreadyExistOnTheOrder_:
		"Toutes les pièces sélectionnées existent déjà sur l'ordre.",
	allSystemDesignTemperaturesHaveBeenChecked:
		"Toutes les températures conception du système ont été vérifiés",
	altitude: "L'altitude",
	altitudeFactor: "Facteur d'altitude",
	altitudeIsNotSet_: "Altitude n'est pas défini !",
	aluminum: "Aluminium",
	ambient: "Ambiante",
	ambientTemp: "Temp ambiante",
	ambientTemperature_BoxTemperature_:
		"La température ambiante < case température !",
	ambientTeperatureMustBeGraterThan:
		"La température ambiante doit être de râpe que",
	ambientTeperatureMustBeLessThan:
		"La température ambiante doit être inférieure à",
	amount: "Montant",
	amountPer: "Montant par",
	anApplicationUpdateIsAvailable_:
		"Une mise à jour de l'application est disponible !",
	anAuthorizationRequestHasBeenSentToTheRegistrar_:
		"Une demande d'autorisation a été envoyée au greffier !",
	anErrorOccurredRunningTheJob_ScopeTranslation_:
		"Une erreur s'est produite en cours d'exécution de la traduction d'emploi-Scope!",
	anIssueOccuredTryingToApplyForRegistration_:
		"Un problème est survenu essayer de demander l'enregistrement.",
	anIssueOccurredWhileInstallingDataUpdates_:
		"Un problème s'est produit lors de l'installation des mises à jour des données.",
	anUnexpectedErrorOccured_: "Une erreur inattendue s'est produite!",
	anUnexpectedErrorOccurred_: "Une erreur inattendue s'est produite!",
	anUnexpectedErrorOcurred_: "Une erreur inattendue s'est produite!",
	anUpdatedPurchaseOrderIsAvailableOnRequest:
		"Un bon de ordre mis à jour est disponible sur demande",
	applicationRegistrationCodeRequested_:
		"Demande Code d'enregistrement demandé.",
	applicationTitle: "Titre de l'application",
	applicationDataFileHaveNotBeenDownloaded_:
		"Application fichier de données n'ont pas été téléchargés!",
	applicationMustBeOn_line_: "La demande doit être en ligne.",
	applicationWillExpireAfter__Days_: "Application expirera après 30 jours.",
	applicationWillNowClose_: "Application va être fermé.",
	archivedRecordsShown: "Enregistrements archivés",
	areYouARegisteredUser_: "Êtes-vous un utilisateur enregistré?",
	areYouSureThisIsTheRightNumber_: "Êtes-vous sûr que ce soit le bon numéro?",
	areYouSureYouWantToCreateABrandNewOrder_:
		"Êtes-vous sûr de vouloir créer une marque nouvelle ordre.",
	areYouSureYouWantToDeleteTheSelectedItems_:
		"Etes-vous sûr de vouloir supprimer les éléments sélectionnés?",
	areYouSureYouWantToDeleteTheSelectedSystem_:
		"Etes-vous sûr de vouloir supprimer le système retenu?",
	areYouSureYouWantToDeleteThisChangeOrder_:
		"Etes-vous sûr de vouloir supprimer cet ordre de changement?",
	areYouSureYouWantToDeleteThisContact_:
		"Etes-vous sûr de vouloir supprimer ce contact?",
	areYouSureYouWantToDeleteThisQuoteRevision_:
		"Etes-vous sûr de vouloir supprimer cette révision devis?",
	areYouSureYouWantToDoThis_: "Êtes-vous sûr de vouloir le faire.",
	areYouSureYouWantToOpenTheServerVersion_:
		"Etes-vous sûr que vous voulez ouvrir la version du serveur?",
	areYouSureYouWantToPlaceThisOrderOnHold_:
		"Êtes-vous sûr de vouloir placer cette ordre en attente?",
	areYouSureYouWantToRejectThisOrder_:
		"Êtes-vous sûr de vouloir rejeter cet ordre?",
	areYouSureYouWantToSetThisValue_:
		"Êtes-vous sûr de vouloir définir cette valeur?",
	areYouSure_: "Etes-vous sûr?",
	area: "Zone",
	areaIsNotCalculatedOrEntered_: "Zone n'est pas calculé ou entré !",
	at: "À",
	atLeastOneConnectionErrorExists_: "Au moins une connexion erreur existe !",
	atLeastOneConnectionWarningExists_:
		"Au moins un avertissement de connexion existe !",
	atLeastOneDoorHasAnEntryOrCalculationError_:
		"Au moins une porte comporte une erreur de saisie ou de calcul !",
	atLeastOneDoorHasAnEntryOrCalculationWarning_:
		"Au moins une porte a un avertissement d'entrée ou de calcul !",
	atLeastOneLayerErrorExists_: "Faute d'au moins une couche existe !",
	atLeastOneLayerWarningExists_: "Au moins un avertissement de couche existe !",
	atLeastOneListedProductHasAWarning_:
		"Au moins un produit visé a un avertissement !",
	atLeastOneListedProductHasAnError_:
		"Au moins un produit visé comporte une erreur !",
	atLeastOneOtherLoadHasAWarning_:
		"Au moins une autre charge a un avertissement ! ",
	atLeastOneOtherLoadHasAnError_: "Au moins une autre charge a une erreur ! ",
	atLeastOneRequestedDateIsSetToPriorToThe:
		"Au moins une date requise définie avant la",
	atLeastOneRevisionWasAlteredByAnotherUserOrOnAnotherWorkstation_:
		"Au moins une révision a été modifié par un autre utilisateur ou sur un autre poste de travail!",
	atLeastOneSectionHasErrors_: "Au moins une section comporte des erreurs !",
	atLeastOneSectionHasErrorsOrWarnings_:
		"Au moins une section a des erreurs ou des avertissements.",
	atLeastOneSectionHasWarnings_: "Au moins une section a avertissements !",
	atThisTime_YourOriginalOrderHasNotBeenProcessedByOurOrderDepartment_:
		"À ce moment, votre ordre initiale n'a pas été traitée par notre service des ordres.",
	atThisTime_YourOriginalRequestHasNotBeenProcessedByThePurchaser_:
		"À ce moment, votre demande initiale n'a pas été traitée par l'acheteur.",
	atThisTime_YourPreviousChangeRequestHasNotBeenProcessedByOurOrderDepartment_:
		"À ce moment, votre demande de modification précédente n'a pas été traitée par notre service des ordres.",
	atThisTime_YourPreviousChangeRequestHasNotBeenProcessedByThePurchaser_:
		"À ce moment, votre demande de modification précédente n'a pas été traitée par l'acheteur.",
	availableOptions: "Options disponibles",
	availablePriceBooks: "Livres Prix Disponible",
	averageWork_: "Travail moyen.",
	bUILDING: "CONSTRUCTION",
	back: "Précédent",
	badRatingColumnCount_: "Comte de colonne de mauvaise cote !",
	badRatingRowCount_: "Nombre de lignes de mauvaise cote !",
	ballyLoadCalcFiles: "Charger les fichiers Bally Calc",
	ballyRefrigeratedBoxes: "Bally boîtes réfrigérées",
	baseModel: "Modèle de base",
	belowFluid_sRatedMinimumTemperature_:
		"Ci-dessous la température minimale du fluide !",
	bestFit: "Meilleur ajustement",
	billOfLading: "Connaissement",
	blankTubes: "Tubes vides",
	blanks: "Flans",
	both: "Les deux",
	box: "Boîte",
	boxDesigner: "Concepteur de la boîte",
	boxExteriorRelativeHumidity___: "Boîte extérieure (% d'humidité Relative )",
	boxExteriorTemperature: "Zone de température extérieure",
	boxInteriorRelativeHumidity___: "Boîte intérieure (% d'humidité Relative )",
	boxInteriorTemperature: "Température intérieure de boîte",
	boxLocation: "Emplacement de la boîte",
	boxTemp: "Temp Boîte",
	boxTemperature: "Boîte de température",
	boxInformationIsMissing_: "Informations de la boîte sont manquantes.",
	browse: "Parcourir",
	building: "Bâtiment",
	button: "Bouton",
	by: "Par",
	cALCULATEDDETAILS: "DÉTAILS CALCULÉS",
	cALCULATOR: "CALCULATRICE",
	cALL: "APPEL",
	cAPACITY: "CAPACITÉ",
	cERTIFIEDPRINTWIZARD: "CERTIFIÉ assistant d'impression",
	cHARGE: "FRAIS",
	cHARGE_FORCED_: "CHARGE (FORCE)",
	cIRCUITTOTAL: "TOTAL CIRCUIT",
	cL: "CL",
	cOLLECT: "RECUEILLIR DES",
	cOMP: "MAQUETTE",
	cOMPLETE: "COMPLET",
	cOMPLEXFLOORPLANNER: "COMPLEXE PLANCHER PLANNER",
	cOMPRESSOR: "COMPRESSEUR",
	cOMPRESSORUNITSELECTION: "SÉLECTION DE L'UNITÉ COMPRESSEUR",
	cONDENSINGUNITSELECTION: "SÉLECTION DE L'UNITÉ DE CONDENSATION",
	cONNECTIONDIAGRAM: "SCHÉMA DE RACCORDEMENT",
	cONNECTIONS: "CONNEXIONS",
	cONTINUEDONPAGE: "SUITE À LA PAGE",
	cOULDNOTLOADPRICEBOOK: "Impossible de charger RÉSERVER Prix",
	cOULDNOTREADCURRENTORDERSUPLOADDATA_:
		"Impossible de lire ordres actuelles télécharger des données!",
	cPWIZ_Splash: "CPWIZ_Splash",
	cREATENEWORDER: "CRÉER LE NOUVEL ORDRE",
	cUSTOMER: "CLIENT",
	calculate: "Calculer",
	calculatedDetails: "Détails calculés",
	calculatedFluidFlowRate: "Calcule le débit de fluide",
	calculatedValue: "Valeur calculée",
	calculatedCondensingTemperatureCanNotBeGreaterThan:
		"Température de condensation calculée ne peut pas être supérieure à",
	calculatedCondensingTemperatureCanNotBeLessThan:
		"Calculer la température de condensation ne peut pas être inférieure à",
	calculatedCondensingTemperatureMustBeGreaterThanTheAmbientTemperature_:
		"Température de condensation calculée doit être supérieure à la température ambiante !",
	calculatedCondensingTemperatureMustBeGreaterThanTheSuctionTemperature_:
		"Température de condensation calculée doit être supérieure à la température d'aspiration !",
	calculatedShipDatesMayBeAnApproximation_:
		"les dates d'expédition calculés peuvent être qu'une approximation.",
	calculateSystemCharge: "Calculer la charge du système",
	calendar: "Calendrier",
	canNotCalculateAirOutTemperature_:
		"Air extérieur la température ne peut pas calculer !",
	canNotCalculateFluidOutTemperature_:
		"Il ne peut pas calculer les fluides à température !",
	canNotCalculateTotalHeatTransmission_:
		"Transmission de la chaleur totale ne peut pas calculer !",
	canNotAddServiceParts_: "Impossible d'ajouter des pièces de rechange.",
	canNotBeGreaterThanFluidTemperatureRange_:
		"Ne peut pas être supérieure à la plage de température du fluide !",
	canNotBeGreaterThanWetBuldTemperature_:
		"Ne peut pas être supérieure à la température humide buld !",
	canNotCheckPrices_: "Impossible de vérifier les prix!",
	canNotContinue_: "Ne peut pas continuer!",
	canNotCopy_: "Impossible de copier.",
	canNotDeleteFactoryOrderFile_:
		"Impossible de supprimer le fichier de commande à l'usine.",
	canNotDeleteOldOrder_: "Impossible de supprimer l'ancien ordre!",
	canNotDeleteOrder_: "Impossible de supprimer l'ordre.",
	canNotDeleteThisOrder_: "Impossible de supprimer cet ordre.",
	canNotFetchOrderFile_: "Impossible de récupérer le fichier de ordre!",
	canNotOpenOrder_: "Impossible d'ouvrir l'ordre!",
	canNotPaste_: "Ne peut pas coller.",
	canNotPreview_: "Impossible d'écouter un extrait.",
	canNotPrint_: "Impossible d'imprimer.",
	canNotReadFile_: "Ne peut ne pas lire le fichier.",
	canNotReadOrderFile_: "Impossible de lire le fichier de ordre!",
	canNotRecoverEdits_: "Impossible de récupérer les modifications.",
	canNotSetRejectedStatus_: "Impossible de définir le statut rejetée.",
	canNotStartApplicationShell_: "Peut ne pas démarrer shell de l'application.",
	canNotStartApplication_: "Impossible de démarrer l'application.",
	canNotStopRequest_: "Impossible de demande d'arrêt.",
	canNotStoreOrderFile_: "Impossible de stocker les fichiers de ordre!",
	canNotSubmitChange_: "Impossible de soumettre le changement!",
	canNotSubmitOrder_: "Ne peut pas soumettre l'ordre!",
	canNotSwitchPriceBooks_: "Impossible de passer des livres de prix.",
	canNotUploadOrder_: "Impossible de télécharger l'ordre!",
	canadian: "Canadienne",
	canadianEnglish: "Canadien anglais",
	cancel: "Annuler",
	cancelThisOperation: "Annuler cette opération",
	cancelThisOperation_: "Annuler cette opération.",
	cancelUpload_: "Annuler le transfert.",
	cancellingDuringTheQuickboxWalkThroughWillCloseTheTool_:
		"Annulation pendant la quickbox balade fermera l'outil.",
	cannotContinue_: "Ne peut pas continuer!",
	cannotCreateBackupFolder: "Impossible de créer le dossier de sauvegarde",
	cannotDeleteBackupFolder_: "Ne peut pas supprimer le dossier de sauvegarde.",
	cannotInstallUpdateFolder:
		"Impossible d'installer le dossier de mise à jour ",
	cannotStartTool_: "Impossible de démarrer outil.",
	cannotUpdate_: "Ne peut pas mettre à jour !",
	capacity: "Capacité",
	capacityExceedsRequestedRange_: "Dépasse la capacité demandée gamme !",
	capacityPerUnit: "Capacité unitaire",
	capacityRange___: "Gamme de capacité (%)",
	capacityTooLow_: "Capacité trop faible !",
	capacityVariance: "Variation de capacité",
	capacityCanNotBeBlank_: "Capacité ne peut pas être vide.",
	capacityIsNotCalculated_: "Capacité n'est pas calculée ! ",
	capacityIsZero_: "Capacité est nulle !",
	capacityMustBeGreaterThanZero_: "Capacité doit être supérieure à zéro.",
	caption: "Caption",
	carrier: "Transporteur",
	cascade: "Cascade",
	catalogModel: "Modèle catalogue",
	catalogueNotLoaded_: "Catalogue pas chargé!",
	ceiling: "Plafond",
	centreDistance: "Entraxe",
	certainOptionsMayNoLongerBeAvailable_:
		"Certaines options peuvent ne plus être disponibles.",
	certifiedDrawing: "Certifié Dessin",
	certifiedPrint: "Certifié Imprimer",
	certifiedPrintWizard: "Assistant d'impression certifiée",
	certifiedPrints: "Affiches certifié",
	challengeAnswer: "Challenge Réponse",
	challengeQuestion: "Question Challenge",
	change_Shape: "Modifier la forme &",
	changeAllMultipliers: "Changer tous les multiplicateurs",
	changeOrderForm: "Bon de ordre Change",
	changePassword: "Changer mot de passe",
	changeReq: "Changement Req",
	changeRequest: "Demande de changement",
	changesNotYetAppliedToCurrentOrder_:
		"Modifications ne sont pas encore appliquée à l'ordre actuel.",
	changesToTheCurrentOrderHaveNotBeenSaved_:
		"Modifications apportées à la ordre en cours n'ont pas été enregistrées!",
	checkingForUpdates: "Vérifier les mises à jour",
	circuitLoadCanNotBeCalculated_:
		"Charge de circuit ne peut pas être calculée.",
	class: "Classe",
	clear: "Claire",
	clearDate: "Date Précise",
	clickHereToAdjustTheMultipliersToGenerateTheNewPrice_:
		"Cliquez ici pour ajuster les multiplicateurs pour générer le nouveau prix.",
	clickHereToApplyThePriceChangeAndReturnToTheOrderWindow_:
		"Cliquez ici pour appliquer le changement de prix et de revenir à la fenêtre de ordre.",
	clickHereToCloseThisFormWithoutChangingTheOrder_:
		"Cliquez ici pour fermer cette forme, sans changer l'ordre.",
	clickHereToResetAllMultipliersToTheirDefaultValues_:
		"Cliquez ici pour réinitialiser tous les multiplicateurs à leurs valeurs par défaut.",
	clickOnNOToCancelThisSaveOperation_:
		"Cliquez sur Non pour annuler cette opération de sauvegarde,",
	clickingOnNoWillKeepTheCurrentOwner_sMultiplierStructure_:
		"En cliquant sur aucune structure gardera multiplicateur de l'actuel propriétaire.",
	clipboardContainsACompleteSystem_:
		"Presse-papiers contient un système complet.",
	close: "Fermer",
	closeAllOrders: "Fermer toutes les ordres",
	closeOrder: "Fermer Ordre",
	closeOrderManager: "Order Manager Fermer",
	closeShellWhenICloseTheLastDesignerWindow:
		"Fermer la carapace quand je ferme la dernière fenêtre Concepteur",
	closed: "Fermé",
	closingThemMayResultInLossOfData_:
		"Leur fermeture peut entraîner la perte de données.",
	code: "Code",
	collect: "Recueillir Des",
	columns: "Colonnes",
	compCapacity: "Capacité Comp",
	company: "Société",
	companyName: "Nom de l'entreprise",
	company_Address_Country_City_Region_PhoneFieldsAreAllRequired_:
		"Société, Adresse, Pays, Ville, Région, des champs de téléphone sont tous nécessaires.",
	completeDirect: "Remplir en Direct",
	completeSquare: "Carré complet",
	composite: "Composite",
	compositeInsulationConstruction: "Construction composite d'isolation",
	compositeK_Factor: "Composite K-Factor",
	compositeR_Value: "Valeur r composite",
	compressor: "Compresseur",
	compressorBalanceSSTTooHigh_: "Compresseur équilibre SST trop élevé !",
	compressorBalanceSSTTooLow_: "Compresseur équilibre SST trop faible !",
	compressorCapacityIsRequired_: "Capacité du compresseur est nécessaire !",
	compressorCapacityMustBeGreaterThanZero_:
		"Capacité du compresseur doit être supérieure à zéro !",
	compressorUnitCapacityError_: "Erreur de capacité d'unité compresseur !",
	compressorUnitCapacityNotCalculated_:
		"Capacité de compresseur unités ne pas calculée !",
	compressorUnitNotSelected_: "Unité de compresseur ne pas sélectionnée !",
	compressorWithCondenser: "Compresseur avec condensateur",
	concentration: "Concentration",
	condTemp: "Temp Cond",
	cond_Temp: "Temp de cond.",
	condenserBalanceSSTTooHigh_: "Condenseur équilibre SST trop élevé !",
	condenserBalanceSSTTooLow_: "Condenseur équilibre SST trop faible !",
	condenserCapacityError_: "Erreur de condensateur de capacité !",
	condenserCapacityNotCalculated_: "Capacité du condensateur ne pas calculée !",
	condenserContainsAtLeastOneError_:
		"Condensateur contient au moins une erreur !",
	condenserContainsAtLeastOneWarning_:
		"Condensateur contient au moins un avertissement !",
	condenserNotSelected_: "Condenseur ne pas sélectionnée !",
	condensing: "Condensation",
	condensingTemp: "Condensation Temp",
	condensingTemp_S_C_T__: "Temp à condensation (SCT)",
	condensingTemperature: "Température de condensation",
	condensingUnit: "Unité de condensation",
	condensingUnitAmbientTemperature:
		"Température ambiante unité de condensation",
	condensingTemperature_AmbientTemperature_:
		"Température de condensation < température ambiante !",
	condensingTemperature_BoxTemperature_:
		"Température de condensation < case température !",
	condensingTemperatureCanNotBeGreaterThan:
		"Température de condensation ne peut pas être supérieure à",
	condensingTemperatureCanNotBeLessThan:
		"Température de condensation ne peut pas être inférieure à",
	condensingTemperatureIsLessThanSiteAmbientTemperature_:
		"Température de condensation est inférieure à la température ambiante de site.",
	condensingTemperatureIsMissing_: "Température de condensation est manquant.",
	condensingTemperatureIsRequired_:
		"Température de condensation est nécessaire !",
	condensingTemperatureMustBeGreaterThanTheAmbientTemperature_:
		"Température de condensation doit être supérieure à la température ambiante !",
	condensingTemperatureMustBeGreaterThanTheSuctionTemperature_:
		"Température de condensation doit être supérieure à la température d'aspiration !",
	condensingUnitBalanceSSTTooHigh_:
		"Condensation équilibre unité SST trop élevé !",
	condensingUnitBalanceSSTTooLow_:
		"Condensation équilibre unité SST trop faible !",
	condensingUnitCapacityError_: "Erreur capacité unité de condensation !",
	condensingUnitCapacityNotCalculated_:
		"Capacité de l'unité de condensation ne pas calculé !",
	condensingUnitNotSelected_: "Unité de condensation ne pas sélectionnée !",
	conduction: "Conduction",
	configuration: "Configuration",
	confirmationIsRequired_: "Une confirmation est exigée!",
	confirmationRequired_: "Confirmation requise!",
	connected: "Connectés",
	connectionDetails: "Détails de la connexion",
	connectionSize: "Taille de connexion",
	connectionCrossesTheCentreLineOfTheUnit_SomeOptionsMayNotBeAvailable_:
		"Connexion franchit la ligne médiane de l'unité, certaines options ne soient pas disponibles !",
	connectionIsOutsideBoundsOfTheUnit_:
		"Connexion est hors limites de l'unité !",
	connectionNotAssignedToFeeds_: "Connexion ne pas assignée aux RSS !",
	connections: "Connexions",
	construction_Insulation: "Construction / isolation",
	constructionLayer: "Couche de construction",
	constructionLayers: "Couches de construction",
	contact: "Contact",
	contactName: "Personne-ressource",
	contacts: "Contacts",
	continued: "Suite",
	copper: "Cuivre",
	copy: "Copier",
	copyCurrentQuoteAsNewRevision: "Copiez cours actuel Comme nouvelle révision",
	copyCurrentRevisionEditsAsNewRevision_AndRestoreThisRevisionToLastSavePoint:
		"Copie de révision actuelle modifications de pure forme nouvelle révision, et de restaurer cette révision à la Dernière point de sauvegarde",
	copySection: "Copier la Section",
	copyAsNewOrder: "Copier en tant que nouvel ordre",
	copyLocalOrderWithNewOrderNumber_ThenDownloadMasterVersion_:
		"Copiez ordre local avec numéro de ordre nouvelle version master puis téléchargez.",
	copyMasterVersionWithNewOrderNo_ThenReplaceMasterVersionWithLocalCopy:
		"version originale de copie à l'ordre nouveau pas, puis remplacer la version de maître avec copie locale",
	copyQuote: "Copiez devis",
	copyToShipToAddress: "Copier Adresse de livraison",
	copyToSoldToAddress: "Copier Vendu à l'adresse",
	couldNotAccessRecoveryData_:
		"Impossible d'accéder à la récupération de données.",
	couldNotAddNewContactRecord_:
		"Impossible d'ajouter nouvel enregistrement de contact!",
	couldNotCalculateActualTD_: "Pouvait pas calculer TD réel !",
	couldNotCalculateDesignTD_: "Pouvait pas calculer conception TD !",
	couldNotCalculateDesignCircuitLoad_:
		"Pouvait pas calculer charge circuit de calcul !",
	couldNotCalculateDesignHeatOfCompressionFactor_:
		"Pouvait pas calculer chaleur conception du facteur de compression !",
	couldNotCalculateDesignTotalHeatRejected_:
		"Pourrait pas calculer la chaleur totale de conception rejetée !",
	couldNotCalculateRefrigerantFactor_:
		"Pouvait pas calculer le facteur réfrigérant !",
	couldNotCalculateSiteAltitudeFactor_:
		"Pouvait pas calculer le facteur d'altitude site !",
	couldNotCalculateTheTotalHeatRejected_:
		"Pouvait pas calculer la chaleur totale rejetée !",
	couldNotChangeMasterRevision_: "Impossible de changer la révision maître.",
	couldNotChangePassword_: "Impossible de changer le mot de passe!",
	couldNotConnectToServer_: "Impossible de se connecter au serveur.",
	couldNotCreateArchivePath_: "Impossible de créer le chemin d'archive!",
	couldNotCreateFactoryOrder_: "Impossible de créer une commande usine!",
	couldNotCreateNewContactRecord_:
		"Impossible de créer le nouvel enregistrement de contact!",
	couldNotCreateOrderStorePath_:
		"Impossible de créer le chemin d'accès pour stocker!",
	couldNotDeleteFile: "Impossible de supprimer le fichier",
	couldNotDeleteFile_: "Impossible de supprimer le fichier.",
	couldNotDeleteOneOfTheSelectedItems_:
		"Pas pu supprimer l'un des éléments sélectionnés !",
	couldNotDeleteOrderStorePath_:
		"Impossible de supprimer le chemin d'accès pour stocker!",
	couldNotDeleteSomeOfTheSelectedItems_:
		"Pas pu supprimer certains des éléments sélectionnés !",
	couldNotDeleteTheSelectedItem_: "Pas pu supprimer l'élément sélectionné !",
	couldNotDownloadThisOrder_: "Impossible de télécharger cet ordre!",
	couldNotExportOrderFile_: "Impossible d'exportation de fichiers de ordre.",
	couldNotExtrapolateCompressorRating_:
		"Pouvait pas extrapoler cote compresseur !",
	couldNotExtrapolateCondenserRating_:
		"Pouvait pas extrapoler cote condensateur !",
	couldNotExtrapolateCondensingUnitRating_:
		"Pouvait pas extrapoler l'unité de condensation de note !",
	couldNotExtrapolateEvaporatorRating_:
		"Pouvait pas extrapoler l'évaporateur de note !",
	couldNotFindArchivePath_: "Impossible de trouver le chemin des archives!",
	couldNotFindOrderFile_: "Impossible de trouver le fichier de ordre!",
	couldNotGenerateTargetValue_: "Impossible de générer de la valeur cible!",
	couldNotImportOrderFile_: "Impossible d'importer le fichier de ordre.",
	couldNotListLocalOrders_: "Impossible de liste les ordres locales!",
	couldNotLoadModelCatalogue_: "Impossible de charger le catalogue de modèles!",
	couldNotLoadServicePartsDatabase_:
		"Impossible de charger le service de pièces de base de données!",
	couldNotLockLocalOrderFile_:
		"Impossible de verrouiller le fichier de ordre locale!",
	couldNotLockOrderFile_: "Impossible de verrouiller le fichier de ordre!",
	couldNotLockRemoteOrder_: "Impossible de verrouiller l'ordre à distance!",
	couldNotLockSubmittedOrder_: "Impossible de verrouiller l'ordre présenté.",
	couldNotOpenDatabase_: "Impossible d'ouvrir la base de données.",
	couldNotOpenExportFile_: "Impossible d'ouvrir le fichier d'exportation!",
	couldNotOpenOrder_: "Impossible d'ouvrir la commande !",
	couldNotReadCalculatorFile_: "Impossible de lire le fichier calculatrice!",
	couldNotReadDatabase_: "Impossible de lire la base de données.",
	couldNotReadFactoryCalendar_: "Impossible de lire le calendrier d'usine!",
	couldNotReadFile_: "Impossible de lire le fichier!",
	couldNotReadOrderData_: "Impossible de lire les données de ordre.",
	couldNotReadOrderHeader_: "Impossible de lire tête de l'ordre.",
	couldNotReadOrderStatusFile_:
		"Impossible de lire le fichier d'état de ordre!",
	couldNotReadResponse_: "A lire pas de réponse.",
	couldNotReadSynchronizedOrderStatusFile_:
		"Impossible de lire le fichier d'état synchronisé ordre!",
	couldNotRetrieveAssociatedPurchaseRequest_:
		"Impossible de récupérer une demande d'achat associés.",
	couldNotRetrieveOrderFile_: "Impossible de récupérer le fichier de ordre!",
	couldNotRetrieveOrderHeader_: "Impossible de récupérer tête de l'ordre.",
	couldNotStartShellApplication_: "Pas pu démarrer application shell.",
	couldNotStartShell_: "Pas pu démarrer shell.",
	couldNotStoreOrderFileToPath:
		"Impossible de stocker les fichiers afin de chemin",
	couldNotSubmitOrder_: "Impossible de soumettre l'ordre.",
	couldNotSynchroniseRemoteOrderFile_:
		"Impossible de synchroniser à distance afin de fichier!",
	couldNotSynchronizeAddressBook_:
		"Impossible de synchroniser carnet d'adresse!",
	couldNotSynchronizeOrder_: "Impossible de synchroniser l'ordre!",
	couldNotSynchronizeOrders_: "Impossible de synchroniser les ordres!",
	couldNotSynchronizeServerOrders_:
		"Impossible de synchroniser les commandes de serveur !",
	couldNotUpdateContactRecord_:
		"Impossible de mettre à jour l'enregistrement de contact!",
	couldNotUpdateOrderDatabase_:
		"Impossible de mettre à jour la base de données de ordre!",
	couldNotUpdateOrderOnNROESServer_:
		"Impossible de mettre à jour sur le serveur afin NROES.",
	couldNotUpdatePurchaseRequestStatusOnServer_:
		"Impossible de mettre à jour l'état des demandes d'achat sur le serveur.",
	couldNotUploadLocalVersion_: "Impossible de télécharger la version locale.",
	couldNotUploadOrder: "Impossible de télécharger l'ordre",
	couldNotUploadRevision_: "Impossible de télécharger la révision.",
	couldNotWriteOrderFile_: "Impossible d'écrire le fichier de ordre!",
	country: "Pays",
	countryName: "Nom du pays",
	countyOrRegion: "Comté ou la région",
	coveredOpening: "Ouverture couverte",
	createAnotherChangeOrder: "Créer un autre ordre de modification",
	createChangeOrder: "Créer Changer l'ordre",
	createANewListing_: "Créer une nouvelle inscription.",
	criticalError_: "Erreur Critique!",
	currentPrice: "Prix actuel",
	currentMargin: "Marge courante",
	currentOrderPrice: "Prix ​​Commande en cours",
	currentQuotePrice: "Demande de prix actuels",
	currentChangesWillBeLost_: "Les changements actuels seront perdus.",
	currentManufacturersMinimumPrice_: "fabricants actuel prix minimal.",
	currentManufacturersRecommendedPrice_: "fabricants actuel prix recommandé.",
	currentOrderPrice_: "prix de l'ordre actuel.",
	currentSetting_: "Le réglage actuel.",
	currentlyThereAreNoServicePartsListedForThisModel_:
		"Actuellement, il n'ya pas de pièces de services figurant sur ce modèle.",
	cust_: "Cust #",
	custName: "Nom Cust",
	custom: "Personnalisé",
	customCeiling: "Plafond personnalisé",
	customFloor: "Plancher personnalisé",
	customWall: "Mur personnalisé",
	customer: "Client",
	customer_: "Client #",
	customerContact: "Contact avec la clientèle",
	customerInfo: "Informations client",
	customerName: "Nom du client",
	customerREF_: "REF N ° de client",
	customerRef: "Ref client",
	customerDatabaseIsMissing_: "base de données client est manquante.",
	customerNumberEnteredIsAlreadyListedAbove__:
		"Numéro de client est déjà entré ci-dessus.!",
	customerNumberEnteredIsNotInTheListShown_:
		"Numéro de client n'est pas entré dans la liste affichée!",
	customerReferenceMustBeNumeric_: "référence client doit être numérique!",
	customerReferenceMustBeSixCharacters_:
		"référence à la clientèle doit être de six caractères!",
	cut: "Couper",
	cutItemsRequested_: "Couper les pièces demandées.",
	cutSystemRequested_: "système Cut demandé.",
	dESCRIPTION: "DESCRIPTION",
	dESCRIPTION_REMARKS: "DESCRIPTION / REMARQUES",
	dESIGNDATA: "DESIGN DE DONNEES",
	dESIGNTOOLS: "OUTILS DE CONCEPTION",
	dESIGNER: "CONCEPTEUR",
	dETAILS: "Détails",
	dISTRIBUTOR: "DISTRIBUTEUR",
	dIV: "DIV",
	dIVISION: "DIVISION",
	dONOTUseThisFunctionToChangeAnExistingOrder_:
		"NE PAS utiliser cette fonction pour changer un ordre existant!",
	dOCUMENTATIONBROWSER: "NAVIGATEUR DE DOCUMENTATION",
	dRAFT: "PROJET",
	dataUpdatesFound: "Mises à jour de données trouvées",
	dataRecovery_: "Récupération de données.",
	date: "Date",
	dateCreated: "Date de création",
	dateModified: "Mise à jour",
	dateOK: "Ok Date",
	dateQuoted: "Date Cité",
	dateCanNotBeOnAWeekend_: "Date ne peut pas être une fin de semaine.",
	dateChanged: "Date changé",
	dateIsPriorToToday_: "Date est antérieure à aujourd'hui.",
	dateMustBeInTheFuture_: "La date doit être dans le futur!",
	dateVerifiedChanged: "Date de vérification changé",
	days_: "Jours!",
	defaultNumericUnits: "Les unités par défaut Numeric",
	defaultUnits: "Unités par défaut",
	delete: "Supprimer",
	deleteChangeOrder: "Changer l'ordre Supprimer",
	deleteCustomSection: "Supprimer la Section personnalisée",
	deleteCustomSections: "Supprimer des Sections personnalisées",
	deleteLayer: "Supprimer le calque",
	deleteLayers: "Supprimer des calques",
	deleteLoad: "Supprimer la charge",
	deleteOrder: "Supprimer Ordre",
	deleteProduct: "Supprimer le produit",
	deleteTheSelectedSystem_: "Supprimer le système choisi.",
	deltaX: "Delta X",
	deltaY: "Delta Y",
	dep_Div: "DEP / Div",
	department: "Département",
	depth: "Profondeur",
	depth_IsARequiredValue_: "Profondeur 1 est une valeur requise !",
	description: "Description",
	descriptionCanNotBeBlank_: "Description ne peut pas être vide !",
	descriptionIsMissing_: "Description est manquante !",
	deselectingThisPackageWillAlsoDeselectALLThoseOptionsIncludedInThatPackage_:
		"La désactivation de cette trousse sera également désélectionner TOUTES les options incluses dans ce package.",
	designAmbient: "Conception ambiante",
	designConnections: "Connections Conception",
	designRequirements: "Exigences de conception",
	designTD: "Conception TD",
	designTDCanNotBeGreaterThan: "Conception TD ne peut pas être supérieure à",
	designTDCanNotBeLessThan: "Conception TD ne peut pas être inférieure à",
	designTDMustBeGreaterThanZero_:
		"TD de conception doit être supérieure à zéro !",
	designTHRNotEntered_: "Conception THR ne pas entré !",
	designTools: "Outils de conception",
	designToolsShellOptions: "Options de conception des outils Shell",
	designCondensingTemperatureCanNotBeGreaterThan:
		"Température de condensation de conception ne peut pas être supérieure à",
	designCondensingTemperatureCanNotBeLessThan:
		"Température de condensation de conception ne peut pas être inférieure à",
	designConnectionContainsErrorsOrWarnings_:
		"Conception connexion contient des erreurs ou des avertissements !",
	designContainsErrorsOrWarnings_:
		"Conception contient des erreurs ou des avertissements !",
	designHasConfigurationErrors_:
		"Conception comporte des erreurs de configuration !",
	designHasConfigurationWarnings_:
		"Conception a configuration avertissements !",
	designHasRequirementErrors_:
		"Conception comporte des erreurs de prescription !",
	designHasRequirementWarnings_: "Conception a exigence avertissements !",
	designNotSaved: "Conception pas enregistré",
	designer: "Concepteur",
	details: "Détails",
	detailsLabel: "DetailsLabel",
	dialog: "Dialogue",
	dialog_Product_Selector: "Dialog_Product_Selector",
	differenceBetweenCurrentPriceAndFloorPrice_:
		"Différence entre le prix actuel et prix plancher.",
	differenceBetweenNewPriceAndCurrentPrice_:
		"Différence entre le nouveau prix et le prix actuel.",
	differenceBetweenNewPriceAndFloorPrice_:
		"Différence entre le nouveau prix et le prix plancher.",
	dim_: "Dim 1",
	dimension: "Dimension",
	dimension_IsNotEntered_: "Dimension 1 n'est pas entrée !",
	dimensions: "Dimensions",
	discardMyChanges_: "Jeter mes modifications.",
	distance: "Distance",
	divisionsAvailable: "Divisions Disponible",
	doNotCheckTheAboveIfThis_WorkstationIsUsedByOtherPeople:
		"Ne cochez pas la-dessus, si ce poste de travail ~ est utilisé par d'autres personnes",
	doNotShowThisMessageAgain: "Ne plus afficher ce message",
	doYouWantChangeAttachedOptionsToTheNewSetting_:
		"Voulez-vous modifier les options ci-joint à la création de nouvelles?",
	doYouWantChangeTheAttachedOptionsToTheNewSetting_:
		"Voulez-vous modifier les options ci-joint à la création de nouvelles?",
	doYouWantChangeTheseOptionsToTheNewIsSetting_:
		"Voulez-vous modifier ces options à la nouvelle création?",
	doYouWantChangeTheseOptionsToTheNewSetting_:
		"Voulez-vous modifier ces options à la création de nouvelles?",
	doYouWantToApplyTheseSettingToTheCurrentOrder_:
		"Voulez-vous d'appliquer ces paramètres à la ordre en cours?",
	doYouWantToApplyYourPersonalMultiplierStructure_:
		"Voulez-vous appliquer votre structure personnelle multiplicateur?",
	doYouWantToBuildItNow_: "Voulez-vous le construire maintenant?",
	doYouWantToChangeTheSoldByInformationToYourName_:
		"Voulez-vous changer le vendus par des informations à votre nom.",
	doYouWantToDeleteTheSelectedOrderFromTheServerAndYourComputer_:
		"Vous souhaitez supprimer la commande sélectionnée depuis le serveur et votre ordinateur.",
	doYouWantToDeleteTheSelectedOrderFromYourComputerOny_:
		"Vous souhaitez supprimer la commande sélectionnée de votre ordinateur ony.",
	doYouWantToOpenTheOrderAnyway_:
		"Voulez-vous d'ouvrir l'ordre de toute façon?",
	doYouWantToOpenTheOrderNow_: "Voulez-vous ouvrir le ordrer dès maintenant?",
	doYouWantToRemoveTheOrderFromTheLocalStore_:
		"Voulez-vous annuler l'ordonnance du magasin?",
	doYouWantToReplaceYourExistingQuoteInformation_:
		"Voulez-vous remplacer vos informations quotes existantes.",
	doYouWantToReplicateThisChangeToAllItems_:
		"Voulez-vous reproduire ce changement à tous les articles?",
	doYouWantToReplicateThisDateToAllItems_:
		"Voulez-vous répliquer cette date à tous les articles?",
	doYouWantToRestoreTheseOrders_: "Vous voulez restaurer ces ordres ?",
	doYouWantToReviewYourErrorsNow_:
		"Vous voulez revoir vos erreurs maintenant ?",
	doYouWantToRunInOff_lineMode_: "Voulez-vous fonctionner en mode off-line?",
	doYouWantToSaveItNow_: "Voulez-vous l'enregistrer maintenant?",
	doYouWantToSaveYourDataAnyway_:
		"Vous voulez sauvegarder vos données de toute façon ?",
	doYouWantToShutdownAnyway_: "Vous voulez arrêter de toute façon ?",
	doYouWantToSubmitAnyway_: "Voulez-vous présenter toute façon?",
	doYouWantToSubmitThisOrderAnyway_:
		"Voulez-vous présenter cet ordre de toute façon?",
	doYouWantToUpdateTheOrderNow_:
		"Voulez-vous mettre à jour le commander dès maintenant?",
	doYouWantToUpdateTheOrderToUseYourNewCatalogueProfileSettings_:
		"Voulez-vous mettre à jour l'ordre d'utiliser vos nouveaux paramètres de profil de catalogue?",
	doYouWantToUpdateTheOrderToUseYourNewMultiplierStructure_:
		"Voulez-vous mettre à jour l'ordre d'utiliser la structure de votre nouveau multiplicateur?",
	doYouWantToUpdateThisRevisionToUseTheLatestPricebook_:
		"Voulez-vous mettre à jour cette révision d'utiliser la dernière pricebook?",
	doYouWishToRetry_: "Voulez-vous réessayer?",
	dockDoor: "Porte de quai",
	documentGenerator: "Générateur de documents",
	documentContainsNoPages_: "Document ne contient pas de pages.",
	door: "Porte",
	door_OpeningDetails: "Porte / détails d'ouverture",
	doorAndOpeningInfiltrationEstimate:
		"Porte et ouverture Infiltration estimation",
	doorsAndOpenings: "Portes et ouvertures",
	down: "Vers Le Bas",
	downloadingApplicationShell: "Télécharger l'Application Shell",
	downloadingDataUpdate_: "Téléchargement de données mise à jour:",
	downloadingProgramUpdate_: "Programme de téléchargement de mise à jour:",
	downloadingUpdates: "Téléchargement des mises à jour",
	draft: "Projet de",
	draftC_O_: "Projet de CO",
	dualEnteringService: "Service entrée double",
	dualEnteringService__FanWideUnitWithSeperateControlsPerSide_:
		"Double mise en Service (2 large ventilateur avec contrôles séparés par côté)",
	dualEnteringService__FanWideUnitsWithSeperateControlsPerSide_:
		"Double mise en Service (2 large ventilateurs avec contrôles séparés par côté)",
	e_Mail: "Courriel",
	e_Mail_UsernameCombinationAlreadyExists_:
		"Courriel / combinaison de nom d'utilisateur déjà existe.",
	e_MailAddressAgain: "Adresse de courriel à nouveau",
	e_MailAddresses: "Adresses E-Mail",
	e_mailAddressesDoNotMatch_: "Adresses de messagerie ne correspondent pas.",
	eACH: "CHAQUE",
	eDIT: "MODIFIER",
	eNTERED: "ENTREE",
	eNTEREDDESIGNREQUIREMENTS: "SAISIE DES EXIGENCES DE CONCEPTION",
	eRROR: "ERREUR",
	eRROR_: "ERREUR !",
	eRRORS: "ERREURS",
	eULA: "CLUF",
	eVAPORATORMOTORS: "ÉVAPORATEUR MOTORS",
	eVAPORATORUNITSELECTION: "SÉLECTION DE L'UNITÉ ÉVAPORATEUR",
	eXITNROES: "SORTIE NROES",
	edit: "Modifier",
	editConstruction: "Modifiez la Construction",
	editLayer: "Modifier la couche",
	editLoad: "Modifier la charge",
	editMode_: "Mode D'édition:",
	editModel: "Modifier le modèle",
	editProduct: "Modifier le produit",
	editQuoteInformation: "Modifier les infos Citer",
	editSection: "Modifier la Section",
	editSections: "Modifier les Sections",
	effectiveR_Value: "R-valeur efficace",
	effectiveR_ValuePerUnitDepth:
		"R-valeur efficace par la profondeur de l'unité",
	either_ShippedClosedOrProcessedIsSet_:
		"Soit, livrés fermés ou transformés est réglé.",
	electricalPower: "L'énergie électrique",
	elevationIsTooHigh_: "Altitude est trop élevée !",
	elevationIsTooLow_: "Altitude est trop faible !",
	emailAddress: "Adresse de courriel",
	ending: "Se terminant",
	enter: "Entrez",
	enterANumberEndingInAPercent_SymbolIfRequired_:
		"Entrez un numéro se terminant par un symbole% pour cent si nécessaire.",
	entered: "Entrée",
	enteredDesignRequirements: "Saisie des exigences de conception",
	enteredValue_: "La valeur entrée:",
	enteredMultiplierIsLessThanTheRecommendedMinimumValue_:
		"Entré multiplicateur est inférieur à la valeur minimale recommandée.",
	enteredPasswordDoNotMatch_: "Mot de passe entré ne correspondent pas.",
	enteringTemperature: "Température entrée",
	equipmentList: "Liste des équipements",
	equipmentType: "Type d'équipement",
	equipmentChangesOccurredOn: "changements survenus sur le matériel",
	equipmentChangesOccurredOnOneRevision_:
		"changements survenus sur le matériel d'une révision.",
	error: "Erreur",
	errorDetails_: "Détails de l'erreur.",
	errorsExistOnThisOrder_: "Les erreurs existent sur cet ordre.",
	evapTemp: "Temp Evap",
	evap_Temp: "Évaporateur. Temp",
	evaporatorBalanceSSTTooHigh_: "Évaporateur équilibre SST trop élevé !",
	evaporatorBalanceSSTTooLow_: "Évaporateur équilibre SST trop faible !",
	evaporatorUnitCapacityError_: "Erreur de capacité d'unité évaporateur !",
	evaporatorUnitCapacityNotCalculated_:
		"Capacité d'unité évaporateur ne pas calculée !",
	evaporatorUnitNotSelected_: "Évaporateur ne pas sélectionnée !",
	exceedsAllModelMaxFanTemperatureSpecifications_:
		"Dépassent toutes les spécifications de température Max ventilateur modèle !",
	exceedsDryBulbTemperature_: "Dépasse la température du thermomètre sec !",
	exceedsFluid_sRatedMaximumTemperature_:
		"Dépasse la température maximale du fluide !",
	excelCSVTextFiles: "Fichiers Texte CSV Excel",
	excelFile_AllListedParts_: "Fichier Excel - toutes les pièces énumérées.",
	excelFile_SelectedPartsOnly_: "fichier Excel - Certaines pièces seulement.",
	excludeDraftOrders: "Un projet d'ordre Exclure",
	excludedArea: "Zone exclue",
	exit: "Sortie",
	exitNROES: "NROES sortie",
	exitOrderManager: "Manager ordre de sortie",
	expectedCarrier: "Attendus Carrier",
	expires: "Expire",
	export: "L'exportation",
	exportOrderFile: "ordre du dossier d'exportation",
	exportServicePartInformation:
		"Renseignements sur les exportations de services dans",
	extTemp: "Temp ext",
	extension: "Extension",
	exteriorRH: "Extérieur RH",
	exteriorTemperature: "Température extérieure",
	exteriorBoundaryLayer: "Couche limite externe",
	externalRH: "RH externe",
	externalTemperature: "Température extérieure",
	fANLENGTH: "LONGUEUR FAN",
	fANMOTOR: "MOTEUR DE VENTILATEUR",
	fANWIDTH: "LARGEUR FAN",
	fANS: "VENTILATEURS",
	fAX: "TÉLÉCOPIEUR",
	fAXNumbers: "Numéros de Fax",
	fIN: "NAGEOIRE",
	fINSPACING: "Pas d'ailettes",
	fINISH: "FINITION",
	fLA_FAN: "FLA / FAN",
	fLUIDCOOLER: "REFROIDISSEUR DE LIQUIDE",
	fORKLIFTS: "CHARIOTS ÉLÉVATEURS À FOURCHE",
	fPI: "FPI",
	faceArea: "Zone du visage",
	factoryInv_To: "Factory Inv. Pour",
	factoryJob: "Job Factory",
	factoryJob_: "Job Factory #",
	factoryOrder: "Commandez Factory",
	factorySettings: "Réglages d'usine",
	factoryStyle: "Style Factory",
	factoryCalendarNotFound_: "Calendrier de l'usine ne trouve pas!",
	factoryOrderNotYetCreated_: "commande à l'usine n'est pas encore créée.",
	failedToDownloadRequiredDataFiles_:
		"Impossible de télécharger des fichiers de données nécessaires!",
	familyMinimumOrderPriceNotReached_:
		"Tarif familial minimum de commande n'est pas atteint.",
	fanConfiguration: "Configuration du ventilateur",
	fanCount: "Comte de ventilateur",
	fans: "Ventilateurs",
	fansLong: "Les fans de longue",
	fansWide: "Les fans Large",
	fansL: "FansL",
	fansW: "FansW",
	faxNo: "Télécopie",
	feeds: "Nourrit",
	feedsAvailable: "Disponible RSS",
	file: "Fichier",
	fileError_: "Fichier d'erreur.",
	fileIsNotAValidOrderFile_: "Le fichier n'est pas un fichier de ordre valide!",
	fileRevisionNoLongerSupportByNROES_:
		"Fichier de révision ne supportent plus par NROES.",
	fileStructureNotRecognized_: "Structure des fichiers non reconnu.",
	fileWasCreatedWithANewerVersionOfNROES_:
		"Le fichier a été créé avec une version plus récente de NROES.",
	filesDownloaded: "Les fichiers téléchargés",
	filter: "Filtrer",
	finBlockDimensions: "Dimensions de bloc de fin",
	finConstruction: "Construction de nageoire",
	finStyle: "Style de nageoire",
	final: "Finale",
	finalTemperature: "Température finale",
	findCustomer: "Trouver des clients",
	finish: "Terminer",
	finsPerInch: "Ailettes par pouce",
	fitAModel: "Ajuster un modèle",
	fitAnApplication: "Adapter une Application",
	flat: "Plat",
	floor: "Plancher",
	floorArea: "Surface de plancher",
	floorPrice: "Etage Prix",
	floorPlanIsNotCompleted_: "Plan d'étage n'est pas terminé !",
	fluid: "Fluides",
	fluidConcentration: "Concentration du liquide",
	fluidConcentrationNotSelected_:
		"Concentration du liquide ne pas sélectionnée !",
	fluidCooler: "Le refroidisseur de fluide",
	fluidCoolerDesignTools: "Outils de conception refroidisseur liquide",
	fluidCoolerDesigner: "Concepteur refroidisseur de fluide",
	fluidCoolerDesigner_FitAJob:
		"Fluide refroidisseur Designer : S'adapter à un emploi",
	fluidCoolerDesigner_FitAModel:
		"Designer refroidisseur liquide : Correspond à un modèle",
	fluidCoolerModelSelector: "Sélecteur de modèle refroidisseur liquide",
	fluidFlowRate: "Débit de fluide",
	fluidFlowRateIsNotSet_: "Débit de fluide n'est pas défini !",
	fluidFlowRatePerUnit: "Débit fluide par unité",
	fluidFoulingFactor: "Liquide salissure facteur",
	fluidInletTemperature: "Température d'entrée du fluide",
	fluidInletTemperatureIsNotSet_:
		"Température d'entrée du fluide n'est pas définie !",
	fluidInletTemperatureMustBeSet_: "Température de fluide doit être réglée !",
	fluidInletTemperatureNotCalculated_PleaseEnterOtherThreeValues_:
		"Température à l'entrée du fluide ne pas calculée ! S'il vous plaît entrer trois autres valeurs.",
	fluidNotSelected_: "Fluide ne pas sélectionné !",
	fluidOutTemperature_AirTemp____C: "Fluide à température < Air Temp + 0,2 C",
	fluidOutTemperatureGreaterThanFluidMaximumTemperature__C_:
		"Fluide à température supérieure à la température maximale de fluide - 2C !",
	fluidOutTemperatureLessThanFluidMinimumTemperature__C_:
		"Fluide à température température minimale inférieure à fluide + 2 C !",
	fluidOutletTemperature: "Température de sortie fluide",
	fluidOutletTemperatureIsNotSet_:
		"Température de sortie fluide n'est pas définie !",
	fluidPressureDrop: "Chute de pression fluide",
	fluidTemperatureRange: "Plage de température de liquide",
	fluidType: "Type de liquide",
	fluidVelocity: "Vitesse du fluide",
	fluidVelocityAboveRecommendedNominalRange_:
		"Vitesse du fluide ci-dessus recommandé gamme nominale !",
	fluidVelocityBelowRecommendedNominalRange_:
		"Vitesse du fluide ci-dessous recommandé gamme nominale !",
	fluidVelocityTooHigh_: "Vitesse du fluide trop élevée !",
	fluidVelocityTooLow_: "Vitesse du fluide trop bas !",
	followTheInstructionsInTheEmailToCompleteYourNewUserRegistration_:
		"Suivez les instructions dans l'email pour terminer votre enregistrement nouvel utilisateur.",
	forceUpdateWaiting: "ForceUpdateWaiting",
	forkliftLoad: "Chariot élévateur charge",
	form_App_Menu: "Form_App_Menu",
	forms: "Formulaires",
	formsMethod: "Méthode de formes",
	fouling: "L'encrassement",
	foulingFactor: "Facteur de l'encrassement",
	freeShippingIsAvailableOnThisOrder_SetTermsToPre_Paid_:
		"Livraison gratuite est disponible sur cette commande !  Termes affectez prépayées.",
	freezeTemperature: "Température de congélation",
	freightTermsFOBBrantfordOntarioUnlessOtherwiseSpecified_:
		"Les frais de transport FOB Brantford, en Ontario, sauf indication contraire.",
	french: "Française",
	from: "De",
	front: "Avant",
	frontCut: "Coupe avant",
	frontInset: "Incrustation devant",
	fuildFlow: "Flux de Fuild",
	fuildVolume: "Volume Fuild",
	fullName: "Nom complet",
	functionalityWillBeLimited_: "Fonctionnalités seront limitées.",
	fx_: "FX : ",
	gLYCOLCOIL: "BOBINE DE GLYCOL",
	general: "Général",
	german: "Allemande",
	glycoilCoil_FitAJob: "Glycoil bobine : S'adapter à un emploi",
	glycoilCoil_FitAModel: "Glycoil bobine : Correspond à un modèle",
	glycolCoil: "Serpentin Au Glycol",
	glycolCoilDesignTools: "Outils de conception de bobine de glycol",
	glycolCoilDesigner: "Concepteur de bobine de glycol",
	glycolCoilModelSelector: "Sélecteur de modèle de bobine glycol",
	greek: "Grecque",
	guest: "Invités",
	hEATERS: "CHAUFFE",
	hELLO_WORLD_: "BONJOUR ~ MONDE ~",
	hOCFactorNotAvailable_: "HOC facteur ne sont pas disponibles.",
	hOLDING: "EXPLOITATION",
	heatOfRespiration: "Chaleur de la Respiration",
	heavyUse: "Usage intensif",
	heavyWork_: "Travaux lourds.",
	height: "Hauteur",
	heightIsARequiredValue_: "Hauteur est une valeur requise !",
	held: "Tenue",
	help: "Aide",
	holdChangeOrder: "Tenez Changer l'ordre",
	holdOrder: "Tenez Ordre",
	holding: "Exploitation",
	holdingAnOrderWillSignalTheOriginatorThatTheyNeedToCorrectSomething_:
		"La tenue d'un signal de ordre sera à l'origine dont ils ont besoin pour corriger quelque chose!",
	horizontal: "Horizontale",
	howDoYouWantToHandleThis_: "Comment voulez-vous gérer cela?",
	howDoYouWantToResolveThisIssue_: "Comment voulez-vous résoudre ce problème!",
	howDoYouWantToStartTheNewQuote_:
		"Comment voulez-vous lancer le nouveau devis.",
	howeverYouMayLooseChangesIfTheOrderIsBeingEditedByAnotherUser_:
		"Cependant vous risquez de perdre des changements si l'ordonnance est en cours de modification par un autre utilisateur!",
	hz: "Hz",
	iACCEPTTHESETERMS: "J'accepte les termes",
	iDONOTACCEPTTHESETERMS: "JE N'ACCEPTE PAS CES CONDITIONS",
	iForget: "J'ai oublié",
	iHaveConfirmedItIsOkToUploadThisOrder_:
		"J'ai confirmé, il est autorisé à transférer cet ordre.",
	iD: "IDENTIFIANT",
	iMPORTORDERFILE: "DOSSIER DE LA COMMANDE IMPORTATION",
	iNREVIEW: "REVUE DE",
	iNFORMATIONISGIVENGRATISANDTHEMANUFACTURERASSUMESNOOBLIGTIONORLIABILITYFORACTUALRESULTSOBTAINED_:
		"DES INFORMATIONS SONT FOURNIES GRATUITEMENT ET LE FABRICANT N'ASSUME AUCUNE OBLIGATION OU RESPONSABILITÉ POUR LES RÉSULTATS RÉELS OBTENUS.",
	iNIFileMissingOrInvalid_: "Fichier INI manquant ou non valide !",
	iNVALIDREFRIGERANT: "RÉFRIGÉRANT INVALIDE",
	iNVOICETO: "FACTURE",
	iTEM: "POINT",
	iTEMCHANGES: "POINT CHANGEMENTS",
	iTM: "ITM",
	identityVerificationChallenge_E_g_FavouriteHockeyTeam:
		"Challenge de vérification d'identité. Équipe de hockey par exemple favorites",
	ifTheLoadIsNotEnteredManually_TheDesignMustIncludeAFloor_ACeiling_AndAtLeastFourWalls__:
		"Si la charge n'est pas entrée manuellement, la conception doit inclure au moins quatre murs, un plafond et un plancher. !",
	ifThisProblemPersistsPleaseContactTheFactory_:
		"Si ce problème persiste s'il vous plaît contacter l'usine.",
	ifYouCanReplicateTheError_PleaseProvideDetailedStepByStepInstructions_:
		"Si vous pouvez reproduire l'erreur, s'il vous plaît fournir détaillées étape par étape les instructions.",
	ifYouChoseNoYouWillNotBeAbleToRecoverTheseOrdersLater_:
		"Si vous choisissez aucun, vous ne serez pas en mesure de récupérer ces arrêtés plus tard.",
	ifYouContinue_YourOutstandingChangeRequestWillBeStopped_:
		"Si vous continuez, votre demande de modification en circulation sera arrêté.",
	ifYouContinue_YourOutstandingOrderWillBeStopped_:
		"Si vous continuez, votre ordre en cours sera arrêté.",
	ifYouContinue_YourOutstandingRequestWillBeStopped_:
		"Si vous continuez, votre demande en circulation sera arrêté.",
	ifYouHaveIssuesWithTheTranslations_AndWouldLikeToHelp_PleaseClickOnTheButtonBelow_:
		"Si vous avez des problèmes avec les traductions, et que vous souhaitez aider, s'il vous plaît cliquer sur le bouton ci-dessous.",
	ifYouHaveVerifiedThatItIsOkToUploadThisOrder_YouMayStillDoSo_:
		"Si vous avez vérifié qu'il est autorisé à télécharger cette ordre, vous pouvez toujours le faire.",
	ifYouThinkTheNetworkIsAvailablePleaseContinueWaiting_:
		"Si vous pensez que le réseau est disponible s'il vous plaît continuer à attendre.",
	ifYouWantToSaveYourChangesAsANewQuoteRevision_DoTheFollowing_:
		"Si vous voulez enregistrer vos modifications comme une révision nouveau devis, procédez comme suit.",
	ignore: "Ignorer",
	ignoreForNow_: "Ignorer pour le moment!",
	imageFiles: "Fichiers image",
	imperial: "Impériales",
	importExistingCondenserJobFile:
		"Importer un fichier existant Job condensateur",
	importJobFile: "Importer un fichier Job",
	importLoadCalcParameters: "Paramètres de charge Import Calc",
	importLoadCalculatorFile: "Importer un fichier calculateur de charge",
	importLoadCalculatorModels: "Modèles d'importation calculateur de charge",
	importOrder: "Importer ordrer",
	importOrderFile: "ordre du dossier d'importation",
	includeAllCompanyOrders: "Toutes les ordonnances Société",
	includeAllOfTheAbove: "Inclure Tous Of The Above",
	includeAllOrders: "Toutes les ordonnances",
	includeClosedOrders: "Inclure les ordres satisfaites",
	includeDeletedOrders: "Inclure supprimés ordres",
	includeHeldOrders: "Inclure les ordres en",
	includeOrdersClosedWithinThePast__Days:
		"ordres comprennent fermé dans les 60 derniers jours",
	includeOrdersRejectedWithinThePast__Days:
		"ordres comprennent rejetée dans les 60 derniers jours",
	includeOrdersShippedWithinThePast__Days:
		"ordres comprennent expédiés dans les 60 derniers jours",
	includeProcessedOrders: "Inclure transformés ordres",
	incompatibleServerVersion_: "version du serveur incompatibles!",
	indicatesOptionIsShippedLoose: "Option Indique est expédié en vrac",
	indicatesRevisionSpecificStatus: "Indique l'état des versions spécifiques",
	indicatesRequiredEntries: "Indique les entrées requises",
	indoors: "À l'intérieur",
	infiltratingAirRH: "Infiltration d'Air RH",
	infiltration: "Infiltration",
	infiltrationLoad: "Charge d'infiltration",
	infiltrationLoadIsNotCalculatedOrEntered_:
		"Charge de l'infiltration n'est pas calculé ou entré !",
	infiltrationLoadIsRarelyZero_: "Charge d'infiltration est rarement nul !",
	informationIncomplete_: "Information incomplète.",
	informationRetreivalFailed_: "Informations retreival a échoué.",
	initializing: "L'initialisation",
	insertLayer: "Insert couche",
	insulation: "Isolation",
	insulation_Construction: "Isolation / Construction",
	insulationName: "Nom de l'isolation",
	interiorBoundaryLayer: "Intérieur couche limite",
	internalRH: "RH interne",
	internalTemperature: "Température interne",
	internalVolume: "Volume interne",
	invalidPassword_: "Mot de passe invalide!",
	invalidChecksum_: "Blancs de contrôle.",
	invalidDateEntered_: "Date non valide est entré!",
	invalidE_mailAddress_: "Adresse de messagerie non valide.",
	invalidFileHeader_: "Blancs-tête du fichier.",
	invalidFileLength_: "Blancs longueur du fichier.",
	invalidHeaderLength_: "Blancs longueur de l'entête.",
	invalidPromiseDate_: "Blancs date promise.",
	invalidQuantityEntered_: "quantité Blancs entré!",
	invoice: "Facture",
	italian: "Italienne",
	item_: "Point #",
	itemAdjustmentDetails: "Détails de réglage de point",
	itemDescription: "Description Point",
	itemListPrice: "Prix éditeur Point",
	itemMultiplier: "Point multiplicateur",
	itemName: "Nom de l'item",
	itemNet: "Point Net",
	itemNo: "Point n",
	itemPrice: "Article Prix",
	itemQuantity: "Quantité",
	itemRequestedDate: "Point Date demandée",
	itemTotal: "Point Total",
	itemTotalAfterAdjustments: "Point Total après ajustements",
	itemWeight: "Point de poids",
	itemsInBOLDAreRequired: "Les éléments en gras sont obligatoires",
	itemsOnTheClipboard_: "Articles sur le presse-papiers:",
	itemsOnThisSystemHaveErrors_: "Articles de ce système ont des erreurs !",
	itemsOnThisSystemHaveWarnings_:
		"Articles de ce système ont des avertissements !",
	itm_OptNotes: "ITM / Opt Notes",
	itm_OptTag: "ITM / Opt Tag",
	job_SiteDetails: "D'emploi / Site Détails",
	job_SiteDetails_Preferences: "Job / Informations sur le site & Préférences",
	jobMayBeUploaded_: "L'emploi peut être téléchargé!",
	jobNotBeUploaded_: "Emploi ne peut être téléchargé!",
	job_ScopeREF_: "Emploi-Champ Ref #",
	job_ScopeReferenceNotReturned_: "Référence de l'emploi-Scope pas retourné!",
	job_ScopeReturnFileNotFound_:
		"déposer une déclaration d'emploi-Scope pas trouvé!",
	kFactor: "Facteur K",
	k_Factor: "K-Factor",
	keepRiteLoadCalcFiles: "Charger les fichiers KeepRite Calc",
	keepRiteRefrigeration: "KeepRite réfrigération",
	keepriteRefrigeration: "Keeprite réfrigération",
	l_SHAPED: "L - FORME",
	lIGHTING: "ÉCLAIRAGE",
	lIQUID: "LIQUIDE",
	lISTPRICE: "LISTE DES PRIX",
	lOAD: "CHARGE",
	lOADCONNECTIONS: "CONNEXIONS DE CHARGE",
	lOADINGCATALOGUE: "En cours de chargement CATALOGUE",
	lOC: "NON !",
	lOCATION: "EMPLACEMENT",
	lOCKED: "VERROUILLÉ",
	lRA: "LRA",
	label: "Étiquette",
	label__Text: "Label1.Text",
	language: "Langue",
	largeToolbarIcons: "Barre d'outils Grandes icônes",
	layer: "Couche",
	leadTime: "Délai d'exécution",
	leadTimeIsDependentOnPartAvailabilityAndWillBeConfirmedAtTimeOfOrder_:
		"Délai dépend de la disponibilité partie et seront confirmés lors de la commande.",
	leaveQuoteDateAsIs_: "Laissez cite comme date.",
	leftCut: "Gauche coupé",
	leftEnd: "Extrémité gauche",
	leftInset: "Récipient encastré à gauche",
	length: "Longueur",
	length_IsARequiredValue_: "Longueur 1 est une valeur requise !",
	less: "Moins",
	lightWork_: "Travaux légers.",
	lightingLoad: "Charge d'éclairage",
	lineFrequency: "Fréquence de ligne",
	lineItem: "Point Ligne",
	liquidOut: "Sortie liquide",
	listPrice: "Liste de prix",
	listPricesAreNotCurrentAndTheQuoteIsOlderThan__Days_:
		"Liste des prix ne sont pas en cours et la citation est de plus de 30 jours!",
	listPricesAreNotCurrent_: "Liste des prix ne sont pas en cours!",
	load: "Charge",
	loadCalculator: "Calculateur de charge",
	loadCalculatorDesignTool: "Outil de conception calculateur de charge",
	loadDifferentFile: "Chargez le fichier différent",
	loadFactor: "Facteur de charge",
	loadingPriceBook: "Chargement catalogue des prix",
	localVersionUploadTimestamp_: "Version locale télécharger timestamp:",
	localOrdersQueried_: "ordres locales interrogées!",
	localVersionModificationTimestamp_:
		"Local horodatage de modification version:",
	location: "Lieu",
	locations: "Emplacements",
	longStorage: "Entreposage à long",
	lookingForUserProfile_: "Vous cherchez profil de l'utilisateur.",
	m_R_P: "MRP",
	mANUALOPTIONS: "Options manuelles",
	mAXSPARES: "PIÈCES DE RECHANGE DE MAX",
	mAY: "MAI",
	mCA_MOPAreForTheCondensingUnitONLY_SinglePointConnectionsWILLShowDifferentOnDataplate_:
		"MCA & MOP sont pour l'unité de condensation seulement. Connexions monopoint montrera différentes sur la plaque signalétique.",
	mCA_: "MCA†",
	mESSAGENOTFOUND: "MESSAGE NON TROUVÉ",
	mODELFEATURES: "MODÈLE CARACTÉRISTIQUES",
	mODELINFORMATION: "Informations sur le modèle",
	mODELOPTIONS: "Options du modèle",
	mODELTYPE: "MODÈLE TYPE",
	mODIFIED: "Dernière mise à jour",
	mOP_: "MOP‡",
	mOREINFO: "Moreinfo",
	mULT: "MULT",
	mULTI_CIRCUIT: "MULTI-CIRCUIT",
	manufacturer: "Fabricant",
	manufacturing: "Fabrication",
	margin: "Marge",
	markAllAsRead: "Tout marquer comme lu",
	markAsShipped: "Comme Mark expédiés",
	markRequestAsProcessed: "Demande Mark Comme transformés",
	markUp: "Marquer",
	markAsClosed: "Marquer comme fermé",
	markAsRead: "Marquer comme lu",
	masterCatalogNotLoaded: "Catalogue Master non chargé",
	match: "Match de football",
	matched: "Appariés",
	matches: "Correspondances",
	matchingModels: "Modèles correspondants",
	material: "Matériel",
	materialName: "Nom de matière",
	materialLoadIsNotCalculatedOrEntered_:
		"Charge matérielle n'est pas calculé ou entré !",
	materialNameMustBeEntered_: "Nom de matière est obligatoire !",
	materialTypeMustBeSelected_: "Type de matériau doit être sélectionnée !",
	maximum: "Maximale",
	maximumFluidFlowRateExceeded_:
		"Maximum Vitesse d'écoulement fluide dépassé !",
	maximumOvercurrentProtection: "Protection contre la surintensité maximale",
	measurementUnits: "Unités de mesure",
	menuStrip: "Menustrip",
	messages: "Messages",
	metric_SI_: "Métriques (SI)",
	minimum: "Minimum",
	minimumCircuitAmpacity: "Circuit courant admissible minimal",
	minimumValue_: "Valeur minimale:",
	minimumOrderPriceNotReached_: "Prix de la commande minimum non atteint !",
	minus: "Moins",
	miscelaneousRefrigerationEquipment:
		"Équipement de réfrigération de Miscelaneous",
	miscellaneousLoad: "Charge divers",
	miscellaneousLoads: "Charges diverses",
	miscellaneousSelected: "Sélectionné divers",
	missingPriceBook: "Réservez Prix disparus",
	model: "Modèle",
	model_: "Modèle #",
	modelCapacity: "Modèle capacité",
	modelConfiguration: "Configuration du modèle",
	modelFeatures: "Caractéristiques du modèle",
	modelMatchingCriterea: "Modèle Criterea correspondant",
	modelNumber: "Numéro de modèle",
	modelOptions: "Options du modèle",
	modelPartNumber: "Numéro de pièce du modèle",
	modelSelector: "Modèle de sélection",
	modelIsNoLongerAvailable_: "Modèle n'est plus disponible !",
	modelIsNoLongerListedInOurInventory_:
		"Modèle ne figure plus dans notre inventaire!",
	modelMatch: "Modèle match",
	modelMultiplierChanged_: "multiplicateur Modèle changé.",
	modelsAvailable_: "Modèles disponibles:",
	modifyStatus: "Modifier le statut",
	moistureContent: "Teneur en humidité",
	motor: "Moteur",
	motorLoad: "Charge du moteur",
	motorType: "Type de moteur",
	moveConnectionLeft: "Déplacer la connexion gauche",
	moveConnectionRight: "Déplacer vers la droite de connexion",
	moveDown: "Descendre",
	moveFirst: "Jouer en premier",
	moveLast: "Déplacer Dernière",
	moveLeft: "Déplacer vers la gauche",
	moveRight: "Déplacer vers la droite",
	moveToSide: "Passer à côté",
	moveUp: "Déplacer vers le haut",
	multiplier: "Multiplicateur",
	multiplierHasBeenResetToDefaultForThisModelType_:
		"Multiplicateur a été réinitialisé à défaut pour ce type de modèle.",
	multiplierIsLessThanDefaultValue_:
		"Multiplicateur est inférieur à la valeur par défaut: ",
	multiplierIsLessThanMinimumValue_:
		"Multiplicateur est inférieur à la valeur minimale:",
	multipliersUpdated_: "Mise à jour des multiplicateurs.",
	mustBeGreaterThanAirInletTemperature_DB__:
		"Doit être supérieure à la température de l'air d'admission (DB) !",
	mustBeGreaterThanFluidInletTemperature_:
		"Doit être supérieure à la température d'entrée du fluide !",
	mustBeGreaterThanOrEqualToZero_: "Doit être supérieure ou égale à zéro !",
	mustBeGreaterThenOrEqualToMinimumValue_:
		"Il faut ensuite supérieure ou égale à la valeur minimale !",
	mustBeLessThanAirInletTemperature_DB__:
		"Doit être inférieure à la température de l'air d'admission (DB) !",
	mustBeLessThanFluidInletTemperature_:
		"Doit être la température d'entrée du fluide moins !",
	mustBeLessThenOrEqualTo_____: "Doit être inférieure ou égale à 100 % !",
	mustBeLessThenOrEqualToMaximumValue_:
		"Doit être inférieure ou égale à la valeur maximale !",
	myInfo: "Mes infos",
	myProfile: "Mon profil",
	nToN: "N à n",
	n_A_: "NA",
	n_A: "S/O",
	nEW: "NOUVEAU",
	nEWCOMPOSITE: "NOUVEAU COMPOSITE",
	nOPARTSFOUND_: "NO pièces trouvées!",
	nOParametersChanged_: "NO paramètres modifiés.",
	nONE: "AUCUN",
	nOTORDERED: "PAS CONDAMNER",
	nOTORDEREDQUOTE: "PAS ORDONNÉ QUOTE",
	nOTPRINTED_SET: "PAS IMPRIME / SET",
	nOTQUOTED: "Non cotées",
	nOTSAVED: "PAS ENREGISTRE",
	nOTSUBMITTEDORDER: "PAS SOUMIS ordrer",
	nOTE: "REMARQUE",
	nR_Shell: "Nr-Shell",
	nRDOCS_Splash: "NRDOCS_Splash",
	nROES: "NROES",
	nROES_NotesEditor: "NROES - Sous la direction de Notes",
	nROESContact: "Contact NROES",
	nROESSERVER: "NROES SERVEUR",
	nROESServerInterface: "Interface Server NROES",
	nROESServerIsNotAvailable_: "NROES Server n'est pas disponible.",
	nROESIsHavingIssuesCommunicatingWithTheServer_:
		"NROES a des problèmes de communication avec le serveur!",
	nROES__: "NROES-8",
	nR_DOCS_BROWSER: "NR_DOCS_BROWSER",
	name: "Nom",
	nationalRefrigeration: "Nationale Refrigeration",
	nationalRefrigerationAndAirConditioning:
		"Réfrigération et de climatisation nationale",
	nationalRefrigerationDesignTools:
		"Outils de conception de réfrigération national",
	nationalRefrigerationOrderEntrySystem_Bally:
		"Réfrigération Ordre national du système d'entrée - Bally",
	nationalRefrigerationOrderEntrySystem_KeepRite:
		"Réfrigération Ordre national du système d'entrée - KeepRite ",
	nationalRefrigerationOrderEntrySystem_Trenton:
		"Réfrigération Ordre national du système d'entrée - Trenton ",
	nationalRefrigerationOrderFiles_: "Fichiers Ordre National de réfrigération.",
	negativeTemperatureDifference_: "différence de température négative!",
	neitherTheKFactorNorTheR_ValueHasBeenEntered_:
		"Le facteur K, ni la valeur de r est intervenu !",
	neitherTheK_FactorNorTheR_ValueAreEnteredForTheMaterialMaterial_:
		"Le facteur K, ni la valeur r sont entrés pour le matériel, matériel !",
	neitherTheK_FactorNorTheR_ValueAreEnteredForTheDividerMaterial_:
		"Le facteur K, ni la valeur r sont entrés pour le matériel de diviseur !",
	neitherTheK_FactorNorTheR_ValueHaveBeenSet_:
		"Le facteur K, ni la valeur r ont été fixés !",
	netPrice: "Prix net",
	new: "Nouveau",
	newJob_KnownLoads: "New Job - charges connues",
	newJob_KnownModel: "New Job - modèle connu",
	newMargin: "Marge Nouveau",
	newMultipler: "Nouveau multiplicateur",
	newOrder: "Nouvel Ordre",
	newOrderPrice: "Prix ​​de New Order",
	newPassword: "Nouveau mot de passe",
	newPrice: "Nouveau Prix",
	newQuotePrice: "Demande de prix Nouveau",
	newSystem_KnownLoads: "Nouveau système - des charges connues",
	newSystem_KnownModel: "Nouveau système - modèle connu",
	newUser: "Nouvel utilisateur",
	newNetPrice_: "Nouveau prix net:",
	newPasswordMustBeAtLeastSixCharactersInLength_:
		"Nouveau mot de passe doit être d'au moins six caractères de long!",
	newPasswordsDoNotMatch_: "nouveaux mots de passe ne correspondent pas!",
	newServicePartsAddedToOrder_: "Nouveau service de pièces ajoutées à l'ordre:",
	newUserNameRegistered_: "Nouveau nom d'utilisateur enregistré !",
	next: "Prochaine",
	nextModel_: "Prochain modèle >",
	nextWallConstruction: "Prochaine Construction d'un mur",
	no: "N",
	noCountryEntered_: "Aucun pays entré !",
	noDetails: "Pas de détails",
	noItemsOrSystemsSelected_: "Aucun articles ou les systèmes sélectionnés.",
	noModelSelected: "Aucun modèle sélectionné",
	noModelSelected_: "Aucun modèle choisi!",
	noModelsAvailable: "Pas de modèles disponibles",
	noPartNameEntered_: "Aucun nom de partie entré !",
	noAddressEntered_: "Aucune adresse saisie !",
	noAlternativePriceBooksOfThisTypeAreAvailable_:
		"Pas de livres Prix alternative de ce type sont disponibles.",
	noAmbientTempEntered_: "Aucun temp ambiante est entré.",
	noApplicationRegistrationOnformationWasFoundForYourUserID_:
		"Aucune information d'inscription application n'introuvable pour votre ID d'utilisateur.",
	noBoxTempEntered_: "Aucun temp de la boîte est entré.",
	noCalculationMethodSelected_: "Aucune méthode de calcul choisie !",
	noChangesDetectedOnTheOrder_: "Aucun changement détecté de l'ordre.",
	noCityEntered_: "Aucune ville entré !",
	noCondenserModelSelected_: "Aucun modèle de condenseur sélectionné !",
	noCondensingTempEntered_: "Aucune condensation temp est entré.",
	noConnectionsDefined_: "Aucune connexion définie !",
	noConstructionLayersHaveBeenDefined_:
		"Sans couches de construction ont été définies !",
	noContactNameGiven_: "Aucun nom de contact donné !",
	noContactsListed_: "Aucune liste de contacts ci.",
	noDeratingFactorListed_: "Aucun facteur de déclassement énumérés!",
	noDescriptionEntered_: "Aucune description entrée !",
	noDoorsEstimateCalculatedOrEntered_:
		"Aucuns portes n'estiment calculé ou entré !",
	noDoorsHaveBeenDefined_: "Aucuns portes n'ont été définies !",
	noDoorsHaveBeenDefined_DefaultDoorLoadValueUsedForOneManDoor_:
		"Aucuns portes n'ont été définies ! Par défaut la valeur de charge de porte utilisée pour une porte de l'homme.",
	noEquipmentChangesFound_: "Aucun changement matériel trouvé.",
	noEquipmentChangesOccurred_: "Aucun changement d'équipement a eu lieu.",
	noEvaporatorMotorLoadHasBeenCalculatedOrEntered_:
		"Aucune charge évaporateur du moteur a été calculé ou entré !",
	noFeedsAssigned_: "Aucun flux assignés !",
	noFluidSelected_: "Aucun fluide sélectionné !",
	noFutureNonWorkingDaysWereListed_:
		"Pas d'avenir non jours ouvrables ont été répertoriés.",
	noInternetConnection_: "Aucune connexion internet.",
	noItemDeliveryDatesHaveBeenSelected_:
		"Aucune date de livraison élément ont été choisis!",
	noLightingLoadHasBeenCalculatedOrEntered_:
		"Aucune charge d'éclairage a été calculé ou entré !",
	noLoadHasBeenCalculatedOrEntered_: "Aucune charge n'a été calculé ou entré !",
	noModelVARatingValuesListed_: "Pas d'évaluation modèle VA valeurs indiquées.",
	noModelRatingValuesListed_: "Pas d'évaluation du modèle des valeurs cotées.",
	noModelsAvailableForThisHighAFlowRate_:
		"Aucun des modèles disponibles pour ce haut débit !",
	noOrdersListed_: "Aucune ordre énumérés!",
	noPartsSelected_: "Aucune des parties sélectionnées!",
	noPhoneNumberEntered_: "Aucun numéro de téléphone entré !",
	noPowerValueEntered_: "Aucune valeur de puissance entrée !",
	noPriceBooksHaveBeenAssignedToYourProfile_:
		"Pas de livres de prix ont été attribués à votre profil.",
	noPriceChangesOccurred_: "Aucun changement de prix a eu lieu.",
	noPricesWereChanged_: "Pas de prix ont été modifiés.",
	noPricingChangesOccurred_: "Aucun changement de prix a eu lieu.",
	noQuantityEntered_: "Aucune quantité entrée !",
	noRefrigerantSelected_: "Aucun réfrigérant sélectionné !",
	noRevisionsWereUpdated_: "Aucune révision n'a été mis à jour.",
	noShippingAndHandlingCostEntered_:
		"Aucune expédition et de manutention coût entré !",
	noState_ProvinceOrCountyEntered_:
		"Aucun État, d'une province ou d'un comté entré !",
	noSuctionTempEntered_: "Aucune aspiration temp est entré.",
	noSuitableFeedConfigurationFound_:
		"Aucune configuration de flux adaptée trouvée.",
	noTotalLoadHasBeenCalculatedOrEntered_:
		"Aucune charge totale a été calculé ou entré !",
	noTotalProductLoadHasBeenCalculatedOrEntered_:
		"Aucune charge de produit total a été calculé ou entré !",
	noTotalProductWeightHasBeenCalculatedOrEntered_:
		"Aucun poids total du produit a été calculé ou entré !",
	noUsageEstimateCalculatedOrEntered_:
		"Aucune estimation de l'utilisation calculé ou entré !",
	noUsageLevelSelected_: "Aucune utilisation de niveau sélectionnée !",
	noVentilationEstimateCalculatedOrEntered_:
		"Aucune estimation de ventilation calculé ou entré !",
	noVersion_OrdersFoundInThisList_: "N ° 8 version ordre dans cette liste!",
	noZipOrPostalCodeEntered_: "Aucun zip ou code postal entré !",
	no_CancelThisOperation: "Non, annuler cette opération",
	no_IAmANewUser_: "Non, je suis un nouvel utilisateur.",
	no_I_llDoItManually_: "Non, je vais le faire manuellement.",
	no_TryToConnectAgain_: "Non, essayez de vous reconnecter.",
	no_LeaveTheExistingOptionMultipliersUnchanged_:
		"Non, laissez les multiplicateurs option existante inchangée.",
	no_RestorePreviousValue_: "Non, restaurer la valeur précédente.",
	non_CatalogItemDetails: "Détails de l'article non-Catalogue",
	non_CatalogOptionDetails: "Détails option non-Catalogue",
	non_numericJob_ScopeReferenceReturned_:
		"Non numériques Référence de l'emploi-Portée de retour!",
	none: "Aucun",
	notOwner: "Non Propriétaire",
	notFound_: "Pas trouvé!",
	notLatestCO: "Pas plus tard CO",
	note_AnInternetConnectionIsRequiredToCompleteThisProcedure:
		"Note: Une connexion Internet est nécessaire pour effectuer cette procédure",
	note_: "Remarque :",
	note_IfYouAnswerNO_NewProductsAddedToThisOrderMayNotUseTheCorrectMultiplier_:
		"Note: Si vous répondez NON, les nouveaux produits ajoutés à cet ordre ne peut pas utiliser le bon multiplicateur?",
	note_IndoorValueOnly__TheExteriorBoundaryLayerIsAdjusted_AutomaticallyIfSectionIsMarkedAsOutdoors:
		"Remarque : Valeur intérieure seulement.  ~ La couche externe de la limite est ajustée ~ automatiquement si l'article est marqué comme à l'extérieur",
	note_OrderOriginatorsAndOrderDeskWillAutomaticallyBeIncludedInTheNotifyList:
		"Note: donneurs d'ordre et d'un bureau ordre sera automatiquement inclus dans la liste de notification",
	notes: "Notes",
	notes_Comments: "Notes / Commentaires",
	notes_Remarks: "Notes / Remarques",
	nothingToDo_: "Rien à faire.",
	numberOfUnits: "Nombre d'unités",
	numericValueRequired_: "Valeur numérique nécessaire.",
	o_ptions: "O & ptions",
	oE: "OE",
	oELIST: "OE LISTE",
	oEList: "Liste OE",
	oEM: "OEM",
	oFFLINE: "DÉCONNECTÉ",
	oK: "Bien",
	oNHOLD: "EN ATTENTE",
	oPENACTIVEORDERMANAGER: "OUVREZ LE GESTIONNAIRE DE COMMANDE ACTIVE",
	oPENEXISTINGORDER: "OUVREZ LES RANGS EXISTANT",
	oPENPURCHAREORDERMANAGER: "OUVREZ LE GESTIONNAIRE D'ORDRE PURCHARE",
	oRDER: "Commander",
	oRDERCONTAINS: "Contient le Décret",
	oRDERCONTAINSCALLPRICING_: "ordre contient PRIX APPEL!",
	oRDERENTRYSYSTEM: "SYSTEME DE SAISIE DE ordre",
	oRDERNO: "ORDONNANCE NO",
	oRDERNOTES: "NOTES POUR",
	oRDERSTATUS: "État de la ordre",
	oRDERVERIFIED: "ORDRE VERIFIED",
	oRDERED: "CONDAMNER",
	oTHER: "AUTRES",
	oTHERLOADS: "AUTRES CHARGES",
	obsolete: "Obsolètes",
	off_LineWarning_: "Off-Line avertissement.",
	oldPassword: "Ancien mot de passe",
	oldPasswordDoesNotMatch_: "Ancien mot de passe ne correspond pas!",
	oneEquipmentChangeFound_: "Un changement d'équipement trouvé.",
	onePriceChanged_: "Un prix a changé.",
	oneRevisionUpdated: "Une révision de mise à jour",
	open: "Ouverte",
	openBallyTools: "Open Tools Bally",
	openExistingJob: "Ouvrez emplois existants",
	openFromWeb: "Ouvrir à partir du Web",
	openKeepRiteTools: "Open Tools KeepRite",
	openOrder: "Open Ordre",
	openOrder_ForceSynch: "Commande en cours - Force Synch",
	openTrentonTools: "Outils de Trenton ouvert",
	openAsNewOrder: "Ouvrir en tant que nouvel ordre",
	openFileInEXCEL_: "Ouvrir le fichier dans Excel?",
	openFileInTextViewer_: "Ouvrir le fichier dans la visionneuse de texte?",
	openLastSavedEditOrder_: "Ouvrez le dernier enregistrement EditOrder.",
	openingType: "Type d'ouverture",
	openingDeletionRequested_: "Suppression d'ouverture demandée.",
	openingsSelected: "Ouvertures sélectionnés",
	oppositeEnds: "Extrémités opposées",
	option: "Option",
	optionPrice: "Prix de l'option",
	optionSelector: "Option de sélection",
	optionIsNoLongerAvailable_: "Option n'est plus disponible!",
	optionNeedsFactoryLeadTime_PleaseCall_:
		"Option a besoin de temps usine. S'il vous plaît appelez !",
	optionNeedsFactoryPricing_PleaseCall_:
		"Option a besoin de prix usine. S'il vous plaît appelez !",
	optional: "En option",
	options: "Options",
	optionsOnThisItemHaveErrors_: "Options sur ce point ont des erreurs !",
	optionsOnThisItemHaveWarnings_: "Options sur ce point ont mises en garde !",
	orEnterAModelNumber: "Ou entrez un numéro de modèle",
	order: "ordrez",
	orderDate: "Date de ordre",
	orderEntry: "Saisie d'une ordre",
	orderEntryListPrice: "Prix Ordre Entry List",
	orderEntryMultiplier: "Saisie d'une ordre multiplicateur",
	orderEntrySystem: "Système d'entrée de commandes",
	orderForm: "Formulaire de ordre",
	orderItemNo: "Point d'ordre",
	orderManager: "Gestionnaire Afin",
	orderManager_COLLATING: "Order Manager - ASSEMBLAGE",
	orderNumericUnits: "Unités de ordre numérique",
	orderPreview: "Aperçu de la ordre",
	orderPreview_NoOrderSelected:
		"Aperçu de la ordre - Pas de ordre sélectionnée",
	orderPreview_NotAvailable: "Aperçu de la commande - Non disponible",
	orderPrice: "ordre Prix",
	orderStatus: "État de la ordre",
	orderUploadConfirmationCheck: "ordrez Envoyer Vérifiez Confirmation",
	orderCanNotBeImported_: "La ordre ne peut être importé.",
	orderChangeStopRequested_: "modification de ordre l'arrêt demandé.",
	orderChangesHaveYetToBeUploadedToTheServer_:
		"Ordre des changements doivent encore être téléchargé sur le serveur.",
	orderImported_: "ordrez importés.",
	orderIncludesIncompleteFactoryPricingInformation_:
		"Ordre comprend une information incomplète sur les prix d'usine!",
	orderInformationAlreadyExistsInTheProgramOrderStore_:
		"Informations pour la ordre existe déjà dans le magasin de l'ordre du programme.",
	orderIsAlreadyLoadedLocally_: "Ordre est déjà chargé au niveau local.",
	orderIsOpenForReviewAndPrintOnly_:
		"Ordre est ouvert aux fins d'examen et d'imprimer uniquement!",
	orderPriceTooLowForPre_paidShippingTerms_:
		"Prix de la commande trop bas pour les conditions d'expédition prépayées ! ",
	orderStopRequested_: "Ordre d'arrêt demandé.",
	orderWasDeleted_: "Ordre a été supprimé.",
	orderWillBeUploadedAfterNextProgramUpdate_:
		"Ordre sera transféré après mise à jour du prochain programme.",
	ordered: "Ordonné",
	orders: "ordres",
	orientation: "Orientation",
	original: "Langue source",
	other: "Autres",
	otherCountryName: "Nom Pays Autres",
	otherEquipmentManufacturer_sModel: "Autre Equipment Manufacturer Model",
	otherHeatSources: "Autres Sources de chaleur",
	otherName: "Autre nom",
	otherDeletionRequested_: "Autre suppression demandée.",
	outOfRange_ShouldBe__And______:
		"Hors de portée, devrait être &gt; 0 et 0,01 !",
	outdoors: "À l'extérieur",
	p_O: "PO",
	p_O_: "PO",
	pARAMETERSREQUIRECHECKINGORADJUSTMENT:
		"Paramètres exigent contrôle ou de réglage",
	pARSING: "L'ANALYSE DE",
	pARTNOTFOUNDINDATABASE_: "PARTIE NON TROUVÉ DANS LA BASE DE DONNÉES !",
	pARTIAL: "PARTIEL",
	pENDING: "PENDANT",
	pERSONNEL: "PERSONNEL",
	pICK_UP: "PICK-UP",
	pO_: "PO #",
	pOWER: "PUISSANCE",
	pRD: "PRD",
	pRE_PAID: "PRÉ-PAYÉS",
	pRICEBOOKNOTAVAILABLE: "LIVRE Prix non disponible",
	pRICEEACH: "Prix unitaire",
	pROCESSED: "Processed",
	pRODUCTLINE: "GAMME DE PRODUITS",
	pRODUCTNO: "PRODUIT NON",
	pROJECT: "PROJET",
	pROTECTED: "PROTÉGÉ",
	package: "Paquet",
	packageOptionsAreHighlightedInGreen_:
		"Paquet options sont surlignés en vert.",
	panel: "Panneau",
	panelExteriorIsOutdoors: "Panneau extérieur est à l'extérieur",
	parametersCheckCompleted_: "Paramètres de contrôle terminée.",
	params: "Params",
	partDescription: "Description de la pièce",
	partDescriptions: "Descriptions partie",
	partNo: "Partie n",
	partDescriptionRequired_: "Description partie requise !",
	partialShipmentSelected_: "livraisons partielles sélectionnés!",
	partialShippingRequested_ButNoSpecificShipDatesListedOnSystemsOrItems_:
		"livraison partielle demandée, mais aucun navire spécifiques dates figurant sur les systèmes ou les articles!",
	parts: "Pièces",
	password: "Mot de passe",
	passwordAgain: "Mot de passe à nouveau",
	passwordRequired_: "Mot de passe requis.",
	paste: "Coller",
	pasteOptions_: "options de collage.",
	pastingNonStockingModelsOnAStockingOrderIsProhibited_:
		"Coller les modèles non bas sur un ordre de stockage est interdite.",
	pastingStockingModelsOnAStandardOrderIsProhibited_:
		"Collant bas modèles sur un ordre standard est interdite.",
	perUnit: "Par unité",
	percentageChangeCausedByEnteredAdjustmentValue_:
		"Pourcentage de variation causée par la valeur de réglage est entré.",
	percentageDifferenceBetweenCurrentPriceAndFloorPrice_:
		"Pourcentage de différence entre prix actuel et prix plancher.",
	percentageDifferenceBetweenNewPriceAndCurrentPrice_:
		"Pourcentage de différence entre le nouveau prix et le prix actuel.",
	percentageDifferenceBetweenNewPriceAndFloorPrice_:
		"Pourcentage de différence entre le nouveau prix et le prix plancher.",
	perimeterLength: "Longueur de périmètre",
	permanentOpening: "Ouverture permanente",
	personalInformation: "Renseignements personnels",
	personnelLoad: "Charge du personnel",
	ph_: "PH : ",
	phone: "Téléphone",
	phoneNo: "Téléphone non",
	phoneNumbers: "Numéros de téléphone",
	pick_Up: "Pick-Up",
	pleaseDescribe: "S'il vous plaît décrire",
	pleaseNote_: "S'il vous plaît Note:",
	pleaseSelect: "Veuillez sélectionner",
	pleaseSelectAModelType: "Veuillez sélectionner un Type de modèle",
	pleaseSelectObjectsToInclude:
		"S'il vous plaît sélectionner les objets à inclure",
	pleaseSelectRatingOptions: "S'il vous plaît sélectionnez Options de notation",
	pleaseAdjustTemperaturesOrChangeRefrigerant_:
		"Veuillez ajuster les températures ou changer de réfrigérant.",
	pleaseAdjustTemperaturesOrSelectAndEnterT_H_R_Value_:
		"Ajuster les températures ou sélectionnez et entrez la valeur T.H.R..",
	pleaseCheckAllItems_: "S'il vous plaît vérifier tous les articles!",
	pleaseCheckInternetAndTryAgain_:
		"S'il vous plaît vérifier Internet et essayez à nouveau.",
	pleaseCheckServerConnectionAndTryAgain_:
		"S'il vous plaît vérifiez la connexion au serveur et essayez à nouveau.",
	pleaseCheckThemIndividually_:
		"S'il vous plaît vérifier eux individuellement.",
	pleaseCheckYourPermissionsAndOrCloseThisApplicationAndRunTheBootstrapManually_:
		"S'il vous plaît vérifier vos autorisations et / ou fermer cette application et exécutez le bootstrap manuellement.",
	pleaseCheckYourPermissionsAndOrDeleteThisFileManually_:
		"S'il vous plaît vérifier vos autorisations et / ou supprimer ce fichier manuellement.",
	pleaseCheckYourPermissionsAndOrMoveThisFileManually_:
		"S'il vous plaît vérifier vos autorisations et / ou déplacer ce fichier manuellement.",
	pleaseCheckYourProfileAndUpdateTheSoldByAddressPanel_:
		"S'il vous plaît vérifier votre profil et mettre à jour l'adresse vendu par panneau.",
	pleaseClickOnTheNEWButtonToBeginAddingYourFirstContact_:
		"S'il vous plaît cliquer sur le bouton NOUVEAU pour ajouter votre premier contact.",
	pleaseCloseAllInstancesOfNROESAndRestartTheApplication_:
		"S'il vous plaît fermer toutes les instances de NROES et redémarrer l'application!",
	pleaseCloseAllRefrigerationAppsClickOnRetry_:
		"Veuillez fermer toutes les applications de réfrigération cliquez sur Recommencer !",
	pleaseCloseAllRefrigerationToolsAndRestartTheApplication_:
		"S'il vous plaît fermez tous les outils de réfrigération et redémarrer l'application !",
	pleaseCloseAllToolsAndRestartTheApplication_:
		"S'il vous plaît fermez tous les outils et redémarrer l'application !",
	pleaseCompleteRequiredInformation_:
		"S'il vous plaît compléter les informations requises.",
	pleaseConfirmTheFollowing: "S'il vous plaît confirmer ce qui suit",
	pleaseConfirm_: "S'il vous plaît confirmer.",
	pleaseContact: "S'il vous plaît contacter",
	pleaseContactSupportTeam_: "S'il vous plaît contacter l'équipe de soutien.",
	pleaseContactTheDevelopmentTeam_:
		"S'il vous plaît contacter l'équipe de développement.",
	pleaseContactTheFactoryToHaveYourProfileAdjusted_:
		"S'il vous plaît contacter l'usine pour avoir votre profil adapté.",
	pleaseContactTheManufacturerForServicePartInformation_:
		"S'il vous plaît contacter le fabricant pour partie, des informations de service.",
	pleaseDescribeTheReasonForThisExceptionBelowAnd:
		"Veuillez indiquer la raison de cette exception ci-dessous et",
	pleaseEnterJob_ScopeReference_:
		"S'il vous plaît entrer Référence de l'emploi-Scope!",
	pleaseEnterANumericValueGreaterZero_:
		"S'il vous plaît entrer une valeur numérique supérieure à zéro.",
	pleaseEnterAllThePrimaryDimensionInformationFirst_:
		"Veuillez entrer toutes les informations de dimension première tout d'abord.",
	pleaseEnterCustomerReferenceNumber_:
		"S'il vous plaît entrez le numéro de référence du client!",
	pleaseEnterExpectedShippingCarried_:
		"S'il vous plaît entrez devrait expédition menée!",
	pleaseEnterYourPersonalApplicationRegistrationCode:
		"Veuillez entrer votre code d'enregistrement de demande personnelle",
	pleaseInstallCustomerDatabaseFileIntoYourApplicationProgramDataFolder_:
		"S'il vous plaît installer le fichier de base de données client dans votre programme de données dossier de l'application!",
	pleaseQuoteFACTORYORDER__: "S'il vous plaît citer de ordre d'#.",
	pleaseRestartTheApplication: "S'il vous plaît redémarrer l'application",
	pleaseRestartTheApplicationWithTheInternetConnected_:
		"S'il vous plaît redémarrer l'application avec le connecté à Internet.",
	pleaseSaveYourWorkAndCloseAllDesignWindows_:
		"Veuillez enregistrer votre travail et fermez toutes les fenêtres de conception.",
	pleaseSelectACompressor_: "Veuillez sélectionner un compresseur !",
	pleaseSelectACountryFromTheList_:
		"Veuillez sélectionner un pays dans la liste !",
	pleaseSelectADivision: "S'il vous plaît sélectionnez une division",
	pleaseSelectAMatchingModelNumberFirst_:
		"S'il vous plaît sélectionner un numéro de modèle d'appariement en premier.",
	pleaseSelectAPriceBook: "S'il vous plaît sélectionnez une liste de prix",
	pleaseSelectAnotherFile_: "S'il vous plaît sélectionner un autre fichier!",
	pleaseSelectOrderType_: "S'il vous plaît sélectionner le type de ordre!",
	pleaseSelectServicePartTypesToAddToOrder_:
		"S'il vous plaît sélectionner les types de pièce de rechange pour ajouter à l'ordre.",
	pleaseSelectTheSameSystemModelType_:
		"Veuillez sélectionner le même type de modèle de système.",
	pleaseSelectTheTypeOfSystemYouWantToAdd:
		"S'il vous plaît sélectionner le type de système que vous souhaitez ajouter",
	pleaseTryAgainLater_: "S'il vous plaît réessayer plus tard!",
	pleaseTryAgain_: "S'il vous plaît essayez de nouveau.",
	pleaseUpdateYourPrices_: "S'il vous plaît mettre à jour vos prix!",
	pleaseUseTheOptionsSelectorToAdjustTheseOptions_:
		"S'il vous plaît utiliser le sélecteur d'options pour régler ces options.",
	pleaseUseTheOrderManagerToOpenThisOrder_:
		"S'il vous plaît utiliser le gestionnaire afin d'ouvrir la présente ordonnance.",
	pleaseWait_: "Veuillez patienter!",
	plus: "Plus",
	popup_Tooltip: "Popup_Tooltip",
	postal: "Postaux",
	postal_ZIPCode: "Code postal / ZIP",
	postalCode: "Code postal",
	power: "Puissance",
	powerDensity: "Densité de puissance",
	powerSearch: "Recherche avancée",
	power_Deg: "Puissance/Deg.",
	pr_St: "Pr / St",
	pre_view: "Pré & view",
	pre_Paid_Charge: "Pré-Payés Et Frais",
	pre_paid: "Pré-payé",
	pressure: "Pression",
	pressureDropExceedsRequestedRange_:
		"Chute de pression est supérieure a demandé la gamme !",
	pressureDropRange: "Plage de chute de pression",
	pressureDropTolerance: "Tolérance de chute de pression",
	pressureDropTooLow_: "Chute de pression trop bas !",
	pressureRange: "Plage de pression",
	preview: "Extrait",
	previousNetPrice_: "Précédent prix net:",
	price: "Prix",
	priceBook: "Prix Livre",
	priceChange: "Changement de prix",
	priceOK: "Prix OK",
	priceBookUpdated_: "Mise à jour le catalogue des prix.",
	priceBookHasExpired_: "Catalogue des prix a expiré !",
	priceBookHasExpiredAndTheQuoteIsOlderThan__Days_:
		"livre Prix a expiré et que la citation est de plus de 30 jours!",
	priceBookIsMissing_: "Prix ​​livre est manquant!",
	priceBookNotUpdated_: "Prix ​​livre pas mis à jour.",
	priceDidNotChange_: "Prix n'a pas changé!",
	pricebookHasExpired: "Pricebook a expiré",
	pricebookHasExpired_: "Commande a expiré !",
	pricebookMissing: "Pricebook disparus",
	pricebookNotLoaded_: "Pricebook pas chargé.",
	pricebookWillExpireIn: "Pricebook arrivera à échéance en",
	pricingChangeOccurredOnOneRevision_:
		"changement de prix s'est produite sur une révision.",
	pricingChangesOccurredOn: "Les changements de prix a eu lieu le",
	pricingShownIncludesAllOptionsListedOnCertifiedPrints_:
		"Les prix indiqués comprennent toutes les options cotées sur certifié impressions.",
	primary: "Primaire",
	print: "Imprimer",
	printPreview: "Aperçu avant impression",
	printSelectedModelOnly: "Imprimer la sélection du modèle uniquement",
	printing: "Impression",
	processChangeOrderRequest: "Demande ordre de modification",
	processOrder: "Processus de ordre",
	processingNotes: "Traitement des Notes",
	product: "Produit",
	productDetails: "Détails du produit",
	productLine: "Ligne de produits",
	productList: "Liste des produits",
	productLoad: "Charge de produit",
	productType: "Type de produit",
	productDeletionRequested_: "Suppression du produit demandée.",
	productsSelected: "Produits sélectionnés",
	profitRate: "Taux de profit",
	programUpdateAvailable_: "Programme de mise à jour disponible.",
	programUpdatesFound: "Mises à jour de programme trouvés",
	programUpdatesShouldBeAvailableShortly_:
		"mises à jour du programme devrait être disponible prochainement.",
	project: "Projet",
	promise: "Promesse",
	promiseDate: "Date De Promesse",
	promised: "Promis",
	protectCurrentRevision: "Protéger révision actuelle",
	province: "Province",
	pulldownTime: "Menu déroulant fois",
	purchaseOrder: "Bon de commande",
	purchaseOrders: "Les ordres d'achat",
	purchaseRequests: "Demandes d'achat",
	purchaser: "Acheteur",
	qTY: "Quantité",
	qUOTE: "DEVIS",
	qUOTEDAYS: "QUOTE JOURS",
	qtty: "Qtty",
	qty: "Qté",
	quantity: "Quantité",
	quantityPerUnit: "Quantité par unité",
	quickBox: "Champ rapide",
	quotationValidFor__DaysFromQuoteDate_:
		"valable 30 jours à compter de la date devis devis.",
	quote: "Citer",
	quote_: "Citer Le Numéro",
	quoteDate: "Citation Date",
	quoteNo: "Quote n",
	quotePrice: "Demande de prix",
	quoteSetup: "Citer le programme d'installation",
	quoteDateIsNotSetToCurrentDate_:
		"Citer date n'est pas définie à ce jour en cours!",
	rValue: "Valeur R",
	r_Value: "Valeur r",
	r____A_R____CombinationNoLongerAvailable_R____ASelected_:
		"R404-A/R-507 combinaison n'est plus disponible, R-404 a sélectionné.",
	rATING: "EVALUATION",
	rEADONLY: "EN LECTURE SEULE",
	rEADY: "PRÊT",
	rECTANGULAR: "RECTANGULAIRE",
	rEF: "RÉF",
	rEFf: "REFF",
	rEQDate: "Date Dem",
	rEQSHIPDATE_: "Date d'expédition REQ:",
	rETRIEVENROESORDERFROMSERVER: "RÉCUPÉRER L'ORDRE NROES DU SERVEUR",
	rLA: "RLA",
	rOUTING: "ROUTAGE",
	rUN: "EXÉCUTER",
	rUNNUMBER: "Numéro de run",
	range: "Gamme",
	ratedAt: "Évalué à",
	rating: "Note",
	ratingOptions: "Options de notation",
	rear: "Arrière",
	rearrangeItemOrder: "Réorganiser l'ordre des Point",
	rearrangeItems: "Réorganiser les éléments",
	rearrangeSystemOrder_RenameSystems:
		"Réorganiser les systèmes d'ordre Renommer système /",
	rearrangeSystems: "Réorganiser les systèmes",
	rebuildFactoryOrder: "Commandez Factory Reconstruire",
	rebuildFactoryOrderRequested_:
		"commande à l'usine de reconstruction demandé.",
	recentOrders: "Les ordres récentes",
	recommendedQuantity: "Recommandé Quantité",
	recoverMyEdits_: "Récupérer mes modifications.",
	rectangularBoxDimensions: "Dimensions de la boîte rectangulaire",
	redo: "Refaire",
	reference: "Référence",
	refresh: "Actualiser",
	refreshOrderList: "Liste de ordre Refresh",
	refrigerant: "Frigorigène",
	refrigerantFactor: "Facteur de réfrigérant",
	refrigerantFactorNotAvailable_: "Réfrigérant facteur n'est pas disponible.",
	refrigerantFactorOutOfRange_: "Réfrigérant facteur hors de portée.",
	refrigerantNotSelected_: "Réfrigérant ne pas sélectionnée !",
	refrigerants: "Fluides frigorigènes",
	refrigerationDesignTools: "Outils de conception de réfrigération",
	refrigerationSystemDetails: "Détails du système de réfrigération",
	regBuild: "Reg Construire",
	region: "Région",
	regularShipDate_: "Date d'expédition régulière: ",
	regularUse: "Utilisation régulière",
	rejectOrder: "Refuser la ordre",
	rejected: "Rejetée",
	rejectingAnOrderWillPreventTheOriginatorFromResubmittingThisOrder_:
		"Rejetant une ordre empêche l'expéditeur de présenter à nouveau cet ordre!",
	releaseNotes: "Notes de publication",
	remarks_Notes: "Remarques / Notes",
	remarks_TermsAndConditions: "Remarques / Termes et Conditions",
	rememberMeOnThisComputer: "Se souvenir de moi sur cet ordinateur",
	remo_ve: "Remo & ve",
	remoteOrderLockNotEstablished_:
		"Afin de verrouillage à distance n'est pas établi.",
	remoteVersionModificationTimestamp_:
		"horodatage à distance modification version:",
	remoteVersionUploadTimestamp_:
		"version téléchargement à distance d'horodatage:",
	remove: "Supprimer",
	remove_LocalOrder: "Commande Remove & Local",
	removeFeed: "Retirer RSS",
	removeLastWall: "Supprimer le dernier mur",
	removeLocalOrder: "Supprimer la commande locale",
	removeModel_Item: "Supprimer Modèle / Point",
	removeSystem: "Retirer du système",
	renumberIDs: "Renuméroter IDs",
	replaceAllItems_: "Remplacer tous les éléments.",
	replaceLocalOrderWithMasterVersion_:
		"Remplacer ordre local avec la version maître.",
	replaceSelectedItems_: "Remplacer les éléments sélectionnés.",
	replaceSelectedSystem_: "Remplacer le système sélectionné.",
	replaceTheOrderInformationFromTheSelectedFile_:
		"Remplacer les informations de ordre à partir du fichier sélectionné.",
	requested: "Requis",
	requestedChanges_PleaseUpdateJob_ScopeAndCheckOffEachItem:
		"Modifications à la demande - S'il vous plaît mettre à jour Job-Champ d'application et de cocher chaque point",
	requestedDate_: "Date souhaitée: ",
	requestedDateException: "Exception Date souhaitée",
	requestedItemShipDateIsLessThanItemLeadTime_:
		"Demandé la date d'expédition élément est inférieure à délais article!",
	requestedOrderShipDateIsLessThanLeadTime_:
		"Demandé la date d'expédition d'ordre est moins que le temps avance!",
	requestedSystemShipDateIsLessThanLeadTime_:
		"Demandé la date de livraison du système est de moins de délais!",
	required: "Requis",
	requiredCapacity: "Capacité requise",
	requiredCapacity_Unit: "Requis capacité / unité",
	requiredCapacityIsNotSet_: "Capacité requise n'est pas définie !",
	requiredCapacityPerUnit: "Capacité requise par unité",
	requiredCapacityTolerance: "Tolérance de capacité requis",
	requiredOptions: "Options requises",
	requiredRatingOptions: "Requise de note des Options",
	requiredShipDate: "Date d'expédition requis",
	requiredValue_: "Une valeur est requise!",
	requirementDatesInThePast_: "Exigence dates dans le passé!",
	resetColumnWidths: "La largeur des colonnes Reset",
	resizeColumns: "Redimensionner les colonnes",
	restoreQuoteRevisionRequested_: "révision devis de restauration demandée.",
	restoreTheOrderInformationFromTheOrderStore_:
		"Restaurer les données de ordre dans le magasin de ordre.",
	resultingPriceIsLowerThanFloorPrice_:
		"prix qui en résulte est inférieur au prix plancher!",
	retrieveOrder: "Récupérer Ordre",
	retry: "Réessayer",
	rev: "Rev",
	revision: "Révision",
	revisionContainsErrors_: "Révision contient des erreurs!",
	revisionContainsWarnings_: "Révision contient des avertissements!",
	rightEnd: "Extrémité droite",
	rightClickOnTheQuoteTabsOnTheTopLeftOfTheOrderWindow_:
		"Faites un clic droit sur les onglets citation en haut à gauche de la fenêtre de commande.",
	rounding: "Arrondissement",
	runNumber: "Nombre Run",
	runTime: "Moment de l'exécution",
	sAndH: "S et H",
	s_C_T_: "SCT",
	s_S_T_: "SST",
	sCT: "SCT",
	sECTIONLIST: "LISTE DE LA SECTION",
	sEEITEMS: "VOIR LES ARTICLES",
	sELECTEDMODELDETAILS: "DÉTAILS DU MODÈLE SÉLECTIONNÉ",
	sERVERERROR_: "ERREUR DE SERVEUR !",
	sERVICEPARTSCATALOG: "CATALOGUE DE PIÈCES DE SERVICE",
	sHIPTO: "NAVIRE",
	sHIPPEDLOOSE: "Livré séparément",
	sIDE_: "CÔTÉ 1",
	sITEVOLTAGES: "SITE DES TENSIONS",
	sOLDBY: "VENDU PAR",
	sOLDTO: "VENDU",
	sST: "SST",
	sTARTING: "À PARTIR",
	sTATE: "ETAT",
	sTOPSUBMITTEDORDERORCHANGE: "STOP SOUMIS POUR OU CHANGEMENT",
	sUBMITNOTES: "NOTES SOUMETTRE",
	sUBMITORDER: "SOUMETTRE LA ordre",
	sUBMITTED: "SOUMIS",
	sUBMITTEDC_O_: "SOUMIS CO",
	sUITABLEREFRIGERANTS: "RÉFRIGÉRANTS APTE",
	sYNCHRONIZING: "SYNCHRONISATION",
	sYS: "SYS",
	sYSTEM: "SYSTEME",
	sYSTEMBALANCE: "BALANCE DE SYSTÈME",
	sYSTEMBULLETIN_: "SYSTEME DE BULLETIN!",
	sYSTEMREFRIGERANT: "SYSTÈME DE RÉFRIGÉRATION",
	sales: "Ventes",
	salesman: "Vendeur",
	sameEnd: "Même fin",
	saturatedCondensingTemperature: "Température de condensation saturée",
	saturatedSuctionTemperature: "Température d'aspiration saturée",
	save: "Enregistrer",
	saveAllOrders: "Enregistrer toutes les ordres",
	saveAsDefault: "Enregistrer par défaut",
	saveAsDefaults: "Enregistrer comme valeurs par défaut",
	saveAsSiteDefaults: "Enregistrer comme valeurs par défaut du Site",
	saveChanges: "Enregistrer les modifications",
	saveOrder: "Enregistrer l'ordre",
	saveOrderParameterChanges_:
		"Enregistrer l'ordre des changements de paramètres?",
	saveChangedContactInformation_:
		"Sauvegardez les changements informations de contact.",
	saveFailed_: "Échec de l'enregistrement!",
	saveMyChanges_: "Enregistrer mes modifications.",
	saveTheseChangesAsANewContact_:
		"Sauvegarder ces changements comme un nouveau contact.",
	search: "Rechercher",
	searchServiceParts: "Pièces Service de la recherche",
	searchingForMatchingAirCooledCondensers_:
		"La recherche pour la correspondance des condenseurs refroidis par air.",
	searchingForMatchingCompressors_:
		"La recherche pour les compresseurs correspondants.",
	searchingForMatchingCondensingUnits_:
		"La recherche pour les unités correspondantes de condensation.",
	searchingForMatchingEvaporatorUnits_:
		"La recherche pour les unités d'évaporateur correspondantes.",
	section: "Section",
	sectionConstruction: "Construction de la section",
	sectionDivider: "Section diviseur",
	sectionExteriorIsOutdoors: "Section extérieur est à l'extérieur",
	sectionLoad: "Charge de la section",
	sectionType: "Type de section",
	sectionDeletionRequested_: "Suppression de l'article demandée.",
	sectionHasOpeningErrors_: "Section a ouverture des erreurs !",
	sectionHasOpeningWarning_: "Section a ouverture AVERTISSEMENT !",
	sectionNameNotProvided_: "Nom de la section non fourni.",
	sectionTypeMustBeSelected_: "Type de section doit être sélectionné !",
	sectional_E_g_StudWall_: "Sectionnel (par ex. mur à ossature)",
	sections: "Sections",
	sectionsSelected: "Sections sélectionnées",
	selectAll: "Sélectionner tout",
	selectApplicationLanguage: "Langue Sélection de l'application",
	selectEquipment: "Choisir l'équipement",
	selectModel: "Sélectionnez le modèle",
	selectNextModel_: "Sélectionnez le modèle suivant &gt;",
	selectOptions: "Sélectionnez Options",
	selectRequestRevision: "Demander la révision Sélectionnez",
	selectRoomShape: "Sélectionnez la forme de la chambre",
	selectWhichPowerOfTenToRoundTo_: "Sélectionnez la puissance de dix à tour à.",
	selectedItemDetails: "Certaines Détails Point",
	selectedModelDetails: "Détails du modèle sélectionné",
	selectedQuoteDetails: "Certaines Détails Citer",
	selectedConstructionNotFound_: "Construction sélectionnée non trouvée !",
	selectedItemCountDiffersFromPasteBufferCount_:
		"Certaines comptent diffère de ceux qui comptent tampon de collage!",
	selectedModelNotFoundInActivePriceBook_:
		"modèle sélectionné non trouvé dans le livre de prix active!",
	selectedPriceBookHasExpired_: "liste de prix sélectionnée a expiré!",
	selectionError_: "Sélection d'erreur!",
	sendNotification: "Envoyer un avis",
	sendNotifications: "Envoyer des notifications",
	sendItToMeAgain: "Envoyez-le moi à nouveau",
	sendNotificationsTo__SelectAllThatApply_:
		"Envoyer des notifications à: (Sélectionnez tout ce qui s'applique)",
	separatePrice: "Séparez les prix",
	seperatePrice: "Prix ​​séparée",
	serverConnected: "Serveur connecté",
	serverNotAvailable: "Serveur non disponible",
	serverCopyHasDifferentSaveHistory_:
		"copie Server a différents sauver l'histoire.",
	serverOrdersQueried_: "ordres Server demandé!",
	serverVersionDiffersFromApplication_:
		"Server version diffère de l'application!",
	serviceParts: "Pièces de rechange",
	setDefaults: "Définir par défaut",
	setStatus: "Définir le statut",
	setQuoteDateToToday_: "Date de citer Set à aujourd'hui.",
	shellApplicationFailedToAcceptCommand_:
		"Application Shell n'a pas à accepter la commande !",
	shellApplicationFailedToStart_: "Shell Application n'a pas démarré !",
	shellApplicationFilesAreMissing_:
		"Les fichiers d'Application Shell manquent !",
	shellTerminatedUnexpectedy_: "Shell a mis fin à Unexpectedy !",
	shellDidNotAcceptCommand_: "Shell n'accepte pas la commande.",
	shellDidNotRespond_: "Shell n'a pas répondu.",
	shellIsLoading: "Charge du Shell",
	shipOrdersFromFile: "Les ordres de navires à partir du fichier",
	shipTo: "Expédier à",
	shipToInformationIsIncomplete: "Navire à l'information est incomplète",
	shipToRegionMustBeTwoCharacterCode_:
		"Navire à la région doit être le code à deux caractères!",
	shipment: "Expédition",
	shipped: "Expédié",
	shippedInformation: "Expédié information",
	shippedLoose: "Livré en vrac",
	shippedLoose_LineItem_: "Expédié en vrac (Ligne Produit )",
	shippingNotes: "Livraison Notes",
	shippingTerms: "Conditions de livraison",
	shippingWeight: "Poids",
	showAll: "Tous les",
	showChangeDetails: "Détails sur le spectacle des changements",
	showExtendedParameters: "Afficher les paramètres étendus",
	showItemPrices: "Afficher les prix Point",
	showLocalOrders: "Show Orders locale",
	showMyOrders: "Afficher Mes ordres",
	showNotes_Tags_AndDates: "Afficher les notes, étiquettes, et Dates",
	showOptionPrices: "Prix option Afficher",
	showOptions: "Afficher les options",
	showSplashScreenAtStartup: "Afficher l'écran de démarrage au démarrage",
	showToolbarText: "Voir la barre d'outils Texte",
	showWhereUsedList: "Afficher la liste Where Used",
	showMeMoreInformation_: "Montrez-moi plus d'informations.",
	shutdown: "Arrêt",
	side: "Côté",
	side_: "Côté 1",
	signIn: "Identifiez-vous",
	signedIn: "Signé à",
	signedIn_: "Signé à.",
	signingInAsGuest_: "Signature en tant qu'invité.",
	sineWave: "Onde sinusoïdale",
	siteAltitude: "Altitude du site",
	siteLineFrequency: "Fréquence de ligne sites",
	siteAltitudeCanNotBeGreaterThan:
		"Altitude du site ne peut pas être supérieure à",
	siteAltitudeCanNotBeLessThan:
		"Altitude du site ne peut pas être inférieure à",
	siteAltitudeNotEntered_: "Altitude du site ne pas entré !",
	smallDimensions: "Petites Dimensions",
	softwarePatches: "Correctifs logiciels",
	soldBy: "Vendu par",
	soldByNameChangeRequested_: "Vendu par changement de nom demandé.",
	soldTo: "Vendu à",
	soldByInformationIsIncomplete: "Vendu par l'information est incomplète",
	soldToInformationIsIncomplete: "Vendu à l'information est incomplète",
	soldToRegionMustBeTwoCharacterCode_:
		"Vendu à la région doit être le code à deux caractères!",
	someDescriptionsHaveNotBeenEntered_:
		"Certaines descriptions n'ont pas été entré!",
	someDesignWindowsAreStillOpen_:
		"Certaines fenêtres de conception sont encore ouvertes.",
	someFactoryCallLeadTimeSettingsAreStillNotSet_:
		"Certains paramètres d'usine le temps d'appel de plomb ne sont pas encore fixés!",
	someFilesDidNotTransferToTheServer_:
		"Certains dossiers ne sont pas transférées vers le serveur!",
	someFunctionalityMayBeDisabled_:
		"Certaines fonctionnalités peuvent être désactivées!",
	someItemsNeedFactoryLeadTimes_PleaseCall_:
		"Certains articles doivent délais usine. S'il vous plaît appeler.",
	someItemsNeedFactoryPricing_PleaseCall_:
		"Certains éléments ont besoin de prix d'usine. S'il vous plaît appelez !",
	someOptionsWerePreviouslySetToAnotherValue_:
		"Certaines options ont été précédemment défini sur une autre valeur.",
	someOrdersNotSaved_: "Certaines ordres n'est pas sauvegardée!",
	someOrdersWereNotProperlyClosed_:
		"Certaines commandes n'ont pas été correctement fermées.",
	someParametersChanged_: "Certains paramètres ont été modifiés.",
	somePriceChangesOccurred_: "Certains changements de prix a eu lieu.",
	somePricesOrOptionsMayHaveChanged_:
		"Certaines options ou les prix peuvent avoir changé !",
	somePricesWereUpdated_: "Certains prix ont été mis à jour!",
	somePrintFunctionsAreDisabled_:
		"Certaines fonctions d'impression sont désactivées.",
	spanish: "Espagnole",
	spareFeeds: "Flux de rechange",
	spares: "Pièces de rechange",
	specificHeatAboveFreezing: "Chaleur spécifique au-dessus de zéro",
	specificHeatBelowFreezing: "Chaleur spécifique en dessous de zéro",
	standard: "Norme",
	standardOrder: "De ordre Standard",
	standardTextFile_AllListedParts_:
		"fichier texte standard - toutes les pièces énumérées.",
	standardTextFile_SelectedPartsOnly_:
		"fichier texte standard - Certaines pièces seulement.",
	startNROES: "Démarrer NROES",
	startUpOptions: "Options de démarrage",
	startShellWhenILogInToWindows:
		"Démarrez shell lorsque je vous connecter à windows",
	starting: "À partir",
	startingTools: "À partir des outils",
	state: "État",
	status: "Statut",
	status_: "Situation:",
	statusStrip: "Statusstrip",
	stockingModelOrder: "ordrez modèle Stocking",
	storedOrderIsMoreRecentThanRecoveryData_:
		"Afin stockée est plus récente que la récupération de données.",
	stripCurtains: "Rideaux de bande",
	style: "Style de",
	sub_Total: "Sous-total",
	subject: "Sous réserve",
	submitChangeOrderNow: "Envoyer le changement ordrer dès maintenant",
	submitChangeOrderRequestToManufacturer:
		"Soumettre des demandes de changement de ordre Pour Fabricant",
	submitChangeRequestToPurchaser:
		"Soumettre des demandes de changement à l'acheteur",
	submitNotes: "Soumettre Notes",
	submitOrder: "Soumettre la ordre",
	submitOrderNow: "Envoyer ordrer dès maintenant",
	submitted: "Soumis",
	submittedOrdersMayNotBeEditedInOff_lineMode_:
		"Soumis ordres peuvent pas être modifiés en mode off-line.",
	success_: "Réussi!",
	suction: "Aspiration",
	suctionTemp: "Aspiration Temp",
	suctionTemp_S_S_T__: "Temp d'aspiration (SST)",
	suctionTemperatureCanNotBeGreaterThan:
		"Température d'aspiration ne peut pas être supérieure à",
	suctionTemperatureCanNotBeLessThan:
		"Température d'aspiration ne peut pas être inférieure à",
	suctionTemperatureIsGreaterThanCondensingTemperature_:
		"Température d'aspiration est supérieure à la température de condensation.",
	suctionTemperatureIsMissing_: "Température d'aspiration est manquante.",
	suctionTemperatureIsRequired_: "La température d'aspiration est nécessaire !",
	suitableModels: "Modèles adaptés",
	switchPriceBook: "Commutateur PriceBook",
	synchronizingOrder_: "ordre de synchronisation:",
	syncronizingContacts: "Synchronisation Contacts",
	system: "Système",
	systemLoad: "La charge du système",
	systemName: "Name System",
	systemNetPrice: "Système de prix imposés",
	systemNotes: "Système Notes",
	systemRun_Time: "Système Run-Time",
	systemTagging: "Système de marquage",
	systemWeight: "Poids du système",
	systemLoadIsMissing_: "Charge système est manquant !",
	systemOptionIsNoLongerAvailable_: "Option de système n'est plus disponible !",
	systemReturned_: "Système retourné.",
	systemsOnThisOrderContainErrors_:
		"Systèmes de cet ordre contenir des erreurs.",
	systemsOnThisOrderContainWarnings_:
		"Systèmes sur cet ordre des avertissements.",
	t_D_: "TD",
	t_D_Error: "Erreur TD",
	t_H_R: "THR",
	tD: "TD",
	tDCanNotBeCalculated_: "TD ne peut pas être calculée.",
	tDCanNotBeGreaterThan: "TD ne peut pas être supérieur à",
	tDCanNotBeLessThan: "TD ne peut pas être inférieur à",
	tDOutOfEnteredToleranceRange_: "TD hors limites de tolérance est entré.",
	tDOutOfMaximumRange_: "TD hors de portée maximale.",
	tERMS_CONDITIONSANDREMARKS: "CONDITIONS ET REMARQUES",
	tHEVALUESCONTAINEDHERINAREBASEDONLABORATORYFINDINGSANDTHEINITIALCONDITIONSENTEREDBYTHEUSER_:
		"LE BRUNO CONTENUES DE VALEURS SONT FONDÉES SUR LES RÉSULTATS DE LABORATOIRE ET LES CONDITIONS INITIALES ENTRÉES PAR L'UTILISATEUR.",
	tHR: "THR",
	tHRCanNotBeCalculated_: "THR ne peut pas être calculée.",
	tOTAL: "TOTAL",
	tOTALINFILTRATIONLOAD: "CHARGE TOTALE INFILTRATION",
	tOTALLISTPRICE: "Prix éditeur",
	tOTALNET: "NET TOTAL",
	tOTALQUOTEPRICE: "DEMANDE DE PRIX TOTAL",
	tYP: "TYP",
	tYPE: "TYPE",
	tabSeparatedTextFiles: 'Onglet "Fichiers" texte séparé',
	tagging: "Marquage",
	taggingID: "Marquage d'identification",
	taggingID_: "Marquage Id #",
	taggingText: "Texte de marquage",
	takeOwnership: "Prendre possession",
	targetPriceAccordingToAdjustmentAndRoundingFactors_:
		"Prix indicatif en fonction de facteurs d'ajustement et de l'arrondissement.",
	tax: "Impôt",
	taxes: "Impôts",
	temperature: "Température",
	temperatures: "Températures",
	terminationOptions: "Options de résiliation",
	terms: "Conditions",
	test: "Test",
	textBox: "Zone De Texte",
	theInternalRelativeHumidityNotEntered_:
		"L'humidité relative intérieure ne pas entrée !",
	theMaterialLoadHasNotBeenCalculatedOrEntered_:
		"La charge matérielle n'a pas été calculée ou entrée !",
	theMaterialMaterialNameIsNotEntered_:
		"Le nom de matière matière n'est pas entré !",
	theMaterialMaterialTypeIsNotSelected_:
		"Le matériel type de matériau n'est pas sélectionnée !",
	theOriginatingCustomersInformationIsAlreadyListedInYourContactDatabase_:
		"Les informations provenant des clients est déjà inscrit dans votre base de contacts.",
	theR_ValueHasNotBeenEntered_: "La valeur r n'est pas intervenu !",
	theActualCondensingTemperatureCouldNotBeCalculated_:
		"Impossible de calculer la température de condensation réelle !",
	theAmbientTemperatureIsNotEntered_:
		"La température ambiante n'est pas entrée !",
	theApplicationCouldNotMoveFile_BootStrap_exeFromTheUpdatesFolderToTheApplicationFolder_:
		"La demande ne pouvait pas bouger BootStrap.exe fichier 'à partir du dossier Mises à jour du dossier de l'application.",
	theApplicationCouldNotRemoveFile_BootStrap_exe_FromTheApplicationFolder_:
		"L'application n'a pas pu supprimer \"BootStrap.exe 'fichier à partir du dossier de l'application.",
	theApplicationCouldNotSpawn_BootStrap_exe_InTheApplicationFolder_:
		"L'application n'a pas pu lancer \"BootStrap.exe dans le dossier de l'application.",
	theAreaHasNotBeenCalculatedOrEntered_:
		"La région n'a pas été calculée ou entrée !",
	theAreaIsGreaterThanTheSectionArea_:
		"La zone est plus grande que la zone de section !",
	theBoxTypeMustBeSelected_: "Le type de la boîte doit être sélectionné !",
	theCentreDistanceIsNotEntered_: "La distance axiale n'est pas entrée !",
	theChangeCanNotBeProcessedUntilYouCloseTheWorkbookInThisApplication_:
		"Le changement ne peut être traité jusqu'à ce que vous fermez le classeur dans cette application.",
	theConstructionK_FactorIsNotCalculatedOrEntered_:
		"La construction K-Factor n'est pas calculée ou entrée !",
	theConstructionR_ValueIsNotCalculatedOrEntered_:
		"La construction de la valeur r n'est pas calculé ou entré !",
	theConstructionNameIsNotEntered_: "Le nom de construction n'est pas entré !",
	theConstructionThicknessHasNotBeenEntered_:
		"L'épaisseur de la construction n'est pas intervenu !",
	theConstructionTypeIsNotSelected_:
		"Le type de construction n'est pas sélectionné !",
	theCorrectQuoteRevisionIsSelected:
		"La révision citation correcte est sélectionnée",
	theCurrentPricebookHasBeenSuperseded_:
		"Le pricebook actuelle a été remplacée.",
	theCurrentPricebookHasExpired_: "Le pricebook actuelle a expiré.",
	theCurrentPricebookNoLongerExists_: "Le pricebook actuelle n'existe plus.",
	theCurrentPricebookWillExpireInLessThanAMonth_:
		"Le pricebook actuel expire en moins d'un mois.",
	theCurrentSettingsWillBeUsedOnAllNewOrdersForThisDivision_:
		"Les réglages actuels seront utilisés sur toutes les nouvelles ordres de cette division.",
	theCurrentSettingsWillBeUsedOnAllNewOrders_:
		"Les réglages actuels seront utilisés sur toutes les nouvelles ordres.",
	theDividerMaterialNameIsNotEntered_:
		"Le nom de matière diviseur n'est pas entré !",
	theDividerMaterialTypeIsNotSelected_:
		"Le type de matériau de séparation n'est pas sélectionné !",
	theDividerWidthIsNotEntered_: "La largeur de la cloison n'est pas entrée !",
	theEffectiveR_ValueIsNotCalculatedOrEntered_:
		"La valeur de r efficace n'est pas calculé ou entré !",
	theEmailAddressGivenIsNotListed_:
		"L'adresse de courriel indiquée n'est pas répertorié.",
	theExternalRelativeHumidityMustBeGreaterThanZero_:
		"L'humidité relative extérieure doit être supérieure à zéro !",
	theExternalRelativeHumidityMustBeLessThan____:
		"L'humidité relative extérieure doit être inférieur à 100 !",
	theExternalRelativeHumidityNotEntered_:
		"L'humidité relative extérieure ne pas entrée !",
	theExternalTemperatureIsLowerThanTheInternalTemperature_:
		"La température extérieure est inférieure à la température interne !",
	theExternalTemperatureIsNotEntered_:
		"La température extérieure n'est pas entrée !",
	theExternalTemperatureIsTooHigh_:
		"La température extérieure est trop élevée !",
	theExternalTemperatureIsTooLow_: "La température extérieure est trop basse !",
	theExternalTemperatureNotEntered_:
		"La température extérieure n'est ne pas entrée !",
	theFactoryInvoiceRecipientIsCorrect:
		"Le destinataire de la facture d'usine est correct",
	theFactoryInvoiceWillBeSentTo: "La facture d'usine sera envoyé à",
	theFactoryIsNotShippingOnTheSelectedDate_:
		"L'usine n'est pas d'expédition à la date sélectionnée.",
	theFloorAreaIsNotCalculatedOrEntered_:
		"La surface de plancher n'est pas calculée ou entrée !",
	theFollowingChangesHaveBeenVerified:
		"Les modifications suivantes ont été vérifiées",
	theFollowingOptionIsNotAllowedWithThisPackage_:
		"L'option suivante n'est pas autorisé avec ce paquet.",
	theFollowingOrdersCouldNotBeMarkedAsClosed_:
		"Les ordres suivantes ne pouvait pas être considéré comme étant fermé.",
	theFollowingOrdersCouldNotBeMarkedAsShipped_:
		"Les ordres suivantes ne pouvait pas être marqué comme expédié.",
	theFollowingOrdersCouldNotBeSynchronized_:
		"Les ordres suivantes peuvent ne pas être synchronisées!",
	theInfiltrationLoadHasNotBeenCalculatedOrEntered_:
		"La charge de l'infiltration n'a pas été calculée ou entrée !",
	theInformationInTheStoreIsOlderThanTheSelectedFile_:
		"Les informations contenues dans le magasin est plus ancien que le fichier sélectionné.",
	theInternalTemperatureIsNotEntered_:
		"La température interne n'est pas entrée !",
	theItemOrSomeOptionsNeedFactoryLeadTimes_PleaseCall_:
		"L'élément ou certaines options ont besoin de délais d'usine. S'il vous plaît appelez !",
	theLayerThicknessIsNotCalculatedOrEntered_:
		"L'épaisseur de la couche n'est pas calculé ou entré !",
	theLayerThicknessIsNotEntered_: "L'épaisseur de la couche n'est pas entré !",
	theLoadIsNotCalculatedOrEntered_: "La charge n'est pas calculée ou entrée !",
	theOpeinngWidthIsGreaterThanTheSectionWidth_:
		"La largeur opeinng est supérieure à la largeur de section !",
	theOpeningHeightHasNotBeenEntered_:
		"La hauteur d'ouverture n'est pas intervenue !",
	theOpeningHeightIsGreaterThanTheSectionHeight_:
		"La hauteur de l'ouverture est supérieure à la hauteur du boudin !",
	theOpeningTypeIsNotSelected_: "Le type d'ouverture n'est pas sélectionné !",
	theOpeningWidthHasNotBeenEntered_:
		"La largeur de l'ouverture n'est pas intervenue !",
	theOrderCanNotBeProcessedUntilYouCloseTheWorkbookInThisApplication_:
		"L'ordre ne peut pas être traitée tant que vous fermez le classeur dans cette application.",
	theOrderFileAppearsCorrupt_: "Le fichier de commande semble endommagé.",
	theOrderIsAlreadyListedInTheLocalOrderDatabase_:
		"L'ordre est déjà répertorié dans la base de données afin locales.",
	theOrderIsLockedByAnotherUser_:
		"L'ordre est verrouillé par un autre utilisateur.",
	theOrderWindowWillUpdateWhenYouCloseTheServicePartsWindow_:
		"La fenêtre de ordre sera mise à jour lorsque vous fermez la fenêtre des pièces de rechange.",
	theOriginalOrderHasNotBeenProcessedYet_:
		"L'ordonnance originale n'a pas encore été traitées.",
	thePerimeterLengthMustBeEnteredForSlabOnGradeFloors_:
		"La longueur du périmètre doit être saisie pour dalle sur planchers de qualité !",
	thePowerUnitsAreNotEntered_:
		"Les unités de puissance ne figurent pas dans la liste !",
	thePriceBookSelectedForThisOrderHasBeenUpdated_:
		"Le livre de prix choisi pour cet ordre a été mis à jour !",
	thePriceBookUsedForTheOpenChangeRequestHasBeenRevised_:
		"Le livre des prix utilisé pour la demande de modification ouverte a été révisé.",
	thePriceBookUsedForTheOpenChangeRequestHasBeenSuperseded_:
		"Le livre des prix utilisé pour la demande de modification ouvert a été remplacé.",
	thePriceBookUsedForTheOpenChangeRequestIsExpiring_:
		"Le livre des prix utilisé pour la demande de modification ouvert arrive à échéance.",
	thePriceBookUsedForThisOrderHasBeenRevised_:
		"Le catalogue des prix utilisé pour cet ordre a été révisé.",
	thePriceBookUsedForThisOrderHasBeenSuperseded_:
		"Le catalogue des prix utilisé pour cette commande a été remplacé.",
	thePriceBookUsedForThisOrderIsExpiring_:
		"Le livre des prix utilisée pour cette commande vient à expiration.",
	thePriceBookUsedOnOneQuoteHasBeenRevised_:
		"Le livre des prix utilisée sur une citation a été révisé.",
	thePriceBookUsedOnOneQuoteHasBeenSuperseded_:
		"Le livre des prix utilisée sur une citation a été remplacée.",
	thePriceBookUsedOnOneQuoteHasExpired_:
		"Le livre des prix utilisée sur une citation a expiré.",
	thePriceBookUsedOnOneQuoteIsMissing_:
		"Le livre des prix utilisée sur une citation est manquant.",
	thePriceBookWasUpdated_: "Le livre a été mise à jour des prix!",
	thePriceBooksUsedForThisOrderAreExpiring_:
		"Les livres de prix utilisés pour cette commande sont expirant.",
	thePriceBooksUsedForThisOrderHaveBeenRevised_:
		"Les livres de prix utilisés pour cette commande ont été révisées.",
	thePriceBooksUsedForThisOrderHaveBeenSuperseded_:
		"Les livres de prix utilisés pour cette commande ont été remplacées.",
	thePriceBooksUsedOn: "Les livres de prix utilisés sur",
	thePriceBooksUsedOnOneQuoteIsExpiring_:
		"Les livres de prix utilisés sur une citation vient à expiration.",
	theProductFreezingTemperatureIsNotEntered_:
		"Le produit température de congélation n'est pas inscrit !",
	theProductIsEnteringAtTheInternalTemperature_TheFinalTemperatureMustAlsoBeTheInternalTemperature_:
		"Le produit est entré à la température interne, la température finale doit être aussi la température interne !",
	theProductTypeIsNotSelected_: "Le type de produit n'est pas sélectionné !",
	theProduct_sEnteringTemperatureIsNotSet_:
		"Température entrée du produit n'est pas définie !",
	theProduct_sFinalTemperatureCanNotBeGreaterThanTheEnteringTemperature_:
		"La température finale du produit ne peut pas être supérieure à la température d'entrée !",
	theProduct_sFinalTemperatureCanNotBeLowerThanTheEnteringTemperature_:
		"La température finale du produit ne peut pas être inférieure à la température d'entrée !",
	theProduct_sFinalTemperatureCanNotBePulledHigherThanTheInternalTemperature_:
		"La température finale du produit ne peut pas être tirée plus élevée que la température interne !",
	theProduct_sFinalTemperatureCanNotBePulledLowerThanTheInternalTemperature_:
		"Température finale du produit ne peut pas être tirée plus bas que la température interne !",
	theProduct_sFinalTemperatureIsNotSet_:
		"La température finale du produit n'est pas définie !",
	theProduct_sLoadFactorIsNotSet_:
		"Facteur de charge du produit n'est pas définie !",
	theProduct_sLoadFactorShouldBeOneUnlessRapidCoolingIsRequired__:
		"Facteur de charge du produit doit être à moins qu'un refroidissement rapide est nécessaire. !",
	theProduct_sLoadIsNotCalculatedNotEntered_:
		"Chargement du produit n'est pas calculée ne pas entré !",
	theProduct_sMoistureContentIsNotEntered_:
		"Teneur en eau du produit n'est pas entré !",
	theProduct_sPullDownTimeIsNotSet_:
		"Tirer du produit le temps n'est pas définie !",
	theProduct_sSpecificHeatAboveFreezingIsNotEntered_:
		"Chaleur spécifique du produit au-dessus de zéro n'est pas entrée !",
	theProduct_sSpecificHeatBelowFreezingIsNotEntered_:
		"Chaleur spécifique du produit en dessous de zéro n'est pas entrée !",
	theProduct_sWeightIsNotEntered_: "Poids du produit n'est pas entré !",
	theProgramStoreIsNewerThanTheFileYouSelected_:
		"Le magasin de programme est plus récent que le fichier que vous avez sélectionné!",
	theQuoteHasBeenSignedByTheCustomer: "La citation a été signé par le client",
	theRunTimeIsNotEntered_: "Le moment de l'exécution n'est pas entré !",
	theSelectedChangeOrderHasBeenProcessedByOurOrderDepartment_:
		"L'ordre de modification sélectionné a été traitée par notre service des ordres.",
	theSelectedChangeOrderHasBeenProcessedByThePurchaser_:
		"L'ordre de modification sélectionnés a été traitée par l'acheteur.",
	theSelectedOrderHasBeenProcessedByOurOrderDepartment_:
		"L'ordre choisi a été traitée par notre service des ordres.",
	theSelectedOrderHasBeenProcessedByThePurchaser_:
		"L'ordre choisi a été traitée par l'acheteur.",
	theSelectedOrderIsOpenInAnotherSessionOfNROES_:
		"L'ordre choisi est ouvert dans une autre session de NROES.",
	theSelectedRefrigerantIsNotAvailableOnThisUnit_:
		"Le réfrigérant sélectionné n'est pas disponible sur cet appareil !",
	theServerReturnedTheFollowing_: "Le serveur a renvoyé le texte suivant.",
	theSystemReportedError: "Le système a signalé l'erreur",
	theSystemReportedTheFollowingError_:
		"Le système a signalé l'erreur suivante.",
	theTotalInfiltrationLoadIsNotCalculatedOrEntered_:
		"La charge totale de l'infiltration n'est pas calculée ou entrée !",
	theTotalVolumeIsNotCalculatedOrEntered_:
		"Le volume total n'est pas calculé ou entré !",
	theUserNameYouEnteredIsNotRegisteredOnThisWorkstation_:
		"Le nom d'utilisateur que vous avez entré n'est pas inscrit sur ce poste de travail!",
	theValueWillBeAddedOrSubtractedFromTheCurrentPrice_:
		"La valeur sera ajouté ou soustrait le prix courant.",
	thenClickOnSaveAgain_: "Puis cliquez sur enregistrer à nouveau.",
	thereAreNoItemsOnThisOrder_: "Il n'ya pas de points de cet ordre!",
	thereAreNoOptionsAvailableForThisUnit_:
		"Il n'y a pas d'options disponibles pour cet appareil!",
	thickness: "Épaisseur",
	thicknessIsNotEntered_: "Épaisseur n'est pas entré !",
	thirdParty: "Troisième Partie",
	thisMayTakeAFewMinutes_: "Cela peut prendre quelques minutes.",
	thisModelHasOptionsAttached_: "Ce modèle a des options ci-joint.",
	thisOrderHasBeenPutOnHoldByTheOrderDepartment_:
		"Cet ordre a été mis en attente par le service des ordres.",
	thisOrderHasBeenRejectedByTheOrderDepartment_:
		"Cette ordonnance a été rejetée par le service des ordres.",
	thisOrderHasPriceBookIssues_: "Cet ordre a des problèmes de liste de prix.",
	thisOrderWasNotClosedProperlyTheLastTimeYouOpenedItOnThisWorkstation_:
		"Cette ordonnance n'a pas été fermé correctement la dernière fois vous l'avez ouvert sur ce poste de travail.",
	thisOrderWasSavedUsingAPreviousVersionOfYourMultiplierStructure_:
		"Cette ordonnance a été enregistrée à l'aide d'une version précédente de votre structure multiplicateur!",
	thisOrderWasSavedUsingAPreviousVersionOfYourUserCatalogueProfile_:
		"Cette ordonnance a été enregistrée à l'aide d'une version précédente de votre profil utilisateur catalogue!",
	tileHorizontally: "Mosaïque horizontale",
	tileVertically: "Mosaïque verticale",
	time: "Temps",
	title: "Titre",
	toNearest: "Pour le plus proche",
	toNearest_: "Pour la plus proche 1",
	toNearest_____: "Pour le plus proche 1,000",
	toNearest__: "Pour plus proche à 10",
	toNearest______: "Pour le plus proche 10.000",
	toNearest___: "Pour le plus proche 100",
	toNearest_______: "Pour le plus proche 100.000",
	toAssistUs_PleaseProvideAnyFurtherInformationYouMayThinkIsRelevant_:
		"Pour nous aider, s'il vous plaît fournir toute information complémentaire vous pouvez penser est pertinente.",
	toChangeThePartQuantities_PleaseEditThemInTheOrderWindow_:
		"Pour modifier les quantités partie, s'il vous plaît les modifier dans la fenêtre de l'ordre.",
	tolerance: "Tolérance",
	tools: "Outils",
	total: "Total",
	totalCapacityAtDesignTD: "Capacité totale à Design TD",
	totalEquipmentPrice: "Prix Total Equipement",
	totalHeatRejected: "Chaleur totale rejetée",
	totalHourlyLoad: "Charge horaire totale",
	totalLoadRequired: "Charge totale requise",
	totalMiscellaneousLoad: "Total charge divers",
	totalOpeningInfiltration: "Ouverture totale Infiltration",
	totalOtherLoads: "Total des autres charges",
	totalPrice: "Prix total",
	totalProductLoad: "Charge total produit",
	totalProductWeight: "Poids total du produit",
	totalSystemCapacity: "Capacité totale",
	totalThickness: "Épaisseur totale",
	totalVolume: "Volume total",
	totalWallLoad: "Charge totale mur",
	totalDoorWidthExceedsSectionWidth:
		"Largeur de porte total dépasse la grosseur du boudin",
	totalHeatRejectedOutOfRange_: "Chaleur totale rejetée hors de portée.",
	totalOpeningAreaExceedsSectionArea:
		"Ouverture totale superficie supérieure à la zone de section",
	translationsAreUNDERCONSTRUCTIONAndMayBeLiteralByNatureAndMayBeContextuallyInError_:
		"Traductions en cours de construction et peut être littérale, par nature, et peuvent être contextuellement dans l'erreur.",
	trentonLoadCalcFiles: "Charger les fichiers Trenton Calc",
	trentonRefrigeration: "Réfrigération de Trenton",
	tubeConfiguration: "Configuration de tube",
	tubeRows: "Lignes de tube",
	tubes: "Tubes",
	tubesPerFeed: "Tubes par Feed",
	tubesPerRow: "Tubes par rang",
	typ: "Typ",
	type: "Tapez",
	u_SHAPED: "U - FORME",
	u_S_: "Etats-Unis",
	u_S_English: "L'anglais américain",
	uNIT: "UNITÉ",
	uNITLIST: "UNITÉ DE LISTE",
	uNITNET: "UNITÉ DE NET",
	uNLESSOTHERWISESPECIFIED: "SAUF INDICATION CONTRAIRE",
	uNUSEDCONNECTIONS: "CONNEXIONS INUTILISÉES",
	uPDATED: "MISE À JOUR",
	uSERINFORMATION: "INFORMATIONS DE L'UTILISATEUR",
	uSERPREFERENCES: "PRÉFÉRENCES DE L'UTILISATEUR",
	undo: "Défaire",
	unit_: "L'unité #",
	unitCooler: "Unité Cooler",
	unitList: "Liste de l'unité",
	unitNet: "Unité net",
	unitPrice: "Prix unitaire",
	unitTotal: "Unité de Total",
	unitIsNotCompatibleWithSelectedSystemRefrigerant_:
		"Unité n'est pas compatible avec fluide frigorigène du système sélectionné !",
	units: "Unités",
	unknownReason_: "Raison inconnue.",
	untilTheChangeIsProcessedByTheManufacturer_YouMayStopYourRequest_:
		"Jusqu'à ce que le changement est traité par le fabricant, vous pouvez arrêter votre demande.",
	untilThen_FunctionalityWillBeLimited_:
		"Jusque-là, les fonctionnalités seront limitées !",
	unusedFeeds: "Aliments non utilisés",
	upOrDown: "Monter ou Descendre",
	updateAllMultipliers: "Mise à jour de tous les multiplicateurs",
	updateAllParameters: "Mise à jour de tous les paramètres",
	updateAllPrices: "Mise à jour Tous les prix",
	updateNow: "Mettre à jour maintenant",
	updatePricebook: "Mise à jour Pricebook",
	updatePending_: "Mise à jour en attente.",
	updateTheCurrentInformation_Or: "Mise à jour de l'information actuelle. ou ",
	updateTheExistingListing_: "Mise à jour de la liste existante.",
	updatesCanNotBeInstalled_: "Mises à jour ne peut pas être installé.",
	uploadToJOB_SCOPE: "Envoyer à Job-SCOPE",
	uploadingBuffer: "Téléchargement de tampon",
	uploadingPreviouslySavedFiles:
		"Téléchargement de fichiers précédemment enregistrée",
	uploadingQueuedOrders: "Téléchargement en file d'attente de commandes",
	uploadingQueuedOrder_: "Transfert en file d'attente de ordre:",
	useLogoImageFile: "Fichier de Logo",
	useMyInformation: "Utiliser mes informations",
	useBoxSetting: "Utilisez le paramètre de la boîte",
	useMenuFunctionCreateChangeOrderInstead_:
		"Utilisez le menu Créer la fonction Changer l'ordre à la place.",
	useTheHOLDFunctionIfYouExpectTheOriginatorToCorrectTheOrder_:
		"Utilisez la fonction HOLD si vous vous attendez à l'origine pour corriger l'ordre!",
	useTheSTOPSUBMITTEDCHANGESelectionInTheOrderMenu_:
		"Utilisez la touche STOP SOUMIS Modifier la sélection dans le menu de ordre.",
	useTheSTOPSUBMITTEDORDERSelectionInTheOrderMenu_:
		"Utilisez la touche STOP SOUMIS sélection ordrer dans le menu de ordre.",
	useTheMenuToCreateNewRevision_s_FromYourEditsAsDesired_:
		"Utilisez le menu pour créer la nouvelle révision (s) de vos modifications comme vous le souhaitez.",
	userID: "ID utilisateur",
	userInformation: "Informations de l'utilisateur",
	userInformationRequested_: "L'utilisateur les informations demandées.",
	userName: "Nom d'utilisateur",
	userRegistrationInformation:
		"Renseignements sur l'inscription de l'utilisateur",
	userVerification: "Vérification De L'utilisateur",
	userNameRequired_: "Nom d'utilisateur requis.",
	userSelectablePriceAdjustmentValue_:
		"Sélectionnable par l'utilisateur la valeur d'ajustement des prix.",
	usernameNotFound_: "Nom d'utilisateur non trouvé!",
	usingLocalVersion_: "En utilisant la version locale.",
	vWaffle: "Gaufre V",
	vOLTAGE: "TENSION",
	valueRequired_: "Valeur requise !",
	valueMustBeGreaterThanZero_: "Valeur doit être supérieure à zéro !",
	various: "Divers",
	velocity: "Vitesse",
	ventilationAirRelativeHumidity___: "Ventilation Air humidité (%)",
	ventilationAirTemperature: "Température d'Air de ventilation",
	ventilationRate: "Débit de ventilation",
	ventilationAirRelativeHumidityIsNotEntered_:
		"Ventilation air humidité n'est pas entrée !",
	ventilationAirTemperatureIsNotEntered_:
		"Température de l'air de ventilation n'est pas entrée !",
	ventilationRateIsLessThanTheRecommendedMinimumValue_:
		"Débit de ventilation est inférieure à la valeur minimale recommandée !",
	verifyDesign: "Vérifiez Design",
	version: "Version",
	version_OfNROESUsesADifferentSaveStrategy_:
		"Version 8 de NROES utilise une autre stratégie de sauver.",
	version_UsesAWorkbookForYourJobInsteadOfMultipleQuoteRevisionFiles_:
		"Version 8 utilise un classeur pour votre travail au lieu de multiples fichiers de révision devis.",
	vertical: "Vertical",
	via: "Par l'intermédiaire",
	view_ArchivedFiles: "Vue & des fichiers archivés",
	viewCSV: "Voir le CSV",
	visitUsOn_Line: "Visitez-nous en ligne",
	voltage: "Tension",
	volume: "Volume",
	wAITINGFORSERVICEPARTSCATALOGUETOLOAD:
		"Attente pour le service CATALOGUE DES PIECES DE CHARGE",
	wARNING: "AVERTISSEMENT",
	wARNING_: "ATTENTION!",
	wARNINGS: "MISES EN GARDE",
	wATTS: "WATTS",
	wO: "WO",
	wORKORDER: "ORDRE DE TRAVAIL",
	waitingForShellToComeReady: "Attente de Shell À venir prêt",
	waitingForShellToComeReadyForFirstTime:
		"Attente de Shell À venir prêt pour la première fois",
	waitingForShellToStart: "En attente pour Shell de démarrer",
	waitingForANewPassword_: "En attente d'un nouveau mot de passe.",
	waitingForRegistrationKey_: "En attente de clé d'enregistrement.",
	waitingForShell_: "En attente de shell.",
	waitingForSign_in: "En attente de signature dans",
	wall: "Mur",
	wallLoad: "Charge de mur",
	wallSection: "Section de mur",
	wallsCross_PleaseCheckYourDimensions_:
		"La Croix de murs ! S'il vous plaît vérifier vos dimensions.",
	warning: "Avertissement",
	warning_PriceBookHasExpired_: "Attention - Prix livre a expiré!",
	warningOppositeEndConnections_DimensionDrawingWillOnlyShowSameEndHeaders:
		"Mise en garde en face de raccordements. Dimension de dessin affiche uniquement les mêmes en-têtes de fin",
	warning_: "Attention!",
	warnings: "Avertissements",
	waterCooledCondensingUnit: "Condenseur refroidi à l'eau",
	webMessagesAndBulletins: "Messages Web et bulletins",
	weight: "Poids",
	whatToPrint: "Ce qu'il faut imprimer",
	whatDoYouWantToDo_: "Que voulez-vous faire?",
	whatTypeOfFileDoYouRequire_: "Quel type de fichier avez-vous besoin?",
	whatTypeOfOrderDoYouWishToCreate_: "Quel type d'ordre vous souhaitez créer.",
	whereUsed: "Lorsqu'utilisé",
	whereUsed_: "Lorsque occasion:",
	wholesale: "Vente en gros",
	width: "Largeur",
	window: "Fenêtre",
	with: "Avec",
	withTheInternetConnected_: "Avec l'connecté à Internet.",
	workHours: "Heures de travail",
	workOrder: "Ordre De Travail",
	workingDays: "Jours de travail",
	workingOn_Line: "De travail en ligne",
	wouldYouLikeToDo_: "Souhaitez-vous faire?",
	wrapSystemBar: "Bar système d'emballage",
	yes: "Oui",
	yesPlease: "Oui s'il vous plaît",
	yes_ChangeTheModelToTheOneISelected_:
		"Oui, le changement du modèle à celle que j'ai choisie.",
	yes_IAmARegisteredUser_: "Oui, je suis un utilisateur enregistré.",
	yes_SetOptionsToTheNewMultiplier_:
		"Oui, définir des options pour le nouveau multiplicateur.",
	yes_SetTheValueIEntered_: "Oui, définissez la valeur je suis entré.",
	yes__SomeFunctionalityWillBeDisabled_:
		"Oui. (Certaines fonctionnalités seront désactivées)",
	youAreNotTheOriginalOwnerOfThisOrder_:
		"Vous n'êtes pas le propriétaire original de la présente ordonnance.",
	youAreProhibitedFromSettingTheMultiplierThisLow_:
		"Il vous est interdit de fixer le coefficient multiplicateur de cette faible.",
	youAreWorkingOffline_: "Vous travaillez hors ligne!",
	youCanNoLongerStopThisChangeRequest_:
		"Vous ne pouvez plus mettre fin à cette demande de modification.",
	youCanNoLongerStopThisOrder_: "Vous ne pouvez plus arrêter cet ordre.",
	youCanNoLongerStopThisPurchaseRequest_:
		"Vous ne pouvez plus mettre fin à cette demande d'achat.",
	youCanNotDeleteTheLastSystemOnAnQuote_:
		"Vous ne pouvez pas supprimer le dernier système sur un devis?",
	youCanNotDeleteThisOrderFromTheServer_:
		"Vous ne pouvez pas supprimer cet ordre à partir du serveur.",
	youCanNotEditTheDoorInformationAtThisTime_:
		"Vous ne pouvez pas modifier l'information porte en ce moment !",
	youCanNotEditTheSectionInformationAtThisTime_:
		"Vous ne pouvez pas modifier les informations de la section en ce moment !",
	youCanNotEditThisOrderAtThisTime_:
		"Vous ne pouvez pas modifier cet ordre en ce moment.",
	youCanNotRejectAnOrderUsingNROESOnceItHasBeenSubmittedToJob_Scope_:
		"Vous ne pouvez pas refuser une ordre en utilisant NROES une fois qu'il a été soumis à Job-Scope.",
	youChangedTheCondensingUnitModelNumber_:
		"Vous avez modifié le numéro de modèle d'unité à condensation !",
	youChangedTheModelNumber_: "Vous avez changé le numéro de modèle!",
	youDoNotCurrentlyHaveRightsToThisTool_:
		"Vous n'avez pas actuellement les droits à cet outil.",
	youDoNotHaveTheOriginalPriceBookLoaded_:
		"Vous n'avez pas le catalogue des prix original chargé.",
	youDoNotHaveTheRequiredPriceBookForTheOpenChangeRequest_:
		"Vous n'avez pas le catalogue des prix requis pour la demande de modification ouverte.",
	youDoNotHaveTheRequiredPriceBookForThisOrder_:
		"Vous n'avez pas le catalogue des prix requis pour cette commande !",
	youDoNotHaveTheRequiredPriceBooksForThisOrder_:
		"Vous n'avez pas les livres de prix requis pour cette commande.",
	youHaveFailedToReceiveACatalogueDataUpdateForMoreThan__Days_:
		"Vous n'avez pas à recevoir un catalogue de données mise à jour pour plus de 30 jours!",
	youHaveFailedToReceiveAProgramUpdateForMoreThan__Days_:
		"Vous n'avez pas à recevoir une mise à jour du programme pour les plus de 30 jours!",
	youHaveNotBeenAssignedAChannelForDirectEntryToTheAS_____:
		"Vous n'avez pas été attribué un canal pour l'accès direct à l'AS / 400.",
	youHaveNotConfirmedYourEmailAddressYet_:
		"Vous n'avez pas encore confirmé votre adresse e-mail !",
	youHaveNotReceivedACatalogueDataUpdateForMoreThan__Days_:
		"Vous n'avez pas reçu une mise à jour le catalogue de données pour plus de 15 jours!",
	youHaveNotReceivedAProgramUpdateForMoreThan__Days_:
		"Vous n'avez pas reçu une mise à jour du programme pour les plus de 15 jours!",
	youHaveNotStartedTheApplicationOn_lineForMoreThan__Days_:
		"Vous n'avez pas commencé à l'application en ligne pour plus de 15 jours!",
	youMayCreateANewChangeOrder_OrContactUsByTelephone_:
		"Vous pouvez créer un ordre nouveau changement, ou nous contacter par téléphone.",
	youMayCreateANewChangeOrder_OrContactYourPurchaser_:
		"Vous pouvez créer un ordre nouveau changement, ou communiquez avec votre acheteur.",
	youMayCreateANewOrderAndCorrectTheListedIssues_:
		"Vous pouvez créer un ordre nouveau et corriger les problèmes répertoriés.",
	youMayEditThisOrderOff_line_: "Vous pouvez modifier cet ordre off-line.",
	youMayEnterAPositiveOrNegativeValueHere_:
		"Vous pouvez entrer une valeur positive ou négative ici.",
	youMayEnterAValueHereToRoundToAValueOtherThanThatEndingInAZero_:
		"Vous pouvez entrer une valeur ici pour tour à une valeur autre que celle se terminant par un zéro.",
	youMayHaveTheOrderOpenInAnotherApplication_:
		"Vous pouvez avoir la ordre ouverte dans une autre application.",
	youMayNotCopyThisOrder_: "Vous ne pouvez pas copier cet ordre!",
	youMayNowEditYourData_: "Vous pouvez maintenant modifier vos données.",
	youMayPreferToSimplyDeselectTheSpecificOptionsYouDoNotRequire_:
		"Vous préférerez peut-être tout simplement désélectionner les options spécifiques que vous n'avez pas besoin.",
	youMaySelectRoundingOptionsHere_:
		"Vous pouvez sélectionner l'arrondissement options ici.",
	youMayTakeOwnershipOfThisOrderUsingTheOrderMenu_:
		"Vous pouvez prendre possession de cette ordre en utilisant le menu de ordre.",
	youMayThenEditTheServicePartQuantitiesInTheOrderItself_:
		"Vous pouvez alors modifier les quantités partie des services dans l'ordre lui-même.",
	youMayWorkOff_lineIfYouWish_:
		"Vous pouvez travailler hors ligne si vous le souhaitez.",
	youMustBeConnectedToTheInternetToUseThisTool_:
		"Vous devez être connecté à internet pour utiliser cet outil.",
	youRequestedIDeleteASystemFromTheOrder_:
		"Vous avez demandé-je supprimer un système de la ordre.",
	youShouldCloseThisOrderAndTryToReopenItForSynchronizationOptions_:
		"Vous devez fermer cet ordre et essayer de le rouvrir pour les options de synchronisation.",
	youShouldReceiveAnE_mailShortly_:
		"Vous devriez recevoir un courriel sous peu.",
	youShouldReceiveMoreInformationWithinTheNextFewWorkingDays_:
		"Vous devriez recevoir plus d'informations dans les prochains jours travail.",
	youShouldReceiveYourCodeShortlyAtTheFollowingE_mailAddress_:
		"Vous devriez recevoir votre code peu à l'adresse e-mail.",
	youShouldReceiveYourLoginInformationViaE_mailShortly_:
		"Vous devriez recevoir vos informations de connexion par e-mail peu de temps.",
	youShouldStopTheRequest_CorrectTheIssuesListedBelow_AndResubmit_:
		"Vous devriez arrêter la demande, corriger les problèmes énumérés ci-dessous, et renvoyez-le.",
	youWillBeTheOnlyOneAbleToMakeChanges_:
		"Vous serez le seul à pouvoir apporter des modifications.",
	yourAuthorizationHasBeenRecinded_: "Votre autorisation a été rendu.",
	yourAuthorizationIsNowUnderReview_:
		"Votre autorisation est maintenant en cours d'examen.",
	yourAuthorizationIsStillUnderReview_:
		"Votre autorisation est en cours d'examen.",
	yourChangeRequestWasSubmitted_:
		"Votre demande de modification a été soumise.",
	yourDataContainsWarnings_: "Vos données contiennent des avertissements.",
	yourNeedToBeOn_lineToCompleteTheRegistrationProcess_:
		"Votre besoin d'être en ligne pour compléter le processus d'inscription.",
	yourNewChangeRequestWillReplaceThePreviousOne_AfterYouCompleteYourChangesAndRe_submit_:
		"Votre nouvelle demande de modification remplacera le précédent, après avoir terminé vos modifications et re-soumettre.",
	yourNewOrderWillReplaceThePreviousOne_AfterYouCompleteYourChangesAndRe_submit_:
		"Votre nouvel ordre remplacera le précédent, après avoir terminé vos modifications et re-soumettre.",
	yourNewRequestWillReplaceThePreviousOne_AfterYouCompleteYourChangesAndRe_submit_:
		"Votre nouvelle demande va remplacer la précédente, après avoir terminé vos modifications et re-soumettre.",
	yourOrderWasSubmitted_: "Votre ordre a été soumise.",
	yourPasswordHasBeenSuccessfullyUpdated: "Votre mot de passe a été mis à jour",
	yourPreviouslySubmittedChangeOrderHasBeenStopped_:
		"Votre ordre de modification lui a été soumis arrêté!",
	yourPreviouslySubmittedChangeRequestHasBeenStopped_:
		"Votre demande de modification lui a été soumis arrêté!",
	yourPreviouslySubmittedOrderHasBeenStopped_:
		"Votre ordre lui a été soumis arrêté!",
	yourPreviouslySubmittedRequestHasBeenStopped_:
		"Votre demande lui a été soumis arrêté!",
	yourPurchaserHasBegunWorkOnYourOrderRequest_:
		"Votre acheteur a commencé à travailler sur votre commande.",
	yourRequestCouldNotBeRetractedAtThisTime_:
		"Votre demande ne peut être rétractée à cette heure!",
	yourSoldByRegionMustBeTwoCharacterCode_:
		"Votre vendu par région doit être le code à deux caractères!",
	yourUserAccountHasBeenRejected_: "Votre compte d'utilisateur a été rejetée !",
	yourUserAccountIsCurrentlyDisabled_:
		"Votre compte d'utilisateur est actuellement désactivé !",
	yourUserProfileHasBeenSuccessfullyUpdated_:
		"Votre profil d'utilisateur a été correctement mis à jour!",
	yourVendorAccountIsCurrentlyDisabled_:
		"Votre compte de fournisseur est actuellement désactivée !",
	zip_PostalCode: "Zip / Postal Code",
	zipCode: "Code postal",
	zoneBoundary: "Limite de la zone",
	zoneBoundary_TD___: "Zone limite (TD = 0 )",
	asNewRevision_AndRestore: "que la nouvelle révision, et de rétablir",
	asNewRevision_: "que nouvelle révision.",
	atFixtureRating: "à cote de luminaire",
	confirmYourRequestByClickingOnTheOKButton_:
		"confirmer votre demande en cliquant sur le bouton OK.",
	e_g_ToRoundTo______SetRoundingToNearest____AndEnter__Here_:
		"Pour compléter, par exemple à 99,99, mis en arrondi au plus près 100, et entrez -5 ici.",
	equipmentChangesFound_: "les changements de matériel trouvé.",
	feedIsNotUsed_: "l'alimentation n'est pas utilisée !",
	feedsAreNotUsed_: "flux ne sont pas utilisés !",
	freightTerms: "conditions de transport",
	frmOptions: "Frmoptions",
	haveBeenRevised_: "ont été révisées.",
	haveBeenSuperseded_: "ont été remplacées.",
	lblBOLD: "lblBOLD",
	orQuantity: "ou la quantité",
	orR_Value_UnitThickness: "ou valeur r / unité d'épaisseur",
	orTotalLightingPower: "ou la puissance d'éclairage totale",
	pricesChanged_: "prix a changé.",
	quotesAreExpiring_: "les citations sont expirant.",
	quotesAreMissing_: "les citations sont manquantes.",
	quotesHaveExpired_: "citations ont expiré.",
	regularShipDateOfTheSelectedItem_:
		"date d'envoi régulier de l'élément sélectionné.",
	revisionsUpdated_: "révisions à jour.",
	revisions_: "révisions.",
	to: "À",
	toLastSavedVersion_: "à la dernière version enregistrée.",
	youCanNoLongerCreateQuoteRevisionsDuringTheSaveOperation_:
		"vous ne pouvez plus créer de révisions au cours de la citation opération de sauvegarde.",
	_LessOptions_: "( Moins d'Options )",
	airCooledCondenserDesigner: "Concepteur de condenseur refroidi par air",
	approvalOfThisDrawingSignifiesThatTheEquipmentIsAcceptableUnderTheProvisionOfTheJobSpecifications_AnyChangeMadeHereonByAnyPersonWhomsoeverSubjectToAcceptanceByNATIONALREFRIGERATIONAtItsHomeOffice_:
		"Approbation de ce dessin signifie que l'équipement est acceptable en vertu des dispositions du cahier des charges de travail. Toute modification apportée sur le connaissement par toute personne quel sous réserve d'acceptation par réfrigération NATIONAL à son bureau à domicile.",
	aPPROVALS: "APPROBATIONS",
	aPPROVEDBY_: "APPROUVÉ PAR : ",
	cARRIERPREFERENCE: "PRÉFÉRENCE DE TRANSPORTEUR",
	certifiedPrintMaker: "Machine à impression certifié",
	cHANGEORDERREQUESTFORM: "FORMULAIRE DE DEMANDE DE COMMANDE CHANGEMENT",
	cUSTOMERORDER__: "NO DE COMMANDE CLIENT :",
	dATE_: "DATE DE :",
	dimA: "Dim Un",
	dimB: "Dim B",
	dimC: "Dim C",
	dimD: "Dim D",
	dIMENSIONA: "DIMENSION UN",
	dIMENSIONB: "B DIMENSION",
	dIMENSIONC: "DIMENSION C",
	dIMENSIOND: "DIMENSION D",
	dIMENSIONE: "DIMENSION E",
	dIMENSIONF: "DIMENSION F",
	dIMENSIONG: "DIMENSION G",
	dIMENSIONH: "DIMENSION H",
	dIMENSIONS: "DIMENSIONS",
	dimensionsShownAreForStandardUnitLessOptions_:
		"Les dimensions indiquées sont pour une unité standard moins d'options.",
	dimensionsShownAreForStandardUnitLessOptions_SeeCertifiedDrawingForMoreDetails_:
		"Les dimensions indiquées sont pour une unité standard moins d'options. Voir dessin certifié pour plus de détails.",
	dISCHARGE: "DÉCHARGE",
	documentBrowser: "Navigateur De Document",
	dRAIN: "VIDANGE",
	eLECTRONICORDERFORM: "FORMULAIRE DE COMMANDE ÉLECTRONIQUE",
	eLECTRONICORDERFORM_CONTINUED: "FORMULAIRE DE COMMANDE ÉLECTRONIQUE - SUITE",
	eQUIPMENTSUMMARY: "RÉSUMÉ DE L'ÉQUIPEMENT",
	eSTSHIPWEIGHT_LessOptions_: "EST navire poids (moins d'Options)",
	eSTSHIPWEIGHT_: "POIDS DE BATEAU EST :",
	fACTORYINVOICETO_: "USINE FACTURE À :",
	fACTORYORDER__: "USINE NO DE COMMANDE :",
	fREIGHT: "FRET",
	fREIGHTTERMS_: "CONDITIONS DE TRANSPORT DE MARCHANDISES :",
	hOTGASINLET: "ENTRÉE DE GAZ CHAUD",
	hOTGASOUTLET: "SORTIE DE GAZ CHAUD",
	hOTGASSIDEPORT: "CHAUD GAZ CÔTÉ PORT",
	iD__: "ID # :",
	iTEM__: "ITEM # :",
	lbs_LessOptions_: "Lbs (moins d'Options )",
	lead: "Plomb",
	list: "Liste",
	mANUALENTRY: "SAISIE MANUELLE",
	mANUALOPTION: "OPTION MANUELLE",
	mult: "Mult",
	nATIONALREFRIGERATIONWillFurnishEquipmentInAccordanceWithThisDrawingAndSpecifications_AndSubjectToItsPublishedWarranty_ApprovalOfThisDrawingSignifiesThatTheEquipmentIsAcceptableUnderTheProvisionOfTheJobSpecifications_AnyChangeMadeHereonByAnyPersonWhomsoeverSubjectToAcceptanceByNATIONALREFRIGERATIONAtItsHomeOffice_:
		"RÉFRIGÉRATION NATIONAL fournira des équipements conformément à ce dessin et devis et sous réserve de sa garantie publiée. Approbation de ce dessin signifie que l'équipement est acceptable en vertu des dispositions du cahier des charges de travail. Toute modification apportée sur le connaissement par toute personne quel sous réserve d'acceptation par réfrigération NATIONAL à son bureau à domicile.",
	nEWSYSTEM: "NOUVEAU SYSTÈME",
	nOTES_: "NOTES :",
	oEMult: "Oe Mult",
	oF: "DE",
	oPTION: "OPTION",
	oRDER__: "NO DE COMMANDE :",
	oRDERDATE_: "DATE DE LA COMMANDE :",
	pACKAGE: "PAQUET",
	pACKAGEITEM: "ÉLÉMENT DE PACKAGE",
	pACKAGEOPTION: "OPTION FORFAIT",
	pAGE: "PAGE",
	pANLOOP: "BOUCLE DE PAN",
	pRICEBOOK_: "PRIX LIVRE :",
	priceTotal: "Prix Total",
	pROD: "PROD",
	pROJECT_: "PROJET :",
	pURCHASER_: "ACHETEUR :",
	pURCHASER_SPO__: "PO L'ACHETEUR # :",
	qUOTE__: "CITE # :",
	qUOTEDATE_: "DATE DE SOUMISSION :",
	rEF__: "REF # :",
	rEMARKS_SPECIALSHIPPINGINSTRUCTIONS:
		"REMARQUES / SPECIAL INSTRUCTIONS D'EXPÉDITION",
	s_H: "S & H",
	servicePartsList: "Liste de pièces de rechange",
	sHIPMENT_: "EXPÉDITION :",
	shippingAndHandling: "Expédition et manutention",
	sHIPPINGWEIGHT: "POIDS À L'EXPÉDITION",
	sOUND: "SOUND",
	sTOCKINGMODELS: "MODÈLES DE STOCKAGE",
	sUBMITTEDBY_: "SOUMIS PAR :",
	sUBTOTAL: "TOTAL PARTIEL",
	sUCTION: "ASPIRATION",
	systemNet: "Système Net",
	tAGGING_: "MARQUAGE :",
	totalNet: "Total Net",
	uSERID_: "ID DE L'UTILISATEUR :",
	vENDORID_: "CODE FOURNISSEUR :",
	wATER: "EAU",
	wEIGHT: "POIDS",
	aSuiteOfSoftwareApplicationsCreatedToHelpRefrigerationProfessionalsMakeAccurateCalculations_AccessProductInformation_ChooseComponentsAndPrintTechnicalDrawings:
		"Une suite d'applications de logiciel créé pour aider les professionnels de la réfrigération à faire des calculs précis, accéder aux informations produit, choisissez les composants et d'imprimer des dessins techniques",
	pleaseUpdateAllPrices_: "Veuillez mettre à jour tous les prix.",
	pleaseEnterYourPersonal: "Veuillez entrer vos données personnelles",
	applicationRegistrationKey_: "clé d'enregistrement de demande.",
	saveAsNewContact: "Enregistrer en tant que nouveau contact",
	saveContact: "Enregistrer le contact",
	saveNewContact: "Enregistrer le nouveau contact",
	partNumber: "Numéro de pièce",
	loadCalculatorII: "Calculateur de charge II",
	newJob: "Nouvel emploi",
	systemCapacity: "Capacité du système",
	descriptions: "Descriptions",
	boxApplication: "Application de la boîte",
	application: "Application",
	theExternalRelativeHumidityMustBeGreaterThan:
		"L'humidité relative extérieure doit être supérieure à",
	theExternalRelativeHumidityMustBeLessThan___:
		"L'humidité relative extérieure doit être inférieure à",
	theInternalRelativeHumidityMustBeGreaterThan:
		"L'humidité relative intérieure doit être supérieure à",
	theInternalRelativeHumidityMustBeLessThan:
		"L'humidité relative intérieure doit être inférieure à",
	lineLoss: "Perte de ligne",
	systemRunTime: "Système de temps d'exécution",
	systemRunTimeMustBeGreaterThanOneHour_:
		"Moment de l'exécution de système doit être supérieure à une heure !",
	systemRunTimeMustBeLessThanTwentyfourHours_:
		"Moment de l'exécution de système doit être inférieur à vingt-quatre heures !",
	theSystemLineLossIsNotEntered_:
		"La perte de ligne du système n'est pas entrée !",
	theSystemLineLossIsTooHigh_: "La perte de ligne du système est trop élevée !",
	theSystemLineLossIsTooLow_: "Le système de perte de ligne est trop faible !",
	averagePassageTime_Seconds_: "Temps de Passage moyen (secondes)",
	passagesPerDay: "Passages par jour",
	curtainEffectiveness: "Efficacité de Rideau",
	standOpenTimePerDay_Minutes_: "Temps ouvert de repos (Minutes par jour)",
	cONSTRUCTIONANDDIMENSIONS: "CONSTRUCTION ET DIMENSIONS",
	volumeAndTemperatures: "Volume et températures",
	airFlowRates: "Débits d'air",
	volumeAndTemperature: "Volume et température",
	hasConfigurationErrors: "comporte des erreurs de configuration",
	optionConfigurations: "Configurations De L'option",
	setConfiguration: "Configuration de jeu",
	can_tHaveOptions: "Ne peut pas avoir des options",
	notRecommendedOptions: "Déconseillé d'Options",
	recommendedOptions: "Options recommandées",
	availableConfigurations_: "Configurations disponibles.",
	normalConfiguration_: "Configuration normale.",
	hasConfigurationWarning: "a avertissement de configuration",
	optionConfigurationError_: "Erreur de configuration option !",
	optionConfigurationWarning_: "Avertissement de configuration option !",
	optionConfigurtionErrorsDetected_:
		"Erreurs de configurtion option détectés !",
	pleaseSelectOneOfTheAvailableConfigurations_:
		"Veuillez sélectionner une des configurations disponibles.",
	thisOptionHasMultipleConfigurationsAvailable_:
		"Cette option a plusieurs configurations disponibles.",
	includesOptions: "Inclut des Options",
	over_Ride_SubmitErrors: "Over_Ride & présenter les erreurs",
	cHeckPRicesOK: "CHeckPRicesOK",
	errorLabel: "Errorlabel",
	noRefrigerantRatingValuesListed_:
		"Aucune valeur de cotation réfrigérant énumérés.",
	termsOfSale: "Conditions générales de vente",
	warranty: "Garantie",
	lOGO: "LOGO",
	tERMSOFSALE: "CONDITIONS GÉNÉRALES DE VENTE",
	vENDORINFORMATION: "INFORMATIONS RELATIVES AU FOURNISSEUR",
	useTOSImageFile: "Utiliser le fichier Image TOS",
	checkAllErrors: "CheckAllErrors",
	drawings: "Dessins",
	optionList: "Liste des options",
	specificationsPage: "Page de spécifications",
	warningLabel: "Étiquette d'avertissement",
	clickCANCELToAdjustYourSelectedOptions_:
		"Cliquez sur Annuler pour ajuster vos options choisies.",
	youWillNotBeAbleToSubmitThisOrderUsingThisOptionConfiguration_:
		"Vous ne serez pas en mesure de soumettre cette commande à l'aide de la configuration de cette option.",
	oPTIONDESCRIPTIONAPPENDIX: "OPTION DESCRIPTION ANNEXE",
	requestedOrderShipDateIsBeforeToday_:
		"Date d'expédition de l'ordonnance demandée est avant aujourd'hui !",
	ordersListed_: "Commandes répertoriées.",
	sEARCHINGANDSORTING: "RECHERCHER ET TRIER",
	synchronizingOrders_PleaseWait:
		"Synchronisation des commandes ~ Veuillez patienter",
	archive_: "Archive - ",
	synchronizingOrders: "Les commandes de synchronisation",
	itemPartDescriptionRequired_: "Description de l'article partie requise !",
	doYouWantToAttemptToOpenThisFileAnyway_:
		"Voulez vous essayer d'ouvrir ce fichier de toute façon ?",
	oldStyleChecksumDoesNotMatch_:
		"Somme de contrôle style vieux ne correspond pas.",
	failedToOpenDataCatalog_: "Impossible d'ouvrir le catalogue de données !",
	oPTIONDESCRIPTIONAPPENDIXCONTINUED: "OPTION DESCRIPTION ANNEXE SUITE",
	includeUnselectedOptions: "Inclure des Options non sélectionnées",
	sELECTEDOPTIONDESCRIPTIONS: "DESCRIPTION DE L'OPTION SÉLECTIONNÉE",
	sELECTEDOPTIONDESCRIPTIONSCONTINUED:
		"DESCRIPTION DES OPTIONS SÉLECTIONNÉES SUITE",
	setRevisionSUbmitted: "Set de révision soumise",
	uNsubmitQuote: "Citation de uNsubmit",
	addDoors_Openings: "Ajouter des portes / ouvertures",
	deleteDoors_Openings: "Supprimer les portes / ouvertures",
	dOORUSAGE: "UTILISATION DE LA PORTE",
	editDoor_Openings: "Edit porte / ouvertures",
	curtainR_Value: "Rideau valeur r",
	loadingPlatformRoll_UpDoor:
		"Porte à enroulement de plate-forme de chargement",
	trailerDockRoll_UpDoor: "Remorque porte à enroulement Dock",
	reach_InGlassDoor: "Reach-In porte vitrée",
	boxUsage: "Utilisation de la boîte",
	floorType: "Type de sol",
	theRefrigeratedFloorIsBuiltDirectlyOnTheGround__OrTheRoomInWhichTheRefrigeratedBoxIsLocatedIsBuiltDirectlyOnTheGround__:
		"La parole réfrigérée est construite directement sur le sol. ~ Ou la pièce où se trouve la boîte réfrigérée est construite directement sur le sol. ~",
	orTheRoomInWhichTheRefrigeratedBoxIsLocatedIsBuiltDirectlyOnTheGround_:
		"Ou la salle où se trouve la boîte réfrigérée est construite directement sur le sol,",
	orThereIsAVentilatedSpaceUnderTheRoomInWhichTheRefrigeratedSpaceIsLocated_:
		"Ou il y a un espace ventilé sous la salle où se trouve l'espace réfrigéré.",
	theRefrigeratedFloorIsBuiltDirectlyOnTheGround_:
		"La parole réfrigérée est construite directement sur le sol.",
	thereIsAVentilatedSpaceUnderTheRefrigeratedFloor_:
		"Il y a un espace ventilé sous le plancher réfrigéré.",
	thisIncludesCrawlSpacesOrUpperFloorInstallations_:
		"Cela inclut les vides sanitaires ou les installations de l'étage supérieur.",
	coolingLoads: "Charges de refroidissement",
	checkBox: "Case à cocher",
	aboveGrade: "Au-dessus du sol",
	onGrade: "Au niveau du sol",
	heatingTemperature: "Température de chauffage",
	surroundingAirTemperature: "Température de l'Air ambiant",
	onGrade_FrostHeaveProtected:
		"Au niveau du sol - le soulèvement dû au gel protégé",
	aNDAControlledFrostHeavePreventionHeaterSystemIsOrWillBeInstalled_:
		"ET un système de chauffage de prévention contrôlée de soulèvement dû au gel est ou sera installé.",
	construction: "Construction",
	wALL_FLOORANDCEILINGLOADS: "CHARGES DE MUR, PLANCHER ET PLAFOND",
	cONTINUED: "SUITE",
	pRODUCTLOADS: "CHARGES DE PRODUIT",
	finalTemp: "Temp finale",
	enterTemp: "Entrez Temp",
	pullDown: "Tirez vers le bas",
	tOTALPRODUCTLOAD: "PRODUIT TOTAL CHARGE",
	tOTALPRODUCTWEIGHT: "POIDS TOTAL DU PRODUIT",
	tOTALWALL_FLOORANDCEILINGLOAD: "TOTAL MUR, PLANCHER ET PLAFOND CHARGE",
	vOLUME: "VOLUME",
	mISCELLANEOUSLOADS: "CHARGES DIVERSES",
	tOTALMISCELLANEOUSLOAD: "TOTAL CHARGE DIVERS",
	aVERAGE: "MOYENNE",
	fORKLIFTS_TOWMOTORS: "CHARIOTS ÉLÉVATEURS / MOTEURS DE REMORQUAGE",
	hEAVY: "LOURDES",
	lIGHT: "LUMIÈRE",
	externalRh: "Rh externe",
	externalTemp: "Temp externe",
	heavy: "Lourdes",
	internalRh: "Rh interne",
	internalTemp: "Temp interne",
	regular: "Régulier",
	tOTALINFILTRATIONLOADESTIMATEDBYDOORSANDOPENINGS:
		"CHARGE D'INFILTRATION TOTAL ESTIMÉ PAR DES PORTES ET OUVERTURES",
	ventilation: "Ventilation",
	iNFILTRATIONLOAD_ESTIMATEDBYDOORSANDOPENINGS:
		"CHARGE D'INFILTRATION : ESTIMÉE PAR DES PORTES ET OUVERTURES",
	iNFILTRATIONLOAD_ESTIMATEDBYVENTILATION:
		"CHARGE D'INFILTRATION : ESTIMÉE PAR VENTILATION",
	iNFILTRATIONLOAD_ESTIMATEDBYVOLUMEANDUSAGE:
		"CHARGE D'INFILTRATION : ESTIMÉE PAR LE VOLUME ET L'UTILISATION",
	curtains: "Rideaux",
	doorOrOpeningType: "Porte ou ouverture Type",
	openTime: "Temps ouvert",
	preparedBy: "Préparé par",
	preparedFor: "Préparé pour",
	customerInformation: "Renseignements sur les clients",
	job_ProjectName: "Nom de la tâche/projet",
	cOOLINGREQUIREMENTS: "EXIGENCES DE REFROIDISSEMENT",
	designLoad: "Charge de calcul",
	equipmentLoad: "Charge de l'équipement",
	hours: "Heures",
	safetyLoad: "Charge de la sécurité",
	safetyFactor: "Coefficient de sécurité",
	capacityRequired: "Capacité requise",
	condenserAmbient: "Condenseur ambiante",
	sYSTEMDESIGNSUMMARY: "RÉSUMÉ DE CONCEPTION SYSTÈME",
	sYSTEMEQUIPMENTSELECTION: "SÉLECTION D'ÉQUIPEMENT DE SYSTÈME",
	evaporator: "Évaporateur",
	nOTSELECTED_: "PAS SÉLECTIONNÉ !",
	airCooledCondenser: "Condenseur refroidi à l'air",
	condenser: "Condenseur",
	evaporatorTD: "Évaporateur TD",
	fanLoad: "Charge de ventilateur",
	deleteSystem: "Supprimer le système",
	duplicateSystem: "Système double",
	editSystem: "Modifier le système",
	area_ZoneOrSystemName: "Zone, Zone ou nom du système",
	areaDeletionRequested_: "Suppression du domaine demandée.",
	systemDetails: "Détails du système",
	equipmentCapacity: "Capacité de l'équipement",
	equipmentTD: "Équipement TD",
	design: "Conception",
	miscellaneous: "Divers",
	products: "Produits",
	safety: "Sécurité",
	openBoxDesigner: "Concepteur de la boîte ouverte",
	sYSTEMSLOADSUMMARY: "SYSTÈMES CHARGE RÉSUMÉ",
	importLoadCalculatorIDesignFile:
		"Calculateur de charge de l'importation je conçois des fichier",
	importLoadCalculatorIIDesignFile:
		"Importer fichier de conception de charge Calculatrice II",
	newCondenserDesign: "Nouveau Design de condenseur",
	newFluidCoolerDesign: "Nouveau Design de refroidisseur liquide",
	newGlycolCoilDesign: "Nouveau Design de bobine de Glycol",
	newLoadCalculatorIIDesign: "Nouvelle charge Calculatrice II Design",
	newSystemNamed: "Nouveau système nommé",
	otherEquipment: "Autres équipements",
	safetyFactor___: "Facteur de sécurité (%)",
	quantityPERCompressorUnit: "Quantité par unité de compresseur",
	quantityPERCondensingUnit: "Quantité par unité de condensation",
	hourlyLoadRequired: "Charge horaire requise",
	suctionLineLoss: "Perte de la conduite d'aspiration",
	connectionCount: "Nombre de connexions",
	pleaseSelectAndEditTheAppropriateSection_:
		"Veuillez sélectionner et modifier la section appropriée.",
	toEnterOrEditDoorOrOpeningDetails_:
		"Pour entrer ou modifier la porte ou les détails de l'ouverture,",
	clearAllSelections: "Effacer toutes les sélections",
	interiorTemperature: "Température intérieure",
	areYouSureYouWantToSubmitThisMyEditOrder_:
		"Etes-vous s√r de vouloir soumettre cette myEditOrder.",
	doYouWantToProcessThisChangeOrderAsAnOriginalMyEditOrder_:
		"Voulez-vous traiter cet ordre de modification comme un myEditOrder original.",
	ifYouSubmitThisOrder_YouAreOfficiallyTakingResponsibilityForTheMyEditOrder_:
		"Si vous envoyez cette commande, vous prenez officiellement responsable de la myEditOrder.",
	untilTheOrderIsProcessedByTheManufacturerYouMayStopThisMyEditOrder_:
		"Jusqu'à ce que la commande est traitée par le fabricant, vous pouvez cesser cette myEditOrder.",
	untilTheOrderIsProcessedByThePurchaserYouMayStopThisMyEditOrder_:
		"Jusqu'à ce que la commande est traitée par l'acheteur, vous pouvez cesser cette myEditOrder.",
	wARNING_YOUARENOTTHEORIGINATOROFTHISMyEditOrder_:
		"AVERTISSEMENT : Vous n'êtes pas le créateur de ce myEditOrder.",
	youCanNoLongerStopThisMyEditOrder_:
		"Vous pouvez arrêter n'est plus de ce myEditOrder.",
	youDoNotHaveOwnershipOfThisOrder_:
		"Vous n'avez pas la propriété de la présente ordonnance.",
	testCloneCompare: "TestCloneCompare",
	modelNotLoaded_: "Modèle ne pas chargé !",
	temperature_TooHigh_: "Température trop élevée de 1 !",
	temperature_TooLow_: "Température trop basse de 1 !",
	noRatingFound_: "Aucune note trouvée !",
	temperature_IsTooHigh_: "1 la température est trop élevée !",
	temperature_IsTooLow_: "1 la température est trop basse !",
	balancedCapacity: "Capacité équilibrée",
	evap_TD: "EVAP TD",
	ambientTemperature_EvapTemperature_:
		"Température ambiante < Evap température !",
	boxTemperature_EvapTemperature_: "Boîte de température < Evap température !",
	condensingTemperature_EvapTemperature_:
		"Température de condensation < Evap température !",
	evap: "EVAP",
	evapTemperature: "Température de l'EVAP",
	isInvalid_: "n'est pas valide !",
	isMissing_: "est manquant !",
	isOutOfRange_: "est hors de portée !",
	systemLoadIsInvalid_: "Charge du système n'est pas valide !",
	systelLoadNotGiven_: "Systel charge ne pas donné.",
	temperature_NotGiven_: "Temperature1 ne pas donné.",
	productName: "Nom du produit",
	ambientTemperature: "Température ambiante",
	loadCalculationDesignHasErrors_:
		"Conception de calcul de charge comporte des erreurs !",
	loadCalculationDesignHasWarnings_:
		"Conception de calcul de charge a avertissements !",
	equipmentSelectionIsIncomplete_: "Choix de l'équipement est incomplète !",
	systemIsNotBalanced_: "Système n'est pas équilibré !",
	designHasErrors_: "Conception comporte des erreurs !",
	designHasWarnings_: "Conception a avertissements !",
	loadCalculationSystemHasDesignErrors_:
		"Système de calcul de charge comporte des erreurs de conception !",
	loadCalculationSystemHasDesignWarnings_:
		"Système de calcul de charge a Design avertissements !",
	testMail: "TestMail",
	oneOrMoreRequestedDatesAreInThePast_:
		"Une ou plusieurs dates demandées sont dans le passé !",
	shipmentDateErrorsFound_: "Date de livraison erreurs détectées !",
	reset: "Remise à zéro",
	currency: "Monnaie",
	edit_AddDoorsAndOpenings: "Modifier/ajouter des portes et ouvertures",
	thePriceBookUsedForTheOpenChangeRequestHasExpired_:
		"Le catalogue des prix utilisé pour la demande de modification ouvertes a expiré.",
	thePriceBookUsedForThisOrderHasExpired_:
		"Le catalogue des prix utilisé pour cet ordre a expiré.",
	thePriceBooksUsedForThisOrderHaveExpired_:
		"Les livres de prix utilisés pour cette commande sont expirés.",
	alwaysOpenAreaOfWallLeadingToAnotherZone_:
		"Ouvre toujours la zone du mur menant vers une autre zone.",
	areaToBeExcludedFromWallDefinition_:
		"Zone à exclure de la définition de mur.",
	nonOpeningGlassWindow_: "Non ouverture vitrée.",
	normallyClosedDoorForStaffOrGoodsTraffic_:
		"Porte normalement fermée à la circulation du personnel ou des biens.",
	roll_UpDoorOpenToOutsideEnvironmentOrOtherZone_:
		"Porte à enroulement ouverte au dehors environnement ou d'une autre zone.",
	sectionOfWallWithDifferentInsulationOrExternalEnvironmentFromRestOfWall_:
		"Pan de mur avec isolation différente ou environnement extérieur reste du mur.",
	pleaseSelectAnOpeningType_: "Veuillez sélectionner un type d'ouverture.",
	lOADCALCULATION: "CALCUL DE LA CHARGE",
	reach_InSelfServeGlassDoors_: "Reach-In libre-service portes vitrées.",
	roll_UpDoorDesignedToBeNormallyClosedOrSealedByTransportTrailer_:
		"Porte à enroulement conçue pour être normalement fermés ou scellés par remorque de transport.",
	areYouSureYouWantToUseTheSeletedValue_:
		"Êtes-vous certain de que vouloir utiliser la valeur sélectionné ?",
	theInfiltrationCalculatedForTheseIsGreaterThanTheVentilationInfiltration_:
		"L'infiltration calculée pour ces derniers est supérieure à l'infiltration de la ventilation.",
	theInfiltrationCalculatedForTheseIsGreaterThanTheVolumeEstimatedInfiltration_:
		"L'infiltration calculée pour ces derniers est supérieure à l'infiltration de volume estimé.",
	valueWarning_: "Avertissement de valeur !",
	youHaveDoorsAndOpeningsDefinedInTheBoxDesign_:
		"Vous avez des portes et ouvertures définis dans la conception de la boîte.",
	conductionLoad: "Charge de conduction",
	iNFILTRATIONLOADPERUNIT: "INFILTRATION DE CHARGE UNITAIRE",
	iNSULATIONLOADPERUNIT: "ISOLATION CHARGE UNITAIRE",
	indoor: "Indoor",
	outdoor: "En plein air",
	outdoorCool: "Plein air Cool",
	outdoorWarm: "Plein air chaud",
	outdoors_ColdClimate_: "Plein air (climat froid)",
	eSTIMATEDBYVENTILATION: "ESTIMÉE PAR VENTILATION",
	eSTIMATEDBYBOXVOLUME_USAGE: "ESTIMÉ PAR LE VOLUME DE LA BOÎTE / UTILISATION",
	eSTIMATEDBYDOORSANDOPENINGS: "ESTIMÉE PAR LES PORTES ET OUVERTURES",
	ventilationInfiltrationEstimate:
		"Estimation de l'Infiltration de ventilation",
	volume_UsageInfiltrationEstimate:
		"Utilisation de volume/Infiltration estimation",
	infiltrationMethodChosenIsNotTheMaximumLoadEstimateEntered_:
		"Méthode d'infiltration choisie n'est pas l'estimation de la charge maximale est entrée.",
	calculationMethod: "Méthode de calcul",
	useMaximum: "Maximale d'utilisation",
	lOADCONNECTIONSCONTINUED: "CONNEXIONS DE CHARGE CONTINUES",
	insulationLayerIsRecommendedForSlabOnGradeFreezers_:
		"Couche d'isolation est recommandé pour la galette sur les congélateurs de grade !",
	aDDNEWSYSTEMUSING: "AJOUTER LE NOUVEAU SYSTÈME À L'AIDE",
	iMPORTFROMFILE: "IMPORTATION DE FICHIER",
	miscelaneousUnits: "Unités de divers",
	importSystemsFromALoadCalculatorIIFile_:
		"Importer des systèmes à partir d'un fichier II de calculateur de charge.",
	ballyBoxWizardFile: "Fichier de l'Assistant boîte Bally",
	importSystemsFromABallyBoxWizardFile_:
		"Importer des systèmes à partir d'un fichier de Bally Assistant de zone.",
	importSystemsFromAKeepriteCalc_RiteFile_:
		"Importer des systèmes à partir d'un fichier Keeprite calco-Rite.",
	importSystemsFromATretonCalculoadFile_:
		"Importer des systèmes à partir d'un fichier Treton Calcuload.",
	keepriteCalc_RiteFile: "Keeprite calco-Rite fichier",
	tretonCalculoadFile: "Fichier Calcuload treton",
	abortAddingASystem_: "Annuler l'ajout d'un système.",
	saveSystemAsTemplate: "Enregistrer comme modèle de système",
	sTANDARDAPPLICATIONS: "APPLICATIONS STANDARD",
	uSERTEMPLATES: "MODÈLES DE L'UTILISATEUR",
	rH_TD: "RH / TD",
	createSystem_PleaseSelectApplicationTypeOrTemplate:
		"Créer un système : Veuillez sélectionner Application Type ou modèle",
	fACTORYTEMPLATES: "MODÈLES DE L'USINE",
	saveAsTemplate: "Enregistrer comme modèle",
	change: "Changement",
	evapTDIsTooHighForTheSelectedApplication_:
		"EVAP TD est trop élevé pour l'application sélectionnée !",
	evapTDIsTooLowForTheSelectedApplication_:
		"EVAP TD est trop faible pour l'application sélectionnée !",
	theInternalTemperatureIsTooHighForTheSelectedApplication_:
		"La température interne est trop élevée pour l'application sélectionnée !",
	theInternalTemperatureIsTooLowForTheSelectedApplication_:
		"La température interne est trop faible pour l'application sélectionnée !",
	newMulti_SystemJob: "Nouveau multi-système travail",
	betaVersionDesignDetected_: "Conception de version bêta détectée.",
	doYouWantToUpdateTheDesignToTheNewerVersion_:
		"Voulez-vous mettre à jour la conception la plus récente version.",
	newerCalculatorVersionDetected_:
		"Version plus récente de la calculatrice détectée.",
	pleaseVerifyYourDesign_: "S'il vous plaît vérifiez votre conception.",
	theLoadCalculationHASCHANGEDWithTheNewerVersion_:
		"Le calcul de la charge a changé avec la version plus récente.",
	theLoadCalculationHASNOTChangedWithTheNewerVersion_:
		"Le calcul de la charge n'a pas changé avec la version plus récente.",
	theLoadCalculationsMayHaveChangedSinceYouFirstCreatedThisJob_:
		"Le calcul des charges peut avoir changé depuis que vous avez créé tout d'abord ce travail.",
	thisDesignWasCreatedWithTheBetaVersionOfTheSoftware_:
		"Cette conception a été créé avec la version bêta du logiciel.",
	export_CSV: "Export_CSV",
	loadCalculation: "Calcul de la charge",
	optionDescriptionList: "Liste des options Description",
	pleaseCheckYourEquipmentSelection_:
		"S'il vous plaît vérifier votre choix de l'équipement.",
	ifThisProblemPersists_PleaseContactTheFactory_:
		"Si le problème persiste, veuillez contacter l'usine.",
	selectedEquipmentNoLongerBalances_:
		"Certains équipements ne sont plus les soldes !",
	thisDesignWasCreatedWithAnEarlierVersionOfThisSoftware_:
		"Cette conception a été créé avec une version antérieure de ce logiciel.",
	newerVersionDesignDetected_: "Nouvelle conception de la version détectée !",
	pleaseRestartYourSoftwareToUpdateYourSystemAndDataFiles_:
		"Redémarrez votre logiciel pour mettre à jour les fichiers de votre système et vos données.",
	lade: "Lade",
	pending: "En attente",
	outdoors_ColdClimate___: "Plein air (climat froid) ~ ~",
	loadingServicePartCatalog_: "Chargement de Service partie catalogue.",
	clearFilter: "Effacer le filtre",
	category: "Catégorie",
	partName_No: "La partie nom / non",
	qTTY: "QTTY",
	modelNumber_WhereUsed: "Numéro de modèle / où utilisé",
	equipmentDetails: "Détails d'équipement",
	leadTimeChangesFound_: "modifications de l'heure plomb trouvées.",
	leadTimesChangedOn: "Délais, changés le",
	leadTimesChangedOnOneRevision_: "Délais a changé sur une révision.",
	noLeadTimeChangesFound_: "Aucun changement de temps de plomb trouvés.",
	noLeadTimeChangesOccurred_:
		"Aucun changement de temps de plomb s'est produite.",
	oneLeadTimeChangeFound_: "Changement d'heure d'un plomb trouvé.",
	copyShippingDateInformationTo: "Copiez les informations de date expédition à",
	forComplexFloorsWeSuggestYouUseTheFloorPlanner:
		"Pour sols complexes nous vous suggérons de qu'utiliser le planificateur de plancher",
	onlyOneFloorSectionMayBeAddedWithThisTool_:
		"Section d'un seul étage peut être ajoutée avec cet outil.",
	sorry: "Désolé",
	orEnterTheTotalFloorAreaAndPerimeterValuesInTheExistingFloorDefinition_:
		"ou entrez les valeurs d'aire et le périmètre de plancher totale dans la définition de plancher existant.",
	boxDesign: "Conception de boîte",
	canNotSubmit_: "Ne peut pas soumettre !",
	invalidEmail_Format_: "Format de l'adresse E-mail incorrecte 1 !",
	OrdersWillBeMarkedAsShipped_:
		" les commandes seront marqués comme ayant été expédiée !",
	OrdersWillBeMarkedClosed_: " les commandes seront marqués fermés !",
	calendarDays: "jours calendaires",
	leadTimeCalendarDays: "Conduire les jours temps",
	leadTimeAddWeeks: "Délai ajouter semaines",
	leadTimeWeeksAdded: "Ajouté semaines de délai d’exécution",
	pleaseContactFactory: "S’il vous plaît contacter usine",
	wARNING_SUBMITTEDQUOTENOTFOUND_:
		"MISE EN GARDE ! SOUMIS CITATION INTROUVABLE.",
	CalandarDays_: " Jours de calendrier !",
	sorry_: "Désolé !",
	youCanNotDeleteTheOrderedQuoteRevision_:
		"Vous ne pouvez pas supprimer la révision devis commandés ?",
	deleteCurrentQuoteRevision: "Supprimer la révision actuelle de citation",
	restoreCurrentQuoteRevisionToLastSavePoint:
		"Devis révision en cours de restauration au dernier Point de sauvegarde",
	canNotRestoreTheOrderedQuoteRevision_:
		"Ne pouvez pas restaurer la révision devis commandés ?",
	thePackage: "Le paquet ",
	standardFeatures: " CARACTÉRISTIQUES STANDARDS",
	additionalOptionsAndAccessories: "OPTIONS ET ACCESSOIRES ADDITIONNELS"
};
