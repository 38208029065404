/* eslint-disable no-underscore-dangle */
import * as React from "react";

import BoxSectionModal from "./BoxSectionModal";
import Button from "../helpers/Button";
import ConfirmationMessage from "../helpers/ConfirmationMessage";
import Grid from "../helpers/Grid";
import OpeningsModal from "../Openings/OpeningsModal";

import { measure, hasValue, getNumericValue } from "../../types/measure";

import UnitOfMeasure from "../../models/helpers/UnitOfMeasure";

import BoxLocation from "../../enums/BoxLocation";
import BoxType from "../../enums/BoxType";
import FloorType from "../../enums/FloorType";
import SectionType from "../../enums/SectionType";
import WallType from "../../enums/WallType";

import IOpening from "../../models/interfaces/IOpening";
import IFormState from "../../models/interfaces/IFormState";
import ISection from "../../models/interfaces/ISection";

import useBoxContext from "../../hooks/useBoxContext";
import useSystemContext from "../../hooks/useSystemContext";
import useTranslationContext from "../../hooks/useTranslationContext";

import "./BoxSectionsGrid.css";

const getSelectedSectionIndex = (
    sectionList: ISection[] | null,
    selectedRowIndex: number
) => {
    let index = -1;
    let associatedSectionIndex = -1;
    if (sectionList) {
        sectionList.forEach((s, sIndex) => {
            index += 1;
            if (selectedRowIndex === index) {
                associatedSectionIndex = sIndex;                
            }
            s.openings?.forEach(o => {
                index += 1;
                if (selectedRowIndex === index) {
                    associatedSectionIndex = sIndex;
                }
            });
        });
    }

    return associatedSectionIndex;
};

const getSelectedSection = (
    sectionList: ISection[] | null,
    selectedRowIndex: number
): ISection | undefined => {
    if (!sectionList) {
        return undefined;
    }
    const sectionIndex = getSelectedSectionIndex(sectionList, selectedRowIndex);
    return sectionList[sectionIndex];
};

const isSectionSelected = (
    sectionList: ISection[] | null,
    selectedRowIndex: number
) => { 
    return (selectedRowIndex > -1) && getSelectedSection(sectionList, selectedRowIndex);
};

const isOpeningSelected = (
    sectionList: ISection[] | null,
    selectedRowIndex: number
) => {
    let isOpening = false;
    let index = -1;
    if (sectionList) {
        sectionList.forEach(s => {
            index += 1;            
            s.openings?.forEach(o => {
                index += 1;
                if (selectedRowIndex === index) {
                    isOpening = true;
                }
            });
        });
    }

    return isOpening;
};

const isCustomSectionSelected = (
    sectionList: ISection[] | null,
    selectedRowIndex: number
) => { 
    return isSectionSelected(sectionList, selectedRowIndex) && (
        getSelectedSection(sectionList, selectedRowIndex)?.sectionType === SectionType.WallCustom
        || getSelectedSection(sectionList, selectedRowIndex)?.sectionType === SectionType.FloorCustom
        || getSelectedSection(sectionList, selectedRowIndex)?.sectionType === SectionType.CeilingCustom);
};

const isWallSectionSelected = (
    sectionList: ISection[] | null,
    selectedRowIndex: number
) => { 
    if (selectedRowIndex > -1) {
        const sectionType = getSelectedSection(sectionList, selectedRowIndex)?.sectionType ?? SectionType.Undefined;
        return sectionType === SectionType.WallCustom || sectionType === SectionType.WallPolygon;
    }

    return false;
};

interface BoxSectionsGridProps {
    onSectionSelect: (sectionType: SectionType, wallType: WallType) => void;
}

const BoxSectionsGrid: React.FunctionComponent<BoxSectionsGridProps> = (
	props: BoxSectionsGridProps
) => {

    const [selectedRow, setSelectedRow] = React.useState(-1);
    const [sectionToEdit, setSectionToEdit] = React.useState(undefined as ISection | undefined);

    const [showSection, setShowSection] = React.useState(false);
    const [showOpenings, setShowOpenings] = React.useState(false);
    const [showConfirmationMessage, setShowConfirmationMessage] = React.useState(false);

    const { translations } = useTranslationContext();

    const { boxData } = useBoxContext();
    const { systemState, dispatch } = useSystemContext();

    const sectionsGridView = (sectionsList: ISection[]) => {
        return sectionsList.reduce((previousValue: { [key: string]: any }[], section: ISection) => {
            const sectionRow: { [key: string]: any } = {};
            sectionRow[translations.section] = section.sectionName;
            sectionRow[translations.sectionConstruction] = section.materialName;
            sectionRow[`${translations.thickness} (${UnitOfMeasure.getSmallerDimensionUnitLabel()})`] = UnitOfMeasure.getSmallerDimensionDisplayValue(section.thickness, 3);
            sectionRow[translations.kFactor] = UnitOfMeasure.getDisplayValue(section.kFactor, 2);
            sectionRow[translations.r_Value] = UnitOfMeasure.getDisplayValue(section.rValueEffective, 2);
            sectionRow[`${translations.extTemp} (${UnitOfMeasure.getTemperatureUnitLabel()})`] = section.exteriorTemperature !== null && section.exteriorTemperature !== undefined ? UnitOfMeasure.getTemperatureDisplayValue(section.exteriorTemperature, 1) : "";
            sectionRow[`Dim 1 (${UnitOfMeasure.getDimensionUnitLabel()})`] = UnitOfMeasure.getDimensionDisplayValue(section.dimension1, 2);
            sectionRow[`Dim 2 (${UnitOfMeasure.getDimensionUnitLabel()})`] = UnitOfMeasure.getDimensionDisplayValue(section.dimension2, 2);
            sectionRow[`${translations.area} (${UnitOfMeasure.getAreaUnitLabel()})`] = UnitOfMeasure.getAreaDisplayValue(section.areaWithoutOpenings, 0);
            sectionRow[`${translations.lOAD} (${UnitOfMeasure.getRatingUnitLabel()})`] = UnitOfMeasure.getRatingDisplayValue(section.conductionLoadWithoutOpenings, 0);
            sectionRow.errors = section.errors;
            sectionRow.rowClassName = (section.isModified ? "is-updated section" : "section");

            previousValue.push(sectionRow);

            section.openings?.forEach((opening: IOpening) => {
                const openingRow: { [key: string]: any } = {};
                openingRow[translations.section] = UnitOfMeasure.getDisplayValue(opening.quantity, 0);
                openingRow[translations.sectionConstruction] = opening.description;
                openingRow[`${translations.thickness} (${UnitOfMeasure.getSmallerDimensionUnitLabel()})`] = undefined;
                openingRow[translations.kFactor] = undefined;
                openingRow[translations.r_Value] = UnitOfMeasure.getDisplayValue(opening.rValueEffective, 2);
                openingRow[`${translations.extTemp} (${UnitOfMeasure.getTemperatureUnitLabel()})`] = opening.exteriorTemperature !== null && opening.exteriorTemperature !== undefined ? UnitOfMeasure.getTemperatureDisplayValue(opening.exteriorTemperature, 1) : "";
                openingRow[`Dim 1 (${UnitOfMeasure.getDimensionUnitLabel()})`] = hasValue(opening.width) ? UnitOfMeasure.getDimensionDisplayValue(UnitOfMeasure.IntoFt(getNumericValue(opening.width)), 2) : undefined;
                openingRow[`Dim 2 (${UnitOfMeasure.getDimensionUnitLabel()})`] = hasValue(opening.height) ? UnitOfMeasure.getDimensionDisplayValue(UnitOfMeasure.IntoFt(getNumericValue(opening.height)), 2) : undefined;
                openingRow[`${translations.area} (${UnitOfMeasure.getAreaUnitLabel()})`] = UnitOfMeasure.getAreaDisplayValue(opening.totalArea, 0);
                openingRow[`${translations.lOAD} (${UnitOfMeasure.getRatingUnitLabel()})`] = UnitOfMeasure.getRatingDisplayValue(opening.totalConductionLoad, 0);
                openingRow.errors = opening.errors;
                openingRow.rowClassName = "opening";

                previousValue.push(openingRow);
            });

            return previousValue;
        }, [] as { [key: string]: any }[]);
    };
    
    const addSection = (newSection: ISection) => { 
        setShowSection(false);
        
        dispatch({ 
            type: "add-section", 
            section: newSection,
			updateStore: false
		});

        const boxSections = systemState.formState.actualValues.wallLoad.boxSections ?? [];
        let totalRows = boxSections.length;
        boxSections.forEach(s => {
            totalRows += s.openings?.length ?? 0;
        });

        setSelectedRow(totalRows);
    };

    const updateSection = (updatedSection: ISection) => { 
        setShowSection(false); 

        if (selectedRow > -1) {
            const {boxSections} = systemState.formState.actualValues.wallLoad;
            const sectionIndex = getSelectedSectionIndex(boxSections, selectedRow);

            dispatch({ 
                type: "update-section", 
                index: sectionIndex,
                section: updatedSection,
                updateStore: false
            });
        }
    };

    const copySection = () => {
        if (selectedRow > -1) {
            const {boxSections} = systemState.formState.actualValues.wallLoad;
            const sectionIndex = getSelectedSectionIndex(boxSections, selectedRow);

            if (boxSections) {
                dispatch({ 
                    type: "copy-section", 
                    index: sectionIndex,
                    updateStore: false
                });

                let totalRows = boxSections.length;
                boxSections.forEach(s => {
                    totalRows += s.openings?.length ?? 0;
                });

                setSelectedRow(totalRows);
            }
        }
    };

    const deleteCustomSection = () => {
        if (selectedRow > -1) {
            const {boxSections} = systemState.formState.actualValues.wallLoad;
            const sectionIndex = getSelectedSectionIndex(boxSections, selectedRow);

            if (boxSections
                && isCustomSectionSelected(boxSections, selectedRow)
            ) {
                dispatch({ 
                    type: "delete-section", 
                    index: sectionIndex,
                    updateStore: false
                });
            }
        }
    };

    const openAddSectionPopup = () => {
        setSectionToEdit(undefined);
        setShowSection(true);
    };

    const openEditSectionPopup = () => {
        if (selectedRow > -1) {
            const selectedSection = getSelectedSection(systemState.formState.actualValues.wallLoad.boxSections, selectedRow);
            setSectionToEdit(selectedSection);
            setShowSection(true);
        }
    };

    const openAddOpeningsPopup = () => {
        if (selectedRow > -1) {
            setShowOpenings(true);
        }
    };

    const openingsModalProps = {
        section: getSelectedSection(systemState.formState.actualValues.wallLoad.boxSections, selectedRow) ?? {} as ISection,
        onOkClick: (sectionOpenings: IOpening[]) => { 
            const {boxSections} = systemState.formState.actualValues.wallLoad;
            const sectionIndex = getSelectedSectionIndex(boxSections, selectedRow);
            if (boxSections && sectionIndex > -1) {
                dispatch({ 
                    type: "update-section-openings", 
                    index: sectionIndex,
                    openings: sectionOpenings,
                    updateStore: false
                });
            }
            
            setShowOpenings(false); },
        onCancelClick: () => { 
            setShowOpenings(false); 
        }
    };

    const deleteConfirmationMessage = {
		title: "Confirmation",
		message: <div>Are you sure you want to delete this section?</div>,
		onYesClick: () => {
            deleteCustomSection();
            setShowConfirmationMessage(false);
        },
		onNoClick: () => {
            setShowConfirmationMessage(false);
        }
    };
    
    const handleRowClick = (rowIndex: number) => {
        setSelectedRow(rowIndex);
        if (props.onSectionSelect) {
            const selectedSection = getSelectedSection(systemState.formState.actualValues.wallLoad.boxSections, rowIndex);
            if (selectedSection) {
                props.onSectionSelect(selectedSection.sectionType, selectedSection.wallType);
            }
        }
    };

    const handleRowDoubleClick = () => {
        if (selectedRow > -1) {
            if (isOpeningSelected(systemState.formState.actualValues.wallLoad.boxSections, selectedRow)) {
                openAddOpeningsPopup();
            }
            else {
                openEditSectionPopup();
            }
        }
    };

    const handleAddButtonClick = () => {
        openAddSectionPopup();
    };

    const handleEditButtonClick = () => {
        openEditSectionPopup();
    };

    const handleDeleteButtonClick = () => { 
        setShowConfirmationMessage(true); 
    };

    const handleCopyButtonClick = () => { 
        copySection(); 
    };

    const handleAddOpeningsButtonClick = () => {
        openAddOpeningsPopup();
    };

    const handleBoxSectionOkClick = (section: ISection, isNew: boolean) => {
        if (isNew) {
            addSection(section);
        }
        else {
            updateSection(section);
        }
    };
    
    const content = (
        <div id="box-sections-grid">
            <div className="block">
                <div className="label is-small header">
                    {translations.sections}
                </div>
                <Grid 
                    dataSource={sectionsGridView(systemState.formState.actualValues.wallLoad.boxSections ?? [])} 
                    selectedRow={selectedRow}
                    onRowClick={handleRowClick}
                    onRowDoubleClick={handleRowDoubleClick}
                />
            </div>
            <div className="block">
                <div className="field is-grouped">
                    <Button
                        name="add-section"
                        label={translations.addCustomSection}
                        className="button is-small is-light is-fullwidth"
                        tooltip={translations.Box_Designer_Button_AddSection}
                        onClick={handleAddButtonClick}
                    />
                    <Button
                        name="delete-section"
                        label={translations.deleteCustomSection}
                        className="button is-small is-light is-fullwidth"
                        disabled={!isCustomSectionSelected(systemState.formState.actualValues.wallLoad.boxSections, selectedRow)}
                        tooltip={translations.Box_Designer_Button_DeleteSections}
                        onClick={handleDeleteButtonClick}
                    />
                    <Button
                        name="copy-section"
                        label={translations.copySection}
                        className="button is-small is-light is-fullwidth"
                        disabled={!isCustomSectionSelected(systemState.formState.actualValues.wallLoad.boxSections, selectedRow)}
                        tooltip={translations.Box_Designer_Button_CopySection}
                        onClick={handleCopyButtonClick}
                    />
                    <Button
                        name="add-opening"
                        label={translations.edit_AddDoorsAndOpenings}
                        className="button is-small is-light is-fullwidth"
                        disabled={!isWallSectionSelected(systemState.formState.actualValues.wallLoad.boxSections, selectedRow)}
                        tooltip={translations.Box_Designer_Button_EditOpenings}
                        onClick={handleAddOpeningsButtonClick}
                    />
                    <Button
                        name="edit-section"
                        label={translations.editSection}
                        className="button is-small is-light is-fullwidth"
                        disabled={!isSectionSelected(systemState.formState.actualValues.wallLoad.boxSections, selectedRow)}
                        tooltip={translations.Box_Designer_Button_EditSection}
                        onClick={handleEditButtonClick}
                    />
                </div>
            </div>
            {showSection && (
                <BoxSectionModal
                    onOkClick={handleBoxSectionOkClick}
                    onCancelClick={() => { setShowSection(false); }}
                    show={showSection}
                    boxType={systemState.formState.actualValues.boxType ?? BoxType.Undefined}
                    height={systemState.formState.actualValues.boxHeight}
                    depth={systemState.formState.actualValues.boxDepth}
                    depth2={systemState.formState.actualValues.boxDepth2}
                    depth3={systemState.formState.actualValues.boxDepth3}
                    length={systemState.formState.actualValues.boxLength}
                    length2={systemState.formState.actualValues.boxLength2}
                    length3={systemState.formState.actualValues.boxLength3}
                    isBoxFreezer={boxData.isBoxFreezer}
                    floorType={systemState.formState.actualValues.floorType ?? FloorType.OnGrade}
                    boxLocation={systemState.formState.actualValues.boxLocation ?? BoxLocation.Indoors}
                    boxExteriorTemperature={systemState.formState.actualValues.exteriorTemperature}
                    boxInteriorTemperature={systemState.formState.actualValues.interiorTemperature}
                    boxExteriorHumidity={systemState.formState.actualValues.exteriorHumidity}
                    boxInteriorHumidity={systemState.formState.actualValues.interiorHumidity}
                    sectionToEdit={sectionToEdit}
                />
            )}
            {showOpenings && (
                <OpeningsModal
                    show={showOpenings}
                    section={openingsModalProps.section}
                    boxInteriorTemperature={systemState.formState.actualValues.interiorTemperature}
                    boxInteriorHumidity={systemState.formState.actualValues.interiorHumidity}
                    onOkClick={openingsModalProps.onOkClick}
                    onCancelClick={openingsModalProps.onCancelClick}
                />
            )}
            {showConfirmationMessage && (
                <ConfirmationMessage
                    title={deleteConfirmationMessage.title}
                    message={deleteConfirmationMessage.message}
                    onYesClick={deleteConfirmationMessage.onYesClick}
                    onNoClick={deleteConfirmationMessage.onNoClick}
                    onCancelClick={deleteConfirmationMessage.onNoClick}
                    show={showConfirmationMessage}
                />
            )}
        </div>
    );
    
    return content;
}

export default BoxSectionsGrid;