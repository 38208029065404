/* eslint-disable import/prefer-default-export */

import { LocaleStrings } from "../../services/translations/TranslationService";

import BoxLocation from "../../enums/BoxLocation";

export const getLocationDescription = (
    location: BoxLocation | null,
    translations: LocaleStrings
): string => {
    switch(location) {
        case BoxLocation.OutdoorsCold:
            return translations.outdoorCool;
        case BoxLocation.OutdoorsWarm:
            return translations.outdoorWarm;
        default:
            return translations.indoor;
    }
};