enum SectionType {
    Undefined = "0",
    FloorPolygon = "1",
    WallPolygon = "2",
    CeilingPolygon = "3",
    FloorCustom = "11",
    WallCustom = "12",
    CeilingCustom = "13",
    Various = "-1"
}

export default SectionType;