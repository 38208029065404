import * as React from "react";
// @ts-ignore
import { Container } from "react-bulma-components";
import StorageFacade from "../services/StorageFacade";
import useTranslationContext from "../hooks/useTranslationContext";

interface LayoutProps {
	children: any;
}

const Layout: React.FunctionComponent<LayoutProps> = (
	props: LayoutProps
) => {
	const { translations } = useTranslationContext();
	
	let divisionName = "KeepRite";

	const brand = StorageFacade.brand?.toLowerCase();
	if (brand === "bally") {
		divisionName = "Bally";
	}
	else if (brand === "keeprite") {
		divisionName = "KeepRite";
	}
	else if (brand === "trenton") {
		divisionName = "Trenton";
	}

	const measurementUnit = StorageFacade.UsesMetricSystem ? translations.metric_SI_ : translations.imperial;

	return (
		<div className="main-div">
			<section className={`hero sh-${divisionName} hidden-for-print`}>
				<div className="hero-body">
					<div className="container">
						<h1 className="title">
							{document.title ?? "Load Calculator"}
						</h1>
					</div>
				</div>
			</section>
			<div className="main-container">
				<Container>{props.children}</Container>
			</div>
			<footer className="footer">
				<div className="level content has-text-centered container-fluid">
					<div className="level-left">
						<div className="level-item">
							Currently using:
							&nbsp;{measurementUnit}
						</div>
					</div>
					<div className="level-right">
						<div className="level-item">
							© {(new Date().getFullYear())}&nbsp;
							<span id="division-name">{divisionName}</span>
							&nbsp;Refrigeration
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default Layout;
