import * as React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import IMessage from "../../models/interfaces/IMessage";

import useTranslationContext from "../../hooks/useTranslationContext";

interface WarningBoxProps {
    warnings: IMessage[] | undefined;
}

const WarningBox: React.FunctionComponent<WarningBoxProps> = (
    props: WarningBoxProps
) => {

    const { translations } = useTranslationContext();

    if (!props.warnings || props.warnings.length === 0) {
        return null;
    }

    return (
        <div className="box content warning">
            <span className="has-text-warning">
                <FontAwesomeIcon icon={faExclamationTriangle} />
                &nbsp;
            </span>
            <span>
                {translations.wARNING}
                :
            </span>
            <ul>
                {props.warnings.map(
                    (item: IMessage, index: number) => {
                        const listKey = index;
                        return (
                            <li className="is-size-7" key={listKey}>
                                <span>{item.name}</span>
                                &nbsp;-&nbsp;
                                <span>{item.text}</span>
                            </li>
                        );
                    }
                )}
            </ul>
        </div>
    );
};

export default React.memo(WarningBox);