import * as React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import UnitOfMeasure from "../../models/helpers/UnitOfMeasure";
import FormField from "../helpers/FormField";
import NumericTextBox from "../helpers/NumericTextBox";

import IEquipmentState from "../../models/interfaces/IEquipmentState";

import useTranslationContext from "../../hooks/useTranslationContext";

interface EquipmentBalanceProps {
    equipmentSelection: IEquipmentState;
}

const EquipmentBalance: React.FunctionComponent<EquipmentBalanceProps> = (
    props: EquipmentBalanceProps
) => {

    const { translations } = useTranslationContext();

    return (
        <div id="equipment-system">
            <div className="columns balance">
                <div className="column is-one-fifth">
                    <div className="field is-horizontal">
                        <div className="field-label is-small">
                            <label className="label has-text-left">
                                {translations.sYSTEMBALANCE}
                                {props.equipmentSelection.systemBalanced ? null : (
                                    <span className="has-text-warning">
                                        &nbsp;
                                        <FontAwesomeIcon icon={faExclamationTriangle} />
                                    </span>
                                )}
                            </label>
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="field-body">
                        <FormField label={`${translations.sST} (${UnitOfMeasure.getTemperatureUnitLabel()})`}>
                            <NumericTextBox 
                                name="sstBalance"
                                value={props.equipmentSelection.sstBalance && props.equipmentSelection.sstBalance.toString() !== "" ? UnitOfMeasure.getTemperatureDisplayValue(props.equipmentSelection.sstBalance, 1, false) : ""}
                                readOnly
                                className="width120"
                            />
                        </FormField>
                        <FormField label={`TD (${UnitOfMeasure.getTemperatureUnitLabel()})`}>
                            <NumericTextBox 
                                name="tdBalance"
                                value={props.equipmentSelection.tdBalance && props.equipmentSelection.tdBalance.toString() !== "" ? UnitOfMeasure.getTemperatureRelativeDisplayValue(props.equipmentSelection.tdBalance, 1, false) : ""}
                                readOnly
                                className="width120"
                            />
                        </FormField>
                        <FormField label={`${translations.totalSystemCapacity} (${UnitOfMeasure.getRatingUnitLabel()})`}>
                            <NumericTextBox 
                                name="totalSystemCapacity"
                                value={props.equipmentSelection.totalSystemCapacity && props.equipmentSelection.totalSystemCapacity.toString() !== "" ? UnitOfMeasure.getRatingDisplayValue(props.equipmentSelection.totalSystemCapacity, 0, false) : ""}
                                readOnly
                                className="width120"
                            />
                        </FormField>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EquipmentBalance;