/* eslint-disable no-underscore-dangle */
import * as React from "react";

import IEquipmentOption from "../models/interfaces/IEquipmentOption";
import IEquipmentState from "../models/interfaces/IEquipmentState";
import IEquipmentUnitItem from "../models/interfaces/IEquipmentUnitItem";
import IMessage from "../models/interfaces/IMessage";
import IOpening from "../models/interfaces/IOpening";
import ISection from "../models/interfaces/ISection";
import ISystem from "../models/interfaces/ISystem";
import ISystemState from "../models/interfaces/ISystemState";

import BoxType from "../enums/BoxType";
import BoxLocation from "../enums/BoxLocation";
import FloorType from "../enums/FloorType";
import SectionType from "../enums/SectionType";

import { measure, hasValue, getNumericValue } from "../types/measure";
import { getCustomValue } from "../models/helpers/FormFields";

import UnitOfMeasure from "../models/helpers/UnitOfMeasure";
import StorageFacade from "../services/StorageFacade";

import useApplicationConfigs from "./useApplicationConfigs";
import useApplicationContext from "./useApplicationContext";
import useBoxContext from "./useBoxContext";
import useBoxMaterialsContext from "./useBoxMaterialsContext";
import useBoxSectionForm from "./useBoxSectionForm";
import useTranslationContext from "./useTranslationContext";

import * as LoadCalculations from "../models/helpers/LoadCalculations";

const useSystemState = () => {

    const { getBoxLocationConfig } = useApplicationConfigs();
    const { getPolygonSections, getDefaultSection, recalculateSection } = useBoxSectionForm();

    const { appData } = useApplicationContext();
    const { boxData } = useBoxContext();
    const { boxMaterials } = useBoxMaterialsContext();
    const { translations } = useTranslationContext();

    const emptyEquipmentSelection: IEquipmentState = {
        uid: "", // can be removed later
        isEditing: false, // redundant, can be removed later
        isCUAmbientTempVisible: false, // unnecessary, can be removed later
        isCondensingTempVisible: false, // unnecessary, can be removed later
        equipmentTypes: [], // unnecessary, can be removed later
        refrigerants: [], // unnecessary, can be removed later
        siteLineFrequencies: [], // unnecessary, can be removed later
        defaultValues: null, // unnecessary, can be removed later
        interiorTemperature: null, // redundant, can be removed later
        safetyFactor: null, // redundant, can be removed later
        systemRunTime: null, // redundant, can be removed later
        totalHourlyLoad: null, // redundant, can be removed later
        hourlyLoadRequired: null, // redundant, can be removed later
        capacityRequired: null, // redundant, can be removed later
        modalState: false, // unnecessary, can be removed later
        divisionId: 0, // redundant, can be removed later
        boxApplicationId: null, // redundant, can be removed later
        boxApplicationName: "", // redundant, can be removed later
        equipmentOnly: false,
        equipmentType: null,
        refrigerant: null,
        siteLineFrequency: null,
        condensingUnitAmbientTemperature: null,
        condensingTemperature: null,
        designTd: null,
        suctionLineLoss: null,
        tdPlus: null,
        tdMinus: null,
        condenserQuantity: 1,
        evaporatorQuantity: 1,
        condenserUnitCount: 1,
        evaporatorUnitCount: 1,        
        isCondenserPrimary: null,
        isPrimaryReloaded: false,
        isMatchedReloaded: false,
        condenserModelIdFilter: null,
        condenserClass9IdFilter: null,
        evaporatorModelIdFilter: null,
        evaporatorClass9IdFilter: null,
        selectedCondenserClass9Id: null, // unnecessary, can be removed later
        selectedCondenserCapacityPerUnit: null, // unnecessary, can be removed later
        selectedCondenserOptionList: [],
        selectedCondenserUnit: null,
        selectedCondenserAttributes: null,
        selectedEvaporatorClass9Id: null, // unnecessary, can be removed later
        selectedEvaporatorCapacityPerUnit: null, // unnecessary, can be removed later
        selectedEvaporatorOptionList: [],
        selectedEvaporator: null,
        selectedEvaporatorAttributes: null,
        systemBalanced: false,
        sstBalance: undefined,
        tdBalance: undefined,
        totalSystemCapacity: undefined
    };

    const emptySystem: ISystem = {
        isEditing: false,
        requireAwef: false,
        systemName: null,
        systemDescription: null,
        boxApplicationId: null,
        boxApplicationName: null,
        interiorTemperature: null,
        exteriorTemperature: null,
        interiorHumidity: null,
        exteriorHumidity: null,
        boxLength: null,
        boxLength2: null,
        boxLength3: null,
        boxDepth: null,
        boxDepth2: null,
        boxDepth3: null,
        boxHeight: null,
        floorType: null,
        boxLocation: null,
        boxType: null,
        safetyFactor: null,
        runTimeHours: null,
        totalHourlyLoad: null,
        totalLoadRequired: null,
        capacityRequired: null,
        totalWallLoad: null,
        totalProductLoad: null,
        totalInfiltrationLoad: null,
        totalMiscLoad: null,
        wallLoad: {
            boxSections: null,
            floorArea: null,
            volume: null
        },
        productLoad: null,
        infiltrationLoad: null,
        miscLoad: null,
        boxDesign: {
            floorArea: null,
            volume: null,
            totalInfiltrationLoad: null
        },
        equipmentSelection: emptyEquipmentSelection
    };

    const emptySystemState: ISystemState = {
        store: StorageFacade.loadCalcSystem ?? emptySystem,
        formState: {
            defaultValues: emptySystem,
            actualValues: emptySystem
        }
    };

    const validateBox = (
        system: ISystem
    ): IMessage[] => {

        const errors: IMessage[] = [];

        if (system.boxType === BoxType.Rectangular) {            
            if (!hasValue(system.boxHeight)) {
                errors.push({
                    name: "boxHeight",
                    text: translations.heightIsARequiredValue_
                });
            }

            if (!hasValue(system.boxLength)) {
                errors.push({
                    name: "boxLength",
                    text: translations.length_IsARequiredValue_.replace("1", "")
                });
            }

            if (!hasValue(system.boxDepth)) {
                errors.push({
                    name: "boxDepth",
                    text: translations.depth_IsARequiredValue_.replace("1", "")
                });
            }
        }

        if (system.boxType === BoxType.LShape
            || system.boxType === BoxType.UShape
        ) {            
            if (!hasValue(system.boxHeight)) {
                errors.push({
                    name: "boxHeight",
                    text: translations.heightIsARequiredValue_
                });
            }

            if (!hasValue(system.boxLength)) {
                errors.push({
                    name: "boxLength",
                    text: translations.length_IsARequiredValue_.replace(`${translations.length} 1`, translations.rear)
                });
            }

            if (!hasValue(system.boxDepth)) {
                errors.push({
                    name: "boxDepth",
                    text: translations.depth_IsARequiredValue_.replace(`${translations.depth} 1`, translations.rightEnd)
                });
            }
        }

        if (!hasValue(system.interiorTemperature)) {
            errors.push({
                name: "interiorTemperature",
                text: translations.theInternalTemperatureIsNotEntered_
            });
        }
        else if (getNumericValue(system.interiorTemperature) < (boxData?.boxTempMin ?? -100)) {
            errors.push({
                name: "interiorTemperature",
                text: translations.theInternalTemperatureIsTooLowForTheSelectedApplication_
            });
        }
        else if (getNumericValue(system.interiorTemperature) > (boxData?.boxTempMax ?? 150)) {
            errors.push({
                name: "interiorTemperature",
                text: translations.theInternalTemperatureIsTooHighForTheSelectedApplication_
            });
        }
        else if (hasValue(system.exteriorTemperature)) {
            if (getNumericValue(system.interiorTemperature) > getNumericValue(system.exteriorTemperature)) { // warning
                errors.push({
                    name: "interiorTemperature", 
                    text: translations.theExternalTemperatureIsLowerThanTheInternalTemperature_
                });
            }
        }
        
        if (!hasValue(system.exteriorTemperature)) {
            errors.push({
                name: "exteriorTemperature",
                text: translations.theExternalTemperatureIsNotEntered_
            });
        }
        else if (getNumericValue(system.exteriorTemperature) < -100) {
            errors.push({
                name: "exteriorTemperature",
                text: translations.theExternalTemperatureIsTooLow_
            });
        }
        else if (getNumericValue(system.exteriorTemperature) > 150) {
            errors.push({
                name: "exteriorTemperature",
                text: translations.theExternalTemperatureIsTooHigh_
            });
        }
        else if (hasValue(system.interiorTemperature)) {
            if (getNumericValue(system.exteriorTemperature) < getNumericValue(system.interiorTemperature)) { // warning
                errors.push({
                    name: "exteriorTemperature", 
                    text: translations.theExternalTemperatureIsLowerThanTheInternalTemperature_
                });
            }
        }

        if (!hasValue(system.exteriorHumidity)) {
            errors.push({
                name: "exteriorHumidity", 
                text: translations.theExternalRelativeHumidityNotEntered_
            });
        }
        else if (getNumericValue(system.exteriorHumidity) < (appData.BOX?.ExternalRH?.Min.value ?? 0)) {
            errors.push({
                name: "exteriorHumidity", 
                text: `${translations.theExternalRelativeHumidityMustBeGreaterThan} ${appData.BOX?.ExternalRH?.Min.value ?? 0} !`
            });
        }
        else if (getNumericValue(system.exteriorHumidity) > (appData.BOX?.ExternalRH?.Max.value ?? 100)) {
            errors.push({
                name: "exteriorHumidity", 
                text: `${translations.theExternalRelativeHumidityMustBeLessThan___} ${appData.BOX?.ExternalRH?.Max.value ?? 0} !`
            });
        }

        if (!hasValue(system.interiorHumidity)) {
            errors.push({
                name: "interiorHumidity", 
                text: translations.theInternalRelativeHumidityNotEntered_
            });
        }
        else if (getNumericValue(system.interiorHumidity) < (boxData?.boxRHMin ?? 50)) {
            errors.push({
                name: "interiorHumidity", 
                text: `${translations.theInternalRelativeHumidityMustBeGreaterThan} ${boxData?.boxRHMin ?? 50} !`
            });
        }
        else if (getNumericValue(system.interiorHumidity) > (boxData?.boxRHMax ?? 90)) {
            errors.push({
                name: "interiorHumidity", 
                text: `${translations.theInternalRelativeHumidityMustBeLessThan} ${boxData?.boxRHMax ?? 90} !`
            });
        }

        if (!hasValue(system.boxType)
            || system.boxType === BoxType.Undefined
        ) {
            errors.push({
                name: "boxType", 
                text: translations.theBoxTypeMustBeSelected_
            });
        }

        let hasSectionErrors = false;
        let openingsCount = 0;

        system.wallLoad.boxSections?.forEach(s => {
            hasSectionErrors = hasSectionErrors || s.errors !== undefined && s.errors.length > 0;
            openingsCount += s.openings?.length ?? 0;
        });

        if (openingsCount > 0
            && !hasValue(system.boxDesign.totalInfiltrationLoad)
        ) {
            errors.push({
                name: "totalInfiltrationLoad", 
                text: translations.theTotalInfiltrationLoadIsNotCalculatedOrEntered_
            });
        }

        if (hasSectionErrors) {
            errors.push({
                name: "totalWallLoad", 
                text: translations.atLeastOneSectionHasErrors_
            });
        }
        
        return errors;
    };

    const validateWallLoad = (
        system: ISystem
    ): IMessage[] => {
        const errors: IMessage[] = [];

        if (system.boxType === BoxType.LShape
            || system.boxType === BoxType.UShape
        ) {
            if (!hasValue(system.boxLength2)) {
                errors.push({
                    name: "boxLength2",
                    text: translations.length_IsARequiredValue_.replace(`${translations.length} 1`, translations.front)
                });
            }

            if (!hasValue(system.boxDepth2)) {
                errors.push({
                    name: "boxDepth2",
                    text: translations.depth_IsARequiredValue_.replace(`${translations.depth} 1`, translations.leftCut)
                });
            }
        }

        if (system.boxType === BoxType.UShape) {
            if (!hasValue(system.boxLength3)) {
                errors.push({
                    name: "boxLength3",
                    text: translations.length_IsARequiredValue_.replace(`${translations.length} 1`, translations.frontInset)
                });
            }

            if (!hasValue(system.boxDepth3)) {
                errors.push({
                    name: "boxDepth3",
                    text: translations.depth_IsARequiredValue_.replace(`${translations.depth} 1`, translations.leftInset)
                });
            }
        }

        if (!hasValue(system.wallLoad.floorArea)) {
            errors.push({
                name: "floorArea", 
                text: translations.theFloorAreaIsNotCalculatedOrEntered_
            });
        }

        if (!hasValue(system.wallLoad.volume)) {
            errors.push({
                name: "volume", 
                text: translations.theTotalVolumeIsNotCalculatedOrEntered_
            });
        }

        if (!hasValue(system.totalWallLoad)) {
            errors.push({
                name: "totalWallLoad", 
                text: translations.theLoadIsNotCalculatedOrEntered_
            });
        }

        const boxErrors = validateBox(system);

        return [
            ...errors,
            ...boxErrors
        ];
    }

    const validateEquipment = (
        system: ISystem
    ): IMessage[] => {
        const errors: IMessage[] = [];

        if (system.equipmentSelection.selectedCondenserUnit
            && system.equipmentSelection.selectedEvaporator) {
            if (!system.equipmentSelection.systemBalanced) {
                errors.push({
                    name: "systemBalanced",
                    text: translations.systemIsNotBalanced_
                });
            }
            else if (getNumericValue(system.equipmentSelection.tdBalance) < (boxData?.evapTdMin ?? 0)) {
                errors.push({
                    name: "tdBalance",
                    text: translations.evapTDIsTooLowForTheSelectedApplication_
                });
            }
            else if (getNumericValue(system.equipmentSelection.tdBalance) > (boxData?.evapTdMax ?? 1000)) {
                errors.push({
                    name: "tdBalance",
                    text: translations.evapTDIsTooHighForTheSelectedApplication_
                });
            }
        }

        return errors;
    }

    const validateEquipmentFilters = (
        system: ISystem
    ): IMessage[] => {

        const errors: IMessage[] = [];

        if (hasValue(system.runTimeHours)) {
            if (getNumericValue(system.runTimeHours) < 1) {
                errors.push({
                    name: "runTimeHours", 
                    text: translations.systemRunTimeMustBeGreaterThanOneHour_
                });
            }
            else if (getNumericValue(system.runTimeHours) > 24) {
                errors.push({
                    name: "runTimeHours", 
                    text: translations.systemRunTimeMustBeLessThanTwentyfourHours_
                });
            }
        }
        
        if (!hasValue(system.equipmentSelection.suctionLineLoss)) {
            errors.push({
                name: "suctionLineLoss",
                text: translations.theSystemLineLossIsNotEntered_
            });
        }
        else if (getNumericValue(system.equipmentSelection.suctionLineLoss) < 0) {
            errors.push({
                name: "suctionLineLoss",
                text: translations.theSystemLineLossIsTooLow_
            });
        }
        else if (getNumericValue(system.equipmentSelection.suctionLineLoss) > 5) {
            errors.push({
                name: "suctionLineLoss",
                text: translations.theSystemLineLossIsTooHigh_
            });
        }

        return errors;
    };

    const validateEquipmentSelector = (
        system: ISystem
    ): IMessage[] => {
        
        const equipmentFiltersErrors = validateEquipmentFilters(system);
        const equipmentErrors = validateEquipment(system);

        return [
            ...equipmentFiltersErrors,
            ...equipmentErrors
        ];
    };

    const validateLoadCalc = (
        system: ISystem
    ): IMessage[] => {
        const errors: IMessage[] = [];

        if (hasValue(system.runTimeHours)) {
            if (getNumericValue(system.runTimeHours) < 1) {
                errors.push({
                    name: "runTimeHours", 
                    text: translations.systemRunTimeMustBeGreaterThanOneHour_
                });
            }
            else if (getNumericValue(system.runTimeHours) > 24) {
                errors.push({
                    name: "runTimeHours", 
                    text: translations.systemRunTimeMustBeLessThanTwentyfourHours_
                });
            }
        }

        if (system.boxType !== BoxType.Undefined) {
            if (!hasValue(system.totalProductLoad)) {
                errors.push({
                    name: "totalProductLoad", 
                    text: translations.noTotalProductLoadHasBeenCalculatedOrEntered_
                });
            }
        }

        const equipmentErrors = validateEquipment(system);

        const boxErrors = validateBox(system);

        return [
            ...boxErrors,
            ...errors,
            ...equipmentErrors
        ];
    }

    const getBoxDefaultSystem = (
        boxApplicationData: any
    ) => {

        if (appData && boxApplicationData) {
            const systemName = boxApplicationData.name;
            const systemDescription = boxApplicationData.description;

            const boxLocationId = BoxLocation.Indoors;
            const boxLocation = getBoxLocationConfig(boxLocationId);

            const boxType = BoxType.Undefined;

            const floorType = boxApplicationData.isBoxFreezer && boxApplicationData.frostHeave === "Y"
                ? FloorType.OnGradeHeated
                : FloorType.OnGrade;

            const interiorTemperature = boxApplicationData.boxTemp;
            
            const exteriorTemperature = boxLocation.defaultExternalTemperature;
            const exteriorHumidity = boxLocation.defaultExternalHumidity;

            // when RH_TD >= 0, it represents RH, otherwise it represents TD
            let interiorHumidity = boxApplicationData.rH_TD;
            let designTd = UnitOfMeasure.TDatRH(boxApplicationData.rH_TD);

            if (boxApplicationData.rH_TD < 0) {
                interiorHumidity = appData.BOX?.FreezerInternalRH_Default?.value;
                designTd = 0 - boxApplicationData.rH_TD;
            }

            const safetyFactor = appData.SYSTEM?.SafetyFactor?.Default?.value;

            const condensingUnitAmbientTemperature = appData.EQUIPMENT?.CondensorAmbient?.value;
            const condensingTemperature = appData.EQUIPMENT?.CondensingTemperature?.value;
            const refrigerant = appData.EQUIPMENT?.RefrigerantID_Default?.value;
            const siteLineFrequency = appData.EQUIPMENT?.DefaultLineFrequency?.value;
            const suctionLineLoss = appData.EQUIPMENT?.LineLoss?.Default?.value;
            const tdPlus = appData.EQUIPMENT?.TDE?.Over?.value;
            const tdMinus = appData.EQUIPMENT?.TDE?.Under?.value;
            
            const boxDefaultSystem: ISystem = {
                ...emptySystem,
                isEditing: false,
                systemName,
                systemDescription,
                boxApplicationId: boxApplicationData.id,
                boxApplicationName: systemName,
                floorType,
                boxLocation: boxLocationId,
                boxType,
                interiorTemperature,
                exteriorTemperature,
                interiorHumidity,
                exteriorHumidity,
                safetyFactor,
                equipmentSelection: {
                    ...emptySystemState.formState.defaultValues.equipmentSelection,
                    equipmentType: "Condensing Units-Air",
                    refrigerant,
                    siteLineFrequency,
                    condensingUnitAmbientTemperature,
                    condensingTemperature,
                    designTd,
                    suctionLineLoss,
                    tdPlus,
                    tdMinus,
                    divisionId: +StorageFacade.divisionId,
                    boxApplicationId: boxApplicationData.id,
                    boxApplicationName: systemName,
                    interiorTemperature,
                    safetyFactor
                }
            };

            return boxDefaultSystem;
        }
        
        return emptySystem;
    };

    const getDefaultStoreSections = (
        boxType: BoxType | null,
        systemActualValues: ISystem
    ): ISection[] | null => {
        const storeSections = getPolygonSections(
            boxType,
            systemActualValues.floorType,
            systemActualValues.boxLocation,
            systemActualValues.boxLength,
            systemActualValues.boxLength2,
            systemActualValues.boxLength3,
            systemActualValues.boxDepth,
            systemActualValues.boxDepth2,
            systemActualValues.boxDepth3,
            systemActualValues.boxHeight,
            systemActualValues.exteriorTemperature,
            systemActualValues.interiorTemperature,
            systemActualValues.exteriorHumidity);

        if (!storeSections || storeSections.length === 0) {
            return null;
        }

        return storeSections.map(s => {
            return {
                sectionType: s.sectionType,
                wallType: s.wallType,
                radians: s.radians,
                sectionName: s.sectionName,
                isModified: false,
                isCustom: false,
                materialId: null,
                materialName: null,
                thickness: null,
                kFactor: null,
                rValuePerInch: null,
                rValueEffective: null,
                exteriorTemperature: null,
                exteriorHumidity: null,
                dimension1: null,
                dimension2: null,
                area: null,
                areaWithoutOpenings: null,
                conductionLoad: null,
                conductionLoadWithoutOpenings: null,
                sectionLocation: null,
                floorType: null
            };
        });
    };

    const getBoxSections = (
        storeSections: ISection[] | null | undefined,
        boxTypeId: BoxType,
        boxFloorType: FloorType,
        boxLocation: BoxLocation,
        boxLength: measure,
        boxLength2: measure,
        boxLength3: measure,
        boxDepth: measure,
        boxDepth2: measure,
        boxDepth3: measure,
        boxHeight: measure,
        boxExteriorTemperature: measure,
        boxInteriorTemperature: measure,
        boxExteriorHumidity: measure,
        boxInteriorHumidity: measure
    ) => {
        const boxSections = [] as ISection[];
        
        // For each box section in the local storage, recalculate the fields based on custom values from local storage and box default values.     

        storeSections?.forEach(storeSection => {

            const section = {...storeSection};

            const defaultSection = getDefaultSection(
                boxTypeId,
                section.sectionType,
                section.wallType,
                section.radians,
                section.isCustom,
                boxLocation,
                boxFloorType,
                boxLength,
                boxLength2,
                boxLength3,
                boxDepth,
                boxDepth2,
                boxDepth3,
                boxHeight,                    
                boxExteriorTemperature,
                boxExteriorHumidity,
                boxInteriorTemperature);

            const sectionFormState = recalculateSection(
                {
                    defaultValues: defaultSection,
                    actualValues: section
                },
                null,
                boxLocation,
                boxFloorType,
                boxHeight,
                boxDepth,
                boxDepth2,
                boxDepth3,
                boxLength,
                boxLength2,
                boxLength3,
                boxTypeId,
                boxExteriorTemperature,
                boxExteriorHumidity,
                boxInteriorTemperature,
                boxInteriorHumidity);

            const recalculatedSection = sectionFormState.actualValues;

            boxSections.push(recalculatedSection);
        });

        return boxSections;
    };

    const getConstructions = () => {
        const firstMaterialId = boxMaterials.filter(m => !m.isConstruct).map(m => m.id).sort((a, b) => a - b)[0];
        const lastMaterialId = boxMaterials.filter(m => !m.isConstruct).map(m => m.id).sort((a, b) => b - a)[0];
        const constructions = boxMaterials.filter(m => m.isConstruct && (m.id < firstMaterialId || m.id > lastMaterialId));
        
        return constructions;
    };
    
    const recalculateSystem = (
        state: ISystemState,
        boxApplicationData?: any
    ): ISystemState => {

        // Get default values based on box application id.
        // These are the box-specific constants used as default values in a system.
        // By default, it gets box data from BoxContext.
        // To use another box, pass the boxApplicationData parameter.
        const boxDefaultSystem = getBoxDefaultSystem(boxApplicationData ?? boxData);
    
        // Build system state based on customs and defaults.
        // This system state will be stored in a system context. Think of this as a cached global state, which can be accessed by any component.
        // ISystemState has store (contains the custom values which will be saved in the local storage) and formState (contains default values and actual values to display in the UI).

        // The following system properties do not change.
        const {boxApplicationId} = boxDefaultSystem;
        const boxApplicationName = boxDefaultSystem.systemName;

        // The following properties are either customs or empty strings:
        const systemName = state.store.systemName ?? boxDefaultSystem.systemName;
        const systemDescription = state.store.systemDescription ?? boxDefaultSystem.systemDescription;
        
        // The following properties are either customs or box defaults.
        const floorType = state.store.floorType ?? (boxDefaultSystem.floorType ?? FloorType.None);
        const boxType = state.store.boxType ?? (boxDefaultSystem.boxType ?? BoxType.Undefined);
        const interiorTemperature = state.store.interiorTemperature ?? boxDefaultSystem.interiorTemperature;
        const interiorHumidity = state.store.interiorHumidity ?? boxDefaultSystem.interiorHumidity;
        const boxLength = state.store.boxLength ?? boxDefaultSystem.boxLength;
        const boxLength2 = state.store.boxLength2 ?? boxDefaultSystem.boxLength2;
        const boxLength3 = state.store.boxLength3 ?? boxDefaultSystem.boxLength3;
        const boxDepth = state.store.boxDepth ?? boxDefaultSystem.boxDepth;
        const boxDepth2 = state.store.boxDepth2 ?? boxDefaultSystem.boxDepth2;
        const boxDepth3 = state.store.boxDepth3 ?? boxDefaultSystem.boxDepth3;
        const boxHeight = state.store.boxHeight ?? boxDefaultSystem.boxHeight;
        const safetyFactor = state.store.safetyFactor ?? boxDefaultSystem.safetyFactor;
        
        const boxLocationId = state.store.boxLocation ?? (boxDefaultSystem.boxLocation ?? BoxLocation.Indoors);
        const boxLocation = getBoxLocationConfig(boxLocationId); // Get box location configurations.

        // The rest are properties that are either customs or calculated.

        const calculatedRunTimeHours = LoadCalculations.calculateRunTimeHours(interiorTemperature, appData.SYSTEM?.RunTime);
        const runTimeHours = state.store.runTimeHours ?? calculatedRunTimeHours;

        const defaultExteriorTemperature = boxLocation.defaultExternalTemperature ?? boxDefaultSystem.exteriorTemperature;
        const defaultExteriorHumidity = boxLocation.defaultExternalHumidity ?? boxDefaultSystem.exteriorHumidity;

        const exteriorTemperature = state.store.exteriorTemperature ?? defaultExteriorTemperature;
        const exteriorHumidity = state.store.exteriorHumidity ?? defaultExteriorHumidity;

        const boxSections = getBoxSections(
            state.store.wallLoad.boxSections,
            boxType,
            floorType,
            boxLocationId,
            boxLength,
            boxLength2,
            boxLength3,
            boxDepth,
            boxDepth2,
            boxDepth3,
            boxHeight,
            exteriorTemperature,
            interiorTemperature,
            exteriorHumidity,
            interiorHumidity);

        const constructions = getConstructions();

        const calculatedTotalWallLoad = LoadCalculations.calculateTotalWallLoad(boxSections);
        const totalWallLoad = state.store.totalWallLoad ?? calculatedTotalWallLoad;
    
        const calculatedFloorArea = LoadCalculations.calculateFloorArea(boxSections);
        const floorArea = state.store.wallLoad.floorArea ?? calculatedFloorArea;
    
        const hasCustomSection = boxSections.some(a => a.sectionType === SectionType.FloorCustom || a.sectionType === SectionType.WallCustom || a.sectionType === SectionType.CeilingCustom);
        const calculatedVolume = LoadCalculations.calculateVolume(floorArea, boxHeight, hasCustomSection);
        const volume = state.store.wallLoad.volume ?? calculatedVolume;
    
        const openingsTotalInfiltrationLoad = LoadCalculations.calculateOpeningsTotalInfiltrationLoad(boxSections);
    
        const totalMiscLoad = state.store.totalMiscLoad ?? state.formState.defaultValues.totalMiscLoad;
        const totalInfiltrationLoad = state.store.totalInfiltrationLoad ?? state.formState.defaultValues.totalInfiltrationLoad;
        const totalProductLoad = state.store.totalProductLoad ?? state.formState.defaultValues.totalProductLoad;

        const calculatedTotalHourlyLoad = LoadCalculations.calculateTotalHourlyLoad(
            totalWallLoad,
            totalInfiltrationLoad,
            totalProductLoad,
            totalMiscLoad);
        const totalHourlyLoad = state.store.totalHourlyLoad ?? calculatedTotalHourlyLoad;

        const calculatedTotalLoadRequired = LoadCalculations.calculateTotalLoadRequired(
            safetyFactor,
            totalHourlyLoad);
        const totalLoadRequired = state.store.totalLoadRequired ?? calculatedTotalLoadRequired;
        
        const calculatedCapacityRequired = LoadCalculations.calculateCapacityRequired(
            totalLoadRequired,
            runTimeHours);
        const defaultCapacityRequired = calculatedCapacityRequired ?? boxDefaultSystem.capacityRequired;
        const capacityRequired = state.store.capacityRequired ?? defaultCapacityRequired;

        // Equipment selection values
        const equipmentSelection: IEquipmentState = {
            ...state.store.equipmentSelection,
            condenserQuantity: state.store.equipmentSelection.condenserQuantity ?? boxDefaultSystem.equipmentSelection.condenserQuantity,
            condenserUnitCount: state.store.equipmentSelection.condenserUnitCount ?? boxDefaultSystem.equipmentSelection.condenserUnitCount,
            evaporatorQuantity: state.store.equipmentSelection.evaporatorQuantity ?? boxDefaultSystem.equipmentSelection.evaporatorQuantity,
            evaporatorUnitCount: state.store.equipmentSelection.evaporatorUnitCount ?? boxDefaultSystem.equipmentSelection.evaporatorUnitCount,
            equipmentType: state.store.equipmentSelection.equipmentType ?? boxDefaultSystem.equipmentSelection.equipmentType,
            refrigerant: state.store.equipmentSelection.refrigerant ?? boxDefaultSystem.equipmentSelection.refrigerant,
            siteLineFrequency: state.store.equipmentSelection.siteLineFrequency ?? boxDefaultSystem.equipmentSelection.siteLineFrequency,
            condensingUnitAmbientTemperature: state.store.equipmentSelection.condensingUnitAmbientTemperature ?? boxDefaultSystem.equipmentSelection.condensingUnitAmbientTemperature,
            condensingTemperature: state.store.equipmentSelection.condensingTemperature ?? boxDefaultSystem.equipmentSelection.condensingTemperature,
            designTd: state.store.equipmentSelection.designTd ?? boxDefaultSystem.equipmentSelection.designTd,
            suctionLineLoss: state.store.equipmentSelection.suctionLineLoss ?? boxDefaultSystem.equipmentSelection.suctionLineLoss,
            tdPlus: state.store.equipmentSelection.tdPlus ?? boxDefaultSystem.equipmentSelection.tdPlus,
            tdMinus: state.store.equipmentSelection.tdMinus ?? boxDefaultSystem.equipmentSelection.tdMinus,
            divisionId: boxDefaultSystem.equipmentSelection.divisionId,
            boxApplicationId,
            boxApplicationName: boxApplicationName ?? "",
            interiorTemperature,
            safetyFactor,
            systemRunTime: runTimeHours,
            totalHourlyLoad,
            hourlyLoadRequired: totalLoadRequired,
            capacityRequired
        };

        // In case the load calc file does not supply refrigerant/siteLineFrequency, set to default values.
        if (equipmentSelection.refrigerant === 0) {
            equipmentSelection.refrigerant = 4;
        }
        if (equipmentSelection.siteLineFrequency === 0) {
            equipmentSelection.siteLineFrequency = 60;
        }

        const actualValues: ISystem = {
            isEditing: state.store.isEditing,
            requireAwef: state.store.requireAwef,
            boxApplicationId,
            boxApplicationName,
            systemName,
            systemDescription,
            floorType,
            boxLocation: boxLocationId,
            boxType,
            interiorTemperature,
            exteriorTemperature,
            interiorHumidity,
            exteriorHumidity,
            boxLength,
            boxLength2,
            boxLength3,
            boxDepth,
            boxDepth2,
            boxDepth3,
            boxHeight,
            safetyFactor,
            runTimeHours,
            totalWallLoad,
            totalProductLoad,
            totalInfiltrationLoad,
            totalMiscLoad,
            totalHourlyLoad,
            totalLoadRequired,
            capacityRequired,
            wallLoad: {
                boxSections,
                floorArea,
                volume,
                constructions
            }, 
            productLoad: state.formState.actualValues.productLoad, // state.store.productLoad,
            infiltrationLoad: state.formState.actualValues.infiltrationLoad, // state.store.infiltrationLoad,
            miscLoad: state.formState.actualValues.miscLoad, // state.store.miscLoad,
            boxDesign: {
                floorArea,
                volume,
                totalInfiltrationLoad: openingsTotalInfiltrationLoad
            },
            equipmentSelection
        };
    
        const defaultValues: ISystem = {
            ...boxDefaultSystem,
            exteriorTemperature: defaultExteriorTemperature,
            exteriorHumidity: defaultExteriorHumidity,
            runTimeHours: calculatedRunTimeHours,
            totalWallLoad: calculatedTotalWallLoad,
            wallLoad: {
                boxSections: null,
                floorArea: calculatedFloorArea,
                volume: calculatedVolume
            },
            totalHourlyLoad: calculatedTotalHourlyLoad,
            totalLoadRequired: calculatedTotalLoadRequired,
            capacityRequired: defaultCapacityRequired,
            totalProductLoad: state.formState.defaultValues.totalProductLoad,
            totalInfiltrationLoad: state.formState.defaultValues.totalInfiltrationLoad,
            totalMiscLoad: state.formState.defaultValues.totalMiscLoad
        };

        const errors = validateLoadCalc(actualValues);

        return {
            formState: {
                defaultValues,
                actualValues: {
                    ...actualValues,
                    errors
                }
            },
            store: {
                ...state.store,
                wallLoad: {
                    ...state.store.wallLoad,
                    constructions
                },
                boxDesign: {
                    ...actualValues.boxDesign // This should always have values. Data here is needed to calculate product, infilitration and misc loads.
                },
                equipmentSelection: {
                    ...equipmentSelection,
                    designTd: actualValues.equipmentSelection.designTd === defaultValues.equipmentSelection.designTd ? null : actualValues.equipmentSelection.designTd,
                    suctionLineLoss: actualValues.equipmentSelection.suctionLineLoss === defaultValues.equipmentSelection.suctionLineLoss ? null : actualValues.equipmentSelection.suctionLineLoss,
                    condensingTemperature: actualValues.equipmentSelection.condensingTemperature === defaultValues.equipmentSelection.condensingTemperature ? null : actualValues.equipmentSelection.condensingTemperature,
                    condensingUnitAmbientTemperature: actualValues.equipmentSelection.condensingUnitAmbientTemperature === defaultValues.equipmentSelection.condensingUnitAmbientTemperature ? null : actualValues.equipmentSelection.condensingUnitAmbientTemperature
                }
            }
        };
    };

    const recalculateLoads = (
        state: ISystemState,
        calculatedTotalMiscLoad: measure, 
        calculatedTotalInfiltrationLoad: measure,
        calculatedTotalProductLoad: measure,
        miscLoad: any,
        infiltrationLoad: any,
        productLoad: any
    ): ISystemState => {

        const totalMiscLoad = state.store.totalMiscLoad ?? calculatedTotalMiscLoad;
        const totalInfiltrationLoad = state.store.totalInfiltrationLoad ?? calculatedTotalInfiltrationLoad;
        const totalProductLoad = state.store.totalProductLoad ?? calculatedTotalProductLoad;

        const calculatedTotalHourlyLoad = LoadCalculations.calculateTotalHourlyLoad(
            state.formState.actualValues.totalWallLoad,
            totalInfiltrationLoad,
            totalProductLoad,
            totalMiscLoad);
        const totalHourlyLoad = state.store.totalHourlyLoad ?? calculatedTotalHourlyLoad;

        const calculatedTotalLoadRequired = LoadCalculations.calculateTotalLoadRequired(
            state.formState.actualValues.safetyFactor,
            totalHourlyLoad);
        const totalLoadRequired = state.store.totalLoadRequired ?? calculatedTotalLoadRequired;
        
        const calculatedCapacityRequired = LoadCalculations.calculateCapacityRequired(
            totalLoadRequired,
            state.formState.actualValues.runTimeHours);
        const defaultCapacityRequired = calculatedCapacityRequired ?? state.formState.defaultValues.capacityRequired;
        const capacityRequired = state.store.capacityRequired ?? defaultCapacityRequired;

        const actualValues: ISystem = {
            ...state.formState.actualValues,
            totalProductLoad,
            totalInfiltrationLoad,
            totalMiscLoad,
            miscLoad,
            infiltrationLoad,
            productLoad,
            totalHourlyLoad,
            totalLoadRequired,
            capacityRequired
        };

        const defaultValues: ISystem = {
            ...state.formState.defaultValues,
            totalProductLoad: calculatedTotalProductLoad,
            totalInfiltrationLoad: calculatedTotalInfiltrationLoad,
            totalMiscLoad: calculatedTotalMiscLoad,
            totalHourlyLoad: calculatedTotalHourlyLoad,
            totalLoadRequired: calculatedTotalLoadRequired,
            capacityRequired: defaultCapacityRequired
        };

        const errors = validateLoadCalc(actualValues);

        return {
            formState: {
                defaultValues,
                actualValues: {
                    ...actualValues,
                    errors
                }
            },
            store: {
                ...state.store,
                equipmentSelection: {
                    ...state.store.equipmentSelection,
                    totalHourlyLoad,
                    hourlyLoadRequired: totalLoadRequired,
                    capacityRequired
                }
            }
        };
    };

    const setInitialState = (): ISystemState => {
        return recalculateSystem({
            ...emptySystemState,
            store: StorageFacade.loadCalcSystem ?? emptySystem
        });
    };

    const resetState = (
        state: ISystemState,
        boxApplicationId: number,
        isEquipmentOnly: boolean,
        requireAwef: boolean
    ): ISystemState => {

        const systemStore: ISystem = {
            ...emptySystem,
            boxApplicationId,
            requireAwef,
            equipmentSelection: {
                ...emptySystem.equipmentSelection,
                equipmentOnly: isEquipmentOnly
            }
        };

        return recalculateSystem({
            ...state,
            store: systemStore
        });
    };

    const changeValue = (
        state: ISystemState,
        isEquipmentField: boolean,
        fieldName: string,
        fieldValue: any
    ): ISystemState => {

        let systemStore: ISystem = {...state.store};

        if (isEquipmentField) {
            let {condenserQuantity, evaporatorQuantity} = systemStore.equipmentSelection;
            if (fieldName === "condenserQuantity") {
                condenserQuantity = fieldValue ?? 0;
            } else if (fieldName === "evaporatorQuantity") {
                evaporatorQuantity = fieldValue ?? 0;
            }
            
            systemStore = {
                ...systemStore,
                equipmentSelection: {
                    ...systemStore.equipmentSelection,
                    [fieldName]: fieldValue,
                    condenserUnitCount: condenserQuantity,
                    evaporatorUnitCount: condenserQuantity * evaporatorQuantity
                }
            };
        }
        else {
            systemStore = {
                ...systemStore,
                [fieldName]: fieldValue
            };
        }

        return recalculateSystem({
            ...state,
            store: systemStore
        });
    };

    const customizeMeasureProperty = (
        state: ISystemState,
        isEquipmentField: boolean,
        fieldName: string,
        fieldValue: measure
    ): ISystemState => {

        let systemStore: ISystem = {...state.store};

        if (isEquipmentField) {
            systemStore = {
                ...systemStore,
                equipmentSelection: {
                    ...systemStore.equipmentSelection,
                    [fieldName]: fieldValue // equipmentSelection should always have actual value
                }
            };
        }
        else {
            const boxType = !systemStore.boxType
                    && (fieldName === "boxLength"
                        || fieldName === "boxHeight"
                        || fieldName === "boxDepth"
                    )
                ? BoxType.Rectangular
                : systemStore.boxType;

            const customValue = getCustomValue(state.formState, fieldName, fieldValue);

            systemStore = {
                ...systemStore,
                [fieldName]: customValue, // save as null if not custom
                boxType,
                wallLoad: {
                    ...systemStore.wallLoad,
                    boxSections: systemStore.wallLoad.boxSections ?? getDefaultStoreSections(boxType, state.formState.actualValues)
                }
            };

            switch(fieldName){
                case "totalProductLoad":
                    if (systemStore.productLoad) systemStore.productLoad.TotalProductLoad = customValue;                    
                    break;
                case "totalMiscLoad":
                    if (systemStore.miscLoad) systemStore.miscLoad.TotalMiscLoad = customValue;                    
                    break;
                case "totalInfiltrationLoad":
                    if (systemStore.infiltrationLoad) systemStore.infiltrationLoad.TotalInfiltrationLoad = customValue;                    
                    break;
                default:
                    break;
            }
        }

        return recalculateSystem({
            ...state,
            store: systemStore
        });
    };

    const customizeWallLoadMeasureProperty = (
        state: ISystemState,
        fieldName: string,
        fieldValue: measure
    ): ISystemState => {

        let systemStore: ISystem = {...state.store};

        systemStore = {
            ...systemStore,
            wallLoad: {
                ...systemStore.wallLoad,
                floorArea: (fieldName === "floorArea")
                    ? getCustomValue(state.formState, "wallLoad.floorArea", fieldValue)
                    : systemStore.wallLoad.floorArea,
                volume: (fieldName === "volume")
                    ? getCustomValue(state.formState, "wallLoad.volume", fieldValue)
                    : systemStore.wallLoad.volume,
                boxSections: systemStore.wallLoad.boxSections ?? getDefaultStoreSections(state.formState.actualValues.boxType, state.formState.actualValues)
            }
        };

        return recalculateSystem({
            ...state,
            store: systemStore
        });
    };

    const resetBoxType = (
        state: ISystemState,
        boxType: BoxType
    ): ISystemState => {

        const systemStore: ISystem = {
            ...state.store,
            boxType,
            wallLoad: {
                ...state.store.wallLoad,
                boxSections: getDefaultStoreSections(boxType, state.formState.actualValues)
            }
        };

        return recalculateSystem({
            ...state,
            store: systemStore
        });
    };

    const clearEquipmentSelections = (
        state: ISystemState
    ): ISystemState => {

        const clearedEquipmentSelection = {
            isCondenserPrimary: null,
            condenserModelIdFilter: null,
            condenserClass9IdFilter: null,
            evaporatorModelIdFilter: null,
            evaporatorClass9IdFilter: null,
            selectedCondenserUnit: null,
            selectedCondenserClass9Id: null,
            selectedCondenserOptionList: [],
            selectedCondenserAttributes: null,
            selectedCondenserCapacityPerUnit: null,
            selectedEvaporator: null,
            selectedEvaporatorClass9Id: null,
            selectedEvaporatorOptionList: [],
            selectedEvaporatorAttributes: null,
            selectedEvaporatorCapacityPerUnit: null,
            systemBalanced: true,
            sstBalance: undefined,
            tdBalance: undefined,
            totalSystemCapacity: undefined
        };

        const systemState: ISystemState = {
            formState: {
                ...state.formState,
                actualValues: {
                    ...state.formState.actualValues,
                    equipmentSelection: {
                        ...state.formState.actualValues.equipmentSelection,
                        ...clearedEquipmentSelection
                    }
                }
            },
            store: {
                ...state.store,
                equipmentSelection: {
                    ...state.store.equipmentSelection,
                    ...clearedEquipmentSelection
                }
            }
        };

        return systemState;
    };

    const setSelectedCondenser = (
        state: ISystemState,
        equipment: IEquipmentUnitItem | null,
        selectedOptions: IEquipmentOption[] | null
    ): ISystemState => {

        const modelId = equipment ? equipment.modelId : null;
        const class9ID = equipment ? (equipment.class9ID ?? null) : null;

        let {isCondenserPrimary, isPrimaryReloaded, isMatchedReloaded, evaporatorModelIdFilter, evaporatorClass9IdFilter} = state.formState.actualValues.equipmentSelection;
        if (equipment
            && state.formState.actualValues.equipmentSelection.isCondenserPrimary === null
        ) {
            isCondenserPrimary = true;
        }

        if (isCondenserPrimary) {
            evaporatorModelIdFilter = modelId;
            evaporatorClass9IdFilter = class9ID;
            isPrimaryReloaded = true;
        }
        else {
            isPrimaryReloaded = false;
            isMatchedReloaded = true;
        }

        const equipmentSelectedCondenser = {
            selectedCondenserUnit: equipment,
            isCondenserPrimary,
            isPrimaryReloaded,
            isMatchedReloaded,
            evaporatorModelIdFilter,
            evaporatorClass9IdFilter,
            selectedCondenserOptionList: selectedOptions ?? []
        };

        const systemState: ISystemState = {
            formState: {
                ...state.formState,
                actualValues: {
                    ...state.formState.actualValues,
                    equipmentSelection: {
                        ...state.formState.actualValues.equipmentSelection,
                        ...equipmentSelectedCondenser
                    }
                }
            },
            store: {
                ...state.store,
                equipmentSelection: {
                    ...state.store.equipmentSelection,
                    ...equipmentSelectedCondenser
                }
            }
        };

        return systemState;
    };

    const setSelectedEvaporator = (
        state: ISystemState,
        equipment: IEquipmentUnitItem | null,
        selectedOptions: IEquipmentOption[] | null
    ): ISystemState => {

        const modelId = equipment ? equipment.modelId : null;
        const class9ID = equipment ? (equipment.class9ID ?? null) : null;

        let {isCondenserPrimary, isPrimaryReloaded, isMatchedReloaded, condenserModelIdFilter, condenserClass9IdFilter} = state.formState.actualValues.equipmentSelection;
        if (equipment
            && state.formState.actualValues.equipmentSelection.isCondenserPrimary === null
        ) {
            isCondenserPrimary = false;
        }

        if (!isCondenserPrimary) {
            condenserModelIdFilter = modelId;
            condenserClass9IdFilter = class9ID;
            isPrimaryReloaded = true;
        }
        else {
            isPrimaryReloaded = false;
            isMatchedReloaded = true;
        }

        const equipmentSelectedEvaporator = {
            selectedEvaporator: equipment,
            isCondenserPrimary,
            isPrimaryReloaded,
            isMatchedReloaded,
            condenserModelIdFilter,
            condenserClass9IdFilter,
            selectedEvaporatorOptionList: selectedOptions ?? []
        };

        const systemState: ISystemState = {
            formState: {
                ...state.formState,
                actualValues: {
                    ...state.formState.actualValues,
                    equipmentSelection: {
                        ...state.formState.actualValues.equipmentSelection,
                        ...equipmentSelectedEvaporator
                    }
                }
            },
            store: {
                ...state.store,
                equipmentSelection: {
                    ...state.store.equipmentSelection,
                    ...equipmentSelectedEvaporator
                }
            }
        };

        return systemState;
    };

    const setEquipmentBalance = (
        state: ISystemState,
        systemBalanced: boolean,
        sstBalance: number,
        tdBalance: number,
        totalSystemCapacity: number
    ): ISystemState => {

        const equipmentBalance = {
            systemBalanced,
            sstBalance,
            tdBalance,
            totalSystemCapacity
        };

        const systemState: ISystemState = {
            formState: {
                ...state.formState,
                actualValues: {
                    ...state.formState.actualValues,
                    equipmentSelection: {
                        ...state.formState.actualValues.equipmentSelection,
                        isPrimaryReloaded: false,
                        isMatchedReloaded: false,
                        ...equipmentBalance
                    }
                }
            },
            store: {
                ...state.store,
                equipmentSelection: {
                    ...state.store.equipmentSelection,
                    isPrimaryReloaded: false,
                    isMatchedReloaded: false,
                    ...equipmentBalance
                }
            }
        };

        return systemState;
    };

    const addSection = (
        state: ISystemState,
        section: ISection
    ) => {

        const sections: ISection[] = [
            ...state.store.wallLoad.boxSections ?? (getDefaultStoreSections(state.formState.actualValues.boxType, state.formState.actualValues) ?? [])
        ];

        const systemStore: ISystem = {
            ...state.store,
            wallLoad: {
                ...state.store.wallLoad,
                boxSections: [
                    ...sections,
                    section
                ]
            }
        };

        return recalculateSystem({
            ...state,
            store: systemStore
        });
    };

    const updateSection = (
        state: ISystemState,
        index: number,
        section: ISection
    ): ISystemState => {

        const sections: ISection[] = [
            ...state.store.wallLoad.boxSections ?? (getDefaultStoreSections(state.formState.actualValues.boxType, state.formState.actualValues) ?? [])
        ];

        const systemStore: ISystem = {
            ...state.store,
            wallLoad: {
                ...state.store.wallLoad,
                boxSections: [
                    ...sections.slice(0, index), 
                    section, 
                    ...sections.slice(index + 1)
                ]
            }
        };

        return recalculateSystem({
            ...state,
            store: systemStore
        });
    };

    const copySection = (
        state: ISystemState,
        index: number
    ): ISystemState => {

        const sections: ISection[] = [
            ...state.store.wallLoad.boxSections ?? (getDefaultStoreSections(state.formState.actualValues.boxType, state.formState.actualValues) ?? [])
        ];

        if (index < 0 
            || index >= sections.length
        ) {
            return state;
        }
        
        const systemStore: ISystem = {
            ...state.store,
            wallLoad: {
                ...state.store.wallLoad,
                boxSections: [
                    ...sections,
                    sections[index]
                ]
            }
        };

        return recalculateSystem({
            ...state,
            store: systemStore
        });
    };

    const deleteSection = (
        state: ISystemState,
        index: number
    ): ISystemState => {

        const sections: ISection[] = [
            ...state.store.wallLoad.boxSections ?? (getDefaultStoreSections(state.formState.actualValues.boxType, state.formState.actualValues) ?? [])
        ];

        const systemStore: ISystem = {
            ...state.store,
            wallLoad: {
                ...state.store.wallLoad,
                boxSections: sections.filter((currentSection, currentIndex) => currentIndex !== index)
            }
        };

        return recalculateSystem({
            ...state,
            store: systemStore
        });
    };

    const updateSectionOpenings = (
        state: ISystemState,
        index: number,
        openings: IOpening[]
    ): ISystemState => {

        const sections: ISection[] = [
            ...state.store.wallLoad.boxSections ?? (getDefaultStoreSections(state.formState.actualValues.boxType, state.formState.actualValues) ?? [])
        ];

        const systemStore: ISystem = {
            ...state.store,
            wallLoad: {
                ...state.store.wallLoad,
                boxSections: [
                    ...sections.slice(0, index), 
                    {
                        ...sections[index],
                        openings
                    }, 
                    ...sections.slice(index + 1)
                ]
            }
        };

        return recalculateSystem({
            ...state,
            store: systemStore
        });
    };

    const getSystemValues = (
        boxApplicationData: any,
        systemStore: ISystem
    ) => {
        const systemState = recalculateSystem({
                ...emptySystemState,
                store: systemStore
            }, 
            boxApplicationData);

        return systemState.formState.actualValues;
    };

    const recalculateTotalLoads = (
        system: ISystem
    ): ISystem => {

        const totalHourlyLoad = LoadCalculations.calculateTotalHourlyLoad(
            system.totalWallLoad,
            system.totalInfiltrationLoad,
            system.totalProductLoad,
            system.totalMiscLoad);

        const totalLoadRequired = LoadCalculations.calculateTotalLoadRequired(
            system.safetyFactor,
            totalHourlyLoad);
            
        const capacityRequired = LoadCalculations.calculateCapacityRequired(
            totalLoadRequired,
            system.runTimeHours);

        return {
            ...system,
            totalHourlyLoad,
            totalLoadRequired,
            capacityRequired
        };
    };
    
    return {
        emptySystemState,
        recalculateSystem,
        recalculateLoads,
        getSystemValues,
        validateLoadCalc, // load calc screen (includes validateLoadCalc, validateBox, validateEquipment)
        validateWallLoad, // wall load screen (includes validateWallLoad, validateBox)
        validateEquipmentSelector, // equipment selector screen (includes validateEquipment, validateEquipmentFilters)
        recalculateTotalLoads,
        setInitialState,
        resetState,
        changeValue,
        customizeMeasureProperty,
        customizeWallLoadMeasureProperty,
        resetBoxType,
        clearEquipmentSelections,
        setSelectedCondenser,
        setSelectedEvaporator,
        setEquipmentBalance,
        addSection,
        updateSection,
        copySection,
        deleteSection,
        updateSectionOpenings
    };
};

export default useSystemState;
