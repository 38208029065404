import { measure, hasValue, getNumericValue } from "../../types/measure";

import IConstructionLayer from "../interfaces/IConstructionLayer";
import IFormField from "../interfaces/IFormField";
import IFormState from "../interfaces/IFormState";
import IMaterial from "../interfaces/IMaterial";

import * as ConstructionLayer from "./ConstructionLayerCalculations";
import { getFormField } from "./FormFields";

import { SECTION_WIDTH_DEFAULT, SECTION_CENTRE_DISTANCE_DEFAULT } from "../../Constants";

export const getThickness = (
    state: IFormState<IConstructionLayer>,
    enteredThickness: measure,
    material: IMaterial | undefined
): IFormField<measure> => {

    const getDefaultValue = () => {
		return material?.stdThickness;
	};
	
	return getFormField<measure>(
		state,
		"thickness",
		enteredThickness,
		getDefaultValue
	);
};

export const getKFactor = (
    state: IFormState<IConstructionLayer>,
    enteredKFactor: measure,
    enteredRValuePerInch: measure,
    enteredRValueEffective: measure,
    material: IMaterial | undefined
): IFormField<measure> => {

    if (hasValue(enteredRValueEffective)
        && hasValue(state.defaultValues.rValueEffective)
        && +getNumericValue(enteredRValueEffective) !== +getNumericValue(state.defaultValues.rValueEffective)
    ) {  
        return {
            defaultValue: null,
            actualValue: null
        };
    }

    if (hasValue(enteredRValuePerInch)) { 
        const calculatedKFactor = ConstructionLayer.calculateKFactor(enteredRValuePerInch);
        return {
            defaultValue: calculatedKFactor,
            actualValue: calculatedKFactor
        };
    }

    const getDefaultValue = () => {
		return material?.kFactor;
	};
    
    return getFormField<measure>(
        state,
        "kFactor",
        enteredKFactor,
        getDefaultValue
    );
};

export const getRValuePerInch = (
    state: IFormState<IConstructionLayer>,
    enteredRValuePerInch: measure,
    enteredKFactor: measure,
    enteredRValueEffective: measure,
    material: IMaterial | undefined
): IFormField<measure> => {

    if (hasValue(enteredRValueEffective)
        && hasValue(state.defaultValues.rValueEffective)
        && +getNumericValue(enteredRValueEffective) !== +getNumericValue(state.defaultValues.rValueEffective)
    ) {  
        return {
            defaultValue: null,
            actualValue: null
        };
    }

    if (hasValue(enteredKFactor)) { 
        const calculatedRValuerPerInch = ConstructionLayer.calculateRValuePerInch(enteredKFactor);
        return {
            defaultValue: calculatedRValuerPerInch,
            actualValue: calculatedRValuerPerInch
        };
    }

    const getDefaultValue = () => {
        const defaultKFactor = material?.kFactor;
        return ConstructionLayer.calculateRValuePerInch(defaultKFactor);
	};
    
    return getFormField<measure>(
        state,
        "rValuePerInch",
        enteredRValuePerInch,
        getDefaultValue
    );
};

export const getRValueEffective = (
    state: IFormState<IConstructionLayer>,
    enteredRValueEffective: measure,
    thickness: measure,
    rValuePerInch: measure,    
    isSectional: boolean,
    sectionRValuePerInch: measure,
    sectionCentreDistance: measure,
    sectionWidth: measure,
    material: IMaterial | undefined,
    sectionMaterial: IMaterial | undefined
): IFormField<measure> => {

    const getDefaultValue = () => {
        const defaultKFactor = material?.kFactor;
        const defaultRValuePerInch = ConstructionLayer.calculateRValuePerInch(defaultKFactor);
        const defaultSectionKFactor = sectionMaterial?.kFactor;
        const defaultSectionRValuePerInch = ConstructionLayer.calculateRValuePerInch(defaultSectionKFactor);

        return ConstructionLayer.calculateRValueEffective(
            thickness,
            rValuePerInch ?? defaultRValuePerInch,
            isSectional,
            sectionRValuePerInch ?? defaultSectionRValuePerInch,
            sectionCentreDistance,
            sectionWidth
        );
	};
    
    return getFormField<measure>(
        state,
        "rValueEffective",
        enteredRValueEffective,
        getDefaultValue
    );
};

export const getSectionCentreDistance = (
    state: IFormState<IConstructionLayer>,
    enteredSectionCentreDistance: measure,
    isSectional: boolean
): IFormField<measure> => {

    if (!isSectional) {
        return {
            defaultValue: null,
            actualValue: null
        };
    }

    const getDefaultValue = () => {
        return SECTION_CENTRE_DISTANCE_DEFAULT;
    }

    return getFormField<measure>(
        state,
        "sectionCentreDistance",
        enteredSectionCentreDistance,
        getDefaultValue
    );
};

export const getSectionWidth = (
    state: IFormState<IConstructionLayer>,
    enteredSectionWidth: measure,
    sectionMaterial: IMaterial | undefined,
    isSectional: boolean
): IFormField<measure> => {

    if (!isSectional) {
        return {
            defaultValue: null,
            actualValue: null
        };
    }

    const getDefaultValue = () => {
        return sectionMaterial?.stdWidth ?? SECTION_WIDTH_DEFAULT;
    }

    return getFormField<measure>(
        state,
        "sectionWidth",
        enteredSectionWidth,
        getDefaultValue
    );
};

export const getSectionKFactor = (
    state: IFormState<IConstructionLayer>,
    enteredSectionKFactor: measure,
    enteredSectionRValuePerInch: measure,
    enteredRValueEffective: measure,
    sectionMaterial: IMaterial | undefined,
    isSectional: boolean
): IFormField<measure> => {
    
    if (!isSectional) {
        return {
            defaultValue: null,
            actualValue: null
        };
    }

    if (hasValue(enteredRValueEffective)
        && hasValue(state.defaultValues.rValueEffective)
        && +getNumericValue(enteredRValueEffective) !== +getNumericValue(state.defaultValues.rValueEffective)
    ) {  
        return {
            defaultValue: null,
            actualValue: null
        };
    }

    if (hasValue(enteredSectionRValuePerInch)) { 
        const calculatedKFactor = ConstructionLayer.calculateKFactor(enteredSectionRValuePerInch);
        return {
            defaultValue: calculatedKFactor,
            actualValue: calculatedKFactor
        };
    }

    const getDefaultValue = () => {
        return sectionMaterial?.kFactor;
    }

    return getFormField<measure>(
        state,
        "sectionKFactor",
        enteredSectionKFactor,
        getDefaultValue
    );
};

export const getSectionRValuePerInch = (
    state: IFormState<IConstructionLayer>,
    enteredSectionRValuePerInch: measure,
    enteredSectionKFactor: measure,
    enteredRValueEffective: measure,
    sectionMaterial: IMaterial | undefined,
    isSectional: boolean
): IFormField<measure> => {

    if (!isSectional) {
        return {
            defaultValue: null,
            actualValue: null
        };
    }

    if (hasValue(enteredRValueEffective)
        && hasValue(state.defaultValues.rValueEffective)
        && +getNumericValue(enteredRValueEffective) !== +getNumericValue(state.defaultValues.rValueEffective)
    ) {  
        return {
            defaultValue: null,
            actualValue: null
        };
    }

    if (hasValue(enteredSectionKFactor)) { 
        const calculatedRValuerPerInch = ConstructionLayer.calculateRValuePerInch(enteredSectionKFactor);
        return {
            defaultValue: calculatedRValuerPerInch,
            actualValue: calculatedRValuerPerInch
        };
    }

    const getDefaultValue = () => {
        const defaultSectionKFactor = sectionMaterial?.kFactor;
        return ConstructionLayer.calculateRValuePerInch(defaultSectionKFactor);
	};
    
    return getFormField<measure>(
        state,
        "sectionRValuePerInch",
        enteredSectionRValuePerInch,
        getDefaultValue
    );
};