import * as React from "react";

import useErrorContext from "../../hooks/useErrorContext";

interface ErrorNotificationProps {}

const errorMessageDisplayStyle = {
	display: "",
	position: "fixed",
	top: 0,
	right: 0,
	width: "100%",
	zIndex: 999
} as React.CSSProperties;

const ErrorNotification: React.FunctionComponent<ErrorNotificationProps> = () => {

    const { errorMessages, hideError } = useErrorContext();

    const handleClick = () => {
        hideError();
    };

    return (
        <>
            { errorMessages && errorMessages.length > 0 &&
            (
                <div className="notification is-danger" style={errorMessageDisplayStyle}>
                    <button name="close" className="delete" aria-label="close" onClick={handleClick} type="button">
                        &nbsp;
                    </button>
                    {errorMessages.map(e => (
                        <div key={e.name}>
                            {e.text}
                        </div>
                    ))}
                </div>
            )}
        </>
    )
};

export default ErrorNotification;