/* eslint-disable import/prefer-default-export */

import IToolTips from "../../models/interfaces/ITooltips";

export const enTooltips: IToolTips = {
    Box_Designer_Button_AddSection: "Add a custom wall, ceiling or floor to the list.",
    Box_Designer_Button_AddWallConstruction: "Select the construction details of the next wall you add.",
    Box_Designer_Button_Cancel: "Discard your edits and return to the main form.",
    Box_Designer_Button_Change_Shape: "Select a different shape for your box.",
    Box_Designer_Button_CompleteDirect: "Add a final wall that goes directly back to the start point.",
    Box_Designer_Button_CompleteSquare: "Draw two final walls to square out the floor back to the start point.",
    Box_Designer_Button_CopySection: "Copy the selected section as a new section and edit it.",
    Box_Designer_Button_DeleteSections: "Delete the selected custom sections.",
    Box_Designer_Button_DrawWall: "Draw a wall from the cursor point ±X units horizontally and ±Y units vertically.",
    Box_Designer_Button_EditOpenings: "Add or edit any doors, windows, panels or excluded areas.",
    Box_Designer_Button_EditSection: "Edit the selected sections.",
    Box_Designer_Button_OK: "Save your changes and return to the main form.",
    Box_Designer_Button_RemoveWall: "Undraw the last wall you added to the floor planner.",
    Box_Designer_CheckBox_OutDoors_Cold: "Select if box is outside in a cold climate",
    Box_Designer_CheckBox_OutDoors_Warm: "Select if box is outside in a warm climate",
    Box_Designer_DraftingBoard: "This panel shows your floor plan.",
    Box_Designer_txtbox_Depth1: "First depth of box. See wall highlighted in box view.",
    Box_Designer_txtbox_Depth2: "Second depth of box. See wall highlighted in box view.",
    Box_Designer_txtbox_Depth3: "Third depth of box. See wall highlighted in box view.",
    Box_Designer_txtbox_DrawX: "Horizontal distance to draw the next wall. Use negative values to draw left.",
    Box_Designer_txtbox_DrawY: "Vertical distance to draw the next wall. Use negative values to draw down.",
    Box_Designer_txtBox_Exterior_Temp: "The maximum external temperature in the vicinity of the box.",
    Box_Designer_txtbox_ExternalRH: "Ambient humidity around box.",
    Box_Designer_txtbox_Floor_Area: "This is the total floor area. You may need to enter this number directly if the box shape is irregular.",
    Box_Designer_txtbox_Height: "Height of box.",
    Box_Designer_txtBox_Interior_Temp: "Enter the coldest temperature you wish the box to reach.",
    Box_Designer_txtbox_InternalRH: "Enter the relative humidity you wish to maintain within the box.",
    Box_Designer_txtbox_Length1: "First length of box. See wall highlighted in box view.",
    Box_Designer_txtbox_Length2: "Second length of box. See wall highlighted in box view.",
    Box_Designer_txtbox_Length3: "Third length of box. See wall highlighted in box view.",
    Box_Designer_txtbox_Volume: "This is the cubic volume of the box. You may enter it directly here or it will be calculated automatically from the above data if the room is not irregular.",
    Box_Designer_txtbox_Wall_Load: "This is the wall load calculated from the above information. You may enter it directly if you wish.",
    Box_Designer_SelectType_Button_Cancel: "Cancel this operation.",
    Box_Designer_SelectType_Button_Floor_Planner: "Box is one height but has an odd shaped floor, draw a floor plan.",
    Box_Designer_SelectType_Button_L_Shaped: "Box is shaped like an L.",
    Box_Designer_SelectType_Button_Rectangular: "Box is rectangular or square.",
    Box_Designer_SelectType_Button_SectionList: "Box is an odd shape, define all surfaces and volume manually.",    
    Box_Designer_SelectType_Button_U_Shaped: "Box is shaped like a U.",
    Box_Designer_WallSection_Button_Add_And_Continue: "Click here to add the current section information, and begin a new addition.",
    Box_Designer_WallSection_Button_AddOpening: "Click this button to add doors, windows and other openings to the section.",
    Box_Designer_WallSection_Button_Cancel: "Cancel your edits and return to the previous form.",
    Box_Designer_WallSection_Button_DeleteOpening: "Delete the selected openings.",
    Box_Designer_WallSection_Button_EditConstruction: "Edit the section construction layers.",
    Box_Designer_WallSection_Button_EditOpening: "Edit the selected opening.",
    Box_Designer_WallSection_Button_OK: "Save your information and return to the previous form.",
    Box_Designer_WallSection_Combo_Material: "Select the insulation type, or construction details from the list. ~ Click on ADD NEW CONSTRUCTION to create a new layered wall construction.",
    Box_Designer_WallSection_Combo_Outdoors: "Select if the external face of this section is outside, exposed to the sun.",
    Box_Designer_WallSection_ComboBox_SectionType: "Select whether the section is a wall, a floor, or a ceiling.",
    Box_Designer_WallSection_txtbox_Area: "This is the area of the section. You should enter this value manually if the section is an odd shape.",
    Box_Designer_WallSection_txtbox_Dimension1: "First dimension of section, usually length.",
    Box_Designer_WallSection_txtbox_Dimension2: "Second dimension of section, usually height but could be width.",
    Box_Designer_WallSection_txtBox_ExteriorTemperature: "The maximum temperature on the outside of this wall section.",
    Box_Designer_WallSection_txtbox_ExternalRH: "The relative humidity on the outside of this wall section.",
    Box_Designer_WallSection_txtBox_KFactor: "Thermal 'K-Factor' of section. Derived from insulation type above or may be entered directly if known.",
    Box_Designer_WallSection_txtbox_Load: "This is the load generated by the above section.",
    Box_Designer_WallSection_txtbox_MaterialOther: "The name of the wall insulation or construction.",
    Box_Designer_WallSection_txtbox_Perimeter: "This is the perimeter length of the floor section. You may need to enter this manually if the floor is an odd shape.",
    Box_Designer_WallSection_txtbox_R_Value_Effective: "This is the effective R-Value of the section across it's entire thickness including air film effects.",
    Box_Designer_WallSection_txtbox_R_Value_PerInch: "This is the R-Value per inch of the section.",
    Box_Designer_WallSection_txtbox_SectionName: "Giving sections distinct names often helps.",
    Box_Designer_WallSection_txtbox_SectionType: "A section can be a wall, ceiling or floor.",
    Box_Designer_WallSection_txtbox_Thickness: "This is the thickness of the wall.",
    Infiltration_Button_Cancel: "Discard your changes and return to the previous form.",
    Infiltration_Button_EditDoors: "Open the door and opening editor to define or edit your doors.",
    Infiltration_Button_OK: "Save your changes and return to the previous form.",
    Infiltration_Combo_Method: "Select the method you want to use.",
    Infiltration_Combo_Usage: "Select the usage level of the box.",
    Infiltration_lbl_Method1_Caption: "This value is calculated based on A.S.H.R.A.E. air change factors and infiltration air temperature.",
    Infiltration_lbl_Method2_Caption: "Use this method for a more accurate load estimate.",
    Infiltration_lbl_Method3_Caption: "Use this method if positive ventilation is used.",
    Infiltration_txtbox_TotalInfiltrationload: "This is the total infiltration load. You may enter this number directly if you wish.",
    Infiltration_txtbox_VentilationAirTemp: "Average Infiltrating Air Temperature.",
    Infiltration_txtbox_VentilationRate: "Enter the air flow rate coming into the box.",
    Infiltration_txtbox_VentilationRH: "Enter relative humidity of the infiltrating air.  (e.g. 55%)",
    Miscellaneous_Button_AddLoad: "Add another load to the list.",
    Miscellaneous_Button_Cancel: "Discard my changes and return to the previous form.",
    Miscellaneous_Button_DeleteLoad: "Delete the selected loads from the list.",
    Miscellaneous_Button_EditLoad: "Edit the selected load.",
    Miscellaneous_Button_OK: "Save my changes and return to the previous form.",
    Miscellaneous_Combo_Activity: "Select the expected average work activity within the box etc.",
    Miscellaneous_lvOtherLoads: "If you have other relevant loads you may describe them here.",
    Miscellaneous_txtbox_EvapMotors_Load: "This value is the total motor load. You may enter it directly if you wish.",
    Miscellaneous_txtbox_EvapMotors_Quantity: "Enter the total number of motors inside the box.",
    Miscellaneous_txtbox_EvapMotors_Rating: "Enter the power rating of the motors that exist within the box. ",
    Miscellaneous_txtbox_EvapMotors_RunTIme: "Enter the amount of time the motors can be expected to run per day.",
    Miscellaneous_txtbox_ForkLift_Load: "This value is the total forklift load. You may enter it directly if you wish.",
    Miscellaneous_txtbox_ForkLift_Quantity: "Enter how many forklifts are present in the box.",
    Miscellaneous_txtbox_ForkLift_RunTime: "Enter amount of time the forklifts are active per day.",
    Miscellaneous_txtbox_Lighting_Load: "This value is the total lighting load. You may enter it directly if you wish.",
    Miscellaneous_txtbox_Lighting_PowerDensity: "This value is the wattage of light you are expecting per square foot of floor.",
    Miscellaneous_txtbox_Lighting_Quantity: "If you know how many lighting fixtures will be installed, then enter the number here.",
    Miscellaneous_txtbox_Lighting_Rating: "If you know which lighting fixtures will be installed, then enter their rating here.",
    Miscellaneous_txtbox_Lighting_RunTime: "Enter the maximum amount of time per day that these lights will be on in hours.",
    Miscellaneous_txtbox_Lighting_TotalPower: "If you know how much lighting will be installed, then enter the total wattage here.",
    Miscellaneous_txtbox_Staff_Load: "This value is the total staff load. You may enter it directly if you wish.",
    Miscellaneous_txtbox_Staff_Quantity: "Enter how many staff are usually present in the box.",
    Miscellaneous_txtbox_Staff_RunTime: "Enter how much time per day the staff are present in the box.",
    Miscellaneous_txtbox_TotalMiscellaneous_load: "This value is the total of the above loads. You may enter it directly if you wish.",
    Miscellaneous_txtbox_TotalOtherLoads: "This value is the total of the other loads. You may enter it directly if you wish.",
    Miscellaneous_Other_Button_Add: "Add this load to the list and start a new one.",
    Miscellaneous_Other_Button_Cancel: "Discard my changes and return to the previous form.",
    Miscellaneous_Other_Button_OK: "Save my changes and return to the previous form.",
    Miscellaneous_Other_Combo_Units: "Select the units of the power value.",
    Miscellaneous_Other_txtbox_Description: "Describe the load here.",
    Miscellaneous_Other_txtbox_Load: "This value is the refrigeration load of the above. You may enter it directly if you wish.",
    Miscellaneous_Other_txtbox_Power: "Enter the watts or horsepower of the load here. Remember to set the appropriate units.",
    Miscellaneous_Other_txtbox_Quantity: "Enter the quantity of described item that are present in the box here.",
    Miscellaneous_Other_txtbox_RunTime: "Enter the relative time per day that this load is active.",
    Product_List_Button_AddProduct: "Add a product to the list.",
    Product_List_Button_Cancel: "Discard your changes and return to the previous form.",
    Product_List_Button_DeleteProduct: "Remove the selected products from the list.",
    Product_List_Button_EditProduct: "Edit the details about the selected product.",
    Product_List_Button_OK: "Save your product information and return to the previous form.",
    Product_List_txtbox_Load: "This value is the Total Product Load.  It is calculated from the above data or may be entered directly.",
    Product_List_txtbox_TotalWeight: "Total weight of product.",
    Product_List_Item_Button_Add: "Add the current product to the list and start adding a new one.",
    Product_List_Item_Button_Cancel: "Discard this information and return to the previous form.",
    Product_List_Item_Button_OK: "Save this information and return to the previous form.",
    Product_List_Item_ButtonSelectType: "Select a product type from a list.",
    Product_List_Item_txtbox_EnteringTemp: "Temperature of the product as is enters the box.",
    Product_List_Item_txtbox_FinalTemp: "Temperature at which product is stored, or leaves the box.",
    Product_List_Item_txtbox_FreezeTemperature: "Highest freeze temperature.",
    Product_List_Item_txtbox_LoadFactor: "Normally 1, change only if the box is used as a blast freezer or if rapid temp reduction is required.",
    Product_List_Item_txtbox_MoistureContent: "Moisture content of product as a percent of total weight.",
    Product_List_Item_txtbox_ProductLoad: "This is the refrigeration load for this product.",
    Product_List_Item_txtbox_ProductOtherName: "Name of the product, If you chose OTHER from the list, please enter the type manually here.",
    Product_List_Item_txtbox_PullDownTime: "This is the amount of time you have calculated it will take to chill the product to the entered final temperature as the specific box temperature.",
    Product_List_Item_txtbox_SpcHeatAbove: "This is the specific heat of the product above freezing.",
    Product_List_Item_txtbox_SpcHeatBelow: "This is the specific heat of the product below freezing.",
    Product_List_Item_txtbox_SpcHeatRespiration: "Heat of respiration /24 hours.",
    Product_List_Item_txtbox_Weight: "Total weight of entering or stored product.",
    System_Designer_Button_Infiltration_Load: "Click this button to define or calculate the infiltration load.",
    System_Designer_Button_Miscellaneous_Load: "Click this button to enter information about lights, motors etc.",
    System_Designer_Button_Preview: "See how your job data will look when it is printed.",
    System_Designer_Button_Print: "Print your job data.",
    System_Designer_Button_Product_Load: "Click this button to enter information about the products you wish to store.",
    System_Designer_Button_Wall_Load: "Click here to enter specifics about the physical size of your box.",
    System_Designer_txtbox_BoxDepth1: "If your box is a standard rectangular shape, enter the width here.",
    System_Designer_txtbox_BoxHeight: "If your box is a standard rectangular shape, enter the height here.",
    System_Designer_txtbox_BoxLength1: "If your box is a standard rectangular shape, enter the length here.",
    System_Designer_txtBox_Exterior_Temp: "Ambient temperature around box.",
    System_Designer_txtbox_ExteriorRH: "Ambient humidity around box.",
    System_Designer_txtBox_Interior_Temp: "Enter the coldest temperature you wish the box to reach.",
    System_Designer_txtbox_InteriorRH: "Enter the relative humidity you wish to maintain within the box.",
    System_Designer_txtRun_Time: "Design Run-Time hours are dependant on the type of defrost system used and box temperature.",
    System_Designer_txtTotal_Hourly_Load: "This is the total box load in Btu/h over a 24 hour time period with an allowance for System Run-Time."
}