import * as React from "react";
import StorageFacade from "../../services/StorageFacade";

const BallyTheme = React.lazy(() => import("./BallyTheme"));
const KeepRiteTheme = React.lazy(() => import("./KeepRiteTheme"));
const TrentonTheme = React.lazy(() => import("./TrentonTheme"));;

interface ThemeSelectorProps {
	children: any;
}

const ThemeSelector: React.FunctionComponent<ThemeSelectorProps> = (
	props: ThemeSelectorProps
) => {

    const brand = StorageFacade.brand?.toLowerCase();
    
    return (
        <>
            <React.Suspense fallback={<></>}>
                {(brand === "bally") && <BallyTheme />}
                {(brand === "keeprite" || !brand || brand === "") && <KeepRiteTheme />}
                {(brand === "trenton") && <TrentonTheme />}
            </React.Suspense>
            {props.children}
        </>
    );
};

export default ThemeSelector;
