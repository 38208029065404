import * as React from "react";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import StorageFacade from "../../services/StorageFacade";
import LoadCalculationService from "../../services/LoadCalculationService";

import IEquipmentState from "../../models/interfaces/IEquipmentState";

import LoadInfoEquipmentSummary from "./LoadInfoEquipmentSummary";
import ModelInfo from "./ModelInfo";
import ModelOptions from "./ModelOptions";
import ModelDrawing from "./ModelDrawing";

import useLoadingContext from "../../hooks/useLoadingContext";
import useSystemContext from "../../hooks/useSystemContext";

import "./Print.css";
import HelperService from "../../services/HelperService";

function hideElement(elementId: string){
	const element = document.getElementById(elementId);
	if (element) element.style.display = "none";
}

function showElement(elementId: string){
	const element = document.getElementById(elementId);
	if (element) element.style.display = "block";
}

const PrintReport = () => {
	const [exportingPageNumber, setExportingPageNumber] = React.useState(0);
    const [totalPageNumber, setTotalPageNumber] = React.useState(0);
    const [isFromLegacy, setIsFromLegacy] = React.useState(false);

	const { showLoading, hideLoading } = useLoadingContext();
	const { systemState, dispatch } = useSystemContext();

    React.useEffect(() => {

        setIsFromLegacy(StorageFacade.isFromLegacy);

        const getLoads = async () => {
            const loads = await LoadCalculationService.fetchCalculatedLoadsAsync(
                systemState.formState.actualValues.boxApplicationId ?? 0,
                systemState.formState.actualValues.boxDesign.floorArea,
                systemState.formState.actualValues.boxDesign.volume,
                systemState.formState.actualValues.boxDesign.totalInfiltrationLoad,
                systemState.formState.actualValues.exteriorTemperature,
				systemState.formState.actualValues.exteriorHumidity,
				systemState.formState.actualValues.interiorTemperature,
                systemState.formState.actualValues.interiorHumidity,
                systemState.store.miscLoad,
                systemState.store.infiltrationLoad,
                systemState.store.productLoad);

            const {totalMiscLoad, totalInfiltrationLoad, totalProductLoad, miscLoadItems, infiltrationLoadItems, productLoadItems} = loads;

            dispatch({ 
				type: "recalculate-loads", 
                totalMiscLoad: totalMiscLoad > 0 ? totalMiscLoad : undefined, 
                totalInfiltrationLoad: totalInfiltrationLoad > 0 ? totalInfiltrationLoad : undefined, 
				totalProductLoad: totalProductLoad > 0 ? totalProductLoad : undefined,
				miscLoadItems,
				infiltrationLoadItems,
				productLoadItems,
				updateStore: true
            });

            hideLoading();
        };

		if (systemState.formState.actualValues.boxApplicationId
			&& systemState.formState.actualValues.boxApplicationId > 0
		) {
			showLoading();
            getLoads();
        }
	}, [
		systemState.formState.actualValues.boxApplicationId,
		systemState.formState.actualValues.boxDesign.floorArea,
		systemState.formState.actualValues.boxDesign.volume,
		systemState.formState.actualValues.boxDesign.totalInfiltrationLoad,
		systemState.formState.actualValues.exteriorTemperature,
		systemState.formState.actualValues.exteriorHumidity,
		systemState.formState.actualValues.interiorTemperature,
		systemState.formState.actualValues.interiorHumidity,
		systemState.store.miscLoad,
		systemState.store.infiltrationLoad,
		systemState.store.productLoad
    ]);

	const pagesEquipment: Array<string> = [];

	function addPDFPage(
		pdf: any, 
		currentPageIndex: number
	) {
		if (currentPageIndex >= pagesEquipment.length){
			pdf.save("certified_print.pdf"); // Generated PDF												
			
			showElement("load_design_summary");			
			pagesEquipment.forEach(pageId => {
				showElement(pageId);
			});

            setExportingPageNumber(-1);
            // setTimeout(() => { setExportingPageNumber(0);}, 8000)
			
			hideLoading();

			return;
		}

		if (currentPageIndex === 0) {
			hideElement("load_design_summary");
		}
		else {	
			hideElement(pagesEquipment[currentPageIndex - 1]);
		}

		pdf.addPage();			
		const dataPage2: any = document.getElementById(pagesEquipment[currentPageIndex]);
		window.scrollTo(0,0);
		setExportingPageNumber(currentPageIndex + 2);
		html2canvas(dataPage2, { scale: 1.25, 
			scrollX: -window.scrollX,
			scrollY: -window.scrollY
		}).then(canvas2 => {
			const imgWidth = 420;			
			const imgHeight = (canvas2.height * imgWidth) / canvas2.width;				    

			pdf.addImage(canvas2, "PNG", 12, 15, imgWidth, imgHeight, undefined, "FAST");

			addPDFPage(pdf, currentPageIndex + 1);
		});
	}

	function exportPDF(equipmentInfo: IEquipmentState | null) {
		if (!equipmentInfo) {
			return;
		}
		
		showLoading();
		
		pagesEquipment.length = 0;

		if (equipmentInfo.selectedCondenserUnit) {
			pagesEquipment.push("ModelInfoCondensor");

			if (equipmentInfo.selectedCondenserOptionList 
				&& equipmentInfo.selectedCondenserOptionList.length > 0
			) {
				pagesEquipment.push("ModelOptionsCondensor");
			}

			pagesEquipment.push("ModelDrawingCondensor");
		}

		if (equipmentInfo.selectedEvaporator) {
			pagesEquipment.push("ModelInfoEvap");

			if (equipmentInfo.selectedEvaporatorOptionList 
				&& equipmentInfo.selectedEvaporatorOptionList.length > 0
			) {
				pagesEquipment.push("ModelOptionsEvap");
			}
			
			pagesEquipment.push("ModelDrawingEvap");
		}

		setTotalPageNumber(pagesEquipment.length + 1);

		const dataPage1: any = document.getElementById("load_design_summary");
		window.scrollTo(0,0);
		setExportingPageNumber(1);
		html2canvas(dataPage1, { scale: 1.25, 
			scrollX: -window.scrollX,
			scrollY: -window.scrollY
		}).then(canvas1 => {
			// Few necessary setting options
			const imgWidth = 420;			
			const imgHeight = (canvas1.height * imgWidth) / canvas1.width;

			const pdf = new jsPDF({
				unit: "px",
				compressPdf: true
			}); // A4 size page of PDF
			
			pdf.addImage(canvas1, "PNG", 12, 15, imgWidth, imgHeight, undefined, "FAST");

			addPDFPage(pdf, 0);						
		});
	}

	const equipmentInfo = systemState.formState.actualValues.equipmentSelection;
	const divisionId = Number(StorageFacade.divisionId);

    let condensingUnitModelDrawingClassName = "";
    if (equipmentInfo.selectedEvaporator)
        condensingUnitModelDrawingClassName = "printpage";
    else
        condensingUnitModelDrawingClassName = "noprintpage";    

	return (
		<>
			<nav className="level hidden-for-print">
				<div className="level-left">
					<div className="level-item">
						<button 
							type="button" 
							className="button is-link" 
							onClick={() => window.print() }
						>
							Print to PDF
						</button>
                    </div>
                    <div className="level-item">
                        <button type="button" className="button is-link" onClick={() => { window.close(); }}>Close Preview</button>
                    </div>
					<div className="level-item" style={{fontSize:"30px"}}>
						{
							((exportingPageNumber > 0) && (
								<>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<b>{`Exporting page ${exportingPageNumber} / ${totalPageNumber} ...`}</b>
								</>
                            ))
                        }
                        {
                            ((exportingPageNumber < 0 && !isFromLegacy) && (
                                <>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
									<b>Please go to the Downloads folder to find your certified print.</b>
                                </>
                            ))
                        }
					</div>
				</div>
			</nav>
			<br />
			<div className="printarea">
                <div className="printpage">
				    <LoadInfoEquipmentSummary 
					    sectionId="load_design_summary" 
					    divisionId={divisionId}
					    myInfo={StorageFacade.myInfo}
					    customerInfo={StorageFacade.customerInfo}
					    system={systemState.formState.actualValues}
				    />
                </div>
				{equipmentInfo.selectedCondenserUnit && (
					<>
						<br />
                        <div className="printpage">
						    <ModelInfo 
							    sectionId="ModelInfoCondensor"
							    divisionId={divisionId}
							    jobName={StorageFacade.jobName}
							    customerName={StorageFacade.customerInfo?.Contact ?? ""}
							    myName={StorageFacade.myInfo?.Contact ?? ""}
							    equipmentInfo={equipmentInfo} 
							    isCondensingUnit
							    selectedUnitItem={equipmentInfo.selectedCondenserUnit} 
                                selectedOptionList={equipmentInfo.selectedCondenserOptionList}
                                excludedOptionIds={null}
							    selectedUnitAttributes={equipmentInfo.selectedCondenserAttributes}
							    isPrintSingleEquipment={true}
							    quoteFormInput={null}
							    itemNumber={StorageFacade.CPMFormInput?.ItemNumber ?? 0}
						    />		
                        </div>
						{ 
							equipmentInfo.selectedCondenserOptionList 
								&& equipmentInfo.selectedCondenserOptionList.length > 0
								&&
							(
								<>
									<br />
                                    <div className="printpage">
									<ModelOptions 
										sectionId="ModelOptionsCondensor" 
										divisionId={divisionId}
										jobName={StorageFacade.jobName}
										customerName={StorageFacade.customerInfo?.Contact ?? ""}
										myName={StorageFacade.myInfo?.Contact ?? ""}
										equipmentInfo={equipmentInfo} 
										isCondensingUnit
										selectedUnitItem={equipmentInfo.selectedCondenserUnit} 
										selectedOptionList={equipmentInfo.selectedCondenserOptionList} 
										excludedOptionIds={null}
										selectedUnitAttributes={equipmentInfo.selectedCondenserAttributes}
									/>
                                    </div>
								</>
							)
						}
						<br />
                        <div className={condensingUnitModelDrawingClassName}>
						<ModelDrawing 
							sectionId="ModelDrawingCondensor" 
							divisionId={divisionId}
							jobName={StorageFacade.jobName}
							customerName={StorageFacade.customerInfo?.Contact ?? ""}
							myName={StorageFacade.myInfo?.Contact ?? ""}
							interiorTemperature={systemState.formState.actualValues.interiorTemperature}
							equipmentInfo={equipmentInfo} 
							isCondensingUnit
							selectedUnitItem={equipmentInfo.selectedCondenserUnit} 
							selectedOptionList={equipmentInfo.selectedCondenserOptionList} 
							selectedUnitAttributes={equipmentInfo.selectedCondenserAttributes}
							isPrintSingleEquipment={true}
							capacityHasError={false}
							quoteFormInput={null}
							itemNumber={StorageFacade.CPMFormInput?.ItemNumber ?? 0}
                            isForACCD={false}
						/>
                        </div>
					</>
				)}
				{equipmentInfo.selectedEvaporator && (
					<>
						<br />
                        <div className="printpage">
						<ModelInfo 
							sectionId="ModelInfoEvap" 
							divisionId={divisionId}
							jobName={StorageFacade.jobName}
							customerName={StorageFacade.customerInfo?.Contact ?? ""}
							myName={StorageFacade.myInfo?.Contact ?? ""}
							equipmentInfo={equipmentInfo} 
							isCondensingUnit={false}
							selectedUnitItem={equipmentInfo.selectedEvaporator} 
                            selectedOptionList={equipmentInfo.selectedEvaporatorOptionList}
                            excludedOptionIds={null}
							selectedUnitAttributes={equipmentInfo.selectedEvaporatorAttributes}
							isPrintSingleEquipment={true}
							quoteFormInput={null}
							itemNumber={StorageFacade.CPMFormInput?.ItemNumber ?? 0}
						/>
                        </div>
						{
							equipmentInfo.selectedEvaporatorOptionList 
								&& equipmentInfo.selectedEvaporatorOptionList.length > 0
								&&
							(
								<>
									<br />
                                    <div className="printpage">
									<ModelOptions 
										sectionId="ModelOptionsEvap" 
										divisionId={divisionId}
										jobName={StorageFacade.jobName}
										customerName={StorageFacade.customerInfo?.Contact ?? ""}
										myName={StorageFacade.myInfo?.Contact ?? ""}
										equipmentInfo={equipmentInfo} 
										isCondensingUnit={false}
										selectedUnitItem={equipmentInfo.selectedEvaporator} 
										selectedOptionList={equipmentInfo.selectedEvaporatorOptionList} 
										excludedOptionIds={null}
										selectedUnitAttributes={equipmentInfo.selectedEvaporatorAttributes}
									/>
                                    </div>
								</>
							)
						}
						<br />                        
						<ModelDrawing 
							sectionId="ModelDrawingEvap" 
							divisionId={divisionId}
							jobName={StorageFacade.jobName}
							customerName={StorageFacade.customerInfo?.Contact ?? ""}
							myName={StorageFacade.myInfo?.Contact ?? ""}
							interiorTemperature={systemState.formState.actualValues.interiorTemperature}
							equipmentInfo={equipmentInfo} 
							isCondensingUnit={false}
							selectedUnitItem={equipmentInfo.selectedEvaporator} 
							selectedOptionList={equipmentInfo.selectedEvaporatorOptionList} 
							selectedUnitAttributes={equipmentInfo.selectedEvaporatorAttributes}
							isPrintSingleEquipment={true}
							capacityHasError={false}
							quoteFormInput={null}
							itemNumber={StorageFacade.CPMFormInput?.ItemNumber ?? 0}
                            isForACCD={false}
						/>                        
					</>
				)}
			</div>
		</>
	);
};

export default PrintReport;
