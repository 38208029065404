/* eslint-disable no-underscore-dangle */
import IFormState from "../models/interfaces/IFormState";
import IConstructionLayer from "../models/interfaces/IConstructionLayer";
import IConstructionLayerFormParams from "../models/interfaces/IConstructionLayerFormParams";
import IMessage from "../models/interfaces/IMessage";

import { hasValue } from "../types/measure";

import * as ConstructionLayer from "../models/helpers/ConstructionLayerFormFields";

import { SECTION_CENTRE_DISTANCE_DEFAULT, SECTION_WIDTH_DEFAULT } from "../Constants";

import useBoxMaterialsContext from "./useBoxMaterialsContext";
import useTranslationContext from "./useTranslationContext";

const useConstructionLayerForm = () => {

    const { getBoxMaterial, getBoxMaterialName } = useBoxMaterialsContext();

    const { translations } = useTranslationContext();

    const initialValues: IConstructionLayer = {
        name: "",
        materialId: "0",
        materialName: "",
        thickness: null,
        kFactor: null,
        rValuePerInch: null,
        rValueEffective: null,
        isBoundaryInteriorLayer: false,
        isBoundaryExteriorLayer: false,
        isSectional: false,
        sectionCentreDistance: SECTION_CENTRE_DISTANCE_DEFAULT,
        sectionMaterialId: "0",
        sectionMaterialName: "",
        sectionWidth: SECTION_WIDTH_DEFAULT,
        sectionKFactor: null,
        sectionRValuePerInch: null
    };
    
    const initialFormState: IFormState<IConstructionLayer> = {
        defaultValues: initialValues,
        actualValues: initialValues
    };

    const validateLayer = (
        layer: IConstructionLayer
    ): IMessage[] => {

        const errors: IMessage[] = [];

        if (!layer.isBoundaryInteriorLayer
            && !layer.isBoundaryExteriorLayer
        ) {
            if (layer.materialId === "0"
                && !hasValue(layer.rValueEffective)
            ) {
                errors.push({
                    name: "materialId", 
                    text: translations.theMaterialMaterialTypeIsNotSelected_
                });
            }        

            if (!hasValue(layer.thickness)) {
                errors.push({
                    name: "thickness", 
                    text: translations.thicknessIsNotEntered_
                });
            }

            if (!hasValue(layer.kFactor)
                && !hasValue(layer.rValuePerInch)
                && !hasValue(layer.rValueEffective)
            ) {
                errors.push({
                    name: "kFactor", 
                    text: translations.neitherTheKFactorNorTheR_ValueHasBeenEntered_
                });
            }

            if (layer.sectionMaterialId === "0"
                && layer.isSectional
                && !hasValue(layer.rValueEffective)
            ) {
                errors.push({
                    name: "sectionMaterialId", 
                    text: translations.theDividerMaterialTypeIsNotSelected_
                });
            }        

            if (!hasValue(layer.sectionWidth)
                && layer.isSectional
                && !hasValue(layer.rValueEffective)
            ) {
                errors.push({
                    name: "sectionWidth", 
                    text: translations.thicknessIsNotEntered_
                });
            }

            if (!hasValue(layer.sectionKFactor)
                && layer.isSectional
                && !hasValue(layer.rValuePerInch)
                && !hasValue(layer.rValueEffective)
            ) {
                errors.push({
                    name: "sectionKFactor", 
                    text: translations.neitherTheK_FactorNorTheR_ValueAreEnteredForTheDividerMaterial_
                });
            }
        }

        if (!hasValue(layer.rValueEffective)) {
            errors.push({
                name: "rValueEffective", 
                text: translations.theEffectiveR_ValueIsNotCalculatedOrEntered_
            });
        }
        
        return errors;
    };

    const recalculateConstruction = (
        state: IFormState<IConstructionLayer>,
        formInput: IConstructionLayerFormParams | null
    ): IFormState<IConstructionLayer> => {

        const materialId = formInput?.materialId ?? (state.actualValues.materialId ?? state.defaultValues.materialId);
        const material = getBoxMaterial(Number(materialId));
        const materialName = getBoxMaterialName(material);
        
        const thicknessField = ConstructionLayer.getThickness(
            state,
            formInput?.thickness,
            material);

        const kFactorField = ConstructionLayer.getKFactor(
            state,
            formInput?.kFactor,
            formInput?.rValuePerInch,
            formInput?.rValueEffective,
            material);

        const rValuePerInchField = ConstructionLayer.getRValuePerInch(
            state,
            formInput?.rValuePerInch,
            formInput?.kFactor,
            formInput?.rValueEffective,
            material);

        const isSectional = formInput?.isSectional ?? state.actualValues.isSectional;

        const sectionMaterialId = isSectional
            ? formInput?.sectionMaterialId ?? (state.actualValues.sectionMaterialId ??  state.defaultValues.sectionMaterialId)
            : "0";
            
        const sectionMaterial = getBoxMaterial(Number(sectionMaterialId));
        const sectionMaterialName = getBoxMaterialName(sectionMaterial);

        const name = `${sectionMaterial ? `${sectionMaterialName} | ` : ""}${materialName}`

        const sectionCentreDistanceField = ConstructionLayer.getSectionCentreDistance(
            state,
            formInput?.sectionCentreDistance,
            isSectional);

        const sectionWidthField = ConstructionLayer.getSectionWidth(
            state,
            formInput?.sectionWidth,
            sectionMaterial,
            isSectional);

        const sectionKFactorField = ConstructionLayer.getSectionKFactor(
            state,
            formInput?.sectionKFactor,
            formInput?.sectionRValuePerInch,
            formInput?.rValueEffective,
            sectionMaterial,
            isSectional);

        const sectionRValuePerInchField = ConstructionLayer.getSectionRValuePerInch(
            state,
            formInput?.sectionRValuePerInch,
            formInput?.sectionKFactor,
            formInput?.rValueEffective,
            sectionMaterial,
            isSectional);

        const rValueEffectiveField = ConstructionLayer.getRValueEffective(
            state,
            formInput?.rValueEffective,
            thicknessField.actualValue,
            rValuePerInchField.actualValue,
            isSectional,
            sectionRValuePerInchField.actualValue,
            sectionCentreDistanceField.actualValue,
            sectionWidthField.actualValue,
            material,
            sectionMaterial
        );

        const defaultValues: IConstructionLayer = {
            ...state.defaultValues,
            thickness: thicknessField.defaultValue,
            kFactor: kFactorField.defaultValue,
            rValuePerInch: rValuePerInchField.defaultValue,
            sectionCentreDistance: sectionCentreDistanceField.defaultValue,
            sectionWidth: sectionWidthField.defaultValue,
            sectionKFactor: sectionKFactorField.defaultValue,
            sectionRValuePerInch: sectionRValuePerInchField.defaultValue,
            rValueEffective: rValueEffectiveField.defaultValue
        };

        const actualValues: IConstructionLayer = {
            ...state.actualValues,
            name,
            materialId,
            materialName,
            thickness: thicknessField.actualValue,
            kFactor: kFactorField.actualValue,
            rValuePerInch: rValuePerInchField.actualValue,
            isSectional,
            sectionMaterialId,
            sectionMaterialName,
            sectionCentreDistance: sectionCentreDistanceField.actualValue,
            sectionWidth: sectionWidthField.actualValue,
            sectionKFactor: sectionKFactorField.actualValue,
            sectionRValuePerInch: sectionRValuePerInchField.actualValue,
            rValueEffective: rValueEffectiveField.actualValue
        };

        const errors = validateLayer(actualValues);
        
        return {
            defaultValues,
            actualValues: {
                ...actualValues,
                errors
            }
        };
    };

    const resetForm = (): IFormState<IConstructionLayer> => {
        return initialFormState;
    };

    const resetFormForEdit = (
        constructionLayerToEdit: IConstructionLayer
    ): IFormState<IConstructionLayer> => {
        
        const defaultValues: IConstructionLayer = {
            ...initialValues
        };

        const actualValues = {
            ...constructionLayerToEdit
        };

        return recalculateConstruction({
            defaultValues,
            actualValues
        },
        null);
    };
    
    const setInitialState = (
        constructionLayerToEdit: IConstructionLayer | undefined
    ): IFormState<IConstructionLayer> => {

        if (constructionLayerToEdit === undefined) {
            return resetForm();
        }

        return resetFormForEdit(constructionLayerToEdit);
    };

    return {
        initialFormState,
        resetForm,
        resetFormForEdit,
        recalculateConstruction,
        setInitialState,
        validateLayer
    };
};

export default useConstructionLayerForm;
