/* eslint-disable no-underscore-dangle */
import * as React from "react";

import Button from "../helpers/Button";
import Checkbox from "../helpers/Checkbox";
import DropDownList from "../helpers/DropDownList";
import FormErrorNotification from "../helpers/FormErrorNotification";
import FormField from "../helpers/FormField";
import Measurement from "../helpers/Measurement";
import Modal from "../helpers/Modal";

import { hasValue } from "../../types/measure";

import IConstructionLayer from "../../models/interfaces/IConstructionLayer";
import IMessage from "../../models/interfaces/IMessage";

import UnitOfMeasure from "../../models/helpers/UnitOfMeasure";

import MeasurementType from "../../enums/MeasurementType";
import * as MeasurementUnit from "../../enums/MeasurementUnit";

import useBoxMaterialsContext from "../../hooks/useBoxMaterialsContext";
import useConstructionLayerReducer from "../../hooks/useConstructionLayerReducer";
import useConstructionLayerForm from "../../hooks/useConstructionLayerForm";
import useTranslationContext from "../../hooks/useTranslationContext";

import "./ConstructionLayerModal.css";

interface ConstructionLayerModalProps {
    show: boolean;
	onOkClick: any;
    onCancelClick: any;
    layerToEdit?: IConstructionLayer;
}

const ConstructionLayerModal: React.FunctionComponent<ConstructionLayerModalProps> = (
	props: ConstructionLayerModalProps
) => {
    
    const [show, setShow] = React.useState(false);
    const [errorMessages, setErrorMessages] = React.useState([] as IMessage[]);
    
    const { boxMaterials } = useBoxMaterialsContext();
    const { translations } = useTranslationContext();
    
    const { formState, dispatch } = useConstructionLayerReducer();
    const { validateLayer } = useConstructionLayerForm();

    const showErrors = (
        errors: IMessage[],
        currentFieldError?: IMessage
    ) => {
        let newList = [] as IMessage[];
        
        const currentList = [...errorMessages];
        currentList.forEach(c => {
            const errorText = errors.find(e => e.name === c.name)?.text ?? "";
            newList.push({
                name: c.name,
                text: errorText
            });
        });

        if (currentFieldError) {
            if (currentList.find(c => c.name === currentFieldError.name)) {
                newList = newList.map(n => {
                    return {
                        name: n.name,
                        text: (n.name === currentFieldError.name) ? currentFieldError.text : n.text
                    }
                });
            }
            else {
                newList.push(currentFieldError);
            }
        }
    
        setErrorMessages(newList);
    };

    const validateField = (
        fieldName: string,
        fieldValue: any
        ) => {

        const layer = {
            ...formState.actualValues,
            [fieldName]: fieldValue
        };

        const errors = validateLayer(layer);

        const fieldError: IMessage = {
            name: fieldName,
            text: errors.find(e => e.name === fieldName)?.text ?? ""
        };
        
        showErrors(errors, fieldError);
        
        return fieldError.text;
    };
    
    React.useEffect(() => {       
        setShow(props.show);
        if (props.show) {
            dispatch({ type: "set-initial-state", formValues: props.layerToEdit });
        }

        if (props.layerToEdit) {
            setErrorMessages(props.layerToEdit.errors ?? []);
        }
        else {
            setErrorMessages([]);
        }
    }, [props.show]);

    const isAddMode = !props.layerToEdit;

    const handleCloseClick = () => {
        setShow(false);
        if (props.onCancelClick) {
            props.onCancelClick();
        }
    };

    const handleOkClick = () => {
        setShow(false);
        if (props.onOkClick) {
            if (props.layerToEdit
                && (props.layerToEdit.isBoundaryInteriorLayer 
                    || props.layerToEdit.isBoundaryExteriorLayer)
            ) {
                formState.actualValues = {
                    ...props.layerToEdit,
                    rValueEffective: formState.actualValues.rValueEffective
                };
            }
            
            props.onOkClick(formState.actualValues, isAddMode);
        }
    };

    const handleDropdownChange = (event: React.FormEvent<HTMLSelectElement>) => {
		const fieldName = event.currentTarget.name;
        const fieldValue = event.currentTarget.value;

        dispatch({ type: "recalculate-form", fieldName, fieldValue });

        validateField(fieldName, fieldValue);
	}

    const handleMeasurementChange = (fieldName: string, fieldValue: any) => {
        dispatch({ type: "recalculate-form", fieldName, fieldValue });
    };

    const handleCheckboxChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
        const fieldName = event.currentTarget.name;
        const isChecked = event.currentTarget.checked;

        dispatch({ type: "recalculate-form", fieldName, fieldValue: isChecked });

        validateField(fieldName, isChecked);
    };

    const layerName = (formState.actualValues.isBoundaryInteriorLayer || formState.actualValues.isBoundaryExteriorLayer)
        ? formState.actualValues.name
        : translations.insulation;

    const isThicknessFixed = hasValue(formState.actualValues.thickness)
        && formState.defaultValues.thickness === formState.actualValues.thickness;

    const materialsDefaultOption = [
        { key: "0", value: translations.pleaseSelect }
    ];

    const materialsLookup = 
        boxMaterials && boxMaterials
            .filter(m => m.showAsBuildingMaterial && !m.isConstruct)
            .map(m => {
                return {
                    key: m.id.toString(),
                    value: `${m.name}${m.example ? `, ${m.example}` : ""}`
                }
            });

    const materialsOtherOptions = [
        { key: "-1000000", value: "Other" }
    ];

    const materialsOptions = [
        ...materialsDefaultOption,
        ...materialsLookup,
        ...materialsOtherOptions
    ];

    const modalBody = (
        <div id="construction-layer-modal" className="content">
            <FormErrorNotification errorMessages={errorMessages} />
            <div className="block">
                <div className="label is-small header">{layerName}</div>
                {!formState.actualValues.isBoundaryInteriorLayer && !formState.actualValues.isBoundaryExteriorLayer && (
                    <>
                        <FormField label={translations.material}>
                            <DropDownList
                                name="materialId"
                                value={formState.actualValues.materialId}
                                dataSource={materialsOptions}
                                onChange={handleDropdownChange}
                            />
                        </FormField>
                        <FormField label={`${translations.thickness} (${UnitOfMeasure.getSmallerDimensionUnitLabel()})`}>
                            <Measurement
                                name="thickness"
                                defaultValue={formState.defaultValues.thickness}
                                actualValue={formState.actualValues.thickness}
                                onChange={handleMeasurementChange}
                                disabled={isThicknessFixed}
                                decimals={2}
                                type={MeasurementType.SmallerDimension}
                                onValidate={validateField}
                            />
                        </FormField>
                        <FormField label={translations.k_Factor}>
                            <Measurement
                                name="kFactor"
                                defaultValue={formState.defaultValues.kFactor}
                                actualValue={formState.actualValues.kFactor}
                                onChange={handleMeasurementChange}
                                decimals={3}
                                type={MeasurementType.PositiveDecimal}
                                onValidate={validateField}
                            />
                        </FormField>
                        <FormField label={translations.orR_Value_UnitThickness}>
                            <Measurement
                                name="rValuePerInch"
                                defaultValue={formState.defaultValues.rValuePerInch}
                                actualValue={formState.actualValues.rValuePerInch}
                                onChange={handleMeasurementChange}
                                decimals={3}
                                type={MeasurementType.PositiveDecimal}
                                onValidate={validateField}
                            />
                        </FormField>
                        <FormField label="">
                            <Checkbox 
                                name="isSectional"
                                label={translations.sectional_E_g_StudWall_}
                                value="1"
                                isChecked={formState.actualValues.isSectional}
                                onChange={handleCheckboxChange}
                            />
                        </FormField>
                    </>
                )}
                {formState.actualValues.isSectional && (
                    <>
                        <FormField label={translations.centreDistance}>
                            <Measurement
                                name="sectionCentreDistance"
                                defaultValue={formState.defaultValues.sectionCentreDistance}
                                actualValue={formState.actualValues.sectionCentreDistance}
                                onChange={handleMeasurementChange}
                                decimals={3}
                                type={MeasurementType.SmallerDimension}
                                onValidate={validateField}
                            />
                        </FormField>
                        <div className="label is-small header">{translations.sectionDivider}</div>
                        <FormField label={translations.material}>
                            <DropDownList
                                name="sectionMaterialId"
                                value={formState.actualValues.sectionMaterialId}
                                dataSource={materialsOptions}
                                onChange={handleDropdownChange}
                            />
                        </FormField>
                        <FormField label={translations.width}>
                            <Measurement
                                name="sectionWidth"
                                defaultValue={formState.defaultValues.sectionWidth}
                                actualValue={formState.actualValues.sectionWidth}
                                onChange={handleMeasurementChange}
                                decimals={3}
                                type={MeasurementType.SmallerDimension}
                                onValidate={validateField}
                            />
                        </FormField>
                        <FormField label={translations.k_Factor}>
                            <Measurement
                                name="sectionKFactor"
                                defaultValue={formState.defaultValues.sectionKFactor}
                                actualValue={formState.actualValues.sectionKFactor}
                                onChange={handleMeasurementChange}
                                decimals={3}
                                type={MeasurementType.PositiveDecimal}
                                onValidate={validateField}
                            />
                        </FormField>
                        <FormField label={translations.orR_Value_UnitThickness}>
                            <Measurement
                                name="sectionRValuePerInch"
                                defaultValue={formState.defaultValues.sectionRValuePerInch}
                                actualValue={formState.actualValues.sectionRValuePerInch}
                                onChange={handleMeasurementChange}
                                decimals={3}
                                type={MeasurementType.PositiveDecimal}
                                onValidate={validateField}
                            />
                        </FormField>
                    </>
                )}
                <FormField label={translations.effectiveR_Value}>
                    <Measurement
                        name="rValueEffective"
                        defaultValue={formState.defaultValues.rValueEffective}
                        actualValue={formState.actualValues.rValueEffective}
                        onChange={handleMeasurementChange}
                        decimals={3}
                        type={MeasurementType.PositiveDecimal}
                        onValidate={validateField}
                    />
                </FormField>                                
            </div>
        </div>
    );

    const modalFooter = (
        <>
            <Button
                name="submit-layer"
                label={translations.oK}
                className="button is-link"
                onClick={handleOkClick}
            />
            <Button
                name="cancel-layer"
                label={translations.cancel}
                className="button is-primary"
                onClick={handleCloseClick}
            />
        </>
    );
    
    return (
        <Modal 
            show={show} 
            title={translations.constructionLayer}
            body={modalBody}
            footer={modalFooter}
            onCancelClick={handleCloseClick}
        />
    );
};

export default ConstructionLayerModal;
