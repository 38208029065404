import { measure, getNumericValue, hasValue } from "../../types/measure";
import IConstructionLayer from "../interfaces/IConstructionLayer";

export const calculateRValueEffective = (
    thickness: measure,
    rValuePerInch: measure,
    isSectional: boolean,
    sectionRValuePerInch: measure,
    sectionCentreDistance: measure,
    sectionWidth: measure
): measure => {
    if (!hasValue(thickness)
        || !hasValue(rValuePerInch)
    ) {
        return undefined;
    }

    if (isSectional) {
        if (!hasValue(sectionRValuePerInch)
            || !hasValue(sectionCentreDistance)
            || !hasValue(sectionWidth)
        ) {
            return undefined;
        }

        const f1 = (getNumericValue(sectionCentreDistance) - getNumericValue(sectionWidth)) / getNumericValue(sectionCentreDistance);
        const f2 = 1 - f1;
        const c1 = f1 / getNumericValue(rValuePerInch);
        const c2 = f2 / getNumericValue(sectionRValuePerInch);
        const r = 1 / (c1 + c2);

        return r * getNumericValue(thickness);
    }
    
    return getNumericValue(thickness) * getNumericValue(rValuePerInch);
};

export const calculateKFactor = (
    rValuePerInch: measure
): measure => {
    if (!hasValue(rValuePerInch)) {
        return undefined;
    }
    
    if (getNumericValue(rValuePerInch) === 0) {
        return 0;
    }
    
    return 1 / getNumericValue(rValuePerInch);
};

export const calculateRValuePerInch = (
    kFactor: measure
): measure => {
    if (!hasValue(kFactor)) {
        return undefined;
    }
    
    if (getNumericValue(kFactor) === 0) {
        return 0;
    }
    
    return 1 / getNumericValue(kFactor);
};

export const calculateConstructionThickness = (layers: IConstructionLayer[]) => { 
    return layers.reduce((sum, current) => { 
        return sum + (current.thickness ? getNumericValue(current.thickness) : 0);
    }, 0)
};

export const calculateConstructionRValue = (layers: IConstructionLayer[]) => { 
    return layers.reduce((sum, current) => { 
        return sum + (current.rValueEffective ? getNumericValue(current.rValueEffective) : 0);
    }, 0);
};

export const calculateConstructionKFactor = (thickness: measure, rValue: measure) => {
    if (!hasValue(thickness)
        || !hasValue(rValue)
    ) {
        return undefined;
    }
    return getNumericValue(thickness) / getNumericValue(rValue);
};
