import { measure, hasValue, getNumericValue, roundOff } from "../../types/measure";

import ISection from "../interfaces/ISection";
import ISystem from "../interfaces/ISystem";

import SectionType from "../../enums/SectionType";

export const calculateRunTimeHours = (
    interiorTemperature: measure,
    runTimeAppData: any
): measure => {
    let runTimeHours = runTimeAppData?.h3?.value;
    if (hasValue(interiorTemperature)) {
        if (getNumericValue(interiorTemperature) >= runTimeAppData?.Temp1?.value) {
            runTimeHours = runTimeAppData?.h1?.value;
        }
        else if (getNumericValue(interiorTemperature) >= runTimeAppData?.Temp2?.value) {
            runTimeHours = runTimeAppData?.h2?.value;
        }
    }
    return runTimeHours;
};


export const calculateFloorArea = (
    sections: ISection[]
): measure => {
    let sectionsFloorArea = 0;
    sections.forEach(s => {
        if (s.sectionType === SectionType.FloorCustom 
            || s.sectionType === SectionType.FloorPolygon) {
                if (hasValue(s.area)) {
                    sectionsFloorArea += getNumericValue(s.area);
                }
            }
    })
    if (sectionsFloorArea === 0) {
        return undefined;
    }
    return sectionsFloorArea;
};

export const calculateVolume = (
    floorArea: measure,
    boxHeight: measure,
    hasCustomSection: boolean
): measure => {
    if (!hasValue(floorArea)
        || !hasValue(boxHeight)
    ) {
        return undefined;
    }

    if (hasCustomSection) {
        return undefined;
    }

    return getNumericValue(floorArea) * getNumericValue(boxHeight);
}

export const calculateTotalWallLoad = (
    sections: ISection[]
): measure => {
    let sectionsWallLoad = 0;
    sections.forEach(s => {
        if (hasValue(s.conductionLoad)) {
            sectionsWallLoad += getNumericValue(s.conductionLoad);
        }
    })
    if (sectionsWallLoad === 0) {
        return undefined;
    }
    return sectionsWallLoad;
};

export const calculateOpeningsTotalInfiltrationLoad = (
    sections: ISection[]
): measure => {
    let totalInfiltrationLoad = 0;
    sections.forEach(s => {
        s.openings?.forEach(o => {
            if (hasValue(o.totalInfiltrationLoad)) {
                totalInfiltrationLoad += getNumericValue(o.totalInfiltrationLoad);
            }
        });
    })
    return totalInfiltrationLoad;
};

export const calculateTotalHourlyLoad = (
    totalWallLoad: measure,
    totalInfiltrationLoad: measure,
    totalProductLoad: measure,
    totalMiscLoad: measure
): measure => {
    let totalHourlyLoad = 0;
    if (hasValue(totalWallLoad)) {
        totalHourlyLoad += getNumericValue(totalWallLoad);
    }
    if (hasValue(totalInfiltrationLoad)) {
        totalHourlyLoad += getNumericValue(totalInfiltrationLoad);
    }
    if (hasValue(totalProductLoad)) {
        totalHourlyLoad += getNumericValue(totalProductLoad);
    }
    if (hasValue(totalMiscLoad)) {
        totalHourlyLoad += getNumericValue(totalMiscLoad);
    }

    if (totalHourlyLoad > 0) {
        return totalHourlyLoad;
    }

    return undefined;
};

export const calculateTotalLoadRequired = (
    safetyFactor: measure,
    totalHourlyLoad: measure
): measure => {
    if (!hasValue(safetyFactor)
        || !hasValue(totalHourlyLoad)
    ) {
        return undefined;
    }

    const totalLoadRequired = getNumericValue(totalHourlyLoad) * (1 + (getNumericValue(safetyFactor) / 100));

    if (totalLoadRequired > 0) {
        return totalLoadRequired;
    }

    return undefined;
};

export const calculateCapacityRequired = (
    totalLoadRequired: measure,
    runTimeHours: measure
): measure => {
    if (!hasValue(totalLoadRequired)
        || !hasValue(runTimeHours)
    ) {
        return undefined;
    }

    const capacityRequired = getNumericValue(totalLoadRequired) * 24 / getNumericValue(runTimeHours);

    if (capacityRequired > 0) {
        return capacityRequired;
    }

    return undefined;
};

export const calculateLoadPercentage = (
    system: ISystem
) => {
    const {totalWallLoad, totalInfiltrationLoad, totalProductLoad, totalMiscLoad} = system;
    
    let wallLoad: measure = 0;
    let infiltrationLoad: measure = 0;
    let productLoad:measure = 0;
    let miscLoad: measure = 0;

    const total = (totalWallLoad ?? 0) + (totalInfiltrationLoad ?? 0) + (totalProductLoad ?? 0) + (totalMiscLoad ?? 0);

    if (totalWallLoad && total) {
        wallLoad = roundOff(totalWallLoad / total * 100, 0);
    }

    if (totalInfiltrationLoad && total) {
        infiltrationLoad = roundOff(totalInfiltrationLoad / total * 100, 0);
    }

    if (totalProductLoad && total) {
        productLoad = roundOff(totalProductLoad / total * 100, 0);
    }

    if (totalMiscLoad && total) {
        miscLoad = roundOff(totalMiscLoad / total * 100, 0);
    }

    return {
        wallLoad,
        infiltrationLoad,
        productLoad,
        miscLoad
    };
};