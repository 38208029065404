/* eslint-disable no-underscore-dangle */
import * as React from "react";

import { Config } from "../../Constants";
import ConfigurationService from "../../services/ConfigurationService";
import CertifiedPrintService from "../../services/CertifiedPrintService";

import useLoadingContext from "../../hooks/useLoadingContext";
import useTranslationContext from "../../hooks/useTranslationContext";

import IEquipmentOption from "../../models/interfaces/IEquipmentOption";
import IEquipmentState from "../../models/interfaces/IEquipmentState";
import IEquipmentUnitAttributes from "../../models/interfaces/IEquipmentUnitAttributes";
import IEquipmentUnitItem from "../../models/interfaces/IEquipmentUnitItem";
import IOption from "../../models/interfaces/IOption";
import IOptionGroup from "../../models/interfaces/IOptionGroup";

import * as OptionUtils from "../EquipmentOptions/EquipmentOptions.utils";

const table = {
	border: "1px solid black"
} as React.CSSProperties;

const getCertifiedPrintValuesAsync = (
	equipmentState: IEquipmentState,
	equipment: IEquipmentUnitItem, 
	selectedOptions: IEquipmentOption[]
) => {
	return new Promise<any>((resolve, reject) => {
		try {
			CertifiedPrintService.getCertifiedPrintValues(
				equipmentState,
				equipment,
				selectedOptions,
				(data) => {
					resolve(data);
				}
			);
		}
		catch (error) {
			reject(error);
		}
	});
};

interface ModelInfoProps {	
	sectionId: string;
	divisionId: number;
	jobName: string;
	customerName: string;
	myName: string;
	equipmentInfo: IEquipmentState;
	isCondensingUnit: boolean;
	selectedUnitItem: IEquipmentUnitItem | null; 
    selectedOptionList: IEquipmentOption[];
    excludedOptionIds: string | null;
    selectedUnitAttributes: IEquipmentUnitAttributes | null;
    isPrintSingleEquipment: boolean;
    quoteFormInput: any;
    itemNumber: number | null;
}

const ModelInfo: React.FunctionComponent<ModelInfoProps> = (props: ModelInfoProps) => {		

	const [cp, setCP] = React.useState({} as any);
	const [lblLiquid, setLblLquid] = React.useState("");	
	const [availableOptions, setAvailableOptions] = React.useState([] as IOptionGroup[]);
	const [approvalImages, setApprovalImages] = React.useState([] as Array<string>);
	const [numberOfImgRows, setNumberOfImgRows] = React.useState(0);
	const [imgRows, setImgRows] = React.useState([] as Array<any>);

	const { showLoading, hideLoading } = useLoadingContext();
	const { translations } = useTranslationContext();

	const getLiquidLabel = (
		class0Id:number
	): string => {
		if (class0Id === 8 || class0Id === 1) {
			return translations.dISTRIBUTOR;
		}
		return translations.lIQUID;
	}

	const loadApprovalImg = (
		approvalImgNumber: number
	) => {
		approvalImages.length = 0;
		for (let i = 1; i < 10; i += 1){
			// eslint-disable-next-line no-bitwise
			if ((approvalImgNumber & (2 ** (i-1))) > 0){
				if (i>=1){
					approvalImages.push(ConfigurationService.getApprovalImageByNum(i, false));
				}
			}
		}

		const numOfRows: number = Math.ceil(approvalImages.length / 2);
		setNumberOfImgRows(numOfRows);

		const _imgRows: Array<any> = [];

		_imgRows.length = 0;
		for (let i = 0; i < numOfRows; i += 1) {
			if (i*2 + 1 <= approvalImages.length - 1) {
				_imgRows.push(
					<tr key={i}>
						<td style={{verticalAlign:"middle"}}>
							<img src={approvalImages[i*2]} height="35px" width="35px" alt="" />
						</td>
						<td style={{verticalAlign:"middle"}}>
							<img src={approvalImages[i*2 + 1]} height="35px" width="35px" alt="" />
						</td>
					</tr>
				);
			}
			else{
				_imgRows.push(
					<tr key={i}>
						<td style={{verticalAlign:"middle"}}>
							<img src={approvalImages[i*2]} height="35px" width="35px" alt="" />
						</td>
						<td>
							&nbsp;
						</td>
					</tr>
				);
			}
		}
		
		setApprovalImages([]);
		setApprovalImages(approvalImages);
		setImgRows([]);
		setImgRows(_imgRows);
	}

	React.useEffect(() => {
		const loadData = async () => {
			if (props.selectedUnitItem && props.selectedUnitItem.class9ID && props.selectedOptionList) {
				try {
					//showLoading();
                    
                    const options = await OptionUtils.getAvailableOptions(
                        props.divisionId,
                        props.selectedUnitItem.class9ID,
                        props.selectedOptionList,
                        props.excludedOptionIds);
                    setAvailableOptions(options);
                    
                    const certifiedPrintValues = await getCertifiedPrintValuesAsync(
                        props.equipmentInfo,
                        props.selectedUnitItem,
                        props.selectedOptionList);
                    setCP(certifiedPrintValues);
                    setLblLquid(getLiquidLabel(props.selectedUnitItem.class0ID ?? 2));
                    loadApprovalImg(certifiedPrintValues.Approvals);                    

					//hideLoading();
				}
				catch (error: any) {
					console.log(`An error was encountered while loading the options. ${error.message ?? ""}`, error);
					hideLoading();
				}
			}
		};

		loadData();
	},[
		props.divisionId,
		props.equipmentInfo,
		props.selectedUnitItem,
		props.selectedOptionList
    ]);

    //console.log("props", props);
    //console.log("unit:", props.selectedUnitItem);
	
    return (        
        <div id={props.sectionId} className="model-info" style={{ width: "1070px" }}>            
			<table
				className="table is-bordered is-narrow is-fullwidth no-margin-table"
				style={table}
			>					
				<tbody>
					<tr>
						<td style={{ textAlign: "center", fontWeight: "bold" }} className="two-columns">								
							<table className="table is-borderless" style={{ width: "100%", border: "0" }}>
								<tbody>
									<tr>
										<td>
											<img
												src={ConfigurationService.getDivisionLogo(props.divisionId)}
												alt={ConfigurationService.getDivisionLogoAltString(props.divisionId)}
												style={{ width: "160px", marginTop: "10px" }}
											/>
										</td>
										<td>
											<div style={{fontSize: "13px", fontWeight:"bold"}}>NATIONAL REFERIGERATION AND</div> 
											<div style={{fontSize: "13px", fontWeight:"bold"}}>AIR CONDITIONING CANADA CORP.</div>
											<div style={{fontSize: "11px", fontWeight:"normal"}}>
												159 ROY BLVD. PO BOX 2020
											</div>
											<div style={{fontSize: "11px", fontWeight:"normal"}}>
												BRANTFORD ON
											</div>
											<div style={{fontSize: "11px", fontWeight:"normal"}}>
												CANADA N3T 5Y6
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
						<td style={{ textAlign: "center", fontWeight: "bold" }} className="two-columns">
							<div style={{ marginTop: "10px"}}>
								<div style={{fontSize: "21px", marginTop: "5px"}}>
									{props.selectedUnitItem?.partNumber}
								</div>
								<div style={{fontSize: "14px"}}>
									{props.selectedUnitItem?.description}
									<br />
                                    {props.isPrintSingleEquipment === false ? (props.isCondensingUnit && props.equipmentInfo ? props.equipmentInfo.equipmentType : "Evaporator"):""}
                                    {props.isPrintSingleEquipment ? props.selectedUnitItem?.type : ""}
								</div>
							</div>
						</td>
					</tr>						
				</tbody>
			</table>
			<table className="table is-narrow is-fullwidth no-margin-table" style={table}>
				<tbody>
					<tr>
						<td style={{ textAlign: "right", borderRight: "0"}}>PURCHASER:</td>
                        <td style={{ borderLeft: "0", borderRight: "0", textAlign: "left" }}><b>{props.isPrintSingleEquipment ? props.quoteFormInput?.Purchaser:props.customerName}</b></td>
						<td style={{ textAlign: "right", borderRight: "0", borderLeft: "0"}}>SUBMITTED BY:</td>
						<td style={{ borderLeft: "0", textAlign: "left"}}><b>{props.myName}</b></td>
					</tr>
					<tr>
						<td style={{ textAlign: "right", borderRight: "0"}}>PROJECT:</td>
                        <td style={{ borderLeft: "0", borderRight: "0", textAlign: "left" }}><b>{props.isPrintSingleEquipment ? props.quoteFormInput?.Project:props.jobName}</b></td>
						<td style={{ textAlign: "right", borderRight: "0", borderLeft: "0"}}>DATE:</td>
						<td style={{ borderLeft: "0", textAlign: "left"}}><b>{new Date().toLocaleDateString ()}</b></td>
					</tr>
					<tr>
						<td style={{ textAlign: "right", borderRight: "0"}}>ORDER #:</td>
						<td style={{ borderLeft: "0", borderRight: "0", textAlign: "left"}}><b>-A00</b></td>
						<td style={{ textAlign: "right", borderRight: "0", borderLeft: "0"}}>ITEM #:</td>
                        <td style={{ borderLeft: "0", textAlign: "left" }}><b>{props.itemNumber}</b></td>
					</tr>
					<tr>
						<td style={{ textAlign: "right", borderRight: "0"}}>QUOTE #:</td>
                        <td style={{ borderLeft: "0", borderRight: "0", textAlign: "left" }}>{props.isPrintSingleEquipment ? props.quoteFormInput?.QuoteNumber : ""}&nbsp;</td>
						<td style={{ textAlign: "right", borderRight: "0", borderLeft: "0"}}>ID #:</td>
						<td style={{ borderLeft: "0", textAlign: "left"}}>{props.selectedUnitAttributes?.taggingId}</td>
					</tr>
					<tr>
						<td style={{ textAlign: "right", borderRight: "0"}}>PURCHASER&apos;S PO#:</td>
                        <td style={{ borderLeft: "0", borderRight: "0", textAlign: "left" }}>{props.isPrintSingleEquipment ? props.quoteFormInput?.PO : ""}&nbsp;</td>
						<td style={{ textAlign: "right", borderRight: "0", borderLeft: "0"}}>TAGGING:</td>
						<td style={{ borderLeft: "0", textAlign: "left"}}>{props.selectedUnitAttributes?.tagging}</td>
					</tr>
				</tbody>
			</table>
			<table className="table is-narrow is-fullwidth no-margin-table" style={table}>					
				<tbody>	
					<tr>
						<td className="header2" style={{ borderBottom: "0"}} colSpan={2}>							
								{translations.standardFeatures}
						</td>
					</tr>					
					<tr>
						<td style={{borderRight: "0", borderTop: "0", padding: "5px", margin: "0"}}>&nbsp;</td>
						<td style={{borderLeft: "0", borderTop: "0", paddingLeft: "4"}}>
                            <ul style={{ listStyleType: "disc" }} className="feature-columns">
								{props.selectedUnitItem?.features?.map((x: any, i: number) => {
									const key = i;
									return (
										<li key={key}>{x.featureDescription}</li>
									);
								})}
							</ul>								
						</td>
					</tr>
				</tbody>
			</table>
			<table className="table is-narrow is-fullwidth no-margin-table" style={table}>
				<tbody>
					<tr>
						<td style={{ textAlign: "center", fontWeight: "bold", borderBottom: "0" }}>
							{translations.mODELOPTIONS}
						</td>
					</tr>
					<tr>
						<td style={{ borderTop: "0", padding: "20px" }}>
							<div className="option-columns">
							{availableOptions &&
								availableOptions.map((optionGroup: IOptionGroup, index: number) => {
									const listKey = index;
									const boldClassName = " has-text-weight-bold is-6 group-name";
									const selectedDropdownOption =
										optionGroup.isListOption &&
										optionGroup.options &&
										optionGroup.options.filter(o => o.isChecked).length > 0
											? optionGroup.options.filter(o => o.isChecked)[0].name
											: "";
									return (
										<div key={listKey} className="option-group">
											{optionGroup.isListOption && selectedDropdownOption !== "" && (
												<>
													<div className={`is-uppercase${boldClassName}`}>
														{optionGroup.isVisible && optionGroup.groupName}
													</div>
													<div>
														<span className="option-control">
															<span className="option-control-print">
																{optionGroup.selectedQuantity > 0 
																	? optionGroup.selectedQuantity 
																	: (<span>&nbsp;</span>)}
															</span>
														</span>
														<span className="option-name">
															{selectedDropdownOption}
														</span>
													</div>
												</>
											)}
											{!optionGroup.isListOption &&
												optionGroup.options && (
													<div className={`is-uppercase${boldClassName}`}>
														{optionGroup.isVisible && optionGroup.groupName}
													</div>
											)}
											{!optionGroup.isListOption &&
												optionGroup.options && 
												optionGroup.options.map((option: IOption) => (
													<div key={option.optionId}>
														<span className="option-control">
															<span className="option-control-print">
																{option.selectedQuantity > 0 
																	? option.selectedQuantity 
																	: (<span>&nbsp;</span>)}
															</span>
														</span>
														<span className={`option-name${option.isBold ? boldClassName : ""}`}>
															{option.name}
														</span>
													</div>
												))}
										</div>
									);
								})}
							</div>
						</td>
					</tr>
				</tbody>
			</table>				
			<table className="table is-narrow is-fullwidth no-margin-table" style={table}>
				<tbody>
					<tr>
						<td rowSpan={numberOfImgRows + 1} style={{ width: "90%", borderLeft: "1px solid black" }}>
							<table style={{border:"none"}}>
								<tbody>
									<tr>
										<td style={{border:"none"}}>
											<u>{translations.notes}</u>
											:
										</td>
										<td style={{fontWeight:"bold", border:"none"}}>
											{
												cp.notes && 
												cp.notes.map((x: any, i: number) => {
													const key = i;
													return (
														<p key={key}>{x}</p>
													);
												})
											}
											{props.selectedUnitAttributes?.notes}
										</td>
									</tr>
								</tbody>
							</table>
						</td>
						<td colSpan={2} style={{verticalAlign:"middle"}}>
							{translations.aPPROVALS}
						</td>						
					</tr>	
					{
						imgRows.length > 0 && imgRows
					}					
				</tbody>
			</table>
			<table className="table is-narrow is-fullwidth no-margin-table" style={table}>
				<tbody>
					<tr>
						<td style={{width:"70%", lineHeight:"30px"}} className="aligh-left"><b>{translations.aPPROVEDBY_}</b></td>
						<td style={{width:"30%", lineHeight:"30px"}} className="aligh-left"><b>{translations.dATE_}</b></td>
					</tr>
					<tr>
						<td colSpan={2}>
							{translations.approvalOfThisDrawingSignifiesThatTheEquipmentIsAcceptableUnderTheProvisionOfTheJobSpecifications_AnyChangeMadeHereonByAnyPersonWhomsoeverSubjectToAcceptanceByNATIONALREFRIGERATIONAtItsHomeOffice_}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default ModelInfo;
