import * as React from "react";

import IMaterial from "../models/interfaces/IMaterial";

const BoxMaterialsContext = React.createContext({
    boxMaterials: [] as IMaterial[],
    isMaterialListLoaded: false,
    updateBoxMaterials: (materials: IMaterial[]) => {}
});

export default BoxMaterialsContext;