/* eslint-disable no-underscore-dangle */
import * as React from "react";

import IOpening from "../../models/interfaces/IOpening";
import ISection from "../../models/interfaces/ISection";
import ISystem from "../../models/interfaces/ISystem";

import FloorType from "../../enums/FloorType";
import Radians from "../../enums/Radians";
import SectionLocation from "../../enums/SectionLocation";
import SectionType from "../../enums/SectionType";
import WallType from "../../enums/WallType";

import ConfigurationService from "../../services/ConfigurationService";
import UnitOfMeasure from "../../models/helpers/UnitOfMeasure";
import { calculateLoadPercentage } from "../../models/helpers/LoadCalculations";
import { calculateDoorOpenTimeHours } from "../../models/helpers/OpeningCalculations";

import { getLocationDescription } from "../../models/helpers/PrintPreview";
import { getNumericValue, hasValue, measure } from "../../types/measure";

import useApplicationConfigs from "../../hooks/useApplicationConfigs";
import useApplicationContext from "../../hooks/useApplicationContext";
import useTranslationContext from "../../hooks/useTranslationContext";

import { refrigerants } from "../EquipmentFilters/EquipmentFilters";

import "./Print.css";

const table = {
	border: "1px solid black"
} as React.CSSProperties;

interface LoadInfoEquipmentSummaryProps {
	sectionId: string;
	divisionId: number;
	myInfo: any;
	customerInfo: any;
    system: ISystem;
}

const LoadInfoEquipmentSummary: React.FunctionComponent<LoadInfoEquipmentSummaryProps> = (
	props: LoadInfoEquipmentSummaryProps
) => {
	
	const { translations } = useTranslationContext();

	const { getOpeningTypeConfig } = useApplicationConfigs();
	const { getCurtain } = useApplicationContext();

	const getPersonnelActivityLabel = (activityId: number) => {
		switch (activityId) {
			case -1:
				return translations.lightWork_;
			case 0:
				return translations.averageWork_;
			case 1:
				return translations.heavyWork_;
			default:
				return "";
		}
	};

	const getPowerUnit = (unitId: number) => {
		switch (unitId) {
			case 0:
				return "W";
			case 1:
				return "Hp";
			case 2:
				return "BTU/hr";
			default:
				return "";
		}
	};

	const getInfiltrationLoadCalcMethod = (
		infiltrationLoad: any
	) => {
		let calcMethod = infiltrationLoad?.calculationMethod ?? 1;
		let load;

		if (calcMethod === 0) {
			calcMethod = 2;
			load = infiltrationLoad?.doorAndOpeningLoad;
			if (load < infiltrationLoad?.volumeUsageInfiltration) {
				calcMethod = 1;
				load = infiltrationLoad?.volumeUsageInfiltration;
			}
			if (load < infiltrationLoad?.ventilationInfiltration) {
				calcMethod = 3;
				load = infiltrationLoad?.ventilationInfiltration;
			}
		}

		return calcMethod;
	}

	const getInfiltrationLoadCalcMethodLabel = (
		infiltrationLoad: any
	) => {
		let calcMethodLabel;

		const calcMethod = getInfiltrationLoadCalcMethod(infiltrationLoad);
		
		switch (calcMethod) {
			case 1:  // Usage
				calcMethodLabel = translations.iNFILTRATIONLOAD_ESTIMATEDBYVOLUMEANDUSAGE;
				break;
			case 2: // Openings
				calcMethodLabel = translations.iNFILTRATIONLOAD_ESTIMATEDBYDOORSANDOPENINGS;
				break;
			case 3: // Ventilation
				calcMethodLabel = translations.iNFILTRATIONLOAD_ESTIMATEDBYVENTILATION;
				break;
			default:
				calcMethodLabel = translations.iNFILTRATIONLOAD_ESTIMATEDBYVOLUMEANDUSAGE;
				break;
		}

		return calcMethodLabel;
	};

	const getInfiltrationLoadEstimated = (
		infiltrationLoad: any
	) => {
		let load;

		const calcMethod = getInfiltrationLoadCalcMethod(infiltrationLoad);
		
		switch (calcMethod) {
			case 1:  // Usage
				load = infiltrationLoad?.volumeUsageInfiltration;
				break;
			case 2: // Openings
				load = infiltrationLoad?.doorAndOpeningLoad;
				break;
			case 3: // Ventilation
				load = infiltrationLoad?.ventilationInfiltration;
				break;
			default:
				load = infiltrationLoad?.volumeUsageInfiltration;
				break;
		}

		return load;
	};

	const getBoxUsage = (id: number) => {
		switch (id) {
			case 1:
				return translations.regularUse;
			case 2:
				return translations.heavyUse;
			case 3:
				return translations.longStorage;
			default:
				return "";
		}
	};

	const round = (value: measure, places: number): string | undefined => {
		if (value !== null && value !== undefined) {
			return Number(value).toFixed(places);
		}
		return undefined;
	};

	const sectionsGridView = (sectionsList: ISection[]) => {
        return sectionsList.reduce((previousValue: ISection[], section: ISection) => {

            previousValue.push(section);

            section.openings?.forEach((opening: IOpening) => {
				const quantityLabel = opening.quantity && getNumericValue(opening.quantity) > 1 ? ` x ${opening.quantity}` : "";
                const openingRow: ISection = {
					sectionName: "",
					materialName: `${opening.description}${quantityLabel}`,
					thickness: undefined,
					kFactor: undefined,
					rValueEffective: opening.rValueEffective,
					exteriorTemperature: opening.exteriorTemperature,
					dimension1: hasValue(opening.width) ? UnitOfMeasure.IntoFt(getNumericValue(opening.width)) : undefined,
					dimension2: hasValue(opening.height) ? UnitOfMeasure.IntoFt(getNumericValue(opening.height)) : undefined,
					area: opening.totalArea,
					areaWithoutOpenings: opening.totalArea,
					conductionLoad: opening.totalConductionLoad,
					conductionLoadWithoutOpenings: opening.totalConductionLoad,
					sectionType: SectionType.Undefined,
					wallType: WallType.Undefined,
					radians: Radians.None,
					sectionLocation: SectionLocation.BoxLocation,
					floorType: FloorType.None,
					materialId: null,
					exteriorHumidity: undefined,
					rValuePerInch: undefined,
					isCustom: true,
					isModified: true
				};

                previousValue.push(openingRow);
            });

            return previousValue;
        }, [] as ISection[]);
    };

	return (	
		<div id={props.sectionId} style={{width:"1070px"}}>
			<table
				className="table is-bordered is-narrow is-fullwidth no-margin-table"
				style={table}
			>					
				<tbody>
					<tr>
						<td style={{ textAlign: "center", fontWeight: "bold" }} className="two-columns">								
							<table className="table is-borderless" style={{ width: "100%", border: "0" }}>
								<tbody>
									<tr>
										<td>
											<img
												src={ConfigurationService.getDivisionLogo(props.divisionId)}
												alt={ConfigurationService.getDivisionLogoAltString(props.divisionId)}
												style={{ width: "160px", marginTop: "10px" }}
											/>
										</td>
										<td>
											<div style={{fontSize: "13px", fontWeight:"bold"}}>NATIONAL REFERIGERATION AND</div> 
											<div style={{fontSize: "13px", fontWeight:"bold"}}>AIR CONDITIONING CANADA CORP.</div>
											<div style={{fontSize: "11px", fontWeight:"normal"}}>
												159 ROY BLVD. PO BOX 2020
											</div>
											<div style={{fontSize: "11px", fontWeight:"normal"}}>
												BRANTFORD ON
											</div>
											<div style={{fontSize: "11px", fontWeight:"normal"}}>
												CANADA N3T 5Y6
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
						<td style={{ textAlign: "center", fontWeight: "bold" }} className="two-columns">
							<h2 style={{ textAlign: "center", marginTop: "5px" }}>
								<span style={{ color: "#000000", fontWeight: "bold", fontSize: "21px" }}>
									{props.system.systemName?.toUpperCase()}
								</span>
							</h2>
						</td>
					</tr>
				</tbody>
			</table>
			<table
				className="table is-bordered is-narrow-cente is-fullwidth no-margin-table"
				style={table}
			>
				<thead>
					<tr>
						<th className="two-columns header1">
							<b>{translations.preparedBy}</b>
						</th>
						<th className="two-columns header1">
							<b>{translations.preparedFor}</b>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td style={{ textAlign: "center", fontWeight: "bold", lineHeight: "17px" }}>
							{props.myInfo?.Contact && (
								<div className="uppercase">
									{props.myInfo.Contact}
								</div>
							)}
							{props.myInfo?.Company && (
								<div className="uppercase">
									{props.myInfo.Company}
								</div>
							)}
							{props.myInfo?.Address1 && (
								<div className="uppercase">
									{props.myInfo.Address1}
								</div>
							)}
							{props.myInfo?.Address2 && (
								<div className="uppercase">
									{props.myInfo.Address2}
								</div>
							)}
							{(props.myInfo?.City || props.myInfo?.Region) && (
								<div className="uppercase">
									{props.myInfo.City ?? ""}
									,&nbsp;
									{props.myInfo.Region ?? ""}
								</div>
							)}
							{(props.myInfo?.Postal || props.myInfo?.Country) && (
								<div className="uppercase">
									{props.myInfo.Postal ?? ""}
									&nbsp;
									{props.myInfo.Country ?? ""}
								</div>
							)}
							{props.myInfo?.Phone && (
								<div>
									Ph:&nbsp;
									{props.myInfo.Phone}
								</div>
							)}
							{props.myInfo?.Fax && (
								<div>
									Fx:&nbsp;
									{props.myInfo.Fax}
								</div>
							)}
							{props.myInfo?.eMail && (
								<div>
									Email:&nbsp;
									{props.myInfo.eMail}
								</div>
							)}
						</td>
						<td style={{ textAlign: "center", fontWeight: "bold", lineHeight: "17px" }}>
							{props.customerInfo?.Contact && (
								<div className="uppercase">
									{props.customerInfo.Contact}
								</div>
							)}
							{props.customerInfo?.Company && (
								<div className="uppercase">
									{props.customerInfo.Company}
								</div>
							)}
							{props.customerInfo?.Address1 && (
								<div className="uppercase">
									{props.customerInfo.Address1}
								</div>
							)}
							{props.customerInfo?.Address2 && (
								<div className="uppercase">
									{props.customerInfo.Address2}
								</div>
							)}
							{(props.customerInfo?.City || props.customerInfo?.Region) && (
								<div className="uppercase">
									{props.customerInfo.City ?? ""}
									,&nbsp;
									{props.customerInfo.Region ?? ""}
								</div>
							)}
							{(props.customerInfo?.Postal || props.customerInfo?.Country) && (
								<div className="uppercase">
									{props.customerInfo.Postal ?? ""}
									&nbsp;
									{props.customerInfo.Country ?? ""}
								</div>
							)}
							{props.customerInfo?.Phone && (
								<div>
									Ph:&nbsp;
									{props.customerInfo.Phone}
								</div>
							)}
							{props.customerInfo?.Fax && (
								<div>
									Fx:&nbsp;
									{props.customerInfo.Fax}
								</div>
							)}
							{props.customerInfo?.eMail && (
								<div>
									Email:&nbsp;
									{props.customerInfo.eMail}
								</div>
							)}
						</td>
					</tr>
				</tbody>
			</table>
			<table
				className="table is-bordered is-narrow-cente is-fullwidth no-margin-table"
				style={table}
			>
				<thead>
					<tr>
						<th className="header1" colSpan={8}>
							<b>{translations.cOOLINGREQUIREMENTS}</b>
						</th>							
					</tr>
				</thead>
				<tbody>
					<tr>
						<td><b>{translations.applicationTitle}</b></td>
						<td><b>{translations.location}</b></td>
						<td><b>{translations.interiorTemperature}</b></td>
						<td><b>{translations.externalTemperature}</b></td>
						<td><b>{translations.internalRH}</b></td>
						<td><b>{translations.externalRH}</b></td>							
					</tr>
					<tr>
						<td>{props.system.boxApplicationName}</td>
						<td>{getLocationDescription(props.system.boxLocation, translations)}</td>
						<td>{`${UnitOfMeasure.getTemperatureDisplayValue(props.system.interiorTemperature, 1)} ${UnitOfMeasure.getTemperatureUnitLabel()}`}</td>							
						<td>{`${UnitOfMeasure.getTemperatureDisplayValue(props.system.exteriorTemperature, 1)} ${UnitOfMeasure.getTemperatureUnitLabel()}`}</td>
						<td>{`${UnitOfMeasure.getDisplayValue(props.system.interiorHumidity, 1)}${UnitOfMeasure.getPercentUnitLabel()}`}</td>
						<td>{`${UnitOfMeasure.getDisplayValue(props.system.exteriorHumidity, 1)}${UnitOfMeasure.getPercentUnitLabel()}`}</td>
					</tr>
					<tr>
						<td colSpan={6} style={{ padding: 0 }} className="no-border">
							<table className="inner-table">
								<tbody>
									<tr>
										<td style={{width: "12.5%", whiteSpace: "nowrap"}}><b>{`${translations.wallLoad} ${calculateLoadPercentage(props.system).wallLoad}${UnitOfMeasure.getPercentUnitLabel()}`}</b></td>
										<td style={{width: "12.5%", whiteSpace: "nowrap"}}><b>{`${translations.infiltrationLoad} ${calculateLoadPercentage(props.system).infiltrationLoad}${UnitOfMeasure.getPercentUnitLabel()}`}</b></td>
										<td style={{width: "12.5%", whiteSpace: "nowrap"}}><b>{`${translations.productLoad} ${calculateLoadPercentage(props.system).productLoad}${UnitOfMeasure.getPercentUnitLabel()}`}</b></td>
										<td style={{width: "12.5%", whiteSpace: "nowrap"}}><b>{`${translations.miscellaneousLoad} ${calculateLoadPercentage(props.system).miscLoad}${UnitOfMeasure.getPercentUnitLabel()}`}</b></td>
									</tr>
									<tr>
										<td style={{width: "12.5%", whiteSpace: "nowrap"}}>{`${UnitOfMeasure.getRatingDisplayValue(props.system.totalWallLoad, 0) ?? 0} ${UnitOfMeasure.getRatingUnitLabel()}`}</td>
										<td style={{width: "12.5%", whiteSpace: "nowrap"}}>{`${UnitOfMeasure.getRatingDisplayValue(props.system.totalInfiltrationLoad, 0) ?? 0} ${UnitOfMeasure.getRatingUnitLabel()}`}</td>
										<td style={{width: "12.5%", whiteSpace: "nowrap"}}>{`${UnitOfMeasure.getRatingDisplayValue(props.system.totalProductLoad, 0) ?? 0} ${UnitOfMeasure.getRatingUnitLabel()}`}</td>
										<td style={{width: "12.5%", whiteSpace: "nowrap"}}>{`${UnitOfMeasure.getRatingDisplayValue(props.system.totalMiscLoad, 0) ?? 0} ${UnitOfMeasure.getRatingUnitLabel()}`}</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
			{props.system.wallLoad.boxSections && props.system.wallLoad.boxSections.length > 0 && (
				<table
					className="table is-bordered is-narrow-cente is-fullwidth no-margin-table"
					style={table}
				>
					<thead>
						<tr>
							<th className="header1" colSpan={10}>
								<b>{translations.wALL_FLOORANDCEILINGLOADS}</b>
							</th>
						</tr>
						<tr>
							<th className="header2" style={{width: "10%"}}><b>{translations.section}</b></th>
							<th className="header2" style={{width: "18%"}}><b>{translations.construction}</b></th>
							<th className="header2" style={{width: "9%"}}><b>{`${translations.thickness} (${UnitOfMeasure.getSmallerDimensionUnitLabel()})`}</b></th>
							<th className="header2" style={{width: "9%"}}><b>{translations.k_Factor}</b></th>
							<th className="header2" style={{width: "9%"}}><b>{translations.r_Value}</b></th>
							<th className="header2" style={{width: "9%"}}><b>{`${translations.extTemp} (${UnitOfMeasure.getTemperatureUnitLabel()})`}</b></th>
							<th className="header2" style={{width: "9%"}}><b>{`Dim 1 (${UnitOfMeasure.getDimensionUnitLabel()})`}</b></th>
							<th className="header2" style={{width: "9%"}}><b>{`Dim 2 (${UnitOfMeasure.getDimensionUnitLabel()})`}</b></th>
							<th className="header2" style={{width: "9%"}}><b>{`${translations.area} (${UnitOfMeasure.getAreaUnitLabel()})`}</b></th>
							<th className="header2" style={{width: "9%"}}><b>{`${translations.load} (${UnitOfMeasure.getRatingUnitLabel()})`}</b></th>
						</tr>
					</thead>
					<tbody>
						{sectionsGridView(props.system.wallLoad.boxSections)?.map((section: ISection, i: number) => {
							const key = i;
							return (
								<tr key={key}>
									<td>{section.sectionName}</td>
									<td>{section.materialName}</td>
									<td>{UnitOfMeasure.getSmallerDimensionDisplayValue(section.thickness, 3)}</td>
									<td>{UnitOfMeasure.getDisplayValue(section.kFactor, 2)}</td>
									<td>{UnitOfMeasure.getDisplayValue(section.rValueEffective, 2)}</td>
									<td>{UnitOfMeasure.getTemperatureDisplayValue(section.exteriorTemperature, 1)}</td>
									<td>{UnitOfMeasure.getDimensionDisplayValue(section.dimension1, 2)}</td>
									<td>{UnitOfMeasure.getDimensionDisplayValue(section.dimension2, 2)}</td>
									<td>{UnitOfMeasure.getAreaDisplayValue(section.areaWithoutOpenings, 0)}</td>
									<td>{UnitOfMeasure.getRatingDisplayValue(section.conductionLoadWithoutOpenings, 0)}</td>
								</tr>
							);
						})}
						<tr>
							<td colSpan={2} className="align-right"><b>{`${translations.volume} (${UnitOfMeasure.getVolumeUnitLabel()})`}</b></td>
							<td><b>{UnitOfMeasure.getVolumeDisplayValue(props.system.wallLoad.volume, 0)}</b></td>
							<td colSpan={6} className="align-right"><b>{translations.tOTALWALL_FLOORANDCEILINGLOAD}</b></td>
							<td><b>{UnitOfMeasure.getRatingDisplayValue(props.system.totalWallLoad, 0)}</b></td>
						</tr>
					</tbody>
				</table>
			)}
			{hasValue(props.system.totalInfiltrationLoad) && getInfiltrationLoadCalcMethod(props.system.infiltrationLoad) !== 2 && (
				<table
					className="table is-bordered is-narrow-cente is-fullwidth no-margin-table"
					style={table}
				>
					<thead>
						<tr>
							<th className="header1" colSpan={7}>
								<b>{getInfiltrationLoadCalcMethodLabel(props.system.infiltrationLoad)}</b>
							</th>
						</tr>
						<tr>
							{ getInfiltrationLoadCalcMethod(props.system.infiltrationLoad) === 1 && (
								<th className="header2"><b>{translations.boxUsage}</b></th>
							)}
							<th className="header2"><b>{`${translations.internalTemperature} (${UnitOfMeasure.getTemperatureUnitLabel()})`}</b></th>
							<th className="header2"><b>{`${translations.internalRh} (${UnitOfMeasure.getPercentUnitLabel()})`}</b></th>
							<th className="header2"><b>{`${translations.externalTemperature} (${UnitOfMeasure.getTemperatureUnitLabel()})`}</b></th>
							<th className="header2"><b>{`${translations.externalRh} (${UnitOfMeasure.getPercentUnitLabel()})`}</b></th>
							<th className="header2"><b>{`${translations.ventilation} (${UnitOfMeasure.getAirFlowUnitLabel()})`}</b></th>
							<th className="header2"><b>{`${translations.load} (${UnitOfMeasure.getRatingUnitLabel()})`}</b></th>
						</tr>
					</thead>
					<tbody>
						<tr>
							{ getInfiltrationLoadCalcMethod(props.system.infiltrationLoad) === 1 && (
								<td>{getBoxUsage(props.system.infiltrationLoad?.boxUsage)}</td>
							)}
							<td>{UnitOfMeasure.getTemperatureDisplayValue(props.system.interiorTemperature, 1)}</td>
							<td>{UnitOfMeasure.getDisplayValue(props.system.interiorHumidity, 1)}</td>
							<td>{UnitOfMeasure.getTemperatureDisplayValue(props.system.exteriorTemperature, 1)}</td>
							<td>{UnitOfMeasure.getDisplayValue(props.system.exteriorHumidity, 1)}</td>
							<td>{UnitOfMeasure.getAirFlowDisplayValue(props.system.infiltrationLoad?.ventilationRate, 1)}</td>
							<td>{UnitOfMeasure.getRatingDisplayValue(getInfiltrationLoadEstimated(props.system.infiltrationLoad), 0)}</td>
						</tr>
					</tbody>
				</table>
			)}
			{hasValue(props.system.totalInfiltrationLoad) && getInfiltrationLoadCalcMethod(props.system.infiltrationLoad) === 2 && (
				<table
					className="table is-bordered is-narrow-cente is-fullwidth no-margin-table"
					style={table}
				>
					<thead>
						<tr>
							<th className="header1" colSpan={10}>
								<b>{getInfiltrationLoadCalcMethodLabel(props.system.infiltrationLoad)}</b>
							</th>
						</tr>
						<tr>
							<th className="header2" style={{width: "10%"}}><b>{translations.quantity}</b></th>
							<th className="header2" style={{width: "20%"}}><b>{translations.doorOrOpeningType}</b></th>
							<th className="header2" style={{width: "10%"}}><b>{`${translations.height} (${UnitOfMeasure.getSmallerDimensionUnitLabel()})`}</b></th>
							<th className="header2" style={{width: "10%"}}><b>{`${translations.width} (${UnitOfMeasure.getSmallerDimensionUnitLabel()})`}</b></th>
							<th className="header2" style={{width: "10%"}}><b>{`${translations.extTemp} (${UnitOfMeasure.getTemperatureUnitLabel()})`}</b></th>
							<th className="header2" style={{width: "10%"}}><b>{`${translations.externalRh} (${UnitOfMeasure.getPercentUnitLabel()})`}</b></th>
							<th className="header2" style={{width: "10%"}}><b>{`${translations.openTime} (hrs)`}</b></th>
							<th className="header2" style={{width: "10%"}}><b>{translations.curtains}</b></th>
							<th className="header2" style={{width: "10%"}}><b>{`${translations.load} (${UnitOfMeasure.getRatingUnitLabel()})`}</b></th>
						</tr>
					</thead>
					<tbody>
						{props.system.wallLoad.boxSections?.map((section: ISection, i: number) => {
							const skey = i;
							return section.openings?.map((opening: IOpening, j: number) => {
								const okey = j;
								return (
									<tr key={`${skey}-${okey}`}>
										<td>{opening.quantity}</td>
										<td>{getOpeningTypeConfig(opening.openingType).name}</td>
										<td>{UnitOfMeasure.getSmallerDimensionDisplayValue(opening.height, 2)}</td>
										<td>{UnitOfMeasure.getSmallerDimensionDisplayValue(opening.width, 2)}</td>
										<td>{UnitOfMeasure.getTemperatureDisplayValue(opening.exteriorTemperature, 1)}</td>
										<td>{UnitOfMeasure.getDisplayValue(opening.infiltratingAirRh, 1)}</td>
										<td>{UnitOfMeasure.getDisplayValue(calculateDoorOpenTimeHours(opening.standOpenTimePerDay, opening.averagePassageTime, opening.passagesPerDay) ?? 0, 2)}</td>
										<td>{getCurtain(opening.stripCurtainId).name}</td>
										<td>{UnitOfMeasure.getRatingDisplayValue(opening.totalInfiltrationLoad, 0)}</td>
									</tr>
								);
							});
						})}
						<tr>
							<td colSpan={8} className="align-right"><b>{translations.tOTALINFILTRATIONLOADESTIMATEDBYDOORSANDOPENINGS}</b></td>
							<td><b>{UnitOfMeasure.getRatingDisplayValue(getInfiltrationLoadEstimated(props.system.infiltrationLoad), 0)}</b></td>
						</tr>
					</tbody>
				</table>
			)}
			{props.system.productLoad && (
				<table
					className="table is-bordered is-narrow-cente is-fullwidth no-margin-table"
					style={table}
				>
					<thead>
						<tr>
							<th className="header1" colSpan={10}>
								<b>{translations.pRODUCTLOADS}</b>
							</th>
						</tr>
						<tr>
							<th className="header2"><b>{translations.type}</b></th>
							<th className="header2"><b>{`${translations.weight} (${UnitOfMeasure.getWeightUnitLabel()})`}</b></th>
							<th className="header2"><b>{`${translations.enterTemp} (${UnitOfMeasure.getTemperatureUnitLabel()})`}</b></th>
							<th className="header2"><b>{`${translations.finalTemperature} (${UnitOfMeasure.getTemperatureUnitLabel()})`}</b></th>
							<th className="header2"><b>{`${translations.pullDown} (hrs)`}</b></th>
							<th className="header2"><b>{`${translations.load} (${UnitOfMeasure.getRatingUnitLabel()})`}</b></th>
						</tr>
					</thead>
					<tbody>
						{props.system.productLoad && props.system.productLoad.productList && props.system.productLoad.productList.map((product: any, i: number) => {
							const key = i;
							return (
								<tr key={key}>
									<td>{product.description}</td>
									<td>{UnitOfMeasure.getWeightDisplayValue(product.weight, 0)}</td>
									<td>{UnitOfMeasure.getTemperatureDisplayValue(product.enteringTemp, 1)}</td>
									<td>{UnitOfMeasure.getTemperatureDisplayValue(product.finalTemp, 1)}</td>
									<td>{UnitOfMeasure.getDisplayValue(product.pulldownTime, 0)}</td>
									<td>{UnitOfMeasure.getRatingDisplayValue(product.productLoad, 0)}</td>
								</tr>
							);
						})}
						<tr>
							<td className="align-right"><b>{`${translations.tOTALPRODUCTWEIGHT} (${UnitOfMeasure.getWeightUnitLabel()})`}</b></td>
							<td><b>{UnitOfMeasure.getWeightDisplayValue(props.system.productLoad?.totalProductLoad, 0)}</b></td>
							<td colSpan={3} className="align-right"><b>{translations.totalProductLoad}</b></td>
							<td><b>{UnitOfMeasure.getRatingDisplayValue(props.system.totalProductLoad, 0)}</b></td>
						</tr>
					</tbody>
				</table>
			)}
			{props.system.miscLoad && (
				<table
					className="table is-bordered is-narrow-cente is-fullwidth no-margin-table"
					style={table}
				>
					<thead>
						<tr>
							<th className="header1" colSpan={10}>
								<b>{translations.mISCELLANEOUSLOADS}</b>
							</th>
						</tr>
						<tr>
							<th className="header2"><b>{translations.quantity}</b></th>
							<th className="header2"><b>{translations.description}</b></th>
							<th className="header2"><b>{translations.power}</b></th>
							<th className="header2"><b>{translations.units}</b></th>
							<th className="header2"><b>{`${translations.runTime} (hrs)`}</b></th>
							<th className="header2"><b>{`${translations.load} (${UnitOfMeasure.getRatingUnitLabel()})`}</b></th>
						</tr>
					</thead>
					<tbody>
						{props.system.miscLoad?.lightingLoad?.load && (
							<tr>
								<td>{UnitOfMeasure.getDisplayValue(props.system.miscLoad?.lightingLoad?.quantity, 0)}</td>
								<td>{translations.lIGHTING}</td>
								<td>{UnitOfMeasure.getAreaDisplayValue(props.system.miscLoad?.lightingLoad?.powerDensity, 2)}</td>
								<td>{`W/${UnitOfMeasure.getAreaUnitLabel()}`}</td>
								<td>{UnitOfMeasure.getDisplayValue(props.system.miscLoad?.lightingLoad?.runtime, 0)}</td>
								<td>{UnitOfMeasure.getRatingDisplayValue(props.system.miscLoad?.lightingLoad?.load, 0)}</td>
							</tr>
						)}
						{props.system.miscLoad?.evaporatorMotorsLoad?.load && (
							<tr>
								<td>{UnitOfMeasure.getDisplayValue(props.system.miscLoad?.evaporatorMotorsLoad?.quantity, 0)}</td>
								<td>{translations.eVAPORATORMOTORS}</td>
								<td>{UnitOfMeasure.getDisplayValue(props.system.miscLoad?.evaporatorMotorsLoad?.ratedAt, 0)}</td>
								<td>W</td>
								<td>{UnitOfMeasure.getDisplayValue(props.system.miscLoad?.evaporatorMotorsLoad?.runTime, 0)}</td>
								<td>{UnitOfMeasure.getRatingDisplayValue(props.system.miscLoad?.evaporatorMotorsLoad?.load, 0)}</td>
							</tr>
						)}
						{props.system.miscLoad?.personnelLoad?.load && (
							<tr>
								<td>{UnitOfMeasure.getDisplayValue(props.system.miscLoad?.personnelLoad?.quantity, 0)}</td>
								<td>{translations.pERSONNEL}</td>
								<td>{getPersonnelActivityLabel(props.system.miscLoad?.personnelLoad?.personnelActivity ?? 0)}</td>
								<td>&nbsp;</td>
								<td>{UnitOfMeasure.getDisplayValue(props.system.miscLoad?.personnelLoad?.workHours, 0)}</td>
								<td>{UnitOfMeasure.getRatingDisplayValue(props.system.miscLoad?.personnelLoad?.load, 0)}</td>
							</tr>
						)}
						{props.system.miscLoad?.forkLiftLoad?.load && (
							<tr>
								<td>{UnitOfMeasure.getDisplayValue(props.system.miscLoad?.forkLiftLoad?.quantity, 0)}</td>
								<td>{translations.fORKLIFTS_TOWMOTORS}</td>
								<td>&nbsp;</td>
								<td>&nbsp;</td>
								<td>{UnitOfMeasure.getDisplayValue(props.system.miscLoad?.forkLiftLoad?.workHours, 0)}</td>
								<td>{UnitOfMeasure.getRatingDisplayValue(props.system.miscLoad?.forkLiftLoad?.load, 0)}</td>
							</tr>
						)}
						{props.system.miscLoad?.otherLoads && props.system.miscLoad.otherLoads.map((otherLoad: any, i: number) => {
							const key = i;
							return (
								<tr key={key}>
									<td>{UnitOfMeasure.getDisplayValue(otherLoad.quantity, 0)}</td>
									<td>{otherLoad.description}</td>
									<td>{UnitOfMeasure.getDisplayValue(otherLoad.power, 0)}</td>
									<td>{getPowerUnit(otherLoad.powerUnit ?? 0)}</td>
									<td>{UnitOfMeasure.getDisplayValue(otherLoad.runTime, 0)}</td>
									<td>{UnitOfMeasure.getRatingDisplayValue(otherLoad.load, 0)}</td>
								</tr>
							);
						})}
						<tr>
							<td colSpan={5} className="align-right"><b>{translations.tOTALMISCELLANEOUSLOAD}</b></td>
							<td><b>{UnitOfMeasure.getRatingDisplayValue(props.system.totalMiscLoad, 0)}</b></td>
						</tr>
					</tbody>
				</table>
			)}
			<table
				className="table is-bordered is-narrow-cente is-fullwidth no-margin-table"
				style={table}
			>
				<thead>
					<tr>
						<th className="header1" colSpan={10}>
							<b>{translations.sYSTEMDESIGNSUMMARY}</b>
						</th>								
					</tr>
					<tr>
						<th className="header2">{`${translations.designLoad} (${UnitOfMeasure.getRatingUnitLabel()})`}</th>
						<th className="header2">{translations.safetyFactor}</th>
						<th className="header2">{`${translations.safetyLoad} (${UnitOfMeasure.getRatingUnitLabel()})`}</th>
						<th className="header2">{`${translations.runTime} (${translations.hours})`}</th>
						<th className="header2">{`${translations.capacityRequired} (${UnitOfMeasure.getRatingUnitLabel()})`}</th>
						<th className="header2">{translations.refrigerant}</th>
						<th className="header2">{`${translations.lineFrequency} (Hz)`}</th>
						<th className="header2">{`${translations.designTD} (${UnitOfMeasure.getTemperatureUnitLabel()})`}</th>
						<th className="header2">{`${translations.lineLoss} (${UnitOfMeasure.getTemperatureUnitLabel()})`}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{UnitOfMeasure.getRatingDisplayValue(props.system.totalHourlyLoad, 0)}</td>
						<td>{`${UnitOfMeasure.getDisplayValue(props.system.safetyFactor, 2)}${UnitOfMeasure.getPercentUnitLabel()}`}</td>
						<td>{UnitOfMeasure.getRatingDisplayValue(props.system.totalLoadRequired, 0)}</td>
						<td>{UnitOfMeasure.getDisplayValue(props.system.runTimeHours, 2)}</td>
						<td>{UnitOfMeasure.getRatingDisplayValue(props.system.capacityRequired, 0)}</td>
						<td>{refrigerants.find(a => a.key === props.system.equipmentSelection.refrigerant?.toString())?.value}</td>
						<td>{props.system.equipmentSelection.siteLineFrequency}</td>
						<td>{UnitOfMeasure.getTemperatureRelativeDisplayValue(props.system.equipmentSelection.designTd, 1)}</td>
						<td>{UnitOfMeasure.getTemperatureRelativeDisplayValue(props.system.equipmentSelection.suctionLineLoss, 1)}</td>
					</tr>
				</tbody>
			</table>
			<table
				className="table is-bordered is-narrow-cente is-fullwidth no-margin-table"
				style={table}
			>
				<thead>
					<tr>
						<th className="header1" colSpan={6}>
							<b>{translations.sYSTEMEQUIPMENTSELECTION}</b>
						</th>								
					</tr>
					<tr>
						<th className="header2">{translations.equipmentType}</th>
						<th className="header2">{translations.quantity}</th>
						<th className="header2">{translations.modelNumber}</th>
						<th className="header2">{translations.description}</th>
						<th className="header2">{translations.voltage}</th>
						<th className="header2">{`${translations.rATING} (${UnitOfMeasure.getRatingUnitLabel()})`}</th>								
					</tr>
				</thead>
				<tbody>
					{props.system.equipmentSelection.selectedCondenserUnit && (
						<tr>
							<td>{props.system.equipmentSelection.equipmentType}</td>
							<td>{props.system.equipmentSelection.condenserUnitCount}</td>
							<td>{props.system.equipmentSelection.selectedCondenserUnit.partNumber}</td>
							<td>{`${props.system.equipmentSelection.selectedCondenserUnit.description}|${props.system.equipmentSelection.equipmentType}`}</td>
							<td>{props.system.equipmentSelection.selectedCondenserUnit.text}</td>
							<td>{UnitOfMeasure.getRatingDisplayValue(props.system.equipmentSelection.selectedCondenserUnit.capacityPerUnit, 0)}</td>
						</tr>
					)}
					{props.system.equipmentSelection.selectedEvaporator && (
						<tr>
							<td>Evaporator</td>
							<td>{props.system.equipmentSelection.evaporatorUnitCount}</td>
							<td>{props.system.equipmentSelection.selectedEvaporator.partNumber}</td>
							<td>{`${props.system.equipmentSelection.selectedEvaporator.description}|Evaporator`}</td>
							<td>{props.system.equipmentSelection.selectedEvaporator.text}</td>
							<td>{UnitOfMeasure.getRatingDisplayValue(props.system.equipmentSelection.selectedEvaporator.capacityPerUnit, 0)}</td>
						</tr>
					)}
				</tbody>
			</table>
			<table
				className="table is-bordered is-narrow-cente is-fullwidth no-margin-table"
				style={table}
			>
				<thead>
					<tr>
						<th className="header1" colSpan={6}>
							<b>{translations.sYSTEMBALANCE}</b>
						</th>								
					</tr>
					<tr>
						<th className="header2">{`${translations.condenserAmbient} (${UnitOfMeasure.getTemperatureUnitLabel()})`}</th>								
						<th className="header2">{`${translations.suctionTemp} (${UnitOfMeasure.getTemperatureUnitLabel()})`}</th>
						<th className="header2">{`${translations.evaporatorTD} (${UnitOfMeasure.getTemperatureUnitLabel()})`}</th>
						<th className="header2">{`${translations.systemCapacity} (${UnitOfMeasure.getRatingUnitLabel()})`}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{UnitOfMeasure.getTemperatureDisplayValue(props.system.equipmentSelection.condensingUnitAmbientTemperature, 1)}</td>
						<td>{UnitOfMeasure.getTemperatureDisplayValue(props.system.equipmentSelection.sstBalance, 1)}</td>
						<td>{UnitOfMeasure.getTemperatureRelativeDisplayValue(props.system.equipmentSelection.tdBalance, 1)}</td>
						<td>{UnitOfMeasure.getRatingDisplayValue(props.system.equipmentSelection.totalSystemCapacity, 0)}</td>
					</tr>							
				</tbody>
			</table>
		</div>
	);
};

export default LoadInfoEquipmentSummary;
