import HelperService from "./HelperService";
import { Config } from "../Constants";
import { measure, hasValue, getNumericValue } from "../types/measure";

class LoadCalculationService {
    private static baseURL = Config.API_URL;

    public static getMiscLoad(boxAppId: number,
        floorArea: number,
        volume: number,
        inTemp: number,
        miscload: any,
        callback: (data: any) => void) {
        
        const url = `${this.baseURL}/loadcalculation/miscload?boxId=${boxAppId}&volume=${volume}&area=${floorArea}&inTemp=${inTemp}`;
        HelperService.callAPIPost(
            url,
            miscload,
            callback);        
    }

    public static getMiscLoadItems(boxAppId: number,
        floorArea: number,
        volume: number,
        inTemp: number,
        miscload: any,
        callback: (data: any) => void) {
        
        const url = `${this.baseURL}/loadcalculation/miscandload?boxId=${boxAppId}&volume=${volume}&area=${floorArea}&inTemp=${inTemp}`;
        HelperService.callAPIPost(
            url,
            miscload,
            callback);        
    }

    public static getInfiltrationLoad(boxAppId: number,
        openingInfilLoad: number,
        volume: number,
        exTemp: number,
        exRH: number,
        inTemp: number,
        inRH: number,
        infiltrationLoad: any,
        callback: (data: any) => void) {
        
        const url = `${this.baseURL}/loadcalculation/infiltrationload?boxId=${boxAppId}&volume=${volume}&openingInfilLoad=${openingInfilLoad}&exTemp=${exTemp}&exRH=${exRH}&inTemp=${inTemp}&inRH=${inRH}`;
        HelperService.callAPIPost(
            url,
            infiltrationLoad,
            callback);        
    }

    public static getInfiltrationLoadItems(boxAppId: number,
        openingInfilLoad: number,
        volume: number,
        exTemp: number,
        exRH: number,
        inTemp: number,
        inRH: number,
        infiltrationLoad: any,
        callback: (data: any) => void) {
        
        const url = `${this.baseURL}/loadcalculation/infiltrationandload?boxId=${boxAppId}&volume=${volume}&openingInfilLoad=${openingInfilLoad}&exTemp=${exTemp}&exRH=${exRH}&inTemp=${inTemp}&inRH=${inRH}`;
        HelperService.callAPIPost(
            url,
            infiltrationLoad,
            callback);        
    }

    public static getProductLoad(boxAppId: number,        
        volume: number,
        inTemp: number,
        prodLoad: any,
        callback: (data: any) => void) {
        
        const url = `${this.baseURL}/loadcalculation/prodload?boxId=${boxAppId}&volume=${volume}&inTemp=${inTemp}`;
        HelperService.callAPIPost(
            url,
            prodLoad,
            callback);        
    }

    public static getProductLoadItems(boxAppId: number,        
        volume: number,
        inTemp: number,
        prodLoad: any,
        callback: (data: any) => void) {
        
        const url = `${this.baseURL}/loadcalculation/prodandload?boxId=${boxAppId}&volume=${volume}&inTemp=${inTemp}`;
        HelperService.callAPIPost(
            url,
            prodLoad,
            callback);        
    }

    public static getTotalMiscLoadAsync = (
        boxApplicationId: number,
        floorArea: number,
        volume: number,
        inTemp: number,
        miscLoad: any
    ) => {
        return new Promise<number>((resolve, reject) => {
            try {
                LoadCalculationService.getMiscLoad(
                    boxApplicationId,
                    floorArea,
                    volume,
                    inTemp,
                    miscLoad, 
                    (data) => {
                        resolve(data);
                    }
                );
            }
            catch (error) {
                reject(error);
            }
        });
    };
    
    public static getTotalInfiltrationLoadAsync = (
        boxApplicationId: number,
        openingsTotalInfiltrationLoad: number,
        volume: number,
        exteriorTemperature: number,
        exteriorHumidity: number,
        interiorTemperature: number,
        interiorHumidity: number,
        infiltrationLoad: any
    ) => {
        return new Promise<number>((resolve, reject) => {
            try {
                LoadCalculationService.getInfiltrationLoad(
                    boxApplicationId,
                    openingsTotalInfiltrationLoad,
                    volume,
                    exteriorTemperature,
                    exteriorHumidity,
                    interiorTemperature,
                    interiorHumidity,
                    infiltrationLoad,
                    (data) => {
                        resolve(data);
                    }
                );
            }
            catch (error) {
                reject(error);
            }
        });
    };
    
    public static getTotalProductLoadAsync = (
        boxApplicationId: number,
        volume: number,
        inTemp: number,
        productLoad: any
    ) => {
        return new Promise<number>((resolve, reject) => {
            try {
                LoadCalculationService.getProductLoad(
                    boxApplicationId,
                    volume,
                    inTemp,
                    productLoad, 
                    (data) => {
                        resolve(data);
                    }
                );
            }
            catch (error) {
                reject(error);
            }
        });
    };

    public static getMiscLoadItemsAsync = (
        boxApplicationId: number,
        floorArea: number,
        volume: number,
        inTemp: number,
        miscLoad: any
    ) => {
        return new Promise<any>((resolve, reject) => {
            try {
                LoadCalculationService.getMiscLoadItems(
                    boxApplicationId,
                    floorArea,
                    volume,
                    inTemp,
                    miscLoad, 
                    (data) => {
                        resolve(data);
                    }
                );
            }
            catch (error) {
                reject(error);
            }
        });
    };

    public static getInfiltrationLoadItemsAsync = (
        boxApplicationId: number,
        openingsTotalInfiltrationLoad: number,
        volume: number,
        exteriorTemperature: number,
        exteriorHumidity: number,
        interiorTemperature: number,
        interiorHumidity: number,
        infiltrationLoad: any
    ) => {
        return new Promise<number>((resolve, reject) => {
            try {
                LoadCalculationService.getInfiltrationLoadItems(
                    boxApplicationId,
                    openingsTotalInfiltrationLoad,
                    volume,
                    exteriorTemperature,
                    exteriorHumidity,
                    interiorTemperature,
                    interiorHumidity,
                    infiltrationLoad,
                    (data) => {
                        resolve(data);
                    }
                );
            }
            catch (error) {
                reject(error);
            }
        });
    };

    public static getProductLoadItemsAsync = (
        boxApplicationId: number,
        volume: number,
        inTemp: number,
        productLoad: any
    ) => {
        return new Promise<any>((resolve, reject) => {
            try {
                LoadCalculationService.getProductLoadItems(
                    boxApplicationId,
                    volume,
                    inTemp,
                    productLoad, 
                    (data) => {
                        resolve(data);
                    }
                );
            }
            catch (error) {
                reject(error);
            }
        });
    };

    public static fetchLoadsAsync = async (
        customTotalMiscLoad: measure,
        customTotalInfiltrationLoad: measure,
        customTotalProductLoad: measure,
        boxApplicationId: number,
        floorArea: measure,
        volume: measure,
        openingsTotalInfiltrationLoad: measure,
        exteriorTemperature: measure,
        exteriorHumidity: measure,
        interiorTemperature: measure,
        interiorHumidity: measure,
        miscLoad: any,
        infiltrationLoad: any,
        productLoad: any
    ) => {

        const totalMiscLoad = 
            hasValue(customTotalMiscLoad)
                ? getNumericValue(customTotalMiscLoad)
                : await LoadCalculationService.getTotalMiscLoadAsync(
                    boxApplicationId,
                    floorArea ?? 0,
                    volume ?? 0,
                    getNumericValue(interiorTemperature),
                    miscLoad);

        const miscLoadItems = await LoadCalculationService.getMiscLoadItemsAsync(
            boxApplicationId,
            floorArea ?? 0,
            volume ?? 0,
            getNumericValue(interiorTemperature),
            miscLoad);
    
        let totalInfiltrationLoad = 0;
        if (hasValue(customTotalInfiltrationLoad)) {
            totalInfiltrationLoad = getNumericValue(customTotalInfiltrationLoad);
        }
        else if (hasValue(exteriorTemperature) && hasValue(exteriorHumidity)
            && hasValue(interiorTemperature) && hasValue(interiorHumidity)
        ) {
            totalInfiltrationLoad = await LoadCalculationService.getTotalInfiltrationLoadAsync(
                boxApplicationId,
                openingsTotalInfiltrationLoad ?? 0,
                volume ?? 0,
                getNumericValue(exteriorTemperature),
                getNumericValue(exteriorHumidity),
                getNumericValue(interiorTemperature),
                getNumericValue(interiorHumidity),
                infiltrationLoad)
        };

        const infiltrationLoadItems = await LoadCalculationService.getInfiltrationLoadItemsAsync(
            boxApplicationId,
            openingsTotalInfiltrationLoad ?? 0,
            volume ?? 0,
            getNumericValue(exteriorTemperature),
            getNumericValue(exteriorHumidity),
            getNumericValue(interiorTemperature),
            getNumericValue(interiorHumidity),
            infiltrationLoad);
    
        const totalProductLoad = 
            hasValue(customTotalProductLoad)
                ? getNumericValue(customTotalProductLoad)
                : await LoadCalculationService.getTotalProductLoadAsync(
                    boxApplicationId,
                    volume ?? 0,
                    getNumericValue(interiorTemperature),
                    productLoad);

        const productLoadItems = await LoadCalculationService.getProductLoadItemsAsync(
            boxApplicationId,
            volume ?? 0,
            getNumericValue(interiorTemperature),
            productLoad);
    
        return {
            totalMiscLoad,
            totalInfiltrationLoad,
            infiltrationLoadItems,
            totalProductLoad,
            miscLoadItems,
            productLoadItems
        };
    };

    public static fetchCalculatedLoadsAsync = async (
        boxApplicationId: number,
        floorArea: measure,
        volume: measure,
        openingsTotalInfiltrationLoad: measure,
        exteriorTemperature: measure,
        exteriorHumidity: measure,
        interiorTemperature: measure,
        interiorHumidity: measure,
        miscLoad: any,
        infiltrationLoad: any,
        productLoad: any
    ) => {

        const totalMiscLoad = await LoadCalculationService.getTotalMiscLoadAsync(
            boxApplicationId,
            floorArea ?? 0,
            volume ?? 0,
            getNumericValue(interiorTemperature),
            miscLoad);

        const miscLoadItems = await LoadCalculationService.getMiscLoadItemsAsync(
            boxApplicationId,
            floorArea ?? 0,
            volume ?? 0,
            getNumericValue(interiorTemperature),
            miscLoad);
    
        let totalInfiltrationLoad = 0;
        if (hasValue(exteriorTemperature) && hasValue(exteriorHumidity)
            && hasValue(interiorTemperature) && hasValue(interiorHumidity)
        ) {
            totalInfiltrationLoad = await LoadCalculationService.getTotalInfiltrationLoadAsync(
                boxApplicationId,
                openingsTotalInfiltrationLoad ?? 0,
                volume ?? 0,
                getNumericValue(exteriorTemperature),
                getNumericValue(exteriorHumidity),
                getNumericValue(interiorTemperature),
                getNumericValue(interiorHumidity),
                infiltrationLoad)
        };

        const infiltrationLoadItems = await LoadCalculationService.getInfiltrationLoadItemsAsync(
            boxApplicationId,
            openingsTotalInfiltrationLoad ?? 0,
            volume ?? 0,
            getNumericValue(exteriorTemperature),
            getNumericValue(exteriorHumidity),
            getNumericValue(interiorTemperature),
            getNumericValue(interiorHumidity),
            infiltrationLoad);
    
        const totalProductLoad = await LoadCalculationService.getTotalProductLoadAsync(
            boxApplicationId,
            volume ?? 0,
            getNumericValue(interiorTemperature),
            productLoad);

        const productLoadItems = await LoadCalculationService.getProductLoadItemsAsync(
            boxApplicationId,
            volume ?? 0,
            getNumericValue(interiorTemperature),
            productLoad);
    
        return {
            totalMiscLoad,
            totalInfiltrationLoad,
            totalProductLoad,
            miscLoadItems,
            infiltrationLoadItems,
            productLoadItems
        };
    };
}

export default LoadCalculationService;