import * as React from "react";

import Button from "../helpers/Button";
import ConfirmationMessage from "../helpers/ConfirmationMessage";
import Grid from "../helpers/Grid";
import OpeningDetailsModal from "./OpeningDetailsModal";

import UnitOfMeasure from "../../models/helpers/UnitOfMeasure";

import useOpeningDetailsForm from "../../hooks/useOpeningDetailsForm";
import useTranslationContext from "../../hooks/useTranslationContext";

import IOpening from "../../models/interfaces/IOpening";
import ISection from "../../models/interfaces/ISection";

import { measure, roundOff } from "../../types/measure";

interface OpeningsGridProps {
    section: ISection,
    boxInteriorTemperature: measure;
    boxInteriorHumidity: measure;
    openings: IOpening[],
    onGridUpdated: (openings: IOpening[]) => void;
}

const OpeningsGrid: React.FunctionComponent<OpeningsGridProps> = (
	props: OpeningsGridProps
) => {

    const { translations } = useTranslationContext();

    const [showConfirmationMessage, setShowConfirmationMessage] = React.useState(false);
    const [showOpeningDetailsPopup, setShowOpeningDetailsPopup] = React.useState(false);
    const [openings, setOpenings] = React.useState(props.openings);
    const [selectedRow, setSelectedRow] = React.useState(-1);
    const [openingToEdit, setOpeningToEdit] = React.useState(undefined as IOpening | undefined);

    const { getDefaultOpening, recalculateOpening } = useOpeningDetailsForm();

    React.useEffect(() => {
        const triggerGridUpdated = () => {
            if (props.onGridUpdated) {
                props.onGridUpdated(openings);
            }
        }
        triggerGridUpdated();
    }, [openings]);

    const openAddOpeningPopup = () => {
        setOpeningToEdit(undefined);
        setShowOpeningDetailsPopup(true);
    };

    const openEditOpeningPopup = () => {
        if (selectedRow > -1) {
            const selectedOpening = openings[selectedRow];
            setOpeningToEdit(selectedOpening);
            setShowOpeningDetailsPopup(true);
        }
    };

    const addOpening = (newOpening: IOpening) => { 
        setShowOpeningDetailsPopup(false); 

        const openingsList = [
            ...openings,
            newOpening
        ];

        setOpenings(openingsList);

        setSelectedRow(openingsList.length - 1);
    };

    const updateOpening = (updatedOpening: IOpening) => { 
        setShowOpeningDetailsPopup(false); 

        if (selectedRow > -1 && openingToEdit) {
            const openingsList = [
                ...openings.slice(0, selectedRow), 
                updatedOpening, 
                ...openings.slice(selectedRow + 1)
            ];
            setOpenings(openingsList);
        }
    };

    const deleteOpening = () => {
        if (selectedRow > -1) {
            const openingsList = openings.filter((opening, index) => index !== selectedRow);
            setOpenings([...openingsList]);
        }
    };

    const handleRowClick = (rowIndex: number) => {
        setSelectedRow(rowIndex);        
    };

    const handleRowDoubleClick = () => {
        openEditOpeningPopup();
    };

    const handleAddButtonClick = () => {
        openAddOpeningPopup();
    };

    const handleEditButtonClick = () => {
        openEditOpeningPopup();
    };

    const handleDeleteButtonClick = () => { 
        setShowConfirmationMessage(true); 
    };

    const handleOpeningDetailsOkClick = (opening: IOpening, isNew: boolean) => {
        if (isNew) {
            addOpening(opening);
        }
        else {
            updateOpening(opening);
        }
    };

    const deleteConfirmationMessage = {
		title: "Confirmation",
		message: <div>Are you sure you want to delete this opening?</div>,
		onYesClick: () => {
            deleteOpening();
            setShowConfirmationMessage(false);
        },
		onNoClick: () => {
            setShowConfirmationMessage(false);
        }
    };

    const openingsGridView = openings.map((opening) => {

        const defaultOpening = getDefaultOpening(
            opening.openingType,
            props.section.exteriorTemperature,
            props.section.exteriorHumidity,
            props.section.dimension1,
            props.section.dimension2,
            props.boxInteriorTemperature,
            props.boxInteriorHumidity);

        const actualOpening = recalculateOpening(
            {
                defaultValues: defaultOpening,
                actualValues: opening
            },
            null,
            props.section.exteriorTemperature,
            props.section.exteriorHumidity,
            props.section.dimension1,
            props.section.dimension2,
            props.boxInteriorTemperature,
            props.boxInteriorHumidity).actualValues;

        const gridData: { [key: string]: any } = {};
        gridData[translations.quantity] = roundOff(actualOpening.quantity, 0);
        gridData[translations.description] = actualOpening.description;
        gridData[`${translations.area} (${UnitOfMeasure.getAreaUnitLabel()})`] = UnitOfMeasure.getAreaDisplayValue(actualOpening.totalArea, 0);
        gridData[`${translations.conduction} (${UnitOfMeasure.getRatingUnitLabel()})`] = UnitOfMeasure.getRatingDisplayValue(actualOpening.totalConductionLoad, 0);
        gridData[`${translations.infiltration} (${UnitOfMeasure.getRatingUnitLabel()})`] = UnitOfMeasure.getRatingDisplayValue(actualOpening.totalInfiltrationLoad, 0);
        gridData.errors = actualOpening.errors;
        
        return gridData;
    });

    const isRowSelected = (selectedRow > -1) && openings[selectedRow];
    
    return (
        <>
            <div id="openings-grid">
                <div>
                    <div className="label is-small header">
                        {translations.doorsAndOpenings}
                    </div>                    
                    <Grid 
                        dataSource={openingsGridView} 
                        height={200} 
                        selectedRow={selectedRow}
                        onRowClick={handleRowClick}
                        onRowDoubleClick={handleRowDoubleClick}
                    />
                </div>
                <div className="block">
                    <div className="field is-grouped">
                        <Button
                            name="add-opening"
                            label={translations.addDoors_Openings}
                            className="button is-light is-small is-fullwidth"
                            onClick={handleAddButtonClick}
                        />
                        <Button
                            name="delete-opening"
                            label={translations.deleteDoors_Openings}
                            className="button is-light is-small is-fullwidth"
                            onClick={handleDeleteButtonClick}
                            disabled={!isRowSelected}
                        />
                        <Button
                            name="edit-opening"
                            label={translations.editDoor_Openings}
                            className="button is-light is-small is-fullwidth"
                            onClick={handleEditButtonClick}
                            disabled={!isRowSelected}
                        />
                    </div>
                </div>
            </div>
            <OpeningDetailsModal
                show={showOpeningDetailsPopup}
                section={props.section}
                boxInteriorTemperature={props.boxInteriorTemperature}
                boxInteriorHumidity={props.boxInteriorHumidity}
                openingToEdit={openingToEdit}
                onOkClick={handleOpeningDetailsOkClick}
                onCancelClick={() => { setShowOpeningDetailsPopup(false); }}
            />
            <ConfirmationMessage
				title={deleteConfirmationMessage.title}
				message={deleteConfirmationMessage.message}
				onYesClick={deleteConfirmationMessage.onYesClick}
                onNoClick={deleteConfirmationMessage.onNoClick}
                onCancelClick={deleteConfirmationMessage.onNoClick}
				show={showConfirmationMessage}
            />
        </>
    );
};

export default OpeningsGrid;
