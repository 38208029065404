import { measure, hasValue, getNumericValue } from "../../types/measure";
import { getFormField, getCustomValue, isCustomValue } from "./FormFields";

import IBoxLocation from "../interfaces/IBoxLocation";
import IBoxType from "../interfaces/IBoxType";
import IFloorType from "../interfaces/IFloorType";
import IFormField from "../interfaces/IFormField";
import IFormState from "../interfaces/IFormState";
import IMaterial from "../interfaces/IMaterial";
import IOpening from "../interfaces/IOpening";
import ISection from "../interfaces/ISection";
import ISectionType from "../interfaces/ISectionType";

import FloorType from "../../enums/FloorType";
import SectionLocation from "../../enums/SectionLocation";
import SectionType from "../../enums/SectionType";

import * as BoxSection from "./BoxSectionCalculations";

export const getDefaultMaterialId = (
    sectionType: ISectionType,
    floorType: IFloorType
) => {
    if (sectionType.id === SectionType.FloorCustom
        || sectionType.id === SectionType.FloorPolygon
    ) {
        return floorType.defaultMaterialId;
    }

    return sectionType.defaultMaterialId;
}

export const getDefaultMaterialName = (
    material: IMaterial
) => {
    let materialName = "";
    if (material && material.name.trim() !== "") {
        const description = (material.example && material.example.trim() !== "")
            ? `, ${material.example}`
            : "";
        materialName = `${material.isConstruct ? "Composite :- " : ""}${material.name}${description}`;
    }
    
    return materialName;
};

export const getDefaultThickness = (
    sectionType: ISectionType,
    floorType: IFloorType,
    material: IMaterial
) => {
    if (material && material.stdThickness) {
        return material.stdThickness;
    }

    if ((sectionType.id === SectionType.FloorCustom
        || sectionType.id === SectionType.FloorPolygon)
    ) {
        return floorType.defaultThickness
    }
    
    return sectionType.defaultThickness;
};

export const getSectionTypeId = (
    state: IFormState<ISection>,
    selectedSectionTypeId: SectionType | undefined
) => {
    return selectedSectionTypeId ?? (state.actualValues.sectionType ?? SectionType.WallCustom);
};

export const getSectionName = (
    state: IFormState<ISection>,
    enteredName: string | undefined,
    defaultValue: string
): IFormField<string> => {
	
	return getFormField<string>(
		state,
		"sectionName",
		enteredName,
		() => { return defaultValue; }
    );
}

export const getSectionLocation = (
    state: IFormState<ISection>,
    selectedSectionLocation: SectionLocation | undefined
) => {
    return selectedSectionLocation ?? (state.actualValues.sectionLocation ?? SectionLocation.BoxLocation);
};

export const getFloorType = (
    state: IFormState<ISection>,
    selectedFloorType: FloorType | undefined
) => {
    return selectedFloorType ?? (state.actualValues.floorType ?? FloorType.BoxSetting);
};

export const getWidth = (
	state: IFormState<ISection>,
	enteredWidth: measure
): IFormField<measure> => {

    const getDefaultValue = () => {
		return undefined;
	};
	
	return getFormField<measure>(
		state,
		"width",
		enteredWidth,
		getDefaultValue
	);
};

export const getHeight = (
	state: IFormState<ISection>,
    enteredHeight: measure,
    boxHeight: measure
): IFormField<measure> => {

    const getDefaultValue = () => {
		return boxHeight;
	};

	return getFormField<measure>(
		state,
		"height",
        enteredHeight,
        getDefaultValue
	);
};

export const getLength = (
	state: IFormState<ISection>,
	enteredLength: measure
): IFormField<measure> => {

    const getDefaultValue = () => {
		return undefined;
	};
	
	return getFormField<measure>(
		state,
		"length",
		enteredLength,
		getDefaultValue
	);
};

export const getDepth = (
	state: IFormState<ISection>,
    enteredDepth: measure,
    boxDepth: measure
): IFormField<measure> => {

    const getDefaultValue = () => {
		return boxDepth;
	};

	return getFormField<measure>(
		state,
		"depth",
        enteredDepth,
        getDefaultValue
	);
};

export const getCustomSectionArea = (
    state: IFormState<any>,
    enteredArea: measure,
    sectionType: ISectionType,
    width: measure,
    length: measure,
    height: measure,
    depth: measure
): IFormField<measure> => {

    return getFormField<measure>(
        state,
        "area",
        enteredArea,
        () => BoxSection.calculateCustomSectionArea(
            sectionType,
            width,
            length,
            height,
            depth)
    );
};

export const getSectionPerimeter = (
	state: IFormState<ISection>,
	enteredPerimeter: measure
): IFormField<measure> => {

    const getDefaultValue = () => {
        return undefined;
    };

	return getFormField<measure>(
		state,
		"perimeter",
        enteredPerimeter,
        getDefaultValue
	);
};

export const getMaterialId = (
    state: IFormState<ISection>,
    selectedMaterialId: number | undefined,
    sectionType: ISectionType,
    floorType: IFloorType
): number => {
    const materialId = selectedMaterialId ?? state.actualValues.materialId;
    const id = Number(materialId) === 0
        ? getDefaultMaterialId(sectionType, floorType)
        : Number(materialId);
    
    return id;
}

export const getMaterialName = (
    state: IFormState<ISection>,
    enteredName: string | undefined,
    material: IMaterial
): IFormField<string> => {

    return getFormField<string>(
		state,
		"materialName",
		enteredName,
		() => getDefaultMaterialName(material)
    );
}

export const getThickness = (
    state: IFormState<any>,
    enteredThickness: measure,
    sectionType: ISectionType,
    floorType: IFloorType,
    material: IMaterial
): IFormField<measure> => {

    if (material && material.stdThickness) {
        return {
            defaultValue: material.stdThickness,
            actualValue: material.stdThickness
        };
    }
    
    return getFormField<measure>(
        state,
        "thickness",
        enteredThickness,
        () => getDefaultThickness(
            sectionType,
            floorType,
            material)
    );
};

export const getKFactor = (
    state: IFormState<ISection>,
    enteredKFactor: measure,
    enteredRValuePerInch: measure,
    enteredRValueEffective: measure,
    thickness: measure,
    material: IMaterial
): IFormField<measure> => {

    let customRValuePerInch: measure = getCustomValue(state, "rValuePerInch", enteredRValuePerInch);
    let customRValueEffective: measure = getCustomValue(state, "rValueEffective", enteredRValueEffective);
    
    if (hasValue(enteredKFactor)) {
        customRValuePerInch = null;
        customRValueEffective = null;
    }
    else if (hasValue(enteredRValuePerInch)) {
        customRValuePerInch = enteredRValuePerInch;
        customRValueEffective = null;
    }
    else if (hasValue(enteredRValueEffective)) {
        customRValuePerInch = null;
        customRValueEffective = enteredRValueEffective;
    }
    
    if (hasValue(customRValuePerInch)
        || hasValue(customRValueEffective)
    ) { 
        const defaultKFactor = BoxSection.calculateKFactor(
            customRValuePerInch, 
            customRValueEffective, 
            thickness, 
            material);
        return {
            defaultValue: defaultKFactor,
            actualValue: defaultKFactor
        };
    }

    return getFormField<measure>(
        state,
        "kFactor",
        enteredKFactor,
        () => BoxSection.calculateKFactor(
            customRValuePerInch, 
            customRValueEffective, 
            thickness, 
            material)
    );
};

export const getRValuePerInch = (
    state: IFormState<any>,
    enteredRValuePerInch: measure,
    enteredKFactor: measure,
    enteredRValueEffective: measure,
    thickness: measure,
    material: IMaterial
): IFormField<measure> => {

    let customKFactor: measure = getCustomValue(state, "kFactor", enteredKFactor);
    let customRValueEffective: measure = getCustomValue(state, "rValueEffective", enteredRValueEffective);
    
    if (hasValue(enteredRValuePerInch)) {
        customKFactor = null;
        customRValueEffective = null;
    }
    else if (hasValue(enteredKFactor)) {
        customKFactor = enteredKFactor;
        customRValueEffective = null;
    }
    else if (hasValue(enteredRValueEffective)) {
        customKFactor = null;
        customRValueEffective = enteredRValueEffective;
    }

    if (hasValue(customKFactor)
        || hasValue(customRValueEffective)
    ) { 
        const defaultRValuePerInch = BoxSection.calculateRValuePerInch(
            customKFactor, 
            customRValueEffective, 
            thickness, 
            material);
        return {
            defaultValue: defaultRValuePerInch,
            actualValue: defaultRValuePerInch
        };
    }

    return getFormField<measure>(
        state,
        "rValuePerInch",
        enteredRValuePerInch,
        () => BoxSection.calculateRValuePerInch(
            customKFactor, 
            customRValueEffective, 
            thickness, 
            material)
    );
};

export const getRValueEffective = (
    state: IFormState<any>,
    enteredRValueEffective: measure,
    enteredKFactor: measure,
    enteredRValuePerInch: measure,
    rValuePerInch: measure,
    thickness: measure,
    sectionType: ISectionType,
    location: IBoxLocation,
    material: IMaterial
): IFormField<measure> => {

    let isKFactorEntered = isCustomValue(state, "kFactor", enteredKFactor);
    let isRValuePerInchEntered = isCustomValue(state, "rValuePerInch", enteredRValuePerInch);

    if (hasValue(enteredRValueEffective)) {
        isKFactorEntered = false;
        isRValuePerInchEntered = false;
    }
    else if (hasValue(enteredKFactor)) {
        isKFactorEntered = true;
        isRValuePerInchEntered = false;
    }
    else if (hasValue(enteredRValuePerInch)) {
        isKFactorEntered = false;
        isRValuePerInchEntered = true;
    }

    let rValuePerInchValue = rValuePerInch;

    if (isKFactorEntered || isRValuePerInchEntered) { 
        const defaultRValueEffective = BoxSection.calculateRValueEffective(
            rValuePerInchValue, 
            thickness, 
            sectionType, 
            location,
            material);
        return {
            defaultValue: defaultRValueEffective,
            actualValue: defaultRValueEffective
        };
    }

    if (isCustomValue(state, "rValueEffective", enteredRValueEffective)) {
        rValuePerInchValue = BoxSection.calculateDefaultRValuePerInch(material);
    }

    return getFormField<measure>(
        state,
        "rValueEffective",
        enteredRValueEffective,
        () => BoxSection.calculateRValueEffective(
            rValuePerInchValue, 
            thickness, 
            sectionType, 
            location,
            material)
    );
}

export const getExteriorTemperature = (
    state: IFormState<any>,
    enteredExteriorTemperature: measure,
    sectionType: ISectionType,
    floorType: IFloorType,
    location: IBoxLocation,
    boxExteriorTemperature: measure
): IFormField<measure> => {

    return getFormField<measure>(
        state,
        "exteriorTemperature",
        enteredExteriorTemperature,
        () => BoxSection.calculateExteriorTemperature(
            boxExteriorTemperature,
            sectionType,
            location,
            floorType)
    );
}

export const getExteriorHumidity = (
	state: IFormState<ISection>,
    enteredExteriorHumidity: measure,
    boxExteriorHumidity: measure
): IFormField<measure> => {

    const getDefaultValue = () => {
        return boxExteriorHumidity;
    };

	return getFormField<measure>(
		state,
		"exteriorHumidity",
        enteredExteriorHumidity,
        getDefaultValue
	);
};

export const getConductionLoad = (
    state: IFormState<ISection>,
    enteredLoad: measure,
    sectionType: ISectionType,
    floorType: IFloorType,
    location: IBoxLocation,
    area: measure,
    perimeter: measure,
    rValueEffective: measure,
    exteriorTemperature: measure,
    boxExteriorTemperature: measure,
    boxInteriorTemperature: measure,
    openings: IOpening[] | undefined
): IFormField<measure> => {
    
    return getFormField<measure>(
        state,
        "conductionLoad",
        enteredLoad,
        () => BoxSection.calculateConductionLoad(
            sectionType,
            floorType,
            location,
            area,
            perimeter,
            rValueEffective,
            exteriorTemperature,
            boxExteriorTemperature,
            boxInteriorTemperature,
            openings)
    );
};