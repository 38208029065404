import * as React from "react";

import { useHistory } from "react-router-dom";

import BoxApplications from "./BoxApplications";
import Button from "../helpers/Button";
import AWEFMessage from "./AWEFDialog";
import LoadCalcConfirmation from "./LoadCalcConfirmation";

import useBoxContext from "../../hooks/useBoxContext";
import useSystemContext from "../../hooks/useSystemContext";
import useTranslationContext from "../../hooks/useTranslationContext";

import loadAWEFHelpMeDecideUrlUseCase from "../../useCases/loadAWEFHelpMeDecideUrlUseCase";
import StorageFacade from "../../services/StorageFacade";

const SelectNewBoxApplication = () => {

	const [selectedApplicationId, setSelectedApplicationId] = React.useState(-1);
	const [isEquipmentOnly, setIsEquipmentOnly] = React.useState(false);
	const [showLoadCalcConfirmation , setShowLoadCalcConfirmation] = React.useState(false);
	const [showAwefConfirmation , setShowAwefConfirmation] = React.useState(false);

	const history = useHistory();

	const { loadBox } = useBoxContext();
	const { dispatch } = useSystemContext();
	const { translations } = useTranslationContext();

	const toggleModal = () => {
        setShowLoadCalcConfirmation(!showLoadCalcConfirmation);
	};

	const handleOkButtonClick = () => {
		setShowLoadCalcConfirmation(true);
	};

	const handleSelectApplicationId = (applicationId: number) => {
		setSelectedApplicationId(applicationId);
	};

	const handleSubmitSelectedApplicationId = (applicationId: number) => {
        handleSelectApplicationId(applicationId);
        handleOkButtonClick();
    };

	const handleLoadCalculatorButtonClick = () => {
		setIsEquipmentOnly(false);
		setShowLoadCalcConfirmation(false);
		setShowAwefConfirmation(true);
	};

	const handleEquipmentSelectorButtonClick = () => {
		setIsEquipmentOnly(true);
		setShowLoadCalcConfirmation(false);
		setShowAwefConfirmation(true);
	};

	const resetState = (requireAwef: boolean) => {
		// Upon selecting a box application,
		// reset the system state and the local storage to an empty system where only the box application id is set.
		dispatch({ 
			type: "reset-state", 
			boxApplicationId: selectedApplicationId,
			isEquipmentOnly,
			requireAwef,
			updateStore: true
		});

		// Reset the box context.
		loadBox(selectedApplicationId);

		// Go to next screen.
		if (isEquipmentOnly) {
			history.push("/selectequipment");
		}
		else {
			history.push("/loadcalculator");
		}
	}

	const awefConfirmationMessage = {
		title: "AWEF Compliance",
		message: <div>Do you require AWEF-compliant equipment? </div>,
		onYesClick: () => { resetState(true) },
		onNoClick: () => { resetState(false) },
		onCancelClick: () => { setShowAwefConfirmation(false); },
	    onHelpDecieClick: () => { window.open(loadAWEFHelpMeDecideUrlUseCase(StorageFacade.project.Division.id), "_blank");	}
	};

	return (
		<>
			<BoxApplications 
				onClick={handleSelectApplicationId} 
				onDoubleClick={handleSubmitSelectedApplicationId} 
			/>
			<div className="block">
				{selectedApplicationId > 0 && (
					<div className="level">
						<div className="level-left">
							<div className="level-item">
								<Button
									name="cancel-box"
									label={translations.cancel}
									className="button is-primary"
									onClick={() => { history.goBack(); }}
								/>
							</div>
						</div>
						<div className="level-right">
							<div className="level-item">
								<Button
									name="select-box"
									label={translations.oK}
									className="button is-link"
									onClick={handleOkButtonClick}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
			<LoadCalcConfirmation 
				show={showLoadCalcConfirmation} 
				onCloseClick={toggleModal}
				onLoadCalcButtonClick={handleLoadCalculatorButtonClick}
				onEquipmentSelectorButtonClick={handleEquipmentSelectorButtonClick}
			/>
			<AWEFMessage
				title={awefConfirmationMessage.title}
				message={awefConfirmationMessage.message}
				onYesClick={awefConfirmationMessage.onYesClick}
				onNoClick={awefConfirmationMessage.onNoClick}
				onCancelClick={awefConfirmationMessage.onCancelClick}
				onHelpDecieClick={awefConfirmationMessage.onHelpDecieClick}
				show={showAwefConfirmation}				
			/>
		</>
	);
};

export default SelectNewBoxApplication;
