
const production = {
    API_URL: "/api/models/v1.0"
};

const development = {
    // API_URL: "https://localhost:44369/api/models/v1.0" 
    API_URL: "https://uat-rewrite-keeprite.azurewebsites.net/api/models/v1.0"
};

export const Config = process.env.NODE_ENV === "development" ? development : production;

export const SECTION_CENTRE_DISTANCE_DEFAULT: number = 16;
export const SECTION_WIDTH_DEFAULT: number = 1.5;
