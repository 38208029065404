/* eslint-disable no-underscore-dangle */
import * as React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

import FloorType from "../../enums/FloorType";

import FloorTypesModal from "./FloorTypesModal";

import useTranslationContext from "../../hooks/useTranslationContext";

export interface FloorTypesProps {
    isFreezer: boolean;
    selected: FloorType;
    showBoxSetting?: boolean;
    boxSetting?: FloorType;
    onChange: any;
}

const FloorTypesButton: React.FunctionComponent<FloorTypesProps> = (
	props: FloorTypesProps
) => {    
    const { translations } = useTranslationContext();
    
    const floorTypeName = (floorType: FloorType): string => {
        let selectedFloorType = floorType;
        
        if (floorType === FloorType.BoxSetting && props.boxSetting) {
            selectedFloorType = props.boxSetting;
        }

        switch (selectedFloorType) {
            case FloorType.OnGrade:
                return translations.onGrade;
            case FloorType.AboveGrade:
                return translations.aboveGrade;
            case FloorType.OnGradeHeated:
                return translations.onGrade_FrostHeaveProtected;
            default:
                return "";
        }
    }

	return (
        <FloorTypesModal
            isFreezer={props.isFreezer}
            selected={props.selected}
            onChange={props.onChange}
            showBoxSetting={props.showBoxSetting ?? false}
            boxSetting={props.boxSetting}
            activator={({ setShow } : { setShow: any}) => (
                <button type="button" name="show-floor-types" className="button is-light is-small" onClick={() => setShow(true)}>
                    <span>
                        {floorTypeName(props.selected)}
                    </span>
                    <span className="icon">
                        <FontAwesomeIcon icon={faAngleDown} />
                    </span>
                </button>
            )}
        />
    );
};

export default FloorTypesButton;
