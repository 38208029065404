/* eslint-disable no-underscore-dangle */

import BoxLocation from "../enums/BoxLocation";
import BoxType from "../enums/BoxType";
import FloorType from "../enums/FloorType";
import Material from "../enums/Material";
import Radians from "../enums/Radians";
import SectionLocation from "../enums/SectionLocation";
import SectionType from "../enums/SectionType";
import WallType from "../enums/WallType";

import IBoxSectionRecalculateFormParams from "../models/interfaces/IBoxSectionRecalculateFormParams";
import IFormState from "../models/interfaces/IFormState";
import IMessage from "../models/interfaces/IMessage";
import IOpening from "../models/interfaces/IOpening";
import ISection from "../models/interfaces/ISection";
import ISectionType from "../models/interfaces/ISectionType";

import { hasValue, getNumericValue, measure } from "../types/measure";

import useApplicationConfigs from "./useApplicationConfigs";
import useBoxMaterialsContext from "./useBoxMaterialsContext";
import useOpeningDetailsForm from "./useOpeningDetailsForm";
import useTranslationContext from "./useTranslationContext";

import * as BoxSectionForm from "../models/helpers/BoxSectionFormFields";
import * as BoxSectionCalculations from "../models/helpers/BoxSectionCalculations";
import { calculateTotalDoorLength, calculateOpeningsTotalArea } from "../models/helpers/OpeningCalculations";

const useBoxSectionForm = () => {

    const { 
        getBoxLocationConfig, 
        getSectionBoxLocationConfig, 
        getSectionTypes, 
        getSectionTypeConfig,
        getBoxTypeConfig,
        getFloorTypeConfig,
        getSectionFloorTypeConfig
    } = useApplicationConfigs();

    const { getDefaultOpening, recalculateOpening } = useOpeningDetailsForm();

    const { boxMaterials, getBoxMaterial } = useBoxMaterialsContext();
    const { translations } = useTranslationContext();

    const validateSection = (
        section: ISection,
        boxFloorType: FloorType | null,
        boxInteriorTemperature: measure
    ): IMessage[] => {

        const errors: IMessage[] = [];

        if (section.sectionType === SectionType.Undefined) {
            errors.push({
                name: "sectionType", 
                text: translations.sectionTypeMustBeSelected_
            });
        }

        if (section.sectionName === "") { // warning
            errors.push({
                name: "sectionName", 
                text: translations.sectionNameNotProvided_
            });
        }

        if (!hasValue(section.width)) {
            if (section.sectionType === SectionType.WallCustom
                && !hasValue(section.area)) {
                    errors.push({
                        name: "width",
                        text: translations.dimension_IsNotEntered_
                    });
                }
        }

        if (!hasValue(section.length)) {
            if ((section.sectionType === SectionType.FloorCustom
                || section.sectionType === SectionType.CeilingCustom)
                && !hasValue(section.area)) {
                    errors.push({
                        name: "length",
                        text: translations.dimension_IsNotEntered_
                    });
                }
        }

        if (!hasValue(section.dimension1)
            && !hasValue(section.area)
            && (section.sectionType === SectionType.WallPolygon
                || section.sectionType === SectionType.FloorPolygon
                || section.sectionType === SectionType.CeilingPolygon)
        ) {
            errors.push({
                name: "dimension1",
                text: translations.dimension_IsNotEntered_
            });
        }

        if (!hasValue(section.height)) {
            if (section.sectionType === SectionType.WallCustom
                && !hasValue(section.area)) {
                    errors.push({
                        name: "height",
                        text: translations.dimension_IsNotEntered_.replace("1", "2")
                    });
                }
        }

        if (!hasValue(section.depth)) {
            if ((section.sectionType === SectionType.FloorCustom
                || section.sectionType === SectionType.CeilingCustom)
                && !hasValue(section.area)) {
                    errors.push({
                        name: "depth",
                        text: translations.dimension_IsNotEntered_.replace("1", "2")
                    });
                }
        }

        if (!hasValue(section.dimension2)
            && !hasValue(section.area)
            && (section.sectionType === SectionType.WallPolygon
                || section.sectionType === SectionType.FloorPolygon
                || section.sectionType === SectionType.CeilingPolygon)
        ) {
            errors.push({
                name: "dimension2",
                text: translations.dimension_IsNotEntered_.replace("1", "2")
            });
        }

        if (!hasValue(section.area)) {
            errors.push({
                name: "area", 
                text: translations.areaIsNotCalculatedOrEntered_
            });
        }

        if (!hasValue(section.perimeter)) {
            if ((section.sectionType === SectionType.FloorCustom 
                || section.sectionType === SectionType.FloorPolygon)
                && (getSectionFloorTypeConfig(section.floorType, boxFloorType).id === FloorType.OnGrade
                    || getSectionFloorTypeConfig(section.floorType, boxFloorType).id === FloorType.OnGradeHeated)
                && !hasValue(section.conductionLoad)) {
                    errors.push({
                        name: "perimeter",
                        text: translations.thePerimeterLengthMustBeEnteredForSlabOnGradeFloors_
                    });
                }
        }

        if (!hasValue(section.conductionLoad)
            && section.materialId === Material.Other
            && section.materialName === "") {
            errors.push({
                name: "otherMaterialName", 
                text: translations.materialNameMustBeEntered_
            });
        }

        if (!hasValue(section.thickness)
            && !hasValue(section.conductionLoad)
            && !hasValue(section.rValueEffective)) {
            errors.push({
                name: "thickness", 
                text: translations.thicknessIsNotEntered_
            });
        }

        if (!hasValue(section.kFactor)
            && !hasValue(section.rValuePerInch)
            && !hasValue(section.conductionLoad)
            && !hasValue(section.rValueEffective)) {
            errors.push({
                name: "kFactor", 
                text: translations.neitherTheKFactorNorTheR_ValueHasBeenEntered_
            });
        }

        if (!hasValue(section.rValueEffective)
            && !hasValue(section.conductionLoad)) {
            errors.push({
                name: "rValueEffective", 
                text: translations.theEffectiveR_ValueIsNotCalculatedOrEntered_
            });
        }

        if (!hasValue(section.exteriorTemperature)
            && !hasValue(section.conductionLoad)) {
            errors.push({
                name: "exteriorTemperature", 
                text: translations.theExternalTemperatureIsNotEntered_
            });
        }
        else if (hasValue(section.exteriorTemperature)
            && hasValue(boxInteriorTemperature)
            && getNumericValue(section.exteriorTemperature) < getNumericValue(boxInteriorTemperature)) { // warning
                errors.push({
                    name: "exteriorTemperature", 
                    text: translations.theExternalTemperatureIsLowerThanTheInternalTemperature_
                });
            }
        else if (getNumericValue(section.exteriorTemperature) < -100) {
            errors.push({
                name: "exteriorTemperature", 
                text: translations.theExternalTemperatureIsTooLow_
            });
        }
        else if (getNumericValue(section.exteriorTemperature) > 150) {
            errors.push({
                name: "exteriorTemperature", 
                text: translations.theExternalTemperatureIsTooHigh_
            });
        }

        if (!hasValue(section.exteriorHumidity)) {
            errors.push({
                name: "exteriorHumidity", 
                text: translations.theExternalRelativeHumidityNotEntered_
            });
        }
        else if (getNumericValue(section.exteriorHumidity) < 0) {
            errors.push({
                name: "exteriorHumidity", 
                text: translations.theExternalRelativeHumidityMustBeGreaterThanZero_
            });
        }
        else if (getNumericValue(section.exteriorHumidity) > 100) {
            errors.push({
                name: "exteriorHumidity", 
                text: translations.theExternalRelativeHumidityMustBeLessThan____
            });
        }

        if (!hasValue(section.conductionLoad)
            && section.materialId !== Material.ZoneBoundary
        ) {
            errors.push({
                name: "conductionLoad", 
                text: translations.materialLoadIsNotCalculatedOrEntered_
            });
        }

        const totalDoorLength = calculateTotalDoorLength(section.openings);
        if (section.dimension1) {
            if (totalDoorLength > section.dimension1) {
                errors.push({
                    name: "totalDoorLength", 
                    text: translations.totalDoorWidthExceedsSectionWidth
                });
            }
        }

        const totalOpeningsArea = calculateOpeningsTotalArea(section.openings);
        if (section.area) {
            if (totalOpeningsArea > section.area) {
                errors.push({
                    name: "totalOpeningsArea", 
                    text: translations.totalOpeningAreaExceedsSectionArea
                });
            }
        }

        if (section.openings) {
            if (section.openings.find(o => o.errors && o.errors.length > 0)) {
                errors.push({
                    name: "openings", 
                    text: translations.sectionHasOpeningErrors_
                });
            }
        }
        
        return errors;
    };

    const getDefaultSectionName = (
        sectionType: ISectionType,
        wallType: WallType
    ): string => {
        switch(sectionType.id)
        {
            case SectionType.CeilingCustom:
            case SectionType.CeilingPolygon:
                return translations.ceiling;
            case SectionType.FloorCustom:
            case SectionType.FloorPolygon:
                return translations.floor;
            case SectionType.WallCustom:
                return translations.wall;
            case SectionType.WallPolygon:
                switch(wallType)
                {
                    case WallType.Rear:
                        return translations.rear;
                    case WallType.Front:
                        return translations.front;
                    case WallType.FrontCut:
                        return translations.frontCut;
                    case WallType.FrontInsetCut:
                        return translations.frontInset;
                    case WallType.LeftCut:
                        return translations.leftCut;
                    case WallType.LeftEnd:
                        return translations.leftEnd;
                    case WallType.LeftInSetCut:
                        return translations.leftInset;
                    case WallType.RightEnd:
                        return translations.rightEnd;
                    case WallType.Undefined:
                        return translations.section;
                    default:
                        return "";
                }
            default:
                return translations.section;
        }
    };

    const getDefaultSection = (
        boxTypeId: BoxType | null,
        sectionTypeId: SectionType,
        wallType: WallType,
        radians: Radians,
        isCustomSection: boolean,
        boxLocation: BoxLocation | null,
        boxFloorType: FloorType | null,
        boxLength: measure,
        boxLength2: measure,
        boxLength3: measure,
        boxDepth: measure,
        boxDepth2: measure,
        boxDepth3: measure,
        boxHeight: measure,
        boxExteriorTemperature: measure,
        boxExteriorHumidity: measure,
        boxInteriorTemperature: measure
    ): ISection => {

        const sectionType = getSectionTypeConfig(sectionTypeId);
        const boxType = getBoxTypeConfig(boxTypeId);
        const floorType = getFloorTypeConfig(boxFloorType);
        const location = getBoxLocationConfig(boxLocation);

        const sectionName = getDefaultSectionName(sectionType, wallType);

        let width;
        let height;
        let length;
        let depth;
        let dimension1;
        let dimension2;
        let area;
        let perimeter;

        if (isCustomSection) {

            width = null;
            length = null;
            perimeter = null;

            height = boxHeight;
            depth = boxDepth;

            dimension1 = BoxSectionCalculations.calculateCustomSectionDimension1(sectionType, width, length);
            dimension2 = BoxSectionCalculations.calculateCustomSectionDimension2(sectionType, height, depth);

            area = BoxSectionCalculations.calculateCustomSectionArea(
                sectionType,
                width,
                length,
                height,
                depth);
        }
        else {

            dimension1 = BoxSectionCalculations.calculatePolygonSectionDimension1(sectionType, wallType, boxType, boxLength, boxLength2, boxLength3, boxDepth, boxDepth2, boxDepth3);
            dimension2 = BoxSectionCalculations.calculatePolygonSectionDimension2(sectionType, boxType, boxHeight, boxDepth);
            
            area = BoxSectionCalculations.calculatePolygonSectionArea(sectionType, boxType, dimension1, dimension2, boxLength, boxLength2, boxLength3, boxDepth, boxDepth2, boxDepth3);
            
            perimeter = BoxSectionCalculations.calculateBoxPerimeter(boxType, sectionType, boxLength, boxLength2, boxLength3, boxDepth, boxDepth2, boxDepth3);
        }

        const defaultMaterialId = 0;
        const actualMaterialId = BoxSectionForm.getDefaultMaterialId(sectionType, floorType);
        const material = getBoxMaterial(actualMaterialId);
        const materialName = material ? BoxSectionForm.getDefaultMaterialName(material) : "";

        let thickness;
        let kFactor;
        let rValuePerInch;

        if (material) {
            thickness = BoxSectionForm.getDefaultThickness(sectionType, floorType, material);
            kFactor = BoxSectionCalculations.calculateKFactor(null, null, thickness, material);
            rValuePerInch = BoxSectionCalculations.calculateRValuePerInch(null, null, thickness, material);
        }
        
        const rValueEffective = BoxSectionCalculations.calculateRValueEffective(rValuePerInch, thickness, sectionType, location, material);
                        
        let exteriorTemperature = BoxSectionCalculations.calculateExteriorTemperature(boxExteriorTemperature, sectionType, location, floorType);
        const exteriorHumidity = boxExteriorHumidity;
        const conductionLoad = BoxSectionCalculations.calculateConductionLoad(sectionType, floorType, location, area, perimeter, rValueEffective, exteriorTemperature, boxExteriorTemperature, boxInteriorTemperature, undefined);

        if ((sectionType.id === SectionType.FloorCustom || sectionType.id === SectionType.FloorPolygon)
            && (floorType.id === FloorType.OnGrade || floorType.id === FloorType.OnGradeHeated)
        ) {
            exteriorTemperature = undefined;
        }

        const areaWithoutOpenings = BoxSectionCalculations.calculateSectionAreaWithoutOpenings(area, undefined);
        const conductionLoadWithoutOpenings = BoxSectionCalculations.calculateConductionLoadWithoutOpenings(conductionLoad, undefined);

        const defaultSection: ISection = {
            sectionType: sectionTypeId,
            wallType,
            radians,
            sectionLocation: SectionLocation.BoxLocation,
            floorType: FloorType.BoxSetting,
            sectionName,
            materialId: defaultMaterialId,
            materialName,
            thickness,
            kFactor,
            rValuePerInch,
            rValueEffective,
            exteriorTemperature,
            exteriorHumidity,
            width,
            length,
            height,
            depth,
            dimension1,
            dimension2,
            area,
            areaWithoutOpenings,
            perimeter,
            conductionLoad,
            conductionLoadWithoutOpenings,
            isModified: false,
            isCustom: isCustomSection
        };

        const errors = validateSection(defaultSection, boxFloorType ?? FloorType.OnGrade, boxInteriorTemperature);

        defaultSection.errors = errors;

        return defaultSection;
    };

    const getPolygonSections = (
        boxTypeId: BoxType | null,
        boxFloorType: FloorType | null,
        boxLocation: BoxLocation | null,
        boxLength: measure,
        boxLength2: measure,
        boxLength3: measure,
        boxDepth: measure,
        boxDepth2: measure,
        boxDepth3: measure,
        boxHeight: measure,
        boxExteriorTemperature: measure,
        boxInteriorTemperature: measure,
        boxExteriorHumidity: measure
    ): ISection[] => {
        const boxSections = [] as ISection[];

        const boxType = getBoxTypeConfig(boxTypeId);

        boxType.sectionsConfig.forEach(c => {
            boxSections.push(getDefaultSection(
                boxTypeId,
                c.sectionType.id,
                c.wallType,
                c.radians,
                false,
                boxLocation,
                boxFloorType,
                boxLength,
                boxLength2,
                boxLength3,
                boxDepth,
                boxDepth2,
                boxDepth3,
                boxHeight,
                boxExteriorTemperature,
                boxExteriorHumidity,
                boxInteriorTemperature
            ));
        });

        return boxSections;
    };

    const recalculateSection = (
        state: IFormState<ISection>,
        formInput: IBoxSectionRecalculateFormParams | null,
        boxLocation: BoxLocation | null,
        boxFloorType: FloorType | null,
        boxHeight: measure,
        boxDepth: measure,
        boxDepth2: measure,
        boxDepth3: measure,
        boxLength: measure,
        boxLength2: measure,
        boxLength3: measure,
        boxTypeId: BoxType | null,
        boxExteriorTemperature: measure,
        boxExteriorHumidity: measure,
        boxInteriorTemperature: measure,
        boxInteriorHumidity: measure
    ): IFormState<ISection> => {

        const sectionTypeId = BoxSectionForm.getSectionTypeId(state, formInput?.sectionType);
        const sectionType = getSectionTypeConfig(sectionTypeId);
        const defaultSectionName = getDefaultSectionName(sectionType, state.actualValues.wallType);
        const sectionNameField = BoxSectionForm.getSectionName(state, formInput?.sectionName, defaultSectionName);
        
        const sectionLocationId = BoxSectionForm.getSectionLocation(state, formInput?.sectionLocation);
        const location = getSectionBoxLocationConfig(sectionLocationId, boxLocation);

        const sectionFloorTypeId = BoxSectionForm.getFloorType(state, formInput?.floorType);
        const floorType = getSectionFloorTypeConfig(sectionFloorTypeId, boxFloorType);

        const boxType = getBoxTypeConfig(boxTypeId);

        let widthField;
        let heightField;
        let lengthField;
        let depthField;
        let areaField;
        let perimeterField;

        let dimension1: measure;
        let dimension2: measure;

        if (state.actualValues.isCustom) {
            widthField = BoxSectionForm.getWidth(state, formInput?.width);
            heightField = BoxSectionForm.getHeight(state, formInput?.height, boxHeight);
            lengthField = BoxSectionForm.getLength(state, formInput?.length);
            depthField = BoxSectionForm.getDepth(state, formInput?.depth, boxDepth);

            dimension1 = BoxSectionCalculations.calculateCustomSectionDimension1(sectionType, widthField.actualValue, lengthField.actualValue);
            dimension2 = BoxSectionCalculations.calculateCustomSectionDimension2(sectionType, heightField.actualValue, depthField.actualValue);

            areaField = BoxSectionForm.getCustomSectionArea(
                state, 
                formInput?.area, 
                sectionType,
                widthField.actualValue, 
                lengthField.actualValue,
                heightField.actualValue,
                depthField.actualValue);
    
            perimeterField = BoxSectionForm.getSectionPerimeter(state, formInput?.perimeter);
        }
        else {
            dimension1 = BoxSectionCalculations.calculatePolygonSectionDimension1(
                sectionType, 
                state.actualValues.wallType, 
                boxType, 
                boxLength, 
                boxLength2, 
                boxLength3, 
                boxDepth, 
                boxDepth2, 
                boxDepth3);
            dimension2 = BoxSectionCalculations.calculatePolygonSectionDimension2(
                sectionType, 
                boxType, 
                boxHeight, 
                boxDepth);

            const calculatedArea = BoxSectionCalculations.calculatePolygonSectionArea(
                sectionType, 
                boxType, 
                dimension1, 
                dimension2, 
                boxLength, 
                boxLength2, 
                boxLength3, 
                boxDepth, 
                boxDepth2, 
                boxDepth3);
            const calculatedPerimeter = BoxSectionCalculations.calculateBoxPerimeter(
                boxType, 
                sectionType, 
                boxLength, 
                boxLength2, 
                boxLength3, 
                boxDepth, 
                boxDepth2, 
                boxDepth3);

            areaField = { 
                defaultValue: calculatedArea,
                actualValue: calculatedArea
            };
            perimeterField = { 
                defaultValue: calculatedPerimeter,
                actualValue: calculatedPerimeter
            };
        }

        const selectedMaterialId = formInput?.materialId ?? state.actualValues.materialId ?? 0;
        const actualMaterialId = BoxSectionForm.getMaterialId(
            state,
            formInput?.materialId,
            sectionType,
            floorType);
        const material = getBoxMaterial(actualMaterialId);

        let materialNameField;
        let thicknessField;
        let kFactorField;
        let rValuePerInchField;
        let rValueEffectiveField;

        if (material) {
            materialNameField = BoxSectionForm.getMaterialName(state, formInput?.materialName, material);

            thicknessField = BoxSectionForm.getThickness(
                state, 
                formInput?.thickness,
                sectionType,
                floorType,
                material);
            
            kFactorField = BoxSectionForm.getKFactor(
                state, 
                formInput?.kFactor,
                formInput?.rValuePerInch,
                formInput?.rValueEffective,
                thicknessField.actualValue,
                material);

            rValuePerInchField = BoxSectionForm.getRValuePerInch(
                state, 
                formInput?.rValuePerInch,
                formInput?.kFactor,
                formInput?.rValueEffective,
                thicknessField.actualValue,
                material);

            rValueEffectiveField = BoxSectionForm.getRValueEffective(
                state,
                formInput?.rValueEffective,
                formInput?.kFactor,
                formInput?.rValuePerInch,
                rValuePerInchField?.actualValue,
                thicknessField?.actualValue,
                sectionType,
                location,
                material);
        }

        if (material && material.locked) {
            if (thicknessField) {
                thicknessField.actualValue = thicknessField.defaultValue;
            }
            if (kFactorField) {
                kFactorField.actualValue = kFactorField.defaultValue;
            }
            if (rValuePerInchField) {
                rValuePerInchField.actualValue = rValuePerInchField.defaultValue;
            }
            if (rValueEffectiveField) {
                rValueEffectiveField.actualValue = rValueEffectiveField.defaultValue;
            }
        }

        const exteriorTemperatureField = BoxSectionForm.getExteriorTemperature(
            state,
            formInput?.exteriorTemperature,
            sectionType,
            floorType,
            location,
            boxExteriorTemperature);

        const exteriorHumidityField = BoxSectionForm.getExteriorHumidity(
            state,
            formInput?.exteriorHumidity,
            boxExteriorHumidity);

        const openings = [] as IOpening[];
        state.actualValues.openings?.forEach(opening => {
            // Recalculate each opening in the box section
            const defaultOpening = getDefaultOpening(
                opening.openingType,
                exteriorTemperatureField.actualValue,
                exteriorHumidityField.actualValue,
                dimension1,
                dimension2,
                boxInteriorTemperature,
                boxInteriorHumidity);

            const openingFormState = recalculateOpening(
                {
                    defaultValues: defaultOpening,
                    actualValues: opening
                },
                null,
                exteriorTemperatureField.actualValue,
                exteriorHumidityField.actualValue,
                dimension1,
                dimension2,
                boxInteriorTemperature,
                boxInteriorHumidity);

            openings.push(openingFormState.actualValues);
        });

        const conductionLoadField = BoxSectionForm.getConductionLoad(
            state,
            formInput?.conductionLoad, 
            sectionType,
            floorType,
            location, 
            areaField.actualValue, 
            perimeterField.actualValue,
            rValueEffectiveField?.actualValue, 
            exteriorTemperatureField.actualValue, 
            boxExteriorTemperature,
            boxInteriorTemperature,
            openings);

        const areaWithoutOpenings = BoxSectionCalculations.calculateSectionAreaWithoutOpenings(areaField.actualValue, openings);
        const conductionLoadWithoutOpenings = BoxSectionCalculations.calculateConductionLoadWithoutOpenings(conductionLoadField.actualValue, openings);

        if ((sectionType.id === SectionType.FloorCustom || sectionType.id === SectionType.FloorPolygon)
            && (floorType.id === FloorType.OnGrade || floorType.id === FloorType.OnGradeHeated)
        ) {
            exteriorTemperatureField.defaultValue = undefined;
            exteriorTemperatureField.actualValue = undefined;
        }

        const defaultValues: ISection = {
            ...state.defaultValues,
            sectionName: sectionNameField.defaultValue,
            width: widthField?.defaultValue,
            length: lengthField?.defaultValue,
            height: heightField?.defaultValue,
            depth: depthField?.defaultValue,
            dimension1,
            dimension2,
            area: areaField.defaultValue,
            perimeter: perimeterField.defaultValue,
            materialName: materialNameField?.defaultValue ?? "",
            thickness: thicknessField?.defaultValue,
            kFactor: kFactorField?.defaultValue,
            rValuePerInch: rValuePerInchField?.defaultValue,
            rValueEffective: rValueEffectiveField?.defaultValue,
            exteriorTemperature: exteriorTemperatureField.defaultValue,
            exteriorHumidity: exteriorHumidityField.defaultValue,
            conductionLoad: conductionLoadField.defaultValue
        };

        const actualValues: ISection = {
            ...state.actualValues,
            sectionType: sectionTypeId,
            sectionName: sectionNameField.actualValue,
            sectionLocation: sectionLocationId,
            floorType: sectionFloorTypeId,
            width: widthField?.actualValue,
            length: lengthField?.actualValue,
            height: heightField?.actualValue,
            depth: depthField?.actualValue,
            dimension1,
            dimension2,
            area: areaField.actualValue,
            areaWithoutOpenings,
            perimeter: perimeterField.actualValue,
            materialId: selectedMaterialId,
            materialName: materialNameField?.actualValue ?? "",
            thickness: thicknessField?.actualValue,
            kFactor: kFactorField?.actualValue,
            rValuePerInch: rValuePerInchField?.actualValue,
            rValueEffective: rValueEffectiveField?.actualValue,
            exteriorTemperature: exteriorTemperatureField.actualValue,
            exteriorHumidity: exteriorHumidityField.actualValue,
            conductionLoad: conductionLoadField.actualValue,
            conductionLoadWithoutOpenings,
            openings
        };

        const errors = validateSection(actualValues, boxFloorType ?? FloorType.OnGrade, boxInteriorTemperature);
        
        return {
            defaultValues,
            actualValues: {
                ...actualValues,
                errors
            }
        };
    };

    const resetForm = (
        sectionType: SectionType,
        boxLocation: BoxLocation,
        boxFloorType: FloorType,
        boxHeight: measure,
        boxDepth: measure,
        boxDepth2: measure,
        boxDepth3: measure,
        boxLength: measure,
        boxLength2: measure,
        boxLength3: measure,
        boxTypeId: BoxType,
        boxExteriorTemperature: measure,
        boxExteriorHumidity: measure,
        boxInteriorTemperature: measure
    ): IFormState<ISection> => {
        
        const defaultSection = getDefaultSection(
            boxTypeId,
            sectionType,
            WallType.Undefined,
            Radians.None,
            true,
            boxLocation,
            boxFloorType,
            boxLength,
            boxLength2,
            boxLength3,
            boxDepth,
            boxDepth2,
            boxDepth3,
            boxHeight,            
            boxExteriorTemperature,
            boxExteriorHumidity,
            boxInteriorTemperature);

        return {
            defaultValues: defaultSection,
            actualValues: defaultSection
        };
    };

    const resetFormForEdit = (
        sectionToEdit: ISection,
        boxLocation: BoxLocation,
        boxFloorType: FloorType,
        boxHeight: measure,
        boxDepth: measure,
        boxDepth2: measure,
        boxDepth3: measure,
        boxLength: measure,
        boxLength2: measure,
        boxLength3: measure,
        boxTypeId: BoxType,
        boxExteriorTemperature: measure,
        boxExteriorHumidity: measure,
        boxInteriorTemperature: measure,
        boxInteriorHumidity: measure
    ): IFormState<ISection> => {

        const defaultValues = getDefaultSection(
            boxTypeId,
            sectionToEdit.sectionType,
            sectionToEdit.wallType,
            sectionToEdit.radians,
            sectionToEdit.isCustom,
            boxLocation,
            boxFloorType,
            boxLength,
            boxLength2,
            boxLength3,
            boxDepth,
            boxDepth2,
            boxDepth3,
            boxHeight,            
            boxExteriorTemperature,
            boxExteriorHumidity,
            boxInteriorTemperature);

        const areaWithoutOpenings = BoxSectionCalculations.calculateSectionAreaWithoutOpenings(sectionToEdit.area, sectionToEdit.openings);
        const conductionLoadWithoutOpenings = BoxSectionCalculations.calculateConductionLoadWithoutOpenings(sectionToEdit.conductionLoad, sectionToEdit.openings);

        const actualValues: ISection = {
            ...sectionToEdit,
            areaWithoutOpenings,
            conductionLoadWithoutOpenings
        };

        return recalculateSection(
            {
                defaultValues,
                actualValues
            },
            null,
            boxLocation,
            boxFloorType,
            boxHeight,
            boxDepth,
            boxDepth2,
            boxDepth3,
            boxLength,
            boxLength2,
            boxLength3,
            boxTypeId,
            boxExteriorTemperature,
            boxExteriorHumidity,
            boxInteriorTemperature,
            boxInteriorHumidity);
    };
    
    const setInitialState = (
        sectionToEdit: ISection | undefined,
        boxLocation: BoxLocation,
        boxFloorType: FloorType,
        boxHeight: measure,
        boxDepth: measure,
        boxDepth2: measure,
        boxDepth3: measure,
        boxLength: measure,
        boxLength2: measure,
        boxLength3: measure,
        boxTypeId: BoxType,
        boxExteriorTemperature: measure,
        boxExteriorHumidity: measure,
        boxInteriorTemperature: measure,
        boxInteriorHumidity: measure
    ): IFormState<ISection> => {

        // add section
        if (sectionToEdit === undefined) {
            return resetForm(
                SectionType.WallCustom,
                boxLocation,
                boxFloorType,
                boxHeight,
                boxDepth,
                boxDepth2,
                boxDepth3,
                boxLength,
                boxLength2,
                boxLength3,
                boxTypeId,
                boxExteriorTemperature,
                boxExteriorHumidity,
                boxInteriorTemperature);
        }

        // edit section
        return resetFormForEdit(
            sectionToEdit,
            boxLocation,
            boxFloorType,
            boxHeight,
            boxDepth,
            boxDepth2,
            boxDepth3,
            boxLength,
            boxLength2,
            boxLength3,
            boxTypeId,
            boxExteriorTemperature,
            boxExteriorHumidity,
            boxInteriorTemperature,
            boxInteriorHumidity);
    };

    const isAddMode = (sectionToEdit: ISection | undefined) => {
        return !sectionToEdit;
    };
    
    const isPolygonSection = (sectionToEdit: ISection | undefined) => { 
        return sectionToEdit && !sectionToEdit.isCustom; 
    };
    
    const formTitle = (sectionToEdit: ISection | undefined) => { 
        return isAddMode(sectionToEdit) ? translations.addCustomSection : translations.editSection;
    };

    const visibleSectionTypes = (sectionToEdit: ISection | undefined) => { 
        const sectionTypes = getSectionTypes();       
        if (!isPolygonSection(sectionToEdit)) {
            return sectionTypes.filter(s => s.isCustom);
        }
        if (sectionToEdit) {
            return sectionTypes.filter(s => s.id === sectionToEdit?.sectionType);
        }
        return sectionTypes;
    };

    const getMaterialsLookup = () => {
        const materialsLookup = [{ key: "0", value: "Default" }];

        if (boxMaterials) {
            boxMaterials
                .filter(m => m.showAsSectionType)
                .forEach(m => {
                    materialsLookup.push({
                        key: m.id.toString(),
                        value: `${m.isConstruct ? "Composite :- " : ""}${m.name}${m.example ? `, ${m.example}` : ""}`
                    })
                });
        }

        materialsLookup.push({ key: "-1000001", value: translations.nEWCOMPOSITE });
        materialsLookup.push({ key: "-1000002", value: translations.zoneBoundary_TD___ });
        materialsLookup.push({ key: "-1000000", value: "Other" });

        return materialsLookup;
    };

    return {
        setInitialState,
        getDefaultSection,
        getPolygonSections,
        recalculateSection,
        isAddMode,
        isPolygonSection,
        formTitle,
        visibleSectionTypes,
        getMaterialsLookup,
        validateSection
    };
};

export default useBoxSectionForm;
