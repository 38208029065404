import * as React from "react";

import "./Button.css";

export interface ButtonProps {
    name: string;
    label: string;
    tooltip?: string;
    className?: string;
    disabled?: boolean;
    onClick?: any;
}

const Button: React.FunctionComponent<ButtonProps> = ({
    name,
    label,
    tooltip,
    className,
    disabled,
    onClick
}: ButtonProps) => {

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <div className={`component${tooltip ? " tooltip" : ""}`}>
            <button 
                type="button" 
                name={name}
                className={className ?? ""} 
                disabled={disabled}
                onClick={handleClick}
            >
                {label}
            </button>
            {tooltip && (
                <div className="tooltiptext tooltip-top">
                    <div>{tooltip}</div>
                </div>
            )}
        </div>
    );
};

export default Button;
