import * as React from "react";

import "./FormField.css";

export interface FormFieldProps {
    label: string | JSX.Element;
    children: JSX.Element;
}

const FormField: React.FunctionComponent<FormFieldProps> = ({
    label,
    children
}: FormFieldProps) => {

    return (
        <div className="field is-horizontal margin-narrow">
            <div className="field-label is-small">
                <label className="label">
                    {label}
                </label>
            </div>
            <div className="field-body">
                <div className="field">
                    <div className="control">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormField;
