import * as React from "react";
import ErrorContext from "./ErrorContext";
import IMessage from "../models/interfaces/IMessage";

interface ErrorContextProviderProps {
	children: any;
}

const ErrorContextProvider: React.FunctionComponent<ErrorContextProviderProps> = ({
	children,
}: ErrorContextProviderProps) => {
    const [errorMessages, setErrorMessages] = React.useState([] as IMessage[]);

    const showError = (message: IMessage) => {
        let currentList = [...errorMessages];

        if (message.name === null || message.name === undefined) {
            return;
        }

        const error = currentList.find(e => e.name === message.name);

		// If error message already exists for the key
        if (error !== null &&
            error !== undefined
        ) {
            // Clear the error message if the message is empty
            if (message.text == null || message.text.length <= 0) {
                currentList = currentList.filter(e => e.name !== message.name);
            }
            else if (error.text !== message.text) {
                // replace the message if it is a new message
                currentList = currentList.map(e => {
                    return {
                        name: e.name,
                        text: (e.name === message.name) ? message.text : e.text
                    }
                });
            }
        } else if (message.text !== null && message.text.length > 0) {
            // If error message doesn't exist, add it
            currentList = [
                ...currentList,
                message
            ];
        }
        
        setErrorMessages(currentList);
    };

    const showErrorList = (
        messages: IMessage[],
        reset: boolean
    ) => {
        let currentList = reset 
            ? [] 
            : [...errorMessages];

        messages.forEach(message => {
            if (message.name !== null && message.name !== undefined) {            
                const error = currentList.find(e => e.name === message.name);
                
                // If error message already exists for the key
                if (error !== null &&
                    error !== undefined) {
                        // Clear the error message if the message is empty
                        if (message.text == null || message.text.length <= 0) {
                            currentList = currentList.filter(e => e.name !== message.name);
                        }
                        else if (error.text !== message.text) {
                            // replace the message if it is a new message
                            currentList = currentList.map(e => {
                                return {
                                    name: e.name,
                                    text: (e.name === message.name) ? message.text : e.text
                                }
                            });
                        }
                } else if (message.text !== null && message.text.length > 0) {
                    // If error message doesn't exist, add it
                    currentList = [
                        ...currentList,
                        message
                    ];
                }
            }
        });
        
        setErrorMessages(currentList);
    };

    const hideError = () => {
        setErrorMessages([]);
    };

    const contextValue = {
        errorMessages,
        showError: (message: IMessage) => showError(message),
        showErrorList: (messages: IMessage[], reset: boolean) => showErrorList(messages, reset),
        hideError: () => hideError()
    };

    return (
        <ErrorContext.Provider value={contextValue}>
            {children}
        </ErrorContext.Provider>
    );
};

export default ErrorContextProvider;