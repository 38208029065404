import { measure, getNumericValue, hasValue } from "../../types/measure";

import UnitOfMeasure from "./UnitOfMeasure";

import OpeningType from "../../enums/OpeningType";

import IAirProperty from "../interfaces/IAirProperty";
import IOpening from "../interfaces/IOpening";
import IOpeningType from "../interfaces/IOpeningType";

export const calculateArea = (
	width: measure, 
	height: measure
): measure => {
	if (!hasValue(width) || !hasValue(height)) {
		return undefined;
	}

	return (
		UnitOfMeasure.IntoFt(getNumericValue(width)) *
		UnitOfMeasure.IntoFt(getNumericValue(height))
	);
};

export const calculateTotalArea = (
	quantity: measure, 
	area: measure
): measure => {
	if (!hasValue(quantity) || !hasValue(area)) {
		return undefined;
	}

	return getNumericValue(quantity) * getNumericValue(area);
};

export const calculateOpeningsTotalArea = (
	openings: IOpening[] | undefined
): number => {
	let total = 0;

	if (openings) {
		openings.forEach(o => {
			total += o.totalArea ?? 0;
		});
	}

    return total;
};

export const calculateTotalDoorLength = (
	openings: IOpening[] | undefined
): number => {
	let total = 0;

	if (openings) {
		openings.forEach(o => {
			if (o.openingType === OpeningType.TrailerDockingDoor
				|| o.openingType === OpeningType.LoadingPlatformDoor
			) {
				total += (o.quantity ?? 0) * (UnitOfMeasure.IntoFt(o.width ?? 0) + 2);
			}
			else if (o.openingType === OpeningType.Door
				|| o.openingType === OpeningType.GlassDoor
			) {
				total += (o.quantity ?? 0) * UnitOfMeasure.IntoFt(o.width ?? 0);
			}
		});
	}

    return total;
};

export const calculateDoorOpenTimeRatio = (
	standOpenTimePerDay: measure,
	averagePassageTime: measure,
	passagesPerDay: measure
): measure => {
	if (
		hasValue(standOpenTimePerDay) ||
		(hasValue(averagePassageTime) && hasValue(passagesPerDay))
	) {
		const s = hasValue(standOpenTimePerDay)
			? getNumericValue(standOpenTimePerDay)
			: 0;
		const pt = hasValue(averagePassageTime)
			? getNumericValue(averagePassageTime)
			: 0;
		const p = hasValue(passagesPerDay) 
			? getNumericValue(passagesPerDay) 
			: 0;

		return (p * pt + 60 * s) / (24 * 3600);
	}

	return undefined;
};

export const calculateDoorOpenTimeHours = (
	standOpenTimePerDay: measure,
	averagePassageTime: measure,
	passagesPerDay: measure
): measure => {
	if (
		hasValue(standOpenTimePerDay) ||
		(hasValue(averagePassageTime) && hasValue(passagesPerDay))
	) {
		const s = hasValue(standOpenTimePerDay)
			? getNumericValue(standOpenTimePerDay)
			: 0;
		const pt = hasValue(averagePassageTime)
			? getNumericValue(averagePassageTime)
			: 0;
		const p = hasValue(passagesPerDay) 
			? getNumericValue(passagesPerDay) 
			: 0;

		return (p * pt + 60 * s) / 3600;
	}

	return undefined;
};

export const calculateConductionLoad = (
	area: measure,
	exteriorTemperature: measure,
	rValueEffective: measure,
	curtainValue: measure,
	standOpenTimePerDay: measure,
	averagePassageTime: measure,
	passagesPerDay: measure,
	boxInteriorTemperature: measure,
	openingType: IOpeningType
): measure => {
	if (
		!hasValue(area) ||
		!hasValue(boxInteriorTemperature) ||
		!hasValue(exteriorTemperature)
	) {
		return undefined;
	}

	const td =
		getNumericValue(exteriorTemperature) -
		getNumericValue(boxInteriorTemperature);
	const doorOpenTimeRatio = calculateDoorOpenTimeRatio(
		standOpenTimePerDay,
		averagePassageTime,
		passagesPerDay
	);

	if (!hasValue(doorOpenTimeRatio) && openingType.closes) {
		return undefined;
	}

	let openTime = 24 * getNumericValue(doorOpenTimeRatio);
	if (!openingType.closes) {
		openTime = 24;
	} else if (!openingType.opens) {
		openTime = 0;
	}

	const r = hasValue(rValueEffective) ? getNumericValue(rValueEffective) : 0;
	const rc = hasValue(curtainValue) ? getNumericValue(curtainValue) : 0;

	let qClose = 0;
	let qOpen = 0;

	if (openingType.closes && r > 0) {
		qClose = (td * getNumericValue(area) * (24 - openTime)) / r / 24;
	}

	if (openingType.opens && rc > 0) {
		qOpen = (td * getNumericValue(area) * openTime) / rc / 24;
	}

	return qClose + qOpen;
};

export const calculateTotalConductionLoad = (
	quantity: measure, 
	conductionLoad: measure
): measure => {
	if (!hasValue(quantity) || !hasValue(conductionLoad)) {
		return undefined;
	}

	return getNumericValue(quantity) * getNumericValue(conductionLoad);
};

export const calculateOpeningsTotalConductionLoad = (
	openings: IOpening[]
): number => {
	let total= 0;

    openings.forEach(o => {
        total += o.totalConductionLoad ?? 0;
    });

    return total;
};

export const calculateTrailerArea = (
	width: measure,
	height: measure,
	openingType: IOpeningType
): measure => {
	const gap = openingType.dockDoorGap;

	if (!hasValue(width)
		|| !hasValue(height)
		|| !hasValue(gap)
	) {
		return undefined;
	}

	return (UnitOfMeasure.IntoFt(getNumericValue(width)) - (getNumericValue(gap) / 6)) 
		* (UnitOfMeasure.IntoFt(getNumericValue(height)) - (getNumericValue(gap) / 6));
};

export const calculateInfiltrationLoad = (
	width: measure,
	height: measure,
	area: measure,
	infiltratingAirRh: measure,
	curtainEffectiveness: measure,
	standOpenTimePerDay: measure,
	averagePassageTime: measure,
    passagesPerDay: measure,
    exteriorTemperature: measure,
    exteriorTemperatureAirProperty: IAirProperty,
	boxInteriorTemperature: measure,
	boxInteriorTemperatureAirProperty: IAirProperty,
	boxInteriorRh: measure,
	openingType: IOpeningType
): measure => {
	if (
		!hasValue(width) ||
		!hasValue(height) ||
		!hasValue(area) ||
		!hasValue(boxInteriorTemperature) ||
		!hasValue(exteriorTemperature) ||
		!hasValue(infiltratingAirRh) ||
		!hasValue(boxInteriorRh)
	) {
		return undefined;
	}

	let doorOpenTimeRatio = calculateDoorOpenTimeRatio(
		standOpenTimePerDay,
		averagePassageTime,
		passagesPerDay
	);

	if (!hasValue(doorOpenTimeRatio) && openingType.closes) {
		return 0;
	}

	const enthalpy = openingType.infiltrationEnthalpy;

	if (
		!hasValue(enthalpy) ||
		!hasValue(curtainEffectiveness)
	) {
		return undefined;
	}

	const leakageCurtain = (100 - getNumericValue(curtainEffectiveness)) / 100;

	const airTemp =
		getNumericValue(exteriorTemperature) < getNumericValue(boxInteriorTemperature)
			? getNumericValue(boxInteriorTemperature)
			: getNumericValue(exteriorTemperature);

	const faRecordHa = exteriorTemperatureAirProperty.ha;
	const faRecordWs = exteriorTemperatureAirProperty.ws;
	const faRecordHas = exteriorTemperatureAirProperty.has;

	const fbRecordHa = boxInteriorTemperatureAirProperty.ha;
	const fbRecordWs = boxInteriorTemperatureAirProperty.ws;
	const fbRecordHas = boxInteriorTemperatureAirProperty.has;

	const enthalpyI =
		faRecordHa +
		(getNumericValue(infiltratingAirRh) /
			100 /
			(1 +
				((1 - getNumericValue(infiltratingAirRh) / 100) * faRecordWs) /
				getNumericValue(enthalpy))) *
			faRecordHas;
	const densityI = (2.7 * 14.7) / (airTemp + 459.7);

	const enthalpyR =
		fbRecordHa +
		(getNumericValue(boxInteriorRh) /
			100 /
			(1 +
				((1 - getNumericValue(boxInteriorRh) / 100) * fbRecordWs) /
				getNumericValue(enthalpy))) *
			fbRecordHas;
	const densityR =
		(2.7 * 14.7) / (getNumericValue(boxInteriorTemperature) + 459.7);

	const g = 32.174;
	const fm = (2 / (1 + (densityR / densityI) ** (1 / 3))) ** 1.5;
	let e = 1 - leakageCurtain;

	let iArea = getNumericValue(area);
	if (openingType.id === OpeningType.TrailerDockingDoor) {
		const areaTrailer = calculateTrailerArea(width, height, openingType) ?? 0;
		iArea -= areaTrailer;
		doorOpenTimeRatio = 1;
		e = 0;
	}

	const flowFactor = openingType.flowFactor ?? 8;
	const q =
		795.6 *
		iArea *
		(enthalpyI - enthalpyR) *
		densityR *
		(1 - densityI / densityR) ** 0.5 *
		(g * UnitOfMeasure.IntoFt(getNumericValue(height))) ** 0.5 *
		fm;
	const qt = q * getNumericValue(doorOpenTimeRatio) * flowFactor * (1 - e);

	return qt;
};

export const calculateTotalInfiltrationLoad = (
	quantity: measure, 
	infiltrationLoad: measure
): measure => {
	if (!hasValue(quantity) || !hasValue(infiltrationLoad)) {
		return undefined;
	}

	return getNumericValue(quantity) * getNumericValue(infiltrationLoad);
};

export const calculateOpeningsTotalInfiltrationLoad = (
	openings: IOpening[]
): number => {
	let total= 0;

    openings.forEach(o => {
        total += o.totalInfiltrationLoad ?? 0;
    });

    return total;
};