import { measure, getNumericValue, hasValue } from "../../types/measure";

import IBoxLocation from "../interfaces/IBoxLocation";
import IFloorType from "../interfaces/IFloorType";
import IMaterial from "../interfaces/IMaterial";
import ISectionType from "../interfaces/ISectionType";
import IOpening from "../interfaces/IOpening";

import SectionType from "../../enums/SectionType";
import FloorType from "../../enums/FloorType";

import BoxLocation from "../../enums/BoxLocation";
import UnitOfMeasure from "./UnitOfMeasure";
import WallType from "../../enums/WallType";
import IBoxType from "../interfaces/IBoxType";
import BoxType from "../../enums/BoxType";

import * as OpeningCalculations from "./OpeningCalculations";

export const calculateCustomSectionArea = (
    sectionType: ISectionType,
    width: measure,
    length: measure,
    height: measure,
    depth: measure
): measure => {
    let totalArea;
    
    switch(sectionType.id)
    {
        case SectionType.WallCustom:
            if (hasValue(width) && hasValue(height)) {
                totalArea = getNumericValue(width) * getNumericValue(height);
            }
            break;
        case SectionType.FloorCustom:
            if (hasValue(length) && hasValue(depth)) {
                totalArea = getNumericValue(length) * getNumericValue(depth);
            }
            break;
        case SectionType.CeilingCustom:
            if (hasValue(length) && hasValue(depth)) {
                totalArea = getNumericValue(length) * getNumericValue(depth);
            }
            break;
        default:
            break;
    }

    return totalArea;
} 

export const calculateKFactor = (
    customRValuePerInch: measure,
    customRValueEffective: measure,
    thickness: measure,
    material: IMaterial
): measure => {
    if (hasValue(customRValuePerInch)) {
        if (getNumericValue(customRValuePerInch) === 0) {
            return 0;
        }
        return 1 / getNumericValue(customRValuePerInch);
    }

    if (hasValue(customRValueEffective)) {
        if (hasValue(thickness) && getNumericValue(customRValueEffective) > 0) {
            return getNumericValue(thickness) / getNumericValue(customRValueEffective);
        }
        return undefined;
    }

    if (material.kFactor) {
        return material.kFactor;
    }

    return undefined;
};

export const calculateDefaultRValuePerInch = (
    material: IMaterial
): measure => {
    if (material.kFactor && material.kFactor !== 0) {
        return 1 / material.kFactor;
    }

    return undefined;
};

export const calculateRValuePerInch = (
    customKFactor: measure,
    customRValueEffective: measure,
    thickness: measure,
    material: IMaterial
): measure => {
    if (hasValue(customKFactor)) {
        if (getNumericValue(customKFactor) === 0) { 
            return 0;
        }
        return 1 / getNumericValue(customKFactor);
    }

    if (hasValue(customRValueEffective)) {
        if (hasValue(thickness)) {
            return getNumericValue(customRValueEffective) / getNumericValue(thickness);
        }
        return undefined;
    }

    return calculateDefaultRValuePerInch(material);
};

export const calculateRValueEffective = (
    rValuePerInch: measure,
    thickness: measure,
    sectionType: ISectionType,
    location: IBoxLocation,
    material: IMaterial | undefined
): measure => {
    if (!hasValue(rValuePerInch)
        || !hasValue(thickness)
    ) {
        return undefined;
    }

    let rValue = getNumericValue(rValuePerInch) * getNumericValue(thickness);

    if (location.id === BoxLocation.OutdoorsCold
        || location.id === BoxLocation.OutdoorsWarm
    ) {
        if (hasValue(sectionType.outdoorRValueAdjustment)) {
            rValue += getNumericValue(sectionType.outdoorRValueAdjustment);
        }
    }

    if (material && material.isConstruct) {
        rValue = getNumericValue(material.rValue);
    }
    
    return rValue;
};

export const calculateExteriorTemperature = (
    boxExteriorTemperature: measure,
    sectionType: ISectionType,
    location: IBoxLocation,
    floorType: IFloorType
): measure => {
    if (!hasValue(boxExteriorTemperature)) {
        return undefined;
    }

    if (location.id === BoxLocation.OutdoorsCold || location.id === BoxLocation.OutdoorsWarm) {
        if (sectionType.id === SectionType.FloorCustom
            || sectionType.id === SectionType.FloorPolygon
        ) {
            const floorTemperature = floorType.defaultExteriorTemperature;
            if (hasValue(floorTemperature)) {
                return floorTemperature;
            }
        }

        const temperatureAdjustment = sectionType.outdoorTemperatureAdjustment;
        if (hasValue(temperatureAdjustment)) {
            return getNumericValue(boxExteriorTemperature) + getNumericValue(temperatureAdjustment);
        }
    }
    
    return boxExteriorTemperature;
};

export const calculateFloorLoad = (
    location: IBoxLocation,
    area: measure,
    perimeter: measure,
    rValueEffective: measure,
    boxExteriorTemperature: measure,
    boxInteriorTemperature: measure
): measure => {
    if (!hasValue(area)
        || !hasValue(perimeter)
        || !hasValue(rValueEffective)
        || !hasValue(boxExteriorTemperature)
        || !hasValue(boxInteriorTemperature)
    ) {
        return undefined;
    }

    let floorLoad = 0;
    
    const tmTemperature = hasValue(location.tmTemperature)
        ? getNumericValue(location.tmTemperature)
        : getNumericValue(boxExteriorTemperature);

    if (!hasValue(location.taTemperature)) {
        return undefined;
    }

    const taTemperature = getNumericValue(location.taTemperature);

    const tmTempCelcius = UnitOfMeasure.TemperatureFtoC(tmTemperature, 3);
    const taTempCelcius = UnitOfMeasure.TemperatureRelativeFtoC(taTemperature, 3);
    const interiorTempCelcius = UnitOfMeasure.TemperatureFtoC(getNumericValue(boxInteriorTemperature), 3);
    const areaSqM = UnitOfMeasure.SqFttoSqM(getNumericValue(area), 3);
    const perimeterM = UnitOfMeasure.FttoM(getNumericValue(perimeter));
    
    const { angularFrequency, soilConductivity, thermalDiffusivity, insulationFactorM, insulationFactorA } = location;

    if (!hasValue(angularFrequency)
        || !hasValue(soilConductivity)
        || !hasValue(thermalDiffusivity)
        || !hasValue(insulationFactorM)
        || !hasValue(insulationFactorA)) {
        return undefined;
    }
    
    const req = getNumericValue(rValueEffective) * 0.1761101838;
    const uo = getNumericValue(soilConductivity) * perimeterM / areaSqM;
    const h = areaSqM / (perimeterM * getNumericValue(soilConductivity) * req);
    const d = Math.log((1 + h) * (1 + (1 / h)) ** h);
    const g = (getNumericValue(soilConductivity) * req) * Math.sqrt(getNumericValue(angularFrequency) / getNumericValue(thermalDiffusivity));
    const uEffM = getNumericValue(insulationFactorM) * uo * d;
    const ueffA = getNumericValue(insulationFactorA) * uo * d ** 0.16 * g ** -0.6;
    const qm = uEffM * areaSqM * (tmTempCelcius - interiorTempCelcius);
    const qa = ueffA * areaSqM * taTempCelcius;
    const loadWatts = qm + qa;

    floorLoad = UnitOfMeasure.RatingWattsToBTUH(loadWatts, 3);

    return floorLoad;
}

export const calculateConductionLoad = (
    sectionType: ISectionType,
    floorType: IFloorType,
    location: IBoxLocation,
    area: measure,
    floorPerimeter: measure,
    rValueEffective: measure,
    exteriorTemperature: measure,
    boxExteriorTemperature: measure,
    boxInteriorTemperature: measure,
    openings: IOpening[] | undefined
): measure => {
    if (!hasValue(exteriorTemperature) 
        || !hasValue(boxInteriorTemperature)) {
        return undefined;
    }

    let conductionLoad = 0;

    if (!hasValue(rValueEffective)
        || !hasValue(area)
    ) {
        return undefined;
    }

    const td = getNumericValue(exteriorTemperature) - getNumericValue(boxInteriorTemperature); 

    const totalOpeningsArea = openings
            ? OpeningCalculations.calculateOpeningsTotalArea(openings)
            : 0;

    const sectionArea = getNumericValue(area) - totalOpeningsArea;

    if (sectionType.id === SectionType.FloorCustom || sectionType.id === SectionType.FloorPolygon) {            
        if (floorType.id === FloorType.OnGrade || floorType.id === FloorType.OnGradeHeated) {
            const floorLoad = calculateFloorLoad(location, sectionArea, floorPerimeter, rValueEffective, boxExteriorTemperature, boxInteriorTemperature);

            if (!floorLoad) {
                return undefined;
            }

            conductionLoad = floorLoad;

            if (floorType.id === FloorType.OnGradeHeated) {
                if (!hasValue(floorType.defaultExteriorTemperature)) {
                    return undefined;
                }

                let heat = 0;
                const tdHeat = getNumericValue(floorType.defaultExteriorTemperature) - getNumericValue(boxInteriorTemperature);
                if (sectionArea > 0 && tdHeat > 0 && getNumericValue(rValueEffective) > 0) {
                    heat = tdHeat * sectionArea / getNumericValue(rValueEffective);
                }
                conductionLoad += heat;
            }
        }
        else if (floorType.id === FloorType.AboveGrade) {
            if (sectionArea > 0 && getNumericValue(rValueEffective) > 0) {
                conductionLoad = td * sectionArea / getNumericValue(rValueEffective);
            }
        }
    }
    else if (sectionArea > 0 && getNumericValue(rValueEffective) > 0) {
        conductionLoad = td * sectionArea / getNumericValue(rValueEffective);
    }

    const totalOpeningsLoad = openings
            ? OpeningCalculations.calculateOpeningsTotalConductionLoad(openings)
            : 0;

    return conductionLoad + totalOpeningsLoad;
};

export const calculateCustomSectionDimension1 = (
    sectionType: ISectionType,
    width: measure,
    length: measure
): measure => {
    switch(sectionType.id)
    {
        case SectionType.WallCustom:
            return width;
        case SectionType.CeilingCustom:
            return length;
        case SectionType.FloorCustom:
            return length;
        default:
            return width; 
    }
};

export const calculateCustomSectionDimension2 = (
    sectionType: ISectionType,
    heigth: measure,
    depth: measure
): measure => {
    switch(sectionType.id)
    {
        case SectionType.WallCustom:
            return heigth;
        case SectionType.CeilingCustom:
            return depth;
        case SectionType.FloorCustom:
            return depth;
        default:
            return heigth; 
    }
};

export const calculatePolygonSectionDimension1 = (
    sectionType: ISectionType,
    wallType: WallType,
    boxType: IBoxType,
    boxLength: measure,
    boxLength2: measure,
    boxLength3: measure,
    boxDepth: measure,
    boxDepth2: measure,
    boxDepth3: measure
): measure => {
    switch (boxType.id)
    {
        case BoxType.Rectangular:        
            switch(sectionType.id)
            {
                case SectionType.WallPolygon:
                    switch(wallType)
                    {
                        case WallType.Rear:
                            return boxLength;
                        case WallType.RightEnd:
                            return boxDepth;
                        case WallType.Front:
                            return boxLength ;
                        case WallType.LeftEnd:
                            return boxDepth;
                        default:
                            return undefined; 
                    }
                case SectionType.CeilingPolygon:
                    return boxLength;
                case SectionType.FloorPolygon:
                    return boxLength;
                default:
                    return undefined; 
            }
        case BoxType.LShape:        
            switch(sectionType.id)
            {
                case SectionType.WallPolygon:
                    switch(wallType)
                    {
                        case WallType.Rear:
                            return boxLength;
                        case WallType.RightEnd:
                            return boxDepth;
                        case WallType.Front:
                            return boxLength2;
                        case WallType.LeftCut:
                            return boxDepth2;
                        case WallType.FrontCut:
                            return (hasValue(boxLength) && hasValue(boxLength2)) 
                                ? Math.abs(getNumericValue(boxLength) - getNumericValue(boxLength2))
                                : undefined;
                        case WallType.LeftEnd:
                            return (hasValue(boxDepth) && hasValue(boxDepth2)) 
                                ? Math.abs(getNumericValue(boxDepth) - getNumericValue(boxDepth2))
                                : undefined;
                        default:
                            return undefined; 
                    }                    
                default:
                    return undefined; 
            }
        case BoxType.UShape:        
            switch(sectionType.id)
            {
                case SectionType.WallPolygon:
                    switch(wallType)
                    {
                        case WallType.Rear:
                            return boxLength;
                        case WallType.RightEnd:
                            return boxDepth;
                        case WallType.Front:
                            return boxLength2;
                        case WallType.LeftCut:
                            return boxDepth2;
                        case WallType.FrontInsetCut:
                            return boxLength3;
                        case WallType.LeftInSetCut:
                            return boxDepth3;
                        case WallType.FrontCut:
                            return (hasValue(boxLength) && hasValue(boxLength2) && hasValue(boxLength3)) 
                                ? getNumericValue(boxLength) - getNumericValue(boxLength2) + getNumericValue(boxLength3)
                                : undefined;
                        case WallType.LeftEnd:
                            return (hasValue(boxDepth) && hasValue(boxDepth2) && hasValue(boxDepth3)) 
                                ? getNumericValue(boxDepth) - getNumericValue(boxDepth2) - getNumericValue(boxDepth3)
                                : undefined;
                        default:
                            return undefined; 
                    }                    
                default:
                    return undefined; 
            }
        default:
            return undefined;
    }
};

export const calculatePolygonSectionDimension2 = (
    sectionType: ISectionType,
    boxType: IBoxType,
    boxHeight: measure,
    boxDepth: measure
): measure => {
    switch (boxType.id)
    {
        case BoxType.Rectangular:   
            switch(sectionType.id)
            {
                case SectionType.WallCustom:
                case SectionType.WallPolygon:
                    return boxHeight;
                case SectionType.CeilingCustom:
                case SectionType.CeilingPolygon:
                    return boxDepth;
                case SectionType.FloorCustom:
                case SectionType.FloorPolygon:
                    return boxDepth;
                default:
                    return undefined; 
            }
        case BoxType.LShape:
        case BoxType.UShape:
            switch(sectionType.id)
            {
                case SectionType.WallCustom:
                case SectionType.WallPolygon:
                    return boxHeight;
                default:
                    return undefined;
            }
        default:
            return undefined; 
    }
};

export const calculateBoxPerimeter = (
    boxType: IBoxType,
    sectionType: ISectionType,
    boxLength: measure,
    boxLength2: measure,
    boxLength3: measure,
    boxDepth: measure,
    boxDepth2: measure,
    boxDepth3: measure
) => {
    let perimeter = 0;

    if (sectionType.id === SectionType.FloorPolygon) {
        boxType.sectionsConfig.forEach(s => {
            if (s.sectionType.id === SectionType.WallPolygon) {
                const dimension1 = calculatePolygonSectionDimension1(s.sectionType, s.wallType, boxType, boxLength, boxLength2, boxLength3, boxDepth, boxDepth2, boxDepth3) ?? 0;
                perimeter += getNumericValue(dimension1);
            }
        });
    }

    return perimeter;
};

export const calculatePolygonSectionArea = (
    sectionType: ISectionType,
    boxType: IBoxType,
    dimension1: measure,
    dimension2: measure,
    boxLength: measure,
    boxLength2: measure,
    boxLength3: measure,
    boxDepth: measure,
    boxDepth2: measure,
    boxDepth3: measure
): measure => {
    if (boxType.id === BoxType.Rectangular) {
        if (hasValue(dimension1) && hasValue(dimension2)) {
            return Math.abs(getNumericValue(dimension1) * getNumericValue(dimension2));
        }
    }
    else if (boxType.id === BoxType.LShape) {
        if (sectionType.id === SectionType.WallPolygon
            || sectionType.id === SectionType.WallCustom
            || sectionType.id === SectionType.CeilingCustom
            || sectionType.id === SectionType.FloorCustom) {
                if (hasValue(dimension1) && hasValue(dimension2)) {
                    return Math.abs(getNumericValue(dimension1) * getNumericValue(dimension2));
                }
            }
        else if (sectionType.id === SectionType.CeilingPolygon
            || sectionType.id === SectionType.FloorPolygon) {
                if (hasValue(boxLength) && hasValue(boxLength2) 
                    && hasValue(boxDepth) && hasValue(boxDepth2)) {
                        const box1Area = getNumericValue(boxLength) * (getNumericValue(boxDepth) - getNumericValue(boxDepth2));
                        const box2Area = getNumericValue(boxLength2) * getNumericValue(boxDepth2);

                        return Math.abs(box1Area + box2Area);
                    }
            }
    }
    else if (boxType.id === BoxType.UShape) {
        if (sectionType.id === SectionType.WallPolygon
            || sectionType.id === SectionType.WallCustom
            || sectionType.id === SectionType.CeilingCustom
            || sectionType.id === SectionType.FloorCustom) {
                if (hasValue(dimension1) && hasValue(dimension2)) {
                    return Math.abs(getNumericValue(dimension1) * getNumericValue(dimension2));
                }
            }
        else if (sectionType.id === SectionType.CeilingPolygon
            || sectionType.id === SectionType.FloorPolygon) {
                if (hasValue(boxLength) && hasValue(boxLength2) && hasValue(boxLength3)
                    && hasValue(boxDepth) && hasValue(boxDepth2) && hasValue(boxDepth3)) {
                        const box1Area = getNumericValue(boxLength) * (getNumericValue(boxDepth) - getNumericValue(boxDepth2) - getNumericValue(boxDepth3));
                        const box2Area = (getNumericValue(boxLength2) - getNumericValue(boxLength3)) * getNumericValue(boxDepth3);
                        const box3Area = getNumericValue(boxLength2) * getNumericValue(boxDepth2);

                        return Math.abs(box1Area + box2Area + box3Area);
                    }
            }
    }

    return undefined;
};

export const calculateSectionAreaWithoutOpenings = (
    area: measure,    
    openings: IOpening[] | undefined
): measure => {
    if (!hasValue(area)) {
        return area;
    }

    const totalOpeningsArea = openings ? OpeningCalculations.calculateOpeningsTotalArea(openings) : 0;
    const sectionArea = getNumericValue(area) - totalOpeningsArea;

    return sectionArea;
};

export const calculateConductionLoadWithoutOpenings = (
    conductionLoad: measure,    
    openings: IOpening[] | undefined
): measure => {
    if (!hasValue(conductionLoad)) {
        return conductionLoad;
    }

    const totalOpeningsLoad = openings ? OpeningCalculations.calculateOpeningsTotalConductionLoad(openings) : 0;
    const load = getNumericValue(conductionLoad) - totalOpeningsLoad;

    return load;
};