import * as React from "react";

export interface CheckboxProps {
    name: string;
    label: string;
    tooltip?: string;
    className?: string;
    value: string;
    isChecked: boolean;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox: React.FunctionComponent<CheckboxProps> = ({
    name,
    label,
    tooltip,
    className,
    value,
    isChecked,
    disabled,
    onChange
}: CheckboxProps) => {

    const handleCheckboxChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <div className={`component${tooltip ? " tooltip" : ""}`}>
            <label className={`checkbox ${className ?? ""}`}>
                <input 
                    name={name}
                    type="checkbox"
                    value={value}
                    checked={isChecked}
                    disabled={disabled}
                    onChange={handleCheckboxChange}
                />
                &nbsp;
                <span className="is-small">
                    {label}
                </span>
            </label>
            {tooltip && (
                <div className="tooltiptext tooltip-top">
                    <div>{tooltip}</div>
                </div>
            )}
        </div>
    );
};

export default Checkbox;
