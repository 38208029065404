import * as React from "react";

import ConfigurationService from "../../services/ConfigurationService";
import UnitOfMeasure from "../../models/helpers/UnitOfMeasure";

import useApplicationContext from "../../hooks/useApplicationContext";
import useLoadingContext from "../../hooks/useLoadingContext";
import useTranslationContext from "../../hooks/useTranslationContext";

const applicationStyle = {
	cursor: "pointer"
} as React.CSSProperties;

const applicationSelectedStyle = {
	cursor: "pointer",
	backgroundColor: "#b7c8f3fc",
	padding: "0.5rem"
} as React.CSSProperties;

interface Application {
	id: number;
	name: string;
}

interface BoxApplicationsProps {
	selectedId?: number;
	onClick: (applicationId: number) => void;
	onDoubleClick: (applicationId: number) => void;
}

const BoxApplications: React.FunctionComponent<BoxApplicationsProps> = (
	props: BoxApplicationsProps
) => {
	
	const [apps, setApps] = React.useState([] as Application[]);
	const [selectedAppId, setSelectedAppId] = React.useState(props.selectedId ?? -1);
	const [boxTemp, setBoxTemp] = React.useState(-9999);
	const [rh, setRH] = React.useState(-9999);
	const [td, setTD] = React.useState(-9999);

	const { appData } = useApplicationContext()
	const { showLoading, hideLoading } = useLoadingContext();
	const { translations } = useTranslationContext();
	
	React.useEffect(() => {
		showLoading();

		ConfigurationService.getBoxApplications(data => {
			setApps(data);
			hideLoading();
		});
	}, []);

	const selectApplication = (appId: number) => {
		setSelectedAppId(appId);

		ConfigurationService.getApplicationById(
			appId.toString(),
			(boxApp: any) => {
				const interiorTemperature = boxApp.boxTemp;
				let relativeHumidity = boxApp.rH_TD;
				let designTd = UnitOfMeasure.TDatRH(boxApp.rH_TD);

				// when RH_TD >= 0, it represents RH, otherwise it represents TD
				if (boxApp.rH_TD < 0) {
					relativeHumidity = appData.BOX.FreezerInternalRH_Default.value;
					designTd = 0 - boxApp.rH_TD;
				}

				setBoxTemp(interiorTemperature);
				setRH(relativeHumidity);
				setTD(designTd);
			}
		);
	};

	const handleClick = (appId: number) => {
		selectApplication(appId);

		if (props.onClick) {
			props.onClick(appId);
		}
	};

	const handleDoubleClick = (appId: number) => {
		selectApplication(appId);

		if (props.onDoubleClick) {
			props.onDoubleClick(appId);
		}
	};

	return (
		<>
			<h1 className="title is-5">{translations.createSystem_PleaseSelectApplicationTypeOrTemplate}</h1>
			<div id="box-applications" style={{ fontSize: "0.7rem" }}>
				<div className="box">
					<div className="columns is-multiline">
						{apps.map(app => {
							return (
								<div className="column is-one-fifth" key={app.id}>
									<figure
										className={
											app.id === selectedAppId
												? "box image is-128x128 has-image-centered"
												: "image is-128x128 has-image-centered"
										}
										style={
											app.id === selectedAppId
												? applicationSelectedStyle
												: applicationStyle
										}
										onClick={() => handleClick(app.id)}
										onDoubleClick={() => handleDoubleClick(app.id)}
									>
										<img
											src={ConfigurationService.getApplicationUrlById(app.id)}
											alt={app.name}
										/>
									</figure>
									{app.name}
								</div>
							);
						})}
					</div>
				</div>
				{selectedAppId > 0 && (
					<div className="level">
						<div className="level-left">
							<div className="level-item">
								&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
							</div>
							<div className="level-item">
								{`${translations.boxTemp}:`}
							</div>
							<div className="level-item">
								{UnitOfMeasure.getTemperatureDisplayValue(boxTemp, 1)}
								&nbsp;
								{UnitOfMeasure.getTemperatureUnitLabel()}
							</div>
						</div>
						<div className="level-right">
							<div className="level-item">RH / TD:</div>
							<div className="level-item">
								{rh}
								%&nbsp;&nbsp;
								{UnitOfMeasure.getTemperatureRelativeDisplayValue(td, 1)}
								&nbsp;
								{UnitOfMeasure.getTemperatureUnitLabel()}
							</div>
							<div className="level-item">
								&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default BoxApplications;