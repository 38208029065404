/* eslint-disable no-underscore-dangle */
import * as React from "react";

import IBoxLocation from "../models/interfaces/IBoxLocation";
import IBoxType from "../models/interfaces/IBoxType";
import IConstructionLayer from "../models/interfaces/IConstructionLayer";
import IFloorType from "../models/interfaces/IFloorType";
import IOpeningType from "../models/interfaces/IOpeningType";
import ISectionType from "../models/interfaces/ISectionType";

import BoxLocation from "../enums/BoxLocation";
import FloorType from "../enums/FloorType";
import Radians from "../enums/Radians";
import SectionLocation from "../enums/SectionLocation";
import WallType from "../enums/WallType";

import BoxType from "../enums/BoxType";
import OpeningType from "../enums/OpeningType";
import SectionType from "../enums/SectionType";

import useApplicationContext from "./useApplicationContext";
import useBoxContext from "./useBoxContext";
import useTranslationContext from "./useTranslationContext";

const useApplicationConfigs = () => {

    const { appData } = useApplicationContext();
    const { boxData } = useBoxContext();
    const { translations } = useTranslationContext();

    const getBoxLocationConfig = React.useCallback((id: BoxLocation | null) => {
        const boxLocations: IBoxLocation[] = [{
            id: BoxLocation.Indoors,
            name: translations.indoors,
            defaultExternalHumidity: appData?.BOX?.ExternalRH?.Indoors_Default?.value,
            defaultExternalTemperature: appData?.BOX?.ExternalTemp?.Indoors_Default?.value,
            tmTemperature: null,
            taTemperature: appData?.FLOOR_ON_GRADE?.Indoor_Ta?.value ?? 5,
            angularFrequency: appData?.FLOOR_ON_GRADE?.Angular_Frequency?.value ?? 0.0000001992,
            soilConductivity: appData?.FLOOR_ON_GRADE?.Soil_Conductivity?.value ?? 1.6,
            thermalDiffusivity: appData?.FLOOR_ON_GRADE?.Thermal_Diffusivity?.value ?? 0.000000712,
            insulationFactorM: appData?.FLOOR_ON_GRADE?.Insulation_Factor_M?.value ?? 0.4,
            insulationFactorA: appData?.FLOOR_ON_GRADE?.Insulation_Factor_A?.value ?? 0.25
        }, {
            id: BoxLocation.OutdoorsWarm,
            name: translations.outdoors,
            defaultExternalHumidity: appData?.BOX?.ExternalRH?.Default?.value,
            defaultExternalTemperature: appData?.BOX?.ExternalTemp?.Default?.value,
            tmTemperature: appData?.FLOOR_ON_GRADE?.Warm_Tm?.value ?? 70,
            taTemperature: appData?.FLOOR_ON_GRADE?.Warm_Ta?.value ?? 30,
            angularFrequency: appData?.FLOOR_ON_GRADE?.Angular_Frequency?.value ?? 0.0000001992,
            soilConductivity: appData?.FLOOR_ON_GRADE?.Soil_Conductivity?.value ?? 1.6,
            thermalDiffusivity: appData?.FLOOR_ON_GRADE?.Thermal_Diffusivity?.value ?? 0.000000712,
            insulationFactorM: appData?.FLOOR_ON_GRADE?.Insulation_Factor_M?.value ?? 0.4,
            insulationFactorA: appData?.FLOOR_ON_GRADE?.Insulation_Factor_A?.value ?? 0.25
        }, {
            id: BoxLocation.OutdoorsCold,
            name: translations.outdoors_ColdClimate_,
            defaultExternalHumidity: appData?.BOX?.ExternalRH?.Default?.value,
            defaultExternalTemperature: appData?.BOX?.ExternalTemp?.Default?.value,
            tmTemperature: appData?.FLOOR_ON_GRADE?.Cold_Tm?.value ?? 40,
            taTemperature: appData?.FLOOR_ON_GRADE?.Cold_Ta?.value ?? 60,
            angularFrequency: appData?.FLOOR_ON_GRADE?.Angular_Frequency?.value ?? 0.0000001992,
            soilConductivity: appData?.FLOOR_ON_GRADE?.Soil_Conductivity?.value ?? 1.6,
            thermalDiffusivity: appData?.FLOOR_ON_GRADE?.Thermal_Diffusivity?.value ?? 0.000000712,
            insulationFactorM: appData?.FLOOR_ON_GRADE?.Insulation_Factor_M?.value ?? 0.4,
            insulationFactorA: appData?.FLOOR_ON_GRADE?.Insulation_Factor_A?.value ?? 0.25
        }];

        return boxLocations.find(item => item.id === id) ?? boxLocations[0]
    }, [appData]);

    const getSectionBoxLocationConfig = React.useCallback((
        selectedSectionLocation: SectionLocation | null,
        boxLocation: BoxLocation | null
    ) => {
        let id = boxLocation ?? BoxLocation.Indoors;
        switch (selectedSectionLocation) {
            case SectionLocation.Indoors:
                id = BoxLocation.Indoors;
                break;
            case SectionLocation.Outdoors:
                id = BoxLocation.OutdoorsWarm;
                break;
            case SectionLocation.BoxLocation:
            default:
                break;
        }
        return getBoxLocationConfig(id);
    }, [getBoxLocationConfig]);

    const getSectionTypes = React.useCallback(() => {
        const sectionTypes: ISectionType[] = [{
            id: SectionType.Undefined,
            name: "",
            isCustom: false,
            defaultSectionName: "",
            defaultMaterialId: 0,
            defaultThickness: null,
            outdoorRValueAdjustment: null,
            outdoorTemperatureAdjustment: null
        }, {
            id: SectionType.WallCustom,
            name: translations.customWall,
            isCustom: true,
            defaultSectionName: translations.wall,
            defaultMaterialId: boxData?.wallMaterialId,
            defaultThickness: boxData?.wallMaterialThickness,
            outdoorRValueAdjustment: appData?.BOX?.AirFilm_R_Exterior_OutdoorAdjusment?.value,
            outdoorTemperatureAdjustment: appData?.BOX?.OutdoorWallTemp_Add?.value
        }, {
            id: SectionType.WallPolygon,
            name: translations.wall,
            isCustom: false,
            defaultSectionName: translations.wall,
            defaultMaterialId: boxData?.wallMaterialId,
            defaultThickness: boxData?.wallMaterialThickness,
            outdoorRValueAdjustment: appData?.BOX?.AirFilm_R_Exterior_OutdoorAdjusment?.value,
            outdoorTemperatureAdjustment: appData?.BOX?.OutdoorWallTemp_Add?.value
        }, {
            id: SectionType.FloorCustom,
            name: translations.customFloor,
            isCustom: true,
            defaultSectionName: translations.floor,
            defaultMaterialId: 0,
            defaultThickness: null,
            outdoorRValueAdjustment: null,
            outdoorTemperatureAdjustment: null
        }, {
            id: SectionType.FloorPolygon,
            name: translations.floor,
            isCustom: false,
            defaultSectionName: translations.floor,
            defaultMaterialId: 0,
            defaultThickness: null,
            outdoorRValueAdjustment: null,
            outdoorTemperatureAdjustment: null
        }, {
            id: SectionType.CeilingCustom,
            name: translations.customCeiling,
            isCustom: true,
            defaultSectionName: translations.ceiling,
            defaultMaterialId: boxData?.ceilingMaterialId,
            defaultThickness: boxData?.ceilingMaterialThickness,
            outdoorRValueAdjustment: appData?.BOX?.AirFilm_R_Exterior_OutdoorAdjusment?.value,
            outdoorTemperatureAdjustment: appData?.BOX?.OutdoorCeilingTemp_Add?.value
        }, {
            id: SectionType.CeilingPolygon,
            name: translations.ceiling,
            isCustom: false,
            defaultSectionName: translations.ceiling,
            defaultMaterialId: boxData?.ceilingMaterialId,
            defaultThickness: boxData?.ceilingMaterialThickness,
            outdoorRValueAdjustment: appData?.BOX?.AirFilm_R_Exterior_OutdoorAdjusment?.value,
            outdoorTemperatureAdjustment: appData?.BOX?.OutdoorCeilingTemp_Add?.value
        }, {
            id: SectionType.Various,
            name: translations.various,
            isCustom: false,
            defaultSectionName: "",
            defaultMaterialId: 0,
            defaultThickness: null,
            outdoorRValueAdjustment: null,
            outdoorTemperatureAdjustment: null
        }];

        return sectionTypes;
    }, [
        appData,
        boxData
    ]);

    const getSectionTypeConfig = React.useCallback((id: SectionType | null) => {
        const sectionTypes = getSectionTypes();
        return sectionTypes.find(item => item.id === id) ?? sectionTypes[0];
    }, [getSectionTypes]);

    const getBoxTypeConfig = React.useCallback((id: BoxType | null) => {
        const boxTypes: IBoxType[] = [{
            id: BoxType.Undefined,
            sectionsConfig: []
        }, {
            id: BoxType.Rectangular,
            sectionsConfig: [{
                sectionType: getSectionTypeConfig(SectionType.WallPolygon),
                wallType: WallType.Rear,
                radians: Radians.Right
            }, {
                sectionType: getSectionTypeConfig(SectionType.WallPolygon),
                wallType: WallType.RightEnd,
                radians: Radians.Down
            }, {
                sectionType: getSectionTypeConfig(SectionType.WallPolygon),
                wallType: WallType.Front,
                radians: Radians.Left
            }, {
                sectionType: getSectionTypeConfig(SectionType.WallPolygon),
                wallType: WallType.LeftEnd,
                radians: Radians.Up
            }, {
                sectionType: getSectionTypeConfig(SectionType.FloorPolygon),
                wallType: WallType.Undefined,
                radians: Radians.None
            }, {
                sectionType: getSectionTypeConfig(SectionType.CeilingPolygon),
                wallType: WallType.Undefined,
                radians: Radians.None
            }]
        }, {
            id: BoxType.LShape,
            sectionsConfig: [{
                sectionType: getSectionTypeConfig(SectionType.WallPolygon),
                wallType: WallType.Rear,
                radians: Radians.Right
            }, {
                sectionType: getSectionTypeConfig(SectionType.WallPolygon),
                wallType: WallType.RightEnd,
                radians: Radians.Down
            }, {
                sectionType: getSectionTypeConfig(SectionType.WallPolygon),
                wallType: WallType.Front,
                radians: Radians.Left
            }, {
                sectionType: getSectionTypeConfig(SectionType.WallPolygon),
                wallType: WallType.LeftCut,
                radians: Radians.Up
            }, {
                sectionType: getSectionTypeConfig(SectionType.WallPolygon),
                wallType: WallType.FrontCut,
                radians: Radians.Left
            }, {
                sectionType: getSectionTypeConfig(SectionType.WallPolygon),
                wallType: WallType.LeftEnd,
                radians: Radians.Up
            }, {
                sectionType: getSectionTypeConfig(SectionType.FloorPolygon),
                wallType: WallType.Undefined,
                radians: Radians.None
            }, {
                sectionType: getSectionTypeConfig(SectionType.CeilingPolygon),
                wallType: WallType.Undefined,
                radians: Radians.None
            }]
        }, {
            id: BoxType.UShape,
            sectionsConfig: [{
                sectionType: getSectionTypeConfig(SectionType.WallPolygon),
                wallType: WallType.Rear,
                radians: Radians.Right
            }, {
                sectionType: getSectionTypeConfig(SectionType.WallPolygon),
                wallType: WallType.RightEnd,
                radians: Radians.Down
            }, {
                sectionType: getSectionTypeConfig(SectionType.WallPolygon),
                wallType: WallType.Front,
                radians: Radians.Left
            }, {
                sectionType: getSectionTypeConfig(SectionType.WallPolygon),
                wallType: WallType.LeftCut,
                radians: Radians.Up
            }, {
                sectionType: getSectionTypeConfig(SectionType.WallPolygon),
                wallType: WallType.FrontInsetCut,
                radians: Radians.Right
            }, {
                sectionType: getSectionTypeConfig(SectionType.WallPolygon),
                wallType: WallType.LeftInSetCut,
                radians: Radians.Up
            }, {
                sectionType: getSectionTypeConfig(SectionType.WallPolygon),
                wallType: WallType.FrontCut,
                radians: Radians.Left
            }, {
                sectionType: getSectionTypeConfig(SectionType.WallPolygon),
                wallType: WallType.LeftEnd,
                radians: Radians.Up
            }, {
                sectionType: getSectionTypeConfig(SectionType.FloorPolygon),
                wallType: WallType.Undefined,
                radians: Radians.None
            }, {
                sectionType: getSectionTypeConfig(SectionType.CeilingPolygon),
                wallType: WallType.Undefined,
                radians: Radians.None
            }]
        }, {
            id: BoxType.Custom,
            sectionsConfig: []
        }, {
            id: BoxType.FloorPlanner,
            sectionsConfig: []
        }];

        return boxTypes.find(item => item.id === id) ?? boxTypes[0]
    }, [getSectionTypeConfig]);

    const getFloorTypeConfig = React.useCallback((id: FloorType | null) => {
        const floorTypes: IFloorType[] = [{
            id: FloorType.None,
            defaultMaterialId: 0,
            defaultThickness: null,
            defaultExteriorTemperature: null
        }, {
            id: FloorType.OnGrade,
            defaultMaterialId: boxData?.onGradeFloorMaterialId,
            defaultThickness: boxData?.onGradeFloorMaterialThickness,
            defaultExteriorTemperature: null
        }, {
            id: FloorType.OnGradeHeated,
            defaultMaterialId: boxData?.onGradeFloorMaterialId,
            defaultThickness: boxData?.onGradeFloorMaterialThickness,
            defaultExteriorTemperature: appData?.BOX?.FloorHeatingTemperature?.value ?? 40.0
        }, {
            id: FloorType.AboveGrade,
            defaultMaterialId: boxData?.aboveGradeFloorMaterialId,
            defaultThickness: boxData?.aboveGradeFloorMaterialThickness,
            defaultExteriorTemperature: null
        }];

        return floorTypes.find(item => item.id === id) ?? floorTypes[0]
    }, [
        appData,
        boxData
    ]);

    const getSectionFloorTypeConfig = React.useCallback((
        selectedSectionFloorType: FloorType | null,
        boxFloorType: FloorType | null,
    ) => {
        let id = selectedSectionFloorType ?? FloorType.BoxSetting;
        if (id === FloorType.BoxSetting) {
            id = boxFloorType ?? FloorType.OnGrade;
        }
        return getFloorTypeConfig(id);
    }, [getFloorTypeConfig]);

    const getOpeningTypes = React.useCallback(() => {
        const openingTypes: IOpeningType[] = [{
            id: OpeningType.None,
            name: translations.pleaseSelect,
            description: "",
            hasQuantityField: false,
            hasDescriptionField: false,
            hasDimensionFields: false,
            hasExteriorTemperatureField: false,
            hasStripCurtainsField: false,
            hasDoorUsageFields: false,
            hasRValueEffectiveField: false,
            hasConductionLoadField: false,
            hasInfiltratingAirRhField: false,
            hasInfiltrationLoadField: false,
            defaultWidth: null,
            defaultHeight: null,
            defaultPassagesPerDay: null,
            defaultAveragePassageTime: 0,
            defaultStandOpenTimePerDay: null,
            defaultRValueEffective: null,
            defaultInfiltrationLoad: null,
            infiltrationEnthalpy: null,
            dockDoorGap: null,
            flowFactor: null,
            opens: false,
            closes: false
        }, {
            id: OpeningType.Door,
            name: translations.door,
            description: translations.normallyClosedDoorForStaffOrGoodsTraffic_,
            hasQuantityField: true,
            hasDescriptionField: true,
            hasDimensionFields: true,
            hasExteriorTemperatureField: true,
            hasStripCurtainsField: true,
            hasDoorUsageFields: true,
            hasRValueEffectiveField: true,
            hasConductionLoadField: true,
            hasInfiltratingAirRhField: true,
            hasInfiltrationLoadField: true,
            defaultWidth: appData?.OPENING?.ManDoor?.Width_Default?.value,
            defaultHeight: appData?.OPENING?.ManDoor?.Height_Default?.value,
            defaultPassagesPerDay: appData?.INFILTRATION?.ManDoor?.PassagesPerDay_Default?.value,
            defaultAveragePassageTime: appData?.INFILTRATION?.ManDoor?.PassageTime_Default?.value,
            defaultStandOpenTimePerDay: appData?.INFILTRATION?.ManDoor?.StandOpenTime_Default?.value,
            defaultRValueEffective: boxData?.isBoxFreezer
                ? appData?.OPENING?.R_Value?.Freezer_Default?.value
                : appData?.OPENING?.R_Value?.Cooler_Default?.value,
            defaultInfiltrationLoad: null,
            infiltrationEnthalpy: appData?.INFILTRATION?.Enthalpy_Constant?.value,
            dockDoorGap: null,
            flowFactor: appData?.OPENING?.Flow_Factor?.value,
            opens: true,
            closes: true
        }, {
            id: OpeningType.GlassDoor,
            name: translations.reach_InGlassDoor,
            description: translations.reach_InSelfServeGlassDoors_,
            hasQuantityField: true,
            hasDescriptionField: true,
            hasDimensionFields: true,
            hasExteriorTemperatureField: false,
            hasStripCurtainsField: false,
            hasDoorUsageFields: false,
            hasRValueEffectiveField: true,
            hasConductionLoadField: true,
            hasInfiltratingAirRhField: false,
            hasInfiltrationLoadField: true,
            defaultWidth: appData?.OPENING?.GlassDoor?.Width_Default?.value,
            defaultHeight: appData?.OPENING?.GlassDoor?.Height_Default?.value,
            defaultPassagesPerDay: 0,
            defaultAveragePassageTime: 0,
            defaultStandOpenTimePerDay: null,
            defaultRValueEffective: boxData?.isBoxFreezer
                ? appData?.OPENING?.R_Value?.Glass_Freezer_Default?.value ?? 5
                : appData?.OPENING?.R_Value?.Glass_Cooler_Default?.value ?? 5,
            defaultInfiltrationLoad: boxData?.infiltGlassDoorLoad,
            infiltrationEnthalpy: appData?.INFILTRATION?.Enthalpy_Constant?.value,
            dockDoorGap: null,
            flowFactor: appData?.OPENING?.Flow_Factor?.value,
            opens: false,
            closes: true
        }, {
            id: OpeningType.TrailerDockingDoor,
            name: translations.trailerDockRoll_UpDoor,
            description: translations.roll_UpDoorDesignedToBeNormallyClosedOrSealedByTransportTrailer_,
            hasQuantityField: true,
            hasDescriptionField: true,
            hasDimensionFields: true,
            hasExteriorTemperatureField: false,
            hasStripCurtainsField: false,
            hasDoorUsageFields: false,
            hasRValueEffectiveField: true,
            hasConductionLoadField: true,
            hasInfiltratingAirRhField: false,
            hasInfiltrationLoadField: true,
            defaultWidth: appData?.OPENING?.DockDoor?.Width_Default?.value,
            defaultHeight: appData?.OPENING?.DockDoor?.Height_Default?.value,
            defaultPassagesPerDay: 0,
            defaultAveragePassageTime: 0,
            defaultStandOpenTimePerDay: null,
            defaultRValueEffective: boxData?.isBoxFreezer
                ? appData?.OPENING?.R_Value?.Freezer_Default?.value
                : appData?.OPENING?.R_Value?.Cooler_Default?.value,
            defaultInfiltrationLoad: null,
            infiltrationEnthalpy: appData?.INFILTRATION?.Enthalpy_Constant?.value,
            dockDoorGap: appData?.OPENING?.DockDoor?.Gap_Default?.value,
            flowFactor: appData?.OPENING?.Flow_Factor?.value,
            opens: true,
            closes: true
        }, {
            id: OpeningType.LoadingPlatformDoor,
            name: translations.loadingPlatformRoll_UpDoor,
            description: translations.roll_UpDoorOpenToOutsideEnvironmentOrOtherZone_,
            hasQuantityField: true,
            hasDescriptionField: true,
            hasDimensionFields: true,
            hasExteriorTemperatureField: true,
            hasStripCurtainsField: true,
            hasDoorUsageFields: true,
            hasRValueEffectiveField: true,
            hasConductionLoadField: true,
            hasInfiltratingAirRhField: true,
            hasInfiltrationLoadField: true,
            defaultWidth: appData?.OPENING?.DockDoor?.Width_Default?.value,
            defaultHeight: appData?.OPENING?.DockDoor?.Height_Default?.value,
            defaultPassagesPerDay: appData?.INFILTRATION?.DockDoor?.PassagesPerDay_Default?.value,
            defaultAveragePassageTime: appData?.INFILTRATION?.DockDoor?.PassageTime_Default?.value,
            defaultStandOpenTimePerDay: appData?.INFILTRATION?.DockDoor?.StandOpenTime_Default?.value,
            defaultRValueEffective: boxData?.isBoxFreezer
                ? appData?.OPENING?.R_Value?.Freezer_Default?.value
                : appData?.OPENING?.R_Value?.Cooler_Default?.value,
            defaultInfiltrationLoad: null,
            infiltrationEnthalpy: appData?.INFILTRATION?.Enthalpy_Constant?.value,
            dockDoorGap: null,
            flowFactor: appData?.OPENING?.Flow_Factor?.value,
            opens: false,
            closes: true
        }, {
            id: OpeningType.PermanentOpening,
            name: translations.permanentOpening,
            description: translations.alwaysOpenAreaOfWallLeadingToAnotherZone_,
            hasQuantityField: true,
            hasDescriptionField: true,
            hasDimensionFields: true,
            hasExteriorTemperatureField: true,
            hasStripCurtainsField: true,
            hasDoorUsageFields: false,
            hasRValueEffectiveField: false,
            hasConductionLoadField: true,
            hasInfiltratingAirRhField: true,
            hasInfiltrationLoadField: true,
            defaultWidth: null,
            defaultHeight: null,
            defaultPassagesPerDay: 0,
            defaultAveragePassageTime: 0,
            defaultStandOpenTimePerDay: 1440,
            defaultRValueEffective: boxData?.isBoxFreezer
                ? appData?.OPENING?.R_Value?.Freezer_Default?.value
                : appData?.OPENING?.R_Value?.Cooler_Default?.value,
            defaultInfiltrationLoad: null,
            infiltrationEnthalpy: appData?.INFILTRATION?.Enthalpy_Constant?.value,
            dockDoorGap: null,
            flowFactor: appData?.OPENING?.Flow_Factor?.value,
            opens: true,
            closes: false
        }, {
            id: OpeningType.Window,
            name: translations.window,
            description: translations.nonOpeningGlassWindow_,
            hasQuantityField: true,
            hasDescriptionField: true,
            hasDimensionFields: true,
            hasExteriorTemperatureField: true,
            hasStripCurtainsField: false,
            hasDoorUsageFields: false,
            hasRValueEffectiveField: true,
            hasConductionLoadField: true,
            hasInfiltratingAirRhField: false,
            hasInfiltrationLoadField: false,
            defaultWidth: null,
            defaultHeight: null,
            defaultPassagesPerDay: 0,
            defaultAveragePassageTime: 0,
            defaultStandOpenTimePerDay: null,
            defaultRValueEffective: boxData?.isBoxFreezer
                ? appData?.OPENING?.R_Value?.Freezer_Default?.value
                : appData?.OPENING?.R_Value?.Cooler_Default?.value,
            defaultInfiltrationLoad: null,
            infiltrationEnthalpy: appData?.INFILTRATION?.Enthalpy_Constant?.value,
            dockDoorGap: null,
            flowFactor: appData?.OPENING?.Flow_Factor?.value,
            opens: false,
            closes: true
        }, {
            id: OpeningType.Panel,
            name: translations.panel,
            description: translations.sectionOfWallWithDifferentInsulationOrExternalEnvironmentFromRestOfWall_,
            hasQuantityField: true,
            hasDescriptionField: true,
            hasDimensionFields: true,
            hasExteriorTemperatureField: true,
            hasStripCurtainsField: false,
            hasDoorUsageFields: true,
            hasRValueEffectiveField: true,
            hasConductionLoadField: true,
            hasInfiltratingAirRhField: false,
            hasInfiltrationLoadField: true,
            defaultWidth: null,
            defaultHeight: null,
            defaultPassagesPerDay: 0,
            defaultAveragePassageTime: 0,
            defaultStandOpenTimePerDay: null,
            defaultRValueEffective: boxData?.isBoxFreezer
                ? appData?.OPENING?.R_Value?.Freezer_Default?.value
                : appData?.OPENING?.R_Value?.Cooler_Default?.value,
            defaultInfiltrationLoad: 0,
            infiltrationEnthalpy: appData?.INFILTRATION?.Enthalpy_Constant?.value,
            dockDoorGap: null,
            flowFactor: appData?.OPENING?.Flow_Factor?.value,
            opens: false,
            closes: true
        }, {
            id: OpeningType.ExcludedArea,
            name: translations.excludedArea,
            description: translations.areaToBeExcludedFromWallDefinition_,
            hasQuantityField: false,
            hasDescriptionField: true,
            hasDimensionFields: true,
            hasExteriorTemperatureField: false,
            hasStripCurtainsField: false,
            hasDoorUsageFields: false,
            hasRValueEffectiveField: false,
            hasConductionLoadField: false,
            hasInfiltratingAirRhField: false,
            hasInfiltrationLoadField: false,
            defaultWidth: null,
            defaultHeight: null,
            defaultPassagesPerDay: 0,
            defaultAveragePassageTime: 0,
            defaultStandOpenTimePerDay: null,
            defaultRValueEffective: boxData?.isBoxFreezer
                ? appData?.OPENING?.R_Value?.Freezer_Default?.value
                : appData?.OPENING?.R_Value?.Cooler_Default?.value,
            defaultInfiltrationLoad: null,
            infiltrationEnthalpy: appData?.INFILTRATION?.Enthalpy_Constant?.value,
            dockDoorGap: null,
            flowFactor: appData?.OPENING?.Flow_Factor?.value,
            opens: false,
            closes: false
        }];

        return openingTypes;
    }, [
        appData,
        boxData
    ]);

    const getOpeningTypeConfig = React.useCallback((id: OpeningType | null) => {
        const openingTypes = getOpeningTypes();
        return openingTypes.find(item => item.id === id) ?? openingTypes[0]
    }, [getOpeningTypes]);

    const getBoundaryConstructionLayers = React.useCallback((): IConstructionLayer[] => {
        return [{
            name: translations.interiorBoundaryLayer,
            materialId: "0",
            materialName: translations.interiorBoundaryLayer,
            thickness: 0,
            kFactor: null,
            rValuePerInch: null,
            rValueEffective: parseFloat(appData?.BOX?.AirFilm_R_Interior?.value ?? undefined),
            isBoundaryInteriorLayer: true,
            isBoundaryExteriorLayer: false,
            isSectional: false,
            sectionCentreDistance: null,
            sectionMaterialId: "0",
            sectionMaterialName: "",
            sectionWidth: null,
            sectionKFactor: null,
            sectionRValuePerInch: null
        }, {
            name: translations.exteriorBoundaryLayer,
            materialId: "0",
            materialName: translations.exteriorBoundaryLayer,
            thickness: 0,
            kFactor: null,
            rValuePerInch: null,
            rValueEffective: parseFloat(appData?.BOX?.AirFilm_R_Exterior?.value ?? undefined),
            isBoundaryInteriorLayer: false,
            isBoundaryExteriorLayer: true,
            isSectional: false,
            sectionCentreDistance: null,
            sectionMaterialId: "0",
            sectionMaterialName: "",
            sectionWidth: null,
            sectionKFactor: null,
            sectionRValuePerInch: null
        }];
    }, [appData]);

    return {
        getBoxLocationConfig,
        getSectionBoxLocationConfig,
        getSectionTypes,
        getSectionTypeConfig,
        getBoxTypeConfig,
        getFloorTypeConfig,
        getSectionFloorTypeConfig,
        getOpeningTypes,
        getOpeningTypeConfig,
        getBoundaryConstructionLayers
     };
};

export default useApplicationConfigs;
