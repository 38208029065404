import * as React from "react";

import FormField from "../helpers/FormField";
import TextBox from "../helpers/TextBox";

import UnitOfMeasure from "../../models/helpers/UnitOfMeasure";

import useTranslationContext from "../../hooks/useTranslationContext";

interface UnitCapacityProps {
    capacityPerUnit: number | undefined;
    unitCount: number;
}

const UnitCapacity: React.FunctionComponent<UnitCapacityProps> = (
    props: UnitCapacityProps
) => {

    const { translations } = useTranslationContext();

    if (!props.capacityPerUnit) {
        return null;
    }

    return (
        <>
            <FormField label={translations.capacityPerUnit}>
                <TextBox 
                    name="capacityPerUnit"
                    value={`${UnitOfMeasure.getRatingDisplayValue(props.capacityPerUnit, 0)} ${UnitOfMeasure.getRatingUnitLabel()}`}
                    className="is-static"
                    disabled
                    readOnly
                />
            </FormField>
            <FormField label={translations.totalCapacityAtDesignTD}>
                <TextBox 
                    name="totalCapacity"
                    value={`${UnitOfMeasure.getRatingDisplayValue(props.capacityPerUnit * props.unitCount, 0)} ${UnitOfMeasure.getRatingUnitLabel()}`}
                    className="is-static"
                    disabled
                    readOnly
                />
            </FormField>
        </>
    );
};

export default React.memo(UnitCapacity);