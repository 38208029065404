import * as React from "react";

import ISystemState from "../models/interfaces/ISystemState";

import useSystemState from "./useSystemState";

const useSystemReducer = () => {

    const { 
        emptySystemState,
        recalculateLoads,
        setInitialState,
        resetState,
        changeValue,
        customizeMeasureProperty,
        customizeWallLoadMeasureProperty,
        resetBoxType,
        clearEquipmentSelections,
        setSelectedCondenser,
        setSelectedEvaporator,
        setEquipmentBalance,
        addSection,
        updateSection,
        copySection,
        deleteSection,
        updateSectionOpenings
    } = useSystemState();

    const [updateStoreFlag, setUpdateStoreFlag] = React.useState(false);

    const reducer = (state: ISystemState, action: any) => {

        setUpdateStoreFlag(action.updateStore);
        
        if (action.type === "set-initial-state") {
            return setInitialState();
        }
        if (action.type === "submit-changes") {
            return {
                ...state,
                action: action.type
            };
        }
        if (action.type === "cancel-changes") {
            return {
                ...setInitialState(),
                action: action.type
            };
        }
        if (action.type === "reset-state") {
            if (action.boxApplicationId) {
                return resetState(
                    state,
                    action.boxApplicationId,
                    action.isEquipmentOnly,
                    action.requireAwef);
            }
        }
        if (action.type === "change-value") {
            return changeValue(
                state,
                false,
                action.fieldName,
                action.fieldValue);
        }
        if (action.type === "customize-property") {
            return customizeMeasureProperty(
                state,
                false,
                action.fieldName,
                action.fieldValue);
        }
        if (action.type === "customize-wallLoad-property") {
            return customizeWallLoadMeasureProperty(
                state,
                action.fieldName,
                action.fieldValue);
        }
        if (action.type === "recalculate-loads") {
            return recalculateLoads(
                state,
                action.totalMiscLoad, 
                action.totalInfiltrationLoad, 
                action.totalProductLoad,
                action.miscLoadItems,
                action.infiltrationLoadItems,
                action.productLoadItems
            );
        }
        if (action.type === "reset-boxType") {
            if (action.boxType) {
                return resetBoxType(
                    state,
                    action.boxType);
            }
        }
        if (action.type === "change-equipment-value") {
            return changeValue(
                state,
                true,
                action.fieldName,
                action.fieldValue);
        }
        if (action.type === "customize-equipment-property") {
            return customizeMeasureProperty(
                state,
                true,
                action.fieldName,
                action.fieldValue);
        }
        if (action.type === "clear-equipment-units") {
            return clearEquipmentSelections(state);
        }
        if (action.type === "set-selected-condenser") {
            return setSelectedCondenser(
                state,
                action.equipment,
                action.selectedOptions);
        }
        if (action.type === "set-selected-evaporator") {
            return setSelectedEvaporator(
                state,
                action.equipment,
                action.selectedOptions);
        }
        if (action.type === "set-equipment-balance") {
            return setEquipmentBalance(
                state,
                action.systemBalanced,
                action.sstBalance,
                action.tdBalance,
                action.totalSystemCapacity);
        }
        if (action.type === "add-section") {
            return addSection(
                state,
                action.section);
        }
        if (action.type === "update-section") {
            return updateSection(
                state,
                action.index,
                action.section);
        }
        if (action.type === "copy-section") {
            return copySection(
                state,
                action.index);
        }
        if (action.type === "delete-section") {
            return deleteSection(
                state,
                action.index);
        }
        if (action.type === "update-section-openings") {
            return updateSectionOpenings(
                state,
                action.index,
                action.openings);
        }

        return state;
    };

    const [systemState, dispatch] = React.useReducer(reducer, emptySystemState);

    return {
        emptySystemState,
        systemState,
        dispatch,
        updateStoreFlag, 
        setUpdateStoreFlag
    };
};

export default useSystemReducer;
