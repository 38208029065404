import IFormField from "../interfaces/IFormField";
import IFormState from "../interfaces/IFormState";
import { hasValue, countDecimals, roundOff } from "../../types/measure";

export const getProperty = (obj: any, key: string) => (
    key.split(".").reduce((acc, part) => acc && acc[part], obj)
);

export const getStateDefaultValue = (
	state: IFormState<any>,
	fieldName: string
) => {
	return getProperty(state.defaultValues, fieldName);
};

export const isNumeric = (
	value: any
): boolean => {
	return value !== null 
		&& value !== undefined
		&& !Number.isNaN(Number(value));
};

export const isCustomValue = (
    formState: IFormState<any>, 
	fieldName: string,
	enteredValue?: any
): boolean => {

	let actualValue = enteredValue ?? getProperty(formState.actualValues, fieldName);
	let defaultValue = getProperty(formState.defaultValues, fieldName);

	if (isNumeric(actualValue) && isNumeric(defaultValue)) {
		const decimals = countDecimals(actualValue);
		defaultValue = roundOff(defaultValue, decimals);
		actualValue = roundOff(actualValue, decimals);
	}
	
	return hasValue(actualValue) 
		// eslint-disable-next-line eqeqeq
		&& actualValue != defaultValue;
};

export const getCustomValue = (
	formState: IFormState<any>,
	fieldName: string,
	enteredValue?: any
) => {

	const actualValue = enteredValue ?? getProperty(formState.actualValues, fieldName);

	if (!isCustomValue(formState, fieldName, enteredValue)) {
		return null;
	}

	return actualValue;
};

export function getFormField<T>(
    state: IFormState<any>,
	fieldName: string,
	enteredValue: T | undefined | null,
	getDefaultValue: any
): IFormField<T> {
    const defaultValue: T = getDefaultValue();

	let actualValue: T;

    if (enteredValue === undefined || enteredValue === null) {
		if (isCustomValue(state, fieldName)) { // checks if value is custom in the state before this event
			actualValue = getProperty(state.actualValues, fieldName);
		} else {
			actualValue = defaultValue;
		}
	}
	else {
		actualValue = enteredValue;
	}

	return {
		defaultValue,
		actualValue
	};
}

export const undefinedFormField: IFormField<any> = {
	defaultValue: undefined,
	actualValue: undefined
};

