import * as React from "react";

import ISystem from "../models/interfaces/ISystem";

import SystemContext from "./SystemContext";

import ConfigurationService from "../services/ConfigurationService";
import UnitOfMeasure from "../models/helpers/UnitOfMeasure";
import EquipmentByIdRequest from "../services/requests/EquipmentByIdRequest";
import ModelSelectionService from "../services/ModelSelectionService";
import StorageFacade from "../services/StorageFacade";

import useApplicationContext from "../hooks/useApplicationContext";
import useBoxContext from "../hooks/useBoxContext";
import useBoxMaterialsContext from "../hooks/useBoxMaterialsContext";
import useSystemReducer from "../hooks/useSystemReducer";

interface SystemContextProviderProps {
	children: any;
}

const SystemContextProvider: React.FunctionComponent<SystemContextProviderProps> = ({
	children,
}: SystemContextProviderProps) => {

    const { isAppDataLoaded, appData } = useApplicationContext();
    const { loadBox, isBoxDataLoaded } = useBoxContext();
    const { isMaterialListLoaded } = useBoxMaterialsContext();
    const { systemState, dispatch, updateStoreFlag, setUpdateStoreFlag } = useSystemReducer();
 
    React.useEffect(() => {
        const storeBoxId = StorageFacade.loadCalcSystem?.boxApplicationId;
        if (storeBoxId) {
            loadBox(storeBoxId);
        }
    }, []);

    React.useEffect(() => {
        if (isAppDataLoaded 
            && isBoxDataLoaded
            && isMaterialListLoaded
        ) {

            const getEquipmentDetails = async () => {
                let storeSystem = StorageFacade.loadCalcSystem;

                const boxApplicationData = await ConfigurationService.getApplicationByIdAsync(storeSystem?.boxApplicationId ?? 0);
                boxApplicationData.isBoxFreezer = boxApplicationData.isFreezer === "Y";
                
                // get default equipment parameters
                const defaultCondensingUnitAmbientTemperature = appData.EQUIPMENT?.CondensorAmbient?.value;
                const defaultSuctionLineLoss = appData.EQUIPMENT?.LineLoss?.Default?.value;
                const defaultDesignTd = (boxApplicationData.rH_TD < 0)
                    ? 0 - boxApplicationData.rH_TD
                    : UnitOfMeasure.TDatRH(boxApplicationData.rH_TD);

                if (storeSystem?.equipmentSelection.selectedCondenserUnit?.class9ID) {
                    // get condensing equipment details
                    const request: EquipmentByIdRequest = {
                        divisionId: +StorageFacade.divisionId,
                        interiorTemp: storeSystem.equipmentSelection.interiorTemperature,
                        refrigerantFluidId: storeSystem.equipmentSelection.refrigerant ?? 0,
                        ambientTemp: storeSystem.equipmentSelection.condensingUnitAmbientTemperature ?? defaultCondensingUnitAmbientTemperature,
                        suctionLineLoss: storeSystem.equipmentSelection.suctionLineLoss ?? defaultSuctionLineLoss,
                        td: storeSystem.equipmentSelection.designTd ?? defaultDesignTd,
                        selectedOptions: storeSystem.equipmentSelection.selectedCondenserOptionList.map(o => o.id).join(",")
                    };
                
                    const equipmentDetails = await ModelSelectionService.getEquipmentByIdAsync(
                        storeSystem.equipmentSelection.selectedCondenserUnit.class9ID,
                        request);
                
                    const equipmentUnit = {
                        ...storeSystem.equipmentSelection.selectedCondenserUnit,
                        capacityPerUnit: equipmentDetails.capacityPerUnit,
                        description: equipmentDetails.description,
                        features: equipmentDetails.features,
                        requiredOptions: equipmentDetails.requiredOptions,
                        class0ID: equipmentDetails.class0ID,
                        class8ID: equipmentDetails.class8ID,
                        class9ID: equipmentDetails.class9ID,
                        awefCompliant: equipmentDetails.awefCompliant,
                        warnings: [...equipmentDetails.warnings ?? []]
                    }
    
                    storeSystem = {
                        ...storeSystem,
                        equipmentSelection: {
                            ...storeSystem.equipmentSelection,
                            selectedCondenserUnit: equipmentUnit
                        }
                    };
                }

                if (storeSystem?.equipmentSelection.selectedEvaporator?.class9ID) {
                    // get evaporator equipment details
                    const request: EquipmentByIdRequest = {
                        divisionId: +StorageFacade.divisionId,
                        interiorTemp: storeSystem.equipmentSelection.interiorTemperature,
                        refrigerantFluidId: storeSystem.equipmentSelection.refrigerant ?? 0,
                        ambientTemp: storeSystem.equipmentSelection.condensingUnitAmbientTemperature ?? defaultCondensingUnitAmbientTemperature,
                        suctionLineLoss: storeSystem.equipmentSelection.suctionLineLoss ?? defaultSuctionLineLoss,
                        td: storeSystem.equipmentSelection.designTd ?? defaultDesignTd,
                        selectedOptions: storeSystem.equipmentSelection.selectedEvaporatorOptionList.map(o => o.id).join(",")
                    };
                
                    const equipmentDetails = await ModelSelectionService.getEquipmentByIdAsync(
                        storeSystem.equipmentSelection.selectedEvaporator.class9ID,
                        request);
                
                    const equipmentUnit = {
                        ...storeSystem.equipmentSelection.selectedEvaporator,
                        capacityPerUnit: equipmentDetails.capacityPerUnit,
                        description: equipmentDetails.description,
                        features: equipmentDetails.features,
                        requiredOptions: equipmentDetails.requiredOptions,
                        class0ID: equipmentDetails.class0ID,
                        class8ID: equipmentDetails.class8ID,
                        class9ID: equipmentDetails.class9ID,
                        awefCompliant: equipmentDetails.awefCompliant,
                        warnings: [...equipmentDetails.warnings ?? []]
                    }
    
                    storeSystem = {
                        ...storeSystem,
                        equipmentSelection: {
                            ...storeSystem.equipmentSelection,
                            selectedEvaporator: equipmentUnit
                        }
                    };
                }

                StorageFacade.loadCalcSystem = storeSystem;

                dispatch({ 
                    type: "set-initial-state" 
                });
            }

            getEquipmentDetails();
        }
    }, [
        isAppDataLoaded,
        isBoxDataLoaded,
        isMaterialListLoaded
    ]);

    const updateStore = (system: ISystem) => {
        StorageFacade.loadCalcSystem = system;
    }

    React.useEffect(() => {
        if (updateStoreFlag) {
            updateStore(systemState.store);
            setUpdateStoreFlag(false);
        }
    }, [
        systemState.store,
        updateStoreFlag
    ]);
    
    const contextValue = {
        systemState,
        dispatch
    };

    return (
        <SystemContext.Provider value={contextValue}>
            {children}
        </SystemContext.Provider>
    );
};

export default SystemContextProvider;
