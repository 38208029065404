import { enTooltips } from "./en-tooltips";

export const enStrings = {
	...enTooltips,
	_Feeds: "# Feeds",
	_threeDollarSigns: "$$$",
	_Cancel: "&Cancel",
	_Feed: "/Feed",
	_RDPARTY: "3RD PARTY",
	_PreviousModel: "< Previous Model",
	_SelectPreviousModel: "< Select Previous Model",
	_TD: "<TD",
	a_ZDescription: "A - Z Description",
	a_ZName: "A - Z Name",
	aConfidentialErrorReportWillBeSentToTheSoftwareDevelopmentTeam_:
		"A confidential error report will be sent to the software development team.",
	aConstructionTypeNameIsNotEntered_:
		"A construction type name is not entered 1",
	aCriticalErrorOccurredInTheApplication_:
		"A critical error occurred in the application!",
	aDescriptionHasNotBeenEntered_: "A description has not been entered !",
	aProblemOccurredWhileRegisteringYourNewPassword_:
		"A problem occurred while registering your new password!",
	aProblemOccurredWhileRegisteringYourNewUserInformation_:
		"A problem occurred while registering your new user information.",
	aProgramOrDataUpdateIsAvailable_: "A program or data update is available.",
	aProgramUpdateIsAvailable_: "A program update is available.",
	aPurchaseOrderNumberIsRequired_: "A purchase order number is required!",
	aSurchargeWillApply_: "A surcharge will apply.",
	a_C_L_: "A.C.L.",
	a_C_T_: "A.C.T.",
	a_T_D_: "A.T.D.",
	aCT: "ACT",
	aIRCOOLEDCONDENSOR: "AIR COOLED CONDENSOR",
	aIRFLOW: "AIR FLOW",
	aLL: "ALL",
	aLLAPPLICABLETAXESEXTRA: "ALL APPLICABLE TAXES EXTRA",
	aLLCLASSES: "ALL CLASSES",
	aLLMODELS: "ALL MODELS",
	aLTITUDE: "ALTITUDE",
	aLTITUDEFACTOR: "ALTITUDE FACTOR",
	aMBIENT: "AMBIENT",
	aMPS: "AMPS",
	aNY: "ANY",
	aPPLICATIONFAILURE: "APPLICATION FAILURE",
	aT: "AT",
	aTHR: "ATHR",
	aTTENTION: "ATTENTION",
	abort: "Abort",
	aborted_NoUPDATESFolderFound_: "Aborted, no UPDATES folder found.",
	about: "About",
	aboutNROES: "About NROES",
	aboutThisApplication: "About This Application",
	active: "Active",
	activeOrderManager: "Active Order Manager",
	activeOrders: "Active Orders",
	activePurchaseOrderManager: "Active Purchase Order Manager",
	activeRecordsShown: "Active Records Shown",
	activity: "Activity",
	actual: "Actual",
	actualAir_T: "Actual Air ΔT",
	actualCondensingTemperature: "Actual Condensing Temperature",
	actualFluidFlowRate: "Actual Fluid Flow Rate",
	actualFluidOutletTemperature: "Actual Fluid Outlet Temperature",
	actualFluidTD: "Actual Fluid TD",
	actualFluid_T: "Actual Fluid ΔT",
	actualTDCanNotBeGreaterThan: "Actual TD can not be greater than",
	actualTDCanNotBeLessThan: "Actual TD can not be less than",
	actualTDMustBeGreaterThanZero_: "Actual TD must be greater than zero !",
	add: "Add",
	add_EditOptions: "Add / Edit Options",
	addBlankQuoteRevision: "Add Blank Quote Revision",
	addCatalogueModel: "Add Catalogue Model",
	addConnection: "Add Connection",
	addCustomSection: "Add Custom Section",
	addFeed: "Add Feed",
	addLoad: "Add Load",
	addModel: "Add Model",
	addModelPriceAdjustment: "Add Model Price Adjustment",
	addMoreConnections: "Add More Connections",
	addNon_CatalogueItem: "Add Non-Catalogue Item",
	addNon_CatalogueOption: "Add Non-Catalogue Option",
	addParts: "Add Parts",
	addProduct: "Add Product",
	addSectionAndStartAnotherNewOne_Insert_:
		"Add Section and Start Another New one [Insert]",
	addSelectedPartTypesToOrder: "Add Selected Part Types To Order",
	addServiceParts: "Add Service Parts",
	addSystem: "Add System",
	addSystemFreightAllowance: "Add System Freight Allowance",
	addSystemOption: "Add System Option",
	addToOrder: "Add To Order",
	addWall: "Add Wall",
	addANewSystem_: "Add a new system.",
	addAsNewItems_: "Add as new items.",
	addBlankQuoteRevision_: "Add blank quote revision.",
	addClipboardSystemItemsToCurrentSystem_:
		"Add clipboard system items to current system.",
	addContactToYourDatabase_: "Add contact to your database.",
	addNewQuoteRevision: "Add new quote revision",
	addOrEditOptions: "Add or Edit Options",
	additionalE_MailText: "Additional E-Mail Text",
	additionalInstructions_Notes: "Additional Instructions / Notes",
	additionalOrderingInstructions: "Additional ordering instructions",
	address: "Address",
	adjustAllMultipliers: "Adjust All Multipliers",
	adjustOrderPrice: "Adjust Order Price",
	adjustPrice: "Adjust Price",
	adjustM: "AdjustM",
	adjustedPrice: "Adjusted Price",
	adjustment: "Adjustment",
	again: "Again",
	airCooledCondenserDesignTools: "Air Cooled Condenser Design Tools",
	airCooledCondenserDesigner_FitAModel:
		"Air Cooled Condenser Designer : Fit A Model",
	airCooledCondenserDesigner_FitAJob:
		"Air Cooled Condenser Designer : Fit a Job",
	airCooledCondenserModelSelector: "Air Cooled Condenser Model Selector",
	airCooledCondensingUnit: "Air Cooled Condensing Unit",
	airFlow: "Air Flow",
	airFlowDirection: "Air Flow Direction",
	airIn: "Air In",
	airIn_DB_: "Air In (DB)",
	airIn_WB_: "Air In (WB)",
	airInletTemperature: "Air Inlet Temperature",
	airInletTemperature_DB_: "Air Inlet Temperature (DB)",
	airInletTemperature_WB_: "Air Inlet Temperature (WB)",
	airInletTemperature_Dry: "Air Inlet Temperature - Dry",
	airInletTemperature_Wet: "Air Inlet Temperature - Wet",
	airInletTemperatureExceedsFanRating_:
		"Air Inlet Temperature Exceeds Fan Rating!",
	airOut: "Air Out",
	airOutletTemperature: "Air Outlet Temperature",
	airOutletTemperatureExceedsFanRating_:
		"Air Outlet Temperature Exceeds Fan Rating!",
	airOutletTemperatureLessThanWetBulbTemperature_:
		"Air Outlet Temperature Less Than Wet Bulb Temperature!",
	airflow: "Airflow",
	allImperial: "All Imperial",
	allMetric_SI_: "All Metric (SI)",
	allOrders: "All Orders",
	allCertifiedPrintsHaveBeenSigned: "All certified prints have been signed",
	allListedOrdersWereMarkedAsClosed_:
		"All listed orders were marked as closed.",
	allListedOrdersWereMarkedAsShipped_:
		"All listed orders were marked as shipped.",
	allOrderItemsWillBeShippedAtTheLongestLeadTime_:
		"All order items will be shipped at the longest lead time.",
	allParametersChecked_: "All parameters checked.",
	allPricesWereUpToDate_: "All prices were up to date!",
	allSelectedPartsAlreadyExistOnTheOrder_:
		"All selected parts already exist on the order.",
	allSystemDesignTemperaturesHaveBeenChecked:
		"All system design temperatures have been checked",
	altitude: "Altitude",
	altitudeFactor: "Altitude Factor",
	altitudeIsNotSet_: "Altitude Is Not Set!",
	aluminum: "Aluminum",
	ambient: "Ambient",
	ambientTemp: "Ambient Temp",
	ambientTemperature_BoxTemperature_: "Ambient temperature < Box temperature!",
	ambientTeperatureMustBeGraterThan: "Ambient teperature must be grater than",
	ambientTeperatureMustBeLessThan: "Ambient teperature must be less than",
	amount: "Amount",
	amountPer: "Amount Per",
	anApplicationUpdateIsAvailable_: "An application update is available!",
	anAuthorizationRequestHasBeenSentToTheRegistrar_:
		"An authorization request has been sent to the registrar!",
	anErrorOccurredRunningTheJob_ScopeTranslation_:
		"An error occurred running the Job-Scope translation!",
	anIssueOccuredTryingToApplyForRegistration_:
		"An issue occured trying to apply for registration.",
	anIssueOccurredWhileInstallingDataUpdates_:
		"An issue occurred while installing data updates.",
	anUnexpectedErrorOccured_: "An unexpected error occured!",
	anUnexpectedErrorOccurred_: "An unexpected error occurred!",
	anUnexpectedErrorOcurred_: "An unexpected error ocurred!",
	anUpdatedPurchaseOrderIsAvailableOnRequest:
		"An updated purchase order is available on request",
	applicationRegistrationCodeRequested_:
		"Application Registration Code Requested.",
	applicationTitle: "Application Title",
	applicationDataFileHaveNotBeenDownloaded_:
		"Application data file have not been downloaded!",
	applicationMustBeOn_line_: "Application must be on-line.",
	applicationWillExpireAfter__Days_: "Application will expire after 30 days.",
	applicationWillNowClose_: "Application will now close.",
	archivedRecordsShown: "Archived Records Shown",
	areYouARegisteredUser_: "Are you a registered user?",
	areYouSureThisIsTheRightNumber_: "Are you sure this is the right number?",
	areYouSureYouWantToCreateABrandNewOrder_:
		"Are you sure you want to create a brand new order.",
	areYouSureYouWantToDeleteTheSelectedItems_:
		"Are you sure you want to delete the selected items?",
	areYouSureYouWantToDeleteTheSelectedSystem_:
		"Are you sure you want to delete the selected system?",
	areYouSureYouWantToDeleteThisChangeOrder_:
		"Are you sure you want to delete this change order?",
	areYouSureYouWantToDeleteThisContact_:
		"Are you sure you want to delete this contact?",
	areYouSureYouWantToDeleteThisQuoteRevision_:
		"Are you sure you want to delete this quote revision?",
	areYouSureYouWantToDoThis_: "Are you sure you want to do this.",
	areYouSureYouWantToOpenTheServerVersion_:
		"Are you sure you want to open the server version?",
	areYouSureYouWantToPlaceThisOrderOnHold_:
		"Are you sure you want to place this order on hold?",
	areYouSureYouWantToRejectThisOrder_:
		"Are you sure you want to reject this order?",
	areYouSureYouWantToSetThisValue_: "Are you sure you want to set this value?",
	areYouSure_: "Are you sure?",
	area: "Area",
	areaIsNotCalculatedOrEntered_: "Area is not calculated or entered !",
	at: "At",
	atLeastOneConnectionErrorExists_: "At least one connection error exists !",
	atLeastOneConnectionWarningExists_:
		"At least one connection warning exists !",
	atLeastOneDoorHasAnEntryOrCalculationError_:
		"At least one door has an entry or calculation error !",
	atLeastOneDoorHasAnEntryOrCalculationWarning_:
		"At least one door has an entry or calculation warning !",
	atLeastOneLayerErrorExists_: "At least one layer error exists !",
	atLeastOneLayerWarningExists_: "At least one layer warning exists !",
	atLeastOneListedProductHasAWarning_:
		"At least one listed product has a warning !",
	atLeastOneListedProductHasAnError_:
		"At least one listed product has an error !",
	atLeastOneOtherLoadHasAWarning_: "At least one other load has a warning ! ",
	atLeastOneOtherLoadHasAnError_: "At least one other load has an error ! ",
	atLeastOneRequestedDateIsSetToPriorToThe:
		"At least one requested date is set to prior to the",
	atLeastOneRevisionWasAlteredByAnotherUserOrOnAnotherWorkstation_:
		"At least one revision was altered by another user or on another workstation!",
	atLeastOneSectionHasErrors_: "At least one section has errors !",
	atLeastOneSectionHasErrorsOrWarnings_:
		"At least one section has errors or warnings.",
	atLeastOneSectionHasWarnings_: "At least one section has warnings !",
	atThisTime_YourOriginalOrderHasNotBeenProcessedByOurOrderDepartment_:
		"At this time, your original order has not been processed by our Order Department.",
	atThisTime_YourOriginalRequestHasNotBeenProcessedByThePurchaser_:
		"At this time, your original request has not been processed by the purchaser.",
	atThisTime_YourPreviousChangeRequestHasNotBeenProcessedByOurOrderDepartment_:
		"At this time, your previous change request has not been processed by our Order Department.",
	atThisTime_YourPreviousChangeRequestHasNotBeenProcessedByThePurchaser_:
		"At this time, your previous change request has not been processed by the purchaser.",
	availableOptions: "Available Options",
	availablePriceBooks: "Available Price Books",
	averageWork_: "Average Work.",
	bUILDING: "BUILDING",
	back: "Back",
	badRatingColumnCount_: "Bad rating column count!",
	badRatingRowCount_: "Bad rating row count!",
	ballyLoadCalcFiles: "Bally Load Calc Files",
	ballyRefrigeratedBoxes: "Bally Refrigerated Boxes",
	baseModel: "Base model",
	belowFluid_sRatedMinimumTemperature_:
		"Below fluid's rated minimum temperature!",
	bestFit: "Best Fit",
	billOfLading: "Bill Of Lading",
	blankTubes: "Blank Tubes",
	blanks: "Blanks",
	both: "Both",
	box: "Box",
	boxDesigner: "Box Designer",
	boxExteriorRelativeHumidity___: "Box Exterior Relative Humidity (%)",
	boxExteriorTemperature: "Box Exterior Temperature",
	boxInteriorRelativeHumidity___: "Box Interior Relative Humidity (%)",
	boxInteriorTemperature: "Box Interior Temperature",
	boxLocation: "Box Location",
	boxTemp: "Box Temp",
	boxTemperature: "Box Temperature",
	boxInformationIsMissing_: "Box information is missing.",
	browse: "Browse",
	building: "Building",
	button: "Button",
	by: "By",
	cALCULATEDDETAILS: "CALCULATED DETAILS",
	cALCULATOR: "CALCULATOR",
	cALL: "CALL",
	cAPACITY: "CAPACITY",
	cERTIFIEDPRINTWIZARD: "CERTIFIED PRINT WIZARD",
	cHARGE: "CHARGE",
	cHARGE_FORCED_: "CHARGE (FORCED)",
	cIRCUITTOTAL: "CIRCUIT TOTAL",
	cL: "CL",
	cOLLECT: "COLLECT",
	cOMP: "COMP",
	cOMPLETE: "COMPLETE",
	cOMPLEXFLOORPLANNER: "COMPLEX FLOOR PLANNER",
	cOMPRESSOR: "COMPRESSOR",
	cOMPRESSORUNITSELECTION: "COMPRESSOR UNIT SELECTION",
	cONDENSINGUNITSELECTION: "CONDENSING UNIT SELECTION",
	cONNECTIONDIAGRAM: "CONNECTION DIAGRAM",
	cONNECTIONS: "CONNECTIONS",
	cONTINUEDONPAGE: "CONTINUED ON PAGE",
	cOULDNOTLOADPRICEBOOK: "COULD NOT LOAD PRICE BOOK",
	cOULDNOTREADCURRENTORDERSUPLOADDATA_:
		"COULD NOT READ CURRENT ORDERS UPLOAD DATA!",
	cPWIZ_Splash: "CPWIZ_Splash",
	cREATENEWORDER: "CREATE NEW ORDER",
	cUSTOMER: "CUSTOMER",
	calculate: "Calculate",
	calculatedDetails: "Calculated Details",
	calculatedFluidFlowRate: "Calculated Fluid Flow Rate",
	calculatedValue: "Calculated Value",
	calculatedCondensingTemperatureCanNotBeGreaterThan:
		"Calculated condensing temperature can not be greater than",
	calculatedCondensingTemperatureCanNotBeLessThan:
		"Calculated condensing temperature can not be less than",
	calculatedCondensingTemperatureMustBeGreaterThanTheAmbientTemperature_:
		"Calculated condensing temperature must be greater than the ambient temperature !",
	calculatedCondensingTemperatureMustBeGreaterThanTheSuctionTemperature_:
		"Calculated condensing temperature must be greater than the suction temperature !",
	calculatedShipDatesMayBeAnApproximation_:
		"Calculated ship dates may be an approximation.",
	calculateSystemCharge: "Calculate System Charge",
	calendar: "Calendar",
	canNotCalculateAirOutTemperature_: "Can Not Calculate Air Out Temperature!",
	canNotCalculateFluidOutTemperature_:
		"Can Not Calculate Fluid Out Temperature!",
	canNotCalculateTotalHeatTransmission_:
		"Can Not Calculate Total Heat Transmission!",
	canNotAddServiceParts_: "Can not add service parts.",
	canNotBeGreaterThanFluidTemperatureRange_:
		"Can not be greater than fluid temperature range!",
	canNotBeGreaterThanWetBuldTemperature_:
		"Can not be greater than wet buld temperature!",
	canNotCheckPrices_: "Can not check prices!",
	canNotContinue_: "Can not continue!",
	canNotCopy_: "Can not copy.",
	canNotDeleteFactoryOrderFile_: "Can not delete factory order file.",
	canNotDeleteOldOrder_: "Can not delete old order!",
	canNotDeleteOrder_: "Can not delete order.",
	canNotDeleteThisOrder_: "Can not delete this order.",
	canNotFetchOrderFile_: "Can not fetch order file!",
	canNotOpenOrder_: "Can not open order!",
	canNotPaste_: "Can not paste.",
	canNotPreview_: "Can not preview.",
	canNotPrint_: "Can not print.",
	canNotReadFile_: "Can not read file.",
	canNotReadOrderFile_: "Can not read order file!",
	canNotRecoverEdits_: "Can not recover edits.",
	canNotSetRejectedStatus_: "Can not set rejected status.",
	canNotStartApplicationShell_: "Can not start application shell.",
	canNotStartApplication_: "Can not start application.",
	canNotStopRequest_: "Can not stop request.",
	canNotStoreOrderFile_: "Can not store order file!",
	canNotSubmitChange_: "Can not submit change!",
	canNotSubmitOrder_: "Can not submit order!",
	canNotSwitchPriceBooks_: "Can not switch price books.",
	canNotUploadOrder_: "Can not upload order!",
	canadian: "Canadian",
	canadianEnglish: "Canadian English",
	cancel: "Cancel",
	cancelThisOperation: "Cancel this operation",
	cancelThisOperation_: "Cancel this operation.",
	cancelUpload_: "Cancel upload.",
	cancellingDuringTheQuickboxWalkThroughWillCloseTheTool_:
		"Cancelling during the quickbox walk through will close the tool.",
	cannotContinue_: "Cannot continue!",
	cannotCreateBackupFolder: "Cannot create backup folder",
	cannotDeleteBackupFolder_: "Cannot delete backup folder.",
	cannotInstallUpdateFolder: "Cannot install update folder ",
	cannotStartTool_: "Cannot start tool.",
	cannotUpdate_: "Cannot update!",
	capacity: "Capacity",
	capacityExceedsRequestedRange_: "Capacity Exceeds Requested Range!",
	capacityPerUnit: "Capacity Per Unit",
	capacityRange___: "Capacity Range (%)",
	capacityTooLow_: "Capacity Too Low!",
	capacityVariance: "Capacity Variance",
	capacityCanNotBeBlank_: "Capacity can not be blank.",
	capacityIsNotCalculated_: "Capacity is not calculated ! ",
	capacityIsZero_: "Capacity is zero !",
	capacityMustBeGreaterThanZero_: "Capacity must be greater than zero.",
	caption: "Caption",
	carrier: "Carrier",
	cascade: "Cascade",
	catalogModel: "Catalog Model",
	catalogueNotLoaded_: "Catalogue not loaded!",
	ceiling: "Ceiling",
	centreDistance: "Centre Distance",
	certainOptionsMayNoLongerBeAvailable_:
		"Certain options may no longer be available.",
	certifiedDrawing: "Certified Drawing",
	certifiedPrint: "Certified Print",
	certifiedPrintWizard: "Certified Print Wizard",
	certifiedPrints: "Certified Prints",
	challengeAnswer: "Challenge Answer",
	challengeQuestion: "Challenge Question",
	change_Shape: "Change Shape",
	changeAllMultipliers: "Change All Multipliers",
	changeOrderForm: "Change Order Form",
	changePassword: "Change Password",
	changeReq: "Change Req",
	changeRequest: "Change Request",
	changesNotYetAppliedToCurrentOrder_:
		"Changes not yet applied to current order.",
	changesToTheCurrentOrderHaveNotBeenSaved_:
		"Changes to the current order have not been saved!",
	checkingForUpdates: "Checking For Updates",
	circuitLoadCanNotBeCalculated_: "Circuit load can not be calculated.",
	class: "Class",
	clear: "Clear",
	clearDate: "Clear Date",
	clickHereToAdjustTheMultipliersToGenerateTheNewPrice_:
		"Click here to adjust the multipliers to generate the new price.",
	clickHereToApplyThePriceChangeAndReturnToTheOrderWindow_:
		"Click here to apply the price change and return to the order window.",
	clickHereToCloseThisFormWithoutChangingTheOrder_:
		"Click here to close this form without changing the order.",
	clickHereToResetAllMultipliersToTheirDefaultValues_:
		"Click here to reset all multipliers to their default values.",
	clickOnNOToCancelThisSaveOperation_:
		"Click on NO to cancel this save operation,",
	clickingOnNoWillKeepTheCurrentOwner_sMultiplierStructure_:
		"Clicking on no will keep the current owner's multiplier structure.",
	clipboardContainsACompleteSystem_: "Clipboard contains a complete system.",
	close: "Close",
	closeAllOrders: "Close All Orders",
	closeOrder: "Close Order",
	closeOrderManager: "Close Order Manager",
	closeShellWhenICloseTheLastDesignerWindow:
		"Close shell when I close the last designer window",
	closed: "Closed",
	closingThemMayResultInLossOfData_: "Closing them may result in loss of data.",
	code: "Code",
	collect: "Collect",
	columns: "Columns",
	compCapacity: "Comp Capacity",
	company: "Company",
	companyName: "Company Name",
	company_Address_Country_City_Region_PhoneFieldsAreAllRequired_:
		"Company, Address, Country, City, Region, Phone fields are all required.",
	completeDirect: "Complete Direct",
	completeSquare: "Complete Square",
	composite: "Composite",
	compositeInsulationConstruction: "Composite Insulation Construction",
	compositeK_Factor: "Composite K-Factor",
	compositeR_Value: "Composite R-Value",
	compressor: "Compressor",
	compressorBalanceSSTTooHigh_: "Compressor balance SST too high !",
	compressorBalanceSSTTooLow_: "Compressor balance SST too low !",
	compressorCapacityIsRequired_: "Compressor capacity is required !",
	compressorCapacityMustBeGreaterThanZero_:
		"Compressor capacity must be greater than zero !",
	compressorUnitCapacityError_: "Compressor unit capacity error !",
	compressorUnitCapacityNotCalculated_:
		"Compressor unit capacity not calculated !",
	compressorUnitNotSelected_: "Compressor unit not selected !",
	compressorWithCondenser: "Compressor with Condenser",
	concentration: "Concentration",
	condTemp: "Cond Temp",
	cond_Temp: "Cond. Temp",
	condenserBalanceSSTTooHigh_: "Condenser balance SST too high !",
	condenserBalanceSSTTooLow_: "Condenser balance SST too low !",
	condenserCapacityError_: "Condenser capacity error !",
	condenserCapacityNotCalculated_: "Condenser capacity not calculated !",
	condenserContainsAtLeastOneError_: "Condenser contains at least one error !",
	condenserContainsAtLeastOneWarning_:
		"Condenser contains at least one warning !",
	condenserNotSelected_: "Condenser not selected !",
	condensing: "Condensing",
	condensingTemp: "Condensing Temp",
	condensingTemp_S_C_T__: "Condensing Temp (S.C.T.)",
	condensingTemperature: "Condensing Temperature",
	condensingUnit: "Condensing Unit",
	condensingUnitAmbientTemperature: "Condensing Unit Ambient Temperature",
	condensingTemperature_AmbientTemperature_:
		"Condensing temperature < Ambient temperature!",
	condensingTemperature_BoxTemperature_:
		"Condensing temperature < Box temperature!",
	condensingTemperatureCanNotBeGreaterThan:
		"Condensing temperature can not be greater than",
	condensingTemperatureCanNotBeLessThan:
		"Condensing temperature can not be less than",
	condensingTemperatureIsLessThanSiteAmbientTemperature_:
		"Condensing temperature is less than site ambient temperature.",
	condensingTemperatureIsMissing_: "Condensing temperature is missing.",
	condensingTemperatureIsRequired_: "Condensing temperature is required !",
	condensingTemperatureMustBeGreaterThanTheAmbientTemperature_:
		"Condensing temperature must be greater than the ambient temperature !",
	condensingTemperatureMustBeGreaterThanTheSuctionTemperature_:
		"Condensing temperature must be greater than the suction temperature !",
	condensingUnitBalanceSSTTooHigh_: "Condensing unit balance SST too high !",
	condensingUnitBalanceSSTTooLow_: "Condensing unit balance SST too low !",
	condensingUnitCapacityError_: "Condensing unit capacity error !",
	condensingUnitCapacityNotCalculated_:
		"Condensing unit capacity not calculated !",
	condensingUnitNotSelected_: "Condensing unit not selected !",
	conduction: "Conduction",
	configuration: "Configuration",
	confirmationIsRequired_: "Confirmation is required!",
	confirmationRequired_: "Confirmation required!",
	connected: "Connected",
	connectionDetails: "Connection Details",
	connectionSize: "Connection Size",
	connectionCrossesTheCentreLineOfTheUnit_SomeOptionsMayNotBeAvailable_:
		"Connection crosses the centre line of the unit, some options may not be available !",
	connectionIsOutsideBoundsOfTheUnit_:
		"Connection is outside bounds of the unit !",
	connectionNotAssignedToFeeds_: "Connection not assigned to feeds !",
	connections: "Connections",
	construction_Insulation: "Construction / Insulation",
	constructionLayer: "Construction Layer",
	constructionLayers: "Construction Layers",
	contact: "Contact",
	contactName: "Contact Name",
	contacts: "Contacts",
	continued: "Continued",
	copper: "Copper",
	copy: "Copy",
	copyCurrentQuoteAsNewRevision: "Copy Current Quote As New Revision",
	copyCurrentRevisionEditsAsNewRevision_AndRestoreThisRevisionToLastSavePoint:
		"Copy Current Revision Edits As New Revision, and Restore This Revision to Last Save Point",
	copySection: "Copy Section",
	copyAsNewOrder: "Copy as New Order",
	copyLocalOrderWithNewOrderNumber_ThenDownloadMasterVersion_:
		"Copy local order with new order number, then download master version.",
	copyMasterVersionWithNewOrderNo_ThenReplaceMasterVersionWithLocalCopy:
		"Copy master version with new order no, then replace master version with local copy",
	copyQuote: "Copy quote",
	copyToShipToAddress: "Copy to Ship To Address",
	copyToSoldToAddress: "Copy to Sold To Address",
	couldNotAccessRecoveryData_: "Could not access recovery data.",
	couldNotAddNewContactRecord_: "Could not add new contact record!",
	couldNotCalculateActualTD_: "Could not calculate actual TD !",
	couldNotCalculateDesignTD_: "Could not calculate design TD !",
	couldNotCalculateDesignCircuitLoad_:
		"Could not calculate design circuit load !",
	couldNotCalculateDesignHeatOfCompressionFactor_:
		"Could not calculate design heat of compression factor !",
	couldNotCalculateDesignTotalHeatRejected_:
		"Could not calculate design total heat rejected !",
	couldNotCalculateRefrigerantFactor_:
		"Could not calculate refrigerant factor !",
	couldNotCalculateSiteAltitudeFactor_:
		"Could not calculate site altitude factor !",
	couldNotCalculateTheTotalHeatRejected_:
		"Could not calculate the total heat rejected !",
	couldNotChangeMasterRevision_: "Could not change master revision.",
	couldNotChangePassword_: "Could not change password!",
	couldNotConnectToServer_: "Could not connect to server.",
	couldNotCreateArchivePath_: "Could not create archive path!",
	couldNotCreateFactoryOrder_: "Could not create factory order!",
	couldNotCreateNewContactRecord_: "Could not create new contact record!",
	couldNotCreateOrderStorePath_: "Could not create order store path!",
	couldNotDeleteFile: "Could not delete file",
	couldNotDeleteFile_: "Could not delete file.",
	couldNotDeleteOneOfTheSelectedItems_:
		"Could not delete one of the selected items !",
	couldNotDeleteOrderStorePath_: "Could not delete order store path!",
	couldNotDeleteSomeOfTheSelectedItems_:
		"Could not delete some of the selected items !",
	couldNotDeleteTheSelectedItem_: "Could not delete the selected item !",
	couldNotDownloadThisOrder_: "Could not download this order!",
	couldNotExportOrderFile_: "Could not export order file.",
	couldNotExtrapolateCompressorRating_:
		"Could not extrapolate compressor rating !",
	couldNotExtrapolateCondenserRating_:
		"Could not extrapolate condenser rating !",
	couldNotExtrapolateCondensingUnitRating_:
		"Could not extrapolate condensing unit rating !",
	couldNotExtrapolateEvaporatorRating_:
		"Could not extrapolate evaporator rating !",
	couldNotFindArchivePath_: "Could not find archive path!",
	couldNotFindOrderFile_: "Could not find order file!",
	couldNotGenerateTargetValue_: "Could not generate target value!",
	couldNotImportOrderFile_: "Could not import order file.",
	couldNotListLocalOrders_: "Could not list local orders!",
	couldNotLoadModelCatalogue_: "Could not load model catalogue!",
	couldNotLoadServicePartsDatabase_: "Could not load service parts database!",
	couldNotLockLocalOrderFile_: "Could not lock local order file!",
	couldNotLockOrderFile_: "Could not lock order file!",
	couldNotLockRemoteOrder_: "Could not lock remote order!",
	couldNotLockSubmittedOrder_: "Could not lock submitted order.",
	couldNotOpenDatabase_: "Could not open database.",
	couldNotOpenExportFile_: "Could not open export file!",
	couldNotOpenOrder_: "Could not open order!",
	couldNotReadCalculatorFile_: "Could not read calculator file!",
	couldNotReadDatabase_: "Could not read database.",
	couldNotReadFactoryCalendar_: "Could not read factory calendar!",
	couldNotReadFile_: "Could not read file!",
	couldNotReadOrderData_: "Could not read order data.",
	couldNotReadOrderHeader_: "Could not read order header.",
	couldNotReadOrderStatusFile_: "Could not read order status file!",
	couldNotReadResponse_: "Could not read response.",
	couldNotReadSynchronizedOrderStatusFile_:
		"Could not read synchronized order status file!",
	couldNotRetrieveAssociatedPurchaseRequest_:
		"Could not retrieve associated purchase request.",
	couldNotRetrieveOrderFile_: "Could not retrieve order file!",
	couldNotRetrieveOrderHeader_: "Could not retrieve order header.",
	couldNotStartShellApplication_: "Could not start shell application.",
	couldNotStartShell_: "Could not start shell.",
	couldNotStoreOrderFileToPath: "Could not store order file to path",
	couldNotSubmitOrder_: "Could not submit order.",
	couldNotSynchroniseRemoteOrderFile_:
		"Could not synchronise remote order file!",
	couldNotSynchronizeAddressBook_: "Could not synchronize address book!",
	couldNotSynchronizeOrder_: "Could not synchronize order!",
	couldNotSynchronizeOrders_: "Could not synchronize orders!",
	couldNotSynchronizeServerOrders_: "Could not synchronize server orders!",
	couldNotUpdateContactRecord_: "Could not update contact record!",
	couldNotUpdateOrderDatabase_: "Could not update order database!",
	couldNotUpdateOrderOnNROESServer_: "Could not update order on NROES server.",
	couldNotUpdatePurchaseRequestStatusOnServer_:
		"Could not update purchase request status on server.",
	couldNotUploadLocalVersion_: "Could not upload local version.",
	couldNotUploadOrder: "Could not upload order",
	couldNotUploadRevision_: "Could not upload revision.",
	couldNotWriteOrderFile_: "Could not write order file!",
	country: "Country",
	countryName: "Country Name",
	countyOrRegion: "County or Region",
	coveredOpening: "Covered Opening",
	createAnotherChangeOrder: "Create Another Change Order",
	createChangeOrder: "Create Change Order",
	createANewListing_: "Create a new listing.",
	criticalError_: "Critical error!",
	currentPrice: "Current  Price",
	currentMargin: "Current Margin",
	currentOrderPrice: "Current Order Price",
	currentQuotePrice: "Current Quote  Price",
	currentChangesWillBeLost_: "Current changes will be lost.",
	currentManufacturersMinimumPrice_: "Current manufacturers minimum price.",
	currentManufacturersRecommendedPrice_:
		"Current manufacturers recommended price.",
	currentOrderPrice_: "Current order price.",
	currentSetting_: "Current setting.",
	currentlyThereAreNoServicePartsListedForThisModel_:
		"Currently there are no service parts listed for this model.",
	cust_: "Cust #",
	custName: "Cust Name",
	custom: "Custom",
	customCeiling: "Custom Ceiling",
	customFloor: "Custom Floor",
	customWall: "Custom Wall",
	customer: "Customer",
	customer_: "Customer #",
	customerContact: "Customer Contact",
	customerInfo: "Customer Info",
	customerName: "Customer Name",
	customerREF_: "Customer REF #",
	customerRef: "Customer Ref",
	customerDatabaseIsMissing_: "Customer database is missing.",
	customerNumberEnteredIsAlreadyListedAbove__:
		"Customer number entered is already listed above.!",
	customerNumberEnteredIsNotInTheListShown_:
		"Customer number entered is not in the list shown!",
	customerReferenceMustBeNumeric_: "Customer reference must be numeric!",
	customerReferenceMustBeSixCharacters_:
		"Customer reference must be six characters!",
	cut: "Cut",
	cutItemsRequested_: "Cut items requested.",
	cutSystemRequested_: "Cut system requested.",
	dESCRIPTION: "DESCRIPTION",
	dESCRIPTION_REMARKS: "DESCRIPTION / REMARKS",
	dESIGNDATA: "DESIGN DATA",
	dESIGNTOOLS: "DESIGN TOOLS",
	dESIGNER: "DESIGNER",
	dETAILS: "DETAILS",
	dISTRIBUTOR: "DISTRIBUTOR",
	dIV: "DIV",
	dIVISION: "DIVISION",
	dONOTUseThisFunctionToChangeAnExistingOrder_:
		"DO NOT use this function to change an existing order!",
	dOCUMENTATIONBROWSER: "DOCUMENTATION BROWSER",
	dRAFT: "DRAFT",
	dataUpdatesFound: "Data Updates Found",
	dataRecovery_: "Data recovery.",
	date: "Date",
	dateCreated: "Date Created",
	dateModified: "Date Modified",
	dateOK: "Date OK",
	dateQuoted: "Date Quoted",
	dateCanNotBeOnAWeekend_: "Date can not be on a weekend.",
	dateChanged: "Date changed",
	dateIsPriorToToday_: "Date is prior to today.",
	dateMustBeInTheFuture_: "Date must be in the future!",
	dateVerifiedChanged: "Date verified changed",
	days_: "Days!",
	defaultNumericUnits: "Default Numeric Units",
	defaultUnits: "Default Units",
	delete: "Delete",
	deleteChangeOrder: "Delete Change Order",
	deleteCustomSection: "Delete Custom Section",
	deleteCustomSections: "Delete Custom Sections",
	deleteLayer: "Delete Layer",
	deleteLayers: "Delete Layers",
	deleteLoad: "Delete Load",
	deleteOrder: "Delete Order",
	deleteProduct: "Delete Product",
	deleteTheSelectedSystem_: "Delete the selected system.",
	deltaX: "Delta X",
	deltaY: "Delta Y",
	dep_Div: "Dep / Div",
	department: "Department",
	depth: "Depth",
	depth_IsARequiredValue_: "Depth 1 is a required value !",
	description: "Description",
	descriptionCanNotBeBlank_: "Description can not be blank !",
	descriptionIsMissing_: "Description is missing!",
	deselectingThisPackageWillAlsoDeselectALLThoseOptionsIncludedInThatPackage_:
		"Deselecting this package will also deselect ALL those options included in that package.",
	designAmbient: "Design Ambient",
	designConnections: "Design Connections",
	designRequirements: "Design Requirements",
	designTD: "Design TD",
	designTDCanNotBeGreaterThan: "Design TD can not be greater than",
	designTDCanNotBeLessThan: "Design TD can not be less than",
	designTDMustBeGreaterThanZero_: "Design TD must be greater than zero !",
	designTHRNotEntered_: "Design THR not entered !",
	designTools: "Design Tools",
	designToolsShellOptions: "Design Tools Shell Options",
	designCondensingTemperatureCanNotBeGreaterThan:
		"Design condensing temperature can not be greater than",
	designCondensingTemperatureCanNotBeLessThan:
		"Design condensing temperature can not be less than",
	designConnectionContainsErrorsOrWarnings_:
		"Design connection contains errors or warnings !",
	designContainsErrorsOrWarnings_: "Design contains errors or warnings !",
	designHasConfigurationErrors_: "Design has configuration errors !",
	designHasConfigurationWarnings_: "Design has configuration warnings !",
	designHasRequirementErrors_: "Design has requirement errors !",
	designHasRequirementWarnings_: "Design has requirement warnings !",
	designNotSaved: "Design not saved",
	designer: "Designer",
	details: "Details",
	detailsLabel: "DetailsLabel",
	dialog: "Dialog",
	dialog_Product_Selector: "Dialog_Product_Selector",
	differenceBetweenCurrentPriceAndFloorPrice_:
		"Difference between current price and floor price.",
	differenceBetweenNewPriceAndCurrentPrice_:
		"Difference between new price and current price.",
	differenceBetweenNewPriceAndFloorPrice_:
		"Difference between new price and floor price.",
	dim_: "Dim 1",
	dimension: "Dimension",
	dimension_IsNotEntered_: "Dimension 1 is not entered !",
	dimensions: "Dimensions",
	discardMyChanges_: "Discard my changes.",
	distance: "Distance",
	divisionsAvailable: "Divisions Available",
	doNotCheckTheAboveIfThis_WorkstationIsUsedByOtherPeople:
		"Do not check the above if this ~ workstation is used by other people",
	doNotShowThisMessageAgain: "Do not show this message again",
	doYouWantChangeAttachedOptionsToTheNewSetting_:
		"Do you want change attached options to the new setting?",
	doYouWantChangeTheAttachedOptionsToTheNewSetting_:
		"Do you want change the attached options to the new setting?",
	doYouWantChangeTheseOptionsToTheNewIsSetting_:
		"Do you want change these options to the new is setting?",
	doYouWantChangeTheseOptionsToTheNewSetting_:
		"Do you want change these options to the new setting?",
	doYouWantToApplyTheseSettingToTheCurrentOrder_:
		"Do you want to apply these setting to the current order?",
	doYouWantToApplyYourPersonalMultiplierStructure_:
		"Do you want to apply your personal multiplier structure?",
	doYouWantToBuildItNow_: "Do you want to build it now?",
	doYouWantToChangeTheSoldByInformationToYourName_:
		"Do you want to change the sold by information to your name.",
	doYouWantToDeleteTheSelectedOrderFromTheServerAndYourComputer_:
		"Do you want to delete the selected order from the server and your computer.",
	doYouWantToDeleteTheSelectedOrderFromYourComputerOny_:
		"Do you want to delete the selected order from your computer ony.",
	doYouWantToOpenTheOrderAnyway_: "Do you want to open the order anyway?",
	doYouWantToOpenTheOrderNow_: "Do you want to open the order now?",
	doYouWantToRemoveTheOrderFromTheLocalStore_:
		"Do you want to remove the order from the local store?",
	doYouWantToReplaceYourExistingQuoteInformation_:
		"Do you want to replace your existing quote information.",
	doYouWantToReplicateThisChangeToAllItems_:
		"Do you want to replicate this change to all items?",
	doYouWantToReplicateThisDateToAllItems_:
		"Do you want to replicate this date to all items?",
	doYouWantToRestoreTheseOrders_: "Do you want to restore these orders?",
	doYouWantToReviewYourErrorsNow_: "Do you want to review your errors now ?",
	doYouWantToRunInOff_lineMode_: "Do you want to run in off-line mode?",
	doYouWantToSaveItNow_: "Do you want to save it now?",
	doYouWantToSaveYourDataAnyway_: "Do you want to save your data anyway ?",
	doYouWantToShutdownAnyway_: "Do you want to shutdown anyway?",
	doYouWantToSubmitAnyway_: "Do you want to submit anyway?",
	doYouWantToSubmitThisOrderAnyway_: "Do you want to submit this order anyway?",
	doYouWantToUpdateTheOrderNow_: "Do you want to update the order now?",
	doYouWantToUpdateTheOrderToUseYourNewCatalogueProfileSettings_:
		"Do you want to update the order to use your new catalogue profile settings?",
	doYouWantToUpdateTheOrderToUseYourNewMultiplierStructure_:
		"Do you want to update the order to use your new multiplier structure?",
	doYouWantToUpdateThisRevisionToUseTheLatestPricebook_:
		"Do you want to update this revision to use the latest pricebook?",
	doYouWishToRetry_: "Do you wish to retry?",
	dockDoor: "Dock Door",
	documentGenerator: "Document Generator",
	documentContainsNoPages_: "Document contains no pages.",
	door: "Door",
	door_OpeningDetails: "Door / Opening Details",
	doorAndOpeningInfiltrationEstimate: "Door and Opening Infiltration Estimate",
	doorsAndOpenings: "Doors And Openings",
	down: "Down",
	downloadingApplicationShell: "Downloading Application Shell",
	downloadingDataUpdate_: "Downloading Data Update:",
	downloadingProgramUpdate_: "Downloading Program Update:",
	downloadingUpdates: "Downloading Updates",
	draft: "Draft",
	draftC_O_: "Draft C.O.",
	dualEnteringService: "Dual Entering Service",
	dualEnteringService__FanWideUnitWithSeperateControlsPerSide_:
		"Dual Entering Service (2 Fan Wide Unit With Seperate Controls Per Side)",
	dualEnteringService__FanWideUnitsWithSeperateControlsPerSide_:
		"Dual Entering Service (2 Fan Wide Units With Seperate Controls Per Side)",
	e_Mail: "E-Mail",
	e_Mail_UsernameCombinationAlreadyExists_:
		"E-Mail / Username combination already exists.",
	e_MailAddressAgain: "E-Mail Address Again",
	e_MailAddresses: "E-Mail Addresses",
	e_mailAddressesDoNotMatch_: "E-mail addresses do not match.",
	eACH: "EACH",
	eDIT: "EDIT",
	eNTERED: "ENTERED",
	eNTEREDDESIGNREQUIREMENTS: "ENTERED DESIGN REQUIREMENTS",
	eRROR: "ERROR",
	eRROR_: "ERROR !",
	eRRORS: "ERRORS",
	eULA: "EULA",
	eVAPORATORMOTORS: "EVAPORATOR MOTORS",
	eVAPORATORUNITSELECTION: "EVAPORATOR UNIT SELECTION",
	eXITNROES: "EXIT NROES",
	edit: "Edit",
	editConstruction: "Edit Construction",
	editLayer: "Edit Layer",
	editLoad: "Edit Load",
	editMode_: "Edit Mode:",
	editModel: "Edit Model",
	editProduct: "Edit Product",
	editQuoteInformation: "Edit Quote Information",
	editSection: "Edit Section",
	editSections: "Edit Sections",
	effectiveR_Value: "Effective R-Value",
	effectiveR_ValuePerUnitDepth: "Effective R-Value Per Unit Depth",
	either_ShippedClosedOrProcessedIsSet_:
		"Either, Shipped Closed or Processed is set.",
	electricalPower: "Electrical Power",
	elevationIsTooHigh_: "Elevation is too high!",
	elevationIsTooLow_: "Elevation is too low!",
	emailAddress: "Email Address",
	ending: "Ending",
	enter: "Enter",
	enterANumberEndingInAPercent_SymbolIfRequired_:
		"Enter a number ending in a percent % symbol if required.",
	entered: "Entered",
	enteredDesignRequirements: "Entered Design Requirements",
	enteredValue_: "Entered Value:",
	enteredMultiplierIsLessThanTheRecommendedMinimumValue_:
		"Entered multiplier is less than the recommended minimum value.",
	enteredPasswordDoNotMatch_: "Entered password do not match.",
	enteringTemperature: "Entering Temperature",
	equipmentList: "Equipment List",
	equipmentType: "Equipment Type",
	equipmentChangesOccurredOn: "Equipment changes occurred on",
	equipmentChangesOccurredOnOneRevision_:
		"Equipment changes occurred on one revision.",
	error: "Error",
	errorDetails_: "Error details.",
	errorsExistOnThisOrder_: "Errors exist on this order.",
	evapTemp: "Evap Temp",
	evap_Temp: "Evap. Temp",
	evaporatorBalanceSSTTooHigh_: "Evaporator balance SST too high !",
	evaporatorBalanceSSTTooLow_: "Evaporator balance SST too low !",
	evaporatorUnitCapacityError_: "Evaporator unit capacity error !",
	evaporatorUnitCapacityNotCalculated_:
		"Evaporator unit capacity not calculated !",
	evaporatorUnitNotSelected_: "Evaporator unit not selected !",
	exceedsAllModelMaxFanTemperatureSpecifications_:
		"Exceeds All Model Max Fan Temperature Specifications!",
	exceedsDryBulbTemperature_: "Exceeds dry bulb temperature!",
	exceedsFluid_sRatedMaximumTemperature_:
		"Exceeds fluid's rated maximum temperature!",
	excelCSVTextFiles: "Excel CSV Text Files",
	excelFile_AllListedParts_: "Excel file - All Listed Parts.",
	excelFile_SelectedPartsOnly_: "Excel file - Selected Parts Only.",
	excludeDraftOrders: "Exclude Draft Orders",
	excludedArea: "Excluded Area",
	exit: "Exit",
	exitNROES: "Exit NROES",
	exitOrderManager: "Exit Order Manager",
	expectedCarrier: "Expected Carrier",
	expires: "Expires",
	export: "Export",
	exportOrderFile: "Export Order File",
	exportServicePartInformation: "Export service part information",
	extTemp: "Ext Temp",
	extension: "Extension",
	exteriorRH: "Exterior RH",
	exteriorTemperature: "Exterior Temperature",
	exteriorBoundaryLayer: "Exterior boundary layer",
	externalRH: "External RH",
	externalTemperature: "External Temperature",
	fANLENGTH: "FAN LENGTH",
	fANMOTOR: "FAN MOTOR",
	fANWIDTH: "FAN WIDTH",
	fANS: "FANS",
	fAX: "FAX",
	fAXNumbers: "FAX Numbers",
	fIN: "FIN",
	fINSPACING: "FIN SPACING",
	fINISH: "FINISH",
	fLA_FAN: "FLA/FAN",
	fLUIDCOOLER: "FLUID COOLER",
	fORKLIFTS: "FORKLIFTS",
	fPI: "FPI",
	faceArea: "Face Area",
	factoryInv_To: "Factory Inv. To",
	factoryJob: "Factory Job",
	factoryJob_: "Factory Job #",
	factoryOrder: "Factory Order",
	factorySettings: "Factory Settings",
	factoryStyle: "Factory Style",
	factoryCalendarNotFound_: "Factory calendar not found!",
	factoryOrderNotYetCreated_: "Factory order not yet created.",
	failedToDownloadRequiredDataFiles_: "Failed to download required data files!",
	familyMinimumOrderPriceNotReached_: "Family minimum order price not reached.",
	fanConfiguration: "Fan Configuration",
	fanCount: "Fan Count",
	fans: "Fans",
	fansLong: "Fans Long",
	fansWide: "Fans Wide",
	fansL: "FansL",
	fansW: "FansW",
	faxNo: "Fax No",
	feeds: "Feeds",
	feedsAvailable: "Feeds Available",
	file: "File",
	fileError_: "File error.",
	fileIsNotAValidOrderFile_: "File is not a valid order file!",
	fileRevisionNoLongerSupportByNROES_:
		"File revision no longer support by NROES.",
	fileStructureNotRecognized_: "File structure not recognized.",
	fileWasCreatedWithANewerVersionOfNROES_:
		"File was created with a newer version of NROES.",
	filesDownloaded: "Files downloaded",
	filter: "Filter",
	finBlockDimensions: "Fin Block Dimensions",
	finConstruction: "Fin Construction",
	finStyle: "Fin Style",
	final: "Final",
	finalTemperature: "Final Temperature",
	findCustomer: "Find Customer",
	finish: "Finish",
	finsPerInch: "Fins Per Inch",
	fitAModel: "Fit A Model",
	fitAnApplication: "Fit An Application",
	flat: "Flat",
	floor: "Floor",
	floorArea: "Floor Area",
	floorPrice: "Floor Price",
	floorPlanIsNotCompleted_: "Floor plan is not completed!",
	fluid: "Fluid",
	fluidConcentration: "Fluid Concentration",
	fluidConcentrationNotSelected_: "Fluid Concentration Not Selected!",
	fluidCooler: "Fluid Cooler",
	fluidCoolerDesignTools: "Fluid Cooler Design Tools",
	fluidCoolerDesigner: "Fluid Cooler Designer",
	fluidCoolerDesigner_FitAJob: "Fluid Cooler Designer: Fit A Job",
	fluidCoolerDesigner_FitAModel: "Fluid Cooler Designer: Fit A Model",
	fluidCoolerModelSelector: "Fluid Cooler Model Selector",
	fluidFlowRate: "Fluid Flow Rate",
	fluidFlowRateIsNotSet_: "Fluid Flow Rate Is Not Set!",
	fluidFlowRatePerUnit: "Fluid Flow Rate Per Unit",
	fluidFoulingFactor: "Fluid Fouling Factor",
	fluidInletTemperature: "Fluid Inlet Temperature",
	fluidInletTemperatureIsNotSet_: "Fluid Inlet Temperature Is Not Set!",
	fluidInletTemperatureMustBeSet_: "Fluid Inlet temperature must be set!",
	fluidInletTemperatureNotCalculated_PleaseEnterOtherThreeValues_:
		"Fluid Inlet temperature not calculated! Please enter other three values.",
	fluidNotSelected_: "Fluid Not Selected!",
	fluidOutTemperature_AirTemp____C: "Fluid Out Temperature < Air Temp + 0.2C",
	fluidOutTemperatureGreaterThanFluidMaximumTemperature__C_:
		"Fluid Out Temperature Greater Than Fluid Maximum Temperature - 2C!",
	fluidOutTemperatureLessThanFluidMinimumTemperature__C_:
		"Fluid Out Temperature Less Than Fluid Minimum Temperature + 2C!",
	fluidOutletTemperature: "Fluid Outlet Temperature",
	fluidOutletTemperatureIsNotSet_: "Fluid Outlet Temperature Is Not Set!",
	fluidPressureDrop: "Fluid Pressure Drop",
	fluidTemperatureRange: "Fluid Temperature Range",
	fluidType: "Fluid Type",
	fluidVelocity: "Fluid Velocity",
	fluidVelocityAboveRecommendedNominalRange_:
		"Fluid Velocity Above Recommended Nominal Range!",
	fluidVelocityBelowRecommendedNominalRange_:
		"Fluid Velocity Below Recommended Nominal Range!",
	fluidVelocityTooHigh_: "Fluid Velocity Too High!",
	fluidVelocityTooLow_: "Fluid Velocity Too Low!",
	followTheInstructionsInTheEmailToCompleteYourNewUserRegistration_:
		"Follow the instructions in the email to complete your new user registration.",
	forceUpdateWaiting: "ForceUpdateWaiting",
	forkliftLoad: "Forklift Load",
	form_App_Menu: "Form_App_Menu",
	forms: "Forms",
	formsMethod: "Forms Method",
	fouling: "Fouling",
	foulingFactor: "Fouling Factor",
	freeShippingIsAvailableOnThisOrder_SetTermsToPre_Paid_:
		"Free shipping is available on this order !  Set terms to Pre-Paid.",
	freezeTemperature: "Freeze Temperature",
	freightTermsFOBBrantfordOntarioUnlessOtherwiseSpecified_:
		"Freight terms FOB Brantford Ontario unless otherwise specified.",
	french: "French",
	from: "From",
	front: "Front",
	frontCut: "Front Cut",
	frontInset: "Front Inset",
	fuildFlow: "Fuild Flow",
	fuildVolume: "Fuild Volume",
	fullName: "Full Name",
	functionalityWillBeLimited_: "Functionality will be limited.",
	fx_: "Fx: ",
	gLYCOLCOIL: "GLYCOL COIL",
	general: "General",
	german: "German",
	glycoilCoil_FitAJob: "Glycoil Coil: Fit A Job",
	glycoilCoil_FitAModel: "Glycoil Coil: Fit A Model",
	glycolCoil: "Glycol Coil",
	glycolCoilDesignTools: "Glycol Coil Design Tools",
	glycolCoilDesigner: "Glycol Coil Designer",
	glycolCoilModelSelector: "Glycol Coil Model Selector",
	greek: "Greek",
	guest: "Guest",
	hEATERS: "HEATERS",
	hELLO_WORLD_: "HELLO ~ WORLD ~",
	hOCFactorNotAvailable_: "HOC factor not available.",
	hOLDING: "HOLDING",
	heatOfRespiration: "Heat of Respiration",
	heavyUse: "Heavy Use",
	heavyWork_: "Heavy Work.",
	height: "Height",
	heightIsARequiredValue_: "Height is a required value !",
	held: "Held",
	help: "Help",
	holdChangeOrder: "Hold Change Order",
	holdOrder: "Hold Order",
	holding: "Holding",
	holdingAnOrderWillSignalTheOriginatorThatTheyNeedToCorrectSomething_:
		"Holding an order will signal the originator that they need to correct something!",
	horizontal: "Horizontal",
	howDoYouWantToHandleThis_: "How do you want to handle this?",
	howDoYouWantToResolveThisIssue_: "How do you want to resolve this issue!",
	howDoYouWantToStartTheNewQuote_: "How do you want to start the new quote.",
	howeverYouMayLooseChangesIfTheOrderIsBeingEditedByAnotherUser_:
		"However you may loose changes if the order is being edited by another user!",
	hz: "Hz",
	iACCEPTTHESETERMS: "I ACCEPT THESE TERMS",
	iDONOTACCEPTTHESETERMS: "I DO NOT ACCEPT THESE TERMS",
	iForget: "I forget",
	iHaveConfirmedItIsOkToUploadThisOrder_:
		"I have confirmed it is ok to upload this order.",
	iD: "ID",
	iMPORTORDERFILE: "IMPORT ORDER FILE",
	iNREVIEW: "IN REVIEW",
	iNFORMATIONISGIVENGRATISANDTHEMANUFACTURERASSUMESNOOBLIGTIONORLIABILITYFORACTUALRESULTSOBTAINED_:
		"INFORMATION IS GIVEN GRATIS AND THE MANUFACTURER ASSUMES NO OBLIGTION OR LIABILITY FOR ACTUAL RESULTS OBTAINED.",
	iNIFileMissingOrInvalid_: "INI file missing or invalid!",
	iNVALIDREFRIGERANT: "INVALID REFRIGERANT",
	iNVOICETO: "INVOICE TO",
	iTEM: "ITEM",
	iTEMCHANGES: "ITEM CHANGES",
	iTM: "ITM",
	identityVerificationChallenge_E_g_FavouriteHockeyTeam:
		"Identity Verification Challenge. e.g. Favourite Hockey Team",
	ifTheLoadIsNotEnteredManually_TheDesignMustIncludeAFloor_ACeiling_AndAtLeastFourWalls__:
		"If the load is not entered manually, the design must include a floor, a ceiling, and at least four walls.!",
	ifThisProblemPersistsPleaseContactTheFactory_:
		"If this problem persists please contact the factory.",
	ifYouCanReplicateTheError_PleaseProvideDetailedStepByStepInstructions_:
		"If you can replicate the error, please provide detailed step by step instructions.",
	ifYouChoseNoYouWillNotBeAbleToRecoverTheseOrdersLater_:
		"If you chose no you will not be able to recover these orders later.",
	ifYouContinue_YourOutstandingChangeRequestWillBeStopped_:
		"If you continue, your outstanding change request will be stopped.",
	ifYouContinue_YourOutstandingOrderWillBeStopped_:
		"If you continue, your outstanding order will be stopped.",
	ifYouContinue_YourOutstandingRequestWillBeStopped_:
		"If you continue, your outstanding request will be stopped.",
	ifYouHaveIssuesWithTheTranslations_AndWouldLikeToHelp_PleaseClickOnTheButtonBelow_:
		"If you have issues with the translations, and would like to help, please click on the button below.",
	ifYouHaveVerifiedThatItIsOkToUploadThisOrder_YouMayStillDoSo_:
		"If you have verified that it is ok to upload this order, you may still do so.",
	ifYouThinkTheNetworkIsAvailablePleaseContinueWaiting_:
		"If you think the network is available please continue waiting.",
	ifYouWantToSaveYourChangesAsANewQuoteRevision_DoTheFollowing_:
		"If you want to save your changes as a new quote revision, do the following.",
	ignore: "Ignore",
	ignoreForNow_: "Ignore for now!",
	imageFiles: "Image Files",
	imperial: "Imperial",
	importExistingCondenserJobFile: "Import Existing Condenser Job File",
	importJobFile: "Import Job File",
	importLoadCalcParameters: "Import Load Calc Parameters",
	importLoadCalculatorFile: "Import Load Calculator File",
	importLoadCalculatorModels: "Import Load Calculator Models",
	importOrder: "Import Order",
	importOrderFile: "Import Order File",
	includeAllCompanyOrders: "Include All Company Orders",
	includeAllOfTheAbove: "Include All Of The Above",
	includeAllOrders: "Include All Orders",
	includeClosedOrders: "Include Closed Orders",
	includeDeletedOrders: "Include Deleted Orders",
	includeHeldOrders: "Include Held Orders",
	includeOrdersClosedWithinThePast__Days:
		"Include Orders Closed Within The Past 60 Days",
	includeOrdersRejectedWithinThePast__Days:
		"Include Orders Rejected Within The Past 60 Days",
	includeOrdersShippedWithinThePast__Days:
		"Include Orders Shipped Within The Past 60 Days",
	includeProcessedOrders: "Include Processed Orders",
	incompatibleServerVersion_: "Incompatible server version!",
	indicatesOptionIsShippedLoose: "Indicates Option Is Shipped Loose",
	indicatesRevisionSpecificStatus: "Indicates Revision Specific Status",
	indicatesRequiredEntries: "Indicates required entries",
	indoors: "Indoors",
	infiltratingAirRH: "Infiltrating Air RH",
	infiltration: "Infiltration",
	infiltrationLoad: "Infiltration Load",
	infiltrationLoadIsNotCalculatedOrEntered_:
		"Infiltration load is not calculated or entered !",
	infiltrationLoadIsRarelyZero_: "Infiltration load is rarely zero !",
	informationIncomplete_: "Information incomplete.",
	informationRetreivalFailed_: "Information retreival failed.",
	initializing: "Initializing",
	insertLayer: "Insert Layer",
	insulation: "Insulation",
	insulation_Construction: "Insulation / Construction",
	insulationName: "Insulation Name",
	interiorBoundaryLayer: "Interior boundary layer",
	internalRH: "Internal RH",
	internalTemperature: "Internal Temperature",
	internalVolume: "Internal Volume",
	invalidPassword_: "Invalid Password!",
	invalidChecksum_: "Invalid checksum.",
	invalidDateEntered_: "Invalid date entered!",
	invalidE_mailAddress_: "Invalid e-mail address.",
	invalidFileHeader_: "Invalid file header.",
	invalidFileLength_: "Invalid file length.",
	invalidHeaderLength_: "Invalid header length.",
	invalidPromiseDate_: "Invalid promise date.",
	invalidQuantityEntered_: "Invalid quantity entered!",
	invoice: "Invoice",
	italian: "Italian",
	item_: "Item #",
	itemAdjustmentDetails: "Item Adjustment Details",
	itemDescription: "Item Description",
	itemListPrice: "Item List Price",
	itemMultiplier: "Item Multiplier",
	itemName: "Item Name",
	itemNet: "Item Net",
	itemNo: "Item No",
	itemPrice: "Item Price",
	itemQuantity: "Item Quantity",
	itemRequestedDate: "Item Requested Date",
	itemTotal: "Item Total",
	itemTotalAfterAdjustments: "Item Total After Adjustments",
	itemWeight: "Item Weight",
	itemsInBOLDAreRequired: "Items in BOLD are required",
	itemsOnTheClipboard_: "Items on the clipboard:",
	itemsOnThisSystemHaveErrors_: "Items on this system have errors !",
	itemsOnThisSystemHaveWarnings_: "Items on this system have warnings !",
	itm_OptNotes: "Itm/Opt Notes",
	itm_OptTag: "Itm/Opt Tag",
	job_SiteDetails: "Job / Site Details",
	job_SiteDetails_Preferences: "Job / Site Details & Preferences",
	jobMayBeUploaded_: "Job may be uploaded!",
	jobNotBeUploaded_: "Job not be uploaded!",
	job_ScopeREF_: "Job-Scope REF #",
	job_ScopeReferenceNotReturned_: "Job-Scope reference not returned!",
	job_ScopeReturnFileNotFound_: "Job-Scope return file not found!",
	kFactor: "K Factor",
	k_Factor: "K-Factor",
	keepRiteLoadCalcFiles: "KeepRite Load Calc Files",
	keepRiteRefrigeration: "KeepRite Refrigeration",
	keepriteRefrigeration: "Keeprite Refrigeration",
	l_SHAPED: "L - SHAPED",
	lIGHTING: "LIGHTING",
	lIQUID: "LIQUID",
	lISTPRICE: "LIST PRICE",
	lOAD: "LOAD",
	lOADCONNECTIONS: "LOAD CONNECTIONS",
	lOADINGCATALOGUE: "LOADING CATALOGUE",
	lOC: "LOC",
	lOCATION: "LOCATION",
	lOCKED: "LOCKED",
	lRA: "LRA",
	label: "Label",
	label__Text: "Label1.Text",
	language: "Language",
	largeToolbarIcons: "Large Toolbar Icons",
	layer: "Layer",
	leadTime: "Lead Time",
	leadTimeIsDependentOnPartAvailabilityAndWillBeConfirmedAtTimeOfOrder_:
		"Lead Time Is Dependent On Part Availability and Will Be Confirmed At Time Of Order.",
	leaveQuoteDateAsIs_: "Leave quote date as is.",
	leftCut: "Left Cut",
	leftEnd: "Left End",
	leftInset: "Left Inset",
	length: "Length",
	length_IsARequiredValue_: "Length 1 is a required value !",
	less: "Less",
	lightWork_: "Light Work.",
	lightingLoad: "Lighting Load",
	lineFrequency: "Line Frequency",
	lineItem: "Line Item",
	liquidOut: "Liquid Out",
	listPrice: "List Price",
	listPricesAreNotCurrentAndTheQuoteIsOlderThan__Days_:
		"List prices are not current and the quote is older than 30 days!",
	listPricesAreNotCurrent_: "List prices are not current!",
	load: "Load",
	loadCalculator: "Load Calculator",
	loadCalculatorDesignTool: "Load Calculator Design Tool",
	loadDifferentFile: "Load Different File",
	loadFactor: "Load Factor",
	loadingPriceBook: "Loading Price Book",
	localVersionUploadTimestamp_: "Local Version upload timestamp:",
	localOrdersQueried_: "Local orders queried!",
	localVersionModificationTimestamp_: "Local version modification timestamp:",
	location: "Location",
	locations: "Locations",
	longStorage: "Long Storage",
	lookingForUserProfile_: "Looking for user profile.",
	m_R_P: "M.R.P",
	mANUALOPTIONS: "MANUAL OPTIONS",
	mAXSPARES: "MAX SPARES",
	mAY: "MAY",
	mCA_MOPAreForTheCondensingUnitONLY_SinglePointConnectionsWILLShowDifferentOnDataplate_:
		"MCA & MOP are for the condensing unit ONLY. Single point connections WILL show different on dataplate.",
	mCA_: "MCA†",
	mESSAGENOTFOUND: "MESSAGE NOT FOUND",
	mODELFEATURES: "MODEL FEATURES",
	mODELINFORMATION: "MODEL INFORMATION",
	mODELOPTIONS: "MODEL OPTIONS",
	mODELTYPE: "MODEL TYPE",
	mODIFIED: "MODIFIED",
	mOP_: "MOP‡",
	mOREINFO: "MOREINFO",
	mULT: "MULT",
	mULTI_CIRCUIT: "MULTI-CIRCUIT",
	manufacturer: "Manufacturer",
	manufacturing: "Manufacturing",
	margin: "Margin",
	markAllAsRead: "Mark All As Read",
	markAsShipped: "Mark As Shipped",
	markRequestAsProcessed: "Mark Request As Processed",
	markUp: "Mark Up",
	markAsClosed: "Mark as Closed",
	markAsRead: "Mark as Read",
	masterCatalogNotLoaded: "Master Catalog Not Loaded",
	match: "Match",
	matched: "Matched",
	matches: "Matches",
	matchingModels: "Matching Models",
	material: "Material",
	materialName: "Material Name",
	materialLoadIsNotCalculatedOrEntered_:
		"Material load is not calculated or entered !",
	materialNameMustBeEntered_: "Material name must be entered !",
	materialTypeMustBeSelected_: "Material type must be selected !",
	maximum: "Maximum",
	maximumFluidFlowRateExceeded_: "Maximum Fluid Flow Rate Exceeded!",
	maximumOvercurrentProtection: "Maximum Overcurrent Protection",
	measurementUnits: "Measurement Units",
	menuStrip: "MenuStrip",
	messages: "Messages",
	metric_SI_: "Metric (SI)",
	minimum: "Minimum",
	minimumCircuitAmpacity: "Minimum Circuit Ampacity",
	minimumValue_: "Minimum Value:",
	minimumOrderPriceNotReached_: "Minimum order price not reached !",
	minus: "Minus",
	miscelaneousRefrigerationEquipment: "Miscelaneous Refrigeration Equipment",
	miscellaneousLoad: "Miscellaneous Load",
	miscellaneousLoads: "Miscellaneous Loads",
	miscellaneousSelected: "Miscellaneous Selected",
	missingPriceBook: "Missing Price Book",
	model: "Model",
	model_: "Model #",
	modelCapacity: "Model Capacity",
	modelConfiguration: "Model Configuration",
	modelFeatures: "Model Features",
	modelMatchingCriterea: "Model Matching Criterea",
	modelNumber: "Model Number",
	modelOptions: "Model Options",
	modelPartNumber: "Model Part Number",
	modelSelector: "Model Selector",
	modelIsNoLongerAvailable_: "Model is no longer available!",
	modelIsNoLongerListedInOurInventory_:
		"Model is no longer listed in our inventory!",
	modelMatch: "Model match",
	modelMultiplierChanged_: "Model multiplier changed.",
	modelsAvailable_: "Models Available:",
	modifyStatus: "Modify Status",
	moistureContent: "Moisture Content",
	motor: "Motor",
	motorLoad: "Motor Load",
	motorType: "Motor Type",
	moveConnectionLeft: "Move Connection Left",
	moveConnectionRight: "Move Connection Right",
	moveDown: "Move Down",
	moveFirst: "Move First",
	moveLast: "Move Last",
	moveLeft: "Move Left",
	moveRight: "Move Right",
	moveToSide: "Move To Side",
	moveUp: "Move Up",
	multiplier: "Multiplier",
	multiplierHasBeenResetToDefaultForThisModelType_:
		"Multiplier has been reset to default for this model type.",
	multiplierIsLessThanDefaultValue_: "Multiplier is less than default value: ",
	multiplierIsLessThanMinimumValue_: "Multiplier is less than minimum value:",
	multipliersUpdated_: "Multipliers Updated.",
	mustBeGreaterThanAirInletTemperature_DB__:
		"Must be greater than air inlet temperature (DB)!",
	mustBeGreaterThanFluidInletTemperature_:
		"Must be greater than fluid inlet temperature!",
	mustBeGreaterThanOrEqualToZero_: "Must be greater than or equal to zero!",
	mustBeGreaterThenOrEqualToMinimumValue_:
		"Must be greater then or equal to minimum value!",
	mustBeLessThanAirInletTemperature_DB__:
		"Must be less than air inlet temperature (DB)!",
	mustBeLessThanFluidInletTemperature_:
		"Must be less than fluid inlet temperature!",
	mustBeLessThenOrEqualTo_____: "Must be less then or equal to 100%!",
	mustBeLessThenOrEqualToMaximumValue_:
		"Must be less then or equal to maximum value!",
	myInfo: "My Info",
	myProfile: "My Profile",
	nToN: "N to N",
	n_A_: "N.A.",
	n_A: "N/A",
	nEW: "NEW",
	nEWCOMPOSITE: "NEW COMPOSITE",
	nOPARTSFOUND_: "NO PARTS FOUND!",
	nOParametersChanged_: "NO parameters changed.",
	nONE: "NONE",
	nOTORDERED: "NOT ORDERED",
	nOTORDEREDQUOTE: "NOT ORDERED QUOTE",
	nOTPRINTED_SET: "NOT PRINTED / SET",
	nOTQUOTED: "NOT QUOTED",
	nOTSAVED: "NOT SAVED",
	nOTSUBMITTEDORDER: "NOT SUBMITTED ORDER",
	nOTE: "NOTE",
	nR_Shell: "NR-Shell",
	nRDOCS_Splash: "NRDOCS_Splash",
	nROES: "NROES",
	nROES_NotesEditor: "NROES - Notes Editor",
	nROESContact: "NROES Contact",
	nROESSERVER: "NROES SERVER",
	nROESServerInterface: "NROES Server Interface",
	nROESServerIsNotAvailable_: "NROES Server is not available.",
	nROESIsHavingIssuesCommunicatingWithTheServer_:
		"NROES is having issues communicating with the server!",
	nROES__: "NROES-8",
	nR_DOCS_BROWSER: "NR_DOCS_BROWSER",
	name: "Name",
	nationalRefrigeration: "National Refrigeration",
	nationalRefrigerationAndAirConditioning:
		"National Refrigeration And Air Conditioning",
	nationalRefrigerationDesignTools: "National Refrigeration Design Tools",
	nationalRefrigerationOrderEntrySystem_Bally:
		"National Refrigeration Order Entry System - Bally",
	nationalRefrigerationOrderEntrySystem_KeepRite:
		"National Refrigeration Order Entry System - KeepRite ",
	nationalRefrigerationOrderEntrySystem_Trenton:
		"National Refrigeration Order Entry System - Trenton ",
	nationalRefrigerationOrderFiles_: "National Refrigeration Order Files.",
	negativeTemperatureDifference_: "Negative temperature difference!",
	neitherTheKFactorNorTheR_ValueHasBeenEntered_:
		"Neither the K Factor nor the R-Value has been entered !",
	neitherTheK_FactorNorTheR_ValueAreEnteredForTheMaterialMaterial_:
		"Neither the K-Factor nor the R-Value are entered for the Material material !",
	neitherTheK_FactorNorTheR_ValueAreEnteredForTheDividerMaterial_:
		"Neither the K-Factor nor the R-Value are entered for the divider material !",
	neitherTheK_FactorNorTheR_ValueHaveBeenSet_:
		"Neither the K-Factor nor the R-Value have been set !",
	netPrice: "Net Price",
	new: "New",
	newJob_KnownLoads: "New Job - Known Loads",
	newJob_KnownModel: "New Job - Known Model",
	newMargin: "New Margin",
	newMultipler: "New Multipler",
	newOrder: "New Order",
	newOrderPrice: "New Order Price",
	newPassword: "New Password",
	newPrice: "New Price",
	newQuotePrice: "New Quote  Price",
	newSystem_KnownLoads: "New System - Known Loads",
	newSystem_KnownModel: "New System - Known Model",
	newUser: "New User",
	newNetPrice_: "New net price:",
	newPasswordMustBeAtLeastSixCharactersInLength_:
		"New password must be at least six characters in length!",
	newPasswordsDoNotMatch_: "New passwords do not match!",
	newServicePartsAddedToOrder_: "New service parts added to order:",
	newUserNameRegistered_: "New user name registered!",
	next: "Next",
	nextModel_: "Next Model >",
	nextWallConstruction: "Next Wall Construction",
	no: "No",
	noCountryEntered_: "No Country entered !",
	noDetails: "No Details",
	noItemsOrSystemsSelected_: "No Items or Systems Selected.",
	noModelSelected: "No Model Selected",
	noModelSelected_: "No Model Selected!",
	noModelsAvailable: "No Models Available",
	noPartNameEntered_: "No Part Name Entered !",
	noAddressEntered_: "No address entered !",
	noAlternativePriceBooksOfThisTypeAreAvailable_:
		"No alternative price books of this type are available.",
	noAmbientTempEntered_: "No ambient temp entered.",
	noApplicationRegistrationOnformationWasFoundForYourUserID_:
		"No application registration onformation was found for your user ID.",
	noBoxTempEntered_: "No box temp entered.",
	noCalculationMethodSelected_: "No calculation method selected !",
	noChangesDetectedOnTheOrder_: "No changes detected on the order.",
	noCityEntered_: "No city entered !",
	noCondenserModelSelected_: "No condenser model selected !",
	noCondensingTempEntered_: "No condensing temp entered.",
	noConnectionsDefined_: "No connections defined !",
	noConstructionLayersHaveBeenDefined_:
		"No construction layers have been defined !",
	noContactNameGiven_: "No contact name given !",
	noContactsListed_: "No contacts listed.",
	noDeratingFactorListed_: "No derating factor listed!",
	noDescriptionEntered_: "No description entered !",
	noDoorsEstimateCalculatedOrEntered_:
		"No doors estimate calculated or entered !",
	noDoorsHaveBeenDefined_: "No doors have been defined !",
	noDoorsHaveBeenDefined_DefaultDoorLoadValueUsedForOneManDoor_:
		"No doors have been defined ! Default door load value used for one man door.",
	noEquipmentChangesFound_: "No equipment changes found.",
	noEquipmentChangesOccurred_: "No equipment changes occurred.",
	noEvaporatorMotorLoadHasBeenCalculatedOrEntered_:
		"No evaporator motor load has been calculated or entered !",
	noFeedsAssigned_: "No feeds assigned !",
	noFluidSelected_: "No fluid selected!",
	noFutureNonWorkingDaysWereListed_: "No future non working days were listed.",
	noInternetConnection_: "No internet connection.",
	noItemDeliveryDatesHaveBeenSelected_:
		"No item delivery dates have been selected!",
	noLightingLoadHasBeenCalculatedOrEntered_:
		"No lighting load has been calculated or entered !",
	noLoadHasBeenCalculatedOrEntered_: "No load has been calculated or entered !",
	noModelVARatingValuesListed_: "No model VA rating values listed.",
	noModelRatingValuesListed_: "No model rating values listed.",
	noModelsAvailableForThisHighAFlowRate_:
		"No models available for this high a flow rate!",
	noOrdersListed_: "No orders listed!",
	noPartsSelected_: "No parts selected!",
	noPhoneNumberEntered_: "No phone number entered !",
	noPowerValueEntered_: "No power value entered !",
	noPriceBooksHaveBeenAssignedToYourProfile_:
		"No price books have been assigned to your profile.",
	noPriceChangesOccurred_: "No price changes occurred.",
	noPricesWereChanged_: "No prices were changed.",
	noPricingChangesOccurred_: "No pricing changes occurred.",
	noQuantityEntered_: "No quantity entered !",
	noRefrigerantSelected_: "No refrigerant selected!",
	noRevisionsWereUpdated_: "No revisions were updated.",
	noShippingAndHandlingCostEntered_: "No shipping and handling cost entered !",
	noState_ProvinceOrCountyEntered_: "No state, province or county entered !",
	noSuctionTempEntered_: "No suction temp entered.",
	noSuitableFeedConfigurationFound_: "No suitable feed configuration found.",
	noTotalLoadHasBeenCalculatedOrEntered_:
		"No total load has been calculated or entered !",
	noTotalProductLoadHasBeenCalculatedOrEntered_:
		"No total product load has been calculated or entered !",
	noTotalProductWeightHasBeenCalculatedOrEntered_:
		"No total product weight has been calculated or entered !",
	noUsageEstimateCalculatedOrEntered_:
		"No usage estimate calculated or entered !",
	noUsageLevelSelected_: "No usage level selected !",
	noVentilationEstimateCalculatedOrEntered_:
		"No ventilation estimate calculated or entered !",
	noVersion_OrdersFoundInThisList_: "No version 8 Orders found in this list!",
	noZipOrPostalCodeEntered_: "No zip or postal code entered !",
	no_CancelThisOperation: "No, Cancel this operation",
	no_IAmANewUser_: "No, I am a new user.",
	no_I_llDoItManually_: "No, I'll do it manually.",
	no_TryToConnectAgain_: "No, Try to connect again.",
	no_LeaveTheExistingOptionMultipliersUnchanged_:
		"No, leave the existing option multipliers unchanged.",
	no_RestorePreviousValue_: "No, restore previous value.",
	non_CatalogItemDetails: "Non-Catalog Item Details",
	non_CatalogOptionDetails: "Non-Catalog Option Details",
	non_numericJob_ScopeReferenceReturned_:
		"Non-numeric Job-Scope reference returned!",
	none: "None",
	notOwner: "Not Owner",
	notFound_: "Not found!",
	notLatestCO: "Not latest CO",
	note_AnInternetConnectionIsRequiredToCompleteThisProcedure:
		"Note : An internet connection is required to complete this procedure",
	note_: "Note:",
	note_IfYouAnswerNO_NewProductsAddedToThisOrderMayNotUseTheCorrectMultiplier_:
		"Note: If you answer NO, new products added to this order may not use the correct multiplier?",
	note_IndoorValueOnly__TheExteriorBoundaryLayerIsAdjusted_AutomaticallyIfSectionIsMarkedAsOutdoors:
		"Note: Indoor value only.  ~ The exterior boundary layer is adjusted  ~ automatically if section is marked as outdoors",
	note_OrderOriginatorsAndOrderDeskWillAutomaticallyBeIncludedInTheNotifyList:
		"Note: Order originators and order desk will automatically be included in the notify list",
	notes: "Notes",
	notes_Comments: "Notes / Comments",
	notes_Remarks: "Notes/Remarks",
	nothingToDo_: "Nothing to do.",
	numberOfUnits: "Number Of Units",
	numericValueRequired_: "Numeric value required.",
	o_ptions: "O&ptions",
	oE: "OE",
	oELIST: "OE LIST",
	oEList: "OE List",
	oEM: "OEM",
	oFFLINE: "OFFLINE",
	oK: "OK",
	oNHOLD: "ON HOLD",
	oPENACTIVEORDERMANAGER: "OPEN ACTIVE ORDER MANAGER",
	oPENEXISTINGORDER: "OPEN EXISTING ORDER",
	oPENPURCHAREORDERMANAGER: "OPEN PURCHARE ORDER MANAGER",
	oRDER: "ORDER",
	oRDERCONTAINS: "ORDER CONTAINS",
	oRDERCONTAINSCALLPRICING_: "ORDER CONTAINS CALL PRICING!",
	oRDERENTRYSYSTEM: "ORDER ENTRY SYSTEM",
	oRDERNO: "ORDER NO",
	oRDERNOTES: "ORDER NOTES",
	oRDERSTATUS: "ORDER STATUS",
	oRDERVERIFIED: "ORDER VERIFIED",
	oRDERED: "ORDERED",
	oTHER: "OTHER",
	oTHERLOADS: "OTHER LOADS",
	obsolete: "Obsolete",
	off_LineWarning_: "Off-Line Warning.",
	oldPassword: "Old Password",
	oldPasswordDoesNotMatch_: "Old password does not match!",
	oneEquipmentChangeFound_: "One equipment change found.",
	onePriceChanged_: "One price changed.",
	oneRevisionUpdated: "One revision updated",
	open: "Open",
	openBallyTools: "Open Bally Tools",
	openExistingJob: "Open Existing Job",
	openFromWeb: "Open From Web",
	openKeepRiteTools: "Open KeepRite Tools",
	openOrder: "Open Order",
	openOrder_ForceSynch: "Open Order - Force Synch",
	openTrentonTools: "Open Trenton Tools",
	openAsNewOrder: "Open as New Order",
	openFileInEXCEL_: "Open file in EXCEL?",
	openFileInTextViewer_: "Open file in text viewer?",
	openLastSavedEditOrder_: "Open last saved EditOrder.",
	openingType: "Opening Type",
	openingDeletionRequested_: "Opening deletion requested.",
	openingsSelected: "Openings Selected",
	oppositeEnds: "Opposite Ends",
	option: "Option",
	optionPrice: "Option Price",
	optionSelector: "Option Selector",
	optionIsNoLongerAvailable_: "Option is no longer available!",
	optionNeedsFactoryLeadTime_PleaseCall_:
		"Option needs factory lead time. Please call !",
	optionNeedsFactoryPricing_PleaseCall_:
		"Option needs factory pricing. Please call !",
	optional: "Optional",
	options: "Options",
	optionsOnThisItemHaveErrors_: "Options on this item have errors !",
	optionsOnThisItemHaveWarnings_: "Options on this item have warnings !",
	orEnterAModelNumber: "Or Enter A Model Number",
	order: "Order",
	orderDate: "Order Date",
	orderEntry: "Order Entry",
	orderEntryListPrice: "Order Entry List Price",
	orderEntryMultiplier: "Order Entry Multiplier",
	orderEntrySystem: "Order Entry System",
	orderForm: "Order Form",
	orderItemNo: "Order Item No",
	orderManager: "Order Manager",
	orderManager_COLLATING: "Order Manager - COLLATING",
	orderNumericUnits: "Order Numeric Units",
	orderPreview: "Order Preview",
	orderPreview_NoOrderSelected: "Order Preview - No Order Selected",
	orderPreview_NotAvailable: "Order Preview - Not Available",
	orderPrice: "Order Price",
	orderStatus: "Order Status",
	orderUploadConfirmationCheck: "Order Upload Confirmation Check",
	orderCanNotBeImported_: "Order can not be imported.",
	orderChangeStopRequested_: "Order change stop requested.",
	orderChangesHaveYetToBeUploadedToTheServer_:
		"Order changes have yet to be uploaded to the server.",
	orderImported_: "Order imported.",
	orderIncludesIncompleteFactoryPricingInformation_:
		"Order includes incomplete factory pricing information!",
	orderInformationAlreadyExistsInTheProgramOrderStore_:
		"Order information already exists in the program order store.",
	orderIsAlreadyLoadedLocally_: "Order is already loaded locally.",
	orderIsOpenForReviewAndPrintOnly_: "Order is open for review and print only!",
	orderPriceTooLowForPre_paidShippingTerms_:
		"Order price too low for pre-paid shipping terms! ",
	orderStopRequested_: "Order stop requested.",
	orderWasDeleted_: "Order was deleted.",
	orderWillBeUploadedAfterNextProgramUpdate_:
		"Order will be uploaded after next program update.",
	ordered: "Ordered",
	orders: "Orders",
	orientation: "Orientation",
	original: "Original",
	other: "Other",
	otherCountryName: "Other Country Name",
	otherEquipmentManufacturer_sModel: "Other Equipment Manufacturer's Model",
	otherHeatSources: "Other Heat Sources",
	otherName: "Other Name",
	otherDeletionRequested_: "Other deletion requested.",
	outOfRange_ShouldBe__And______: "Out of range, should be > 0 and < 0.01!",
	outdoors: "Outdoors",
	p_O: "P.O",
	p_O_: "P.O.",
	pARAMETERSREQUIRECHECKINGORADJUSTMENT:
		"PARAMETERS REQUIRE CHECKING OR ADJUSTMENT",
	pARSING: "PARSING",
	pARTNOTFOUNDINDATABASE_: "PART NOT FOUND IN DATABASE !",
	pARTIAL: "PARTIAL",
	pENDING: "PENDING",
	pERSONNEL: "PERSONNEL",
	pICK_UP: "PICK-UP",
	pO_: "PO #",
	pOWER: "POWER",
	pRD: "PRD",
	pRE_PAID: "PRE-PAID",
	pRICEBOOKNOTAVAILABLE: "PRICE BOOK NOT AVAILABLE",
	pRICEEACH: "PRICE EACH",
	pROCESSED: "PROCESSED",
	pRODUCTLINE: "PRODUCT LINE",
	pRODUCTNO: "PRODUCT NO",
	pROJECT: "PROJECT",
	pROTECTED: "PROTECTED",
	package: "Package",
	packageOptionsAreHighlightedInGreen_:
		"Package options are highlighted in green.",
	panel: "Panel",
	panelExteriorIsOutdoors: "Panel Exterior Is Outdoors",
	parametersCheckCompleted_: "Parameters check completed.",
	params: "Params",
	partDescription: "Part Description",
	partDescriptions: "Part Descriptions",
	partNo: "Part No",
	partDescriptionRequired_: "Part description required !",
	partialShipmentSelected_: "Partial shipment selected!",
	partialShippingRequested_ButNoSpecificShipDatesListedOnSystemsOrItems_:
		"Partial shipping requested, but no specific ship dates listed on systems or items!",
	parts: "Parts",
	password: "Password",
	passwordAgain: "Password Again",
	passwordRequired_: "Password required.",
	paste: "Paste",
	pasteOptions_: "Paste options.",
	pastingNonStockingModelsOnAStockingOrderIsProhibited_:
		"Pasting non stocking models on a stocking order is prohibited.",
	pastingStockingModelsOnAStandardOrderIsProhibited_:
		"Pasting stocking models on a standard order is prohibited.",
	perUnit: "Per Unit",
	percentageChangeCausedByEnteredAdjustmentValue_:
		"Percentage change caused by entered adjustment value.",
	percentageDifferenceBetweenCurrentPriceAndFloorPrice_:
		"Percentage difference between current price and floor price.",
	percentageDifferenceBetweenNewPriceAndCurrentPrice_:
		"Percentage difference between new price and current price.",
	percentageDifferenceBetweenNewPriceAndFloorPrice_:
		"Percentage difference between new price and floor price.",
	perimeterLength: "Perimeter Length",
	permanentOpening: "Permanent Opening",
	personalInformation: "Personal Information",
	personnelLoad: "Personnel Load",
	ph_: "Ph: ",
	phone: "Phone",
	phoneNo: "Phone No",
	phoneNumbers: "Phone Numbers",
	pick_Up: "Pick-Up",
	pleaseDescribe: "Please Describe",
	pleaseNote_: "Please Note:",
	pleaseSelect: "Please Select",
	pleaseSelectAModelType: "Please Select A Model Type",
	pleaseSelectObjectsToInclude: "Please Select Objects To Include",
	pleaseSelectRatingOptions: "Please Select Rating Options",
	pleaseAdjustTemperaturesOrChangeRefrigerant_:
		"Please adjust temperatures or change refrigerant.",
	pleaseAdjustTemperaturesOrSelectAndEnterT_H_R_Value_:
		"Please adjust temperatures or select and enter T.H.R. value.",
	pleaseCheckAllItems_: "Please check all items!",
	pleaseCheckInternetAndTryAgain_: "Please check internet and try again.",
	pleaseCheckServerConnectionAndTryAgain_:
		"Please check server connection and try again.",
	pleaseCheckThemIndividually_: "Please check them individually.",
	pleaseCheckYourPermissionsAndOrCloseThisApplicationAndRunTheBootstrapManually_:
		"Please check your permissions and or close this application and run the bootstrap manually.",
	pleaseCheckYourPermissionsAndOrDeleteThisFileManually_:
		"Please check your permissions and or delete this file manually.",
	pleaseCheckYourPermissionsAndOrMoveThisFileManually_:
		"Please check your permissions and or move this file manually.",
	pleaseCheckYourProfileAndUpdateTheSoldByAddressPanel_:
		"Please check your profile and update the sold by address panel.",
	pleaseClickOnTheNEWButtonToBeginAddingYourFirstContact_:
		"Please click on the NEW button to begin adding your first contact.",
	pleaseCloseAllInstancesOfNROESAndRestartTheApplication_:
		"Please close all instances of NROES and restart the application!",
	pleaseCloseAllRefrigerationAppsClickOnRetry_:
		"Please close all refrigeration apps click on retry!",
	pleaseCloseAllRefrigerationToolsAndRestartTheApplication_:
		"Please close all refrigeration tools and restart the application!",
	pleaseCloseAllToolsAndRestartTheApplication_:
		"Please close all tools and restart the application!",
	pleaseCompleteRequiredInformation_: "Please complete required information.",
	pleaseConfirmTheFollowing: "Please confirm the following",
	pleaseConfirm_: "Please confirm.",
	pleaseContact: "Please contact",
	pleaseContactSupportTeam_: "Please contact support team.",
	pleaseContactTheDevelopmentTeam_: "Please contact the development team.",
	pleaseContactTheFactoryToHaveYourProfileAdjusted_:
		"Please contact the factory to have your profile adjusted.",
	pleaseContactTheManufacturerForServicePartInformation_:
		"Please contact the manufacturer for service part information.",
	pleaseDescribeTheReasonForThisExceptionBelowAnd:
		"Please describe the reason for this exception below and",
	pleaseEnterJob_ScopeReference_: "Please enter Job-Scope reference!",
	pleaseEnterANumericValueGreaterZero_:
		"Please enter a numeric value greater zero.",
	pleaseEnterAllThePrimaryDimensionInformationFirst_:
		"Please enter all the primary dimension information first.",
	pleaseEnterCustomerReferenceNumber_:
		"Please enter customer reference number!",
	pleaseEnterExpectedShippingCarried_:
		"Please enter expected shipping carried!",
	pleaseEnterYourPersonalApplicationRegistrationCode:
		"Please enter your personal application registration code",
	pleaseInstallCustomerDatabaseFileIntoYourApplicationProgramDataFolder_:
		"Please install customer database file into your application program data folder!",
	pleaseQuoteFACTORYORDER__: "Please quote FACTORY ORDER #.",
	pleaseRestartTheApplication: "Please restart the application",
	pleaseRestartTheApplicationWithTheInternetConnected_:
		"Please restart the application with the internet connected.",
	pleaseSaveYourWorkAndCloseAllDesignWindows_:
		"Please save your work and close all design windows.",
	pleaseSelectACompressor_: "Please select a compressor !",
	pleaseSelectACountryFromTheList_: "Please select a country from the list!",
	pleaseSelectADivision: "Please select a division",
	pleaseSelectAMatchingModelNumberFirst_:
		"Please select a matching model number first.",
	pleaseSelectAPriceBook: "Please select a price book",
	pleaseSelectAnotherFile_: "Please select another file!",
	pleaseSelectOrderType_: "Please select order type!",
	pleaseSelectServicePartTypesToAddToOrder_:
		"Please select service part types to add to order.",
	pleaseSelectTheSameSystemModelType_:
		"Please select the same system model type .",
	pleaseSelectTheTypeOfSystemYouWantToAdd:
		"Please select the type of system you want to add",
	pleaseTryAgainLater_: "Please try again later!",
	pleaseTryAgain_: "Please try again.",
	pleaseUpdateYourPrices_: "Please update your prices!",
	pleaseUseTheOptionsSelectorToAdjustTheseOptions_:
		"Please use the options selector to adjust these options.",
	pleaseUseTheOrderManagerToOpenThisOrder_:
		"Please use the order manager to open this order.",
	pleaseWait_: "Please wait!",
	plus: "Plus",
	popup_Tooltip: "Popup_Tooltip",
	postal: "Postal",
	postal_ZIPCode: "Postal / ZIP Code",
	postalCode: "Postal Code",
	power: "Power",
	powerDensity: "Power Density",
	powerSearch: "Power Search",
	power_Deg: "Power/Deg",
	pr_St: "Pr/St",
	pre_view: "Pre&view",
	pre_Paid_Charge: "Pre-Paid & Charge",
	pre_paid: "Pre-paid",
	pressure: "Pressure",
	pressureDropExceedsRequestedRange_: "Pressure Drop Exceeds Requested Range!",
	pressureDropRange: "Pressure Drop Range",
	pressureDropTolerance: "Pressure Drop Tolerance",
	pressureDropTooLow_: "Pressure Drop Too Low!",
	pressureRange: "Pressure Range",
	preview: "Preview",
	previousNetPrice_: "Previous net price:",
	price: "Price",
	priceBook: "Price Book",
	priceChange: "Price Change",
	priceOK: "Price OK",
	priceBookUpdated_: "Price book Updated.",
	priceBookHasExpired_: "Price book has expired !",
	priceBookHasExpiredAndTheQuoteIsOlderThan__Days_:
		"Price book has expired and the quote is older than 30 days!",
	priceBookIsMissing_: "Price book is missing!",
	priceBookNotUpdated_: "Price book not updated.",
	priceDidNotChange_: "Price did not change!",
	pricebookHasExpired: "Pricebook has expired",
	pricebookHasExpired_: "Pricebook has expired !",
	pricebookMissing: "Pricebook missing",
	pricebookNotLoaded_: "Pricebook not loaded.",
	pricebookWillExpireIn: "Pricebook will expire in",
	pricingChangeOccurredOnOneRevision_:
		"Pricing change occurred on one revision.",
	pricingChangesOccurredOn: "Pricing changes occurred on",
	pricingShownIncludesAllOptionsListedOnCertifiedPrints_:
		"Pricing shown includes all options listed on certified prints.",
	primary: "Primary",
	print: "Print",
	printPreview: "Print Preview",
	printSelectedModelOnly: "Print Selected Model Only",
	printing: "Printing",
	processChangeOrderRequest: "Process Change Order Request",
	processOrder: "Process Order",
	processingNotes: "Processing Notes",
	product: "Product",
	productDetails: "Product Details",
	productLine: "Product Line",
	productList: "Product List",
	productLoad: "Product Load",
	productType: "Product Type",
	productDeletionRequested_: "Product deletion requested.",
	productsSelected: "Products Selected",
	profitRate: "Profit Rate",
	programUpdateAvailable_: "Program Update Available.",
	programUpdatesFound: "Program Updates Found",
	programUpdatesShouldBeAvailableShortly_:
		"Program updates should be available shortly.",
	project: "Project",
	promise: "Promise",
	promiseDate: "Promise Date",
	promised: "Promised",
	protectCurrentRevision: "Protect Current Revision",
	province: "Province",
	pulldownTime: "Pulldown Time",
	purchaseOrder: "Purchase Order",
	purchaseOrders: "Purchase Orders",
	purchaseRequests: "Purchase Requests",
	purchaser: "Purchaser",
	qTY: "QTY",
	qUOTE: "QUOTE",
	qUOTEDAYS: "QUOTE DAYS",
	qtty: "Qtty",
	qty: "Qty",
	quantity: "Quantity",
	quantityPerUnit: "Quantity Per Unit",
	quickBox: "Quick Box",
	quotationValidFor__DaysFromQuoteDate_:
		"Quotation valid for 30 days from quote date.",
	quote: "Quote",
	quote_: "Quote #",
	quoteDate: "Quote Date",
	quoteNo: "Quote No",
	quotePrice: "Quote Price",
	quoteSetup: "Quote Setup",
	quoteDateIsNotSetToCurrentDate_: "Quote date is not set to current date!",
	rValue: "R Value",
	r_Value: "R-Value",
	r____A_R____CombinationNoLongerAvailable_R____ASelected_:
		"R404-A/R-507 Combination no longer available, R-404A Selected.",
	rATING: "RATING",
	rEADONLY: "READ ONLY",
	rEADY: "READY",
	rECTANGULAR: "RECTANGULAR",
	rEF: "REF",
	rEFf: "REFf",
	rEQDate: "REQ Date",
	rEQSHIPDATE_: "REQ SHIP DATE :",
	rETRIEVENROESORDERFROMSERVER: "RETRIEVE NROES ORDER FROM SERVER",
	rLA: "RLA",
	rOUTING: "ROUTING",
	rUN: "RUN",
	rUNNUMBER: "RUN NUMBER",
	range: "Range",
	ratedAt: "Rated at",
	rating: "Rating",
	ratingOptions: "Rating Options",
	rear: "Rear",
	rearrangeItemOrder: "Rearrange Item Order",
	rearrangeItems: "Rearrange Items",
	rearrangeSystemOrder_RenameSystems: "Rearrange System Order / Rename Systems",
	rearrangeSystems: "Rearrange Systems",
	rebuildFactoryOrder: "Rebuild Factory Order",
	rebuildFactoryOrderRequested_: "Rebuild factory order requested.",
	recentOrders: "Recent Orders",
	recommendedQuantity: "Recommended Quantity",
	recoverMyEdits_: "Recover my edits.",
	rectangularBoxDimensions: "Rectangular Box Dimensions",
	redo: "Redo",
	reference: "Reference",
	refresh: "Refresh",
	refreshOrderList: "Refresh Order List",
	refrigerant: "Refrigerant",
	refrigerantFactor: "Refrigerant Factor",
	refrigerantFactorNotAvailable_: "Refrigerant factor not available.",
	refrigerantFactorOutOfRange_: "Refrigerant factor out of range.",
	refrigerantNotSelected_: "Refrigerant not selected !",
	refrigerants: "Refrigerants",
	refrigerationDesignTools: "Refrigeration Design Tools",
	refrigerationSystemDetails: "Refrigeration System Details",
	regBuild: "Reg Build",
	region: "Region",
	regularShipDate_: "Regular Ship Date : ",
	regularUse: "Regular Use",
	rejectOrder: "Reject Order",
	rejected: "Rejected",
	rejectingAnOrderWillPreventTheOriginatorFromResubmittingThisOrder_:
		"Rejecting an order will prevent the originator from resubmitting this order!",
	releaseNotes: "Release Notes",
	remarks_Notes: "Remarks / Notes",
	remarks_TermsAndConditions: "Remarks / Terms and Conditions",
	rememberMeOnThisComputer: "Remember Me On This Computer",
	remo_ve: "Remo&ve",
	remoteOrderLockNotEstablished_: "Remote order lock not established.",
	remoteVersionModificationTimestamp_: "Remote version modification timestamp:",
	remoteVersionUploadTimestamp_: "Remote version upload timestamp:",
	remove: "Remove",
	remove_LocalOrder: "Remove &Local Order",
	removeFeed: "Remove Feed",
	removeLastWall: "Remove Last Wall",
	removeLocalOrder: "Remove Local Order",
	removeModel_Item: "Remove Model / Item",
	removeSystem: "Remove System",
	renumberIDs: "Renumber IDs",
	replaceAllItems_: "Replace all items.",
	replaceLocalOrderWithMasterVersion_:
		"Replace local order with master version.",
	replaceSelectedItems_: "Replace selected items.",
	replaceSelectedSystem_: "Replace selected system.",
	replaceTheOrderInformationFromTheSelectedFile_:
		"Replace the order information from the selected file.",
	requested: "Requested",
	requestedChanges_PleaseUpdateJob_ScopeAndCheckOffEachItem:
		"Requested Changes - Please update Job-Scope and Check Off Each Item",
	requestedDate_: "Requested Date : ",
	requestedDateException: "Requested Date Exception",
	requestedItemShipDateIsLessThanItemLeadTime_:
		"Requested item ship date is less than item lead time!",
	requestedOrderShipDateIsLessThanLeadTime_:
		"Requested order ship date is less than lead time!",
	requestedSystemShipDateIsLessThanLeadTime_:
		"Requested system ship date is less than lead time!",
	required: "Required",
	requiredCapacity: "Required Capacity",
	requiredCapacity_Unit: "Required Capacity / Unit",
	requiredCapacityIsNotSet_: "Required Capacity Is Not Set!",
	requiredCapacityPerUnit: "Required Capacity Per Unit",
	requiredCapacityTolerance: "Required Capacity Tolerance",
	requiredOptions: "Required Options",
	requiredRatingOptions: "Required Rating Options",
	requiredShipDate: "Required Ship Date",
	requiredValue_: "Required value!",
	requirementDatesInThePast_: "Requirement dates in the past!",
	resetColumnWidths: "Reset Column Widths",
	resizeColumns: "Resize Columns",
	restoreQuoteRevisionRequested_: "Restore quote revision requested.",
	restoreTheOrderInformationFromTheOrderStore_:
		"Restore the order information from the order store.",
	resultingPriceIsLowerThanFloorPrice_:
		"Resulting price is lower than floor price!",
	retrieveOrder: "Retrieve Order",
	retry: "Retry",
	rev: "Rev",
	revision: "Revision",
	revisionContainsErrors_: "Revision contains errors!",
	revisionContainsWarnings_: "Revision contains warnings!",
	rightEnd: "Right End",
	rightClickOnTheQuoteTabsOnTheTopLeftOfTheOrderWindow_:
		"Right click on the Quote tabs on the top left of the order window.",
	rounding: "Rounding",
	runNumber: "Run Number",
	runTime: "Run Time",
	sAndH: "S and H",
	s_C_T_: "S.C.T.",
	s_S_T_: "S.S.T.",
	sCT: "SCT",
	sECTIONLIST: "SECTION LIST",
	sEEITEMS: "SEE ITEMS",
	sELECTEDMODELDETAILS: "SELECTED MODEL DETAILS",
	sERVERERROR_: "SERVER ERROR!",
	sERVICEPARTSCATALOG: "SERVICE PARTS CATALOG",
	sHIPTO: "SHIP TO",
	sHIPPEDLOOSE: "SHIPPED LOOSE",
	sIDE_: "SIDE 1",
	sITEVOLTAGES: "SITE VOLTAGES",
	sOLDBY: "SOLD BY",
	sOLDTO: "SOLD TO",
	sST: "SST",
	sTARTING: "STARTING",
	sTATE: "STATE",
	sTOPSUBMITTEDORDERORCHANGE: "STOP SUBMITTED ORDER OR CHANGE",
	sUBMITNOTES: "SUBMIT NOTES",
	sUBMITORDER: "SUBMIT ORDER",
	sUBMITTED: "SUBMITTED",
	sUBMITTEDC_O_: "SUBMITTED C.O.",
	sUITABLEREFRIGERANTS: "SUITABLE REFRIGERANTS",
	sYNCHRONIZING: "SYNCHRONIZING",
	sYS: "SYS",
	sYSTEM: "SYSTEM",
	sYSTEMBALANCE: "SYSTEM BALANCE",
	sYSTEMBULLETIN_: "SYSTEM BULLETIN!",
	sYSTEMREFRIGERANT: "SYSTEM REFRIGERANT",
	sales: "Sales",
	salesman: "Salesman",
	sameEnd: "Same End",
	saturatedCondensingTemperature: "Saturated Condensing Temperature",
	saturatedSuctionTemperature: "Saturated Suction Temperature",
	save: "Save",
	saveAllOrders: "Save All Orders",
	saveAsDefault: "Save As Default",
	saveAsDefaults: "Save As Defaults",
	saveAsSiteDefaults: "Save As Site Defaults",
	saveChanges: "Save Changes",
	saveOrder: "Save Order",
	saveOrderParameterChanges_: "Save Order Parameter changes?",
	saveChangedContactInformation_: "Save changed contact information.",
	saveFailed_: "Save failed!",
	saveMyChanges_: "Save my changes.",
	saveTheseChangesAsANewContact_: "Save these changes as a new contact.",
	search: "Search",
	searchServiceParts: "Search Service Parts",
	searchingForMatchingAirCooledCondensers_:
		"Searching For matching air cooled condensers.",
	searchingForMatchingCompressors_: "Searching For matching compressors.",
	searchingForMatchingCondensingUnits_:
		"Searching For matching condensing units.",
	searchingForMatchingEvaporatorUnits_:
		"Searching For matching evaporator units.",
	section: "Section",
	sectionConstruction: "Section Construction",
	sectionDivider: "Section Divider",
	sectionExteriorIsOutdoors: "Section Exterior Is Outdoors",
	sectionLoad: "Section Load",
	sectionType: "Section Type",
	sectionDeletionRequested_: "Section deletion requested.",
	sectionHasOpeningErrors_: "Section has opening errors !",
	sectionHasOpeningWarning_: "Section has opening warning !",
	sectionNameNotProvided_: "Section name not provided.",
	sectionTypeMustBeSelected_: "Section type must be selected !",
	sectional_E_g_StudWall_: "Sectional ( e.g. Stud wall)",
	sections: "Sections",
	sectionsSelected: "Sections Selected",
	selectAll: "Select All",
	selectApplicationLanguage: "Select Application Language",
	selectEquipment: "Select Equipment",
	selectModel: "Select Model",
	selectNextModel_: "Select Next Model >",
	selectOptions: "Select Options",
	selectRequestRevision: "Select Request Revision",
	selectRoomShape: "Select Room Shape",
	selectWhichPowerOfTenToRoundTo_: "Select which power of ten to round to.",
	selectedItemDetails: "Selected Item Details",
	selectedModelDetails: "Selected Model Details",
	selectedQuoteDetails: "Selected Quote Details",
	selectedConstructionNotFound_: "Selected construction not found !",
	selectedItemCountDiffersFromPasteBufferCount_:
		"Selected item count differs from paste buffer count!",
	selectedModelNotFoundInActivePriceBook_:
		"Selected model not found in active price book!",
	selectedPriceBookHasExpired_: "Selected price book has expired!",
	selectionError_: "Selection error!",
	sendNotification: "Send Notification",
	sendNotifications: "Send Notifications",
	sendItToMeAgain: "Send it to me again",
	sendNotificationsTo__SelectAllThatApply_:
		"Send notifications to: (Select all that apply)",
	separatePrice: "Separate Price",
	seperatePrice: "Seperate Price",
	serverConnected: "Server Connected",
	serverNotAvailable: "Server Not Available",
	serverCopyHasDifferentSaveHistory_: "Server copy has different save history.",
	serverOrdersQueried_: "Server orders queried!",
	serverVersionDiffersFromApplication_:
		"Server version differs from application!",
	serviceParts: "Service Parts",
	setDefaults: "Set Defaults",
	setStatus: "Set Status",
	setQuoteDateToToday_: "Set quote date to today.",
	shellApplicationFailedToAcceptCommand_:
		"Shell Application Failed To Accept Command!",
	shellApplicationFailedToStart_: "Shell Application Failed to start!",
	shellApplicationFilesAreMissing_: "Shell Application Files are missing!",
	shellTerminatedUnexpectedy_: "Shell Terminated Unexpectedy!",
	shellDidNotAcceptCommand_: "Shell did not accept command.",
	shellDidNotRespond_: "Shell did not respond.",
	shellIsLoading: "Shell is loading",
	shipOrdersFromFile: "Ship Orders From File",
	shipTo: "Ship To",
	shipToInformationIsIncomplete: "Ship to information is incomplete",
	shipToRegionMustBeTwoCharacterCode_:
		"Ship to region must be two character code!",
	shipment: "Shipment",
	shipped: "Shipped",
	shippedInformation: "Shipped Information",
	shippedLoose: "Shipped Loose",
	shippedLoose_LineItem_: "Shipped Loose ( Line Item )",
	shippingNotes: "Shipping Notes",
	shippingTerms: "Shipping Terms",
	shippingWeight: "Shipping Weight",
	showAll: "Show All",
	showChangeDetails: "Show Change Details",
	showExtendedParameters: "Show Extended Parameters",
	showItemPrices: "Show Item Prices",
	showLocalOrders: "Show Local Orders",
	showMyOrders: "Show My Orders",
	showNotes_Tags_AndDates: "Show Notes, Tags, and Dates",
	showOptionPrices: "Show Option Prices",
	showOptions: "Show Options",
	showSplashScreenAtStartup: "Show Splash Screen At Startup",
	showToolbarText: "Show Toolbar Text",
	showWhereUsedList: "Show Where Used List",
	showMeMoreInformation_: "Show me more information.",
	shutdown: "Shutdown",
	side: "Side",
	side_: "Side 1",
	signIn: "Sign In",
	signedIn: "Signed In",
	signedIn_: "Signed In.",
	signingInAsGuest_: "Signing in as guest.",
	sineWave: "Sine Wave",
	siteAltitude: "Site Altitude",
	siteLineFrequency: "Site Line Frequency",
	siteAltitudeCanNotBeGreaterThan: "Site altitude can not be greater than",
	siteAltitudeCanNotBeLessThan: "Site altitude can not be less than",
	siteAltitudeNotEntered_: "Site altitude not entered !",
	smallDimensions: "Small Dimensions",
	softwarePatches: "Software Patches",
	soldBy: "Sold By",
	soldByNameChangeRequested_: "Sold By Name Change Requested.",
	soldTo: "Sold To",
	soldByInformationIsIncomplete: "Sold by information is incomplete",
	soldToInformationIsIncomplete: "Sold to information is incomplete",
	soldToRegionMustBeTwoCharacterCode_:
		"Sold to region must be two character code!",
	someDescriptionsHaveNotBeenEntered_:
		"Some descriptions have not been entered!",
	someDesignWindowsAreStillOpen_: "Some design windows are still open.",
	someFactoryCallLeadTimeSettingsAreStillNotSet_:
		"Some factory call lead time settings are still not set!",
	someFilesDidNotTransferToTheServer_:
		"Some files did not transfer to the server!",
	someFunctionalityMayBeDisabled_: "Some functionality may be disabled!",
	someItemsNeedFactoryLeadTimes_PleaseCall_:
		"Some items need factory lead times. Please call.",
	someItemsNeedFactoryPricing_PleaseCall_:
		"Some items need factory pricing. Please call !",
	someOptionsWerePreviouslySetToAnotherValue_:
		"Some options were previously set to another value.",
	someOrdersNotSaved_: "Some orders not saved!",
	someOrdersWereNotProperlyClosed_: "Some orders were not properly closed.",
	someParametersChanged_: "Some parameters changed.",
	somePriceChangesOccurred_: "Some price changes occurred.",
	somePricesOrOptionsMayHaveChanged_:
		"Some prices or options may have changed !",
	somePricesWereUpdated_: "Some prices were updated!",
	somePrintFunctionsAreDisabled_: "Some print functions are disabled.",
	spanish: "Spanish",
	spareFeeds: "Spare Feeds",
	spares: "Spares",
	specificHeatAboveFreezing: "Specific Heat Above Freezing",
	specificHeatBelowFreezing: "Specific Heat Below Freezing",
	standard: "Standard",
	standardOrder: "Standard Order",
	standardTextFile_AllListedParts_: "Standard text file - All Listed Parts.",
	standardTextFile_SelectedPartsOnly_:
		"Standard text file - Selected Parts Only.",
	startNROES: "Start NROES",
	startUpOptions: "Start Up Options",
	startShellWhenILogInToWindows: "Start shell when I log in to windows",
	starting: "Starting",
	startingTools: "Starting Tools",
	state: "State",
	status: "Status",
	status_: "Status:",
	statusStrip: "StatusStrip",
	stockingModelOrder: "Stocking Model Order",
	storedOrderIsMoreRecentThanRecoveryData_:
		"Stored order is more recent than recovery data.",
	stripCurtains: "Strip Curtains",
	style: "Style",
	sub_Total: "Sub-Total",
	subject: "Subject",
	submitChangeOrderNow: "Submit Change Order Now",
	submitChangeOrderRequestToManufacturer:
		"Submit Change Order Request To Manufacturer",
	submitChangeRequestToPurchaser: "Submit Change Request To Purchaser",
	submitNotes: "Submit Notes",
	submitOrder: "Submit Order",
	submitOrderNow: "Submit Order Now",
	submitted: "Submitted",
	submittedOrdersMayNotBeEditedInOff_lineMode_:
		"Submitted orders may not be edited in off-line mode.",
	success_: "Success!",
	suction: "Suction",
	suctionTemp: "Suction Temp",
	suctionTemp_S_S_T__: "Suction Temp (S.S.T.)",
	suctionTemperatureCanNotBeGreaterThan:
		"Suction temperature can not be greater than",
	suctionTemperatureCanNotBeLessThan:
		"Suction temperature can not be less than",
	suctionTemperatureIsGreaterThanCondensingTemperature_:
		"Suction temperature is greater than condensing temperature.",
	suctionTemperatureIsMissing_: "Suction temperature is missing.",
	suctionTemperatureIsRequired_: "Suction temperature is required !",
	suitableModels: "Suitable Models",
	switchPriceBook: "Switch PriceBook",
	synchronizingOrder_: "Synchronizing Order:",
	syncronizingContacts: "Syncronizing Contacts",
	system: "System",
	systemLoad: "System Load",
	systemName: "System Name",
	systemNetPrice: "System Net Price",
	systemNotes: "System Notes",
	systemRun_Time: "System Run-Time",
	systemTagging: "System Tagging",
	systemWeight: "System Weight",
	systemLoadIsMissing_: "System load is missing!",
	systemOptionIsNoLongerAvailable_: "System option is no longer available!",
	systemReturned_: "System returned.",
	systemsOnThisOrderContainErrors_: "Systems on this order contain errors.",
	systemsOnThisOrderContainWarnings_: "Systems on this order contain warnings.",
	t_D_: "T.D.",
	t_D_Error: "T.D. Error",
	t_H_R: "T.H.R",
	tD: "TD",
	tDCanNotBeCalculated_: "TD can not be calculated.",
	tDCanNotBeGreaterThan: "TD can not be greater than",
	tDCanNotBeLessThan: "TD can not be less than",
	tDOutOfEnteredToleranceRange_: "TD out of entered tolerance range.",
	tDOutOfMaximumRange_: "TD out of maximum range.",
	tERMS_CONDITIONSANDREMARKS: "TERMS, CONDITIONS AND REMARKS",
	tHEVALUESCONTAINEDHERINAREBASEDONLABORATORYFINDINGSANDTHEINITIALCONDITIONSENTEREDBYTHEUSER_:
		"THE VALUES CONTAINED HERIN ARE BASED ON LABORATORY FINDINGS AND THE INITIAL CONDITIONS ENTERED BY THE USER.",
	tHR: "THR",
	tHRCanNotBeCalculated_: "THR can not be calculated.",
	tOTAL: "TOTAL",
	tOTALINFILTRATIONLOAD: "TOTAL INFILTRATION LOAD",
	tOTALLISTPRICE: "TOTAL LIST PRICE",
	tOTALNET: "TOTAL NET",
	tOTALQUOTEPRICE: "TOTAL QUOTE PRICE",
	tYP: "TYP",
	tYPE: "TYPE",
	tabSeparatedTextFiles: "Tab Separated Text Files",
	tagging: "Tagging",
	taggingID: "Tagging ID",
	taggingID_: "Tagging ID #",
	taggingText: "Tagging Text",
	takeOwnership: "Take Ownership",
	targetPriceAccordingToAdjustmentAndRoundingFactors_:
		"Target price according to adjustment and rounding factors.",
	tax: "Tax",
	taxes: "Taxes",
	temperature: "Temperature",
	temperatures: "Temperatures",
	terminationOptions: "Termination Options",
	terms: "Terms",
	test: "Test",
	textBox: "TextBox",
	theInternalRelativeHumidityNotEntered_:
		"The Internal relative humidity not entered !",
	theMaterialLoadHasNotBeenCalculatedOrEntered_:
		"The Material load has not been calculated or entered !",
	theMaterialMaterialNameIsNotEntered_:
		"The Material material name is not entered !",
	theMaterialMaterialTypeIsNotSelected_:
		"The Material material type is not selected !",
	theOriginatingCustomersInformationIsAlreadyListedInYourContactDatabase_:
		"The Originating customers information is already listed in your contact database.",
	theR_ValueHasNotBeenEntered_: "The R-Value has not been entered !",
	theActualCondensingTemperatureCouldNotBeCalculated_:
		"The actual condensing temperature could not be calculated !",
	theAmbientTemperatureIsNotEntered_:
		"The ambient temperature is not entered !",
	theApplicationCouldNotMoveFile_BootStrap_exeFromTheUpdatesFolderToTheApplicationFolder_:
		"The application could not move file 'BootStrap.exe from the Updates folder to the application folder.",
	theApplicationCouldNotRemoveFile_BootStrap_exe_FromTheApplicationFolder_:
		"The application could not remove file 'BootStrap.exe' from the application folder.",
	theApplicationCouldNotSpawn_BootStrap_exe_InTheApplicationFolder_:
		"The application could not spawn 'BootStrap.exe' in the application folder.",
	theAreaHasNotBeenCalculatedOrEntered_:
		"The area has not been calculated or entered !",
	theAreaIsGreaterThanTheSectionArea_:
		"The area is greater than the section area !",
	theBoxTypeMustBeSelected_: "The box type must be selected !",
	theCentreDistanceIsNotEntered_: "The centre distance is not entered !",
	theChangeCanNotBeProcessedUntilYouCloseTheWorkbookInThisApplication_:
		"The change can not be processed until you close the workbook in this application.",
	theConstructionK_FactorIsNotCalculatedOrEntered_:
		"The construction K-Factor is not calculated or entered !",
	theConstructionR_ValueIsNotCalculatedOrEntered_:
		"The construction R-Value is not calculated or entered !",
	theConstructionNameIsNotEntered_: "The construction name is not entered !",
	theConstructionThicknessHasNotBeenEntered_:
		"The construction thickness has not been entered !",
	theConstructionTypeIsNotSelected_: "The construction type is not selected !",
	theCorrectQuoteRevisionIsSelected: "The correct quote revision is selected",
	theCurrentPricebookHasBeenSuperseded_:
		"The current pricebook has been superseded.",
	theCurrentPricebookHasExpired_: "The current pricebook has expired.",
	theCurrentPricebookNoLongerExists_: "The current pricebook no longer exists.",
	theCurrentPricebookWillExpireInLessThanAMonth_:
		"The current pricebook will expire in less than a month.",
	theCurrentSettingsWillBeUsedOnAllNewOrdersForThisDivision_:
		"The current settings will be used on all new orders for this division.",
	theCurrentSettingsWillBeUsedOnAllNewOrders_:
		"The current settings will be used on all new orders.",
	theDividerMaterialNameIsNotEntered_:
		"The divider material name is not entered !",
	theDividerMaterialTypeIsNotSelected_:
		"The divider material type is not selected !",
	theDividerWidthIsNotEntered_: "The divider width is not entered !",
	theEffectiveR_ValueIsNotCalculatedOrEntered_:
		"The effective R-Value is not calculated or entered !",
	theEmailAddressGivenIsNotListed_: "The email address given is not listed.",
	theExternalRelativeHumidityMustBeGreaterThanZero_:
		"The external relative humidity must be greater than zero !",
	theExternalRelativeHumidityMustBeLessThan____:
		"The external relative humidity must be less than 100 !",
	theExternalRelativeHumidityNotEntered_:
		"The external relative humidity not entered !",
	theExternalTemperatureIsLowerThanTheInternalTemperature_:
		"The external temperature is lower than the internal temperature !",
	theExternalTemperatureIsNotEntered_:
		"The external temperature is not entered !",
	theExternalTemperatureIsTooHigh_: "The external temperature is too high !",
	theExternalTemperatureIsTooLow_: "The external temperature is too low !",
	theExternalTemperatureNotEntered_: "The external temperature not entered !",
	theFactoryInvoiceRecipientIsCorrect:
		"The factory invoice recipient is correct",
	theFactoryInvoiceWillBeSentTo: "The factory invoice will be sent to",
	theFactoryIsNotShippingOnTheSelectedDate_:
		"The factory is not shipping on the selected date.",
	theFloorAreaIsNotCalculatedOrEntered_:
		"The floor area is not calculated or entered !",
	theFollowingChangesHaveBeenVerified:
		"The following changes have been verified",
	theFollowingOptionIsNotAllowedWithThisPackage_:
		"The following option is not allowed with this package.",
	theFollowingOrdersCouldNotBeMarkedAsClosed_:
		"The following orders could not be marked as closed.",
	theFollowingOrdersCouldNotBeMarkedAsShipped_:
		"The following orders could not be marked as shipped.",
	theFollowingOrdersCouldNotBeSynchronized_:
		"The following orders could not be synchronized!",
	theInfiltrationLoadHasNotBeenCalculatedOrEntered_:
		"The infiltration load has not been calculated or entered !",
	theInformationInTheStoreIsOlderThanTheSelectedFile_:
		"The information in the store is older than the selected file.",
	theInternalTemperatureIsNotEntered_:
		"The internal temperature is not entered !",
	theItemOrSomeOptionsNeedFactoryLeadTimes_PleaseCall_:
		"The item or some options need factory lead times. Please call !",
	theLayerThicknessIsNotCalculatedOrEntered_:
		"The layer thickness is not calculated or entered !",
	theLayerThicknessIsNotEntered_: "The layer thickness is not entered !",
	theLoadIsNotCalculatedOrEntered_: "The load is not calculated or entered !",
	theOpeinngWidthIsGreaterThanTheSectionWidth_:
		"The opeinng width is greater than the section width !",
	theOpeningHeightHasNotBeenEntered_:
		"The opening height has not been entered !",
	theOpeningHeightIsGreaterThanTheSectionHeight_:
		"The opening height is greater than the section height !",
	theOpeningTypeIsNotSelected_: "The opening type is not selected !",
	theOpeningWidthHasNotBeenEntered_: "The opening width has not been entered !",
	theOrderCanNotBeProcessedUntilYouCloseTheWorkbookInThisApplication_:
		"The order can not be processed until you close the workbook in this application.",
	theOrderFileAppearsCorrupt_: "The order file appears corrupt.",
	theOrderIsAlreadyListedInTheLocalOrderDatabase_:
		"The order is already listed in the local order database.",
	theOrderIsLockedByAnotherUser_: "The order is locked by another user.",
	theOrderWindowWillUpdateWhenYouCloseTheServicePartsWindow_:
		"The order window will update when you close the service parts window.",
	theOriginalOrderHasNotBeenProcessedYet_:
		"The original order has not been processed yet.",
	thePerimeterLengthMustBeEnteredForSlabOnGradeFloors_:
		"The perimeter length must be entered for slab on grade floors !",
	thePowerUnitsAreNotEntered_: "The power units are not entered !",
	thePriceBookSelectedForThisOrderHasBeenUpdated_:
		"The price book selected for this order has been updated !",
	thePriceBookUsedForTheOpenChangeRequestHasBeenRevised_:
		"The price book used for the open change request has been revised.",
	thePriceBookUsedForTheOpenChangeRequestHasBeenSuperseded_:
		"The price book used for the open change request has been superseded.",
	thePriceBookUsedForTheOpenChangeRequestIsExpiring_:
		"The price book used for the open change request is expiring.",
	thePriceBookUsedForThisOrderHasBeenRevised_:
		"The price book used for this order has been revised.",
	thePriceBookUsedForThisOrderHasBeenSuperseded_:
		"The price book used for this order has been superseded.",
	thePriceBookUsedForThisOrderIsExpiring_:
		"The price book used for this order is expiring.",
	thePriceBookUsedOnOneQuoteHasBeenRevised_:
		"The price book used on one quote has been revised.",
	thePriceBookUsedOnOneQuoteHasBeenSuperseded_:
		"The price book used on one quote has been superseded.",
	thePriceBookUsedOnOneQuoteHasExpired_:
		"The price book used on one quote has expired.",
	thePriceBookUsedOnOneQuoteIsMissing_:
		"The price book used on one quote is missing.",
	thePriceBookWasUpdated_: "The price book was updated!",
	thePriceBooksUsedForThisOrderAreExpiring_:
		"The price books used for this order are expiring.",
	thePriceBooksUsedForThisOrderHaveBeenRevised_:
		"The price books used for this order have been revised.",
	thePriceBooksUsedForThisOrderHaveBeenSuperseded_:
		"The price books used for this order have been superseded.",
	thePriceBooksUsedOn: "The price books used on",
	thePriceBooksUsedOnOneQuoteIsExpiring_:
		"The price books used on one quote is expiring.",
	theProductFreezingTemperatureIsNotEntered_:
		"The product freezing temperature is not entered !",
	theProductIsEnteringAtTheInternalTemperature_TheFinalTemperatureMustAlsoBeTheInternalTemperature_:
		"The product is entering at the internal temperature, the final temperature must also be the internal temperature !",
	theProductTypeIsNotSelected_: "The product type is not selected !",
	theProduct_sEnteringTemperatureIsNotSet_:
		"The product's entering temperature is not set !",
	theProduct_sFinalTemperatureCanNotBeGreaterThanTheEnteringTemperature_:
		"The product's final temperature can not be greater than the entering temperature !",
	theProduct_sFinalTemperatureCanNotBeLowerThanTheEnteringTemperature_:
		"The product's final temperature can not be lower than the entering temperature !",
	theProduct_sFinalTemperatureCanNotBePulledHigherThanTheInternalTemperature_:
		"The product's final temperature can not be pulled higher than the internal temperature !",
	theProduct_sFinalTemperatureCanNotBePulledLowerThanTheInternalTemperature_:
		"The product's final temperature can not be pulled lower than the internal temperature !",
	theProduct_sFinalTemperatureIsNotSet_:
		"The product's final temperature is not set !",
	theProduct_sLoadFactorIsNotSet_: "The product's load factor is not set !",
	theProduct_sLoadFactorShouldBeOneUnlessRapidCoolingIsRequired__:
		"The product's load factor should be one unless rapid cooling is required. !",
	theProduct_sLoadIsNotCalculatedNotEntered_:
		"The product's load is not calculated not entered !",
	theProduct_sMoistureContentIsNotEntered_:
		"The product's moisture content is not entered !",
	theProduct_sPullDownTimeIsNotSet_:
		"The product's pull down time is not set !",
	theProduct_sSpecificHeatAboveFreezingIsNotEntered_:
		"The product's specific heat above freezing is not entered !",
	theProduct_sSpecificHeatBelowFreezingIsNotEntered_:
		"The product's specific heat below freezing is not entered !",
	theProduct_sWeightIsNotEntered_: "The product's weight is not entered !",
	theProgramStoreIsNewerThanTheFileYouSelected_:
		"The program store is newer than the file you selected!",
	theQuoteHasBeenSignedByTheCustomer:
		"The quote has been signed by the customer",
	theRunTimeIsNotEntered_: "The run time is not entered !",
	theSelectedChangeOrderHasBeenProcessedByOurOrderDepartment_:
		"The selected change order has been processed by our Order Department.",
	theSelectedChangeOrderHasBeenProcessedByThePurchaser_:
		"The selected change order has been processed by the purchaser.",
	theSelectedOrderHasBeenProcessedByOurOrderDepartment_:
		"The selected order has been processed by our Order Department.",
	theSelectedOrderHasBeenProcessedByThePurchaser_:
		"The selected order has been processed by the purchaser.",
	theSelectedOrderIsOpenInAnotherSessionOfNROES_:
		"The selected order is open in another session of NROES.",
	theSelectedRefrigerantIsNotAvailableOnThisUnit_:
		"The selected refrigerant is not available on this unit !",
	theServerReturnedTheFollowing_: "The server returned the following.",
	theSystemReportedError: "The system reported error",
	theSystemReportedTheFollowingError_:
		"The system reported the following error.",
	theTotalInfiltrationLoadIsNotCalculatedOrEntered_:
		"The total infiltration load is not calculated or entered !",
	theTotalVolumeIsNotCalculatedOrEntered_:
		"The total volume is not calculated or entered !",
	theUserNameYouEnteredIsNotRegisteredOnThisWorkstation_:
		"The user name you entered is not registered on this workstation!",
	theValueWillBeAddedOrSubtractedFromTheCurrentPrice_:
		"The value will be added or subtracted from the current price.",
	thenClickOnSaveAgain_: "Then click on save again.",
	thereAreNoItemsOnThisOrder_: "There are no items on this order!",
	thereAreNoOptionsAvailableForThisUnit_:
		"There are no options available for this unit!",
	thickness: "Thickness",
	thicknessIsNotEntered_: "Thickness is not entered !",
	thirdParty: "Third Party",
	thisMayTakeAFewMinutes_: "This may take a few minutes.",
	thisModelHasOptionsAttached_: "This model has options attached.",
	thisOrderHasBeenPutOnHoldByTheOrderDepartment_:
		"This order has been put on hold by the order department.",
	thisOrderHasBeenRejectedByTheOrderDepartment_:
		"This order has been rejected by the order department.",
	thisOrderHasPriceBookIssues_: "This order has price book issues.",
	thisOrderWasNotClosedProperlyTheLastTimeYouOpenedItOnThisWorkstation_:
		"This order was not closed properly the last time you opened it on this workstation.",
	thisOrderWasSavedUsingAPreviousVersionOfYourMultiplierStructure_:
		"This order was saved using a previous version of your multiplier structure!",
	thisOrderWasSavedUsingAPreviousVersionOfYourUserCatalogueProfile_:
		"This order was saved using a previous version of your user catalogue profile!",
	tileHorizontally: "Tile Horizontally",
	tileVertically: "Tile Vertically",
	time: "Time",
	title: "Title",
	toNearest: "To Nearest",
	toNearest_: "To Nearest 1",
	toNearest_____: "To Nearest 1,000",
	toNearest__: "To Nearest 10",
	toNearest______: "To Nearest 10,000",
	toNearest___: "To Nearest 100",
	toNearest_______: "To Nearest 100,000",
	toAssistUs_PleaseProvideAnyFurtherInformationYouMayThinkIsRelevant_:
		"To assist us, please provide any further information you may think is relevant.",
	toChangeThePartQuantities_PleaseEditThemInTheOrderWindow_:
		"To change the part quantities, please edit them in the order window.",
	tolerance: "Tolerance",
	tools: "Tools",
	total: "Total",
	totalCapacityAtDesignTD: "Total Capacity At Design TD",
	totalEquipmentPrice: "Total Equipment Price",
	totalHeatRejected: "Total Heat Rejected",
	totalHourlyLoad: "Total Hourly Load",
	totalLoadRequired: "Total Load Required",
	totalMiscellaneousLoad: "Total Miscellaneous Load",
	totalOpeningInfiltration: "Total Opening Infiltration",
	totalOtherLoads: "Total Other Loads",
	totalPrice: "Total Price",
	totalProductLoad: "Total Product Load",
	totalProductWeight: "Total Product Weight",
	totalSystemCapacity: "Total System Capacity",
	totalThickness: "Total Thickness",
	totalVolume: "Total Volume",
	totalWallLoad: "Total Wall Load",
	totalDoorWidthExceedsSectionWidth: "Total door width exceeds section width",
	totalHeatRejectedOutOfRange_: "Total heat rejected out of range.",
	totalOpeningAreaExceedsSectionArea: "Total opening area exceeds section area",
	translationsAreUNDERCONSTRUCTIONAndMayBeLiteralByNatureAndMayBeContextuallyInError_:
		"Translations are UNDER CONSTRUCTION and may be literal by nature and may be contextually in error.",
	trentonLoadCalcFiles: "Trenton Load Calc Files",
	trentonRefrigeration: "Trenton Refrigeration",
	tubeConfiguration: "Tube Configuration",
	tubeRows: "Tube Rows",
	tubes: "Tubes",
	tubesPerFeed: "Tubes Per Feed",
	tubesPerRow: "Tubes Per Row",
	typ: "Typ",
	type: "Type",
	u_SHAPED: "U - SHAPED",
	u_S_: "U.S.",
	u_S_English: "U.S. English",
	uNIT: "UNIT",
	uNITLIST: "UNIT LIST",
	uNITNET: "UNIT NET",
	uNLESSOTHERWISESPECIFIED: "UNLESS OTHERWISE SPECIFIED",
	uNUSEDCONNECTIONS: "UNUSED CONNECTIONS",
	uPDATED: "UPDATED",
	uSERINFORMATION: "USER INFORMATION",
	uSERPREFERENCES: "USER PREFERENCES",
	undo: "Undo",
	unit_: "Unit #",
	unitCooler: "Unit Cooler",
	unitList: "Unit List",
	unitNet: "Unit Net",
	unitPrice: "Unit Price",
	unitTotal: "Unit Total",
	unitIsNotCompatibleWithSelectedSystemRefrigerant_:
		"Unit is not compatible with selected system refrigerant !",
	units: "Units",
	unknownReason_: "Unknown Reason.",
	untilTheChangeIsProcessedByTheManufacturer_YouMayStopYourRequest_:
		"Until the change is processed by the manufacturer, you may stop your request.",
	untilThen_FunctionalityWillBeLimited_:
		"Until then, functionality will be limited!",
	unusedFeeds: "Unused Feeds",
	upOrDown: "Up Or Down",
	updateAllMultipliers: "Update All Multipliers",
	updateAllParameters: "Update All Parameters",
	updateAllPrices: "Update All Prices",
	updateNow: "Update Now",
	updatePricebook: "Update Pricebook",
	updatePending_: "Update pending.",
	updateTheCurrentInformation_Or: "Update the current information. or ",
	updateTheExistingListing_: "Update the existing listing.",
	updatesCanNotBeInstalled_: "Updates can not be installed.",
	uploadToJOB_SCOPE: "Upload To  JOB-SCOPE",
	uploadingBuffer: "Uploading Buffer",
	uploadingPreviouslySavedFiles: "Uploading Previously Saved Files",
	uploadingQueuedOrders: "Uploading Queued Orders",
	uploadingQueuedOrder_: "Uploading queued Order:",
	useLogoImageFile: "Use Logo Image File",
	useMyInformation: "Use My Information",
	useBoxSetting: "Use box setting",
	useMenuFunctionCreateChangeOrderInstead_:
		"Use menu function Create Change Order instead.",
	useTheHOLDFunctionIfYouExpectTheOriginatorToCorrectTheOrder_:
		"Use the HOLD function if you expect the originator to correct the order!",
	useTheSTOPSUBMITTEDCHANGESelectionInTheOrderMenu_:
		"Use the STOP SUBMITTED CHANGE selection in the Order Menu.",
	useTheSTOPSUBMITTEDORDERSelectionInTheOrderMenu_:
		"Use the STOP SUBMITTED ORDER selection in the Order Menu.",
	useTheMenuToCreateNewRevision_s_FromYourEditsAsDesired_:
		"Use the menu to create new revision(s) from your edits as desired.",
	userID: "User ID",
	userInformation: "User Information",
	userInformationRequested_: "User Information requested.",
	userName: "User Name",
	userRegistrationInformation: "User Registration Information",
	userVerification: "User Verification",
	userNameRequired_: "User name required.",
	userSelectablePriceAdjustmentValue_:
		"User selectable price adjustment value.",
	usernameNotFound_: "Username not found!",
	usingLocalVersion_: "Using local version.",
	vWaffle: "V Waffle",
	vOLTAGE: "VOLTAGE",
	valueRequired_: "Value Required!",
	valueMustBeGreaterThanZero_: "Value must be greater than zero!",
	various: "Various",
	velocity: "Velocity",
	ventilationAirRelativeHumidity___: "Ventilation Air Relative Humidity (%)",
	ventilationAirTemperature: "Ventilation Air Temperature",
	ventilationRate: "Ventilation Rate",
	ventilationAirRelativeHumidityIsNotEntered_:
		"Ventilation air relative humidity is not entered !",
	ventilationAirTemperatureIsNotEntered_:
		"Ventilation air temperature is not entered !",
	ventilationRateIsLessThanTheRecommendedMinimumValue_:
		"Ventilation rate is less than the recommended minimum value !",
	verifyDesign: "Verify Design",
	version: "Version",
	version_OfNROESUsesADifferentSaveStrategy_:
		"Version 8 of NROES uses a different save strategy.",
	version_UsesAWorkbookForYourJobInsteadOfMultipleQuoteRevisionFiles_:
		"Version 8 uses a workbook for your job instead of multiple quote revision files.",
	vertical: "Vertical",
	via: "Via",
	view_ArchivedFiles: "View &Archived Files",
	viewCSV: "View CSV",
	visitUsOn_Line: "Visit Us On-Line",
	voltage: "Voltage",
	volume: "Volume",
	wAITINGFORSERVICEPARTSCATALOGUETOLOAD:
		"WAITING FOR SERVICE PARTS CATALOGUE TO LOAD",
	wARNING: "WARNING",
	wARNING_: "WARNING!",
	wARNINGS: "WARNINGS",
	wATTS: "WATTS",
	wO: "WO",
	wORKORDER: "WORK ORDER",
	waitingForShellToComeReady: "Waiting For Shell To Come Ready",
	waitingForShellToComeReadyForFirstTime:
		"Waiting For Shell To Come Ready For First Time",
	waitingForShellToStart: "Waiting For Shell To Start",
	waitingForANewPassword_: "Waiting for a new password.",
	waitingForRegistrationKey_: "Waiting for registration key.",
	waitingForShell_: "Waiting for shell.",
	waitingForSign_in: "Waiting for sign-in",
	wall: "Wall",
	wallLoad: "Wall Load",
	wallSection: "Wall Section",
	wallsCross_PleaseCheckYourDimensions_:
		"Walls cross! Please check your dimensions.",
	warning: "Warning",
	warning_PriceBookHasExpired_: "Warning - Price book has expired!",
	warningOppositeEndConnections_DimensionDrawingWillOnlyShowSameEndHeaders:
		"Warning Opposite End Connections. Dimension drawing will only show same end headers",
	warning_: "Warning!",
	warnings: "Warnings",
	waterCooledCondensingUnit: "Water Cooled Condensing Unit",
	webMessagesAndBulletins: "Web Messages And Bulletins",
	weight: "Weight",
	whatToPrint: "What To Print",
	whatDoYouWantToDo_: "What do you want to do?",
	whatTypeOfFileDoYouRequire_: "What type of file do you require?",
	whatTypeOfOrderDoYouWishToCreate_:
		"What type of order do you wish to create.",
	whereUsed: "Where Used",
	whereUsed_: "Where Used:",
	wholesale: "Wholesale",
	width: "Width",
	window: "Window",
	with: "With",
	withTheInternetConnected_: "With the internet connected.",
	workHours: "Work Hours",
	workOrder: "Work Order",
	workingDays: "Working Days",
	workingOn_Line: "Working On-Line",
	wouldYouLikeToDo_: "Would you like to do?",
	wrapSystemBar: "Wrap System Bar",
	yes: "Yes",
	yesPlease: "Yes please",
	yes_ChangeTheModelToTheOneISelected_:
		"Yes, Change the model to the one I selected.",
	yes_IAmARegisteredUser_: "Yes, I am a registered user.",
	yes_SetOptionsToTheNewMultiplier_: "Yes, set options to the new multiplier.",
	yes_SetTheValueIEntered_: "Yes, set the value I entered.",
	yes__SomeFunctionalityWillBeDisabled_:
		"Yes. (Some functionality will be disabled)",
	youAreNotTheOriginalOwnerOfThisOrder_:
		"You are not the original owner of this order.",
	youAreProhibitedFromSettingTheMultiplierThisLow_:
		"You are prohibited from setting the multiplier this low.",
	youAreWorkingOffline_: "You are working offline!",
	youCanNoLongerStopThisChangeRequest_:
		"You can no longer stop this change request.",
	youCanNoLongerStopThisOrder_: "You can no longer stop this order.",
	youCanNoLongerStopThisPurchaseRequest_:
		"You can no longer stop this purchase request.",
	youCanNotDeleteTheLastSystemOnAnQuote_:
		"You can not delete the last system on an quote?",
	youCanNotDeleteThisOrderFromTheServer_:
		"You can not delete this order from the server.",
	youCanNotEditTheDoorInformationAtThisTime_:
		"You can not edit the door information at this time !",
	youCanNotEditTheSectionInformationAtThisTime_:
		"You can not edit the section information at this time !",
	youCanNotEditThisOrderAtThisTime_:
		"You can not edit this order at this time.",
	youCanNotRejectAnOrderUsingNROESOnceItHasBeenSubmittedToJob_Scope_:
		"You can not reject an order using NROES once it has been submitted to Job-Scope.",
	youChangedTheCondensingUnitModelNumber_:
		"You changed the condensing unit model number!",
	youChangedTheModelNumber_: "You changed the model number!",
	youDoNotCurrentlyHaveRightsToThisTool_:
		"You do not currently have rights to this tool.",
	youDoNotHaveTheOriginalPriceBookLoaded_:
		"You do not have the original price book loaded.",
	youDoNotHaveTheRequiredPriceBookForTheOpenChangeRequest_:
		"You do not have the required price book for the open change request.",
	youDoNotHaveTheRequiredPriceBookForThisOrder_:
		"You do not have the required price book for this order !",
	youDoNotHaveTheRequiredPriceBooksForThisOrder_:
		"You do not have the required price books for this order.",
	youHaveFailedToReceiveACatalogueDataUpdateForMoreThan__Days_:
		"You have failed to receive a catalogue data update for more than 30 days!",
	youHaveFailedToReceiveAProgramUpdateForMoreThan__Days_:
		"You have failed to receive a program update for more than 30 days!",
	youHaveNotBeenAssignedAChannelForDirectEntryToTheAS_____:
		"You have not been assigned a channel for direct entry to the AS/400.",
	youHaveNotConfirmedYourEmailAddressYet_:
		"You have not confirmed your email address yet!",
	youHaveNotReceivedACatalogueDataUpdateForMoreThan__Days_:
		"You have not received a catalogue data update for more than 15 days!",
	youHaveNotReceivedAProgramUpdateForMoreThan__Days_:
		"You have not received a program update for more than 15 days!",
	youHaveNotStartedTheApplicationOn_lineForMoreThan__Days_:
		"You have not started the application on-line for more than 15 days!",
	youMayCreateANewChangeOrder_OrContactUsByTelephone_:
		"You may create a new change order, or contact us by telephone.",
	youMayCreateANewChangeOrder_OrContactYourPurchaser_:
		"You may create a new change order, or contact your purchaser.",
	youMayCreateANewOrderAndCorrectTheListedIssues_:
		"You may create a new order and correct the listed issues.",
	youMayEditThisOrderOff_line_: "You may edit this order off-line.",
	youMayEnterAPositiveOrNegativeValueHere_:
		"You may enter a positive or negative value here.",
	youMayEnterAValueHereToRoundToAValueOtherThanThatEndingInAZero_:
		"You may enter a value here to round to a value other than that ending in a zero.",
	youMayHaveTheOrderOpenInAnotherApplication_:
		"You may have the order open in another application.",
	youMayNotCopyThisOrder_: "You may not copy this order!",
	youMayNowEditYourData_: "You may now edit your data.",
	youMayPreferToSimplyDeselectTheSpecificOptionsYouDoNotRequire_:
		"You may prefer to simply deselect the specific options you do not require.",
	youMaySelectRoundingOptionsHere_: "You may select rounding options here.",
	youMayTakeOwnershipOfThisOrderUsingTheOrderMenu_:
		"You may take ownership of this order using the order menu.",
	youMayThenEditTheServicePartQuantitiesInTheOrderItself_:
		"You may then edit the service part quantities in the order itself.",
	youMayWorkOff_lineIfYouWish_: "You may work off-line if you wish.",
	youMustBeConnectedToTheInternetToUseThisTool_:
		"You must be connected to the internet to use this tool.",
	youRequestedIDeleteASystemFromTheOrder_:
		"You requested I delete a system from the order.",
	youShouldCloseThisOrderAndTryToReopenItForSynchronizationOptions_:
		"You should close this order and try to reopen it for synchronization options.",
	youShouldReceiveAnE_mailShortly_: "You should receive an e-mail shortly.",
	youShouldReceiveMoreInformationWithinTheNextFewWorkingDays_:
		"You should receive more information within the next few working days.",
	youShouldReceiveYourCodeShortlyAtTheFollowingE_mailAddress_:
		"You should receive your code shortly at the following e-mail address.",
	youShouldReceiveYourLoginInformationViaE_mailShortly_:
		"You should receive your login information via e-mail shortly.",
	youShouldStopTheRequest_CorrectTheIssuesListedBelow_AndResubmit_:
		"You should stop the request, correct the issues listed below, and resubmit.",
	youWillBeTheOnlyOneAbleToMakeChanges_:
		"You will be the only one able to make changes.",
	yourAuthorizationHasBeenRecinded_: "Your authorization has been recinded.",
	yourAuthorizationIsNowUnderReview_: "Your authorization is now under review.",
	yourAuthorizationIsStillUnderReview_:
		"Your authorization is still under review.",
	yourChangeRequestWasSubmitted_: "Your change request was submitted.",
	yourDataContainsWarnings_: "Your data contains warnings.",
	yourNeedToBeOn_lineToCompleteTheRegistrationProcess_:
		"Your need to be on-line to complete the registration process.",
	yourNewChangeRequestWillReplaceThePreviousOne_AfterYouCompleteYourChangesAndRe_submit_:
		"Your new change request will replace the previous one, after you complete your changes and re-submit.",
	yourNewOrderWillReplaceThePreviousOne_AfterYouCompleteYourChangesAndRe_submit_:
		"Your new order will replace the previous one, after you complete your changes and re-submit.",
	yourNewRequestWillReplaceThePreviousOne_AfterYouCompleteYourChangesAndRe_submit_:
		"Your new request will replace the previous one, after you complete your changes and re-submit.",
	yourOrderWasSubmitted_: "Your order was submitted.",
	yourPasswordHasBeenSuccessfullyUpdated:
		"Your password has been successfully updated",
	yourPreviouslySubmittedChangeOrderHasBeenStopped_:
		"Your previously submitted change order has been stopped!",
	yourPreviouslySubmittedChangeRequestHasBeenStopped_:
		"Your previously submitted change request has been stopped!",
	yourPreviouslySubmittedOrderHasBeenStopped_:
		"Your previously submitted order has been stopped!",
	yourPreviouslySubmittedRequestHasBeenStopped_:
		"Your previously submitted request has been stopped!",
	yourPurchaserHasBegunWorkOnYourOrderRequest_:
		"Your purchaser has begun work on your order request.",
	yourRequestCouldNotBeRetractedAtThisTime_:
		"Your request could not be retracted at this time!",
	yourSoldByRegionMustBeTwoCharacterCode_:
		"Your sold by region must be two character code!",
	yourUserAccountHasBeenRejected_: "Your user account has been rejected!",
	yourUserAccountIsCurrentlyDisabled_:
		"Your user account is currently disabled!",
	yourUserProfileHasBeenSuccessfullyUpdated_:
		"Your user profile has been successfully updated!",
	yourVendorAccountIsCurrentlyDisabled_:
		"Your vendor account is currently disabled!",
	zip_PostalCode: "Zip / Postal Code",
	zipCode: "Zip Code",
	zoneBoundary: "Zone Boundary",
	zoneBoundary_TD___: "Zone Boundary ( TD = 0 )",
	asNewRevision_AndRestore: "as new revision, and restore",
	asNewRevision_: "as new revision.",
	atFixtureRating: "at Fixture Rating",
	confirmYourRequestByClickingOnTheOKButton_:
		"confirm your request  by clicking on the OK button.",
	e_g_ToRoundTo______SetRoundingToNearest____AndEnter__Here_:
		"e.g. To round to 99.99, set rounding to nearest 100, and enter -5 here.",
	equipmentChangesFound_: "equipment changes found.",
	feedIsNotUsed_: "feed is not used !",
	feedsAreNotUsed_: "feeds are not used !",
	freightTerms: "freight terms",
	frmOptions: "frmOptions",
	haveBeenRevised_: "have been revised.",
	haveBeenSuperseded_: "have been superseded.",
	lblBOLD: "lblBOLD",
	orQuantity: "or Quantity",
	orR_Value_UnitThickness: "or R-Value / Unit Thickness",
	orTotalLightingPower: "or Total Lighting Power",
	pricesChanged_: "prices changed.",
	quotesAreExpiring_: "quotes are expiring.",
	quotesAreMissing_: "quotes are missing.",
	quotesHaveExpired_: "quotes have expired.",
	regularShipDateOfTheSelectedItem_: "regular ship date of the selected item.",
	revisionsUpdated_: "revisions updated.",
	revisions_: "revisions.",
	to: "to",
	toLastSavedVersion_: "to last saved version.",
	youCanNoLongerCreateQuoteRevisionsDuringTheSaveOperation_:
		"you can no longer create quote revisions during the save operation.",
	_LessOptions_: "( Less Options )",
	airCooledCondenserDesigner: "Air Cooled Condenser Designer",
	approvalOfThisDrawingSignifiesThatTheEquipmentIsAcceptableUnderTheProvisionOfTheJobSpecifications_AnyChangeMadeHereonByAnyPersonWhomsoeverSubjectToAcceptanceByNATIONALREFRIGERATIONAtItsHomeOffice_:
		"Approval of this drawing signifies that the equipment is acceptable under the provision of the job specifications. Any change made hereon by any person whomsoever subject to acceptance by NATIONAL REFRIGERATION at its home office.",
	aPPROVALS: "APPROVALS",
	aPPROVEDBY_: "APPROVED BY : ",
	cARRIERPREFERENCE: "CARRIER PREFERENCE",
	certifiedPrintMaker: "Certified Print Maker",
	cHANGEORDERREQUESTFORM: "CHANGE ORDER REQUEST FORM",
	cUSTOMERORDER__: "CUSTOMER ORDER # :",
	dATE_: "DATE :",
	dimA: "Dim A",
	dimB: "Dim B",
	dimC: "Dim C",
	dimD: "Dim D",
	dIMENSIONA: "DIMENSION A",
	dIMENSIONB: "DIMENSION B",
	dIMENSIONC: "DIMENSION C",
	dIMENSIOND: "DIMENSION D",
	dIMENSIONE: "DIMENSION E",
	dIMENSIONF: "DIMENSION F",
	dIMENSIONG: "DIMENSION G",
	dIMENSIONH: "DIMENSION H",
	dIMENSIONS: "DIMENSIONS",
	dimensionsShownAreForStandardUnitLessOptions_:
		"Dimensions shown are for standard unit less options.",
	dimensionsShownAreForStandardUnitLessOptions_SeeCertifiedDrawingForMoreDetails_:
		"Dimensions shown are for standard unit less options. See certified drawing for more details.",
	dISCHARGE: "DISCHARGE",
	documentBrowser: "Document Browser",
	dRAIN: "DRAIN",
	eLECTRONICORDERFORM: "ELECTRONIC ORDER FORM",
	eLECTRONICORDERFORM_CONTINUED: "ELECTRONIC ORDER FORM - CONTINUED",
	eQUIPMENTSUMMARY: "EQUIPMENT SUMMARY",
	eSTSHIPWEIGHT_LessOptions_: "EST SHIP WEIGHT (Less Options)",
	eSTSHIPWEIGHT_: "EST SHIP WEIGHT :",
	fACTORYINVOICETO_: "FACTORY INVOICE TO :",
	fACTORYORDER__: "FACTORY ORDER # :",
	fREIGHT: "FREIGHT",
	fREIGHTTERMS_: "FREIGHT TERMS :",
	hOTGASINLET: "HOT GAS INLET",
	hOTGASOUTLET: "HOT GAS OUTLET",
	hOTGASSIDEPORT: "HOT GAS SIDE PORT",
	iD__: "ID # :",
	iTEM__: "ITEM # :",
	lbs_LessOptions_: "Lbs ( Less Options )",
	lead: "Lead",
	list: "List",
	mANUALENTRY: "MANUAL ENTRY",
	mANUALOPTION: "MANUAL OPTION",
	mult: "Mult",
	nATIONALREFRIGERATIONWillFurnishEquipmentInAccordanceWithThisDrawingAndSpecifications_AndSubjectToItsPublishedWarranty_ApprovalOfThisDrawingSignifiesThatTheEquipmentIsAcceptableUnderTheProvisionOfTheJobSpecifications_AnyChangeMadeHereonByAnyPersonWhomsoeverSubjectToAcceptanceByNATIONALREFRIGERATIONAtItsHomeOffice_:
		"NATIONAL REFRIGERATION will furnish equipment in accordance with this drawing and specifications, and subject to its published warranty. Approval of this drawing signifies that the equipment is acceptable under the provision of the job specifications. Any change made hereon by any person whomsoever subject to acceptance by NATIONAL REFRIGERATION at its home office.",
	nEWSYSTEM: "NEW SYSTEM",
	nOTES_: "NOTES:",
	oEMult: "OE Mult",
	oF: "OF",
	oPTION: "OPTION",
	oRDER__: "ORDER # :",
	oRDERDATE_: "ORDER DATE :",
	pACKAGE: "PACKAGE",
	pACKAGEITEM: "PACKAGE ITEM",
	pACKAGEOPTION: "PACKAGE OPTION",
	pAGE: "PAGE",
	pANLOOP: "PAN LOOP",
	pRICEBOOK_: "PRICE BOOK :",
	priceTotal: "Price Total",
	pROD: "PROD",
	pROJECT_: "PROJECT :",
	pURCHASER_: "PURCHASER :",
	pURCHASER_SPO__: "PURCHASER'S PO # :",
	qUOTE__: "QUOTE # :",
	qUOTEDATE_: "QUOTE DATE :",
	rEF__: "REF # :",
	rEMARKS_SPECIALSHIPPINGINSTRUCTIONS:
		"REMARKS / SPECIAL SHIPPING INSTRUCTIONS",
	s_H: "S & H",
	servicePartsList: "Service Parts List",
	sHIPMENT_: "SHIPMENT :",
	shippingAndHandling: "Shipping and Handling",
	sHIPPINGWEIGHT: "SHIPPING WEIGHT",
	sOUND: "SOUND",
	sTOCKINGMODELS: "STOCKING MODELS",
	sUBMITTEDBY_: "SUBMITTED BY :",
	sUBTOTAL: "SUBTOTAL",
	sUCTION: "SUCTION",
	systemNet: "System Net",
	tAGGING_: "TAGGING :",
	totalNet: "Total Net",
	uSERID_: "USER ID :",
	vENDORID_: "VENDOR ID :",
	wATER: "WATER",
	wEIGHT: "WEIGHT",
	aSuiteOfSoftwareApplicationsCreatedToHelpRefrigerationProfessionalsMakeAccurateCalculations_AccessProductInformation_ChooseComponentsAndPrintTechnicalDrawings:
		"A suite of software applications created to help refrigeration professionals make accurate calculations, access product information, choose components and print technical drawings",
	pleaseUpdateAllPrices_: "Please update all prices.",
	pleaseEnterYourPersonal: "Please enter your personal",
	applicationRegistrationKey_: "application registration key.",
	saveAsNewContact: "Save as new contact",
	saveContact: "Save contact",
	saveNewContact: "Save new contact",
	partNumber: "Part Number",
	loadCalculatorII: "Load Calculator II",
	newJob: "New Job",
	systemCapacity: "System Capacity",
	descriptions: "Descriptions",
	boxApplication: "Box Application",
	application: "Application",
	theExternalRelativeHumidityMustBeGreaterThan:
		"The external relative humidity must be greater than",
	theExternalRelativeHumidityMustBeLessThan___:
		"The external relative humidity must be less than",
	theInternalRelativeHumidityMustBeGreaterThan:
		"The Internal relative humidity must be greater than",
	theInternalRelativeHumidityMustBeLessThan:
		"The Internal relative humidity must be less than",
	lineLoss: "Line Loss",
	systemRunTime: "System Run Time",
	systemRunTimeMustBeGreaterThanOneHour_:
		"System run time must be greater than one hour !",
	systemRunTimeMustBeLessThanTwentyfourHours_:
		"System run time must be less than twentyfour hours !",
	theSystemLineLossIsNotEntered_: "The system line loss is not entered !",
	theSystemLineLossIsTooHigh_: "The system line loss is too high !",
	theSystemLineLossIsTooLow_: "The system line loss is too low !",
	averagePassageTime_Seconds_: "Average Passage Time (Seconds)",
	passagesPerDay: "Passages Per Day",
	curtainEffectiveness: "Curtain Effectiveness",
	standOpenTimePerDay_Minutes_: "Stand Open Time Per Day (Minutes)",
	cONSTRUCTIONANDDIMENSIONS: "CONSTRUCTION AND DIMENSIONS",
	volumeAndTemperatures: "Volume And Temperatures",
	airFlowRates: "Air Flow Rates",
	volumeAndTemperature: "Volume And Temperature",
	hasConfigurationErrors: "has configuration errors",
	optionConfigurations: "Option Configurations",
	setConfiguration: "Set configuration",
	can_tHaveOptions: "Can't have options",
	notRecommendedOptions: "Not Recommended Options",
	recommendedOptions: "Recommended Options",
	availableConfigurations_: "Available Configurations.",
	normalConfiguration_: "Normal Configuration.",
	hasConfigurationWarning: "has configuration warning",
	optionConfigurationError_: "Option configuration error !",
	optionConfigurationWarning_: "Option configuration warning !",
	optionConfigurtionErrorsDetected_: "Option configurtion errors detected!",
	pleaseSelectOneOfTheAvailableConfigurations_:
		"Please select one of the available configurations.",
	thisOptionHasMultipleConfigurationsAvailable_:
		"This option has multiple configurations available.",
	includesOptions: "Includes Options",
	over_Ride_SubmitErrors: "Over_Ride &Submit Errors",
	cHeckPRicesOK: "CHeckPRicesOK",
	errorLabel: "ErrorLabel",
	noRefrigerantRatingValuesListed_: "No refrigerant rating values listed.",
	termsOfSale: "Terms Of Sale",
	warranty: "Warranty",
	lOGO: "LOGO",
	tERMSOFSALE: "TERMS OF SALE",
	vENDORINFORMATION: "VENDOR INFORMATION",
	useTOSImageFile: "Use TOS Image File",
	checkAllErrors: "CheckAllErrors",
	drawings: "Drawings",
	optionList: "Option List",
	specificationsPage: "Specifications Page",
	warningLabel: "Warning Label",
	clickCANCELToAdjustYourSelectedOptions_:
		"Click CANCEL to adjust your selected options.",
	youWillNotBeAbleToSubmitThisOrderUsingThisOptionConfiguration_:
		"You will not be able to submit this order using this option configuration.",
	oPTIONDESCRIPTIONAPPENDIX: "OPTION DESCRIPTION APPENDIX",
	requestedOrderShipDateIsBeforeToday_:
		"Requested order ship date is before today!",
	ordersListed_: "Orders Listed.",
	sEARCHINGANDSORTING: "SEARCHING AND SORTING",
	synchronizingOrders_PleaseWait: "Synchronizing Orders ~ Please Wait",
	archive_: "Archive - ",
	synchronizingOrders: "Synchronizing Orders",
	itemPartDescriptionRequired_: "Item Part description required !",
	doYouWantToAttemptToOpenThisFileAnyway_:
		"Do you want to attempt to open this file anyway?",
	oldStyleChecksumDoesNotMatch_: "Old style checksum does not match.",
	failedToOpenDataCatalog_: "Failed to open data catalog!",
	oPTIONDESCRIPTIONAPPENDIXCONTINUED: "OPTION DESCRIPTION APPENDIX CONTINUED",
	includeUnselectedOptions: "Include Unselected Options",
	sELECTEDOPTIONDESCRIPTIONS: "SELECTED OPTION DESCRIPTIONS",
	sELECTEDOPTIONDESCRIPTIONSCONTINUED: "SELECTED OPTION DESCRIPTIONS CONTINUED",
	setRevisionSUbmitted: "Set Revision SUbmitted",
	uNsubmitQuote: "UNsubmit Quote",
	addDoors_Openings: "Add Doors / Openings",
	deleteDoors_Openings: "Delete Doors / Openings",
	dOORUSAGE: "DOOR USAGE",
	editDoor_Openings: "Edit Door / Openings",
	curtainR_Value: "Curtain R-Value",
	loadingPlatformRoll_UpDoor: "Loading Platform Roll-Up Door",
	trailerDockRoll_UpDoor: "Trailer Dock Roll-Up Door",
	reach_InGlassDoor: "Reach-In Glass Door",
	boxUsage: "Box Usage",
	floorType: "Floor Type",
	theRefrigeratedFloorIsBuiltDirectlyOnTheGround__OrTheRoomInWhichTheRefrigeratedBoxIsLocatedIsBuiltDirectlyOnTheGround__:
		"The refrigerated floor is built directly on the ground. ~ Or the room in which the refrigerated box is located is built directly on the ground. ~",
	orTheRoomInWhichTheRefrigeratedBoxIsLocatedIsBuiltDirectlyOnTheGround_:
		"Or the room in which the refrigerated box is located is built directly on the ground,",
	orThereIsAVentilatedSpaceUnderTheRoomInWhichTheRefrigeratedSpaceIsLocated_:
		"Or there is a ventilated space under the room in which the refrigerated space is located.",
	theRefrigeratedFloorIsBuiltDirectlyOnTheGround_:
		"The refrigerated floor is built directly on the ground.",
	thereIsAVentilatedSpaceUnderTheRefrigeratedFloor_:
		"There is a ventilated space under the refrigerated floor.",
	thisIncludesCrawlSpacesOrUpperFloorInstallations_:
		"This includes crawl spaces or upper floor installations.",
	coolingLoads: "Cooling Loads",
	checkBox: "CheckBox",
	aboveGrade: "Above Grade",
	onGrade: "On Grade",
	heatingTemperature: "Heating Temperature",
	surroundingAirTemperature: "Surrounding Air Temperature",
	onGrade_FrostHeaveProtected: "On Grade - Frost Heave Protected",
	aNDAControlledFrostHeavePreventionHeaterSystemIsOrWillBeInstalled_:
		"AND a controlled frost heave prevention heater system is or will be installed.",
	construction: "Construction",
	wALL_FLOORANDCEILINGLOADS: "WALL, FLOOR AND CEILING LOADS",
	cONTINUED: "CONTINUED",
	pRODUCTLOADS: "PRODUCT LOADS",
	finalTemp: "Final Temp",
	enterTemp: "Enter Temp",
	pullDown: "Pull Down",
	tOTALPRODUCTLOAD: "TOTAL PRODUCT LOAD",
	tOTALPRODUCTWEIGHT: "TOTAL PRODUCT WEIGHT",
	tOTALWALL_FLOORANDCEILINGLOAD: "TOTAL WALL, FLOOR AND CEILING LOAD",
	vOLUME: "VOLUME",
	mISCELLANEOUSLOADS: "MISCELLANEOUS LOADS",
	tOTALMISCELLANEOUSLOAD: "TOTAL MISCELLANEOUS LOAD",
	aVERAGE: "AVERAGE",
	fORKLIFTS_TOWMOTORS: "FORKLIFTS/ TOW MOTORS",
	hEAVY: "HEAVY",
	lIGHT: "LIGHT",
	externalRh: "External Rh",
	externalTemp: "External Temp",
	heavy: "Heavy",
	internalRh: "Internal Rh",
	internalTemp: "Internal Temp",
	regular: "Regular",
	tOTALINFILTRATIONLOADESTIMATEDBYDOORSANDOPENINGS:
		"TOTAL INFILTRATION LOAD ESTIMATED BY DOORS AND OPENINGS",
	ventilation: "Ventilation",
	iNFILTRATIONLOAD_ESTIMATEDBYDOORSANDOPENINGS:
		"INFILTRATION LOAD : ESTIMATED BY DOORS AND OPENINGS",
	iNFILTRATIONLOAD_ESTIMATEDBYVENTILATION:
		"INFILTRATION LOAD : ESTIMATED BY VENTILATION",
	iNFILTRATIONLOAD_ESTIMATEDBYVOLUMEANDUSAGE:
		"INFILTRATION LOAD : ESTIMATED BY VOLUME AND USAGE",
	curtains: "Curtains",
	doorOrOpeningType: "Door or Opening Type",
	openTime: "Open Time",
	preparedBy: "Prepared By",
	preparedFor: "Prepared For",
	customerInformation: "Customer Information",
	job_ProjectName: "Job/Project Name",
	cOOLINGREQUIREMENTS: "COOLING REQUIREMENTS",
	designLoad: "Design Load",
	equipmentLoad: "Equipment Load",
	hours: "Hours",
	safetyLoad: "Safety Load",
	safetyFactor: "Safety Factor",
	capacityRequired: "Capacity Required",
	condenserAmbient: "Condenser Ambient",
	sYSTEMDESIGNSUMMARY: "SYSTEM DESIGN SUMMARY",
	sYSTEMEQUIPMENTSELECTION: "SYSTEM EQUIPMENT SELECTION",
	evaporator: "Evaporator",
	nOTSELECTED_: "NOT SELECTED!",
	airCooledCondenser: "Air Cooled Condenser",
	condenser: "Condenser",
	evaporatorTD: "Evaporator TD",
	fanLoad: "Fan Load",
	deleteSystem: "Delete System",
	duplicateSystem: "Duplicate System",
	editSystem: "Edit System",
	area_ZoneOrSystemName: "Area, Zone or System Name",
	areaDeletionRequested_: "Area deletion requested.",
	systemDetails: "System Details",
	equipmentCapacity: "Equipment Capacity",
	equipmentTD: "Equipment TD",
	design: "Design",
	miscellaneous: "Miscellaneous",
	products: "Products",
	safety: "Safety",
	openBoxDesigner: "Open Box Designer",
	sYSTEMSLOADSUMMARY: "SYSTEMS LOAD SUMMARY",
	importLoadCalculatorIDesignFile: "Import Load Calculator I Design File",
	importLoadCalculatorIIDesignFile: "Import Load Calculator II Design File",
	newCondenserDesign: "New Condenser Design",
	newFluidCoolerDesign: "New Fluid Cooler Design",
	newGlycolCoilDesign: "New Glycol Coil Design",
	newLoadCalculatorIIDesign: "New Load Calculator II Design",
	newSystemNamed: "New System Named",
	otherEquipment: "Other Equipment",
	safetyFactor___: "Safety Factor (%)",
	quantityPERCompressorUnit: "Quantity PER Compressor Unit",
	quantityPERCondensingUnit: "Quantity PER Condensing Unit",
	hourlyLoadRequired: "Hourly Load Required",
	suctionLineLoss: "Suction Line Loss",
	connectionCount: "Connection Count",
	pleaseSelectAndEditTheAppropriateSection_:
		"please select and edit the appropriate section.",
	toEnterOrEditDoorOrOpeningDetails_:
		"To enter or edit door or opening details,",
	clearAllSelections: "Clear All Selections",
	interiorTemperature: "Interior Temperature",
	areYouSureYouWantToSubmitThisMyEditOrder_:
		"Are you sure you want to submit this myEditOrder.",
	doYouWantToProcessThisChangeOrderAsAnOriginalMyEditOrder_:
		"Do you want to process this change order as an original myEditOrder.",
	ifYouSubmitThisOrder_YouAreOfficiallyTakingResponsibilityForTheMyEditOrder_:
		"If you submit this order, you are officially taking responsibility for the myEditOrder.",
	untilTheOrderIsProcessedByTheManufacturerYouMayStopThisMyEditOrder_:
		"Until the order is processed by the manufacturer you may stop this myEditOrder.",
	untilTheOrderIsProcessedByThePurchaserYouMayStopThisMyEditOrder_:
		"Until the order is processed by the purchaser you may stop this myEditOrder.",
	wARNING_YOUARENOTTHEORIGINATOROFTHISMyEditOrder_:
		"WARNING: YOU ARE NOT THE ORIGINATOR OF THIS myEditOrder.",
	youCanNoLongerStopThisMyEditOrder_:
		"You can no longer stop this myEditOrder.",
	youDoNotHaveOwnershipOfThisOrder_: "You do not have ownership of this Order.",
	testCloneCompare: "TestCloneCompare",
	modelNotLoaded_: "Model not loaded!",
	temperature_TooHigh_: "Temperature 1 too high!",
	temperature_TooLow_: "Temperature 1 too low!",
	noRatingFound_: "No Rating found!",
	temperature_IsTooHigh_: "Temperature 1 is too high!",
	temperature_IsTooLow_: "Temperature 1 is too low!",
	balancedCapacity: "Balanced Capacity",
	evap_TD: "Evap. TD",
	ambientTemperature_EvapTemperature_:
		"Ambient temperature < Evap temperature!",
	boxTemperature_EvapTemperature_: "Box temperature < Evap temperature!",
	condensingTemperature_EvapTemperature_:
		"Condensing temperature < Evap temperature!",
	evap: "Evap",
	evapTemperature: "Evap temperature",
	isInvalid_: "is invalid!",
	isMissing_: "is missing!",
	isOutOfRange_: "is out of range!",
	systemLoadIsInvalid_: "System load is invalid!",
	systelLoadNotGiven_: "Systel Load not given.",
	temperature_NotGiven_: "Temperature1 not given.",
	productName: "Product Name",
	ambientTemperature: "Ambient temperature",
	loadCalculationDesignHasErrors_: "Load Calculation Design Has Errors !",
	loadCalculationDesignHasWarnings_: "Load Calculation Design Has Warnings !",
	equipmentSelectionIsIncomplete_: "Equipment selection is incomplete!",
	systemIsNotBalanced_: "System is not balanced !",
	designHasErrors_: "Design Has Errors!",
	designHasWarnings_: "Design Has Warnings!",
	loadCalculationSystemHasDesignErrors_:
		"Load Calculation System Has Design Errors !",
	loadCalculationSystemHasDesignWarnings_:
		"Load Calculation System Has Design Warnings !",
	testMail: "TestMail",
	oneOrMoreRequestedDatesAreInThePast_:
		"One or more requested dates are in the past!",
	shipmentDateErrorsFound_: "Shipment Date Errors Found!",
	reset: "Reset",
	currency: "Currency",
	edit_AddDoorsAndOpenings: "Edit/Add Doors And Openings",
	thePriceBookUsedForTheOpenChangeRequestHasExpired_:
		"The price book used for the open change request has expired.",
	thePriceBookUsedForThisOrderHasExpired_:
		"The price book used for this order has expired.",
	thePriceBooksUsedForThisOrderHaveExpired_:
		"The price books used for this order have expired.",
	alwaysOpenAreaOfWallLeadingToAnotherZone_:
		"Always open area of wall leading to another zone.",
	areaToBeExcludedFromWallDefinition_:
		"Area to be excluded from wall definition.",
	nonOpeningGlassWindow_: "Non opening glass window.",
	normallyClosedDoorForStaffOrGoodsTraffic_:
		"Normally closed door for staff or goods traffic.",
	roll_UpDoorOpenToOutsideEnvironmentOrOtherZone_:
		"Roll-Up door open to outside environment or other zone.",
	sectionOfWallWithDifferentInsulationOrExternalEnvironmentFromRestOfWall_:
		"Section of wall with different insulation or external environment from rest of wall.",
	pleaseSelectAnOpeningType_: "Please select an opening type.",
	lOADCALCULATION: "LOAD CALCULATION",
	reach_InSelfServeGlassDoors_: "Reach-In self serve glass doors.",
	roll_UpDoorDesignedToBeNormallyClosedOrSealedByTransportTrailer_:
		"Roll-Up door designed to be normally closed or sealed by transport trailer.",
	areYouSureYouWantToUseTheSeletedValue_:
		"Are you sure you want to use the seleted value?",
	theInfiltrationCalculatedForTheseIsGreaterThanTheVentilationInfiltration_:
		"The infiltration calculated for these is greater than the ventilation infiltration.",
	theInfiltrationCalculatedForTheseIsGreaterThanTheVolumeEstimatedInfiltration_:
		"The infiltration calculated for these is greater than the volume estimated infiltration.",
	valueWarning_: "Value Warning!",
	youHaveDoorsAndOpeningsDefinedInTheBoxDesign_:
		"You have doors and openings defined in the box design.",
	conductionLoad: "Conduction Load",
	iNFILTRATIONLOADPERUNIT: "INFILTRATION LOAD PER UNIT",
	iNSULATIONLOADPERUNIT: "INSULATION LOAD PER UNIT",
	indoor: "Indoor",
	outdoor: "Outdoor",
	outdoorCool: "Outdoor Cool",
	outdoorWarm: "Outdoor Warm",
	outdoors_ColdClimate_: "Outdoors (Cold Climate)",
	eSTIMATEDBYVENTILATION: "ESTIMATED BY VENTILATION",
	eSTIMATEDBYBOXVOLUME_USAGE: "ESTIMATED BY BOX VOLUME / USAGE",
	eSTIMATEDBYDOORSANDOPENINGS: "ESTIMATED BY DOORS AND OPENINGS",
	ventilationInfiltrationEstimate: "Ventilation Infiltration Estimate",
	volume_UsageInfiltrationEstimate: "Volume/Usage Infiltration Estimate",
	infiltrationMethodChosenIsNotTheMaximumLoadEstimateEntered_:
		"Infiltration method chosen is not the maximum load estimate entered.",
	calculationMethod: "Calculation Method",
	useMaximum: "Use maximum",
	lOADCONNECTIONSCONTINUED: "LOAD CONNECTIONS CONTINUED",
	insulationLayerIsRecommendedForSlabOnGradeFreezers_:
		"Insulation layer is recommended for slab on grade freezers !",
	aDDNEWSYSTEMUSING: "ADD NEW SYSTEM USING",
	iMPORTFROMFILE: "IMPORT FROM FILE",
	miscelaneousUnits: "Miscelaneous Units",
	importSystemsFromALoadCalculatorIIFile_:
		"Import systems from a load calculator II file.",
	ballyBoxWizardFile: "Bally Box Wizard File",
	importSystemsFromABallyBoxWizardFile_:
		"Import systems from a Bally Box Wizard file.",
	importSystemsFromAKeepriteCalc_RiteFile_:
		"Import systems from a Keeprite Calc-Rite file.",
	importSystemsFromATretonCalculoadFile_:
		"Import systems from a Treton Calcuload file.",
	keepriteCalc_RiteFile: "Keeprite Calc-Rite File",
	tretonCalculoadFile: "Treton Calcuload File",
	abortAddingASystem_: "Abort adding a system.",
	saveSystemAsTemplate: "Save system As Template",
	sTANDARDAPPLICATIONS: "STANDARD APPLICATIONS",
	uSERTEMPLATES: "USER TEMPLATES",
	rH_TD: "RH / TD",
	createSystem_PleaseSelectApplicationTypeOrTemplate:
		"Create System : Please Select Application Type Or Template",
	fACTORYTEMPLATES: "FACTORY TEMPLATES",
	saveAsTemplate: "Save As Template",
	change: "Change",
	evapTDIsTooHighForTheSelectedApplication_:
		"Evap TD is too high for the selected application !",
	evapTDIsTooLowForTheSelectedApplication_:
		"Evap TD is too low for the selected application !",
	theInternalTemperatureIsTooHighForTheSelectedApplication_:
		"The internal temperature is too high for the selected application !",
	theInternalTemperatureIsTooLowForTheSelectedApplication_:
		"The internal temperature is too low for the selected application !",
	newMulti_SystemJob: "New Multi-System Job",
	betaVersionDesignDetected_: "Beta version design detected.",
	doYouWantToUpdateTheDesignToTheNewerVersion_:
		"Do you want to update the design to the newer version.",
	newerCalculatorVersionDetected_: "Newer calculator version detected.",
	pleaseVerifyYourDesign_: "Please verify your design.",
	theLoadCalculationHASCHANGEDWithTheNewerVersion_:
		"The load calculation HAS CHANGED with the newer version.",
	theLoadCalculationHASNOTChangedWithTheNewerVersion_:
		"The load calculation HAS NOT changed with the newer version.",
	theLoadCalculationsMayHaveChangedSinceYouFirstCreatedThisJob_:
		"The load calculations may have changed since you first created this job.",
	thisDesignWasCreatedWithTheBetaVersionOfTheSoftware_:
		"This design was created with the Beta version of the software.",
	export_CSV: "Export_CSV",
	loadCalculation: "Load Calculation",
	optionDescriptionList: "Option Description List",
	pleaseCheckYourEquipmentSelection_: "Please check your equipment selection.",
	ifThisProblemPersists_PleaseContactTheFactory_:
		"If this problem persists, please contact the factory.",
	selectedEquipmentNoLongerBalances_: "Selected Equipment No Longer Balances!",
	thisDesignWasCreatedWithAnEarlierVersionOfThisSoftware_:
		"This design was created with an earlier version of this software.",
	newerVersionDesignDetected_: "Newer version design detected !",
	pleaseRestartYourSoftwareToUpdateYourSystemAndDataFiles_:
		"Please restart your software to update your system and data files.",
	lade: "Lade",
	pending: "Pending",
	outdoors_ColdClimate___: "Outdoors (Cold Climate) ~  ~",
	loadingServicePartCatalog_: "Loading Service Part Catalog.",
	clearFilter: "Clear Filter",
	category: "Category",
	partName_No: "Part Name / No",
	qTTY: "QTTY",
	modelNumber_WhereUsed: "Model Number / Where Used",
	equipmentDetails: "Equipment Details",
	leadTimeChangesFound_: "lead time changes found.",
	leadTimesChangedOn: "Lead times changed on",
	leadTimesChangedOnOneRevision_: "Lead times changed on one revision.",
	noLeadTimeChangesFound_: "No lead time changes found.",
	noLeadTimeChangesOccurred_: "No lead time changes occurred.",
	oneLeadTimeChangeFound_: "One lead time change found.",
	copyShippingDateInformationTo: "Copy shipping date information to",
	forComplexFloorsWeSuggestYouUseTheFloorPlanner:
		"For complex floors we suggest you use the floor planner",
	onlyOneFloorSectionMayBeAddedWithThisTool_:
		"Only one floor section may be added with this tool.",
	sorry: "Sorry",
	orEnterTheTotalFloorAreaAndPerimeterValuesInTheExistingFloorDefinition_:
		"or enter the total floor area and perimeter values in the existing floor definition.",
	boxDesign: "Box Design",
	canNotSubmit_: "Can not submit!",
	invalidEmail_Format_: "Invalid Email 1 Format!",
	OrdersWillBeMarkedAsShipped_: " orders will be marked as shipped!",
	OrdersWillBeMarkedClosed_: " orders will be marked closed!",
	calendarDays: "calendar days",
	leadTimeCalendarDays: "Lead Time Calendar Days",
	leadTimeAddWeeks: "Lead Time Add Weeks",
	leadTimeWeeksAdded: "Lead Time Weeks Added",
	pleaseContactFactory: "Please contact factory",
	wARNING_SUBMITTEDQUOTENOTFOUND_: "WARNING! SUBMITTED QUOTE NOT FOUND.",
	CalandarDays_: " Calandar Days !",
	sorry_: "Sorry!",
	youCanNotDeleteTheOrderedQuoteRevision_:
		"You can not delete the ordered quote revision?",
	deleteCurrentQuoteRevision: "Delete Current Quote Revision",
	restoreCurrentQuoteRevisionToLastSavePoint:
		"Restore Current Quote Revision To Last Save Point",
	canNotRestoreTheOrderedQuoteRevision_:
		"Can not restore the ordered quote revision?",
	thePackage: "The package ",
	standardFeatures: "STANDARD FEATURES",
	additionalOptionsAndAccessories: "ADDITIONAL OPTIONS AND ACCESSORIES"
};
