import React from "react";

import IFormState from "../models/interfaces/IFormState";
import IOpening from "../models/interfaces/IOpening";
import ISection from "../models/interfaces/ISection";

import { measure } from "../types/measure";

import useOpeningDetailsForm from "./useOpeningDetailsForm";

const useOpeningDetailsReducer = (
    section: ISection,
    boxInteriorTemperature: measure,
    boxInteriorHumidity: measure
) => {

    const openingDetailsForm = useOpeningDetailsForm();

    const reducer = (state: IFormState<IOpening>, action: any) => {        
        if (action.type === "set-initial-state") {
            return openingDetailsForm.setInitialState(
                action.formValues,
                section.exteriorTemperature,
                section.exteriorHumidity,
                section.dimension1,
                section.dimension2,
                boxInteriorTemperature,
                boxInteriorHumidity);
        }
        if (action.type === "recalculate-form") {
            return openingDetailsForm.recalculateOpening(
                state,
                { [action.fieldName]: action.fieldValue },
                section.exteriorTemperature,
                section.exteriorHumidity,
                section.dimension1,
                section.dimension2,
                boxInteriorTemperature,
                boxInteriorHumidity);
        }
        if (action.type === "change-value") {
            return {
                ...state,
                actualValues: {
                    ...state.actualValues,
                    [action.fieldName]: action.fieldValue
                }
            };
        }
        return state;
    };

    const [formState, dispatch] = React.useReducer(reducer, openingDetailsForm.setInitialState(
        undefined,
        section.exteriorTemperature,
        section.exteriorHumidity,
        section.dimension1,
        section.dimension2,
        boxInteriorTemperature,
        boxInteriorHumidity));

    return {
        formState,
        dispatch
    };
};

export default useOpeningDetailsReducer;
