import * as React from "react";

import Button from "../helpers/Button";
import ConstructionLayerModal from "./ConstructionLayerModal";
import Grid from "../helpers/Grid";

import IConstructionLayer from "../../models/interfaces/IConstructionLayer";
import IMessage from "../../models/interfaces/IMessage";

import useTranslationContext from "../../hooks/useTranslationContext";
import UnitOfMeasure from "../../models/helpers/UnitOfMeasure";

interface ConstructionLayersGridProps {
    layers: IConstructionLayer[],
    onGridUpdated: (layers: IConstructionLayer[]) => void;
}

const ConstructionLayersGrid: React.FunctionComponent<ConstructionLayersGridProps> = (
	props: ConstructionLayersGridProps
) => {

    const { translations } = useTranslationContext();

    const [showConstructionLayerPopup, setShowConstructionLayerPopup] = React.useState(false);
    const [constructionLayers, setConstructionLayers] = React.useState(props.layers);
    const [selectedRow, setSelectedRow] = React.useState(-1);
    const [layerToEdit, setLayerToEdit] = React.useState(undefined as IConstructionLayer | undefined);

    const allowDeleteLayer = (selectedRow > -1) 
        && constructionLayers[selectedRow] 
        && !constructionLayers[selectedRow].isBoundaryInteriorLayer
        && !constructionLayers[selectedRow].isBoundaryExteriorLayer;

    const allowMoveUpLayer = (selectedRow > 1)
        && (selectedRow < constructionLayers.length - 1) 
        && constructionLayers[selectedRow] 
        && !constructionLayers[selectedRow].isBoundaryInteriorLayer
        && !constructionLayers[selectedRow].isBoundaryExteriorLayer;

    const allowMoveDownLayer = (selectedRow > 0) 
        && (selectedRow < constructionLayers.length - 2) 
        && constructionLayers[selectedRow]
        && !constructionLayers[selectedRow].isBoundaryInteriorLayer
        && !constructionLayers[selectedRow].isBoundaryExteriorLayer;

    React.useEffect(() => {
        setConstructionLayers(props.layers);
    }, [props.layers]);

    React.useEffect(() => {
        const triggerGridUpdated = () => {
            if (props.onGridUpdated) {
                props.onGridUpdated(constructionLayers);
            }
        }
        triggerGridUpdated();
    }, [constructionLayers]);

    const openAddLayerPopup = () => {
        setLayerToEdit(undefined);
        setShowConstructionLayerPopup(true);
    };

    const openEditLayerPopup = () => {
        if (selectedRow > -1) {
            const selectedLayer = constructionLayers[selectedRow];
            setLayerToEdit(selectedLayer);
            setShowConstructionLayerPopup(true);
        }
    };

    const addLayer = (newLayer: IConstructionLayer) => { 
        setShowConstructionLayerPopup(false); 

        const layers = constructionLayers;
        layers.splice(layers.length - 1, 0, newLayer);
        setConstructionLayers([...layers]);

        setSelectedRow(constructionLayers.length - 2);
    };

    const updateLayer = (updatedLayer: IConstructionLayer) => { 
        setShowConstructionLayerPopup(false); 

        if (selectedRow > -1 && layerToEdit) {
            const layers = constructionLayers;
            layers[selectedRow] = updatedLayer;
            setConstructionLayers([...layers]);
        }
    };

    const deleteLayer = () => {
        if (allowDeleteLayer) {
            const layers = constructionLayers.filter((layer, index) => index !== selectedRow);
            setConstructionLayers([...layers]);
        }
    };

    const moveLayer = (currentIndex: number, newIndex: number) => {
        const layers = constructionLayers;
        const layer = layers.splice(currentIndex, 1)[0];
        layers.splice(newIndex, 0, layer);
        return layers;
    };

    const handleRowClick = (rowIndex: number) => {
        setSelectedRow(rowIndex);        
    };

    const handleRowDoubleClick = () => {
        openEditLayerPopup();
    };

    const handleAddButtonClick = () => {
        openAddLayerPopup();
    };

    const handleEditButtonClick = () => {
        openEditLayerPopup();
    };

    const handleDeleteButtonClick = () => { 
        deleteLayer();
    };

    const handleMoveUpButtonClick = () => { 
        if (allowMoveUpLayer) {
            const newPosition = selectedRow - 1;
            const layers = moveLayer(selectedRow, newPosition);
            setConstructionLayers([...layers]);
            setSelectedRow(newPosition);
        }
    };

    const handleMoveDownButtonClick = () => { 
        if (allowMoveDownLayer) {
            const newPosition = selectedRow + 1;
            const layers = moveLayer(selectedRow, newPosition)
            setConstructionLayers([...layers]);
            setSelectedRow(newPosition);
        }
    };

    const handleConstructionLayerOkClick = (layer: IConstructionLayer, isNew: boolean) => {
        if (isNew) {
            addLayer(layer);
        }
        else {
            updateLayer(layer);
        }
    };

    const layersGridView = constructionLayers.map((layer, index) => {
        const gridData: { [key: string]: any } = {};
        gridData[translations.layer] = index + 1;
        gridData[translations.material] = layer.name;
        gridData[translations.thickness] = UnitOfMeasure.getDimensionDisplayValue(layer.thickness, 3);
        gridData[translations.r_Value] = UnitOfMeasure.getDisplayValue(layer.rValueEffective, 2);
        gridData.errors = layer.errors;

        return gridData;
    });
    
    return (
        <>
            <div id="construction-layers-grid">
                <div>
                    <div className="label is-small header">
                        {translations.constructionLayers}
                    </div>                    
                    <Grid 
                        dataSource={layersGridView} 
                        height={200} 
                        selectedRow={selectedRow}
                        onRowClick={handleRowClick}
                        onRowDoubleClick={handleRowDoubleClick}
                    />
                </div>
                <div className="block">
                    <div className="field is-grouped">
                        <Button
                            name="add-layer"
                            label={translations.insertLayer}
                            className="button is-light is-small is-fullwidth"
                            onClick={handleAddButtonClick}
                        />
                        <Button
                            name="delete-layer"
                            label={translations.deleteLayer}
                            className="button is-light is-small is-fullwidth"
                            onClick={handleDeleteButtonClick}
                            disabled={!allowDeleteLayer}
                        />
                        <Button
                            name="moveup-layer"
                            label={translations.moveUp}
                            className="button is-light is-small is-fullwidth"
                            onClick={handleMoveUpButtonClick}
                            disabled={!allowMoveUpLayer}
                        />
                        <Button
                            name="movedown-layer"
                            label={translations.moveDown}
                            className="button is-light is-small is-fullwidth"
                            onClick={handleMoveDownButtonClick}
                            disabled={!allowMoveDownLayer}
                        />
                        <Button
                            name="edit-layer"
                            label={translations.editLayer}
                            className="button is-light is-small is-fullwidth"
                            onClick={handleEditButtonClick}
                        />
                    </div>
                </div>
            </div>
            <ConstructionLayerModal
                onOkClick={handleConstructionLayerOkClick}
                onCancelClick={() => { setShowConstructionLayerPopup(false); }}
                show={showConstructionLayerPopup}
                layerToEdit={layerToEdit}
            />
        </>
    );
};

export default ConstructionLayersGrid;
