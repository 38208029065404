import * as React from "react";

export interface TextBoxProps {
    name: string;    
    className?: string;
    value?: string;
    disabled?: boolean;
    readOnly?: boolean;
    onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
}

const TextBox: React.FunctionComponent<TextBoxProps> = ({
    name,
    className,
    value,
    disabled,
    readOnly,
    onChange
}: TextBoxProps) => {

    return (
        <input
            type="text"
            name={name}
            className={`input is-small ${className ?? ""}`}
            value={value}
            disabled={disabled}
            readOnly={readOnly}
            onChange={onChange}
        />
    );
};

export default TextBox;
