import * as React from "react";

import ApplicationContext from "../context/ApplicationContext";

import { measure, hasValue, getNumericValue, roundOff } from "../types/measure";

import IAirProperty from "../models/interfaces/IAirProperty";
import ICurtain from "../models/interfaces/ICurtain";

const useApplicationContext = () => {
    const { appData, airProperties, curtains, isAppDataLoaded } = React.useContext(ApplicationContext);

    const getAirPropertyByTemperature = React.useCallback((
        temperature: measure
     ) => {

        const setTemperature = () => {
            if (!hasValue(temperature)) {
                return undefined;
            }

            const temp = getNumericValue(temperature);

            const t1 = getNumericValue(roundOff(temperature, 0));
            const t2 = temp > t1 ? t1 + 1 : t1;
        
            const t1Record = airProperties.find(a => a.temp === t1);
            const t2Record = airProperties.find(a => a.temp === t2);
        
            let ws;
            let ha;
            let has;
        
            if (t1Record && t2Record) {
                let v1 = t1Record.ws;
                let v2 = t2Record.ws;
                let v = v1 + (v2 - v1) * (temp - t1);
                ws = v;
        
                v1 = t1Record.ha;
                v2 = t2Record.ha;
                v = v1 + (v2 - v1) * (temp - t1);
                ha = v;
        
                v1 = t1Record.has;
                v2 = t2Record.has;
                v = v1 + (v2 - v1) * (temp - t1);
                has = v;
            } 
            else if (t1Record) {
                ws = t1Record.ws;
                ha = t1Record.ha;
                has = t1Record.has;
            } 
            else if (t2Record) {
                ws = t2Record.ws;
                ha = t2Record.ha;
                has = t2Record.has;
            }
            else {
                return undefined;
            }

            const airProperty: IAirProperty = {
                temp,
                ws,
                ha,
                has
            };
        
            return airProperty;
        };

        return setTemperature();
    }, [airProperties]);

    const getCurtain = React.useCallback((
        id: string
    ) => {
        const defaultCurtain: ICurtain = {
            id: 0,
            name: "None",
            effectiveness: 100,
            rValue: 0
        };

        return curtains.find(c => c.id.toString() === id) ?? defaultCurtain;
    }, [curtains]);

    return { 
        appData, 
        isAppDataLoaded,
        curtains,
        getAirPropertyByTemperature,
        getCurtain
    };
};

export default useApplicationContext;
