import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import Button from "./Button";
import Modal from "./Modal";

import useTranslationContext from "../../hooks/useTranslationContext";

export interface ConfirmationMessageProps {
	title: string;
	message: JSX.Element;
	show: boolean;
	onYesClick: any;
	onNoClick: any;
	onCancelClick: any;
}

const ConfirmationMessage: React.FunctionComponent<ConfirmationMessageProps> = (
	props: ConfirmationMessageProps
) => {

	const { translations } = useTranslationContext();

	const modalBody = (
		<div className="content confirmation-message">
			<div className="media">
				<div className="media-left has-text-warning">
					<FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
				</div>
				<div className="media-content">{props.message}</div>
			</div>
		</div>
	);

	const modalFooter = (
		<>
			<Button
				name="confirm-yes"
                label={translations.yes ?? "Yes"}
                className="button is-link"
				onClick={props.onYesClick}
			/>
            <Button
				name="confirm-no"
                label="No"
                className="button is-primary"
                onClick={props.onNoClick}
            />
		</>
	);

	return (
		<Modal 
            show={props.show} 
            title={props.title}
            body={modalBody}
            footer={modalFooter}
			onCancelClick={props.onCancelClick}
		/>
	);
};

export default ConfirmationMessage;
