import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import Button from "../helpers/Button";
import EquipmentFilters from "../EquipmentFilters/EquipmentFilters";
import EquipmentBalance from "../EquipmentBalance/EquipmentBalance";
import EquipmentUnit from "../EquipmentUnit/EquipmentUnit";
import FormField from "../helpers/FormField";
import Modal from "../helpers/Modal";
import NumericTextBox from "../helpers/NumericTextBox";

import IFormState from "../../models/interfaces/IFormState";
import ISystem from "../../models/interfaces/ISystem";

import useTranslationContext from "../../hooks/useTranslationContext";

import "./Equipment.css";
import ConfirmationMessage from "../helpers/ConfirmationMessage";
import StorageFacade from "../../services/StorageFacade";

const isCUAmbientTempVisible = (
	equipmentType: string | null
): boolean => {
	return equipmentType === "Condensing Units-Air";
};

const isCondensingTempVisible = (
	equipmentType: string | null
): boolean => {
	return equipmentType === "Compressor Unit";
};

const emptyUnitAttributes = {
    taggingId: "",
    tagging: "",
    notes: ""
};

interface EquipmentComponentProps {
    isEquipmentOnly: boolean;
    formState: IFormState<ISystem>;
    onInputChange: any;
    onDropDownChange: any;
    onCheckboxChange: any;
    onMeasurementChange: any;
    onValidateSystemField: any;
    onValidateEquipmentField: any;
    onToggleModal: any;
    onClearSelections: any;
    onShowSystemChargeButtonClick: any;
    onCondensorEquipmentSelected: any;
    onEvaporatorEquipmentSelected: any;
    onCondenserUnitOptionsSelected: any;
    onEvaporatorOptionsSelected: any;
    onCondenserAttributeChanged: any;
    onEvaporatorAttributeChanged: any;
    onPrintPreviewClick: any;
    onCancelEquipmentClick: any;
    onSubmitEquipmentClick: any;
}

const EquipmentComponent: React.FunctionComponent<EquipmentComponentProps> = (
    props: EquipmentComponentProps
) => {

    const [showCancelConfirmation, setShowCancelConfirmation] = React.useState(false);
    const [allowedSpecialModels, setAllowedSpecialModels] = React.useState({SpecialModels: ""});

    const { translations } = useTranslationContext();

    let condenserLabel = "";
    let evaporatorLabel = "";
    if (props.formState.actualValues.equipmentSelection.isCondenserPrimary != null) {
        condenserLabel = props.formState.actualValues.equipmentSelection.isCondenserPrimary === true 
            ? "(Primary)" 
            : "(Matched)";
        evaporatorLabel = props.formState.actualValues.equipmentSelection.isCondenserPrimary === false 
            ? "(Primary)" 
            : "(Matched)";
    }

    const cancelConfirmationMessage = {
		title: "Confirmation",
		message: <div>Are you sure you want to cancel?</div>,
		onYesClick: () => {
            setShowCancelConfirmation(false);
            if (props.onCancelEquipmentClick) {
                props.onCancelEquipmentClick();
            }
        },
		onNoClick: () => {
            setShowCancelConfirmation(false);
        }
    };

    const showSccbutton = (props.formState.actualValues.equipmentSelection.selectedCondenserUnit?.partNumber ?? "") != ""
        && (props.formState.actualValues.equipmentSelection.selectedEvaporator?.partNumber ?? "") != "";

    React.useEffect(() => {
        const spModels = StorageFacade.AllowedSpecialModels;
        if (spModels && spModels.SpeicalModels !== "") setAllowedSpecialModels(spModels);
    }, []);

    return (
        <div id="equipment">
            <h1 className="title is-5">{translations.modelSelector}</h1>
            <EquipmentFilters
                isEquipmentOnly={props.isEquipmentOnly}
                requireAwef={props.formState.actualValues.requireAwef}
                formState={props.formState}
                isCUAmbientTempVisible={isCUAmbientTempVisible(props.formState.actualValues.equipmentSelection.equipmentType)}
                isCondensingTempVisible={isCondensingTempVisible(props.formState.actualValues.equipmentSelection.equipmentType)}
                onDropDownChange={props.onDropDownChange}
                onCheckboxChange={props.onCheckboxChange}
                onMeasurementChange={props.onMeasurementChange}
                onValidateSystemField={props.onValidateSystemField}
                onValidateEquipmentField={props.onValidateEquipmentField}
            />
            <div className="columns">
                <div className="column is-half">
                    <label className="title is-6">
                        {translations.cONDENSINGUNITSELECTION}
                        <span>
                            &nbsp;
                            {condenserLabel}
                        </span>
                    </label>
                    <div className="box">
                        <div className="columns quantities">
                            <div className="column">
                                <FormField label={translations.quantity}>
                                    <NumericTextBox 
                                        name="condenserQuantity"
                                        value={props.formState.actualValues.equipmentSelection.condenserQuantity}
                                        onChange={props.onInputChange}
                                        min="1"
                                        className="width80"
                                    />
                                </FormField>
                            </div>
                            <div className="column">
                                <FormField label={translations.numberOfUnits}>
                                    <NumericTextBox 
                                        name="condenserUnitCount"
                                        value={props.formState.actualValues.equipmentSelection.condenserUnitCount}
                                        className="is-static width80"
                                        readOnly
                                    />
                                </FormField>
                            </div>
                        </div>
                        <EquipmentUnit
                            equipmentUnitKey="condensing-unit"
                            isPrimary={
                                props.formState.actualValues.equipmentSelection.isCondenserPrimary == null
                                    ? null
                                    : props.formState.actualValues.equipmentSelection.isCondenserPrimary === true
                            }
                            isPrimaryReloaded={props.formState.actualValues.equipmentSelection.isPrimaryReloaded}
                            isMatchedReloaded={props.formState.actualValues.equipmentSelection.isMatchedReloaded}
                            requireAwef={props.formState.actualValues.requireAwef}
                            divisionId={props.formState.actualValues.equipmentSelection.divisionId}
                            equipmentType={props.formState.actualValues.equipmentSelection.equipmentType ?? ""}
                            interiorTemperature={props.formState.actualValues.interiorTemperature}
                            capacityMin={-3}
                            capacityMax={50}
                            capacityRequired={props.formState.actualValues.capacityRequired}
                            refrigerant={props.formState.actualValues.equipmentSelection.refrigerant ?? -1}
                            siteLineFrequency={props.formState.actualValues.equipmentSelection.siteLineFrequency ?? -1}
                            condensingUnitAmbientTemperature={
                                isCUAmbientTempVisible(props.formState.actualValues.equipmentSelection.equipmentType)
                                    ? props.formState.actualValues.equipmentSelection.condensingUnitAmbientTemperature
                                    : null
                            }
                            condensingTemperature={
                                isCondensingTempVisible(props.formState.actualValues.equipmentSelection.equipmentType)
                                    ? props.formState.actualValues.equipmentSelection.condensingTemperature
                                    : null
                            }
                            designTd={props.formState.actualValues.equipmentSelection.designTd}
                            suctionLineLoss={props.formState.actualValues.equipmentSelection.suctionLineLoss}
                            tdPlus={props.formState.actualValues.equipmentSelection.tdPlus}
                            tdMinus={props.formState.actualValues.equipmentSelection.tdMinus}
                            condenserCount={props.formState.actualValues.equipmentSelection.condenserUnitCount}
                            evaporatorCount={props.formState.actualValues.equipmentSelection.evaporatorUnitCount}
                            modelIdFilter={props.formState.actualValues.equipmentSelection.condenserModelIdFilter}
                            class9IdFilter={props.formState.actualValues.equipmentSelection.condenserClass9IdFilter}
                            selectedUnitItem={props.formState.actualValues.equipmentSelection.selectedCondenserUnit}
                            selectedOptions={props.formState.actualValues.equipmentSelection.selectedCondenserOptionList}
                            unitAttributes={props.formState.actualValues.equipmentSelection.selectedCondenserAttributes ?? emptyUnitAttributes}
                            specialModels = {allowedSpecialModels.SpecialModels}
                            onEquipmentSelected={props.onCondensorEquipmentSelected}
                            onOptionsSelected={props.onCondenserUnitOptionsSelected}
                            onAttributeChanged={props.onCondenserAttributeChanged}
                        />
                    </div>
                </div>
                <div className="column is-half">
                    <label className="title is-6">
                        {translations.eVAPORATORUNITSELECTION}
                        <span>
                            &nbsp;
                            {evaporatorLabel}
                        </span>
                    </label>
                    <div className="box">
                        <div className="columns quantities">
                            <div className="column">
                                <FormField label={translations.quantityPERCondensingUnit}>
                                    <NumericTextBox 
                                        name="evaporatorQuantity"
                                        value={props.formState.actualValues.equipmentSelection.evaporatorQuantity}
                                        onChange={props.onInputChange}
                                        min="1"
                                        className="width80"
                                    />
                                </FormField>
                            </div>
                            <div className="column">
                                <FormField label={translations.numberOfUnits}>
                                    <NumericTextBox 
                                        name="evaporatorUnitCount"
                                        value={props.formState.actualValues.equipmentSelection.evaporatorUnitCount}
                                        className="is-static width80"
                                        readOnly
                                    />
                                </FormField>
                            </div>
                        </div>
                        <EquipmentUnit
                            equipmentUnitKey="evaporator-unit"
                            isPrimary={
                                props.formState.actualValues.equipmentSelection.isCondenserPrimary == null
                                    ? null
                                    : props.formState.actualValues.equipmentSelection.isCondenserPrimary === false
                            }
                            isPrimaryReloaded={props.formState.actualValues.equipmentSelection.isPrimaryReloaded}
                            isMatchedReloaded={props.formState.actualValues.equipmentSelection.isMatchedReloaded}
                            requireAwef={props.formState.actualValues.requireAwef}
                            divisionId={props.formState.actualValues.equipmentSelection.divisionId}
                            equipmentType="Evaporators"
                            interiorTemperature={props.formState.actualValues.interiorTemperature}
                            capacityMin={-3}
                            capacityMax={50}
                            capacityRequired={props.formState.actualValues.capacityRequired}
                            refrigerant={props.formState.actualValues.equipmentSelection.refrigerant ?? -1}
                            siteLineFrequency={props.formState.actualValues.equipmentSelection.siteLineFrequency ?? -1}
                            condensingUnitAmbientTemperature={null}
                            condensingTemperature={null}
                            designTd={props.formState.actualValues.equipmentSelection.designTd}
                            suctionLineLoss={props.formState.actualValues.equipmentSelection.suctionLineLoss}
                            tdPlus={props.formState.actualValues.equipmentSelection.tdPlus}
                            tdMinus={props.formState.actualValues.equipmentSelection.tdMinus}
                            condenserCount={props.formState.actualValues.equipmentSelection.condenserUnitCount}
                            evaporatorCount={props.formState.actualValues.equipmentSelection.evaporatorUnitCount}
                            modelIdFilter={props.formState.actualValues.equipmentSelection.evaporatorModelIdFilter}
                            class9IdFilter={props.formState.actualValues.equipmentSelection.evaporatorClass9IdFilter}
                            selectedUnitItem={props.formState.actualValues.equipmentSelection.selectedEvaporator}
                            selectedOptions={props.formState.actualValues.equipmentSelection.selectedEvaporatorOptionList}
                            unitAttributes={props.formState.actualValues.equipmentSelection.selectedEvaporatorAttributes ?? emptyUnitAttributes}
                            specialModels = {allowedSpecialModels.SpecialModels}
                            onEquipmentSelected={props.onEvaporatorEquipmentSelected}
                            onOptionsSelected={props.onEvaporatorOptionsSelected}                            
                            onAttributeChanged={props.onEvaporatorAttributeChanged}
                        />
                    </div>
                </div>
            </div>
            <EquipmentBalance equipmentSelection={props.formState.actualValues.equipmentSelection} />
            <div className="level block">
                <div className="level-left">
                    <div className="level-item">
                        <Button
                            name="cancel-equipment"
                            label={translations.cancel}
                            className="button is-primary"
                            onClick={() => { setShowCancelConfirmation(true); }}
                        />
                    </div>
                    <div className="level-item">
                        <Button
                            name="clear-equipment"
                            label={translations.clearAllSelections}
                            className="button is-primary"
                            onClick={props.onClearSelections}
                        />
                    </div>
                    <div className="level-item">
                        <Button
                            name="show-systemcharge"
                            label={translations.calculateSystemCharge}
                            className="button is-primary"
                            onClick={props.onShowSystemChargeButtonClick}
                            disabled={!showSccbutton}
                        />
                    </div>
                </div>
                <div className="level-right">
                    <div className="level-item">
                        <Button
                            name="submit-equipment"
                            label={translations.oK}
                            className="button is-link"
                            onClick={props.onSubmitEquipmentClick}
                        />
                    </div>
                </div>
            </div>
            <ConfirmationMessage
                title={cancelConfirmationMessage.title}
                message={cancelConfirmationMessage.message}
                onYesClick={cancelConfirmationMessage.onYesClick}
                onNoClick={cancelConfirmationMessage.onNoClick}
                onCancelClick={cancelConfirmationMessage.onNoClick}
                show={showCancelConfirmation}
            />
        </div>
    );
};

export default EquipmentComponent;