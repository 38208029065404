/* eslint-disable prefer-template */
import * as React from "react";
import createPersistedState from "use-persisted-state";

const STORAGE_KEY = "APP_VERSION";

const defaultProps = {
	auto: false,
	storageKey: STORAGE_KEY,
	basePath: "",
};

type OwnProps = {
	children?: any;
} & typeof defaultProps;

const useClearCache = (props?: OwnProps) => {
	const { auto, storageKey, basePath } = {
		...defaultProps,
		...props,
	};
	const [loading, setLoading] = React.useState(true);
	const useAppVersionState = createPersistedState<string>(storageKey);
	const [appVersion, setAppVersion] = useAppVersionState("");
	const [isLatestVersion, setIsLatestVersion] = React.useState(true);
	const [latestVersion, setLatestVersion] = React.useState<string>(appVersion);

	async function setVersion(version: string) {
		await setAppVersion(version);
	}

	const emptyCacheStorage = async (version?: string) => {
		if ("caches" in window) {
			// Service worker cache should be cleared with caches.delete()
			caches.keys().then(names => {
				// eslint-disable-next-line no-restricted-syntax
				for (const name of names) caches.delete(name);
			});
		}

		// clear browser cache and reload page
		await setVersion(version || latestVersion).then(() =>
			window.location.reload()
		);
	};

	// Replace any last slash with an empty space
	const baseUrl = basePath.replace(/\/+$/, "") + "/meta.json";

	function fetchMeta() {
		try {
			fetch(baseUrl, {
				cache: "no-store",
			})
				.then(response => response.json())
				.then(meta => {
					const newVersion = meta.version;
					const currentVersion = appVersion;
					const isUpdated = newVersion === currentVersion;
					if (!isUpdated && !auto) {
						setLatestVersion(newVersion);
						setLoading(false);
						if (appVersion) {
							setIsLatestVersion(false);
						} else {
							setVersion(newVersion);
						}
					} else if (!isUpdated && auto) {
						emptyCacheStorage(newVersion);
					} else {
						setIsLatestVersion(true);
						setLoading(false);
					}
					console.log("Is app updated?", isUpdated);
				});
		} catch (err) {
			console.error(err);
		}
	}

	React.useEffect(() => {
		console.log("Checking if app is updated...", new Date());
		fetchMeta();
	}, []);

	return {
		loading,
		isLatestVersion,
		emptyCacheStorage,
		latestVersion,
	};
};

export default useClearCache;
