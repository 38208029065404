import * as React from "react";

import useTranslationContext from "../../hooks/useTranslationContext";

import UnitOfMeasure from "../../models/helpers/UnitOfMeasure";

import ConfigurationService from "../../services/ConfigurationService";
import StorageFacade from "../../services/StorageFacade";
import { measure } from "../../types/measure";

const table = {
	border: "1px solid black"
} as React.CSSProperties;

interface ACCDCondensorProps {
    sectionId: string;
	divisionId: number;    
    myName: string;
}

const ACCDCondensor: React.FunctionComponent<ACCDCondensorProps> = (props: ACCDCondensorProps) => {	
    // const [condensor, setCondensor] = React.useState({} as any);
    //const [filters, setFilter] = React.useState({} as any);
    //const [connections, setConnections] = React.useState({} as any);
    const { translations } = useTranslationContext();        

    const condensor = StorageFacade.CondenserToPrint;
    const connections = StorageFacade.CondenserConnections;
    const filters = StorageFacade.ACCFilters;

    React.useEffect(() => {
        if (condensor 
			&& condensor.MaxFeeds > 0 
			&& filters 
			&& connections
			&& (window as any).circuitsDiagram
			&& (window as any).circuitsDiagram.initializeCanvas){

			const canvasId = "circuitsDiagram";    
			const canvas = document.getElementById(canvasId);
			if (canvas){
				let cirString = getCircuitsString(condensor, connections);
				//console.log("circuitString", cirString);
							
				(window as any).circuitsDiagram.initializeCanvas(canvasId, condensor.MaxFeeds, (condensor.FansWide === 2));
				(window as any).circuitsDiagram.addCircuits(cirString);
				(window as any).circuitsDiagram.resizeCanvas(canvasId);
				(window as any).circuitsDiagram.attachToWindowResizeEvent(canvasId);
			}
        }
    }, [condensor, filters, connections]);

	function getCircuitsString(condenser: any, connections: any){		
		if (condensor.FansWide < 2){
			const circuits = connections.map((x: any)=>{
				return x.StartFeed + "," + x.EndFeed + "," + x.Guid + "," + x.IsSelected;
			});
			return circuits.join("|");
		}
		else{
			let circuitStrings: any = [];
			connections.forEach((connection:any) => {				
				const shouldSplit = shouldConnectionSplitIntoTwoCircuits(condenser, connection);				
				if (shouldSplit){
					circuitStrings.push(connection.StartFeed + "," + connection.EndFeed + "," + connection.Guid + "," + connection.IsSelected + "," + "YesSplitInHalf");
				}
				else{
					circuitStrings.push(connection.StartFeed + "," + connection.EndFeed + "," + connection.Guid + "," + connection.IsSelected + "," + "NoSplitInHalf");
				}				
			});
			return circuitStrings.join("|");
		}
	}

	function shouldConnectionSplitIntoTwoCircuits(condenser: any, connection: any){
		if (condenser.FansWide < 2) return false;

		// Split the circuit into two if it exceeds 60% of total available feeds (max feeds) for the selected model.
		// This rule only applies to double fan wide models.
		return (connection.EndFeed - connection.StartFeed + 1 > Math.ceil((condenser.MaxFeeds) * 0.6));
	}

	function getRatingPerTdDisplay(imperialValue: measure) {
		const ratingDisplay = UnitOfMeasure.getRatingDisplayValue(imperialValue);
		const ratingPerTdDisplay = ratingDisplay === undefined ? "" : UnitOfMeasure.getTemperatureRelativeDisplayValue(+ratingDisplay, 3);
		return ratingPerTdDisplay;
	}

    return(
        <div id={props.sectionId} style={{width:"1070px"}}>
            <table
				className="table is-bordered is-narrow is-fullwidth no-margin-table"
				style={table}
			>					
				<tbody>
					<tr>
						<td style={{ textAlign: "center", fontWeight: "bold" }} className="two-columns">								
							<table className="table is-borderless" style={{ width: "100%", border: "0" }}>
								<tbody>
									<tr>
										<td>
											<img
												src={ConfigurationService.getDivisionLogo(props.divisionId)}
												alt={ConfigurationService.getDivisionLogoAltString(props.divisionId)}
												style={{ width: "160px", marginTop: "10px" }}
											/>
										</td>
										<td>
											<div style={{fontSize: "13px", fontWeight:"bold"}}>NATIONAL REFERIGERATION AND</div> 
											<div style={{fontSize: "13px", fontWeight:"bold"}}>AIR CONDITIONING CANADA CORP.</div>
											<div style={{fontSize: "11px", fontWeight:"normal"}}>
												159 ROY BLVD. PO BOX 2020
											</div>
											<div style={{fontSize: "11px", fontWeight:"normal"}}>
												BRANTFORD ON
											</div>
											<div style={{fontSize: "11px", fontWeight:"normal"}}>
												CANADA N3T 5Y6
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
						<td style={{ textAlign: "center", fontWeight: "bold" }} className="two-columns">
							<div style={{ marginTop: "10px"}}>
								<div style={{fontSize: "21px", marginTop: "5px"}}>
									{condensor?.PartNumber}
								</div>
								<div style={{fontSize: "14px"}}>
									{condensor?.ModelType}
									<br />
                                    Condenser
								</div>
							</div>
						</td>
					</tr>						
				</tbody>
			</table>
			<table className="table is-narrow is-fullwidth no-margin-table" style={table}>
				<tbody>
					<tr>
						<td style={{ textAlign: "right", borderRight: "0"}}>PURCHASER:</td>
                        <td style={{ borderLeft: "0", borderRight: "0", textAlign: "left" }}><b>{filters.Company}</b></td>
						<td style={{ textAlign: "right", borderRight: "0", borderLeft: "0"}}>SUBMITTED BY:</td>
						<td style={{ borderLeft: "0", textAlign: "left"}}><b>{props.myName}</b></td>
					</tr>
					<tr>
						<td style={{ textAlign: "right", borderRight: "0"}}>PROJECT:</td>
                        <td style={{ borderLeft: "0", borderRight: "0", textAlign: "left" }}><b>{filters.Project}</b></td>
						<td style={{ textAlign: "right", borderRight: "0", borderLeft: "0"}}>DATE:</td>
						<td style={{ borderLeft: "0", textAlign: "left"}}><b>{new Date().toLocaleDateString ()}</b></td>
					</tr>
					<tr>
						<td style={{ textAlign: "right", borderRight: "0"}}>ORDER #:</td>
						<td style={{ borderLeft: "0", borderRight: "0", textAlign: "left"}}><b>-A00</b></td>
						<td style={{ textAlign: "right", borderRight: "0", borderLeft: "0"}}>ITEM #:</td>
                        <td style={{ borderLeft: "0", textAlign: "left" }}><b>0</b></td>
					</tr>
					<tr>
						<td style={{ textAlign: "right", borderRight: "0"}}>QUOTE #:</td>
                        <td style={{ borderLeft: "0", borderRight: "0", textAlign: "left" }}></td>
						<td style={{ textAlign: "right", borderRight: "0", borderLeft: "0"}}>ID #:</td>
						<td style={{ borderLeft: "0", textAlign: "left"}}></td>
					</tr>
					<tr>
						<td style={{ textAlign: "right", borderRight: "0"}}>PURCHASER&apos;S PO#:</td>
                        <td style={{ borderLeft: "0", borderRight: "0", textAlign: "left" }}></td>
						<td style={{ textAlign: "right", borderRight: "0", borderLeft: "0"}}>TAGGING:</td>
						<td style={{ borderLeft: "0", textAlign: "left"}}></td>
					</tr>
				</tbody>
			</table>
            <table className="table is-narrow is-fullwidth no-margin-table" style={table}>
				<tbody>
                    <tr>
                        <td>
                            <div style={{ textAlign: "center"}}><b>{translations.dESIGNDATA}</b></div>
                            <div className="level">
                                <div className="level-item has-text-centered">
                                    <div>
                                        <p className="heading">{translations.aMBIENT}</p>
                                        <p className="heading"><b>{filters.AmbientTemp}</b></p>
                                    </div>
                                </div>
                                <div className="level-item has-text-centered">
                                    <div>
                                        <p className="heading">{translations.aLTITUDE}</p>
                                        <p className="heading"><b>{filters.Altitude}</b></p>
                                    </div>
                                </div>
                                <div className="level-item has-text-centered">
                                    <div>
                                        <p className="heading">{translations.aLTITUDEFACTOR}</p>
                                        <p className="heading"><b>{filters.AltitudeFactor}</b></p>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>            
            <table className="table is-narrow is-fullwidth no-margin-table" style={table}>
				<tbody>
					<tr>
						<td style={{ textAlign: "right", borderWidth: "0"}}>{translations.type}:</td>
                        <td style={{ borderLeft: "0", borderWidth: "0", textAlign: "left" }}><b>{condensor.ModelType}</b></td>
						<td style={{ textAlign: "right", borderWidth: "0", borderLeft: "0"}}>{translations.fansLong}:</td>
						<td style={{ borderLeft: "0", borderWidth: "0", textAlign: "left" }}><b>{condensor.FansLong}</b></td>
					</tr>
					<tr>
						<td style={{ textAlign: "right", borderWidth: "0"}}>{translations.model}:</td>
                        <td style={{ borderLeft: "0", borderWidth: "0", textAlign: "left" }}><b>{condensor.PartNumber}</b></td>
						<td style={{ textAlign: "right", borderWidth: "0", borderLeft: "0"}}>{translations.fansWide}:</td>
						<td style={{ borderLeft: "0", borderWidth: "0", textAlign: "left" }}><b>{condensor.FansWide}</b></td>
					</tr>
					<tr>
						<td style={{ textAlign: "right", borderWidth: "0"}}>{translations.voltage}:</td>
						<td style={{ borderLeft: "0", borderWidth: "0", textAlign: "left"}}><b>{condensor.SiteVoltage}</b></td>
						<td style={{ textAlign: "right", borderWidth: "0", borderLeft: "0"}}>Rating:</td>
                        <td style={{ borderLeft: "0", borderWidth: "0", textAlign: "left" }}><b>{getRatingPerTdDisplay(condensor.CapacityPerDegreeTD * 1000)} {UnitOfMeasure.getRatingUnitLabel()}/{UnitOfMeasure.getTemperatureUnitLabel()}.TD</b></td>
					</tr>
					<tr>
						<td style={{ textAlign: "right", borderWidth: "0"}}>{translations.orientation}:</td>
                        <td style={{ borderLeft: "0", borderWidth: "0", textAlign: "left" }}>{condensor.AirFlow}</td>
						<td style={{ textAlign: "right", borderWidth: "0", borderLeft: "0"}}>{translations.feedsAvailable}:</td>
						<td style={{ borderLeft: "0", borderWidth: "0", textAlign: "left" }}>{condensor.MaxFeeds}</td>
					</tr>
					<tr>
						<td style={{ textAlign: "right", borderWidth: "0"}}>{translations.motorType}:</td>
                        <td style={{ borderLeft: "0", borderWidth: "0", textAlign: "left" }}>{condensor.Motor}</td>
						<td style={{ textAlign: "right", borderWidth: "0", borderLeft: "0"}}>{translations.spareFeeds}:</td>
						<td style={{ borderLeft: "0", borderWidth: "0", textAlign: "left" }}>{condensor.Spares}</td>
					</tr>
                    <tr>
						<td style={{ textAlign: "right", borderWidth: "0"}}>&nbsp;</td>
                        <td style={{ borderLeft: "0", borderWidth: "0", textAlign: "left" }}>&nbsp;</td>
						<td style={{ textAlign: "right", borderWidth: "0", borderLeft: "0"}}>{translations.totalHeatRejected}:</td>
						<td style={{ borderLeft: "0", borderWidth: "0", textAlign: "left" }}></td>
					</tr>
				</tbody>
			</table>            
            <table 
					className="table is-bordered is-narrow-cente is-fullwidth no-margin-table"
					style={table}
				>
					<thead>
						<tr>
							<th className="header2" colSpan={13}>
								<b>{translations.lOADCONNECTIONS}</b>
							</th>
						</tr>
						<tr>
                            <th></th>
							<th className="header2" ><b>{translations.description.toUpperCase()}</b></th>
							<th className="header2" ><b>{translations.sST}{UnitOfMeasure.getTemperatureUnitLabel()}</b></th>
							<th className="header2" ><b>{`${translations.sCT} ${UnitOfMeasure.getTemperatureUnitLabel()}`}</b></th>
							<th className="header2" ><b>{translations.compCapacity} {UnitOfMeasure.getRatingUnitLabel()}</b></th>
							<th className="header2" ><b>{translations.rEF}</b></th>
							<th className="header2" ><b>{`${translations.designTD} ${UnitOfMeasure.getTemperatureUnitLabel()}`}</b></th>
							<th className="header2" ><b>{translations.tHR} {UnitOfMeasure.getRatingUnitLabel()}</b></th>
                            <th className="header2" ><b>{translations.tHR} {UnitOfMeasure.getRatingUnitLabel()}/{UnitOfMeasure.getTemperatureUnitLabel()}.TD</b></th>
							<th className="header2" ><b># {translations.feeds}</b></th>
							<th className="header2" ><b>{translations.feeds}</b></th>
							<th className="header2" ><b>{`ACT ${UnitOfMeasure.getTemperatureUnitLabel()}`}</b></th>
                            <th className="header2" ><b>{`TD ${UnitOfMeasure.getTemperatureUnitLabel()}`}</b></th>
						</tr>
					</thead>
					<tbody>                                            
						{connections?.map((connection: any, i: number) => {
							const key = i;
							const thrPerTd = connection.THR/connection.ATD;
							const thrDisplay = UnitOfMeasure.getRatingDisplayValue(thrPerTd);
							const thrPerTdDisplay = thrDisplay === undefined ? "" : UnitOfMeasure.getTemperatureRelativeDisplayValue(+thrDisplay);
							return (
								<tr key={key}>
                                    <td>{connection.ID}.</td>
									<td>{connection.Description}</td>
									<td>{UnitOfMeasure.getTemperatureDisplayValue(connection.SST, 1)}</td>
									<td>{UnitOfMeasure.getTemperatureDisplayValue(connection.SCT, 1)}</td>
									<td>{UnitOfMeasure.getRatingDisplayValue(connection.CapacityRequired, 1)}</td>
									<td>{connection.RefrigerantName}</td>
									<td>{UnitOfMeasure.getTemperatureRelativeDisplayValue(connection.DesignTD, 1)}</td>
									<td>{UnitOfMeasure.getRatingDisplayValue(connection.THR, 1)}</td>
									<td>{thrPerTdDisplay === "" || thrPerTdDisplay === undefined ? "" : UnitOfMeasure.getDisplayValue(+thrPerTdDisplay, 3)}</td>
									<td>{connection.EndFeed - connection.StartFeed + 1}</td>
									<td>{connection.StartFeed} - {connection.EndFeed}</td>
                                    <td>{UnitOfMeasure.getTemperatureDisplayValue(connection.ACT, 1)}</td>
                                    <td>{UnitOfMeasure.getTemperatureRelativeDisplayValue(connection.ATD, 1)}</td>
								</tr>
							);
						})}
					</tbody>
				</table>				
                <table className="table is-narrow is-fullwidth no-margin-table" style={table}>
                    <tbody>
                        <tr>
							<td colSpan={3}>
								<br/>
								<div id="acc-circuits-grid" style={{width:"98%", textAlign: "center"}}>
									<b>{translations.cONNECTIONDIAGRAM}</b>
								</div>
                                <canvas id="circuitsDiagram" style={{border: "1px solid #cccccc", textAlign:"center"}}></canvas>
                            </td>
                        </tr>
						<tr>
							<td colSpan={3}>
								<br/>
								<br/>
							</td>
						</tr>
						<tr>
							<td>
								SST = {translations.saturatedSuctionTemperature}
							</td>
							<td>								
								SCT = {translations.saturatedCondensingTemperature}
							</td>
							<td>								
								ACT = {translations.actualCondensingTemperature}
							</td>
						</tr>
						<tr>
							<td colSpan={3}>
								{translations.approvalOfThisDrawingSignifiesThatTheEquipmentIsAcceptableUnderTheProvisionOfTheJobSpecifications_AnyChangeMadeHereonByAnyPersonWhomsoeverSubjectToAcceptanceByNATIONALREFRIGERATIONAtItsHomeOffice_}
							</td>
						</tr>
                    </tbody>
                </table>
        </div>
    );
};

export default ACCDCondensor;
