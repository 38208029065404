import * as React from "react";
// @ts-ignore
import { Modal } from "react-bulma-components";

import "./FullScreenModal.css";
import StorageFacade from "../../services/StorageFacade";

export interface FullScreenModalProps {
	show: boolean;
	title: string;
	body: React.ReactNode;
	footer: React.ReactNode;
	onCancelClick: any;
}

const FullScreenModal: React.FunctionComponent<FullScreenModalProps> = ({
	show,
	title,
	body,
	footer,
	onCancelClick
}: FullScreenModalProps) => {
	return (
		<div>
			<Modal
				show={show}
				onClose={onCancelClick}
				className="modal-full-screen modal-fx-fadeInScale"
			>
				<div className="modal is-active">
					<div className="modal-background" />
					<div className="modal-content modal-card">
						<header className="modal-card-head">
							<p className="modal-card-title">{title}</p>
							<button
								type="button"
								name="close"
								className="delete"
								aria-label="close"
								onClick={onCancelClick}
							/>
						</header>
						<section className="modal-card-body">{body}</section>
						<footer className="modal-card-foot buttons is-right">
							{footer}
						</footer>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default FullScreenModal;
