/* eslint-disable no-underscore-dangle */
import IFormState from "../models/interfaces/IFormState";
import IMessage from "../models/interfaces/IMessage";
import IOpening from "../models/interfaces/IOpening";
import IOpeningRecalculateFormParams from "../models/interfaces/IOpeningRecalculateFormParams";

import OpeningType from "../enums/OpeningType";

import { measure, hasValue, getNumericValue } from "../types/measure";
import UnitOfMeasure from "../models/helpers/UnitOfMeasure";

import useApplicationConfigs from "./useApplicationConfigs";
import useApplicationContext from "./useApplicationContext";
import useTranslationContext from "./useTranslationContext";

import * as OpeningForm from "../models/helpers/OpeningFormFields";
import * as OpeningCalculations from "../models/helpers/OpeningCalculations";

const useOpeningDetailsForm = () => {

    const { getOpeningTypeConfig } = useApplicationConfigs();
    const { getAirPropertyByTemperature, getCurtain } = useApplicationContext();
    const { translations } = useTranslationContext();

    const initialValues: IOpening = {
        openingType: OpeningType.None,
        quantity: 1,
        description: "",
        width: undefined,
        height: undefined,
        area: undefined,
        totalArea: undefined,
        exteriorTemperature: undefined,
        stripCurtainId: "0",
        curtainEffectiveness: 0,
        curtainRValue: 0,
        passagesPerDay: undefined,
        averagePassageTime: undefined,
        standOpenTimePerDay: undefined,
        rValueEffective: undefined,
        conductionLoad: undefined,
        totalConductionLoad: undefined,
        infiltratingAirRh: undefined,
        infiltrationLoad: undefined,
        totalInfiltrationLoad: undefined
    };
    
    const initialFormState: IFormState<IOpening> = {
        defaultValues: initialValues,
        actualValues: initialValues
    };

    const validateOpening = (
        opening: IOpening,
        dimension1: measure,
        dimension2: measure
    ): IMessage[] => {

        const errors: IMessage[] = [];

        const openingType = getOpeningTypeConfig(opening.openingType);

        if (opening.openingType === OpeningType.None) {
            errors.push({
                name: "openingType", 
                text: translations.theOpeningTypeIsNotSelected_
            });
        }

        if (hasValue(opening.quantity)
            && getNumericValue(opening.quantity) === 0
        ) {
            errors.push({
                name: "quantity", 
                text: translations.noQuantityEntered_
            });
        }

        if (opening.description.trim() === ""
            && openingType.hasDescriptionField
        ) {
            errors.push({
                name: "description", 
                text: translations.aDescriptionHasNotBeenEntered_
            });
        }

        if (!hasValue(opening.width)
            && openingType.hasDimensionFields
        ) {
            errors.push({
                name: "width", 
                text: translations.theOpeningWidthHasNotBeenEntered_
            });
        }

        if (hasValue(opening.width)
            && openingType.hasDimensionFields
            && UnitOfMeasure.IntoFt(getNumericValue(opening.width)) > getNumericValue(dimension1 ?? 0)
        ) {
            errors.push({
                name: "width", 
                text: translations.theOpeinngWidthIsGreaterThanTheSectionWidth_
            });
        }

        if (!hasValue(opening.height)
            && openingType.hasDimensionFields
        ) {
            errors.push({
                name: "height", 
                text: translations.theOpeningHeightHasNotBeenEntered_
            });
        }

        if (hasValue(opening.height)
            && openingType.hasDimensionFields
            && UnitOfMeasure.IntoFt(getNumericValue(opening.height)) > getNumericValue(dimension2 ?? 0)
        ) {
            errors.push({
                name: "height", 
                text: translations.theOpeningHeightIsGreaterThanTheSectionHeight_
            });
        }

        if (!hasValue(opening.area)
            && openingType.hasDimensionFields
        ) {
            errors.push({
                name: "area", 
                text: translations.theAreaHasNotBeenCalculatedOrEntered_
            });
        }

        if (!hasValue(opening.rValueEffective)
            && openingType.hasRValueEffectiveField
            && !hasValue(opening.conductionLoad)
        ) {
            errors.push({
                name: "rValueEffective", 
                text: translations.theR_ValueHasNotBeenEntered_
            });
        }

        if (!hasValue(opening.conductionLoad)
            && openingType.hasConductionLoadField
        ) {
            errors.push({
                name: "conductionLoad", 
                text: translations.theMaterialLoadHasNotBeenCalculatedOrEntered_
            });
        }

        if (!hasValue(opening.infiltrationLoad)
            && openingType.hasInfiltrationLoadField
        ) {
            errors.push({
                name: "infiltrationLoad", 
                text: translations.theInfiltrationLoadHasNotBeenCalculatedOrEntered_
            });
        }

        return errors;
    };

    const getDefaultOpening = (        
        openingTypeId: OpeningType,
        sectionExteriorTemperature: measure,
        sectionExteriorHumidity: measure,
        sectionDimension1: measure,
        sectionDimension2: measure,
        boxInteriorTemperature: measure,
        boxInteriorHumidity: measure
    ): IOpening => {

        const openingType = getOpeningTypeConfig(openingTypeId);

        const {quantity, stripCurtainId} = initialValues;

        const description = openingType.name;
        const width = openingType.defaultWidth;
        const height = openingType.defaultHeight;
        const area = OpeningCalculations.calculateArea(width, height);
        const totalArea = OpeningCalculations.calculateTotalArea(quantity, area);

        const exteriorTemperature = sectionExteriorTemperature;
        
        const curtain = getCurtain(stripCurtainId);
        const curtainEffectiveness = curtain.id === 0 ? 0 : curtain.effectiveness;
        const curtainRValue = curtain.rValue;
        
        const passagesPerDay = openingType.defaultPassagesPerDay;
        const averagePassageTime = openingType.defaultAveragePassageTime;
        const standOpenTimePerDay = openingType.defaultStandOpenTimePerDay;
        
        const rValueEffective = openingType.hasRValueEffectiveField ? openingType.defaultRValueEffective : curtainRValue;
        const conductionLoad = OpeningCalculations.calculateConductionLoad(
            area,
            exteriorTemperature,
            rValueEffective,
            curtainRValue,
            standOpenTimePerDay,
            averagePassageTime,
            passagesPerDay,
            boxInteriorTemperature,
            openingType);

        const totalConductionLoad = OpeningCalculations.calculateTotalConductionLoad(quantity, conductionLoad);

        const exteriorTempAirProperty = getAirPropertyByTemperature(exteriorTemperature);
        const interiorTempAirProperty = getAirPropertyByTemperature(boxInteriorTemperature);

        const infiltratingAirRh = sectionExteriorHumidity;
        const infiltrationLoad = 
            (exteriorTempAirProperty && interiorTempAirProperty)
                ? OpeningCalculations.calculateInfiltrationLoad(
                    width,
                    height,
                    area,
                    infiltratingAirRh,
                    curtainEffectiveness,
                    standOpenTimePerDay,
                    averagePassageTime,
                    passagesPerDay,
                    exteriorTemperature,
                    exteriorTempAirProperty,
                    boxInteriorTemperature,
                    interiorTempAirProperty,
                    boxInteriorHumidity,
                    openingType)
                : undefined;

        const totalInfiltrationLoad = OpeningCalculations.calculateTotalInfiltrationLoad(quantity, infiltrationLoad);

        const defaultOpening: IOpening = {
            openingType: openingTypeId,
            quantity,
            description,
            width,
            height,
            area,
            totalArea,
            exteriorTemperature,
            stripCurtainId,
            curtainEffectiveness,
            curtainRValue,
            passagesPerDay,
            averagePassageTime,
            standOpenTimePerDay,
            rValueEffective,
            conductionLoad,
            totalConductionLoad,
            infiltratingAirRh,
            infiltrationLoad,
            totalInfiltrationLoad
        };

        const errors = validateOpening(defaultOpening, sectionDimension1, sectionDimension2);
        
        defaultOpening.errors = errors;

        return defaultOpening;
    };

    const recalculateOpening = (
        state: IFormState<IOpening>,
        formInput: IOpeningRecalculateFormParams | null,
        sectionExteriorTemperature: measure,
        sectionExteriorHumidity: measure,
        sectionDimension1: measure,
        sectionDimension2: measure,
        boxInteriorTemperature: measure,
        boxInteriorHumidity: measure
    ): IFormState<IOpening> => {

        const openingTypeId = OpeningForm.getOpeningTypeId(state, formInput?.openingType);
        const openingType = getOpeningTypeConfig(openingTypeId);

        if (openingType.id === OpeningType.None) { // no selection
            return initialFormState;
        }

        const quantityField = OpeningForm.getQuantity(state, formInput?.quantity);

        const descriptionField = OpeningForm.getDescription(state, openingType);

        const widthField = OpeningForm.getWidth(state, formInput?.width, openingType);
        
        const heightField = OpeningForm.getHeight(state, formInput?.height, openingType);

        const areaField = OpeningForm.getArea(state, formInput?.area, widthField.actualValue, heightField.actualValue);

        const totalArea = OpeningCalculations.calculateTotalArea(quantityField.actualValue, areaField.actualValue);

        const exteriorTemperatureField = OpeningForm.getExteriorTemperature(state, formInput?.exteriorTemperature, sectionExteriorTemperature);

        const curtainId = OpeningForm.getStripCurtainId(state, formInput?.stripCurtainId);
        const curtain = getCurtain(curtainId);

        const curtainEffectivenessField = OpeningForm.getCurtainEffectiveness(state, curtain);

        const curtainRValueField = OpeningForm.getCurtainRValue(state, formInput?.curtainRValue, curtain);

        const passagesPerDayField = OpeningForm.getPassagesPerDay(state, formInput?.passagesPerDay, openingType);

        const averagePassageTimeField = OpeningForm.getAveragePassageTime(state, formInput?.averagePassageTime, openingType);

        const standOpenTimePerDayField = OpeningForm.getStandOpenTimePerDay(state, formInput?.standOpenTimePerDay, openingType);        

        const rValueEffectiveField = OpeningForm.getRValueEffective(state, formInput?.rValueEffective, openingType, curtainRValueField.actualValue);

        const conductionLoadField = OpeningForm.getConductionLoad(
            state, 
            formInput?.conductionLoad, 
            openingType, 
            areaField.actualValue, 
            exteriorTemperatureField.actualValue,
            rValueEffectiveField.actualValue, 
            curtainRValueField.actualValue, 
            standOpenTimePerDayField.actualValue, 
            averagePassageTimeField.actualValue, 
            passagesPerDayField.actualValue, 
            boxInteriorTemperature);

        const totalConductionLoad = OpeningCalculations.calculateTotalConductionLoad(quantityField.actualValue, conductionLoadField.actualValue);

        const infiltratingAirRhField = OpeningForm.getInfiltratingAirRh(state, formInput?.infiltratingAirRh, sectionExteriorHumidity);

        const infiltrationLoadField = OpeningForm.getInfiltrationLoad(
            state, 
            formInput?.infiltrationLoad, 
            openingType,
            widthField.actualValue,
            heightField.actualValue,
            areaField.actualValue,
            infiltratingAirRhField.actualValue,
            curtainEffectivenessField.actualValue,
            standOpenTimePerDayField.actualValue,
            averagePassageTimeField.actualValue, 
            passagesPerDayField.actualValue, 
            exteriorTemperatureField.actualValue,
            getAirPropertyByTemperature(exteriorTemperatureField.actualValue),
            boxInteriorTemperature,
            getAirPropertyByTemperature(boxInteriorTemperature),
            boxInteriorHumidity);

        const totalInfiltrationLoad = OpeningCalculations.calculateTotalInfiltrationLoad(quantityField.actualValue, infiltrationLoadField.actualValue);

        const defaultValues: IOpening = {
            ...state.defaultValues,
            quantity: quantityField.defaultValue,
            width: widthField.defaultValue,
            height: heightField.defaultValue,
            area: areaField.defaultValue,
            exteriorTemperature: exteriorTemperatureField.defaultValue,
            curtainEffectiveness: curtainEffectivenessField.defaultValue,
            curtainRValue: curtainRValueField.defaultValue,
            passagesPerDay: passagesPerDayField.defaultValue,
            averagePassageTime: averagePassageTimeField.defaultValue,
            standOpenTimePerDay: standOpenTimePerDayField.defaultValue,
            rValueEffective: rValueEffectiveField.defaultValue,
            conductionLoad: conductionLoadField.defaultValue,
            infiltratingAirRh: infiltratingAirRhField.defaultValue,
            infiltrationLoad: infiltrationLoadField.defaultValue,
            totalArea,
            totalConductionLoad,
            totalInfiltrationLoad
        };

        const actualValues: IOpening = {
            ...state.actualValues,
            openingType: openingType.id,
            description: descriptionField.actualValue,
            quantity: quantityField.actualValue,
            width: widthField.actualValue,
            height: heightField.actualValue,
            area: areaField.actualValue,
            exteriorTemperature: exteriorTemperatureField.actualValue,
            stripCurtainId: curtainId,
            curtainEffectiveness: curtainEffectivenessField.actualValue,
            curtainRValue: curtainRValueField.actualValue,
            passagesPerDay: passagesPerDayField.actualValue,
            averagePassageTime: averagePassageTimeField.actualValue,
            standOpenTimePerDay: standOpenTimePerDayField.actualValue,
            rValueEffective: rValueEffectiveField.actualValue,
            conductionLoad: conductionLoadField.actualValue,
            infiltratingAirRh: infiltratingAirRhField.actualValue,
            infiltrationLoad: infiltrationLoadField.actualValue,
            totalArea,
            totalConductionLoad,
            totalInfiltrationLoad
        };

        const errors = validateOpening(actualValues, sectionDimension1, sectionDimension2);

        return {
            defaultValues,
            actualValues: {
                ...actualValues,
                errors
            }
        };
    };

    const resetForm = (): IFormState<IOpening> => {        
        return initialFormState;
    };

    const resetFormForEdit = (
        openingToEdit: IOpening,
        sectionExteriorTemperature: measure,
        sectionExteriorHumidity: measure,
        sectionDimension1: measure,
        sectionDimension2: measure,
        boxInteriorTemperature: measure,
        boxInteriorHumidity: measure
    ): IFormState<IOpening> => {

        const defaultValues = getDefaultOpening(
            openingToEdit.openingType,
            sectionExteriorTemperature,
            sectionDimension1,
            sectionDimension2,
            sectionExteriorHumidity,
            boxInteriorTemperature,
            boxInteriorHumidity);
        
        const actualValues: IOpening = {
            ...openingToEdit
        };

        return recalculateOpening({
                defaultValues,
                actualValues
            },
            null,
            sectionExteriorTemperature,
            sectionExteriorHumidity,
            sectionDimension1,
            sectionDimension2,
            boxInteriorTemperature,
            boxInteriorHumidity);
    };

    const setInitialState = (
        openingToEdit: IOpening | undefined,
        sectionExteriorTemperature: measure,
        sectionExteriorHumidity: measure,
        sectionDimension1: measure,
        sectionDimension2: measure,
        boxInteriorTemperature: measure,
        boxInteriorHumidity: measure
    ): IFormState<IOpening> => {

        // add opening
        if (openingToEdit === undefined) {
            return resetForm();
        }

        // edit opening
        return resetFormForEdit(
            openingToEdit,
            sectionExteriorTemperature,
            sectionExteriorHumidity,
            sectionDimension1,
            sectionDimension2,
            boxInteriorTemperature,
            boxInteriorHumidity);
    };

    return {
        getDefaultOpening,
        setInitialState,
        recalculateOpening,
        validateOpening
    };
};

export default useOpeningDetailsForm;
