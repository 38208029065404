import * as React from "react";
import { dictionary } from "../../types/dictionary";

export interface DropDownListProps {
    name: string;    
    className?: string;
    value: string;
    dataSource: dictionary<string>;
    disabled?: boolean;
    onChange?: (event: React.FormEvent<HTMLSelectElement>) => void;
}

const DropDownList: React.FunctionComponent<DropDownListProps> = ({
    name,
    className,
    value,
    dataSource,
    disabled,
    onChange
}: DropDownListProps) => {

    return (
        <div className={`select is-small is-fullwidth ${className ?? ""}`}>
            <select name={name} onChange={onChange} value={value} disabled={disabled}>
                {dataSource.map((item) => (
                    <option key={item.key} value={item.key}>
                        {item.value}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default DropDownList;
