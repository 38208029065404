import * as React from "react";

import BoxContext from "../context/BoxContext";

const useBoxContext = () => {
    const { 
        boxId,
        boxData,
        isBoxDataLoaded,
        loadBox
    } = React.useContext(BoxContext);

    return { 
        boxId,
        boxData,
        isBoxDataLoaded,
        loadBox
     };
};

export default useBoxContext;
