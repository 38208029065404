/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import SelectNewBoxApplication from "./components/BoxApplications/SelectNewBoxApplication";
import Equipment from "./components/Equipment/Equipment";
import EquipmentOptions from "./components/EquipmentOptions/EquipmentOptions";
import ErrorNotification from "./components/helpers/ErrorNotification";
import Layout from "./components/Layout";
import LoadCalculator from "./components/LoadCalculator/LoadCalculator";
import PrintReport from "./components/Print/Print";
import PrintMultipleReport from "./components/Print/PrintMultiple";
import PrintSingleReport from "./components/Print/PrintSingle";
import PrintCondenserReport from "./components/Print/PrintCondenser";
import Spinner from "./components/helpers/Spinner";
import WallLoad from "./components/WallLoad/WallLoad";

import ApplicationContextProvider from "./context/ApplicationContextProvider";
import BoxMaterialsContextProvider from "./context/BoxMaterialsContextProvider";
import ErrorContextProvider from "./context/ErrorContextProvider";
import LoadingContextProvider from "./context/LoadingContextProvider";
import TranslationContextProvider from "./context/TranslationContextProvider";
import BoxContextProvider from "./context/BoxContextProvider";
import SystemContextProvider from "./context/SystemContextProvider";

import useClearCache from "./hooks/useClearCache";

import "./App.css";

const App = () => {
	const { isLatestVersion, emptyCacheStorage } = useClearCache({
		auto: !process.env.REACT_APP_AUTO_CLEAR_CACHE || process.env.REACT_APP_AUTO_CLEAR_CACHE === "true",
		storageKey: !process.env.REACT_APP_VERSION_KEY ? "APP_VERSION" : process.env.REACT_APP_VERSION_KEY,
		basePath: ""
	});

	return (
		<TranslationContextProvider>
			<ErrorContextProvider>
				<LoadingContextProvider>
					<ApplicationContextProvider>
						<BoxContextProvider>
							<BoxMaterialsContextProvider>
								<SystemContextProvider>
									<Spinner />
									<Layout>
										<div>
											{!isLatestVersion && (
												<div className="snackbar">
													<h5>
														A new version of the application is available!&nbsp;
														<a 
															role="button" 
															tabIndex={0} 
															onClick={e => {
																e.preventDefault();
																emptyCacheStorage();
															}}
														>
															Click here to download updates.
														</a>
													</h5>
												</div>
											)}
										</div>
										<ErrorNotification />
										<br />																
										<Switch>
											<Redirect 
												exact 
												from="/" 
												to="/project" 
											/>
											<Route path="/loadcalculator">
												<LoadCalculator />
											</Route>
											<Route path="/wallload">
												<WallLoad />
											</Route>
											<Route path="/selectequipment">
												<Equipment />
											</Route>
											<Route
												exact
												path="/printequipment"
												component={PrintReport}
											/>
											<Route 
												exact 
												path="/printmultiple"
												component={PrintMultipleReport} 
											/>
                                            <Route 
												exact 
                                                path="/printsingle"
												component={PrintSingleReport} 
											/>
											<Route 
												exact 
                                                path="/printcondenser"
												component={PrintCondenserReport} 
											/>											
											<Route
												path="/selectequipmentoptions/:id/:divisionId"
												component={EquipmentOptions}
											/>
											<Route 
												exact 
												path="/applications" 
												component={SelectNewBoxApplication} 
											/>
										</Switch>
									</Layout>
								</SystemContextProvider>
							</BoxMaterialsContextProvider>
						</BoxContextProvider>
					</ApplicationContextProvider>
				</LoadingContextProvider>
			</ErrorContextProvider>
		</TranslationContextProvider>
	);
}

export default App;
