import IEquipmentUnitItem from "../models/interfaces/IEquipmentUnitItem";
import IEquipmentOption from "../models/interfaces/IEquipmentOption";
import IEquipmentState from "../models/interfaces/IEquipmentState";
import TranslationService, {
	LocaleStrings
} from "./translations/TranslationService";
import HelperService from "./HelperService";
import { Config } from "../Constants";

const strings: LocaleStrings = TranslationService.getTranslations();

class CertifiedPrintService {
    private static baseURL = Config.API_URL;

    public static getCertifiedPrintValues(
        equipmentState: IEquipmentState,
        equipment: IEquipmentUnitItem, 
        selectedOptions: IEquipmentOption[],
        callback: (data: any) => void){
        
        let notes: string[] = [];
        notes.push(` † MCA.. ${strings.minimumCircuitAmpacity}, ‡ MOP.. ${strings.maximumOvercurrentProtection}`);

        if (equipment.class0ID === 2 ||
            equipment.class0ID === 3 ||
            equipment.class0ID === 5 ||
            equipment.class0ID === 9){
                notes.push(strings.mCA_MOPAreForTheCondensingUnitONLY_SinglePointConnectionsWILLShowDifferentOnDataplate_)
        }

        this.get_CertifiedPrintValues(
            equipmentState,
            equipment, 
            selectedOptions, 
            data => {            
                notes.push(data.notes);
                data.notes = notes;
                callback(data);
        });

    }

    private static get_CertifiedPrintValues(
        equipmentState: IEquipmentState,
        equipment: IEquipmentUnitItem, 
        selectedOptions: IEquipmentOption[],
        callback: (data: any) => void){

        let options: number[] = equipment.requiredOptions?.map( x => {
            return x.optionId;
        })??[];                

        options = options.concat(selectedOptions?.map( (x:any) => {
            return x.id;
        }));

        const optionIds: string = options.join(",");        

        let url = "";

        if (equipmentState && equipmentState.refrigerant){
            url = `${this.baseURL}/equipment/${equipment.class9ID}/certifiedprint?optionIds=${optionIds}&fluidId=${equipmentState.refrigerant}`;
        }
        else
        {
            url = `${this.baseURL}/equipment/${equipment.class9ID}/certifiedprint?optionIds=${optionIds}&fluidId=4`;
        }

        HelperService.callAPI(url, callback);

    }

}

export default CertifiedPrintService;