import * as React from "react";

import ApplicationContext from "./ApplicationContext";

import ConfigurationService from "../services/ConfigurationService";

import IAirProperty from "../models/interfaces/IAirProperty";
import ICurtain from "../models/interfaces/ICurtain";

import useErrorContext from "../hooks/useErrorContext";
import useLoadingContext from "../hooks/useLoadingContext";

interface ApplicationContextProviderProps {
    children: any;
}

const getApplicationConstantsAsync = () => {
    return new Promise<any>((resolve, reject) => {
        try {
            ConfigurationService.getAppConstants((data: any) => {
                resolve(data);
            });
        } catch (error) {
            reject(error);
        }
    });
};

const getAirPropertiesAsync = () => {
    return new Promise<any>((resolve, reject) => {
        try {
            ConfigurationService.getAirProperties((data: IAirProperty[]) => {
                resolve(data);
            });
        } catch (error) {
            reject(error);
        }
    });
};

const getCurtainsAsync = () => {
    return new Promise<any>((resolve, reject) => {
        try {
            ConfigurationService.getCurtains((data: ICurtain[]) => {
                resolve(data);
            });
        } catch (error) {
            reject(error);
        }
    });
};

const ApplicationContextProvider: React.FunctionComponent<ApplicationContextProviderProps> = ({    
	children,
}: ApplicationContextProviderProps) => {

    const [appData, setAppData] = React.useState(null);
    const [airProperties, setAirProperties] = React.useState([] as IAirProperty[]);
    const [curtains, setCurtains] = React.useState([] as ICurtain[]);
    const [isAppDataLoaded, setIsAppDataLoaded] = React.useState(false);

    const { showLoading, hideLoading } = useLoadingContext();
    const { showError } = useErrorContext();

    React.useEffect(() => {
        setIsAppDataLoaded(false);
        showLoading();

        Promise.all([
            getApplicationConstantsAsync(),
            getAirPropertiesAsync(),
            getCurtainsAsync()
        ]).then((data: any) => {
            setAppData(data[0]);
            setAirProperties(data[1]);
            setCurtains(data[2]);

            setIsAppDataLoaded(true);
            hideLoading();
        }).catch((error: any) => {
            showError({
                name: "Error",
                text: `An error was encountered. ${error.message ?? ""}`
            });
            hideLoading();
        });
    }, []);
    
    const contextValue = {
        appData,
        airProperties,
        curtains,
        isAppDataLoaded
    };

    return (
        <ApplicationContext.Provider value={contextValue}>
            {children}
        </ApplicationContext.Provider>
    );
};

export default ApplicationContextProvider;