/* eslint-disable no-underscore-dangle */
import * as React from "react";
import { useHistory } from "react-router-dom";

import BoxSectionsGrid from "./BoxSectionsGrid";
import BoxShapesModal from "./BoxShapesModal";
import Button from "../helpers/Button";
import Checkbox from "../helpers/Checkbox";
import FormField from "../helpers/FormField";
import Measurement from "../helpers/Measurement";
import RectangularBox from "./RectangularBox";
import LShapeBox from "./LShapeBox";
import UShapeBox from "./UShapeBox";

import BoxLocation from "../../enums/BoxLocation";
import BoxType from "../../enums/BoxType";
import MeasurementType from "../../enums/MeasurementType";
import SectionType from "../../enums/SectionType";
import WallType from "../../enums/WallType";

import UnitOfMeasure from "../../models/helpers/UnitOfMeasure";

import IMessage from "../../models/interfaces/IMessage";
import ISection from "../../models/interfaces/ISection";
import ISystem from "../../models/interfaces/ISystem";

import useErrorContext from "../../hooks/useErrorContext";
import useSystemContext from "../../hooks/useSystemContext";
import useSystemState from "../../hooks/useSystemState";
import useTranslationContext from "../../hooks/useTranslationContext";

import "./WallLoad.css";

interface WallLoadProps {}

const WallLoad: React.FunctionComponent<WallLoadProps> = () => {
    
    const { errorMessages, showErrorList } = useErrorContext();
    const { translations } = useTranslationContext();

    const history = useHistory();

    const { systemState, dispatch } = useSystemContext();
    const { validateWallLoad } = useSystemState();

    const [selectedSection, setSelectedSection] = React.useState({
        sectionType: SectionType.Undefined,
        wallType: WallType.Undefined
    } as ISection);

    const [showShapes, setShowShapes] = React.useState(false);

    const showErrors = (
        errors: IMessage[],
        currentFieldError?: IMessage
    ) => {
        let newList = [] as IMessage[];
        
        const currentList = [...errorMessages];
        currentList.forEach(c => {
            const errorText = errors.find(e => e.name === c.name)?.text ?? "";
            newList.push({
                name: c.name,
                text: errorText
            });
        });

        if (currentFieldError) {
            if (currentList.find(c => c.name === currentFieldError.name)) {
                newList = newList.map(n => {
                    return {
                        name: n.name,
                        text: (n.name === currentFieldError.name) ? currentFieldError.text : n.text
                    }
                });
            }
            else {
                newList.push(currentFieldError);
            }
        }
    
        showErrorList(newList, false);
    };

    const validateField = (
        system: ISystem,
        fieldName: string
    ) => {
        
        const errors = validateWallLoad(system);

        const fieldError: IMessage = {
            name: fieldName,
            text: errors.find(e => e.name === fieldName)?.text ?? ""
        };
        
        showErrors(errors, fieldError);
        
        return fieldError.text;
    };

    const validateSystemField = (
        fieldName: string,
        fieldValue: any
        ) => {

        const system = {
            ...systemState.formState.actualValues,
            [fieldName]: fieldValue
        };

        return validateField(system, fieldName);
    };

    const validateWallLoadField = (
        fieldName: string,
        fieldValue: any
        ) => {

        const system = {
            ...systemState.formState.actualValues,
            wallLoad: {
                ...systemState.formState.actualValues.wallLoad,
                [fieldName]: fieldValue
            }
        };

        return validateField(system, fieldName);
    };
    
    const handleCancelButtonClick = () => {
        dispatch({ 
            type: "cancel-changes"
        });
    };

    const handleOkButtonClick = () => {
        dispatch({ 
            type: "submit-changes", 
            updateStore: true
        });
    };

    React.useEffect(() => {
        if (systemState.action === "submit-changes"
            || systemState.action === "cancel-changes"
        ) {
			history.goBack();
		}
	}, [systemState.action]);

    const handleMeasurementChange = (fieldName: string, fieldValue: any) => {
        dispatch({ 
            type: "customize-property", 
            fieldName, 
            fieldValue,
            updateStore: false
        });
    };
    
    const handleWallLoadMeasurementChange = (fieldName: string, fieldValue: any) => {
        dispatch({ 
            type: "customize-wallLoad-property", 
            fieldName, 
            fieldValue,
            updateStore: false
        });
	};

    const handleBoxLocationChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.checked) {
            const boxLocation = event.target.value as BoxLocation;
            dispatch({ 
				type: "change-value", 
				fieldName: "boxLocation", 
				fieldValue: boxLocation,
				updateStore: false
            });
            
            validateSystemField("boxLocation", boxLocation);
        }
    };

    const handleSectionSelected = (
        sectionType: SectionType,
        wallType: WallType
    ) => {
        const updatedSelectedSection = {
            ...selectedSection,
            sectionType,
            wallType
        };
        setSelectedSection(updatedSelectedSection);
    };

    const handleShapeChange = (boxType: BoxType) => {
        dispatch({ 
			type: "reset-boxType", 
			boxType,
			updateStore: false
        });
        
        validateSystemField("boxType", boxType);

        setShowShapes(false);

        const updatedSelectedSection = {
            ...selectedSection,
            sectionType: SectionType.Undefined,
            wallType: WallType.Undefined
        };
        setSelectedSection(updatedSelectedSection);
    }

    const showDimensions1 =
        (systemState.formState.actualValues.boxType === BoxType.Rectangular
            || systemState.formState.actualValues.boxType === BoxType.LShape
            || systemState.formState.actualValues.boxType === BoxType.UShape);

    const showDimensions2 =
        (systemState.formState.actualValues.boxType === BoxType.LShape
            || systemState.formState.actualValues.boxType === BoxType.UShape);

    const showDimensions3 =
        (systemState.formState.actualValues.boxType === BoxType.UShape);

    const showHeight =
        (systemState.formState.actualValues.boxType !== BoxType.Custom);

    const lengthLabel = (showDimensions2 || showDimensions3) ? translations.rear : translations.length;
    const depthLabel = (showDimensions2 || showDimensions3) ? translations.rightEnd : translations.depth;

    const body = (
        <div id="wall-load" className="container">
            <div className="block">
                <div className="columns">
                    <div className="column is-one-quarter">
                        <div className="section-preview">
                            {systemState.formState.actualValues.boxType === BoxType.Rectangular && (
                                <RectangularBox sectionType={selectedSection.sectionType} wallType={selectedSection.wallType} />
                            )}
                            {systemState.formState.actualValues.boxType === BoxType.LShape && (
                                <LShapeBox sectionType={selectedSection.sectionType} wallType={selectedSection.wallType} />
                            )}
                            {systemState.formState.actualValues.boxType === BoxType.UShape && (
                                <UShapeBox sectionType={selectedSection.sectionType} wallType={selectedSection.wallType} />
                            )}
                            {systemState.formState.actualValues.boxType === BoxType.Custom && (
                                <span>
                                    SectionList
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="column">
                        <div className="columns">
                            <div className="column is-two-fifths narrow">
                                {showDimensions1 && (
                                    <FormField label={`${lengthLabel} (${UnitOfMeasure.getDimensionUnitLabel()})`}>
                                        <Measurement
                                            name="boxLength"
                                            defaultValue={systemState.formState.defaultValues.boxLength}
                                            actualValue={systemState.formState.actualValues.boxLength}
                                            decimals={2}
                                            onChange={handleMeasurementChange}
                                            onValidate={validateSystemField}
                                            type={MeasurementType.Dimension}
                                            tooltip={translations.Box_Designer_txtbox_Length1}
                                        />
                                    </FormField>
                                )}
                            </div>
                            <div className="column narrow">
                                {showDimensions1 && (
                                    <FormField label={`${depthLabel} (${UnitOfMeasure.getDimensionUnitLabel()})`}>
                                        <Measurement
                                            name="boxDepth"
                                            defaultValue={systemState.formState.defaultValues.boxDepth}
                                            actualValue={systemState.formState.actualValues.boxDepth}
                                            decimals={2}
                                            onChange={handleMeasurementChange}
                                            type={MeasurementType.Dimension}
                                            onValidate={validateSystemField}
                                            tooltip={translations.Box_Designer_txtbox_Depth1}
                                        />
                                    </FormField>
                                )}
                            </div>
                        </div>
                        {showDimensions2 && (
                            <div className="columns">
                                <div className="column is-two-fifths narrow">
                                    <FormField label={`${translations.front} (${UnitOfMeasure.getDimensionUnitLabel()})`}>
                                        <Measurement
                                            name="boxLength2"
                                            defaultValue={systemState.formState.defaultValues.boxLength2}
                                            actualValue={systemState.formState.actualValues.boxLength2}
                                            decimals={2}
                                            onChange={handleMeasurementChange}
                                            onValidate={validateSystemField}
                                            type={MeasurementType.Dimension}
                                            tooltip={translations.Box_Designer_txtbox_Length2}
                                        />
                                    </FormField>
                                </div>
                                <div className="column narrow">
                                    <FormField label={`${translations.leftCut} (${UnitOfMeasure.getDimensionUnitLabel()})`}>
                                        <Measurement
                                            name="boxDepth2"
                                            defaultValue={systemState.formState.defaultValues.boxDepth2}
                                            actualValue={systemState.formState.actualValues.boxDepth2}
                                            decimals={2}
                                            onChange={handleMeasurementChange}
                                            type={MeasurementType.Dimension}
                                            onValidate={validateSystemField}
                                            tooltip={translations.Box_Designer_txtbox_Depth2}
                                        />
                                    </FormField>
                                </div>
                            </div>
                        )}
                        {showDimensions3 && (
                            <div className="columns">
                                <div className="column is-two-fifths narrow">
                                    <FormField label={`${translations.frontInset} (${UnitOfMeasure.getDimensionUnitLabel()})`}>
                                        <Measurement
                                            name="boxLength3"
                                            defaultValue={systemState.formState.defaultValues.boxLength3}
                                            actualValue={systemState.formState.actualValues.boxLength3}
                                            decimals={2}
                                            onChange={handleMeasurementChange}
                                            onValidate={validateSystemField}
                                            type={MeasurementType.Dimension}
                                            tooltip={translations.Box_Designer_txtbox_Length3}
                                        />
                                    </FormField>
                                </div>
                                <div className="column narrow">
                                    <FormField label={`${translations.leftInset} (${UnitOfMeasure.getDimensionUnitLabel()})`}>
                                        <Measurement
                                            name="boxDepth3"
                                            defaultValue={systemState.formState.defaultValues.boxDepth3}
                                            actualValue={systemState.formState.actualValues.boxDepth3}
                                            decimals={2}
                                            onChange={handleMeasurementChange}
                                            type={MeasurementType.Dimension}
                                            onValidate={validateSystemField}
                                            tooltip={translations.Box_Designer_txtbox_Depth3}
                                        />
                                    </FormField>
                                </div>
                            </div>
                        )}
                        <div className="columns">
                            <div className="column is-two-fifths narrow">
                                {showHeight && (
                                    <FormField label={`${translations.height} (${UnitOfMeasure.getDimensionUnitLabel()})`}>
                                        <Measurement
                                            name="boxHeight"
                                            defaultValue={systemState.formState.defaultValues.boxHeight}
                                            actualValue={systemState.formState.actualValues.boxHeight}
                                            decimals={2}
                                            onChange={handleMeasurementChange}
                                            type={MeasurementType.Dimension}
                                            tooltip={translations.Box_Designer_txtbox_Height}
                                            onValidate={validateSystemField}
                                        />
                                    </FormField>
                                )}
                            </div>
                            <div className="column narrow">
                                <FormField label={translations.location}>
                                    <>
                                        <Checkbox
                                            name="boxLocation"
                                            label={translations.indoors}
                                            value={BoxLocation.Indoors}
                                            isChecked={systemState.formState.actualValues.boxLocation === BoxLocation.Indoors}
                                            onChange={handleBoxLocationChange}
                                        />
                                        <Checkbox
                                            name="boxLocation"
                                            label={translations.outdoors}
                                            tooltip={translations.Box_Designer_CheckBox_OutDoors_Warm}
                                            value={BoxLocation.OutdoorsWarm}
                                            isChecked={systemState.formState.actualValues.boxLocation === BoxLocation.OutdoorsWarm}
                                            onChange={handleBoxLocationChange}
                                        />
                                        <Checkbox
                                            name="boxLocation"
                                            label={translations.outdoors_ColdClimate_}
                                            tooltip={translations.Box_Designer_CheckBox_OutDoors_Cold}
                                            value={BoxLocation.OutdoorsCold}
                                            isChecked={systemState.formState.actualValues.boxLocation === BoxLocation.OutdoorsCold}
                                            onChange={handleBoxLocationChange}
                                        />
                                    </>
                                </FormField>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-two-fifths narrow">
                                <FormField label={`${translations.internalTemperature} (${UnitOfMeasure.getTemperatureUnitLabel()})`}>
                                    <Measurement
                                        name="interiorTemperature"
                                        defaultValue={systemState.formState.defaultValues.interiorTemperature}
                                        actualValue={systemState.formState.actualValues.interiorTemperature}
                                        decimals={1}
                                        onChange={handleMeasurementChange}
                                        type={MeasurementType.Temperature}
                                        tooltip={translations.Box_Designer_txtBox_Interior_Temp}
                                        onValidate={validateSystemField}
                                    />
                                </FormField>
                            </div>
                            <div className="column narrow">
                                <FormField label={`${translations.externalTemperature} (${UnitOfMeasure.getTemperatureUnitLabel()})`}>
                                    <Measurement
                                        name="exteriorTemperature"
                                        defaultValue={systemState.formState.defaultValues.exteriorTemperature}
                                        actualValue={systemState.formState.actualValues.exteriorTemperature}
                                        decimals={1}
                                        onChange={handleMeasurementChange}
                                        type={MeasurementType.Temperature}
                                        tooltip={translations.Box_Designer_txtBox_Exterior_Temp}
                                        onValidate={validateSystemField}
                                    />
                                </FormField>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-two-fifths narrow">
                                <FormField label={`${translations.internalRH} (${UnitOfMeasure.getPercentUnitLabel()})`}>
                                    <Measurement
                                        name="interiorHumidity"
                                        defaultValue={systemState.formState.defaultValues.interiorHumidity}
                                        actualValue={systemState.formState.actualValues.interiorHumidity}
                                        decimals={1}
                                        onChange={handleMeasurementChange}
                                        type={MeasurementType.PositiveDecimal}
                                        tooltip={translations.Box_Designer_txtbox_InternalRH}
                                        onValidate={validateSystemField}
                                    />
                                </FormField>
                            </div>
                            <div className="column narrow">
                                <FormField label={`${translations.externalRH} (${UnitOfMeasure.getPercentUnitLabel()})`}>
                                    <Measurement
                                        name="exteriorHumidity"
                                        defaultValue={systemState.formState.defaultValues.exteriorHumidity}
                                        actualValue={systemState.formState.actualValues.exteriorHumidity}
                                        decimals={1}
                                        onChange={handleMeasurementChange}
                                        type={MeasurementType.PositiveDecimal}
                                        tooltip={translations.Box_Designer_txtbox_ExternalRH}
                                        onValidate={validateSystemField}
                                    />
                                </FormField>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BoxSectionsGrid
                onSectionSelect={handleSectionSelected}
            />
            <div className="block">
                <div className="columns">
                    <div className="column">
                        <FormField label={`${translations.floorArea} (${UnitOfMeasure.getAreaUnitLabel()})`}>
                            <Measurement
                                name="floorArea"
                                defaultValue={systemState.formState.defaultValues.wallLoad.floorArea}
                                actualValue={systemState.formState.actualValues.wallLoad.floorArea}
                                decimals={0}
                                onChange={handleWallLoadMeasurementChange}
                                type={MeasurementType.Area}
                                tooltip={translations.Box_Designer_txtbox_Floor_Area}
                                onValidate={validateWallLoadField}
                            />
                        </FormField>
                    </div>
                    <div className="column">
                        <FormField label={`${translations.totalVolume} (${UnitOfMeasure.getVolumeUnitLabel()})`}>
                            <Measurement
                                name="volume"
                                defaultValue={systemState.formState.defaultValues.wallLoad.volume}
                                actualValue={systemState.formState.actualValues.wallLoad.volume}
                                decimals={0}
                                onChange={handleWallLoadMeasurementChange}
                                type={MeasurementType.Volume}
                                tooltip={translations.Box_Designer_txtbox_Volume}
                                onValidate={validateWallLoadField}
                            />
                        </FormField>
                    </div>
                    <div className="column">
                        <FormField label={`${translations.totalWallLoad} (${UnitOfMeasure.getRatingUnitLabel()})`}>
                            <Measurement
                                name="totalWallLoad"
                                defaultValue={systemState.formState.defaultValues.totalWallLoad}
                                actualValue={systemState.formState.actualValues.totalWallLoad}
                                decimals={0}
                                onChange={handleMeasurementChange}
                                type={MeasurementType.Rating}
                                tooltip={translations.Box_Designer_txtbox_Wall_Load}
                                onValidate={validateWallLoadField}
                            />
                        </FormField>
                    </div>
                </div>
            </div>
            {showShapes && (
                <BoxShapesModal
                    show={showShapes}
                    onSelect={handleShapeChange}
                    onClose={() => { setShowShapes(false); }}
                />
            )}
        </div>
    );

    const footer = (
        <div className="level">
            <div className="level-left">
                <div className="level-item tooltip-leftedge">
                    <Button
                        name="cancel-wallload"
                        label={translations.cancel}
                        className="button is-primary"
                        tooltip={translations.Box_Designer_Button_Cancel}
                        onClick={handleCancelButtonClick}
                    />
                </div>
                <div className="level-item">
                    <Button
                        name="change-shape"
                        label={translations.change_Shape}
                        className="button is-primary"
                        tooltip={translations.Box_Designer_Button_Change_Shape}
                        onClick={() => { setShowShapes(true); }}
                    />
                </div>
            </div>
            <div className="level-right tooltip-rightedge">
                <div className="level-item">
                    <Button
                        name="submit-wallload"
                        label={translations.oK}
                        className="button is-link"
                        tooltip={translations.Box_Designer_Button_OK}
                        onClick={handleOkButtonClick}
                    />
                </div>
            </div>
        </div>
    );

    const modal = (
        <>
            {body}
            <hr />
            {footer}
        </>
    );

    return modal;
}

export default WallLoad;