import * as React from "react";

import useLoadingContext from "../../hooks/useLoadingContext";

import "./Spinner.css";

const Spinner = () => {
    const { loadingCount } = useLoadingContext();

    return (
        <>
            {loadingCount > 0 && (
                <div className="wrapper">
                    <div className="spinning-circle"> </div>
                </div>
            )}
        </>
    );
};

export default Spinner;
