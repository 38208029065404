/* eslint-disable no-underscore-dangle */
import * as React from "react";

import Button from "../helpers/Button";
import Modal from "../helpers/Modal";
import OpeningsGrid from "./OpeningsGrid";

import { measure } from "../../types/measure";

import IOpening from "../../models/interfaces/IOpening";
import ISection from "../../models/interfaces/ISection";

import useTranslationContext from "../../hooks/useTranslationContext";

interface OpeningsModalProps {
    show: boolean;
    section: ISection;
    boxInteriorTemperature: measure;
    boxInteriorHumidity: measure;
	onOkClick: (sectionOpenings: IOpening[]) => void;
    onCancelClick: () => void;
}

const OpeningsModal: React.FunctionComponent<OpeningsModalProps> = (
	props: OpeningsModalProps
) => {

    const { translations } = useTranslationContext();
    
    const [show, setShow] = React.useState(false);
    
    const [sectionOpenings, setSectionOpenings] = React.useState(props.section?.openings ?? []);

    React.useEffect(() => {       
        setShow(props.show);
        if (props.show) {
            setSectionOpenings(props.section?.openings ?? []);
        }
    }, [props.show]);

    const handleOpeningsGridUpdated = (openings: IOpening[]) => {
        setSectionOpenings(openings);
    };
    
    const handleCloseClick = () => {
        setShow(false);
        if (props.onCancelClick) {
            props.onCancelClick();
        }
    };

    const handleOkClick = () => {
        setShow(false);
        if (props.onOkClick) {
            props.onOkClick(sectionOpenings);
        }
    };

    const modalBody = (
        <div id="openings-modal" className="content">
            <OpeningsGrid 
                section={props.section}
                boxInteriorTemperature={props.boxInteriorTemperature}
                boxInteriorHumidity={props.boxInteriorHumidity}
                openings={sectionOpenings}
                onGridUpdated={handleOpeningsGridUpdated}
            />
        </div>
    );

    const modalFooter = (
        <>
            <Button
                name="submit-openings"
                label={translations.oK}
                className="button is-link"
                onClick={handleOkClick}
            />
            <Button
                name="cancel-openings"
                label={translations.cancel}
                className="button is-primary"
                onClick={handleCloseClick}
            />
        </>
    );
    
    return (
        <Modal 
            show={show} 
            title={props.section?.sectionName ?? ""}
            body={modalBody}
            footer={modalFooter}
            onCancelClick={handleCloseClick}
        />
    );
};

export default OpeningsModal;
