/* eslint-disable no-use-before-define */
import React, { useState } from "react"
import LoadingContext from "./LoadingContext"

interface LoadingContextProviderProps {
	children: any;
}

const LoadingContextProvider: React.FunctionComponent<LoadingContextProviderProps> = ({
	children,
}: LoadingContextProviderProps) => {
  
  const showLoading = () => {
    toggleLoading(prevState => {
      return {
        ...prevState,
        loadingCount: prevState.loadingCount + 1
      }
    })
  };

  const hideLoading = () => {
      toggleLoading(prevState => {
        return {
          ...prevState,
          loadingCount:
            prevState.loadingCount > 0 ? prevState.loadingCount - 1 : 0
        }
      })
  };

  const loadingState = {
    loadingCount: 0,
    showLoading,
    hideLoading
  };

  const [loading, toggleLoading] = useState(loadingState);

  return (
    <LoadingContext.Provider value={loading}>
      {children}
    </LoadingContext.Provider>
  )
}

export default LoadingContextProvider