/* eslint-disable no-underscore-dangle */
import * as React from "react";

import Button from "../helpers/Button";
import Checkbox from "../helpers/Checkbox";
import Modal from "../helpers/Modal";

import FloorType from "../../enums/FloorType";

import useTranslationContext from "../../hooks/useTranslationContext";

import "./FloorTypesModal.css";

export interface FloorTypesModalProps {
    isFreezer: boolean;
    selected: FloorType;
    showBoxSetting: boolean;
    boxSetting?: FloorType;
    onChange: any;    
    activator: any;
}

const FloorTypesModal: React.FunctionComponent<FloorTypesModalProps> = (
	props: FloorTypesModalProps
) => {
    const [show, setShow] = React.useState(false);
    const [selected, setSelected] = React.useState(props.selected);

    const { translations } = useTranslationContext();
    
    React.useEffect(() => {
        if (show) {
            setSelected(props.selected);
        }
    }, [show, props.selected]);

	const handleCloseClick = () => {
		setShow(false);
    };

    const handleOkClick = () => {
        setShow(false);
        props.onChange(selected);
    };

    const handleCheckboxChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
        if (event.target.checked) {
            setSelected(event.target.value as FloorType);
        } else {
            setSelected(FloorType.None);
        }
    };

    const floorTypeName = (floorType: FloorType): string => {
        switch (floorType) {
            case FloorType.OnGrade:
                return translations.onGrade;
            case FloorType.AboveGrade:
                return translations.aboveGrade;
            case FloorType.OnGradeHeated:
                return translations.onGrade_FrostHeaveProtected;
            default:
                return "";
        }
    }

    const modalBody = (
        <div id="floor-types-modal" className="content">
            <article className="media">
                <figure className="media-left is-narrow">
                    <p className="image">
                        <img src="/images/floortypes/BoxOutsideOnGrade.jpg" alt="On Grade" />
                    </p>
                </figure>
                <div className="media-content">
                    <div className="content is-small">
                        <Checkbox 
                            name="floorType"
                            label={floorTypeName(FloorType.OnGrade)}
                            className="has-text-weight-bold"
                            value={FloorType.OnGrade}
                            isChecked={selected === FloorType.OnGrade}
                            onChange={handleCheckboxChange}
                        />
                        <p>
                            {translations.theRefrigeratedFloorIsBuiltDirectlyOnTheGround_}
                            <br />
                            {translations.orTheRoomInWhichTheRefrigeratedBoxIsLocatedIsBuiltDirectlyOnTheGround_}
                        </p>
                    </div>
                </div>
            </article>
            <hr className="dropdown-divider" />
            <article className="media">
                <figure className="media-left is-narrow">
                    <p className="image">
                    <img src="/images/floortypes/BoxOutsideCrawlSpace.jpg" alt="Above Grade" />
                    </p>
                </figure>
                <div className="media-content">
                    <div className="content is-small">
                        <Checkbox 
                            name="floorType"
                            label={floorTypeName(FloorType.AboveGrade)}
                            className="has-text-weight-bold"
                            value={FloorType.AboveGrade}
                            isChecked={selected === FloorType.AboveGrade}
                            onChange={handleCheckboxChange}
                        />
                        <p>
                            {translations.thereIsAVentilatedSpaceUnderTheRefrigeratedFloor_}
                            <br />
                            {translations.orThereIsAVentilatedSpaceUnderTheRoomInWhichTheRefrigeratedSpaceIsLocated_}
                            <br />
                            {translations.thisIncludesCrawlSpacesOrUpperFloorInstallations_}
                        </p>
                    </div>
                </div>
            </article>
            {props.isFreezer && (
                <>
                    <hr className="dropdown-divider" />
                    <article className="media">
                        <figure className="media-left is-narrow">
                            <p className="image">
                            <img src="/images/floortypes/BoxOutsideOnGradeHeatedFloor.jpg" alt="On Grade Heated" />
                            </p>
                        </figure>
                        <div className="media-content">
                            <div className="content is-small">
                                <Checkbox 
                                    name="floorType"
                                    label={floorTypeName(FloorType.OnGradeHeated)}
                                    className="has-text-weight-bold"
                                    value={FloorType.OnGradeHeated}
                                    isChecked={selected === FloorType.OnGradeHeated}
                                    onChange={handleCheckboxChange}
                                />
                                <p>
                                    {translations.theRefrigeratedFloorIsBuiltDirectlyOnTheGround_}
                                    <br />
                                    {translations.orTheRoomInWhichTheRefrigeratedBoxIsLocatedIsBuiltDirectlyOnTheGround_}
                                    <br />
                                    {translations.aNDAControlledFrostHeavePreventionHeaterSystemIsOrWillBeInstalled_}
                                </p>
                            </div>
                        </div>
                    </article>
                </>
            )}
            {props.showBoxSetting && props.boxSetting && (
                <>
                    <hr className="dropdown-divider" />
                    <article className="media">
                        <figure className="media-left is-narrow">
                            <p className="image">
                            &nbsp;
                            </p>
                        </figure>
                        <div className="media-content">
                            <div className="content is-small">
                                <Checkbox 
                                    name="floorType"
                                    label={`${translations.useBoxSetting} (${floorTypeName(props.boxSetting)})`}
                                    className="has-text-weight-bold"
                                    value={FloorType.BoxSetting}
                                    isChecked={selected === FloorType.BoxSetting}
                                    onChange={handleCheckboxChange}
                                />
                            </div>
                        </div>
                    </article>
                </>
            )}
        </div>
    );

    const modalFooter = (
        <>
            <Button
                name="submit-floortype"
                label={translations.oK}
                className="button is-link"
                onClick={handleOkClick}
            />
            <Button
                name="cancel-floortype"
                label={translations.cancel}
                className="button is-primary"
                onClick={handleCloseClick}
            />
        </>
    );
    
    const content = (
        <Modal 
            show={show} 
            title={translations.floorType}
            body={modalBody}
            footer={modalFooter}
            width={700}
            onCancelClick={handleCloseClick}
        />
    );

	return (
        <>
            {props.activator({setShow})}
            {content}
        </>
    );
};

export default FloorTypesModal;
