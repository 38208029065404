/* eslint-disable react/no-array-index-key */
import * as React from "react";

import ConfigurationService from "../../services/ConfigurationService";
import UnitOfMeasure from "../../models/helpers/UnitOfMeasure";
import { calculateLoadPercentage } from "../../models/helpers/LoadCalculations";

import ISystem from "../../models/interfaces/ISystem";

import { getLocationDescription } from "../../models/helpers/PrintPreview";

import useTranslationContext from "../../hooks/useTranslationContext";

import "./Print.css";

const table = {
	border: "1px solid black"
} as React.CSSProperties;

interface SystemsLoadSummaryProps {	
    divisionId: number;
	myInfo: any;
	customerInfo: any;
    projectSystems: ISystem[];
}

const SystemsLoadSummary: React.FunctionComponent<SystemsLoadSummaryProps> = (props: SystemsLoadSummaryProps) => {		

    const { translations } = useTranslationContext();

    return (
        <>
            {props.projectSystems && props.projectSystems.length > 0 && (	
                <div id="systems-load-summary" style={{width:"1070px"}} className="printpage">
                    <table
                        className="table is-bordered is-narrow is-fullwidth no-margin-table"
                        style={table}
                    >					
                        <tbody>
                            <tr>
                                <td style={{ textAlign: "center", fontWeight: "bold" }} className="two-columns">								
                                    <table className="table is-borderless" style={{ width: "100%", border: "0" }}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <img
                                                        src={ConfigurationService.getDivisionLogo(props.divisionId)}
                                                        alt={ConfigurationService.getDivisionLogoAltString(props.divisionId)}
                                                        style={{ width: "160px", marginTop: "10px" }}
                                                    />
                                                </td>
                                                <td>
                                                    <div style={{fontSize: "13px", fontWeight:"bold"}}>NATIONAL REFERIGERATION AND</div> 
                                                    <div style={{fontSize: "13px", fontWeight:"bold"}}>AIR CONDITIONING CANADA CORP.</div>
                                                    <div style={{fontSize: "11px", fontWeight:"normal"}}>
                                                        159 ROY BLVD. PO BOX 2020
                                                    </div>
                                                    <div style={{fontSize: "11px", fontWeight:"normal"}}>
                                                        BRANTFORD ON
                                                    </div>
                                                    <div style={{fontSize: "11px", fontWeight:"normal"}}>
                                                        CANADA N3T 5Y6
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td style={{ textAlign: "center", fontWeight: "bold" }} className="two-columns">
                                    <h2 style={{ textAlign: "center", marginTop: "5px" }}>
                                        <span style={{ color: "#000000", fontWeight: "bold", fontSize: "21px" }}>
                                            {translations.sYSTEMSLOADSUMMARY}
                                        </span>
                                    </h2>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table
                        className="table is-bordered is-narrow-cente is-fullwidth no-margin-table"
                        style={table}
                    >
                        <thead>
                            <tr>
                                <th className="two-columns header1">
                                    <b>{translations.preparedBy}</b>
                                </th>
                                <th className="two-columns header1">
                                    <b>{translations.preparedFor}</b>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ textAlign: "center", fontWeight: "bold", lineHeight: "17px" }}>
                                {props.myInfo?.Contact && (
                                    <div className="uppercase">
                                        {props.myInfo.Contact}
                                    </div>
                                )}
                                {props.myInfo?.Company && (
                                    <div className="uppercase">
                                        {props.myInfo.Company}
                                    </div>
                                )}
                                {props.myInfo?.Address1 && (
                                    <div className="uppercase">
                                        {props.myInfo.Address1}
                                    </div>
                                )}
                                {props.myInfo?.Address2 && (
                                    <div className="uppercase">
                                        {props.myInfo.Address2}
                                    </div>
                                )}
                                {(props.myInfo?.City || props.myInfo?.Region) && (
                                    <div className="uppercase">
                                        {props.myInfo.City ?? ""}
                                        ,&nbsp;
                                        {props.myInfo.Region ?? ""}
                                    </div>
                                )}
                                {(props.myInfo?.Postal || props.myInfo?.Country) && (
                                    <div className="uppercase">
                                        {props.myInfo.Postal ?? ""}
                                        &nbsp;
                                        {props.myInfo.Country ?? ""}
                                    </div>
                                )}
                                {props.myInfo?.Phone && (
                                    <div>
                                        Ph:&nbsp;
                                        {props.myInfo.Phone}
                                    </div>
                                )}
                                {props.myInfo?.Fax && (
                                    <div>
                                        Fx:&nbsp;
                                        {props.myInfo.Fax}
                                    </div>
                                )}
                                {props.myInfo?.eMail && (
                                    <div>
                                        Email:&nbsp;
                                        {props.myInfo.eMail}
                                    </div>
                                )}
                            </td>
                            <td style={{ textAlign: "center", fontWeight: "bold", lineHeight: "17px" }}>
                                {props.customerInfo?.Contact && (
                                    <div className="uppercase">
                                        {props.customerInfo.Contact}
                                    </div>
                                )}
                                {props.customerInfo?.Company && (
                                    <div className="uppercase">
                                        {props.customerInfo.Company}
                                    </div>
                                )}
                                {props.customerInfo?.Address1 && (
                                    <div className="uppercase">
                                        {props.customerInfo.Address1}
                                    </div>
                                )}
                                {props.customerInfo?.Address2 && (
                                    <div className="uppercase">
                                        {props.customerInfo.Address2}
                                    </div>
                                )}
                                {(props.customerInfo?.City || props.customerInfo?.Region) && (
                                    <div className="uppercase">
                                        {props.customerInfo.City ?? ""}
                                        ,&nbsp;
                                        {props.customerInfo.Region ?? ""}
                                    </div>
                                )}
                                {(props.customerInfo?.Postal || props.customerInfo?.Country) && (
                                    <div className="uppercase">
                                        {props.customerInfo.Postal ?? ""}
                                        &nbsp;
                                        {props.customerInfo.Country ?? ""}
                                    </div>
                                )}
                                {props.customerInfo?.Phone && (
                                    <div>
                                        Ph:&nbsp;
                                        {props.customerInfo.Phone}
                                    </div>
                                )}
                                {props.customerInfo?.Fax && (
                                    <div>
                                        Fx:&nbsp;
                                        {props.customerInfo.Fax}
                                    </div>
                                )}
                                {props.customerInfo?.eMail && (
                                    <div>
                                        Email:&nbsp;
                                        {props.customerInfo.eMail}
                                    </div>
                                )}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    {props.projectSystems.map((system, index) => {

                        return (
                            <table
                                className="table is-bordered is-narrow-cente is-fullwidth no-margin-table"
                                style={table}
                                key={index}
                            >
                                <thead>
                                    <tr>
                                        <th className="header1" colSpan={8}>
                                            <b>{`${translations.system}: ${system.systemName ?? ""}`}</b>
                                        </th>							
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{width: "20%", whiteSpace: "nowrap"}}><b>{translations.applicationTitle}</b></td>
                                        <td style={{width: "20%", whiteSpace: "nowrap"}}><b>{translations.location}</b></td>
                                        <td style={{width: "15%", whiteSpace: "nowrap"}}><b>{translations.interiorTemperature}</b></td>
                                        <td style={{width: "15%", whiteSpace: "nowrap"}}><b>{translations.exteriorTemperature}</b></td>
                                        <td style={{width: "15%", whiteSpace: "nowrap"}}><b>{translations.internalRH}</b></td>
                                        <td style={{width: "15%", whiteSpace: "nowrap"}}><b>{translations.externalRH}</b></td>							
                                    </tr>
                                    <tr>
                                        <td>{system.boxApplicationName}</td>
                                        <td>{getLocationDescription(system.boxLocation, translations)}</td>
                                        <td>{system.interiorTemperature ? `${UnitOfMeasure.getTemperatureDisplayValue(system.interiorTemperature, 1)} ${UnitOfMeasure.getTemperatureUnitLabel()}` : ""}</td>
                                        <td>{system.exteriorTemperature ? `${UnitOfMeasure.getTemperatureDisplayValue(system.exteriorTemperature, 1)} ${UnitOfMeasure.getTemperatureUnitLabel()}` : ""}</td>
                                        <td>{system.interiorHumidity ? `${UnitOfMeasure.getDisplayValue(system.interiorHumidity, 1)} ${UnitOfMeasure.getPercentUnitLabel()}` : ""}</td>
                                        <td>{system.exteriorHumidity ? `${UnitOfMeasure.getDisplayValue(system.exteriorHumidity, 1)} ${UnitOfMeasure.getPercentUnitLabel()}` : ""}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={6} style={{ padding: 0 }}>
                                            <table className="inner-table">
                                                <tbody>
                                                    <tr>
                                                        <td style={{width: "12.5%", whiteSpace: "nowrap"}}><b>{`${translations.wallLoad} ${calculateLoadPercentage(system).wallLoad}${UnitOfMeasure.getPercentUnitLabel()}`}</b></td>
                                                        <td style={{width: "12.5%", whiteSpace: "nowrap"}}>{system.totalWallLoad ? `${UnitOfMeasure.getRatingDisplayValue(system.totalWallLoad, 0)} ${UnitOfMeasure.getRatingUnitLabel()}` : ""}</td>
                                                        <td style={{width: "12.5%", whiteSpace: "nowrap"}}><b>{`${translations.infiltrationLoad} ${calculateLoadPercentage(system).infiltrationLoad}${UnitOfMeasure.getPercentUnitLabel()}`}</b></td>
                                                        <td style={{width: "12.5%", whiteSpace: "nowrap"}}>{system.totalInfiltrationLoad ? `${UnitOfMeasure.getRatingDisplayValue(system.totalInfiltrationLoad, 0)} ${UnitOfMeasure.getRatingUnitLabel()}` : ""}</td>
                                                        <td style={{width: "12.5%", whiteSpace: "nowrap"}}><b>{`${translations.productLoad} ${calculateLoadPercentage(system).productLoad}${UnitOfMeasure.getPercentUnitLabel()}`}</b></td>
                                                        <td style={{width: "12.5%", whiteSpace: "nowrap"}}>{system.totalProductLoad ? `${UnitOfMeasure.getRatingDisplayValue(system.totalProductLoad, 0)} ${UnitOfMeasure.getRatingUnitLabel()}` : ""}</td>
                                                        <td style={{width: "12.5%", whiteSpace: "nowrap"}}><b>{`${translations.miscellaneousLoad} ${calculateLoadPercentage(system).miscLoad}${UnitOfMeasure.getPercentUnitLabel()}`}</b></td>
                                                        <td style={{width: "12.5%", whiteSpace: "nowrap"}}>{system.totalMiscLoad ? `${UnitOfMeasure.getRatingDisplayValue(system.totalMiscLoad, 0)} ${UnitOfMeasure.getRatingUnitLabel()}` : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>{translations.designLoad}</b></td>
                                                        <td>{system.totalHourlyLoad ? `${UnitOfMeasure.getRatingDisplayValue(system.totalHourlyLoad, 0)} ${UnitOfMeasure.getRatingUnitLabel()}` : ""}</td>
                                                        <td><b>{translations.safetyLoad}</b></td>
                                                        <td>{system.totalLoadRequired ? `${UnitOfMeasure.getRatingDisplayValue(system.totalLoadRequired, 0)} ${UnitOfMeasure.getRatingUnitLabel()}` : ""}</td>
                                                        <td><b>{translations.runTime}</b></td>
                                                        <td>{system.runTimeHours ? `${UnitOfMeasure.getDisplayValue(system.runTimeHours, 0)} ${translations.hours}` : ""}</td>
                                                        <td><b>{translations.capacityRequired}</b></td>	
                                                        <td>{system.capacityRequired ? `${UnitOfMeasure.getRatingDisplayValue(system.capacityRequired, 0)} ${UnitOfMeasure.getRatingUnitLabel()}` : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>{translations.sYSTEMBALANCE}</td>
                                                        <td><b>{translations.sST}</b></td>
                                                        <td>{system.equipmentSelection.sstBalance ? `${UnitOfMeasure.getTemperatureDisplayValue(system.equipmentSelection.sstBalance, 1)} ${UnitOfMeasure.getTemperatureUnitLabel()}` : ""}</td>
                                                        <td><b>{translations.evaporatorTD}</b></td>
                                                        <td>{system.equipmentSelection.tdBalance ? `${UnitOfMeasure.getTemperatureDisplayValue(system.equipmentSelection.tdBalance, 1)} ${UnitOfMeasure.getTemperatureUnitLabel()}` : ""}</td>
                                                        <td><b>{translations.systemCapacity}</b></td>	
                                                        <td>{system.equipmentSelection.totalSystemCapacity ? `${UnitOfMeasure.getRatingDisplayValue(system.equipmentSelection.totalSystemCapacity, 0)} ${UnitOfMeasure.getRatingUnitLabel()}` : ""}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        );
                    })}
                </div>
            )}
        </>
	);
};

export default SystemsLoadSummary;
