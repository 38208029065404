import { measure, hasValue, roundOff, getNumericValue } from "../../types/measure";
import { getFormField, undefinedFormField } from "./FormFields";

import IAirProperty from "../interfaces/IAirProperty";
import ICurtain from "../interfaces/ICurtain";
import IFormField from "../interfaces/IFormField";
import IFormState from "../interfaces/IFormState";
import IOpening from "../interfaces/IOpening";
import IOpeningType from "../interfaces/IOpeningType";

import OpeningType from "../../enums/OpeningType";

import * as Opening from "./OpeningCalculations";

export const getOpeningTypeId = (
    state: IFormState<IOpening>,
    selectedOpeningTypeId: OpeningType | undefined
) => {
    return selectedOpeningTypeId ?? (state.actualValues.openingType ?? OpeningType.None);
};

export const getQuantity = (
	state: IFormState<IOpening>,
	enteredQuantity: measure
): IFormField<measure> => {

	const getDefaultValue = () => {
		return 1;
	};
	
	return getFormField<measure>(
		state,
		"quantity",
		roundOff(enteredQuantity, 0),
		getDefaultValue
	);
};

export const getDescription = (
	state: IFormState<IOpening>,
    openingType: IOpeningType
): IFormField<string> => {
	
	const defaultDescription = openingType.name;

	return {
		defaultValue: defaultDescription,
		actualValue: defaultDescription
	};
};

export const getWidth = (
	state: IFormState<IOpening>,
	enteredWidth: measure,
	openingType: IOpeningType
): IFormField<measure> => {

	const getDefaultValue = () => {
		return openingType.defaultWidth;
	};
	
	return getFormField<measure>(
		state,
		"width",
		enteredWidth,
		getDefaultValue
	);
};

export const getHeight = (
	state: IFormState<IOpening>,
    enteredHeight: measure,
    openingType: IOpeningType
): IFormField<measure> => {

	const getDefaultValue = () => {
		return openingType.defaultHeight;
	};
	
	return getFormField<measure>(
		state,
		"height",
		enteredHeight,
		getDefaultValue
	);
};

export const getArea = (
	state: IFormState<IOpening>,
	enteredArea: measure,
	width: measure,
	height: measure
): IFormField<measure> => {

	const getDefaultValue = () => {
		return Opening.calculateArea(width, height);
	};
	
	return getFormField<measure>(
		state,
		"area",
		enteredArea,
		getDefaultValue
	);
};

export const getExteriorTemperature = (
	state: IFormState<IOpening>,
	enteredExteriorTemperature: measure,
	sectionExteriorTemperature: measure
): IFormField<measure> => {

	const getDefaultValue = () => {
		return sectionExteriorTemperature;
	};
	
	return getFormField<measure>(
		state,
		"exteriorTemperature",
		enteredExteriorTemperature,
		getDefaultValue
	);
};

export const getStripCurtainId = (
    state: IFormState<IOpening>,
    selectedCurtainId: string | undefined
) => {
    return selectedCurtainId ?? (state.actualValues.stripCurtainId ?? "0");
};

export const getCurtainEffectiveness = (
	state: IFormState<IOpening>,
	curtain: ICurtain
): IFormField<measure> => {
	
	const getDefaultValue = () => {
		if (curtain.id === 0) {
			return 0;
		}
		return curtain.effectiveness;
	};
	
	return getFormField<measure>(
		state,
		"curtainEffectiveness",
		null,
		getDefaultValue
	);
};

export const getCurtainRValue = (
	state: IFormState<IOpening>,
	enteredCurtainRValue: measure,
	curtain: ICurtain
): IFormField<measure> => {
	
	const getDefaultValue = () => {
		return curtain.rValue;
	};
	
	return getFormField<measure>(
		state,
		"curtainRValue",
		enteredCurtainRValue,
		getDefaultValue
	);
};

export const getPassagesPerDay = (
	state: IFormState<IOpening>,
	enteredPassagesPerDay: measure,
	openingType: IOpeningType
): IFormField<measure> => {

	const getDefaultValue = () => {
		return openingType.defaultPassagesPerDay;
	};
	
	return getFormField<measure>(
		state,
		"passagesPerDay",
		enteredPassagesPerDay,
		getDefaultValue
	);
};

export const getAveragePassageTime = (
	state: IFormState<IOpening>,
    enteredAveragePassageTime: measure,
	openingType: IOpeningType
): IFormField<measure> => {

	const getDefaultValue = () => {
		return openingType.defaultAveragePassageTime;
	};
	
	return getFormField<measure>(
		state,
		"averagePassageTime",
		enteredAveragePassageTime,
		getDefaultValue
	);
};

export const getStandOpenTimePerDay = (
	state: IFormState<IOpening>,
    enteredStandOpenTimePerDay: measure,
	openingType: IOpeningType
): IFormField<measure> => {

	const getDefaultValue = () => {
		return openingType.defaultStandOpenTimePerDay;
	};
	
	return getFormField<measure>(
		state,
		"standOpenTimePerDay",
		enteredStandOpenTimePerDay,
		getDefaultValue
	);
};

export const getRValueEffective = (
	state: IFormState<IOpening>,
	enteredRValueEffective: measure,
	openingType: IOpeningType,
	curtainValue: measure
): IFormField<measure> => {

	const getDefaultValue = () => {
		if (openingType.id === OpeningType.PermanentOpening
			&& hasValue(curtainValue)
		) {
			return curtainValue;
		}

		return openingType.defaultRValueEffective;
	};
	
	return getFormField<measure>(
		state,
		"rValueEffective",
		enteredRValueEffective,
		getDefaultValue
	);
};

export const getConductionLoad = (
	state: IFormState<IOpening>,
	enteredConductionLoad: measure,
	openingType: IOpeningType,
	area: measure,
    exteriorTemperature: measure,
    rValueEffective: measure,
    curtainValue: measure,
    standOpenTimePerDay: measure,
    averagePassageTime: measure,
    passagesPerDay: measure,
    boxInteriorTemperature: measure
): IFormField<measure> => {
	
	const getDefaultValue = () => {
		return Opening.calculateConductionLoad(
			area,
			exteriorTemperature,
			rValueEffective,
			curtainValue,
			standOpenTimePerDay,
			averagePassageTime,
			passagesPerDay,
			boxInteriorTemperature,
			openingType
		);
	};

	if (!openingType.hasConductionLoadField) {
		return undefinedFormField;
	}
	
	return getFormField<measure>(
		state,
		"conductionLoad",
		enteredConductionLoad,
		getDefaultValue
	);
};

export const getInfiltratingAirRh = (
	state: IFormState<IOpening>,
	enteredInfiltratingAirRh: measure,
	sectionExteriorHumidity: measure
): IFormField<measure> => {

	const getDefaultValue = () => {
		return sectionExteriorHumidity;
	};
	
	return getFormField<measure>(
		state,
		"infiltratingAirRh",
		enteredInfiltratingAirRh,
		getDefaultValue
	);
};

export const getInfiltrationLoad = (
	state: IFormState<IOpening>,
	enteredInfiltrationLoad: measure,
	openingType: IOpeningType,
	width: measure,
	height: measure,
    area: measure,
    infiltratingAirRh: measure,
    curtainEffectiveness: measure,
    standOpenTimePerDay: measure,
    averagePassageTime: measure,
	passagesPerDay: measure,
	exteriorTemperature: measure,
    exteriorTemperatureAirProperty: IAirProperty | undefined,
	boxInteriorTemperature: measure,
	boxInteriorTemperatureAirProperty: IAirProperty | undefined,
	boxInteriorRh: measure
): IFormField<measure> => {

	const getDefaultValue = () => {
		if (exteriorTemperatureAirProperty && boxInteriorTemperatureAirProperty) {
			return Opening.calculateInfiltrationLoad(
				width,
				height,
				area,
				infiltratingAirRh,
				curtainEffectiveness,
				standOpenTimePerDay,
				averagePassageTime,
				passagesPerDay,
				exteriorTemperature,
				exteriorTemperatureAirProperty,
				boxInteriorTemperature,
				boxInteriorTemperatureAirProperty,
				boxInteriorRh,
				openingType
			);
		}

		return undefined;
	};

	if (!openingType.hasInfiltrationLoadField) {
		return undefinedFormField;
	}

	if (hasValue(openingType.defaultInfiltrationLoad)) {
		return {
			defaultValue: openingType.defaultInfiltrationLoad,
			actualValue: openingType.defaultInfiltrationLoad
		};
	}
	
	return getFormField<measure>(
		state,
		"infiltrationLoad",
		enteredInfiltrationLoad,
		getDefaultValue
	);
};