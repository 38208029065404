import HelperService from "../HelperService";
import LocalizedStrings, { LocalizedStringsMethods } from "react-localization";
import { enStrings } from "./en";
import { frStrings } from "./fr";
import { esStrings } from "./es";
import IToolTips from "../../models/interfaces/ITooltips";

export interface LocaleStrings extends LocalizedStringsMethods, IToolTips {
	_Feeds: string;
	_threeDollarSigns: string;
	_Cancel: string;
	_RDPARTY: string;
	_PreviousModel: string;
	_SelectPreviousModel: string;
	_TD: string;
	a_ZDescription: string;
	a_ZName: string;
	aConfidentialErrorReportWillBeSentToTheSoftwareDevelopmentTeam_: string;
	aConstructionTypeNameIsNotEntered_: string;
	aCriticalErrorOccurredInTheApplication_: string;
	aDescriptionHasNotBeenEntered_: string;
	aProblemOccurredWhileRegisteringYourNewPassword_: string;
	aProblemOccurredWhileRegisteringYourNewUserInformation_: string;
	aProgramOrDataUpdateIsAvailable_: string;
	aProgramUpdateIsAvailable_: string;
	aPurchaseOrderNumberIsRequired_: string;
	aSurchargeWillApply_: string;
	a_C_L_: string;
	a_C_T_: string;
	a_T_D_: string;
	aCT: string;
	aIRCOOLEDCONDENSOR: string;
	aIRFLOW: string;
	aLL: string;
	aLLAPPLICABLETAXESEXTRA: string;
	aLLCLASSES: string;
	aLLMODELS: string;
	aLTITUDE: string;
	aLTITUDEFACTOR: string;
	aMBIENT: string;
	aMPS: string;
	aNY: string;
	aPPLICATIONFAILURE: string;
	aT: string;
	aTHR: string;
	aTTENTION: string;
	abort: string;
	aborted_NoUPDATESFolderFound_: string;
	about: string;
	aboutNROES: string;
	aboutThisApplication: string;
	active: string;
	activeOrderManager: string;
	activeOrders: string;
	activePurchaseOrderManager: string;
	activeRecordsShown: string;
	activity: string;
	actual: string;
	actualAir_T: string;
	actualCondensingTemperature: string;
	actualFluidFlowRate: string;
	actualFluidOutletTemperature: string;
	actualFluidTD: string;
	actualFluid_T: string;
	actualTDCanNotBeGreaterThan: string;
	actualTDCanNotBeLessThan: string;
	actualTDMustBeGreaterThanZero_: string;
	add: string;
	add_EditOptions: string;
	addBlankQuoteRevision: string;
	addCatalogueModel: string;
	addConnection: string;
	addCustomSection: string;
	addFeed: string;
	addLoad: string;
	addModel: string;
	addModelPriceAdjustment: string;
	addMoreConnections: string;
	addNon_CatalogueItem: string;
	addNon_CatalogueOption: string;
	addParts: string;
	addProduct: string;
	addSectionAndStartAnotherNewOne_Insert_: string;
	addSelectedPartTypesToOrder: string;
	addServiceParts: string;
	addSystem: string;
	addSystemFreightAllowance: string;
	addSystemOption: string;
	addToOrder: string;
	addWall: string;
	addANewSystem_: string;
	addAsNewItems_: string;
	addBlankQuoteRevision_: string;
	addClipboardSystemItemsToCurrentSystem_: string;
	addContactToYourDatabase_: string;
	addNewQuoteRevision: string;
	addOrEditOptions: string;
	additionalE_MailText: string;
	additionalInstructions_Notes: string;
	additionalOrderingInstructions: string;
	address: string;
	adjustAllMultipliers: string;
	adjustOrderPrice: string;
	adjustPrice: string;
	adjustM: string;
	adjustedPrice: string;
	adjustment: string;
	again: string;
	airCooledCondenserDesignTools: string;
	airCooledCondenserDesigner_FitAModel: string;
	airCooledCondenserDesigner_FitAJob: string;
	airCooledCondenserModelSelector: string;
	airCooledCondensingUnit: string;
	airFlow: string;
	airFlowDirection: string;
	airIn: string;
	airIn_DB_: string;
	airIn_WB_: string;
	airInletTemperature: string;
	airInletTemperature_DB_: string;
	airInletTemperature_WB_: string;
	airInletTemperature_Dry: string;
	airInletTemperature_Wet: string;
	airInletTemperatureExceedsFanRating_: string;
	airOut: string;
	airOutletTemperature: string;
	airOutletTemperatureExceedsFanRating_: string;
	airOutletTemperatureLessThanWetBulbTemperature_: string;
	airflow: string;
	allImperial: string;
	allMetric_SI_: string;
	allOrders: string;
	allCertifiedPrintsHaveBeenSigned: string;
	allListedOrdersWereMarkedAsClosed_: string;
	allListedOrdersWereMarkedAsShipped_: string;
	allOrderItemsWillBeShippedAtTheLongestLeadTime_: string;
	allParametersChecked_: string;
	allPricesWereUpToDate_: string;
	allSelectedPartsAlreadyExistOnTheOrder_: string;
	allSystemDesignTemperaturesHaveBeenChecked: string;
	altitude: string;
	altitudeFactor: string;
	altitudeIsNotSet_: string;
	aluminum: string;
	ambient: string;
	ambientTemp: string;
	ambientTemperature_BoxTemperature_: string;
	ambientTeperatureMustBeGraterThan: string;
	ambientTeperatureMustBeLessThan: string;
	amount: string;
	amountPer: string;
	anApplicationUpdateIsAvailable_: string;
	anAuthorizationRequestHasBeenSentToTheRegistrar_: string;
	anErrorOccurredRunningTheJob_ScopeTranslation_: string;
	anIssueOccuredTryingToApplyForRegistration_: string;
	anIssueOccurredWhileInstallingDataUpdates_: string;
	anUnexpectedErrorOccured_: string;
	anUnexpectedErrorOccurred_: string;
	anUnexpectedErrorOcurred_: string;
	anUpdatedPurchaseOrderIsAvailableOnRequest: string;
	applicationRegistrationCodeRequested_: string;
	applicationTitle: string;
	applicationDataFileHaveNotBeenDownloaded_: string;
	applicationMustBeOn_line_: string;
	applicationWillExpireAfter__Days_: string;
	applicationWillNowClose_: string;
	archivedRecordsShown: string;
	areYouARegisteredUser_: string;
	areYouSureThisIsTheRightNumber_: string;
	areYouSureYouWantToCreateABrandNewOrder_: string;
	areYouSureYouWantToDeleteTheSelectedItems_: string;
	areYouSureYouWantToDeleteTheSelectedSystem_: string;
	areYouSureYouWantToDeleteThisChangeOrder_: string;
	areYouSureYouWantToDeleteThisContact_: string;
	areYouSureYouWantToDeleteThisQuoteRevision_: string;
	areYouSureYouWantToDoThis_: string;
	areYouSureYouWantToOpenTheServerVersion_: string;
	areYouSureYouWantToPlaceThisOrderOnHold_: string;
	areYouSureYouWantToRejectThisOrder_: string;
	areYouSureYouWantToSetThisValue_: string;
	areYouSure_: string;
	area: string;
	areaIsNotCalculatedOrEntered_: string;
	atLeastOneConnectionErrorExists_: string;
	atLeastOneConnectionWarningExists_: string;
	atLeastOneDoorHasAnEntryOrCalculationError_: string;
	atLeastOneDoorHasAnEntryOrCalculationWarning_: string;
	atLeastOneLayerErrorExists_: string;
	atLeastOneLayerWarningExists_: string;
	atLeastOneListedProductHasAWarning_: string;
	atLeastOneListedProductHasAnError_: string;
	atLeastOneOtherLoadHasAWarning_: string;
	atLeastOneOtherLoadHasAnError_: string;
	atLeastOneRequestedDateIsSetToPriorToThe: string;
	atLeastOneRevisionWasAlteredByAnotherUserOrOnAnotherWorkstation_: string;
	atLeastOneSectionHasErrors_: string;
	atLeastOneSectionHasErrorsOrWarnings_: string;
	atLeastOneSectionHasWarnings_: string;
	atThisTime_YourOriginalOrderHasNotBeenProcessedByOurOrderDepartment_: string;
	atThisTime_YourOriginalRequestHasNotBeenProcessedByThePurchaser_: string;
	atThisTime_YourPreviousChangeRequestHasNotBeenProcessedByOurOrderDepartment_: string;
	atThisTime_YourPreviousChangeRequestHasNotBeenProcessedByThePurchaser_: string;
	availableOptions: string;
	availablePriceBooks: string;
	averageWork_: string;
	bUILDING: string;
	back: string;
	badRatingColumnCount_: string;
	badRatingRowCount_: string;
	ballyLoadCalcFiles: string;
	ballyRefrigeratedBoxes: string;
	baseModel: string;
	belowFluid_sRatedMinimumTemperature_: string;
	bestFit: string;
	billOfLading: string;
	blankTubes: string;
	blanks: string;
	both: string;
	box: string;
	boxDesigner: string;
	boxExteriorRelativeHumidity___: string;
	boxExteriorTemperature: string;
	boxInteriorRelativeHumidity___: string;
	boxInteriorTemperature: string;
	boxLocation: string;
	boxTemp: string;
	boxTemperature: string;
	boxInformationIsMissing_: string;
	browse: string;
	building: string;
	button: string;
	by: string;
	cALCULATEDDETAILS: string;
	cALCULATOR: string;
	cALL: string;
	cAPACITY: string;
	cERTIFIEDPRINTWIZARD: string;
	cHARGE: string;
	cHARGE_FORCED_: string;
	cIRCUITTOTAL: string;
	cL: string;
	cOLLECT: string;
	cOMP: string;
	cOMPLETE: string;
	cOMPLEXFLOORPLANNER: string;
	cOMPRESSOR: string;
	cOMPRESSORUNITSELECTION: string;
	cONDENSINGUNITSELECTION: string;
	cONNECTIONDIAGRAM: string;
	cONNECTIONS: string;
	cONTINUEDONPAGE: string;
	cOULDNOTLOADPRICEBOOK: string;
	cOULDNOTREADCURRENTORDERSUPLOADDATA_: string;
	cPWIZ_Splash: string;
	cREATENEWORDER: string;
	cUSTOMER: string;
	calculate: string;
	calculatedDetails: string;
	calculatedFluidFlowRate: string;
	calculatedValue: string;
	calculatedCondensingTemperatureCanNotBeGreaterThan: string;
	calculatedCondensingTemperatureCanNotBeLessThan: string;
	calculatedCondensingTemperatureMustBeGreaterThanTheAmbientTemperature_: string;
	calculatedCondensingTemperatureMustBeGreaterThanTheSuctionTemperature_: string;
	calculatedShipDatesMayBeAnApproximation_: string;
	calculateSystemCharge: string;
	calendar: string;
	canNotCalculateAirOutTemperature_: string;
	canNotCalculateFluidOutTemperature_: string;
	canNotCalculateTotalHeatTransmission_: string;
	canNotAddServiceParts_: string;
	canNotBeGreaterThanFluidTemperatureRange_: string;
	canNotBeGreaterThanWetBuldTemperature_: string;
	canNotCheckPrices_: string;
	canNotContinue_: string;
	canNotCopy_: string;
	canNotDeleteFactoryOrderFile_: string;
	canNotDeleteOldOrder_: string;
	canNotDeleteOrder_: string;
	canNotDeleteThisOrder_: string;
	canNotFetchOrderFile_: string;
	canNotOpenOrder_: string;
	canNotPaste_: string;
	canNotPreview_: string;
	canNotPrint_: string;
	canNotReadFile_: string;
	canNotReadOrderFile_: string;
	canNotRecoverEdits_: string;
	canNotSetRejectedStatus_: string;
	canNotStartApplicationShell_: string;
	canNotStartApplication_: string;
	canNotStopRequest_: string;
	canNotStoreOrderFile_: string;
	canNotSubmitChange_: string;
	canNotSubmitOrder_: string;
	canNotSwitchPriceBooks_: string;
	canNotUploadOrder_: string;
	canadian: string;
	canadianEnglish: string;
	cancel: string;
	cancelThisOperation: string;
	cancelThisOperation_: string;
	cancelUpload_: string;
	cancellingDuringTheQuickboxWalkThroughWillCloseTheTool_: string;
	cannotContinue_: string;
	cannotCreateBackupFolder: string;
	cannotDeleteBackupFolder_: string;
	cannotInstallUpdateFolder: string;
	cannotStartTool_: string;
	cannotUpdate_: string;
	capacity: string;
	capacityExceedsRequestedRange_: string;
	capacityPerUnit: string;
	capacityRange___: string;
	capacityTooLow_: string;
	capacityVariance: string;
	capacityCanNotBeBlank_: string;
	capacityIsNotCalculated_: string;
	capacityIsZero_: string;
	capacityMustBeGreaterThanZero_: string;
	caption: string;
	carrier: string;
	cascade: string;
	catalogModel: string;
	catalogueNotLoaded_: string;
	ceiling: string;
	centreDistance: string;
	certainOptionsMayNoLongerBeAvailable_: string;
	certifiedDrawing: string;
	certifiedPrint: string;
	certifiedPrintWizard: string;
	certifiedPrints: string;
	challengeAnswer: string;
	challengeQuestion: string;
	change_Shape: string;
	changeAllMultipliers: string;
	changeOrderForm: string;
	changePassword: string;
	changeReq: string;
	changeRequest: string;
	changesNotYetAppliedToCurrentOrder_: string;
	changesToTheCurrentOrderHaveNotBeenSaved_: string;
	checkingForUpdates: string;
	circuitLoadCanNotBeCalculated_: string;
	class: string;
	clear: string;
	clearDate: string;
	clickHereToAdjustTheMultipliersToGenerateTheNewPrice_: string;
	clickHereToApplyThePriceChangeAndReturnToTheOrderWindow_: string;
	clickHereToCloseThisFormWithoutChangingTheOrder_: string;
	clickHereToResetAllMultipliersToTheirDefaultValues_: string;
	clickOnNOToCancelThisSaveOperation_: string;
	clickingOnNoWillKeepTheCurrentOwner_sMultiplierStructure_: string;
	clipboardContainsACompleteSystem_: string;
	close: string;
	closeAllOrders: string;
	closeOrder: string;
	closeOrderManager: string;
	closeShellWhenICloseTheLastDesignerWindow: string;
	closed: string;
	closingThemMayResultInLossOfData_: string;
	code: string;
	collect: string;
	columns: string;
	compCapacity: string;
	company: string;
	companyName: string;
	company_Address_Country_City_Region_PhoneFieldsAreAllRequired_: string;
	completeDirect: string;
	completeSquare: string;
	composite: string;
	compositeInsulationConstruction: string;
	compositeK_Factor: string;
	compositeR_Value: string;
	compressor: string;
	compressorBalanceSSTTooHigh_: string;
	compressorBalanceSSTTooLow_: string;
	compressorCapacityIsRequired_: string;
	compressorCapacityMustBeGreaterThanZero_: string;
	compressorUnitCapacityError_: string;
	compressorUnitCapacityNotCalculated_: string;
	compressorUnitNotSelected_: string;
	compressorWithCondenser: string;
	concentration: string;
	condTemp: string;
	cond_Temp: string;
	condenserBalanceSSTTooHigh_: string;
	condenserBalanceSSTTooLow_: string;
	condenserCapacityError_: string;
	condenserCapacityNotCalculated_: string;
	condenserContainsAtLeastOneError_: string;
	condenserContainsAtLeastOneWarning_: string;
	condenserNotSelected_: string;
	condensing: string;
	condensingTemp: string;
	condensingTemp_S_C_T__: string;
	condensingTemperature: string;
	condensingUnit: string;
	condensingUnitAmbientTemperature: string;
	condensingTemperature_AmbientTemperature_: string;
	condensingTemperature_BoxTemperature_: string;
	condensingTemperatureCanNotBeGreaterThan: string;
	condensingTemperatureCanNotBeLessThan: string;
	condensingTemperatureIsLessThanSiteAmbientTemperature_: string;
	condensingTemperatureIsMissing_: string;
	condensingTemperatureIsRequired_: string;
	condensingTemperatureMustBeGreaterThanTheAmbientTemperature_: string;
	condensingTemperatureMustBeGreaterThanTheSuctionTemperature_: string;
	condensingUnitBalanceSSTTooHigh_: string;
	condensingUnitBalanceSSTTooLow_: string;
	condensingUnitCapacityError_: string;
	condensingUnitCapacityNotCalculated_: string;
	condensingUnitNotSelected_: string;
	conduction: string;
	configuration: string;
	confirmationIsRequired_: string;
	confirmationRequired_: string;
	connected: string;
	connectionDetails: string;
	connectionSize: string;
	connectionCrossesTheCentreLineOfTheUnit_SomeOptionsMayNotBeAvailable_: string;
	connectionIsOutsideBoundsOfTheUnit_: string;
	connectionNotAssignedToFeeds_: string;
	connections: string;
	construction_Insulation: string;
	constructionLayer: string;
	constructionLayers: string;
	contact: string;
	contactName: string;
	contacts: string;
	continued: string;
	copper: string;
	copy: string;
	copyCurrentQuoteAsNewRevision: string;
	copyCurrentRevisionEditsAsNewRevision_AndRestoreThisRevisionToLastSavePoint: string;
	copySection: string;
	copyAsNewOrder: string;
	copyLocalOrderWithNewOrderNumber_ThenDownloadMasterVersion_: string;
	copyMasterVersionWithNewOrderNo_ThenReplaceMasterVersionWithLocalCopy: string;
	copyQuote: string;
	copyToShipToAddress: string;
	copyToSoldToAddress: string;
	couldNotAccessRecoveryData_: string;
	couldNotAddNewContactRecord_: string;
	couldNotCalculateActualTD_: string;
	couldNotCalculateDesignTD_: string;
	couldNotCalculateDesignCircuitLoad_: string;
	couldNotCalculateDesignHeatOfCompressionFactor_: string;
	couldNotCalculateDesignTotalHeatRejected_: string;
	couldNotCalculateRefrigerantFactor_: string;
	couldNotCalculateSiteAltitudeFactor_: string;
	couldNotCalculateTheTotalHeatRejected_: string;
	couldNotChangeMasterRevision_: string;
	couldNotChangePassword_: string;
	couldNotConnectToServer_: string;
	couldNotCreateArchivePath_: string;
	couldNotCreateFactoryOrder_: string;
	couldNotCreateNewContactRecord_: string;
	couldNotCreateOrderStorePath_: string;
	couldNotDeleteFile: string;
	couldNotDeleteFile_: string;
	couldNotDeleteOneOfTheSelectedItems_: string;
	couldNotDeleteOrderStorePath_: string;
	couldNotDeleteSomeOfTheSelectedItems_: string;
	couldNotDeleteTheSelectedItem_: string;
	couldNotDownloadThisOrder_: string;
	couldNotExportOrderFile_: string;
	couldNotExtrapolateCompressorRating_: string;
	couldNotExtrapolateCondenserRating_: string;
	couldNotExtrapolateCondensingUnitRating_: string;
	couldNotExtrapolateEvaporatorRating_: string;
	couldNotFindArchivePath_: string;
	couldNotFindOrderFile_: string;
	couldNotGenerateTargetValue_: string;
	couldNotImportOrderFile_: string;
	couldNotListLocalOrders_: string;
	couldNotLoadModelCatalogue_: string;
	couldNotLoadServicePartsDatabase_: string;
	couldNotLockLocalOrderFile_: string;
	couldNotLockOrderFile_: string;
	couldNotLockRemoteOrder_: string;
	couldNotLockSubmittedOrder_: string;
	couldNotOpenDatabase_: string;
	couldNotOpenExportFile_: string;
	couldNotOpenOrder_: string;
	couldNotReadCalculatorFile_: string;
	couldNotReadDatabase_: string;
	couldNotReadFactoryCalendar_: string;
	couldNotReadFile_: string;
	couldNotReadOrderData_: string;
	couldNotReadOrderHeader_: string;
	couldNotReadOrderStatusFile_: string;
	couldNotReadResponse_: string;
	couldNotReadSynchronizedOrderStatusFile_: string;
	couldNotRetrieveAssociatedPurchaseRequest_: string;
	couldNotRetrieveOrderFile_: string;
	couldNotRetrieveOrderHeader_: string;
	couldNotStartShellApplication_: string;
	couldNotStartShell_: string;
	couldNotStoreOrderFileToPath: string;
	couldNotSubmitOrder_: string;
	couldNotSynchroniseRemoteOrderFile_: string;
	couldNotSynchronizeAddressBook_: string;
	couldNotSynchronizeOrder_: string;
	couldNotSynchronizeOrders_: string;
	couldNotSynchronizeServerOrders_: string;
	couldNotUpdateContactRecord_: string;
	couldNotUpdateOrderDatabase_: string;
	couldNotUpdateOrderOnNROESServer_: string;
	couldNotUpdatePurchaseRequestStatusOnServer_: string;
	couldNotUploadLocalVersion_: string;
	couldNotUploadOrder: string;
	couldNotUploadRevision_: string;
	couldNotWriteOrderFile_: string;
	country: string;
	countryName: string;
	countyOrRegion: string;
	coveredOpening: string;
	createAnotherChangeOrder: string;
	createChangeOrder: string;
	createANewListing_: string;
	criticalError_: string;
	currentPrice: string;
	currentMargin: string;
	currentOrderPrice: string;
	currentQuotePrice: string;
	currentChangesWillBeLost_: string;
	currentManufacturersMinimumPrice_: string;
	currentManufacturersRecommendedPrice_: string;
	currentOrderPrice_: string;
	currentSetting_: string;
	currentlyThereAreNoServicePartsListedForThisModel_: string;
	cust_: string;
	custName: string;
	custom: string;
	customCeiling: string;
	customFloor: string;
	customWall: string;
	customer: string;
	customer_: string;
	customerContact: string;
	customerInfo: string;
	customerName: string;
	customerREF_: string;
	customerRef: string;
	customerDatabaseIsMissing_: string;
	customerNumberEnteredIsAlreadyListedAbove__: string;
	customerNumberEnteredIsNotInTheListShown_: string;
	customerReferenceMustBeNumeric_: string;
	customerReferenceMustBeSixCharacters_: string;
	cut: string;
	cutItemsRequested_: string;
	cutSystemRequested_: string;
	dESCRIPTION: string;
	dESCRIPTION_REMARKS: string;
	dESIGNDATA: string;
	dESIGNTOOLS: string;
	dESIGNER: string;
	dETAILS: string;
	dISTRIBUTOR: string;
	dIV: string;
	dIVISION: string;
	dONOTUseThisFunctionToChangeAnExistingOrder_: string;
	dOCUMENTATIONBROWSER: string;
	dRAFT: string;
	dataUpdatesFound: string;
	dataRecovery_: string;
	dateCreated: string;
	dateModified: string;
	dateOK: string;
	dateQuoted: string;
	dateCanNotBeOnAWeekend_: string;
	dateChanged: string;
	dateIsPriorToToday_: string;
	dateMustBeInTheFuture_: string;
	dateVerifiedChanged: string;
	days_: string;
	defaultNumericUnits: string;
	defaultUnits: string;
	delete: string;
	deleteChangeOrder: string;
	deleteCustomSection: string;
	deleteCustomSections: string;
	deleteLayer: string;
	deleteLayers: string;
	deleteLoad: string;
	deleteOrder: string;
	deleteProduct: string;
	deleteTheSelectedSystem_: string;
	deltaX: string;
	deltaY: string;
	dep_Div: string;
	department: string;
	depth: string;
	depth_IsARequiredValue_: string;
	description: string;
	descriptionCanNotBeBlank_: string;
	descriptionIsMissing_: string;
	deselectingThisPackageWillAlsoDeselectALLThoseOptionsIncludedInThatPackage_: string;
	designAmbient: string;
	designConnections: string;
	designRequirements: string;
	designTD: string;
	designTDCanNotBeGreaterThan: string;
	designTDCanNotBeLessThan: string;
	designTDMustBeGreaterThanZero_: string;
	designTHRNotEntered_: string;
	designTools: string;
	designToolsShellOptions: string;
	designCondensingTemperatureCanNotBeGreaterThan: string;
	designCondensingTemperatureCanNotBeLessThan: string;
	designConnectionContainsErrorsOrWarnings_: string;
	designContainsErrorsOrWarnings_: string;
	designHasConfigurationErrors_: string;
	designHasConfigurationWarnings_: string;
	designHasRequirementErrors_: string;
	designHasRequirementWarnings_: string;
	designNotSaved: string;
	designer: string;
	details: string;
	detailsLabel: string;
	dialog: string;
	dialog_Product_Selector: string;
	differenceBetweenCurrentPriceAndFloorPrice_: string;
	differenceBetweenNewPriceAndCurrentPrice_: string;
	differenceBetweenNewPriceAndFloorPrice_: string;
	dim_: string;
	dimension: string;
	dimension_IsNotEntered_: string;
	dimensions: string;
	discardMyChanges_: string;
	distance: string;
	divisionsAvailable: string;
	doNotCheckTheAboveIfThis_WorkstationIsUsedByOtherPeople: string;
	doNotShowThisMessageAgain: string;
	doYouWantChangeAttachedOptionsToTheNewSetting_: string;
	doYouWantChangeTheAttachedOptionsToTheNewSetting_: string;
	doYouWantChangeTheseOptionsToTheNewIsSetting_: string;
	doYouWantChangeTheseOptionsToTheNewSetting_: string;
	doYouWantToApplyTheseSettingToTheCurrentOrder_: string;
	doYouWantToApplyYourPersonalMultiplierStructure_: string;
	doYouWantToBuildItNow_: string;
	doYouWantToChangeTheSoldByInformationToYourName_: string;
	doYouWantToDeleteTheSelectedOrderFromTheServerAndYourComputer_: string;
	doYouWantToDeleteTheSelectedOrderFromYourComputerOny_: string;
	doYouWantToOpenTheOrderAnyway_: string;
	doYouWantToOpenTheOrderNow_: string;
	doYouWantToRemoveTheOrderFromTheLocalStore_: string;
	doYouWantToReplaceYourExistingQuoteInformation_: string;
	doYouWantToReplicateThisChangeToAllItems_: string;
	doYouWantToReplicateThisDateToAllItems_: string;
	doYouWantToRestoreTheseOrders_: string;
	doYouWantToReviewYourErrorsNow_: string;
	doYouWantToRunInOff_lineMode_: string;
	doYouWantToSaveItNow_: string;
	doYouWantToSaveYourDataAnyway_: string;
	doYouWantToShutdownAnyway_: string;
	doYouWantToSubmitAnyway_: string;
	doYouWantToSubmitThisOrderAnyway_: string;
	doYouWantToUpdateTheOrderNow_: string;
	doYouWantToUpdateTheOrderToUseYourNewCatalogueProfileSettings_: string;
	doYouWantToUpdateTheOrderToUseYourNewMultiplierStructure_: string;
	doYouWantToUpdateThisRevisionToUseTheLatestPricebook_: string;
	doYouWishToRetry_: string;
	dockDoor: string;
	documentGenerator: string;
	documentContainsNoPages_: string;
	door: string;
	door_OpeningDetails: string;
	doorAndOpeningInfiltrationEstimate: string;
	doorsAndOpenings: string;
	downloadingApplicationShell: string;
	downloadingDataUpdate_: string;
	downloadingProgramUpdate_: string;
	downloadingUpdates: string;
	draft: string;
	draftC_O_: string;
	dualEnteringService: string;
	dualEnteringService__FanWideUnitWithSeperateControlsPerSide_: string;
	dualEnteringService__FanWideUnitsWithSeperateControlsPerSide_: string;
	e_Mail: string;
	e_Mail_UsernameCombinationAlreadyExists_: string;
	e_MailAddressAgain: string;
	e_MailAddresses: string;
	e_mailAddressesDoNotMatch_: string;
	eACH: string;
	eDIT: string;
	eNTERED: string;
	eNTEREDDESIGNREQUIREMENTS: string;
	eRROR: string;
	eRROR_: string;
	eRRORS: string;
	eULA: string;
	eVAPORATORMOTORS: string;
	eVAPORATORUNITSELECTION: string;
	eXITNROES: string;
	edit: string;
	editConstruction: string;
	editLayer: string;
	editLoad: string;
	editMode_: string;
	editModel: string;
	editProduct: string;
	editQuoteInformation: string;
	editSection: string;
	editSections: string;
	effectiveR_Value: string;
	effectiveR_ValuePerUnitDepth: string;
	either_ShippedClosedOrProcessedIsSet_: string;
	electricalPower: string;
	elevationIsTooHigh_: string;
	elevationIsTooLow_: string;
	emailAddress: string;
	ending: string;
	enter: string;
	enterANumberEndingInAPercent_SymbolIfRequired_: string;
	entered: string;
	enteredDesignRequirements: string;
	enteredValue_: string;
	enteredMultiplierIsLessThanTheRecommendedMinimumValue_: string;
	enteredPasswordDoNotMatch_: string;
	enteringTemperature: string;
	equipmentList: string;
	equipmentType: string;
	equipmentChangesOccurredOn: string;
	equipmentChangesOccurredOnOneRevision_: string;
	error: string;
	errorDetails_: string;
	errorsExistOnThisOrder_: string;
	evapTemp: string;
	evap_Temp: string;
	evaporatorBalanceSSTTooHigh_: string;
	evaporatorBalanceSSTTooLow_: string;
	evaporatorUnitCapacityError_: string;
	evaporatorUnitCapacityNotCalculated_: string;
	evaporatorUnitNotSelected_: string;
	exceedsAllModelMaxFanTemperatureSpecifications_: string;
	exceedsDryBulbTemperature_: string;
	exceedsFluid_sRatedMaximumTemperature_: string;
	excelCSVTextFiles: string;
	excelFile_AllListedParts_: string;
	excelFile_SelectedPartsOnly_: string;
	excludeDraftOrders: string;
	excludedArea: string;
	exit: string;
	exitNROES: string;
	exitOrderManager: string;
	expectedCarrier: string;
	expires: string;
	export: string;
	exportOrderFile: string;
	exportServicePartInformation: string;
	extTemp: string;
	extension: string;
	exteriorRH: string;
	exteriorTemperature: string;
	exteriorBoundaryLayer: string;
	externalRH: string;
	externalTemperature: string;
	fANLENGTH: string;
	fANMOTOR: string;
	fANWIDTH: string;
	fANS: string;
	fAX: string;
	fAXNumbers: string;
	fIN: string;
	fINSPACING: string;
	fINISH: string;
	fLA_FAN: string;
	fLUIDCOOLER: string;
	fORKLIFTS: string;
	fPI: string;
	faceArea: string;
	factoryInv_To: string;
	factoryJob: string;
	factoryJob_: string;
	factoryOrder: string;
	factorySettings: string;
	factoryStyle: string;
	factoryCalendarNotFound_: string;
	factoryOrderNotYetCreated_: string;
	failedToDownloadRequiredDataFiles_: string;
	familyMinimumOrderPriceNotReached_: string;
	fanConfiguration: string;
	fanCount: string;
	fans: string;
	fansLong: string;
	fansWide: string;
	faxNo: string;
	feeds: string;
	feedsAvailable: string;
	fileError_: string;
	fileIsNotAValidOrderFile_: string;
	fileRevisionNoLongerSupportByNROES_: string;
	fileStructureNotRecognized_: string;
	fileWasCreatedWithANewerVersionOfNROES_: string;
	filesDownloaded: string;
	filter: string;
	finBlockDimensions: string;
	finConstruction: string;
	finStyle: string;
	final: string;
	finalTemperature: string;
	findCustomer: string;
	finish: string;
	finsPerInch: string;
	fitAModel: string;
	fitAnApplication: string;
	flat: string;
	floor: string;
	floorArea: string;
	floorPrice: string;
	floorPlanIsNotCompleted_: string;
	fluid: string;
	fluidConcentration: string;
	fluidConcentrationNotSelected_: string;
	fluidCooler: string;
	fluidCoolerDesignTools: string;
	fluidCoolerDesigner: string;
	fluidCoolerDesigner_FitAJob: string;
	fluidCoolerDesigner_FitAModel: string;
	fluidCoolerModelSelector: string;
	fluidFlowRate: string;
	fluidFlowRateIsNotSet_: string;
	fluidFlowRatePerUnit: string;
	fluidFoulingFactor: string;
	fluidInletTemperature: string;
	fluidInletTemperatureIsNotSet_: string;
	fluidInletTemperatureMustBeSet_: string;
	fluidInletTemperatureNotCalculated_PleaseEnterOtherThreeValues_: string;
	fluidNotSelected_: string;
	fluidOutTemperature_AirTemp____C: string;
	fluidOutTemperatureGreaterThanFluidMaximumTemperature__C_: string;
	fluidOutTemperatureLessThanFluidMinimumTemperature__C_: string;
	fluidOutletTemperature: string;
	fluidOutletTemperatureIsNotSet_: string;
	fluidPressureDrop: string;
	fluidTemperatureRange: string;
	fluidType: string;
	fluidVelocity: string;
	fluidVelocityAboveRecommendedNominalRange_: string;
	fluidVelocityBelowRecommendedNominalRange_: string;
	fluidVelocityTooHigh_: string;
	fluidVelocityTooLow_: string;
	followTheInstructionsInTheEmailToCompleteYourNewUserRegistration_: string;
	forceUpdateWaiting: string;
	forkliftLoad: string;
	form_App_Menu: string;
	forms: string;
	formsMethod: string;
	fouling: string;
	foulingFactor: string;
	freeShippingIsAvailableOnThisOrder_SetTermsToPre_Paid_: string;
	freezeTemperature: string;
	freightTermsFOBBrantfordOntarioUnlessOtherwiseSpecified_: string;
	french: string;
	from: string;
	front: string;
	frontCut: string;
	frontInset: string;
	fuildFlow: string;
	fuildVolume: string;
	fullName: string;
	functionalityWillBeLimited_: string;
	fx_: string;
	gLYCOLCOIL: string;
	general: string;
	german: string;
	glycoilCoil_FitAJob: string;
	glycoilCoil_FitAModel: string;
	glycolCoil: string;
	glycolCoilDesignTools: string;
	glycolCoilDesigner: string;
	glycolCoilModelSelector: string;
	greek: string;
	guest: string;
	hEATERS: string;
	hELLO_WORLD_: string;
	hOCFactorNotAvailable_: string;
	hOLDING: string;
	heatOfRespiration: string;
	heavyUse: string;
	heavyWork_: string;
	height: string;
	heightIsARequiredValue_: string;
	held: string;
	help: string;
	holdChangeOrder: string;
	holdOrder: string;
	holding: string;
	holdingAnOrderWillSignalTheOriginatorThatTheyNeedToCorrectSomething_: string;
	horizontal: string;
	howDoYouWantToHandleThis_: string;
	howDoYouWantToResolveThisIssue_: string;
	howDoYouWantToStartTheNewQuote_: string;
	howeverYouMayLooseChangesIfTheOrderIsBeingEditedByAnotherUser_: string;
	hz: string;
	iACCEPTTHESETERMS: string;
	iDONOTACCEPTTHESETERMS: string;
	iForget: string;
	iHaveConfirmedItIsOkToUploadThisOrder_: string;
	iMPORTORDERFILE: string;
	iNREVIEW: string;
	iNFORMATIONISGIVENGRATISANDTHEMANUFACTURERASSUMESNOOBLIGTIONORLIABILITYFORACTUALRESULTSOBTAINED_: string;
	iNIFileMissingOrInvalid_: string;
	iNVALIDREFRIGERANT: string;
	iNVOICETO: string;
	iTEM: string;
	iTEMCHANGES: string;
	iTM: string;
	identityVerificationChallenge_E_g_FavouriteHockeyTeam: string;
	ifTheLoadIsNotEnteredManually_TheDesignMustIncludeAFloor_ACeiling_AndAtLeastFourWalls__: string;
	ifThisProblemPersistsPleaseContactTheFactory_: string;
	ifYouCanReplicateTheError_PleaseProvideDetailedStepByStepInstructions_: string;
	ifYouChoseNoYouWillNotBeAbleToRecoverTheseOrdersLater_: string;
	ifYouContinue_YourOutstandingChangeRequestWillBeStopped_: string;
	ifYouContinue_YourOutstandingOrderWillBeStopped_: string;
	ifYouContinue_YourOutstandingRequestWillBeStopped_: string;
	ifYouHaveIssuesWithTheTranslations_AndWouldLikeToHelp_PleaseClickOnTheButtonBelow_: string;
	ifYouHaveVerifiedThatItIsOkToUploadThisOrder_YouMayStillDoSo_: string;
	ifYouThinkTheNetworkIsAvailablePleaseContinueWaiting_: string;
	ifYouWantToSaveYourChangesAsANewQuoteRevision_DoTheFollowing_: string;
	ignore: string;
	ignoreForNow_: string;
	imageFiles: string;
	imperial: string;
	importExistingCondenserJobFile: string;
	importJobFile: string;
	importLoadCalcParameters: string;
	importLoadCalculatorFile: string;
	importLoadCalculatorModels: string;
	importOrder: string;
	importOrderFile: string;
	includeAllCompanyOrders: string;
	includeAllOfTheAbove: string;
	includeAllOrders: string;
	includeClosedOrders: string;
	includeDeletedOrders: string;
	includeHeldOrders: string;
	includeOrdersClosedWithinThePast__Days: string;
	includeOrdersRejectedWithinThePast__Days: string;
	includeOrdersShippedWithinThePast__Days: string;
	includeProcessedOrders: string;
	incompatibleServerVersion_: string;
	indicatesOptionIsShippedLoose: string;
	indicatesRevisionSpecificStatus: string;
	indicatesRequiredEntries: string;
	indoor: string;
	indoors: string;
	infiltratingAirRH: string;
	infiltration: string;
	infiltrationLoad: string;
	infiltrationLoadIsNotCalculatedOrEntered_: string;
	infiltrationLoadIsRarelyZero_: string;
	informationIncomplete_: string;
	informationRetreivalFailed_: string;
	initializing: string;
	insertLayer: string;
	insulation: string;
	insulation_Construction: string;
	insulationName: string;
	interiorBoundaryLayer: string;
	internalRH: string;
	internalTemperature: string;
	internalVolume: string;
	invalidPassword_: string;
	invalidChecksum_: string;
	invalidDateEntered_: string;
	invalidE_mailAddress_: string;
	invalidFileHeader_: string;
	invalidFileLength_: string;
	invalidHeaderLength_: string;
	invalidPromiseDate_: string;
	invalidQuantityEntered_: string;
	invoice: string;
	italian: string;
	item_: string;
	itemAdjustmentDetails: string;
	itemDescription: string;
	itemListPrice: string;
	itemMultiplier: string;
	itemName: string;
	itemNet: string;
	itemNo: string;
	itemPrice: string;
	itemQuantity: string;
	itemRequestedDate: string;
	itemTotal: string;
	itemTotalAfterAdjustments: string;
	itemWeight: string;
	itemsInBOLDAreRequired: string;
	itemsOnTheClipboard_: string;
	itemsOnThisSystemHaveErrors_: string;
	itemsOnThisSystemHaveWarnings_: string;
	itm_OptNotes: string;
	itm_OptTag: string;
	job_SiteDetails: string;
	job_SiteDetails_Preferences: string;
	jobMayBeUploaded_: string;
	jobNotBeUploaded_: string;
	job_ScopeREF_: string;
	job_ScopeReferenceNotReturned_: string;
	job_ScopeReturnFileNotFound_: string;
	kFactor: string;
	k_Factor: string;
	keepRiteLoadCalcFiles: string;
	keepRiteRefrigeration: string;
	keepriteRefrigeration: string;
	l_SHAPED: string;
	lIGHTING: string;
	lIQUID: string;
	lISTPRICE: string;
	lOAD: string;
	lOADCONNECTIONS: string;
	lOADINGCATALOGUE: string;
	lOC: string;
	lOCATION: string;
	lOCKED: string;
	lRA: string;
	label: string;
	label__Text: string;
	language: string;
	largeToolbarIcons: string;
	layer: string;
	leadTime: string;
	leadTimeIsDependentOnPartAvailabilityAndWillBeConfirmedAtTimeOfOrder_: string;
	leaveQuoteDateAsIs_: string;
	leftCut: string;
	leftEnd: string;
	leftInset: string;
	length: string;
	length_IsARequiredValue_: string;
	lightWork_: string;
	lightingLoad: string;
	lineFrequency: string;
	lineItem: string;
	liquidOut: string;
	listPrice: string;
	listPricesAreNotCurrentAndTheQuoteIsOlderThan__Days_: string;
	listPricesAreNotCurrent_: string;
	load: string;
	loadCalculator: string;
	loadCalculatorDesignTool: string;
	loadDifferentFile: string;
	loadFactor: string;
	loadingPriceBook: string;
	localVersionUploadTimestamp_: string;
	localOrdersQueried_: string;
	localVersionModificationTimestamp_: string;
	location: string;
	locations: string;
	longStorage: string;
	lookingForUserProfile_: string;
	m_R_P: string;
	mANUALOPTIONS: string;
	mAXSPARES: string;
	mAY: string;
	mCA_MOPAreForTheCondensingUnitONLY_SinglePointConnectionsWILLShowDifferentOnDataplate_: string;
	mESSAGENOTFOUND: string;
	mODELFEATURES: string;
	mODELINFORMATION: string;
	mODELOPTIONS: string;
	mODELTYPE: string;
	mODIFIED: string;
	mOP_: string;
	mOREINFO: string;
	mULT: string;
	mULTI_CIRCUIT: string;
	manufacturer: string;
	manufacturing: string;
	margin: string;
	markAllAsRead: string;
	markAsShipped: string;
	markRequestAsProcessed: string;
	markUp: string;
	markAsClosed: string;
	markAsRead: string;
	masterCatalogNotLoaded: string;
	match: string;
	matched: string;
	matches: string;
	matchingModels: string;
	material: string;
	materialName: string;
	materialLoadIsNotCalculatedOrEntered_: string;
	materialNameMustBeEntered_: string;
	materialTypeMustBeSelected_: string;
	maximum: string;
	maximumFluidFlowRateExceeded_: string;
	maximumOvercurrentProtection: string;
	measurementUnits: string;
	menuStrip: string;
	messages: string;
	metric_SI_: string;
	minimum: string;
	minimumCircuitAmpacity: string;
	minimumValue_: string;
	minimumOrderPriceNotReached_: string;
	minus: string;
	miscelaneousRefrigerationEquipment: string;
	miscellaneousLoad: string;
	miscellaneousLoads: string;
	miscellaneousSelected: string;
	missingPriceBook: string;
	model: string;
	model_: string;
	modelCapacity: string;
	modelConfiguration: string;
	modelFeatures: string;
	modelMatchingCriterea: string;
	modelNumber: string;
	modelOptions: string;
	modelPartNumber: string;
	modelSelector: string;
	modelIsNoLongerAvailable_: string;
	modelIsNoLongerListedInOurInventory_: string;
	modelMultiplierChanged_: string;
	modelsAvailable_: string;
	modifyStatus: string;
	moistureContent: string;
	motor: string;
	motorLoad: string;
	motorType: string;
	moveConnectionLeft: string;
	moveConnectionRight: string;
	moveDown: string;
	moveFirst: string;
	moveLast: string;
	moveLeft: string;
	moveRight: string;
	moveToSide: string;
	moveUp: string;
	multiplier: string;
	multiplierHasBeenResetToDefaultForThisModelType_: string;
	multiplierIsLessThanDefaultValue_: string;
	multiplierIsLessThanMinimumValue_: string;
	multipliersUpdated_: string;
	mustBeGreaterThanAirInletTemperature_DB__: string;
	mustBeGreaterThanFluidInletTemperature_: string;
	mustBeGreaterThanOrEqualToZero_: string;
	mustBeGreaterThenOrEqualToMinimumValue_: string;
	mustBeLessThanAirInletTemperature_DB__: string;
	mustBeLessThanFluidInletTemperature_: string;
	mustBeLessThenOrEqualTo_____: string;
	mustBeLessThenOrEqualToMaximumValue_: string;
	myInfo: string;
	myProfile: string;
	nToN: string;
	n_A_: string;
	nEW: string;
	nEWCOMPOSITE: string;
	nOPARTSFOUND_: string;
	nOParametersChanged_: string;
	nONE: string;
	nOTORDERED: string;
	nOTORDEREDQUOTE: string;
	nOTPRINTED_SET: string;
	nOTQUOTED: string;
	nOTSAVED: string;
	nOTSUBMITTEDORDER: string;
	nOTE: string;
	nR_Shell: string;
	nRDOCS_Splash: string;
	nROES: string;
	nROES_NotesEditor: string;
	nROESContact: string;
	nROESSERVER: string;
	nROESServerInterface: string;
	nROESServerIsNotAvailable_: string;
	nROESIsHavingIssuesCommunicatingWithTheServer_: string;
	nROES__: string;
	nR_DOCS_BROWSER: string;
	nationalRefrigeration: string;
	nationalRefrigerationAndAirConditioning: string;
	nationalRefrigerationDesignTools: string;
	nationalRefrigerationOrderEntrySystem_Bally: string;
	nationalRefrigerationOrderEntrySystem_KeepRite: string;
	nationalRefrigerationOrderEntrySystem_Trenton: string;
	nationalRefrigerationOrderFiles_: string;
	negativeTemperatureDifference_: string;
	neitherTheKFactorNorTheR_ValueHasBeenEntered_: string;
	neitherTheK_FactorNorTheR_ValueAreEnteredForTheMaterialMaterial_: string;
	neitherTheK_FactorNorTheR_ValueAreEnteredForTheDividerMaterial_: string;
	neitherTheK_FactorNorTheR_ValueHaveBeenSet_: string;
	netPrice: string;
	new: string;
	newJob_KnownLoads: string;
	newJob_KnownModel: string;
	newMargin: string;
	newMultipler: string;
	newOrder: string;
	newOrderPrice: string;
	newPassword: string;
	newPrice: string;
	newQuotePrice: string;
	newSystem_KnownLoads: string;
	newSystem_KnownModel: string;
	newUser: string;
	newNetPrice_: string;
	newPasswordMustBeAtLeastSixCharactersInLength_: string;
	newPasswordsDoNotMatch_: string;
	newServicePartsAddedToOrder_: string;
	newUserNameRegistered_: string;
	next: string;
	nextModel_: string;
	nextWallConstruction: string;
	noCountryEntered_: string;
	noDetails: string;
	noItemsOrSystemsSelected_: string;
	noModelSelected: string;
	noModelSelected_: string;
	noModelsAvailable: string;
	noPartNameEntered_: string;
	noAddressEntered_: string;
	noAlternativePriceBooksOfThisTypeAreAvailable_: string;
	noAmbientTempEntered_: string;
	noApplicationRegistrationOnformationWasFoundForYourUserID_: string;
	noBoxTempEntered_: string;
	noCalculationMethodSelected_: string;
	noChangesDetectedOnTheOrder_: string;
	noCityEntered_: string;
	noCondenserModelSelected_: string;
	noCondensingTempEntered_: string;
	noConnectionsDefined_: string;
	noConstructionLayersHaveBeenDefined_: string;
	noContactNameGiven_: string;
	noContactsListed_: string;
	noDeratingFactorListed_: string;
	noDescriptionEntered_: string;
	noDoorsEstimateCalculatedOrEntered_: string;
	noDoorsHaveBeenDefined_: string;
	noDoorsHaveBeenDefined_DefaultDoorLoadValueUsedForOneManDoor_: string;
	noEquipmentChangesFound_: string;
	noEquipmentChangesOccurred_: string;
	noEvaporatorMotorLoadHasBeenCalculatedOrEntered_: string;
	noFeedsAssigned_: string;
	noFluidSelected_: string;
	noFutureNonWorkingDaysWereListed_: string;
	noInternetConnection_: string;
	noItemDeliveryDatesHaveBeenSelected_: string;
	noLightingLoadHasBeenCalculatedOrEntered_: string;
	noLoadHasBeenCalculatedOrEntered_: string;
	noModelVARatingValuesListed_: string;
	noModelRatingValuesListed_: string;
	noModelsAvailableForThisHighAFlowRate_: string;
	noOrdersListed_: string;
	noPartsSelected_: string;
	noPhoneNumberEntered_: string;
	noPowerValueEntered_: string;
	noPriceBooksHaveBeenAssignedToYourProfile_: string;
	noPriceChangesOccurred_: string;
	noPricesWereChanged_: string;
	noPricingChangesOccurred_: string;
	noQuantityEntered_: string;
	noRefrigerantSelected_: string;
	noRevisionsWereUpdated_: string;
	noShippingAndHandlingCostEntered_: string;
	noState_ProvinceOrCountyEntered_: string;
	noSuctionTempEntered_: string;
	noSuitableFeedConfigurationFound_: string;
	noTotalLoadHasBeenCalculatedOrEntered_: string;
	noTotalProductLoadHasBeenCalculatedOrEntered_: string;
	noTotalProductWeightHasBeenCalculatedOrEntered_: string;
	noUsageEstimateCalculatedOrEntered_: string;
	noUsageLevelSelected_: string;
	noVentilationEstimateCalculatedOrEntered_: string;
	noVersion_OrdersFoundInThisList_: string;
	noZipOrPostalCodeEntered_: string;
	no_CancelThisOperation: string;
	no_IAmANewUser_: string;
	no_I_llDoItManually_: string;
	no_TryToConnectAgain_: string;
	no_LeaveTheExistingOptionMultipliersUnchanged_: string;
	no_RestorePreviousValue_: string;
	non_CatalogItemDetails: string;
	non_CatalogOptionDetails: string;
	non_numericJob_ScopeReferenceReturned_: string;
	none: string;
	notOwner: string;
	notFound_: string;
	notLatestCO: string;
	note_AnInternetConnectionIsRequiredToCompleteThisProcedure: string;
	note_IfYouAnswerNO_NewProductsAddedToThisOrderMayNotUseTheCorrectMultiplier_: string;
	note_IndoorValueOnly__TheExteriorBoundaryLayerIsAdjusted_AutomaticallyIfSectionIsMarkedAsOutdoors: string;
	note_OrderOriginatorsAndOrderDeskWillAutomaticallyBeIncludedInTheNotifyList: string;
	notes: string;
	notes_Comments: string;
	notes_Remarks: string;
	nothingToDo_: string;
	numberOfUnits: string;
	numericValueRequired_: string;
	o_ptions: string;
	oELIST: string;
	oEList: string;
	oEM: string;
	oFFLINE: string;
	oK: string;
	oNHOLD: string;
	oPENACTIVEORDERMANAGER: string;
	oPENEXISTINGORDER: string;
	oPENPURCHAREORDERMANAGER: string;
	oRDER: string;
	oRDERCONTAINS: string;
	oRDERCONTAINSCALLPRICING_: string;
	oRDERENTRYSYSTEM: string;
	oRDERNO: string;
	oRDERNOTES: string;
	oRDERSTATUS: string;
	oRDERVERIFIED: string;
	oRDERED: string;
	oTHER: string;
	oTHERLOADS: string;
	obsolete: string;
	off_LineWarning_: string;
	oldPassword: string;
	oldPasswordDoesNotMatch_: string;
	oneEquipmentChangeFound_: string;
	onePriceChanged_: string;
	oneRevisionUpdated: string;
	open: string;
	openBallyTools: string;
	openExistingJob: string;
	openFromWeb: string;
	openKeepRiteTools: string;
	openOrder: string;
	openOrder_ForceSynch: string;
	openTrentonTools: string;
	openAsNewOrder: string;
	openFileInEXCEL_: string;
	openFileInTextViewer_: string;
	openLastSavedEditOrder_: string;
	openingType: string;
	openingDeletionRequested_: string;
	openingsSelected: string;
	oppositeEnds: string;
	option: string;
	optionPrice: string;
	optionSelector: string;
	optionIsNoLongerAvailable_: string;
	optionNeedsFactoryLeadTime_PleaseCall_: string;
	optionNeedsFactoryPricing_PleaseCall_: string;
	optional: string;
	options: string;
	optionsOnThisItemHaveErrors_: string;
	optionsOnThisItemHaveWarnings_: string;
	orEnterAModelNumber: string;
	order: string;
	orderDate: string;
	orderEntry: string;
	orderEntryListPrice: string;
	orderEntryMultiplier: string;
	orderEntrySystem: string;
	orderForm: string;
	orderItemNo: string;
	orderManager: string;
	orderManager_COLLATING: string;
	orderNumericUnits: string;
	orderPreview: string;
	orderPreview_NoOrderSelected: string;
	orderPreview_NotAvailable: string;
	orderPrice: string;
	orderStatus: string;
	orderUploadConfirmationCheck: string;
	orderCanNotBeImported_: string;
	orderChangeStopRequested_: string;
	orderChangesHaveYetToBeUploadedToTheServer_: string;
	orderImported_: string;
	orderIncludesIncompleteFactoryPricingInformation_: string;
	orderInformationAlreadyExistsInTheProgramOrderStore_: string;
	orderIsAlreadyLoadedLocally_: string;
	orderIsOpenForReviewAndPrintOnly_: string;
	orderPriceTooLowForPre_paidShippingTerms_: string;
	orderStopRequested_: string;
	orderWasDeleted_: string;
	orderWillBeUploadedAfterNextProgramUpdate_: string;
	ordered: string;
	orders: string;
	orientation: string;
	original: string;
	otherCountryName: string;
	otherEquipmentManufacturer_sModel: string;
	otherHeatSources: string;
	otherName: string;
	otherDeletionRequested_: string;
	outOfRange_ShouldBe__And______: string;
	outdoors: string;
	p_O: string;
	p_O_: string;
	pARAMETERSREQUIRECHECKINGORADJUSTMENT: string;
	pARSING: string;
	pARTNOTFOUNDINDATABASE_: string;
	pARTIAL: string;
	pENDING: string;
	pERSONNEL: string;
	pICK_UP: string;
	pO_: string;
	pOWER: string;
	pRD: string;
	pRE_PAID: string;
	pRICEBOOKNOTAVAILABLE: string;
	pRICEEACH: string;
	pROCESSED: string;
	pRODUCTLINE: string;
	pRODUCTNO: string;
	pROJECT: string;
	pROTECTED: string;
	package: string;
	packageOptionsAreHighlightedInGreen_: string;
	panel: string;
	panelExteriorIsOutdoors: string;
	parametersCheckCompleted_: string;
	params: string;
	partDescription: string;
	partDescriptions: string;
	partNo: string;
	partDescriptionRequired_: string;
	partialShipmentSelected_: string;
	partialShippingRequested_ButNoSpecificShipDatesListedOnSystemsOrItems_: string;
	parts: string;
	password: string;
	passwordAgain: string;
	passwordRequired_: string;
	paste: string;
	pasteOptions_: string;
	pastingNonStockingModelsOnAStockingOrderIsProhibited_: string;
	pastingStockingModelsOnAStandardOrderIsProhibited_: string;
	perUnit: string;
	percentageChangeCausedByEnteredAdjustmentValue_: string;
	percentageDifferenceBetweenCurrentPriceAndFloorPrice_: string;
	percentageDifferenceBetweenNewPriceAndCurrentPrice_: string;
	percentageDifferenceBetweenNewPriceAndFloorPrice_: string;
	perimeterLength: string;
	permanentOpening: string;
	personalInformation: string;
	personnelLoad: string;
	ph_: string;
	phone: string;
	phoneNo: string;
	phoneNumbers: string;
	pick_Up: string;
	pleaseDescribe: string;
	pleaseNote_: string;
	pleaseSelect: string;
	pleaseSelectAModelType: string;
	pleaseSelectObjectsToInclude: string;
	pleaseSelectRatingOptions: string;
	pleaseAdjustTemperaturesOrChangeRefrigerant_: string;
	pleaseAdjustTemperaturesOrSelectAndEnterT_H_R_Value_: string;
	pleaseCheckAllItems_: string;
	pleaseCheckInternetAndTryAgain_: string;
	pleaseCheckServerConnectionAndTryAgain_: string;
	pleaseCheckThemIndividually_: string;
	pleaseCheckYourPermissionsAndOrCloseThisApplicationAndRunTheBootstrapManually_: string;
	pleaseCheckYourPermissionsAndOrDeleteThisFileManually_: string;
	pleaseCheckYourPermissionsAndOrMoveThisFileManually_: string;
	pleaseCheckYourProfileAndUpdateTheSoldByAddressPanel_: string;
	pleaseClickOnTheNEWButtonToBeginAddingYourFirstContact_: string;
	pleaseCloseAllInstancesOfNROESAndRestartTheApplication_: string;
	pleaseCloseAllRefrigerationAppsClickOnRetry_: string;
	pleaseCloseAllRefrigerationToolsAndRestartTheApplication_: string;
	pleaseCloseAllToolsAndRestartTheApplication_: string;
	pleaseCompleteRequiredInformation_: string;
	pleaseConfirmTheFollowing: string;
	pleaseConfirm_: string;
	pleaseContact: string;
	pleaseContactSupportTeam_: string;
	pleaseContactTheDevelopmentTeam_: string;
	pleaseContactTheFactoryToHaveYourProfileAdjusted_: string;
	pleaseContactTheManufacturerForServicePartInformation_: string;
	pleaseDescribeTheReasonForThisExceptionBelowAnd: string;
	pleaseEnterJob_ScopeReference_: string;
	pleaseEnterANumericValueGreaterZero_: string;
	pleaseEnterAllThePrimaryDimensionInformationFirst_: string;
	pleaseEnterCustomerReferenceNumber_: string;
	pleaseEnterExpectedShippingCarried_: string;
	pleaseEnterYourPersonalApplicationRegistrationCode: string;
	pleaseInstallCustomerDatabaseFileIntoYourApplicationProgramDataFolder_: string;
	pleaseQuoteFACTORYORDER__: string;
	pleaseRestartTheApplication: string;
	pleaseRestartTheApplicationWithTheInternetConnected_: string;
	pleaseSaveYourWorkAndCloseAllDesignWindows_: string;
	pleaseSelectACompressor_: string;
	pleaseSelectACountryFromTheList_: string;
	pleaseSelectADivision: string;
	pleaseSelectAMatchingModelNumberFirst_: string;
	pleaseSelectAPriceBook: string;
	pleaseSelectAnotherFile_: string;
	pleaseSelectOrderType_: string;
	pleaseSelectServicePartTypesToAddToOrder_: string;
	pleaseSelectTheSameSystemModelType_: string;
	pleaseSelectTheTypeOfSystemYouWantToAdd: string;
	pleaseTryAgainLater_: string;
	pleaseTryAgain_: string;
	pleaseUpdateYourPrices_: string;
	pleaseUseTheOptionsSelectorToAdjustTheseOptions_: string;
	pleaseUseTheOrderManagerToOpenThisOrder_: string;
	pleaseWait_: string;
	plus: string;
	popup_Tooltip: string;
	postal: string;
	postal_ZIPCode: string;
	postalCode: string;
	power: string;
	powerDensity: string;
	powerSearch: string;
	power_Deg: string;
	pr_St: string;
	pre_view: string;
	pre_Paid_Charge: string;
	pre_paid: string;
	pressure: string;
	pressureDropExceedsRequestedRange_: string;
	pressureDropRange: string;
	pressureDropTolerance: string;
	pressureDropTooLow_: string;
	pressureRange: string;
	preview: string;
	previousNetPrice_: string;
	price: string;
	priceBook: string;
	priceChange: string;
	priceOK: string;
	priceBookUpdated_: string;
	priceBookHasExpired_: string;
	priceBookHasExpiredAndTheQuoteIsOlderThan__Days_: string;
	priceBookIsMissing_: string;
	priceBookNotUpdated_: string;
	priceDidNotChange_: string;
	pricebookHasExpired: string;
	pricebookHasExpired_: string;
	pricebookMissing: string;
	pricebookNotLoaded_: string;
	pricebookWillExpireIn: string;
	pricingChangeOccurredOnOneRevision_: string;
	pricingChangesOccurredOn: string;
	pricingShownIncludesAllOptionsListedOnCertifiedPrints_: string;
	primary: string;
	print: string;
	printPreview: string;
	printSelectedModelOnly: string;
	printing: string;
	processChangeOrderRequest: string;
	processOrder: string;
	processingNotes: string;
	product: string;
	productDetails: string;
	productLine: string;
	productList: string;
	productLoad: string;
	productType: string;
	productDeletionRequested_: string;
	productsSelected: string;
	profitRate: string;
	programUpdateAvailable_: string;
	programUpdatesFound: string;
	programUpdatesShouldBeAvailableShortly_: string;
	project: string;
	promise: string;
	promiseDate: string;
	promised: string;
	protectCurrentRevision: string;
	province: string;
	pulldownTime: string;
	purchaseOrder: string;
	purchaseOrders: string;
	purchaseRequests: string;
	purchaser: string;
	qTY: string;
	qUOTE: string;
	qUOTEDAYS: string;
	qtty: string;
	qty: string;
	quantity: string;
	quantityPerUnit: string;
	quickBox: string;
	quotationValidFor__DaysFromQuoteDate_: string;
	quote: string;
	quote_: string;
	quoteDate: string;
	quoteNo: string;
	quotePrice: string;
	quoteSetup: string;
	quoteDateIsNotSetToCurrentDate_: string;
	r_Value: string;
	r____A_R____CombinationNoLongerAvailable_R____ASelected_: string;
	rATING: string;
	rEADONLY: string;
	rEADY: string;
	rECTANGULAR: string;
	rEF: string;
	rEFf: string;
	rEQDate: string;
	rEQSHIPDATE_: string;
	rETRIEVENROESORDERFROMSERVER: string;
	rLA: string;
	rOUTING: string;
	rUN: string;
	rUNNUMBER: string;
	range: string;
	ratedAt: string;
	ratingOptions: string;
	rear: string;
	rearrangeItemOrder: string;
	rearrangeItems: string;
	rearrangeSystemOrder_RenameSystems: string;
	rearrangeSystems: string;
	rebuildFactoryOrder: string;
	rebuildFactoryOrderRequested_: string;
	recentOrders: string;
	recommendedQuantity: string;
	recoverMyEdits_: string;
	rectangularBoxDimensions: string;
	redo: string;
	refresh: string;
	refreshOrderList: string;
	refrigerant: string;
	refrigerantFactor: string;
	refrigerantFactorNotAvailable_: string;
	refrigerantFactorOutOfRange_: string;
	refrigerantNotSelected_: string;
	refrigerants: string;
	refrigerationDesignTools: string;
	refrigerationSystemDetails: string;
	regBuild: string;
	region: string;
	regularShipDate_: string;
	regularUse: string;
	rejectOrder: string;
	rejected: string;
	rejectingAnOrderWillPreventTheOriginatorFromResubmittingThisOrder_: string;
	releaseNotes: string;
	remarks_Notes: string;
	remarks_TermsAndConditions: string;
	rememberMeOnThisComputer: string;
	remo_ve: string;
	remoteOrderLockNotEstablished_: string;
	remoteVersionModificationTimestamp_: string;
	remoteVersionUploadTimestamp_: string;
	remove: string;
	remove_LocalOrder: string;
	removeFeed: string;
	removeLastWall: string;
	removeLocalOrder: string;
	removeModel_Item: string;
	removeSystem: string;
	renumberIDs: string;
	replaceAllItems_: string;
	replaceLocalOrderWithMasterVersion_: string;
	replaceSelectedItems_: string;
	replaceSelectedSystem_: string;
	replaceTheOrderInformationFromTheSelectedFile_: string;
	requested: string;
	requestedChanges_PleaseUpdateJob_ScopeAndCheckOffEachItem: string;
	requestedDate_: string;
	requestedDateException: string;
	requestedItemShipDateIsLessThanItemLeadTime_: string;
	requestedOrderShipDateIsLessThanLeadTime_: string;
	requestedSystemShipDateIsLessThanLeadTime_: string;
	required: string;
	requiredCapacity: string;
	requiredCapacity_Unit: string;
	requiredCapacityIsNotSet_: string;
	requiredCapacityPerUnit: string;
	requiredCapacityTolerance: string;
	requiredOptions: string;
	requiredRatingOptions: string;
	requiredShipDate: string;
	requiredValue_: string;
	requirementDatesInThePast_: string;
	resetColumnWidths: string;
	resizeColumns: string;
	restoreQuoteRevisionRequested_: string;
	restoreTheOrderInformationFromTheOrderStore_: string;
	resultingPriceIsLowerThanFloorPrice_: string;
	retrieveOrder: string;
	retry: string;
	revision: string;
	revisionContainsErrors_: string;
	revisionContainsWarnings_: string;
	rightEnd: string;
	rightClickOnTheQuoteTabsOnTheTopLeftOfTheOrderWindow_: string;
	rounding: string;
	runNumber: string;
	runTime: string;
	sAndH: string;
	s_C_T_: string;
	s_S_T_: string;
	sCT: string;
	sECTIONLIST: string;
	sEEITEMS: string;
	sELECTEDMODELDETAILS: string;
	sERVERERROR_: string;
	sERVICEPARTSCATALOG: string;
	sHIPTO: string;
	sHIPPEDLOOSE: string;
	sIDE_: string;
	sITEVOLTAGES: string;
	sOLDBY: string;
	sOLDTO: string;
	sST: string;
	sTARTING: string;
	sTATE: string;
	sTOPSUBMITTEDORDERORCHANGE: string;
	sUBMITNOTES: string;
	sUBMITORDER: string;
	sUBMITTED: string;
	sUBMITTEDC_O_: string;
	sUITABLEREFRIGERANTS: string;
	sYNCHRONIZING: string;
	sYS: string;
	sYSTEM: string;
	sYSTEMBALANCE: string;
	sYSTEMBULLETIN_: string;
	sYSTEMREFRIGERANT: string;
	sales: string;
	salesman: string;
	sameEnd: string;
	saturatedCondensingTemperature: string;
	saturatedSuctionTemperature: string;
	save: string;
	saveAllOrders: string;
	saveAsDefault: string;
	saveAsDefaults: string;
	saveAsSiteDefaults: string;
	saveChanges: string;
	saveOrder: string;
	saveOrderParameterChanges_: string;
	saveChangedContactInformation_: string;
	saveFailed_: string;
	saveMyChanges_: string;
	saveTheseChangesAsANewContact_: string;
	search: string;
	searchServiceParts: string;
	searchingForMatchingAirCooledCondensers_: string;
	searchingForMatchingCompressors_: string;
	searchingForMatchingCondensingUnits_: string;
	searchingForMatchingEvaporatorUnits_: string;
	section: string;
	sectionConstruction: string;
	sectionDivider: string;
	sectionExteriorIsOutdoors: string;
	sectionLoad: string;
	sectionType: string;
	sectionDeletionRequested_: string;
	sectionHasOpeningErrors_: string;
	sectionHasOpeningWarning_: string;
	sectionNameNotProvided_: string;
	sectionTypeMustBeSelected_: string;
	sectional_E_g_StudWall_: string;
	sections: string;
	sectionsSelected: string;
	selectAll: string;
	selectApplicationLanguage: string;
	selectEquipment: string;
	selectModel: string;
	selectNextModel_: string;
	selectOptions: string;
	selectRequestRevision: string;
	selectRoomShape: string;
	selectWhichPowerOfTenToRoundTo_: string;
	selectedItemDetails: string;
	selectedModelDetails: string;
	selectedQuoteDetails: string;
	selectedConstructionNotFound_: string;
	selectedItemCountDiffersFromPasteBufferCount_: string;
	selectedModelNotFoundInActivePriceBook_: string;
	selectedPriceBookHasExpired_: string;
	selectionError_: string;
	sendNotification: string;
	sendNotifications: string;
	sendItToMeAgain: string;
	sendNotificationsTo__SelectAllThatApply_: string;
	separatePrice: string;
	seperatePrice: string;
	serverConnected: string;
	serverNotAvailable: string;
	serverCopyHasDifferentSaveHistory_: string;
	serverOrdersQueried_: string;
	serverVersionDiffersFromApplication_: string;
	serviceParts: string;
	setDefaults: string;
	setStatus: string;
	setQuoteDateToToday_: string;
	shellApplicationFailedToAcceptCommand_: string;
	shellApplicationFailedToStart_: string;
	shellApplicationFilesAreMissing_: string;
	shellTerminatedUnexpectedy_: string;
	shellDidNotAcceptCommand_: string;
	shellDidNotRespond_: string;
	shellIsLoading: string;
	shipOrdersFromFile: string;
	shipTo: string;
	shipToInformationIsIncomplete: string;
	shipToRegionMustBeTwoCharacterCode_: string;
	shipment: string;
	shipped: string;
	shippedInformation: string;
	shippedLoose: string;
	shippedLoose_LineItem_: string;
	shippingNotes: string;
	shippingTerms: string;
	shippingWeight: string;
	showAll: string;
	showChangeDetails: string;
	showExtendedParameters: string;
	showItemPrices: string;
	showLocalOrders: string;
	showMyOrders: string;
	showNotes_Tags_AndDates: string;
	showOptionPrices: string;
	showOptions: string;
	showSplashScreenAtStartup: string;
	showToolbarText: string;
	showWhereUsedList: string;
	showMeMoreInformation_: string;
	shutdown: string;
	side_: string;
	signIn: string;
	signedIn: string;
	signedIn_: string;
	signingInAsGuest_: string;
	sineWave: string;
	siteAltitude: string;
	siteLineFrequency: string;
	siteAltitudeCanNotBeGreaterThan: string;
	siteAltitudeCanNotBeLessThan: string;
	siteAltitudeNotEntered_: string;
	smallDimensions: string;
	softwarePatches: string;
	soldBy: string;
	soldByNameChangeRequested_: string;
	soldTo: string;
	soldByInformationIsIncomplete: string;
	soldToInformationIsIncomplete: string;
	soldToRegionMustBeTwoCharacterCode_: string;
	someDescriptionsHaveNotBeenEntered_: string;
	someDesignWindowsAreStillOpen_: string;
	someFactoryCallLeadTimeSettingsAreStillNotSet_: string;
	someFilesDidNotTransferToTheServer_: string;
	someFunctionalityMayBeDisabled_: string;
	someItemsNeedFactoryLeadTimes_PleaseCall_: string;
	someItemsNeedFactoryPricing_PleaseCall_: string;
	someOptionsWerePreviouslySetToAnotherValue_: string;
	someOrdersNotSaved_: string;
	someOrdersWereNotProperlyClosed_: string;
	someParametersChanged_: string;
	somePriceChangesOccurred_: string;
	somePricesOrOptionsMayHaveChanged_: string;
	somePricesWereUpdated_: string;
	somePrintFunctionsAreDisabled_: string;
	spanish: string;
	spareFeeds: string;
	spares: string;
	specificHeatAboveFreezing: string;
	specificHeatBelowFreezing: string;
	standard: string;
	standardOrder: string;
	standardTextFile_AllListedParts_: string;
	standardTextFile_SelectedPartsOnly_: string;
	startNROES: string;
	startUpOptions: string;
	startShellWhenILogInToWindows: string;
	starting: string;
	startingTools: string;
	state: string;
	status: string;
	status_: string;
	statusStrip: string;
	stockingModelOrder: string;
	storedOrderIsMoreRecentThanRecoveryData_: string;
	stripCurtains: string;
	style: string;
	sub_Total: string;
	subject: string;
	submitChangeOrderNow: string;
	submitChangeOrderRequestToManufacturer: string;
	submitChangeRequestToPurchaser: string;
	submitNotes: string;
	submitOrder: string;
	submitOrderNow: string;
	submitted: string;
	submittedOrdersMayNotBeEditedInOff_lineMode_: string;
	success_: string;
	suction: string;
	suctionTemp: string;
	suctionTemp_S_S_T__: string;
	suctionTemperatureCanNotBeGreaterThan: string;
	suctionTemperatureCanNotBeLessThan: string;
	suctionTemperatureIsGreaterThanCondensingTemperature_: string;
	suctionTemperatureIsMissing_: string;
	suctionTemperatureIsRequired_: string;
	suitableModels: string;
	switchPriceBook: string;
	synchronizingOrder_: string;
	syncronizingContacts: string;
	system: string;
	systemLoad: string;
	systemName: string;
	systemNetPrice: string;
	systemNotes: string;
	systemRun_Time: string;
	systemTagging: string;
	systemWeight: string;
	systemLoadIsMissing_: string;
	systemOptionIsNoLongerAvailable_: string;
	systemReturned_: string;
	systemsOnThisOrderContainErrors_: string;
	systemsOnThisOrderContainWarnings_: string;
	t_D_: string;
	t_D_Error: string;
	t_H_R: string;
	tDCanNotBeCalculated_: string;
	tDCanNotBeGreaterThan: string;
	tDCanNotBeLessThan: string;
	tDOutOfEnteredToleranceRange_: string;
	tDOutOfMaximumRange_: string;
	tERMS_CONDITIONSANDREMARKS: string;
	tHEVALUESCONTAINEDHERINAREBASEDONLABORATORYFINDINGSANDTHEINITIALCONDITIONSENTEREDBYTHEUSER_: string;
	tHR: string;
	tHRCanNotBeCalculated_: string;
	tOTAL: string;
	tOTALINFILTRATIONLOAD: string;
	tOTALLISTPRICE: string;
	tOTALNET: string;
	tOTALQUOTEPRICE: string;
	tYP: string;
	tYPE: string;
	tabSeparatedTextFiles: string;
	tagging: string;
	taggingID: string;
	taggingID_: string;
	taggingText: string;
	takeOwnership: string;
	targetPriceAccordingToAdjustmentAndRoundingFactors_: string;
	tax: string;
	taxes: string;
	temperature: string;
	temperatures: string;
	terminationOptions: string;
	terms: string;
	textBox: string;
	theInternalRelativeHumidityNotEntered_: string;
	theMaterialLoadHasNotBeenCalculatedOrEntered_: string;
	theMaterialMaterialNameIsNotEntered_: string;
	theMaterialMaterialTypeIsNotSelected_: string;
	theOriginatingCustomersInformationIsAlreadyListedInYourContactDatabase_: string;
	theR_ValueHasNotBeenEntered_: string;
	theActualCondensingTemperatureCouldNotBeCalculated_: string;
	theAmbientTemperatureIsNotEntered_: string;
	theApplicationCouldNotMoveFile_BootStrap_exeFromTheUpdatesFolderToTheApplicationFolder_: string;
	theApplicationCouldNotRemoveFile_BootStrap_exe_FromTheApplicationFolder_: string;
	theApplicationCouldNotSpawn_BootStrap_exe_InTheApplicationFolder_: string;
	theAreaHasNotBeenCalculatedOrEntered_: string;
	theAreaIsGreaterThanTheSectionArea_: string;
	theBoxTypeMustBeSelected_: string;
	theCentreDistanceIsNotEntered_: string;
	theChangeCanNotBeProcessedUntilYouCloseTheWorkbookInThisApplication_: string;
	theConstructionK_FactorIsNotCalculatedOrEntered_: string;
	theConstructionR_ValueIsNotCalculatedOrEntered_: string;
	theConstructionNameIsNotEntered_: string;
	theConstructionThicknessHasNotBeenEntered_: string;
	theConstructionTypeIsNotSelected_: string;
	theCorrectQuoteRevisionIsSelected: string;
	theCurrentPricebookHasBeenSuperseded_: string;
	theCurrentPricebookHasExpired_: string;
	theCurrentPricebookNoLongerExists_: string;
	theCurrentPricebookWillExpireInLessThanAMonth_: string;
	theCurrentSettingsWillBeUsedOnAllNewOrdersForThisDivision_: string;
	theCurrentSettingsWillBeUsedOnAllNewOrders_: string;
	theDividerMaterialNameIsNotEntered_: string;
	theDividerMaterialTypeIsNotSelected_: string;
	theDividerWidthIsNotEntered_: string;
	theEffectiveR_ValueIsNotCalculatedOrEntered_: string;
	theEmailAddressGivenIsNotListed_: string;
	theExternalRelativeHumidityMustBeGreaterThanZero_: string;
	theExternalRelativeHumidityMustBeGreaterThan: string;
	theExternalRelativeHumidityMustBeLessThan____: string;
	theExternalRelativeHumidityMustBeLessThan___: string;
	theExternalRelativeHumidityNotEntered_: string;
	theExternalTemperatureIsLowerThanTheInternalTemperature_: string;
	theExternalTemperatureIsNotEntered_: string;
	theExternalTemperatureIsTooHigh_: string;
	theExternalTemperatureIsTooLow_: string;
	theExternalTemperatureNotEntered_: string;
	theFactoryInvoiceRecipientIsCorrect: string;
	theFactoryInvoiceWillBeSentTo: string;
	theFactoryIsNotShippingOnTheSelectedDate_: string;
	theFloorAreaIsNotCalculatedOrEntered_: string;
	theFollowingChangesHaveBeenVerified: string;
	theFollowingOptionIsNotAllowedWithThisPackage_: string;
	theFollowingOrdersCouldNotBeMarkedAsClosed_: string;
	theFollowingOrdersCouldNotBeMarkedAsShipped_: string;
	theFollowingOrdersCouldNotBeSynchronized_: string;
	theInfiltrationLoadHasNotBeenCalculatedOrEntered_: string;
	theInformationInTheStoreIsOlderThanTheSelectedFile_: string;
	theInternalTemperatureIsNotEntered_: string;
	theItemOrSomeOptionsNeedFactoryLeadTimes_PleaseCall_: string;
	theLayerThicknessIsNotCalculatedOrEntered_: string;
	theLayerThicknessIsNotEntered_: string;
	theLoadIsNotCalculatedOrEntered_: string;
	theOpeinngWidthIsGreaterThanTheSectionWidth_: string;
	theOpeningHeightHasNotBeenEntered_: string;
	theOpeningHeightIsGreaterThanTheSectionHeight_: string;
	theOpeningTypeIsNotSelected_: string;
	theOpeningWidthHasNotBeenEntered_: string;
	theOrderCanNotBeProcessedUntilYouCloseTheWorkbookInThisApplication_: string;
	theOrderFileAppearsCorrupt_: string;
	theOrderIsAlreadyListedInTheLocalOrderDatabase_: string;
	theOrderIsLockedByAnotherUser_: string;
	theOrderWindowWillUpdateWhenYouCloseTheServicePartsWindow_: string;
	theOriginalOrderHasNotBeenProcessedYet_: string;
	thePerimeterLengthMustBeEnteredForSlabOnGradeFloors_: string;
	thePowerUnitsAreNotEntered_: string;
	thePriceBookSelectedForThisOrderHasBeenUpdated_: string;
	thePriceBookUsedForTheOpenChangeRequestHasBeenRevised_: string;
	thePriceBookUsedForTheOpenChangeRequestHasBeenSuperseded_: string;
	thePriceBookUsedForTheOpenChangeRequestIsExpiring_: string;
	thePriceBookUsedForThisOrderHasBeenRevised_: string;
	thePriceBookUsedForThisOrderHasBeenSuperseded_: string;
	thePriceBookUsedForThisOrderIsExpiring_: string;
	thePriceBookUsedOnOneQuoteHasBeenRevised_: string;
	thePriceBookUsedOnOneQuoteHasBeenSuperseded_: string;
	thePriceBookUsedOnOneQuoteHasExpired_: string;
	thePriceBookUsedOnOneQuoteIsMissing_: string;
	thePriceBookWasUpdated_: string;
	thePriceBooksUsedForThisOrderAreExpiring_: string;
	thePriceBooksUsedForThisOrderHaveBeenRevised_: string;
	thePriceBooksUsedForThisOrderHaveBeenSuperseded_: string;
	thePriceBooksUsedOn: string;
	thePriceBooksUsedOnOneQuoteIsExpiring_: string;
	theProductFreezingTemperatureIsNotEntered_: string;
	theProductIsEnteringAtTheInternalTemperature_TheFinalTemperatureMustAlsoBeTheInternalTemperature_: string;
	theProductTypeIsNotSelected_: string;
	theProduct_sEnteringTemperatureIsNotSet_: string;
	theProduct_sFinalTemperatureCanNotBeGreaterThanTheEnteringTemperature_: string;
	theProduct_sFinalTemperatureCanNotBeLowerThanTheEnteringTemperature_: string;
	theProduct_sFinalTemperatureCanNotBePulledHigherThanTheInternalTemperature_: string;
	theProduct_sFinalTemperatureCanNotBePulledLowerThanTheInternalTemperature_: string;
	theProduct_sFinalTemperatureIsNotSet_: string;
	theProduct_sLoadFactorIsNotSet_: string;
	theProduct_sLoadFactorShouldBeOneUnlessRapidCoolingIsRequired__: string;
	theProduct_sLoadIsNotCalculatedNotEntered_: string;
	theProduct_sMoistureContentIsNotEntered_: string;
	theProduct_sPullDownTimeIsNotSet_: string;
	theProduct_sSpecificHeatAboveFreezingIsNotEntered_: string;
	theProduct_sSpecificHeatBelowFreezingIsNotEntered_: string;
	theProduct_sWeightIsNotEntered_: string;
	theProgramStoreIsNewerThanTheFileYouSelected_: string;
	theQuoteHasBeenSignedByTheCustomer: string;
	theRunTimeIsNotEntered_: string;
	theSelectedChangeOrderHasBeenProcessedByOurOrderDepartment_: string;
	theSelectedChangeOrderHasBeenProcessedByThePurchaser_: string;
	theSelectedOrderHasBeenProcessedByOurOrderDepartment_: string;
	theSelectedOrderHasBeenProcessedByThePurchaser_: string;
	theSelectedOrderIsOpenInAnotherSessionOfNROES_: string;
	theSelectedRefrigerantIsNotAvailableOnThisUnit_: string;
	theServerReturnedTheFollowing_: string;
	theSystemReportedError: string;
	theSystemReportedTheFollowingError_: string;
	theTotalInfiltrationLoadIsNotCalculatedOrEntered_: string;
	theTotalVolumeIsNotCalculatedOrEntered_: string;
	theUserNameYouEnteredIsNotRegisteredOnThisWorkstation_: string;
	theValueWillBeAddedOrSubtractedFromTheCurrentPrice_: string;
	thenClickOnSaveAgain_: string;
	thereAreNoItemsOnThisOrder_: string;
	thereAreNoOptionsAvailableForThisUnit_: string;
	thickness: string;
	thicknessIsNotEntered_: string;
	thirdParty: string;
	thisMayTakeAFewMinutes_: string;
	thisModelHasOptionsAttached_: string;
	thisOrderHasBeenPutOnHoldByTheOrderDepartment_: string;
	thisOrderHasBeenRejectedByTheOrderDepartment_: string;
	thisOrderHasPriceBookIssues_: string;
	thisOrderWasNotClosedProperlyTheLastTimeYouOpenedItOnThisWorkstation_: string;
	thisOrderWasSavedUsingAPreviousVersionOfYourMultiplierStructure_: string;
	thisOrderWasSavedUsingAPreviousVersionOfYourUserCatalogueProfile_: string;
	tileHorizontally: string;
	tileVertically: string;
	time: string;
	title: string;
	toNearest: string;
	toNearest_: string;
	toNearest_____: string;
	toNearest______: string;
	toNearest_______: string;
	toAssistUs_PleaseProvideAnyFurtherInformationYouMayThinkIsRelevant_: string;
	toChangeThePartQuantities_PleaseEditThemInTheOrderWindow_: string;
	tolerance: string;
	tools: string;
	total: string;
	totalCapacityAtDesignTD: string;
	totalEquipmentPrice: string;
	totalHeatRejected: string;
	totalHourlyLoad: string;
	totalLoadRequired: string;
	totalMiscellaneousLoad: string;
	totalOpeningInfiltration: string;
	totalOtherLoads: string;
	totalPrice: string;
	totalProductLoad: string;
	totalProductWeight: string;
	totalSystemCapacity: string;
	totalThickness: string;
	totalVolume: string;
	totalWallLoad: string;
	totalDoorWidthExceedsSectionWidth: string;
	totalHeatRejectedOutOfRange_: string;
	totalOpeningAreaExceedsSectionArea: string;
	translationsAreUNDERCONSTRUCTIONAndMayBeLiteralByNatureAndMayBeContextuallyInError_: string;
	trentonLoadCalcFiles: string;
	trentonRefrigeration: string;
	tubeConfiguration: string;
	tubeRows: string;
	tubes: string;
	tubesPerFeed: string;
	tubesPerRow: string;
	typ: string;
	type: string;
	u_SHAPED: string;
	u_S_: string;
	u_S_English: string;
	uNIT: string;
	uNITLIST: string;
	uNITNET: string;
	uNLESSOTHERWISESPECIFIED: string;
	uNUSEDCONNECTIONS: string;
	uPDATED: string;
	uSERINFORMATION: string;
	uSERPREFERENCES: string;
	undo: string;
	unit_: string;
	unitCooler: string;
	unitList: string;
	unitNet: string;
	unitPrice: string;
	unitTotal: string;
	unitIsNotCompatibleWithSelectedSystemRefrigerant_: string;
	units: string;
	unknownReason_: string;
	untilTheChangeIsProcessedByTheManufacturer_YouMayStopYourRequest_: string;
	untilThen_FunctionalityWillBeLimited_: string;
	unusedFeeds: string;
	upOrDown: string;
	updateAllMultipliers: string;
	updateAllParameters: string;
	updateAllPrices: string;
	updateNow: string;
	updatePricebook: string;
	updatePending_: string;
	updateTheCurrentInformation_Or: string;
	updateTheExistingListing_: string;
	updatesCanNotBeInstalled_: string;
	uploadToJOB_SCOPE: string;
	uploadingBuffer: string;
	uploadingPreviouslySavedFiles: string;
	uploadingQueuedOrders: string;
	uploadingQueuedOrder_: string;
	useLogoImageFile: string;
	useMyInformation: string;
	useBoxSetting: string;
	useMenuFunctionCreateChangeOrderInstead_: string;
	useTheHOLDFunctionIfYouExpectTheOriginatorToCorrectTheOrder_: string;
	useTheSTOPSUBMITTEDCHANGESelectionInTheOrderMenu_: string;
	useTheSTOPSUBMITTEDORDERSelectionInTheOrderMenu_: string;
	useTheMenuToCreateNewRevision_s_FromYourEditsAsDesired_: string;
	userID: string;
	userInformation: string;
	userInformationRequested_: string;
	userName: string;
	userRegistrationInformation: string;
	userVerification: string;
	userNameRequired_: string;
	userSelectablePriceAdjustmentValue_: string;
	usernameNotFound_: string;
	usingLocalVersion_: string;
	vWaffle: string;
	vOLTAGE: string;
	valueRequired_: string;
	valueMustBeGreaterThanZero_: string;
	various: string;
	velocity: string;
	ventilationAirRelativeHumidity___: string;
	ventilationAirTemperature: string;
	ventilationRate: string;
	ventilationAirRelativeHumidityIsNotEntered_: string;
	ventilationAirTemperatureIsNotEntered_: string;
	ventilationRateIsLessThanTheRecommendedMinimumValue_: string;
	verifyDesign: string;
	version: string;
	version_OfNROESUsesADifferentSaveStrategy_: string;
	version_UsesAWorkbookForYourJobInsteadOfMultipleQuoteRevisionFiles_: string;
	vertical: string;
	via: string;
	view_ArchivedFiles: string;
	viewCSV: string;
	visitUsOn_Line: string;
	voltage: string;
	volume: string;
	wAITINGFORSERVICEPARTSCATALOGUETOLOAD: string;
	wARNING: string;
	wARNING_: string;
	wARNINGS: string;
	wATTS: string;
	wORKORDER: string;
	waitingForShellToComeReady: string;
	waitingForShellToComeReadyForFirstTime: string;
	waitingForShellToStart: string;
	waitingForANewPassword_: string;
	waitingForRegistrationKey_: string;
	waitingForShell_: string;
	waitingForSign_in: string;
	wall: string;
	wallLoad: string;
	wallSection: string;
	wallsCross_PleaseCheckYourDimensions_: string;
	warning: string;
	warning_PriceBookHasExpired_: string;
	warningOppositeEndConnections_DimensionDrawingWillOnlyShowSameEndHeaders: string;
	warnings: string;
	waterCooledCondensingUnit: string;
	webMessagesAndBulletins: string;
	weight: string;
	whatToPrint: string;
	whatDoYouWantToDo_: string;
	whatTypeOfFileDoYouRequire_: string;
	whatTypeOfOrderDoYouWishToCreate_: string;
	whereUsed: string;
	whereUsed_: string;
	wholesale: string;
	width: string;
	window: string;
	with: string;
	withTheInternetConnected_: string;
	workHours: string;
	workOrder: string;
	workingDays: string;
	workingOn_Line: string;
	wouldYouLikeToDo_: string;
	wrapSystemBar: string;
	yes: string;
	yesPlease: string;
	yes_ChangeTheModelToTheOneISelected_: string;
	yes_IAmARegisteredUser_: string;
	yes_SetOptionsToTheNewMultiplier_: string;
	yes_SetTheValueIEntered_: string;
	yes__SomeFunctionalityWillBeDisabled_: string;
	youAreNotTheOriginalOwnerOfThisOrder_: string;
	youAreProhibitedFromSettingTheMultiplierThisLow_: string;
	youAreWorkingOffline_: string;
	youCanNoLongerStopThisChangeRequest_: string;
	youCanNoLongerStopThisOrder_: string;
	youCanNoLongerStopThisPurchaseRequest_: string;
	youCanNotDeleteTheLastSystemOnAnQuote_: string;
	youCanNotDeleteThisOrderFromTheServer_: string;
	youCanNotEditTheDoorInformationAtThisTime_: string;
	youCanNotEditTheSectionInformationAtThisTime_: string;
	youCanNotEditThisOrderAtThisTime_: string;
	youCanNotRejectAnOrderUsingNROESOnceItHasBeenSubmittedToJob_Scope_: string;
	youChangedTheCondensingUnitModelNumber_: string;
	youChangedTheModelNumber_: string;
	youDoNotCurrentlyHaveRightsToThisTool_: string;
	youDoNotHaveTheOriginalPriceBookLoaded_: string;
	youDoNotHaveTheRequiredPriceBookForTheOpenChangeRequest_: string;
	youDoNotHaveTheRequiredPriceBookForThisOrder_: string;
	youDoNotHaveTheRequiredPriceBooksForThisOrder_: string;
	youHaveFailedToReceiveACatalogueDataUpdateForMoreThan__Days_: string;
	youHaveFailedToReceiveAProgramUpdateForMoreThan__Days_: string;
	youHaveNotBeenAssignedAChannelForDirectEntryToTheAS_____: string;
	youHaveNotConfirmedYourEmailAddressYet_: string;
	youHaveNotReceivedACatalogueDataUpdateForMoreThan__Days_: string;
	youHaveNotReceivedAProgramUpdateForMoreThan__Days_: string;
	youHaveNotStartedTheApplicationOn_lineForMoreThan__Days_: string;
	youMayCreateANewChangeOrder_OrContactUsByTelephone_: string;
	youMayCreateANewChangeOrder_OrContactYourPurchaser_: string;
	youMayCreateANewOrderAndCorrectTheListedIssues_: string;
	youMayEditThisOrderOff_line_: string;
	youMayEnterAPositiveOrNegativeValueHere_: string;
	youMayEnterAValueHereToRoundToAValueOtherThanThatEndingInAZero_: string;
	youMayHaveTheOrderOpenInAnotherApplication_: string;
	youMayNotCopyThisOrder_: string;
	youMayNowEditYourData_: string;
	youMayPreferToSimplyDeselectTheSpecificOptionsYouDoNotRequire_: string;
	youMaySelectRoundingOptionsHere_: string;
	youMayTakeOwnershipOfThisOrderUsingTheOrderMenu_: string;
	youMayThenEditTheServicePartQuantitiesInTheOrderItself_: string;
	youMayWorkOff_lineIfYouWish_: string;
	youMustBeConnectedToTheInternetToUseThisTool_: string;
	youRequestedIDeleteASystemFromTheOrder_: string;
	youShouldCloseThisOrderAndTryToReopenItForSynchronizationOptions_: string;
	youShouldReceiveAnE_mailShortly_: string;
	youShouldReceiveMoreInformationWithinTheNextFewWorkingDays_: string;
	youShouldReceiveYourCodeShortlyAtTheFollowingE_mailAddress_: string;
	youShouldReceiveYourLoginInformationViaE_mailShortly_: string;
	youShouldStopTheRequest_CorrectTheIssuesListedBelow_AndResubmit_: string;
	youWillBeTheOnlyOneAbleToMakeChanges_: string;
	yourAuthorizationHasBeenRecinded_: string;
	yourAuthorizationIsNowUnderReview_: string;
	yourAuthorizationIsStillUnderReview_: string;
	yourChangeRequestWasSubmitted_: string;
	yourDataContainsWarnings_: string;
	yourNeedToBeOn_lineToCompleteTheRegistrationProcess_: string;
	yourNewChangeRequestWillReplaceThePreviousOne_AfterYouCompleteYourChangesAndRe_submit_: string;
	yourNewOrderWillReplaceThePreviousOne_AfterYouCompleteYourChangesAndRe_submit_: string;
	yourNewRequestWillReplaceThePreviousOne_AfterYouCompleteYourChangesAndRe_submit_: string;
	yourOrderWasSubmitted_: string;
	yourPasswordHasBeenSuccessfullyUpdated: string;
	yourPreviouslySubmittedChangeOrderHasBeenStopped_: string;
	yourPreviouslySubmittedChangeRequestHasBeenStopped_: string;
	yourPreviouslySubmittedOrderHasBeenStopped_: string;
	yourPreviouslySubmittedRequestHasBeenStopped_: string;
	yourPurchaserHasBegunWorkOnYourOrderRequest_: string;
	yourRequestCouldNotBeRetractedAtThisTime_: string;
	yourSoldByRegionMustBeTwoCharacterCode_: string;
	yourUserAccountHasBeenRejected_: string;
	yourUserAccountIsCurrentlyDisabled_: string;
	yourUserProfileHasBeenSuccessfullyUpdated_: string;
	yourVendorAccountIsCurrentlyDisabled_: string;
	zip_PostalCode: string;
	zipCode: string;
	zoneBoundary: string;
	zoneBoundary_TD___: string;
	asNewRevision_AndRestore: string;
	atFixtureRating: string;
	confirmYourRequestByClickingOnTheOKButton_: string;
	e_g_ToRoundTo______SetRoundingToNearest____AndEnter__Here_: string;
	equipmentChangesFound_: string;
	feedIsNotUsed_: string;
	feedsAreNotUsed_: string;
	frmOptions: string;
	haveBeenRevised_: string;
	haveBeenSuperseded_: string;
	lblBOLD: string;
	orQuantity: string;
	orR_Value_UnitThickness: string;
	orTotalLightingPower: string;
	pricesChanged_: string;
	quotesAreExpiring_: string;
	quotesAreMissing_: string;
	quotesHaveExpired_: string;
	regularShipDateOfTheSelectedItem_: string;
	revisionsUpdated_: string;
	revisions_: string;
	toLastSavedVersion_: string;
	youCanNoLongerCreateQuoteRevisionsDuringTheSaveOperation_: string;
	_LessOptions_: string;
	approvalOfThisDrawingSignifiesThatTheEquipmentIsAcceptableUnderTheProvisionOfTheJobSpecifications_AnyChangeMadeHereonByAnyPersonWhomsoeverSubjectToAcceptanceByNATIONALREFRIGERATIONAtItsHomeOffice_: string;
	aPPROVALS: string;
	aPPROVEDBY_: string;
	cARRIERPREFERENCE: string;
	certifiedPrintMaker: string;
	cHANGEORDERREQUESTFORM: string;
	cUSTOMERORDER__: string;
	dATE_: string;
	dimA: string;
	dimB: string;
	dimC: string;
	dimD: string;
	dIMENSIONA: string;
	dIMENSIONB: string;
	dIMENSIONC: string;
	dIMENSIOND: string;
	dIMENSIONE: string;
	dIMENSIONF: string;
	dIMENSIONG: string;
	dIMENSIONH: string;
	dIMENSIONS: string;
	dimensionsShownAreForStandardUnitLessOptions_: string;
	dimensionsShownAreForStandardUnitLessOptions_SeeCertifiedDrawingForMoreDetails_: string;
	dISCHARGE: string;
	documentBrowser: string;
	dRAIN: string;
	eLECTRONICORDERFORM: string;
	eLECTRONICORDERFORM_CONTINUED: string;
	eQUIPMENTSUMMARY: string;
	eSTSHIPWEIGHT_LessOptions_: string;
	fACTORYINVOICETO_: string;
	fACTORYORDER__: string;
	fREIGHT: string;
	fREIGHTTERMS_: string;
	hOTGASINLET: string;
	hOTGASOUTLET: string;
	hOTGASSIDEPORT: string;
	iD__: string;
	iTEM__: string;
	lbs_LessOptions_: string;
	mANUALENTRY: string;
	nATIONALREFRIGERATIONWillFurnishEquipmentInAccordanceWithThisDrawingAndSpecifications_AndSubjectToItsPublishedWarranty_ApprovalOfThisDrawingSignifiesThatTheEquipmentIsAcceptableUnderTheProvisionOfTheJobSpecifications_AnyChangeMadeHereonByAnyPersonWhomsoeverSubjectToAcceptanceByNATIONALREFRIGERATIONAtItsHomeOffice_: string;
	nEWSYSTEM: string;
	nOTES_: string;
	oEMult: string;
	oPTION: string;
	oRDER__: string;
	oRDERDATE_: string;
	pACKAGE: string;
	pACKAGEITEM: string;
	pACKAGEOPTION: string;
	pAGE: string;
	pANLOOP: string;
	pRICEBOOK_: string;
	priceTotal: string;
	pROJECT_: string;
	pURCHASER_: string;
	pURCHASER_SPO__: string;
	qUOTE__: string;
	qUOTEDATE_: string;
	rEF__: string;
	rEMARKS_SPECIALSHIPPINGINSTRUCTIONS: string;
	s_H: string;
	servicePartsList: string;
	sHIPMENT_: string;
	shippingAndHandling: string;
	sHIPPINGWEIGHT: string;
	sOUND: string;
	sTOCKINGMODELS: string;
	sUBMITTEDBY_: string;
	sUBTOTAL: string;
	sUCTION: string;
	tAGGING_: string;
	totalNet: string;
	uSERID_: string;
	vENDORID_: string;
	wATER: string;
	wEIGHT: string;
	aSuiteOfSoftwareApplicationsCreatedToHelpRefrigerationProfessionalsMakeAccurateCalculations_AccessProductInformation_ChooseComponentsAndPrintTechnicalDrawings: string;
	pleaseUpdateAllPrices_: string;
	applicationRegistrationKey_: string;
	saveAsNewContact: string;
	saveContact: string;
	saveNewContact: string;
	partNumber: string;
	loadCalculatorII: string;
	systemCapacity: string;
	descriptions: string;
	boxApplication: string;
	theInternalRelativeHumidityMustBeGreaterThan: string;
	theInternalRelativeHumidityMustBeLessThan: string;
	lineLoss: string;
	systemRunTime: string;
	systemRunTimeMustBeGreaterThanOneHour_: string;
	systemRunTimeMustBeLessThanTwentyfourHours_: string;
	theSystemLineLossIsNotEntered_: string;
	theSystemLineLossIsTooHigh_: string;
	theSystemLineLossIsTooLow_: string;
	averagePassageTime_Seconds_: string;
	passagesPerDay: string;
	curtainEffectiveness: string;
	standOpenTimePerDay_Minutes_: string;
	cONSTRUCTIONANDDIMENSIONS: string;
	volumeAndTemperatures: string;
	airFlowRates: string;
	hasConfigurationErrors: string;
	optionConfigurations: string;
	setConfiguration: string;
	can_tHaveOptions: string;
	notRecommendedOptions: string;
	recommendedOptions: string;
	availableConfigurations_: string;
	normalConfiguration_: string;
	hasConfigurationWarning: string;
	optionConfigurationError_: string;
	optionConfigurationWarning_: string;
	optionConfigurtionErrorsDetected_: string;
	pleaseSelectOneOfTheAvailableConfigurations_: string;
	thisOptionHasMultipleConfigurationsAvailable_: string;
	includesOptions: string;
	over_Ride_SubmitErrors: string;
	cHeckPRicesOK: string;
	errorLabel: string;
	noRefrigerantRatingValuesListed_: string;
	termsOfSale: string;
	warranty: string;
	lOGO: string;
	tERMSOFSALE: string;
	vENDORINFORMATION: string;
	useTOSImageFile: string;
	checkAllErrors: string;
	drawings: string;
	optionList: string;
	specificationsPage: string;
	warningLabel: string;
	clickCANCELToAdjustYourSelectedOptions_: string;
	youWillNotBeAbleToSubmitThisOrderUsingThisOptionConfiguration_: string;
	oPTIONDESCRIPTIONAPPENDIX: string;
	requestedOrderShipDateIsBeforeToday_: string;
	ordersListed_: string;
	sEARCHINGANDSORTING: string;
	synchronizingOrders_PleaseWait: string;
	archive_: string;
	itemPartDescriptionRequired_: string;
	doYouWantToAttemptToOpenThisFileAnyway_: string;
	oldStyleChecksumDoesNotMatch_: string;
	failedToOpenDataCatalog_: string;
	oPTIONDESCRIPTIONAPPENDIXCONTINUED: string;
	includeUnselectedOptions: string;
	sELECTEDOPTIONDESCRIPTIONS: string;
	sELECTEDOPTIONDESCRIPTIONSCONTINUED: string;
	setRevisionSUbmitted: string;
	uNsubmitQuote: string;
	addDoors_Openings: string;
	deleteDoors_Openings: string;
	dOORUSAGE: string;
	editDoor_Openings: string;
	curtainR_Value: string;
	loadingPlatformRoll_UpDoor: string;
	trailerDockRoll_UpDoor: string;
	reach_InGlassDoor: string;
	boxUsage: string;
	floorType: string;
	theRefrigeratedFloorIsBuiltDirectlyOnTheGround__OrTheRoomInWhichTheRefrigeratedBoxIsLocatedIsBuiltDirectlyOnTheGround__: string;
	orTheRoomInWhichTheRefrigeratedBoxIsLocatedIsBuiltDirectlyOnTheGround_: string;
	orThereIsAVentilatedSpaceUnderTheRoomInWhichTheRefrigeratedSpaceIsLocated_: string;
	theRefrigeratedFloorIsBuiltDirectlyOnTheGround_: string;
	thereIsAVentilatedSpaceUnderTheRefrigeratedFloor_: string;
	thisIncludesCrawlSpacesOrUpperFloorInstallations_: string;
	coolingLoads: string;
	checkBox: string;
	aboveGrade: string;
	onGrade: string;
	heatingTemperature: string;
	surroundingAirTemperature: string;
	onGrade_FrostHeaveProtected: string;
	aNDAControlledFrostHeavePreventionHeaterSystemIsOrWillBeInstalled_: string;
	construction: string;
	wALL_FLOORANDCEILINGLOADS: string;
	pRODUCTLOADS: string;
	enterTemp: string;
	pullDown: string;
	tOTALPRODUCTLOAD: string;
	tOTALPRODUCTWEIGHT: string;
	tOTALWALL_FLOORANDCEILINGLOAD: string;
	vOLUME: string;
	mISCELLANEOUSLOADS: string;
	tOTALMISCELLANEOUSLOAD: string;
	aVERAGE: string;
	fORKLIFTS_TOWMOTORS: string;
	hEAVY: string;
	externalRh: string;
	internalRh: string;
	tOTALINFILTRATIONLOADESTIMATEDBYDOORSANDOPENINGS: string;
	ventilation: string;
	iNFILTRATIONLOAD_ESTIMATEDBYDOORSANDOPENINGS: string;
	iNFILTRATIONLOAD_ESTIMATEDBYVENTILATION: string;
	iNFILTRATIONLOAD_ESTIMATEDBYVOLUMEANDUSAGE: string;
	curtains: string;
	doorOrOpeningType: string;
	openTime: string;
	preparedBy: string;
	preparedFor: string;
	customerInformation: string;
	job_ProjectName: string;
	cOOLINGREQUIREMENTS: string;
	designLoad: string;
	equipmentLoad: string;
	hours: string;
	safetyLoad: string;
	safetyFactor: string;
	capacityRequired: string;
	condenserAmbient: string;
	sYSTEMDESIGNSUMMARY: string;
	sYSTEMEQUIPMENTSELECTION: string;
	nOTSELECTED_: string;
	evaporatorTD: string;
	fanLoad: string;
	deleteSystem: string;
	duplicateSystem: string;
	editSystem: string;
	area_ZoneOrSystemName: string;
	areaDeletionRequested_: string;
	systemDetails: string;
	equipmentCapacity: string;
	equipmentTD: string;
	openBoxDesigner: string;
	sYSTEMSLOADSUMMARY: string;
	importLoadCalculatorIDesignFile: string;
	importLoadCalculatorIIDesignFile: string;
	newCondenserDesign: string;
	newFluidCoolerDesign: string;
	newGlycolCoilDesign: string;
	newLoadCalculatorIIDesign: string;
	newSystemNamed: string;
	safetyFactor___: string;
	quantityPERCompressorUnit: string;
	quantityPERCondensingUnit: string;
	hourlyLoadRequired: string;
	suctionLineLoss: string;
	connectionCount: string;
	pleaseSelectAndEditTheAppropriateSection_: string;
	toEnterOrEditDoorOrOpeningDetails_: string;
	clearAllSelections: string;
	interiorTemperature: string;
	areYouSureYouWantToSubmitThisMyEditOrder_: string;
	doYouWantToProcessThisChangeOrderAsAnOriginalMyEditOrder_: string;
	ifYouSubmitThisOrder_YouAreOfficiallyTakingResponsibilityForTheMyEditOrder_: string;
	untilTheOrderIsProcessedByTheManufacturerYouMayStopThisMyEditOrder_: string;
	untilTheOrderIsProcessedByThePurchaserYouMayStopThisMyEditOrder_: string;
	wARNING_YOUARENOTTHEORIGINATOROFTHISMyEditOrder_: string;
	youCanNoLongerStopThisMyEditOrder_: string;
	youDoNotHaveOwnershipOfThisOrder_: string;
	testCloneCompare: string;
	modelNotLoaded_: string;
	temperature_TooHigh_: string;
	temperature_TooLow_: string;
	noRatingFound_: string;
	temperature_IsTooHigh_: string;
	temperature_IsTooLow_: string;
	balancedCapacity: string;
	evap_TD: string;
	ambientTemperature_EvapTemperature_: string;
	boxTemperature_EvapTemperature_: string;
	condensingTemperature_EvapTemperature_: string;
	evapTemperature: string;
	isInvalid_: string;
	isMissing_: string;
	isOutOfRange_: string;
	systemLoadIsInvalid_: string;
	systelLoadNotGiven_: string;
	temperature_NotGiven_: string;
	productName: string;
	loadCalculationDesignHasErrors_: string;
	loadCalculationDesignHasWarnings_: string;
	equipmentSelectionIsIncomplete_: string;
	systemIsNotBalanced_: string;
	designHasErrors_: string;
	designHasWarnings_: string;
	loadCalculationSystemHasDesignErrors_: string;
	loadCalculationSystemHasDesignWarnings_: string;
	testMail: string;
	oneOrMoreRequestedDatesAreInThePast_: string;
	shipmentDateErrorsFound_: string;
	currency: string;
	edit_AddDoorsAndOpenings: string;
	thePriceBookUsedForTheOpenChangeRequestHasExpired_: string;
	thePriceBookUsedForThisOrderHasExpired_: string;
	thePriceBooksUsedForThisOrderHaveExpired_: string;
	alwaysOpenAreaOfWallLeadingToAnotherZone_: string;
	areaToBeExcludedFromWallDefinition_: string;
	nonOpeningGlassWindow_: string;
	normallyClosedDoorForStaffOrGoodsTraffic_: string;
	roll_UpDoorOpenToOutsideEnvironmentOrOtherZone_: string;
	sectionOfWallWithDifferentInsulationOrExternalEnvironmentFromRestOfWall_: string;
	pleaseSelectAnOpeningType_: string;
	lOADCALCULATION: string;
	reach_InSelfServeGlassDoors_: string;
	roll_UpDoorDesignedToBeNormallyClosedOrSealedByTransportTrailer_: string;
	areYouSureYouWantToUseTheSeletedValue_: string;
	theInfiltrationCalculatedForTheseIsGreaterThanTheVentilationInfiltration_: string;
	theInfiltrationCalculatedForTheseIsGreaterThanTheVolumeEstimatedInfiltration_: string;
	valueWarning_: string;
	youHaveDoorsAndOpeningsDefinedInTheBoxDesign_: string;
	conductionLoad: string;
	iNFILTRATIONLOADPERUNIT: string;
	iNSULATIONLOADPERUNIT: string;
	outdoorCool: string;
	outdoorWarm: string;
	outdoors_ColdClimate_: string;
	eSTIMATEDBYVENTILATION: string;
	eSTIMATEDBYBOXVOLUME_USAGE: string;
	eSTIMATEDBYDOORSANDOPENINGS: string;
	ventilationInfiltrationEstimate: string;
	volume_UsageInfiltrationEstimate: string;
	infiltrationMethodChosenIsNotTheMaximumLoadEstimateEntered_: string;
	calculationMethod: string;
	useMaximum: string;
	lOADCONNECTIONSCONTINUED: string;
	insulationLayerIsRecommendedForSlabOnGradeFreezers_: string;
	aDDNEWSYSTEMUSING: string;
	iMPORTFROMFILE: string;
	miscelaneousUnits: string;
	importSystemsFromALoadCalculatorIIFile_: string;
	ballyBoxWizardFile: string;
	importSystemsFromABallyBoxWizardFile_: string;
	importSystemsFromAKeepriteCalc_RiteFile_: string;
	importSystemsFromATretonCalculoadFile_: string;
	keepriteCalc_RiteFile: string;
	tretonCalculoadFile: string;
	abortAddingASystem_: string;
	saveSystemAsTemplate: string;
	sTANDARDAPPLICATIONS: string;
	uSERTEMPLATES: string;
	rH_TD: string;
	createSystem_PleaseSelectApplicationTypeOrTemplate: string;
	fACTORYTEMPLATES: string;
	saveAsTemplate: string;
	evapTDIsTooHighForTheSelectedApplication_: string;
	evapTDIsTooLowForTheSelectedApplication_: string;
	theInternalTemperatureIsTooHighForTheSelectedApplication_: string;
	theInternalTemperatureIsTooLowForTheSelectedApplication_: string;
	newMulti_SystemJob: string;
	betaVersionDesignDetected_: string;
	doYouWantToUpdateTheDesignToTheNewerVersion_: string;
	newerCalculatorVersionDetected_: string;
	pleaseVerifyYourDesign_: string;
	theLoadCalculationHASCHANGEDWithTheNewerVersion_: string;
	theLoadCalculationHASNOTChangedWithTheNewerVersion_: string;
	theLoadCalculationsMayHaveChangedSinceYouFirstCreatedThisJob_: string;
	thisDesignWasCreatedWithTheBetaVersionOfTheSoftware_: string;
	export_CSV: string;
	optionDescriptionList: string;
	pleaseCheckYourEquipmentSelection_: string;
	ifThisProblemPersists_PleaseContactTheFactory_: string;
	selectedEquipmentNoLongerBalances_: string;
	thisDesignWasCreatedWithAnEarlierVersionOfThisSoftware_: string;
	newerVersionDesignDetected_: string;
	pleaseRestartYourSoftwareToUpdateYourSystemAndDataFiles_: string;
	lade: string;
	pending: string;
	outdoors_ColdClimate___: string;
	loadingServicePartCatalog_: string;
	clearFilter: string;
	category: string;
	partName_No: string;
	qTTY: string;
	modelNumber_WhereUsed: string;
	equipmentDetails: string;
	leadTimeChangesFound_: string;
	leadTimesChangedOn: string;
	leadTimesChangedOnOneRevision_: string;
	noLeadTimeChangesFound_: string;
	noLeadTimeChangesOccurred_: string;
	oneLeadTimeChangeFound_: string;
	copyShippingDateInformationTo: string;
	forComplexFloorsWeSuggestYouUseTheFloorPlanner: string;
	onlyOneFloorSectionMayBeAddedWithThisTool_: string;
	sorry: string;
	orEnterTheTotalFloorAreaAndPerimeterValuesInTheExistingFloorDefinition_: string;
	canNotSubmit_: string;
	invalidEmail_Format_: string;
	OrdersWillBeMarkedAsShipped_: string;
	OrdersWillBeMarkedClosed_: string;
	calendarDays: string;
	leadTimeCalendarDays: string;
	leadTimeAddWeeks: string;
	leadTimeWeeksAdded: string;
	pleaseContactFactory: string;
	wARNING_SUBMITTEDQUOTENOTFOUND_: string;
	CalandarDays_: string;
	sorry_: string;
	youCanNotDeleteTheOrderedQuoteRevision_: string;
	deleteCurrentQuoteRevision: string;
	restoreCurrentQuoteRevisionToLastSavePoint: string;
	canNotRestoreTheOrderedQuoteRevision_: string;
	thePackage: string;
	standardFeatures: string;
	additionalOptionsAndAccessories: string;
}

const translations: LocaleStrings = new LocalizedStrings({
	en: enStrings,
	fr: frStrings,
	es: esStrings
});

class TranslationService {
	public static getCurrentLanguageFromQueryString(): string {
		return HelperService.getQueryVariable("lang");
	}

	public static getTranslations() {
		let language = this.getCurrentLanguageFromQueryString();
        let storedLanguage = window.sessionStorage.getItem("lang");
        if (storedLanguage === "" ||
            storedLanguage === null ||
            storedLanguage === undefined) {
            storedLanguage = window.localStorage.getItem("lang");

            if (storedLanguage != null)
                window.sessionStorage.setItem("lang", storedLanguage);
        }

		if (
			(language === "" || language === null || language === undefined) &&
			(storedLanguage === "" ||
				storedLanguage === null ||
				storedLanguage === undefined)
		)
			window.sessionStorage.setItem("lang", "en");
		else if (
			language !== "" &&
			language !== null &&
			language !== undefined &&
			language !== storedLanguage
		)
			window.sessionStorage.setItem("lang", language);

		storedLanguage = window.sessionStorage.getItem("lang");

		translations.setLanguage(storedLanguage === null ? "en" : storedLanguage);

		return translations;
	}
}

export default TranslationService;
