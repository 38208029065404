import * as React from "react";

export interface NumericTextBoxProps {
    name: string;    
    className?: string;
    value?: string | number;
    min?: string | number;
    max?: string | number; 
    disabled?: boolean;
    readOnly?: boolean;
    onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
}

const NumericTextBox: React.FunctionComponent<NumericTextBoxProps> = ({
    name,
    className,
    value,
    min,
    max,
    disabled,
    readOnly,
    onChange
}: NumericTextBoxProps) => {

    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {

        const minValue = min !== undefined
            ? min.toString()
            : "";

		const inputValue =
			event.currentTarget.value.trim() === ""
				? minValue
                : parseInt(event.currentTarget.value, 10).toString();
                
        event.currentTarget.value = inputValue;

        if (onChange) {
            onChange(event);
        }
	};

    return (
        <input
            type="number"
            name={name}
            className={`input is-small ${className ?? ""}`}
            value={value}
            min={min}
            max={max}
            disabled={disabled}
            readOnly={readOnly}
            onChange={handleChange}
        />
    );
};

export default NumericTextBox;
