import IEquipmentState from "../models/interfaces/IEquipmentState";
import ISystem from "../models/interfaces/ISystem";

// declare var require: any

class StorageFacade {

    private static getItemRaw(key: string): any {
        let value = sessionStorage.getItem(key);
        if (value === null) value = localStorage.getItem(key);
        if (value === null) return null;

        return value;
    }

    private static getItem(key: string): any {
        let value = this.getItemRaw(key);        
        return JSON.parse(value);
    }

    private static setItem(key: string, value: any) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }    

    public static get equipmentSelection(): IEquipmentState | null {
        // const store = require('store');
        return this.getItem("KeepRite.EquipmentSelection");
    }    

    public static set equipmentSelection(selection: IEquipmentState | null) {
        // const store = require('store');
        this.setItem("KeepRite.EquipmentSelection", selection);
    }    
    
    public static get project(): any | null {
        // const store = require('store');
        return this.getItem("KeepRite.Project");
    }    

    public static get quote(): any | null {
        return this.getItem("KeepRite.Quote");
    }    

    public static get loadCalcSystem(): ISystem | null {
        // const store = require('store');
        return this.getItem("KeepRite.LoadCalcSystem");
    }    

    public static set loadCalcSystem(system: ISystem | null) {
        // const store = require('store');
        this.setItem("KeepRite.LoadCalcSystem", system);
    }

    public static transferLoadCalcSystemFromSessionToLocal() {        
        const system: string | null = sessionStorage.getItem("KeepRite.LoadCalcSystem")
        if (system !== null) localStorage.setItem("KeepRite.LoadCalcSystem", system);
    }

    public static transferProjectFromSessionToLocal() {
        const project: string | null = sessionStorage.getItem("KeepRite.Project");
        if (project !==null) localStorage.setItem("KeepRite.Project", project);
    }

    public static get brand(): string {
        return this.getItemRaw("brand");
    }

    public static get divisionId(): string {
        let divId: string;
        divId = "-1";

        if (this.project?.Division?.id)
            divId = this.project.Division.id.toString();
        else {
            let divisionId = this.getItem("KeepRite.DivisionId");
            if (divisionId)
                divId = divisionId.toString();
        }

        return divId;
    }   

    public static get jobName(): string {
        return this.project?.ProjectName
            ? this.project.ProjectName.toString()
            : "";
    } 

    public static get myInfo(): any {
        return this.project?.MyInfo;
    } 

    public static get customerInfo(): any {
        return this.project?.CustomerInfo;
    } 

    public static get AllowedSpecialModels(): any {
        return this.getItem("KeepRite.UserProfile");
    }

    public static get UsesMetricSystem(): boolean {
        const userProfile = this.getItem("KeepRite.UserProfile");
        return userProfile?.UsesMetricSystem ?? false;
    }

    public static get EquipmentToPrint(): any {

        return this.getItem("KeepRite.EquipmentToPrint");
    }    

    public static get CondenserConnections(): any {
        return this.getItem("KeepRite.CondenserConnections");
    }   

    public static get CondenserToPrint(): any {

        return this.getItem("KeepRite.ACCCondenser");
    }

    public static get ACCFilters(): any {
        return this.getItem("KeepRite.ACCFilters");
    }

    public static get CPMFormInput(): any {
        return this.getItem("KeepRite.CPMFormInput");
    }

    public static get CPMQuoteFormInput(): any {
        return this.getItem("KeepRite.CPMQuoteFormInput");
    }

    public static get EquipmentToPrint_Options(): any {
        return this.getItem("KeepRite.EquipmentToPrint.Options");
    }

    public static get EquipmentToPrint_ExcludedOptions(): any {
        return this.getItem("KeepRite.EquipmentToPrint.ExcludedOptions");
    }

    public static get EquipmentToPrint_CapacityHasError(): boolean {
        return this.getItem("KeepRite.EquipmentToPrint.CapacityHasError");
    }

    public static set EquipmentToPrint_OptionsHasError(hasError: boolean) {        
        this.setItem("KeepRite.EquipmentToPrint.OptionsHasError", hasError);
    }

    public static get EquipmentToPrint_Attributes(): any {
        return this.getItem("KeepRite.EquipmentToPrint.Attributes");
    }

    public static get CPMSubmittedBy(): string {
        const submittedBy = this.getItemRaw("KeepRite.CPMSubmittedBy");
        if (submittedBy)
            return submittedBy;
        else
            return "";
    }

    public static get isFromLegacy(): boolean {
        const userProfile = this.getItem("KeepRite.UserProfile");
        return userProfile?.IsFromLegacyApp ?? false;
    }
    
}

export default StorageFacade;
