import * as React from "react";

import IConstructionLayer from "../models/interfaces/IConstructionLayer";
import IFormState from "../models/interfaces/IFormState";
import IMaterial from "../models/interfaces/IMaterial";

import { hasValue } from "../types/measure";

import * as ConstructionLayerFields from "../models/helpers/ConstructionLayerFormFields";
import * as ConstructionLayerCalculations from "../models/helpers/ConstructionLayerCalculations";

import { SECTION_WIDTH_DEFAULT, SECTION_CENTRE_DISTANCE_DEFAULT } from "../Constants";

import BoxMaterialsContext from "../context/BoxMaterialsContext";

import useTranslationContext from "./useTranslationContext";
import useApplicationConfigs from "./useApplicationConfigs";

const useBoxMaterialsContext = () => {
    
    const { boxMaterials, isMaterialListLoaded, updateBoxMaterials } = React.useContext(BoxMaterialsContext);

    const { getBoundaryConstructionLayers } = useApplicationConfigs();
    const { translations } = useTranslationContext();

    const getBoxMaterialName = (        
        selectedMaterial: IMaterial | undefined
    ): string => {

        let materialName = "";
        
        if (selectedMaterial) {
            if (selectedMaterial.isConstruct) {
                materialName = `${translations.composite} := ${selectedMaterial.name}`;
            }
            else if (selectedMaterial.name.trim() !== "") {
                const description = (selectedMaterial.example && selectedMaterial.example.trim() !== "")
                    ? `, ${selectedMaterial.example}`
                    : "";
                materialName = `${selectedMaterial.name}${description}`;
            }
        }

        return materialName;
    };

    const getMaterialConstruction = (constructionFromStorage: IMaterial) => {
        const initialValues: IConstructionLayer = {
            name: "",
            materialId: "0",
            materialName: "",
            thickness: null,
            kFactor: null,
            rValuePerInch: null,
            rValueEffective: null,
            isBoundaryInteriorLayer: false,
            isBoundaryExteriorLayer: false,
            isSectional: false,
            sectionCentreDistance: SECTION_CENTRE_DISTANCE_DEFAULT,
            sectionMaterialId: "0",
            sectionMaterialName: "",
            sectionWidth: SECTION_WIDTH_DEFAULT,
            sectionKFactor: null,
            sectionRValuePerInch: null
        };
        
        const initialFormState: IFormState<IConstructionLayer> = {
            defaultValues: initialValues,
            actualValues: initialValues
        };

        const calculatedLayers = constructionFromStorage.layerMaterials?.map(lm => {
            const material = boxMaterials.find(m => m.id === Number(lm.materialId));
            const materialName = getBoxMaterialName(material);
            
            const thickness = ConstructionLayerFields.getThickness(
                initialFormState,
                lm.thickness,
                material).actualValue;

            const kFactor = ConstructionLayerFields.getKFactor(
                initialFormState,
                lm.kFactor,
                lm.rValuePerInch,
                lm.rValueEffective,
                material).actualValue;

            const rValuePerInch = ConstructionLayerFields.getRValuePerInch(
                initialFormState,
                lm.rValuePerInch,
                lm.kFactor,
                lm.rValueEffective,
                material).actualValue;

            const sectionMaterialId = lm.isSectional
                ? lm.sectionMaterialId
                : "0";
                
            const sectionMaterial = boxMaterials.find(m => m.id === Number(sectionMaterialId));
            const sectionMaterialName = getBoxMaterialName(sectionMaterial);

            let name = `${sectionMaterial ? `${sectionMaterialName} | ` : ""}${materialName}`

            if (lm.isBoundaryInteriorLayer) {
                name = getBoundaryConstructionLayers()[0].name;
            }
            else if (lm.isBoundaryExteriorLayer) {
                name = getBoundaryConstructionLayers()[1].name;
            }

            const sectionCentreDistance = ConstructionLayerFields.getSectionCentreDistance(
                initialFormState,
                lm.sectionCentreDistance,
                lm.isSectional).actualValue;

            const sectionWidth = ConstructionLayerFields.getSectionWidth(
                initialFormState,
                lm.sectionWidth,
                sectionMaterial,
                lm.isSectional).actualValue;

            const sectionKFactor = ConstructionLayerFields.getSectionKFactor(
                initialFormState,
                lm.sectionKFactor,
                lm.sectionRValuePerInch,
                lm.rValueEffective,
                sectionMaterial,
                lm.isSectional).actualValue;

            const sectionRValuePerInch = ConstructionLayerFields.getSectionRValuePerInch(
                initialFormState,
                lm.sectionRValuePerInch,
                lm.sectionKFactor,
                lm.rValueEffective,
                sectionMaterial,
                lm.isSectional).actualValue;

            const rValueEffective = ConstructionLayerFields.getRValueEffective(
                initialFormState,
                lm.rValueEffective,
                thickness,
                rValuePerInch,
                lm.isSectional,
                sectionRValuePerInch,
                sectionCentreDistance,
                sectionWidth,
                material,
                sectionMaterial
            ).actualValue;

            const layer: IConstructionLayer = {
                ...lm,
                name,
                materialName,
                thickness,
                kFactor,
                rValuePerInch,
                sectionMaterialName,
                sectionCentreDistance,
                sectionWidth,
                sectionKFactor,
                sectionRValuePerInch,
                rValueEffective
            };

            return layer;
        });

        const stdThickness = ConstructionLayerCalculations.calculateConstructionThickness(calculatedLayers ?? []);
        const rValue = ConstructionLayerCalculations.calculateConstructionRValue(calculatedLayers ?? []);
        const kFactor = ConstructionLayerCalculations.calculateConstructionKFactor(stdThickness, rValue);

        const construction: IMaterial = {
            ...constructionFromStorage,
            layerMaterials: calculatedLayers ?? [],
            stdThickness,
            rValue,
            kFactor
        };

        return construction;
    };

    const getBoxMaterial = React.useCallback((id: number) => {
        if (id === -1000000) { // Other
            return {
                id: -1000000,
                name: "",
                example: null,
                locked: false,
                showAsSectionType: false,
                showAsBuildingMaterial: false,
                kFactor: null,
                stdThickness: null,
                stdWidth: null,
                rValue: null,
                thickness: null,
                isConstruct: false,
                layerMaterials: null
            } as IMaterial;
        }

        const material = boxMaterials.find(m => m.id === id);

        if (material?.isConstruct) {
            return getMaterialConstruction(material);
        }

        return material;
    }, [boxMaterials]);

    const isMaterialLocked = (materialId: number): boolean => {
        return boxMaterials.find(m => m.id === materialId)?.locked ?? false;
    }

    const isMaterialConstruct = (materialId: number): boolean => {
        return boxMaterials.find(m => m.id === materialId)?.isConstruct ?? false;
    }

    const isMaterialThicknessStandard = (materialId: number): boolean => {
        return hasValue(boxMaterials.find(m => m.id === materialId)?.stdThickness);
    }

    return { 
        boxMaterials,
        isMaterialListLoaded,
        updateBoxMaterials,
        getBoxMaterial,
        getBoxMaterialName,
        isMaterialLocked,
        isMaterialConstruct,
        isMaterialThicknessStandard
    };
}

export default useBoxMaterialsContext;
