enum MeasurementType {
	Temperature,
	PositiveTemperature,
	TemperatureRelative,
	Dimension,
	SmallerDimension,
	Weight,
	Rating,
	ElectricPower,
	AirFlow,
	PositiveDecimal,
	Percent,
	Area,
	Volume
}

export default MeasurementType;
