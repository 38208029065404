/* eslint-disable no-underscore-dangle */
import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faInfoCircle,
	faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";

import Button from "../helpers/Button";
import Checkbox from "../helpers/Checkbox";
import ConfirmationMessage from "../helpers/ConfirmationMessage";
import FullScreenModal from "../helpers/FullScreenModal";
import NumericTextBox from "../helpers/NumericTextBox";
import OptionConfigurations from "./OptionConfigurations";
import WarningMessage from "../helpers/WarningMessage";

import { Config } from "../../Constants";

import IOption from "../../models/interfaces/IOption";
import IOptionGroup from "../../models/interfaces/IOptionGroup";
import IOptionPackageItem from "../../models/interfaces/IOptionPackageItem";
import IOptionRule from "../../models/interfaces/IOptionRule";
import IOptionConfigurationSet from "../../models/interfaces/IOptionConfigurationSet";
import IOptionConfigurationVariant from "../../models/interfaces/IOptionConfigurationVariant";
import IEquipmentOption from "../../models/interfaces/IEquipmentOption";

import useErrorContext from "../../hooks/useErrorContext";
import useLoadingContext from "../../hooks/useLoadingContext";
import useTranslationContext from "../../hooks/useTranslationContext";

import * as OptionUtils from "./EquipmentOptions.utils";

import "./EquipmentOptions.css";
import StorageFacade from "../../services/StorageFacade";

interface EquipmentOptionsProps {
	title: string;
	show: boolean;
	id: number;
	divisionId: number;
    selectedOptionList: IEquipmentOption[];
    excludedOptionIds: string | null;
	onOkClick: any;
	onCancelClick: any;
}

const EquipmentOptions: React.FunctionComponent<EquipmentOptionsProps> = ({
	title,
	show,
	id,
	divisionId,
    selectedOptionList,
    excludedOptionIds,
	onOkClick,
	onCancelClick
}: EquipmentOptionsProps) => {
	const [data, setData] = React.useState([] as IOptionGroup[]);

	const [rules, setRules] = React.useState([] as IOptionRule[]);

	const { showLoading, hideLoading } = useLoadingContext();
	const { showError } = useErrorContext();
	const { translations } = useTranslationContext();

	const [hasError, setHasError] = React.useState(false);

	const [selectedOptions, setSelectedOptions] = React.useState(
		[] as IOption[]
	);

	const [currentPackage, setCurrentPackage] = React.useState(
		null as IOption | null
	);

	const [configurationSetModal, setConfigurationSetModal] = React.useState({
		show: false,
		optionId: 0,
		optionName: "",
		data: undefined as IOptionConfigurationSet | undefined
	});

	const [confirmationMessage, setConfirmationMessage] = React.useState({
		title: "Confirmation",
		message: <div>Are you sure?</div>,
		show: false,
		onYesClick: () => {},
		onNoClick: () => {}
	});

	const [warningMessage, setWarningMessage] = React.useState({
		title: "Warning",
		message: <div />,
		show: false,
		showErrorIcon: false,
		onClose: () => {}
	});

	const closeConfirmationMessage = () => {
		setConfirmationMessage({
			...confirmationMessage,
			show: false
		});
	};

	const closeWarningMessage = () => {
		setWarningMessage({
			...warningMessage,
			show: false
		});
	};

	const showWarningMessage = (messageTitle: string, messageBody: string, isError: boolean) => {
		setWarningMessage({
			title: messageTitle,
			message: <p>{messageBody}</p>,
			show: true,
			showErrorIcon: isError,
			onClose: closeWarningMessage
		});
	};

	React.useEffect(() => {
		const loadData = async () => {
			if (id && divisionId && show) {
				showLoading();

				try {
					const optionsData = await OptionUtils.loadOptions(
						id,
						divisionId,
                        selectedOptionList,
                        excludedOptionIds
					);

					setCurrentPackage(optionsData.packageMatch);
					setData(optionsData.optionGroups);
					setSelectedOptions(optionsData.selected);
					setRules(optionsData.selectionRules);
					setHasError(optionsData.hasError);

					hideLoading();
				}
				catch (error: any) {
					showError({
						name: "Error",
						text: `An error was encountered while loading the options. ${error.message ?? ""}`
					});
					hideLoading();
				}
			}
		};

		loadData();

	}, [show]);

	const selectPackage = (selectedOption: IOption) => {
		const updatedData = OptionUtils.selectPackage(data, selectedOption);

		setCurrentPackage(updatedData.selectedOption);
		setData(updatedData.groups);
		setSelectedOptions(updatedData.selected);
		setHasError(updatedData.hasError);
	};

	const unselectPackage = (selectedOption: IOption) => {
		const updatedData = OptionUtils.unselectPackage(data, selectedOption);

		setCurrentPackage(null);
		setData(updatedData.groups);
		setSelectedOptions(updatedData.selected);
		setHasError(updatedData.hasError);
	};

	const selectOption = (
		selectedOption: IOption,
		isChecked: boolean
	) => {
		let groups = [...data];
		let selected = [] as IOption[];
		let isInvalidOption = false;

		let isCurrentPackageCollectionModified = false;

		const updatedData = OptionUtils.updateOption(
			groups,
			currentPackage,
			selected,
			isCurrentPackageCollectionModified,
			selectedOption,
			isChecked,
			rules
		);

		if (updatedData) {
			if (updatedData.errorMessage) {
				isInvalidOption = true;
				showWarningMessage(updatedData.errorMessage.title, updatedData.errorMessage.body, true);
			}
			else {				
				groups = updatedData.groups;
				selected = updatedData.selected;
				isCurrentPackageCollectionModified =
					updatedData.isCurrentPackageCollectionModified;

				if (updatedData.warningMessage) {
					showWarningMessage(updatedData.warningMessage.title, updatedData.warningMessage.body, false);
				}
			}
		}

		if (!isInvalidOption) {
			if (isCurrentPackageCollectionModified) {
				const checkedData = OptionUtils.checkPackageMatch(groups, selected);
				if (checkedData) {
					groups = checkedData.groups;
					selected = checkedData.selected;

					if (checkedData.packageMatch) {
						setCurrentPackage(checkedData.packageMatch);
					}
				}
			}

			const validatedData = OptionUtils.validateSelectedConfigurations(groups, selected);
			if (validatedData) {
				groups = validatedData.groups;
				selected = validatedData.selected;
				setHasError(validatedData.selectionHasError);
			}

			setData(groups);
			setSelectedOptions(selected);
		}
	};

	const selectMultipleOptions = (
		options: {
			option: IOption;
			isChecked: boolean;
            state: number;
		}[]
	) => {
		let groups = [...data];
		let selected = [] as IOption[];
		let hasInvalidOption = false;

		let isCurrentPackageCollectionModified = false;

		options.forEach(so => {

            so.option.state = so.state;            

			if (!hasInvalidOption) {
				const selectedOption = so.option;
                
				const updatedData = OptionUtils.updateOption(
					groups,
					currentPackage,
					selected,
					isCurrentPackageCollectionModified,
					selectedOption,
					so.isChecked,
					rules
				);

                
				if (updatedData) {
					if (updatedData.errorMessage) {
						hasInvalidOption = true;
						showWarningMessage(updatedData.errorMessage.title, updatedData.errorMessage.body, true);
					}
					else {				
						groups = updatedData.groups;
						selected = updatedData.selected;
						isCurrentPackageCollectionModified =
							updatedData.isCurrentPackageCollectionModified;

						if (updatedData.warningMessage) {
							showWarningMessage(updatedData.warningMessage.title, updatedData.warningMessage.body, false);
						}
					}				
				}
			}
		});

		if (!hasInvalidOption) {
			if (isCurrentPackageCollectionModified) {
				const checkedData = OptionUtils.checkPackageMatch(groups, selected);
				if (checkedData) {
					groups = checkedData.groups;
					selected = checkedData.selected;

					if (checkedData.packageMatch) {
						setCurrentPackage(checkedData.packageMatch);
					}
				}
			}

			const validatedData = OptionUtils.validateSelectedConfigurations(groups, selected);
			if (validatedData) {
				groups = validatedData.groups;
				selected = validatedData.selected;
				setHasError(validatedData.selectionHasError);
			}

			setData(groups);
			setSelectedOptions(selected);
		}
	};

	const confirmPackageSelected = (selectedOption: IOption) => {
		closeConfirmationMessage();

		selectPackage(selectedOption);
	};

	const confirmPackageUnselected = (selectedOption: IOption) => {
		closeConfirmationMessage();

		unselectPackage(selectedOption);
	};

	const showConfigurations = (option: IOption) => {
		setConfigurationSetModal({
			show: true,
			optionId: option.optionId,
			optionName: option.name,
			data: option.configurationSets[0]
		});
	};

	const hideConfigurations = () => {
		setConfigurationSetModal({
			...configurationSetModal,
			show: false
		});
	};

	const setConfigurationVariant = (
		optionId: number, 
		variant: IOptionConfigurationVariant
	) => {
		const updatedData = OptionUtils.setVariantOptions(data, optionId, variant);
		
		setData(updatedData.groups);
		selectMultipleOptions(updatedData.variantOptions);

		// close modal
		hideConfigurations();
	};

	const handleOptionLinkClicked = (
		selectedOption: IOption,
		event: React.MouseEvent<HTMLSpanElement>
	) => {
		if (selectedOption.hasConfigurationSet) {
			event.preventDefault();
			showConfigurations(selectedOption);
		}
	};

	const handleCheckboxChange = (
		selectedOption: IOption,
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (selectedOption.hasConfigurationSet) {
			if (event.target.checked) {
				// option selected
				showConfigurations(selectedOption);
			} else {
				// option unselected
				selectOption(selectedOption, false);
			}
		} else if (selectedOption.isPackage) {
			const selectedRegularOptions = OptionUtils.getSelectedRegularOptionIds(
				selectedOptions
			);

			if (event.target.checked) {
				// Package is selected.

				const includedOptions =
					selectedOption.packageOptions &&
					selectedOption.packageOptions.filter(
						o => o.state === 1 || o.state === 3 || o.state === 5
					);

				const excludedOptions =
					selectedOption.packageOptions &&
					selectedOption.packageOptions.filter(
						o => o.state === 2 || o.state === 4
					);

				let hasSelectedExcludedOption = false;
				let selectedExcludedOption = "";

				if (excludedOptions && excludedOptions.length > 0) {
					const groups = [...data];
					
					groups.forEach(g => {
						const checkedOptions = g.options
							.filter(go => 
								(go.isChecked && !go.allowMultiples) ||
								(go.selectedQuantity > 0 && go.allowMultiples))
							.map(go => go.optionId);
						if (checkedOptions && checkedOptions.length > 0) {
							excludedOptions.forEach(eo => {
								if (checkedOptions.indexOf(eo.optionId) > -1) {
									hasSelectedExcludedOption = true;
									selectedExcludedOption = `${eo.groupName} - ${eo.name}`;
								}
							})
						}
					})
				}

				if (hasSelectedExcludedOption) {
					showWarningMessage("Selection Error", `The following option is not allowed with this package: ${selectedExcludedOption}`, false);
				}
				else {
					// Validate: Check if there are previously selected options that are not in the new selected package.
					const hasMissingSelectedOption = selectedRegularOptions &&
						selectedRegularOptions.some(
							o => includedOptions.map(po => po.optionId).indexOf(o) < 0
						);

					if (hasMissingSelectedOption) {
						setConfirmationMessage({
							...confirmationMessage,
							message: (
								<div>
									The package&nbsp;
									{selectedOption.groupName}
									&nbsp;doesn&apos;t include some of the already selected options. If
									you select the package, those options will be unchecked. Do you
									really want to select the package?
								</div>
							),
							show: true,
							onYesClick: () => {
								confirmPackageSelected(selectedOption);
							},
							onNoClick: closeConfirmationMessage
						});
					} else {
						selectPackage(selectedOption);
					}
				}
			} else {
				// Package is unselected.

				// Validate: Check if there are previously selected options that are not in the new selected package.
				setConfirmationMessage({
					...confirmationMessage,
					message: (
						<div>
							<p>{translations.deselectingThisPackageWillAlsoDeselectALLThoseOptionsIncludedInThatPackage_}</p>
							<p>
								{translations.youMayPreferToSimplyDeselectTheSpecificOptionsYouDoNotRequire_}
								<br />
								(
								{translations.nOTE}
								:
								{translations.packageOptionsAreHighlightedInGreen_}
								)
							</p>
							<p>Are you sure you want to do this?</p>
						</div>
					),
					show: true,
					onYesClick: () => {
						confirmPackageUnselected(selectedOption);
					},
					onNoClick: closeConfirmationMessage
				});
			}
		} else {
			// Option is selected.

			selectOption(selectedOption, event.target.checked);
		}
	};

	const handleOptionMouseOver = (
		isPackage: boolean,
		packageOptions: IOptionPackageItem[]
	) => {
		if (isPackage) {
			const groups = [...data];

			groups.forEach(g => {
				g.options.forEach(o => {
					o.isCurrentPackagePreviewOption =
						packageOptions &&
						packageOptions
							.filter(po => po.state === 1 || po.state === 3 || po.state === 5)
							.map(po => po.optionId)
							.indexOf(o.optionId) > -1;

					o.isCurrentPackageExcludedOption =
						packageOptions &&
						packageOptions
							.filter(po => po.state === 2 || po.state === 4)
							.map(po => po.optionId)
							.indexOf(o.optionId) > -1;
				});
			});

			setData(groups);
		}
	};

	const handleOptionMouseOut = (isPackage: boolean) => {
		if (isPackage) {
			const groups = [...data];

			groups.forEach(g => {
				g.options.forEach(o => {
					o.isCurrentPackagePreviewOption = false;
					o.isCurrentPackageExcludedOption = false;
				});
			});

			setData(groups);
		}
	};

	const handleQuantityChange = (
		selectedOption: IOption,
		event: React.FormEvent<HTMLInputElement>
	) => {
		let groups = [...data];
		let selected = [] as IOption[];
		let isInvalidOption = false;

		let isCurrentPackageCollectionModified = false;

		const quantity =
			event.currentTarget.value.trim() === ""
				? 0
				: parseInt(event.currentTarget.value.trim(), 10);

		const updatedData = OptionUtils.updateQuantityOption(
			groups,
			currentPackage,
			selected,
			isCurrentPackageCollectionModified,
			selectedOption,
			quantity,
			rules
		);

		if (updatedData) {
			if (updatedData.errorMessage) {
				isInvalidOption = true;
				showWarningMessage(updatedData.errorMessage.title, updatedData.errorMessage.body, true);
			}
			else {				
				groups = updatedData.groups;
				selected = updatedData.selected;
				isCurrentPackageCollectionModified =
					updatedData.isCurrentPackageCollectionModified;

				if (updatedData.warningMessage) {
					showWarningMessage(updatedData.warningMessage.title, updatedData.warningMessage.body, false);
				}
			}
		}

		if (!isInvalidOption) {
			if (isCurrentPackageCollectionModified) {
				const checkedData = OptionUtils.checkPackageMatch(groups, selected);
				if (checkedData) {
					groups = checkedData.groups;
					selected = checkedData.selected;

					if (checkedData.packageMatch) {
						setCurrentPackage(checkedData.packageMatch);
					}
				}
			}

			const validatedData = OptionUtils.validateSelectedConfigurations(groups, selected);
			if (validatedData) {
				groups = validatedData.groups;
				selected = validatedData.selected;
				setHasError(validatedData.selectionHasError);
			}

			setData(groups);
			setSelectedOptions(selected);
		}
	};

	const handleDropdownChange = (
		groupName: string,
		event: React.FormEvent<HTMLSelectElement>
	) => {
		const optionId = event.currentTarget.value;

		let groups = [...data];
		let selected = [] as IOption[];

		groups.forEach(g => {
			g.options.forEach(o => {
				if (g.groupName === groupName) {
					// options within the group
					if (o.optionId.toString() === optionId) {
						o.isChecked = true; // Check/uncheck selected option.
						g.selectedQuantity = g.selectedQuantity > 0 ? g.selectedQuantity : 1;
						o.selectedQuantity = g.selectedQuantity;
					} else {
						o.isChecked = false; // Everything else is unchecked.
						o.selectedQuantity = 0;
					}
				}

				selected = OptionUtils.updateOptionList(selected, o);
			});
		});

		const validatedData = OptionUtils.validateSelectedConfigurations(groups, selected);
		if (validatedData) {
			groups = validatedData.groups;
			selected = validatedData.selected;
			setHasError(validatedData.selectionHasError);
		}

		setData(groups);
		setSelectedOptions(selected);
	};

	const handleGroupQuantityChange = (
		groupName: string,
		event: React.FormEvent<HTMLInputElement>
	) => {
		const groups = [...data];
		let selected = [] as IOption[];

		const quantity =
			event.currentTarget.value.trim() === ""
				? 0
				: parseInt(event.currentTarget.value.trim(), 10);

		groups.forEach(g => {
			if (g.groupName === groupName) {
				g.selectedQuantity = quantity;
			}
			g.options.forEach(o => {
				if (g.groupName === groupName) {
					// options within the group
					if (o.isChecked) {
						o.selectedQuantity = quantity;
					} else {
						o.selectedQuantity = 0; // Everything else is 0.
					}
				}

				selected = OptionUtils.updateOptionList(selected, o);
			});
		});

		setData(groups);
		setSelectedOptions(selected);
	};

	const triggerConfirmEvent = () => {
		closeConfirmationMessage();

		const selectedOptionQuantities = OptionUtils.getSelectedOptionQuantities(selectedOptions);
		onOkClick(selectedOptionQuantities);
	};

	const handleSubmitOptionsClick = () => {
        
		if (hasError) {
			setConfirmationMessage({
				...confirmationMessage,
				message: (
					<div>
						<strong>{translations.optionConfigurtionErrorsDetected_}</strong>
						<p>{translations.youWillNotBeAbleToSubmitThisOrderUsingThisOptionConfiguration_}</p>
						<p>{translations.areYouSureYouWantToDoThis_}</p>
					</div>
				),
				show: true,
				onYesClick: triggerConfirmEvent,
				onNoClick: closeConfirmationMessage
			});
		} else {
			triggerConfirmEvent();
		}
        
        StorageFacade.EquipmentToPrint_OptionsHasError = hasError;
	};

	const handleCancelOptionsClick = () => {
		onCancelClick();
	};

	const body = (
		<div id="equipment-options">
			<div className="box">
				<div className="option-columns">
					{data &&
						data.map((optionGroup: IOptionGroup, index: number) => {
							const listKey = index;
							const boldClassName = " has-text-weight-bold is-6 group-name";
							const yellowHighlightClassName = " yellow-highlight";
							const greenHighlightClassName = " green-highlight";
							const orangeHighlightClassName = " orange-highlight";
							const errorClassName = " option-error";
							const warningClassName = " option-warning";
							const clickableClassName = " clickable";
							const selectedDropdownOption =
								optionGroup.isListOption &&
								optionGroup.options &&
								optionGroup.options.filter(o => o.isChecked).length > 0
									? optionGroup.options.filter(o => o.isChecked)[0].optionId
									: 0;
							return (
								<div key={listKey} className="option-group">
									<div className={`is-uppercase${boldClassName}`}>
										{optionGroup.isVisible && optionGroup.groupName}
									</div>
									{optionGroup.isListOption && (
										<div>
											<span className="option-control">
												{optionGroup.allowMultiples && (
													<NumericTextBox 
														name="group-selected-quantity"
														className="option-number"
														min="0"
														max="100"
														value={optionGroup.selectedQuantity}
														onChange={(event) => {
															handleGroupQuantityChange(optionGroup.groupName, event);
														}}
													/>
												)}
											</span>
											<span className="option-list">
												{optionGroup.options &&
													optionGroup.options.filter(o => o.hasError).length >
														0 && (
														<span className="icon has-text-danger">
															<FontAwesomeIcon icon={faExclamationTriangle} />
														</span>
													)}
												{optionGroup.options &&
													optionGroup.options.filter(o => o.hasError).length === 0 &&
													optionGroup.options.filter(o => o.hasWarning).length >
														0 && (
														<span className="icon has-text-warning">
															<FontAwesomeIcon icon={faExclamationTriangle} />
														</span>
													)}
												<select
													name="equipmentType"
													value={selectedDropdownOption}
													onChange={event =>
														handleDropdownChange(optionGroup.groupName, event)}
												>
													<option key="0" value="0" aria-label="empty" />
													{optionGroup.options &&
														optionGroup.options.map(
															(option: IOption) => (
																<option
																	key={option.optionId}
																	value={option.optionId}
																>
																	&nbsp;
																	{option.name}
																</option>
															)
														)}
												</select>
											</span>
										</div>
									)}
									{!optionGroup.isListOption &&
										optionGroup.options &&
										optionGroup.options.map((option: IOption) => (
											<div key={option.optionId}>
												<label
													onMouseOver={() =>
														handleOptionMouseOver(
															option.isPackage,
															option.packageOptions
														)}
													onFocus={() =>
														handleOptionMouseOver(
															option.isPackage,
															option.packageOptions
														)}
													onMouseOut={() =>
														handleOptionMouseOut(option.isPackage)}
													onBlur={() =>
														handleOptionMouseOut(option.isPackage)}
													className="checkbox"
												>
													<span className="option-control">
														{option.allowMultiples && (
															<NumericTextBox 
																name="selected-quantity"
																className="option-number"
																min="0"
																value={option.selectedQuantity}
																disabled={option.isRequired}
																onChange={(event) => {
																	handleQuantityChange(option, event);
																}}
															/>
														)}
														{!option.allowMultiples && (
															<Checkbox 
																name="option-item"
																label=""
																value={option.optionId.toString()}
																isChecked={option.isChecked}
																disabled={option.isRequired}
																onChange={(event) => {
																	handleCheckboxChange(option, event);
																}}
															/>
														)}
													</span>
													<span
														role="link"
														tabIndex={0}
														onClick={event => {
															handleOptionLinkClicked(option, event);
														}}
														className={`option-name${
															option.isBold ? boldClassName : ""
														}${
															option.isCurrentPackageOption
																? yellowHighlightClassName
																: ""
														}${
															option.isCurrentPackagePreviewOption
																? greenHighlightClassName
																: ""
														}${
															option.isCurrentPackageExcludedOption
																? orangeHighlightClassName
																: ""
														}${
															option.hasConfigurationSet
																? clickableClassName
																: ""
														}
														${option.hasError ? errorClassName : ""}
														${!option.hasError && option.hasWarning ? warningClassName : ""}`}
													>
														{option.hasError && (
															<span className="icon has-text-danger">
																<FontAwesomeIcon
																	icon={faExclamationTriangle}
																/>
															</span>
														)}
														{!option.hasError && option.hasWarning && (
															<span className="icon has-text-warning orange">
																<FontAwesomeIcon
																	icon={faExclamationTriangle}
																/>
															</span>
														)}
														{option.name}
													</span>
													{option.isPackage && (
														<span className="has-text-preview">
															&nbsp;
															<div className="preview">
																<FontAwesomeIcon icon={faInfoCircle} />
																<article className="package-preview message is-primary preview-text preview-right">
																	<div className="message-body content">
																		<div>
																			{option.groupName}
,
{option.name}
																		</div>
																		<ul>
																			{option.packageOptions &&
																				option.packageOptions
																					.filter(
																						o =>
																							o.state === 1 ||
																							o.state === 3 ||
																							o.state === 5
																					)
																					.map(o => (
																						<li key={o.optionId}>
																							{o.groupName}
,
{o.name}
																						</li>
																					))}
																		</ul>
																	</div>
																</article>
															</div>
														</span>
													)}
												</label>
											</div>
										))}
								</div>
							);
						})}
				</div>
			</div>
			<OptionConfigurations
				show={configurationSetModal.show}
				optionId={configurationSetModal.optionId}
				optionName={configurationSetModal.optionName}
				data={configurationSetModal.data}
				onClose={hideConfigurations}
				onSelectConfiguration={setConfigurationVariant}
			/>
			<ConfirmationMessage
				title={confirmationMessage.title}
				message={confirmationMessage.message}
				onYesClick={confirmationMessage.onYesClick}
				onNoClick={confirmationMessage.onNoClick}
				onCancelClick={confirmationMessage.onNoClick}
				show={confirmationMessage.show}
			/>
			<WarningMessage
				title={warningMessage.title}
				message={warningMessage.message}
				onClose={warningMessage.onClose}
				show={warningMessage.show}
				showErrorIcon={warningMessage.showErrorIcon}
			/>
		</div>
	);

	const footer = (
		<>
			<Button
				name="submit-options"
                label={translations.oK ?? "OK"}
                className="button is-link"
                onClick={handleSubmitOptionsClick}
			/>
            <Button
				name="cancel-options"
                label={translations.cancel ?? "Cancel"}
                className="button is-primary"
                onClick={handleCancelOptionsClick}
            />
		</>
	);

	return (
		<FullScreenModal
			show={show}
			title={title}
			body={body}
			footer={footer}
			onCancelClick={handleCancelOptionsClick}
		/>
	);
};

export default EquipmentOptions;
