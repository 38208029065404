/* eslint-disable no-underscore-dangle */
import * as React from "react";

import Button from "../helpers/Button";
import DropDownList from "../helpers/DropDownList";
import FormErrorNotification from "../helpers/FormErrorNotification";
import FormField from "../helpers/FormField";
import Measurement from "../helpers/Measurement";
import Modal from "../helpers/Modal";
import TextBox from "../helpers/TextBox";

import OpeningType from "../../enums/OpeningType";

import { dictionary } from "../../types/dictionary";

import MeasurementType from "../../enums/MeasurementType";
import * as MeasurementUnit from "../../enums/MeasurementUnit";

import { measure } from "../../types/measure";
import { getCustomValue } from "../../models/helpers/FormFields";

import UnitOfMeasure from "../../models/helpers/UnitOfMeasure";

import IMessage from "../../models/interfaces/IMessage";
import IOpening from "../../models/interfaces/IOpening";
import ISection from "../../models/interfaces/ISection";

import useApplicationConfigs from "../../hooks/useApplicationConfigs";
import useApplicationContext from "../../hooks/useApplicationContext";
import useOpeningDetailsReducer from "../../hooks/useOpeningDetailsReducer";
import useOpeningDetailsForm from "../../hooks/useOpeningDetailsForm";
import useTranslationContext from "../../hooks/useTranslationContext";

import "./OpeningDetailsModal.css";

interface OpeningDetailsModalProps {
    show: boolean;
    section: ISection;
    boxInteriorTemperature: measure;
    boxInteriorHumidity: measure;
    openingToEdit?: IOpening;
    onOkClick: any;
    onCancelClick: any;
}

const OpeningDetailsModal: React.FunctionComponent<OpeningDetailsModalProps> = (
    props: OpeningDetailsModalProps
) => {

    const [show, setShow] = React.useState(false);
    const [errorMessages, setErrorMessages] = React.useState([] as IMessage[]);
    
    const { curtains, getCurtain } = useApplicationContext();
    const { getOpeningTypes, getOpeningTypeConfig } = useApplicationConfigs();
    const { translations } = useTranslationContext();

    const { validateOpening } = useOpeningDetailsForm();

    const { formState, dispatch } = useOpeningDetailsReducer(
        props.section,
        props.boxInteriorTemperature,
        props.boxInteriorHumidity);

    React.useEffect(() => {
        setShow(props.show);
        if (props.show) {
            dispatch({ type: "set-initial-state", formValues: props.openingToEdit });
        }

        if (props.openingToEdit) {
            setErrorMessages(props.openingToEdit.errors ?? []);
        }
        else {
            setErrorMessages([]);
        }
    }, [props.show]);

    const isAddMode = !props.openingToEdit;

    const showErrors = (
        errors: IMessage[],
        currentFieldError?: IMessage
    ) => {
        let newList = [] as IMessage[];
        
        const currentList = [...errorMessages];
        currentList.forEach(c => {
            const errorText = errors.find(e => e.name === c.name)?.text ?? "";
            newList.push({
                name: c.name,
                text: errorText
            });
        });

        if (currentFieldError) {
            if (currentList.find(c => c.name === currentFieldError.name)) {
                newList = newList.map(n => {
                    return {
                        name: n.name,
                        text: (n.name === currentFieldError.name) ? currentFieldError.text : n.text
                    }
                });
            }
            else {
                newList.push(currentFieldError);
            }
        }
    
        setErrorMessages(newList);
    };

    const validateField = (
        fieldName: string,
        fieldValue: any
        ) => {

        const opening = {
            ...formState.actualValues,
            [fieldName]: fieldValue
        };

        const errors = validateOpening(opening, props.section.dimension1, props.section.dimension2);

        const fieldError: IMessage = {
            name: fieldName,
            text: errors.find(e => e.name === fieldName)?.text ?? ""
        };
        
        showErrors(errors, fieldError);
        
        return fieldError.text;
    };

    const handleCloseClick = () => {
        setShow(false);
        if (props.onCancelClick) {
            props.onCancelClick();
        }
    };

    const handleOkClick = () => {
        setShow(false);
        if (props.onOkClick) {
            const openingCustomValues: IOpening = {
                openingType: formState.actualValues.openingType,
                quantity: formState.actualValues.quantity,
                description: getCustomValue(formState, "description"),
                width: getCustomValue(formState, "width"),
                height: getCustomValue(formState, "height"),
                area: getCustomValue(formState, "area"),
                totalArea: getCustomValue(formState, "totalArea"),
                exteriorTemperature: getCustomValue(formState, "exteriorTemperature"),
                stripCurtainId: getCustomValue(formState, "stripCurtainId"),
                curtainEffectiveness: getCustomValue(formState, "curtainEffectiveness"),
                curtainRValue: getCustomValue(formState, "curtainRValue"),
                passagesPerDay: getCustomValue(formState, "passagesPerDay"),
                averagePassageTime: getCustomValue(formState, "averagePassageTime"),
                standOpenTimePerDay: getCustomValue(formState, "standOpenTimePerDay"),
                rValueEffective: getCustomValue(formState, "rValueEffective"),
                conductionLoad: getCustomValue(formState, "conductionLoad"),
                totalConductionLoad: formState.actualValues.totalConductionLoad,
                infiltratingAirRh: getCustomValue(formState, "infiltratingAirRh"),
                infiltrationLoad: getCustomValue(formState, "infiltrationLoad"),
                totalInfiltrationLoad: formState.actualValues.totalInfiltrationLoad
            };

            props.onOkClick(openingCustomValues, isAddMode);
        }
    };

    const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
        const fieldName = event.currentTarget.name;
        const fieldValue = event.currentTarget.value;

        dispatch({ type: "change-value", fieldName, fieldValue });

        validateField(fieldName, fieldValue);
    };

    const handleDropdownChange = (event: React.FormEvent<HTMLSelectElement>) => {
        const fieldName = event.currentTarget.name;
        const fieldValue = event.currentTarget.value;

        dispatch({ type: "recalculate-form", fieldName, fieldValue });

        validateField(fieldName, fieldValue);
    };

    const handleMeasurementChange = (fieldName: string, fieldValue: any) => {
        dispatch({ type: "recalculate-form", fieldName, fieldValue });
    };

    const openingTypesLookup: dictionary<string> = 
        getOpeningTypes()
            .map(item => {
                return {
                    key: item.id,
                    value: item.name
                }
            }
        );

    const selectedOpeningType = () => {
        const id = formState.actualValues.openingType ?? OpeningType.None;
        return getOpeningTypeConfig(id);
    };

    const curtainsLookup: dictionary<string> = 
        curtains
            .map(item => {
                return {
                    key: item.id.toString(),
                    value: item.name
                }
            }
        );

    const showCurtainEffectivenessField = () => {
        const id = formState.actualValues.stripCurtainId ?? "0";
        return getCurtain(id).effectiveness < 100;
    };

    const showCurtainRValueField = () => {
        const id = formState.actualValues.stripCurtainId ?? "0";
        return getCurtain(id).rValue > 0;
    };

    const modalBody = (
        <div id="opening-details-modal" className="content">
            <FormErrorNotification errorMessages={errorMessages} />
            <div className="block">
                <div className="columns">
                    <div className="column">
                        <FormField label={translations.openingType}>
                            <>
                                <DropDownList
                                    name="openingType"
                                    value={formState.actualValues.openingType}
                                    dataSource={openingTypesLookup}
                                    onChange={handleDropdownChange}
                                />
                                <span className="is-small">
                                    {selectedOpeningType().description}
                                </span>
                            </>
                        </FormField>
                        {selectedOpeningType().hasQuantityField && (
                            <FormField label={translations.quantity}>
                                <Measurement
                                    name="quantity"
                                    defaultValue={formState.defaultValues.quantity}
                                    actualValue={formState.actualValues.quantity}
                                    decimals={0}
                                    onChange={handleMeasurementChange}
                                    type={MeasurementType.PositiveDecimal}
                                    onValidate={validateField}
                                />
                            </FormField>
                        )}
                        {selectedOpeningType().hasDescriptionField && (
                            <FormField label={translations.description}>
                                <TextBox
                                    name="description"
                                    value={formState.actualValues.description}
                                    onChange={handleInputChange}
                                />
                            </FormField>
                        )}
                    </div>
                </div>
                {(selectedOpeningType().hasDimensionFields || selectedOpeningType().hasExteriorTemperatureField || selectedOpeningType().hasStripCurtainsField) && (
                    <div className="label is-small header">{translations.cONSTRUCTIONANDDIMENSIONS}</div>
                )}
                {selectedOpeningType().hasDimensionFields && (
                    <div className="columns">
                        <div className="column is-half">
                            <FormField label={`${translations.width} (${UnitOfMeasure.getSmallerDimensionUnitLabel()})`}>
                                <Measurement
                                    name="width"
                                    defaultValue={formState.defaultValues.width}
                                    actualValue={formState.actualValues.width}
                                    decimals={2}
                                    onChange={handleMeasurementChange}
                                    type={MeasurementType.SmallerDimension}
                                    onValidate={validateField}
                                />
                            </FormField>
                            <FormField label={`${translations.height} (${UnitOfMeasure.getSmallerDimensionUnitLabel()})`}>
                                <Measurement
                                    name="height"
                                    defaultValue={formState.defaultValues.height}
                                    actualValue={formState.actualValues.height}
                                    decimals={2}
                                    onChange={handleMeasurementChange}
                                    type={MeasurementType.SmallerDimension}
                                    onValidate={validateField}
                                />
                            </FormField>
                        </div>
                        <div className="column is-half">
                            <FormField label={`${translations.area} (${UnitOfMeasure.getAreaUnitLabel()})`}>
                                <Measurement
                                    name="area"
                                    defaultValue={formState.defaultValues.area}
                                    actualValue={formState.actualValues.area}
                                    decimals={0}
                                    onChange={handleMeasurementChange}
                                    type={MeasurementType.Area}
                                    onValidate={validateField}
                                />
                            </FormField>
                            {selectedOpeningType().hasExteriorTemperatureField && (
                                <FormField label={`${translations.exteriorTemperature} (${UnitOfMeasure.getTemperatureUnitLabel()})`}>
                                    <Measurement
                                        name="exteriorTemperature"
                                        defaultValue={formState.defaultValues.exteriorTemperature}
                                        actualValue={formState.actualValues.exteriorTemperature}
                                        decimals={1}
                                        onChange={handleMeasurementChange}
                                        type={MeasurementType.Temperature}
                                        onValidate={validateField}
                                    />
                                </FormField>
                            )}
                        </div>
                    </div>
                )}
                {selectedOpeningType().hasStripCurtainsField && (
                    <>
                        <FormField label={translations.stripCurtains}>
                            <DropDownList
                                name="stripCurtainId"
                                value={formState.actualValues.stripCurtainId}
                                dataSource={curtainsLookup}
                                onChange={handleDropdownChange}
                            />
                        </FormField>
                        {(showCurtainEffectivenessField() || showCurtainRValueField()) && (
                            <div className="columns">
                                <div className="column is-half">
                                    {showCurtainEffectivenessField() && (
                                        <FormField label={`${translations.curtainEffectiveness} (${UnitOfMeasure.getPercentUnitLabel()})`}>
                                            <Measurement
                                                name="curtainEffectiveness"
                                                defaultValue={formState.defaultValues.curtainEffectiveness}
                                                actualValue={formState.actualValues.curtainEffectiveness}
                                                decimals={3}
                                                onChange={handleMeasurementChange}
                                                disabled
                                                type={MeasurementType.PositiveDecimal}
                                                onValidate={validateField}
                                            />
                                        </FormField>
                                    )}
                                </div>
                                <div className="column is-half">
                                    {showCurtainRValueField() && (
                                        <FormField label={translations.curtainR_Value}>
                                            <Measurement
                                                name="curtainRValue"
                                                defaultValue={formState.defaultValues.curtainRValue}
                                                actualValue={formState.actualValues.curtainRValue}
                                                decimals={3}
                                                onChange={handleMeasurementChange}
                                                type={MeasurementType.PositiveDecimal}
                                                onValidate={validateField}
                                            />
                                        </FormField>
                                    )}
                                </div>
                            </div>
                        )}
                    </>
                )}
                {selectedOpeningType().hasDoorUsageFields && (
                    <>
                        <div className="label is-small header">{translations.dOORUSAGE}</div>
                        <div className="columns">
                            <div className="column">
                                <FormField label={translations.passagesPerDay}>
                                    <Measurement
                                        name="passagesPerDay"
                                        defaultValue={formState.defaultValues.passagesPerDay}
                                        actualValue={formState.actualValues.passagesPerDay}
                                        decimals={0}
                                        onChange={handleMeasurementChange}
                                        type={MeasurementType.PositiveDecimal}
                                        onValidate={validateField}
                                    />
                                </FormField>
                            </div>
                            <div className="column">
                                <FormField label={translations.averagePassageTime_Seconds_}>
                                    <Measurement
                                        name="averagePassageTime"
                                        defaultValue={formState.defaultValues.averagePassageTime}
                                        actualValue={formState.actualValues.averagePassageTime}
                                        decimals={2}
                                        onChange={handleMeasurementChange}
                                        type={MeasurementType.PositiveDecimal}
                                        onValidate={validateField}
                                    />
                                </FormField>
                                <FormField label={translations.standOpenTimePerDay_Minutes_}>
                                    <Measurement
                                        name="standOpenTimePerDay"
                                        defaultValue={formState.defaultValues.standOpenTimePerDay}
                                        actualValue={formState.actualValues.standOpenTimePerDay}
                                        decimals={2}
                                        onChange={handleMeasurementChange}
                                        type={MeasurementType.PositiveDecimal}
                                        onValidate={validateField}
                                    />
                                </FormField>
                            </div>
                        </div>
                    </>
                )}
                {(selectedOpeningType().hasRValueEffectiveField || selectedOpeningType().hasConductionLoadField) && (
                    <div className="label is-small header">{translations.iNSULATIONLOADPERUNIT}</div>
                )}
                <div className="columns">
                    <div className="column">
                        {selectedOpeningType().hasRValueEffectiveField && (
                            <FormField label={translations.effectiveR_Value}>
                                <Measurement
                                    name="rValueEffective"
                                    defaultValue={formState.defaultValues.rValueEffective}
                                    actualValue={formState.actualValues.rValueEffective}
                                    decimals={3}
                                    onChange={handleMeasurementChange}
                                    type={MeasurementType.PositiveDecimal}
                                    onValidate={validateField}
                                />
                            </FormField>
                        )}
                    </div>
                    <div className="column">
                        {selectedOpeningType().hasConductionLoadField && (
                            <FormField label={`${translations.conductionLoad} (${UnitOfMeasure.getRatingUnitLabel()})`}>
                                <Measurement
                                    name="conductionLoad"
                                    defaultValue={formState.defaultValues.conductionLoad}
                                    actualValue={formState.actualValues.conductionLoad}
                                    decimals={0}
                                    onChange={handleMeasurementChange}
                                    type={MeasurementType.Rating}
                                    onValidate={validateField}
                                />
                            </FormField>
                        )}
                    </div>
                </div>
                {(selectedOpeningType().hasInfiltratingAirRhField || selectedOpeningType().hasInfiltrationLoadField) && (
                    <div className="label is-small header">{translations.iNFILTRATIONLOADPERUNIT}</div>
                )}
                <div className="columns">
                    <div className="column">
                        {selectedOpeningType().hasInfiltratingAirRhField && (
                            <FormField label={`${translations.infiltratingAirRH} (${UnitOfMeasure.getPercentUnitLabel()})`}>
                                <Measurement
                                    name="infiltratingAirRh"
                                    defaultValue={formState.defaultValues.infiltratingAirRh}
                                    actualValue={formState.actualValues.infiltratingAirRh}
                                    decimals={2}
                                    onChange={handleMeasurementChange}
                                    type={MeasurementType.PositiveDecimal}
                                    onValidate={validateField}
                                />
                            </FormField>
                        )}
                    </div>
                    <div className="column">
                        {selectedOpeningType().hasInfiltrationLoadField && (
                            <FormField label={`${translations.infiltrationLoad} (${UnitOfMeasure.getRatingUnitLabel()})`}>
                                <Measurement
                                    name="infiltrationLoad"
                                    defaultValue={formState.defaultValues.infiltrationLoad}
                                    actualValue={formState.actualValues.infiltrationLoad}
                                    decimals={0}
                                    onChange={handleMeasurementChange}
                                    type={MeasurementType.Rating}
                                    onValidate={validateField}
                                />
                            </FormField>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );

    const modalFooter = (
        <>
            <Button
                name="submit-opening"
                label={translations.oK}
                className="button is-link"
                onClick={handleOkClick}
            />
            <Button
                name="cancel-opening"
                label={translations.cancel}
                className="button is-primary"
                onClick={handleCloseClick}
            />
        </>
    );

    return (
        <Modal
            show={show}
            title={translations.door_OpeningDetails}
            width={800}
            body={modalBody}
            footer={modalFooter}
            onCancelClick={handleCloseClick}
        />
    );
};

export default OpeningDetailsModal;
