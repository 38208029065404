import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import Modal from "./Modal";
import Button from "./Button";

import useTranslationContext from "../../hooks/useTranslationContext";

export interface WarningMessageProps {
	title: string;
	message: JSX.Element;
	show: boolean;
	showErrorIcon?: boolean;
	onClose: any;
}

const WarningMessage: React.FunctionComponent<WarningMessageProps> = (
	props: WarningMessageProps
) => {

	const { translations } = useTranslationContext();

	const modalBody = (
		<div className="content warning-message">
			<div className="media">
				{props.showErrorIcon && (
					<div className="media-left has-text-danger">
						<FontAwesomeIcon icon={faTimesCircle} size="2x" />
					</div>
				)}
				{!props.showErrorIcon && (
					<div className="media-left has-text-warning">
						<FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
					</div>
				)}
				<div className="media-content">{props.message}</div>
			</div>
		</div>
	);

	const modalFooter = (
		<Button
			name="warn-ok"
			label={translations.oK ?? "OK"}
			className="button is-link"
			onClick={props.onClose}
		/>
	);

	return (
		<Modal 
            show={props.show} 
            title={props.title}
            body={modalBody}
            footer={modalFooter}
			onCancelClick={props.onClose}
		/>
	);
};

export default WarningMessage;
