/* eslint-disable no-underscore-dangle */
import * as React from "react";

import Button from "../helpers/Button";
import ConstructionModal from "../Construction/ConstructionModal";
import DropDownList from "../helpers/DropDownList";
import FloorTypesButton from "../FloorTypes/FloorTypesButton";
import FormErrorNotification from "../helpers/FormErrorNotification";
import FormField from "../helpers/FormField";
import Measurement from "../helpers/Measurement";
import Modal from "../helpers/Modal";
import TextBox from "../helpers/TextBox";

import { measure, hasValue, getNumericValue } from "../../types/measure";
import { getCustomValue } from "../../models/helpers/FormFields";
import { getDefaultMaterialId } from "../../models/helpers/BoxSectionFormFields";
import UnitOfMeasure from "../../models/helpers/UnitOfMeasure";

import BoxLocation from "../../enums/BoxLocation";
import BoxType from "../../enums/BoxType";
import FloorType from "../../enums/FloorType";
import Material from "../../enums/Material";
import MeasurementType from "../../enums/MeasurementType";
import * as MeasurementUnit from "../../enums/MeasurementUnit";
import SectionLocation from "../../enums/SectionLocation";
import SectionType from "../../enums/SectionType";

import IMaterial from "../../models/interfaces/IMaterial";
import IMessage from "../../models/interfaces/IMessage";
import ISection from "../../models/interfaces/ISection";

import useApplicationConfigs from "../../hooks/useApplicationConfigs";
import useBoxSectionForm from "../../hooks/useBoxSectionForm";
import useBoxSectionReducer from "../../hooks/useBoxSectionReducer";
import useBoxMaterialsContext from "../../hooks/useBoxMaterialsContext";
import useTranslationContext from "../../hooks/useTranslationContext";

import "./BoxSectionModal.css";

const isWall = (sectionType: SectionType) => {
    return sectionType === SectionType.WallPolygon
        || sectionType === SectionType.WallCustom;
};

const isFloor = (sectionType: SectionType) => {
    return sectionType === SectionType.FloorPolygon
        || sectionType === SectionType.FloorCustom;
};

const isCeiling = (sectionType: SectionType) => {
    return sectionType === SectionType.CeilingPolygon
        || sectionType === SectionType.CeilingCustom;
};

interface BoxSectionModalProps {
	show: boolean;
	onOkClick: any;
    onCancelClick: any;
    boxType: BoxType;
    height: measure;
    depth: measure;
    depth2: measure;
    depth3: measure;
    length: measure;
    length2: measure;
    length3: measure;
    isBoxFreezer: boolean;
    floorType: FloorType;
    boxLocation: BoxLocation;
    boxExteriorTemperature: measure;
    boxInteriorTemperature: measure;
    boxExteriorHumidity: measure;
    boxInteriorHumidity: measure;
    sectionToEdit?: ISection;
}

const BoxSectionModal: React.FunctionComponent<BoxSectionModalProps> = (
	props: BoxSectionModalProps
) => {
    
    const [show, setShow] = React.useState(false);
    const [errorMessages, setErrorMessages] = React.useState([] as IMessage[]);
    const [showConstruction, setShowConstruction] = React.useState(false);
    const [constructionToEdit, setConstructionToEdit] = React.useState(null as IMaterial | null);

    const { getSectionTypeConfig, getSectionFloorTypeConfig } = useApplicationConfigs();
    
    const { isAddMode, isPolygonSection, formTitle, visibleSectionTypes, getMaterialsLookup, validateSection } = useBoxSectionForm();
    const { getBoxMaterial, boxMaterials, updateBoxMaterials, isMaterialLocked, isMaterialConstruct, isMaterialThicknessStandard } = useBoxMaterialsContext();
    const { translations } = useTranslationContext();
    
    const { formState, dispatch } = useBoxSectionReducer(
        props.boxLocation,
        props.floorType,
        props.height,
        props.depth,
        props.depth2,
        props.depth3,
        props.length,
        props.length2,
        props.length3,
        props.boxType,
        props.boxExteriorTemperature,
        props.boxExteriorHumidity,
        props.boxInteriorTemperature,
        props.boxInteriorHumidity);

    const showErrors = (
        errors: IMessage[],
        currentFieldError?: IMessage
    ) => {
        let newList = [] as IMessage[];
        
        const currentList = [...errorMessages];
        currentList.forEach(c => {
            const errorText = errors.find(e => e.name === c.name)?.text ?? "";
            newList.push({
                name: c.name,
                text: errorText
            });
        });

        if (currentFieldError) {
            if (currentList.find(c => c.name === currentFieldError.name)) {
                newList = newList.map(n => {
                    return {
                        name: n.name,
                        text: (n.name === currentFieldError.name) ? currentFieldError.text : n.text
                    }
                });
            }
            else {
                newList.push(currentFieldError);
            }
        }
    
        setErrorMessages(newList);
    };

    const validateField = (
        fieldName: string,
        fieldValue: any
        ) => {

        const section = {
            ...formState.actualValues,
            [fieldName]: fieldValue
        };

        const errors = validateSection(section, section.floorType, props.boxInteriorTemperature);

        const fieldError: IMessage = {
            name: fieldName,
            text: errors.find(e => e.name === fieldName)?.text ?? ""
        };
        
        showErrors(errors, fieldError);
        
        return fieldError.text;
    };

    React.useEffect(() => {       
        setShow(props.show);
        if (props.show) {
            dispatch({ type: "set-initial-state", formValues: props.sectionToEdit });

            if (props.sectionToEdit) {
                setErrorMessages(props.sectionToEdit.errors ?? []);
            }
            else {
                setErrorMessages([]);
            }
        }
    }, [props.show]);

    const getActualMaterialId = () => {
        const actualMaterialId = formState.actualValues.materialId && formState.actualValues.materialId !== 0
            ? formState.actualValues.materialId
            : getDefaultMaterialId(getSectionTypeConfig(formState.actualValues.sectionType), getSectionFloorTypeConfig(formState.actualValues.floorType, props.floorType));
        return actualMaterialId;
    }

    const openAddConstructionPopup = () => {
        setConstructionToEdit(null);
        setShowConstruction(true);
    }

    const openEditConstructionPopup = () => {        
        const selectedConstruction = getBoxMaterial(getActualMaterialId());
        if (selectedConstruction) {
            setConstructionToEdit(selectedConstruction);
            setShowConstruction(true);
        }
    }
    
    const handleCloseClick = () => {
        setShow(false);
        if (props.onCancelClick) {
            props.onCancelClick();
        }
    };

    const handleOkClick = () => {
        setShow(false);
        if (props.onOkClick) {
            const sectionCustomValues: ISection = {
                sectionType: formState.actualValues.sectionType,
                wallType: formState.actualValues.wallType,
                radians: formState.actualValues.radians,
                isCustom: formState.actualValues.isCustom,
                isModified: formState.actualValues.isModified,
                sectionName: formState.actualValues.sectionName,
                materialId: getCustomValue(formState, "materialId"),
                materialName: getCustomValue(formState, "materialName"),
                thickness: getCustomValue(formState, "thickness"),
                kFactor: getCustomValue(formState, "kFactor"),
                rValuePerInch: getCustomValue(formState, "rValuePerInch"),
                rValueEffective: getCustomValue(formState, "rValueEffective"),
                exteriorTemperature: getCustomValue(formState, "exteriorTemperature"),
                exteriorHumidity: getCustomValue(formState, "exteriorHumidity"),
                dimension1: getCustomValue(formState, "dimension1"),
                dimension2: getCustomValue(formState, "dimension2"),
                area: getCustomValue(formState, "area"),
                areaWithoutOpenings: formState.actualValues.areaWithoutOpenings,
                conductionLoad: getCustomValue(formState, "conductionLoad"),
                conductionLoadWithoutOpenings: formState.actualValues.conductionLoadWithoutOpenings,
                sectionLocation: getCustomValue(formState, "sectionLocation"),
                floorType: getCustomValue(formState, "floorType"),
                width: getCustomValue(formState, "width"),
                length: getCustomValue(formState, "length"),
                height: getCustomValue(formState, "height"),
                depth: getCustomValue(formState, "depth"),
                perimeter: getCustomValue(formState, "perimeter"),
                openings: formState.actualValues.openings
            };

            props.onOkClick(sectionCustomValues, isAddMode(props.sectionToEdit));
        }
    };

    const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
		const fieldName = event.currentTarget.name;
        const fieldValue = event.currentTarget.value;
        
        dispatch({ type: "change-value", fieldName, fieldValue });

        validateField(fieldName, fieldValue);
    };
    
    const handleDropdownChange = (event: React.FormEvent<HTMLSelectElement>) => {
		const fieldName = event.currentTarget.name;
        const fieldValue = event.currentTarget.value;

        switch (fieldName) {
            case "sectionType":
            case "sectionLocation":
                dispatch({ type: "recalculate-form", fieldName, fieldValue });
                break;
            case "materialId":
                if (fieldValue === Material.NewComposite.toString()) {
                    openAddConstructionPopup();
                }
                else {
                    dispatch({ type: "recalculate-form", fieldName, fieldValue: Number(fieldValue) });
                }
                break;
            default:
                dispatch({ type: "change-value", fieldName, fieldValue });
                break;
        }

        validateField(fieldName, fieldValue);
    }
    
	const handleMeasurementChange = (fieldName: string, fieldValue: any) => {
        dispatch({ type: "recalculate-form", fieldName, fieldValue });
    };

    const handleFloorTypeChange = (value: FloorType) => {
		if (value === FloorType.None) {
			value = formState.defaultValues.floorType ?? FloorType.OnGrade;
        }
        dispatch({ type: "recalculate-form", fieldName: "floorType", fieldValue: value });

        validateField("floorType", value);
    };

    const handleEditConstructionButtonClick= () => {
        openEditConstructionPopup();
    }

    const handleConstructionOkClick = (construction: IMaterial, isNewConstruction: boolean) => {
        setShowConstruction(false);

        let materials = [...boxMaterials];
        let constructionId = construction.id;

        if (isNewConstruction) {
            const hasConstruction = boxMaterials.some(m => m.id < 0);
            if (!hasConstruction) {
                constructionId = -1;
            }
            else {
                constructionId = (boxMaterials.map(m => m.id).sort((a, b) => a - b)[0]) - 1;
            }

            const newConstruction = {
                ...construction,
                id: constructionId
            };

            materials = [
                ...materials,
                newConstruction
            ];
        }
        else {
            const index = materials.findIndex(m => m.id === constructionId);
            materials = [
                ...materials.slice(0, index), 
                construction, 
                ...materials.slice(index + 1)
            ];
        }

        updateBoxMaterials(materials);

        dispatch({ type: "recalculate-form", fieldName: "materialId", fieldValue: constructionId });

        validateField("materialId", constructionId);
    };

    const modalBody = (
        <div id="box-section-modal" className="content">
            <FormErrorNotification errorMessages={errorMessages} />
            <div>
                <FormField label={translations.sectionType}>
                    <DropDownList
                        name="sectionType"
                        value={formState.actualValues.sectionType}
                        dataSource={visibleSectionTypes(props.sectionToEdit).map(s => {
                            return {
                                key: s.id,
                                value: s.name
                            }
                        })}
                        disabled={isPolygonSection(props.sectionToEdit)}
                        onChange={handleDropdownChange}
                    />
                </FormField>
                <FormField label="Name">
                    <TextBox 
                        name="sectionName"
                        value={formState.actualValues.sectionName ?? ""}
                        disabled={isPolygonSection(props.sectionToEdit)}
                        onChange={handleInputChange}
                    />
                </FormField>
                {isFloor(formState.actualValues.sectionType) && (
                    <FormField label={translations.floorType}>
                        <FloorTypesButton
                            isFreezer={props.isBoxFreezer}
                            selected={formState.actualValues.floorType ?? FloorType.OnGrade}
                            onChange={handleFloorTypeChange}
                            boxSetting={props.floorType}
                            showBoxSetting
                        />
                    </FormField>
                )}
                {(isWall(formState.actualValues.sectionType) || isCeiling(formState.actualValues.sectionType)) && (
                    <FormField label={translations.sectionExteriorIsOutdoors}>
                        <DropDownList
                            name="sectionLocation"
                            value={formState.actualValues.sectionLocation ?? SectionLocation.BoxLocation}
                            dataSource={[
                                { key: "1", value: translations.useBoxSetting },
                                { key: "2", value: translations.indoors },
                                { key: "3", value: translations.outdoors }
                            ]}
                            onChange={handleDropdownChange}
                        />
                    </FormField>
                )}
                {!isPolygonSection(props.sectionToEdit) && (
                    <>
                    {isWall(formState.actualValues.sectionType) && (    
                        <>
                            <FormField label={`${translations.width} (${UnitOfMeasure.getDimensionUnitLabel()})`}>
                                <Measurement
                                    name="width"
                                    defaultValue={formState.defaultValues.width}
                                    actualValue={formState.actualValues.width}
                                    decimals={2}
                                    onChange={handleMeasurementChange}
                                    type={MeasurementType.Dimension}
                                    onValidate={validateField}
                                />
                            </FormField>
                            <FormField label={`${translations.height} (${UnitOfMeasure.getDimensionUnitLabel()})`}>
                                <Measurement
                                    name="height"
                                    defaultValue={formState.defaultValues.height}
                                    actualValue={formState.actualValues.height}
                                    decimals={2}
                                    onChange={handleMeasurementChange}
                                    type={MeasurementType.Dimension}
                                    onValidate={validateField}
                                />
                            </FormField>
                        </>
                    )}
                    {(isFloor(formState.actualValues.sectionType) || isCeiling(formState.actualValues.sectionType)) && (
                        <>
                            <FormField label={`${translations.length} (${UnitOfMeasure.getDimensionUnitLabel()})`}>
                                <Measurement
                                    name="length"
                                    defaultValue={formState.defaultValues.length}
                                    actualValue={formState.actualValues.length}
                                    decimals={2}
                                    onChange={handleMeasurementChange}
                                    type={MeasurementType.Dimension}
                                    onValidate={validateField}
                                />
                            </FormField>
                            <FormField label={`${translations.depth} (${UnitOfMeasure.getDimensionUnitLabel()})`}>
                                <Measurement
                                    name="depth"
                                    defaultValue={formState.defaultValues.depth}
                                    actualValue={formState.actualValues.depth}
                                    decimals={2}
                                    onChange={handleMeasurementChange}
                                    type={MeasurementType.Dimension}
                                    onValidate={validateField}
                                />
                            </FormField>
                        </>
                    )}
                    <FormField label={`${translations.area} (${UnitOfMeasure.getAreaUnitLabel()})`}>
                        <Measurement
                            name="area"
                            defaultValue={formState.defaultValues.area}
                            actualValue={formState.actualValues.area}
                            decimals={0}
                            onChange={handleMeasurementChange}
                            type={MeasurementType.Area}
                            onValidate={validateField}
                        />
                    </FormField>
                    {isFloor(formState.actualValues.sectionType) && (
                        <FormField label={`${translations.perimeterLength} (${UnitOfMeasure.getDimensionUnitLabel()})`}>
                            <Measurement
                                name="perimeter"
                                defaultValue={formState.defaultValues.perimeter}
                                actualValue={formState.actualValues.perimeter}
                                decimals={2}
                                onChange={handleMeasurementChange}
                                type={MeasurementType.Dimension}
                                onValidate={validateField}
                            />
                        </FormField>
                    )}
                    </>
                )}
                <FormField label={translations.insulation_Construction}>
                    <>
                        <DropDownList
                            name="materialId"
                            value={(formState.actualValues.materialId ?? 0).toString()}
                            dataSource={getMaterialsLookup()}
                            onChange={handleDropdownChange}
                        />
                        {formState.actualValues.materialId !== 0 && isMaterialConstruct(getActualMaterialId()) && (
                            <div className="padtop-small">
                                <Button
                                    name="edit-construction"
                                    label={translations.editConstruction}
                                    className="button is-light is-small is-fullwidth"
                                    onClick={handleEditConstructionButtonClick}
                                />
                            </div>
                        )}
                    </>
                </FormField>
                {(formState.actualValues.materialId === 0 || formState.actualValues.materialId === Material.Other) && (
                    <FormField label={translations.insulationName}>
                        <>
                            <TextBox 
                                name="materialName"
                                value={formState.actualValues.materialName ?? ""}
                                disabled={formState.actualValues.materialId === 0}
                                onChange={handleInputChange}
                            />
                            {isMaterialConstruct(getActualMaterialId()) && (
                                <div className="padtop-small">
                                    <Button
                                        name="edit-construction"
                                        label={translations.editConstruction}
                                        className="button is-light is-small is-fullwidth"
                                        onClick={handleEditConstructionButtonClick}
                                    />
                                </div>
                            )}
                        </>
                    </FormField>
                )}
                {(formState.actualValues.materialId !== Material.ZoneBoundary) && (
                    <>
                        <FormField label={`${translations.thickness} (${UnitOfMeasure.getSmallerDimensionUnitLabel()})`}>
                            <Measurement
                                name="thickness"
                                defaultValue={formState.defaultValues.thickness}
                                actualValue={formState.actualValues.thickness}
                                decimals={3}
                                onChange={handleMeasurementChange}
                                type={MeasurementType.SmallerDimension}
                                disabled={isMaterialLocked(getActualMaterialId()) || isMaterialThicknessStandard(getActualMaterialId())}
                                onValidate={validateField}
                            />
                        </FormField>
                        <FormField label={translations.k_Factor}>
                            <Measurement
                                name="kFactor"
                                defaultValue={formState.defaultValues.kFactor}
                                actualValue={formState.actualValues.kFactor}
                                decimals={3}
                                onChange={handleMeasurementChange}
                                type={MeasurementType.PositiveDecimal}
                                disabled={isMaterialLocked(getActualMaterialId())}
                                onValidate={validateField}
                            />
                        </FormField>
                        <FormField label={translations.orR_Value_UnitThickness}>
                            <Measurement
                                name="rValuePerInch"
                                defaultValue={formState.defaultValues.rValuePerInch}
                                actualValue={formState.actualValues.rValuePerInch}
                                decimals={3}
                                onChange={handleMeasurementChange}
                                type={MeasurementType.PositiveDecimal}
                                disabled={isMaterialLocked(getActualMaterialId())}
                                onValidate={validateField}
                            />
                        </FormField>
                        <FormField label={translations.effectiveR_Value}>
                            <Measurement
                                name="rValueEffective"
                                defaultValue={formState.defaultValues.rValueEffective}
                                actualValue={formState.actualValues.rValueEffective}
                                decimals={3}
                                onChange={handleMeasurementChange}
                                type={MeasurementType.PositiveDecimal}
                                disabled={isMaterialLocked(getActualMaterialId())}
                                onValidate={validateField}
                            />
                        </FormField>
                        {(isWall(formState.actualValues.sectionType) 
                            || isCeiling(formState.actualValues.sectionType)
                            || (isFloor(formState.actualValues.sectionType) 
                                && getSectionFloorTypeConfig(formState.actualValues.floorType, props.floorType).id === FloorType.AboveGrade)
                        ) && (
                            <FormField label={`${translations.exteriorTemperature} (${UnitOfMeasure.getTemperatureUnitLabel()})`}>
                                <Measurement
                                    name="exteriorTemperature"
                                    defaultValue={formState.defaultValues.exteriorTemperature}
                                    actualValue={formState.actualValues.exteriorTemperature}
                                    decimals={1}
                                    onChange={handleMeasurementChange}
                                    type={MeasurementType.Temperature}
                                    onValidate={validateField}
                                />
                            </FormField>
                        )}
                    </>
                )}
                {isWall(formState.actualValues.sectionType) && (
                    <FormField label={`${translations.exteriorRH} (${UnitOfMeasure.getPercentUnitLabel()})`}>
                        <Measurement
                            name="exteriorHumidity"
                            defaultValue={formState.defaultValues.exteriorHumidity}
                            actualValue={formState.actualValues.exteriorHumidity}
                            decimals={1}
                            onChange={handleMeasurementChange}
                            type={MeasurementType.PositiveDecimal}
                            onValidate={validateField}
                        />
                    </FormField>
                )}
                {(formState.actualValues.materialId !== Material.ZoneBoundary) && (
                    <FormField label={`${translations.sectionLoad} (${UnitOfMeasure.getRatingUnitLabel()})`}>
                        <Measurement
                            name="conductionLoad"
                            defaultValue={formState.defaultValues.conductionLoad}
                            actualValue={formState.actualValues.conductionLoad}
                            decimals={0}
                            onChange={handleMeasurementChange}
                            type={MeasurementType.Rating}
                            onValidate={validateField}
                        />
                    </FormField>
                )}
            </div>
        </div>
    );

    const modalFooter = (
        <>
            <Button
                name="submit-section"
                label={translations.oK}
                className="button is-link"
                onClick={handleOkClick}
            />
            <Button
                name="cancel-section"
                label={translations.cancel}
                className="button is-primary"
                onClick={handleCloseClick}
            />
        </>
    );

    return (
        <>
            <Modal 
                show={show} 
                title={formTitle(props.sectionToEdit)}
                body={modalBody}
                footer={modalFooter}
                onCancelClick={handleCloseClick}
            />
            <ConstructionModal
                onOkClick={handleConstructionOkClick}
				onCancelClick={() => { setShowConstruction(false); }}
                show={showConstruction}
                constructionToEdit={constructionToEdit}
            />
        </>
    );
};

export default BoxSectionModal;
