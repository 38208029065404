import * as React from "react";

import MeasurementType from "../../enums/MeasurementType";

import UnitOfMeasure from "../../models/helpers/UnitOfMeasure";
import StorageFacade from "../../services/StorageFacade";

import { measure, getNumericValue, roundOff, hasValue, countDecimals } from "../../types/measure";

import "./Measurement.css";

const getStringValue = (value: measure, decimals: number | undefined) => {
	let stringValue = "";
	if (hasValue(value) && !Number.isNaN(getNumericValue(value))) {
		if (hasValue(decimals)) {
			stringValue = getNumericValue(roundOff(value, getNumericValue(decimals))).toString();
		}
		else {
			stringValue = getNumericValue(value).toString();
		}
	}

	return stringValue;
};

interface MeasurementProps {
	name: string;
	defaultValue: measure;
	actualValue: measure;
	onChange: (name: string, value: string) => void;
	decimals: number;
	type: MeasurementType;
	tooltip?: string;
	disabled?: boolean;
	onValidate?: (name: string, value: measure) => string;
}

const Measurement: React.FunctionComponent<MeasurementProps> = (
	props: MeasurementProps
) => {

	// The measure props passed to this component are always imperial.
	// When user changes a value in this Measurement component, it is passed back to the parent component (and then saved to browser storage) as imperial.
	// Also, all other processes outside this Measurement component use values as imperial. E.g. validations, export, import, calculations and other business logic.
	// However, when user uses metric system, the value is converted to metric right before it is displayed in the page.

	const [displayValue, setDisplayValue] = React.useState("");
	const [previousDisplayValue, setPreviousDisplayValue] = React.useState("");
	const [isCustom, setIsCustom] = React.useState(false);
	const [isTouched, setIsTouched] = React.useState(false);
	const [isValid, setIsValid] = React.useState(true);

	const usesMetricSystem = StorageFacade.UsesMetricSystem;

	React.useEffect(() => {
		// props.actualValue is always in imperial.
		// If user uses metric, convert props.actualValue to metric before displaying.
		let actualDisplayValue = props.actualValue;
		if (usesMetricSystem && props.actualValue) {
			actualDisplayValue = UnitOfMeasure.convertImperialToMetric(props.actualValue, props.type);
		}
		const decimalPlaces = usesMetricSystem && props.type === MeasurementType.Rating ? props.decimals + UnitOfMeasure.KilowattsDecimalPlaces : props.decimals;
		const stringValue = getStringValue(actualDisplayValue, decimalPlaces)
		setDisplayValue(stringValue);
	
		if (props.onValidate && isTouched) {
			const message = props.onValidate(props.name, props.actualValue); // Always validate values as imperial.
			setIsValid(message === "");
		}
		
		const defaultValueDecimals = countDecimals(props.defaultValue);
		const isCustomValue = hasValue(props.defaultValue) && hasValue(props.actualValue)
			? +getStringValue(props.defaultValue, defaultValueDecimals) !== +getStringValue(props.actualValue, defaultValueDecimals)
			: (props.defaultValue ?? null) !== (props.actualValue ?? null);
		setIsCustom(isCustomValue);
	}, [
		props.actualValue,
		props.defaultValue,
		props.decimals
	]);

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (!UnitOfMeasure.allowedCharacter(event.key, props.type)) {
			event.preventDefault();
		}
	};

	const handleFocus = (event: React.FormEvent<HTMLInputElement>) => {
		const fieldValue = event.currentTarget.value;
		setPreviousDisplayValue(fieldValue);
	};

	const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
		const fieldValue = event.currentTarget.value;
		setDisplayValue(fieldValue);
	};

	const handleBlur = () => {
		let newDisplayValue = "";
		const decimalPlaces = usesMetricSystem && props.type === MeasurementType.Rating ? props.decimals + UnitOfMeasure.KilowattsDecimalPlaces : props.decimals;

		if (displayValue === "") {
			// If entered value is empty, show default value.
			// props.defaultValue is always in imperial.
			// If user uses metric, convert props.defaultValue to metric before displaying.
			let defaultDisplayValue = props.defaultValue;
			if (usesMetricSystem && props.defaultValue) {
				defaultDisplayValue = UnitOfMeasure.convertImperialToMetric(props.defaultValue, props.type);
			}
			newDisplayValue = getStringValue(defaultDisplayValue, decimalPlaces); // newDisplayValue is either imperial or metric based on usesMetricSystem flag
		} else {
			const convertedValue = UnitOfMeasure.convertValueWithUnit(displayValue, usesMetricSystem, props.type);
			newDisplayValue = getStringValue(convertedValue, decimalPlaces); // newDisplayValue is either imperial or metric based on usesMetricSystem flagfon
		}

		setDisplayValue(newDisplayValue);

		setIsTouched(true);

		// Always store values (in browser storage) and validate values as imperial.
		// If user uses metric system, newDisplayValue is in metric.
		// So convert newDisplayValue to imperial before storing and validating.
		let imperialValue = newDisplayValue;
		if (usesMetricSystem && newDisplayValue) {
			imperialValue = UnitOfMeasure.convertMetricToImperial(+newDisplayValue, props.type).toString();
		}
		if (+newDisplayValue !== +previousDisplayValue) {
			props.onChange(props.name, imperialValue); // This event triggers saving to the browser storage.
		}
		if (props.onValidate) {
			const message = props.onValidate(props.name, imperialValue ? +imperialValue : undefined); // This event triggers validating the field based on business rules.
			setIsValid(message === "");
		}
	};

	return (
		<div className={`component${props.tooltip ? " tooltip" : ""}`}>
			<input
				type="text"
				name={props.name}
				className={`input measurement is-small${isValid ? "" : " is-danger"} ${isCustom ? " is-custom" : ""}`}
				value={displayValue}
				disabled={props.disabled ?? false}
				onFocus={handleFocus}
				onChange={handleChange}
				onBlur={handleBlur}
				onKeyPress={handleKeyPress}
			/>
			{props.tooltip && (
				<div className="tooltiptext tooltip-top">
					<div>{props.tooltip}</div>
				</div>
			)}
		</div>
	);
};

export default Measurement;