const loadAWEFHelpMeDecideUrl = (division: number): string => {

    if (division == 1 || division == 11){
        return "https://b-rp.ca/awef-faq";
    }
    else if (division == 2 || division == 12){
        return "https://k-rp.com/awef-faq";
    }
    else if (division == 3 || division == 13){
        return "https://t-rp.com/awef-faq";
    }

    return "";
}

export default loadAWEFHelpMeDecideUrl;