import * as React from "react";

import ErrorContext from "../context/ErrorContext";

const useErrorContext = () => {
    const { errorMessages, showError, showErrorList, hideError } = React.useContext(ErrorContext);
    
    React.useEffect(() => {
        return () => {
            hideError();
        };
    }, [])
    
    return { errorMessages, showError, showErrorList, hideError };
};

export default useErrorContext;
