import * as React from "react";

import IAirProperty from "../models/interfaces/IAirProperty";
import ICurtain from "../models/interfaces/ICurtain";

const ApplicationContext = React.createContext({
    appData: {} as any,
    airProperties: [] as IAirProperty[],
    curtains: [] as ICurtain[],
    isAppDataLoaded: false
});

export default ApplicationContext;