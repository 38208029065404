import * as React from "react";

import SectionType from "../../enums/SectionType";
import WallType from "../../enums/WallType";

import "./LShapeBox.css"

export interface LShapeBoxProps {    
    sectionType: SectionType,
    wallType: WallType
}

const LShapeBox: React.FunctionComponent<LShapeBoxProps> = (
	props: LShapeBoxProps
) => {
    const isFrontSelected = () => {
        return (props.sectionType === SectionType.WallCustom || props.sectionType === SectionType.WallPolygon)
            && props.wallType === WallType.Front;
    }

    const isFrontCutSelected = () => {
        return (props.sectionType === SectionType.WallCustom || props.sectionType === SectionType.WallPolygon)
            && props.wallType === WallType.FrontCut;
    }

    const isBackSelected = () => {
        return (props.sectionType === SectionType.WallCustom || props.sectionType === SectionType.WallPolygon)
            && props.wallType === WallType.Rear;
    }

    const isRightSelected = () => {
        return (props.sectionType === SectionType.WallCustom || props.sectionType === SectionType.WallPolygon)
            && props.wallType === WallType.RightEnd;
    }

    const isLeftSelected = () => {
        return (props.sectionType === SectionType.WallCustom || props.sectionType === SectionType.WallPolygon)
            && props.wallType === WallType.LeftEnd;
    }

    const isLeftCutSelected = () => {
        return (props.sectionType === SectionType.WallCustom || props.sectionType === SectionType.WallPolygon)
            && props.wallType === WallType.LeftCut;
    }

    const isTopSelected = () => {
        return (props.sectionType === SectionType.CeilingCustom || props.sectionType === SectionType.CeilingPolygon);
    }

    const isBottomSelected = () => {
        return (props.sectionType === SectionType.FloorCustom || props.sectionType === SectionType.FloorPolygon);
    }

	return (
    <div className="l-box-holder">
        <div className="box-3d angled">
            <div className={`box-face front${(isFrontSelected() ? " selected" : "")}`}>&nbsp;</div>
            <div className={`box-face front-cut${(isFrontCutSelected() ? " selected" : "")}`}>&nbsp;</div>
            <div className={`box-face back${(isBackSelected() ? " selected" : "")}`}>&nbsp;</div>
            <div className={`box-face right${(isRightSelected() ? " selected" : "")}`}>&nbsp;</div>
            <div className={`box-face left${(isLeftSelected() ? " selected" : "")}`}>&nbsp;</div>
            <div className={`box-face left-cut${(isLeftCutSelected() ? " selected" : "")}`}>&nbsp;</div>
            <div className={`box-face top${(isTopSelected() ? " selected" : "")}`}>
                <div className="box-face top-half">&nbsp;</div>
            </div>
            <div className={`box-face bottom${(isBottomSelected() ? " selected" : "")}`}>
                <div className="box-face bottom-half">&nbsp;</div>
            </div>
        </div>
    </div>
	);
};

export default LShapeBox;
