import * as React from "react";

import IConstructionLayer from "../models/interfaces/IConstructionLayer";
import IMaterial from "../models/interfaces/IMaterial";

import BoxMaterialsContext from "./BoxMaterialsContext";

import ConfigurationService from "../services/ConfigurationService";
import StorageFacade from "../services/StorageFacade";

import useApplicationConfigs from "../hooks/useApplicationConfigs";
import useConstructionLayerForm from "../hooks/useConstructionLayerForm";
import useErrorContext from "../hooks/useErrorContext";
import useLoadingContext from "../hooks/useLoadingContext";

import * as ConstructionLayerCalculations from "../models/helpers/ConstructionLayerCalculations";

interface BoxMaterialsContextProviderProps {
	children: any;
}

const BoxMaterialsContextProvider: React.FunctionComponent<BoxMaterialsContextProviderProps> = ({
	children,
}: BoxMaterialsContextProviderProps) => {

    const [boxMaterials, setBoxMaterials] = React.useState([] as IMaterial[]);
    const [isMaterialListLoaded, setIsMaterialListLoaded] = React.useState(false);

    const { showLoading, hideLoading } = useLoadingContext();
    const { showError } = useErrorContext();

    const { getBoundaryConstructionLayers } = useApplicationConfigs();
    const { initialFormState } = useConstructionLayerForm();

    React.useEffect(() => {
        setIsMaterialListLoaded(false);

        showLoading();

        try {
            ConfigurationService.getBoxMaterials((data) => {

                let materials = data.map(material => {
                    if (material.isConstruct && material.layerMaterials) {
                        if (typeof material.layerMaterials === "string") {
                            const layersConfig = (material.layerMaterials as string).split("|");
                            if (layersConfig.length > 0) {
                                // sample LayerMaterials string value: "1|7000;4|1400;6|0"

                                const constructionLayers = [] as IConstructionLayer[];
                                
                                const interiorLayerConfig = layersConfig[0];
                                if (interiorLayerConfig === "0") {
                                    const interiorLayer = getBoundaryConstructionLayers()[0];
                                    interiorLayer.rValueEffective = 0;
                                    constructionLayers.push(interiorLayer);
                                }
                                else if (interiorLayerConfig === "1") {
                                    const interiorLayer = getBoundaryConstructionLayers()[0];
                                    constructionLayers.push(interiorLayer);
                                }

                                layersConfig.forEach((
                                    layerConfig: string, 
                                    index: number
                                ) => {
                                    if (index > 0 && index < layersConfig.length - 1) {
                                        const attributes = layerConfig.split(";");
                                        if (attributes.length === 2) {
                                            const materialId = attributes[0].toString();
                                            const thickness = parseInt(attributes[1], 10);

                                            const layer: IConstructionLayer = {
                                                ...initialFormState.defaultValues,
                                                materialId,
                                                thickness
                                            };

                                            constructionLayers.push(layer);
                                        }
                                    }
                                });

                                const exteriorLayerConfig = layersConfig[layersConfig.length - 1];
                                if (exteriorLayerConfig === "0") {
                                    const exteriorLayer = getBoundaryConstructionLayers()[1];
                                    exteriorLayer.rValueEffective = 0;
                                    constructionLayers.push(exteriorLayer);
                                }
                                else if (exteriorLayerConfig === "1") {
                                    const exteriorLayer = getBoundaryConstructionLayers()[1];
                                    constructionLayers.push(exteriorLayer);
                                }

                                material.layerMaterials = constructionLayers;
                                material.stdThickness = ConstructionLayerCalculations.calculateConstructionThickness(constructionLayers);
                                material.rValue = ConstructionLayerCalculations.calculateConstructionRValue(constructionLayers);
                                material.kFactor = ConstructionLayerCalculations.calculateConstructionKFactor(material.stdThickness, material.rValue);
                            }
                        }
                    }
                    return material;
                })
                
                if (StorageFacade.loadCalcSystem?.wallLoad?.constructions
                    && StorageFacade.loadCalcSystem.wallLoad.constructions.length > 0
                ) {
                    materials = [
                        ...data,
                        ...StorageFacade.loadCalcSystem.wallLoad.constructions
                    ];
                }

                setBoxMaterials(materials);
                setIsMaterialListLoaded(true);
                hideLoading();
            });
        } catch (error: any) {
            showError({
                name: "Error",
                text: `An error was encountered. ${error.message ?? ""}`
            });
            hideLoading();
        }
    }, [getBoundaryConstructionLayers]);

    const updateBoxMaterials = React.useCallback((materials: IMaterial[]): void => {
        setBoxMaterials(materials);
    }, []);
    
    const contextValue = {
        boxMaterials,
        isMaterialListLoaded,
        updateBoxMaterials
    };

    return (
        <BoxMaterialsContext.Provider value={contextValue}>
            {children}
        </BoxMaterialsContext.Provider>
    );
};

export default BoxMaterialsContextProvider;