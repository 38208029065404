import * as React from "react";

import FormField from "../helpers/FormField";
import TextBox from "../helpers/TextBox";

import useTranslationContext from "../../hooks/useTranslationContext";

interface UnitAttributesProps {
    taggingId: string;
    tagging: string;
    notes: string;
    onAttributeChange: (event: React.FormEvent<HTMLInputElement>) => void;
}

const UnitAttributes: React.FunctionComponent<UnitAttributesProps> = (
    props: UnitAttributesProps
) => {

    const { translations } = useTranslationContext();

    return (
        <>
            <FormField label={translations.taggingID}>
                <TextBox 
                    name="taggingId"
                    value={props.taggingId}
                    onChange={props.onAttributeChange}
                />
            </FormField>
            <FormField label={translations.tagging}>
                <TextBox 
                    name="tagging"
                    value={props.tagging}
                    onChange={props.onAttributeChange}
                />
            </FormField>
            <FormField label={translations.notes}>
                <TextBox 
                    name="notes"
                    value={props.notes}
                    onChange={props.onAttributeChange}
                />
            </FormField>
        </>
    );
};

export default React.memo(UnitAttributes);