import * as React from "react";

import "./Grid.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import IMessage from "../../models/interfaces/IMessage";

interface GridProps<T> {
    dataSource: T[];
    height?: number;
    selectedRow: number;
    onRowClick?: (index: number) => void,
    onRowDoubleClick?: (index: number) => void
}

const Grid = <T extends object>(
    props: GridProps<T>
) => {

    const [selectedRowIndex, setSelectedRowIndex] = React.useState(-1);

    React.useEffect(() => {
        setSelectedRowIndex(props.selectedRow);
    }, [props.selectedRow]);
    
    const headers = (props.dataSource && props.dataSource.length > 0) 
        ? Object.keys(props.dataSource[0])
            .filter(k => k.toLowerCase() !== "errors"
                && k.toLowerCase() !== "rowclassname"
            )
        : null;
    const rows = props.dataSource;

    const handleRowClick = (index: number) => {
        setSelectedRowIndex(index);
        if (props.onRowClick) {
            props.onRowClick(index);
        }
    };

    const handleRowDoubleClick = (index: number) => {
        setSelectedRowIndex(index);
        if (props.onRowDoubleClick) {
            props.onRowDoubleClick(index);
        }
    };

    const heightStyle = {
        height: props.height ? `${props.height.toString()}px` : "auto"
    };
    
    return (
        <div id="grid" style={heightStyle}>
            {headers && (
                <table className="table is-hoverable is-fullwidth">
                    <thead>
                        <tr>
                            <th>
                                &nbsp;
                            </th>
                            {headers.map((header, hIndex) => {
                                const hKey = hIndex;
                                return (
                                    <th key={hKey}>
                                        {header}
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row: { [key: string]: any }, index: number) => {
                            const rowIndex = index;
                            return (
                                <tr 
                                    className={`grid-row${(selectedRowIndex === rowIndex ? " is-selected" : "")}${(row.rowClassName ? ` ${row.rowClassName}` : "")}`}
                                    key={rowIndex}
                                    onClick={() => handleRowClick(rowIndex)}
                                    onDoubleClick={() => handleRowDoubleClick(rowIndex)}
                                >
                                    <td className="icons">
                                        {row.errors && row.errors?.length > 0 && (
                                            <div className="tooltip-leftedge">
                                                <div className="component tooltip">
                                                    <div>
                                                        <FontAwesomeIcon icon={faTimesCircle} color="#a30710" />
                                                    </div>
                                                    <div className="tooltiptext tooltip-top">
                                                        {row.errors.map((e: IMessage) => (
                                                            <div key={e.name}>
                                                                {e.text}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </td>
                                    {headers.map((header, hIndex) => {
                                        const hKey = hIndex;
                                        return (
                                            <td key={hKey}>
                                                {row[header]}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default Grid;
