import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./registerServiceWorker";
import "react-bulma-components/dist/react-bulma-components.min.css";

import App from "./App";
import ThemeSelector from "./components/Themes/ThemeSelector";

// expose the following to Blazor
import EquipmentOptions from "./components/EquipmentOptions/EquipmentOptions";
import * as OptionUtils from "./components/EquipmentOptions/EquipmentOptions.utils";
import IEquipmentOption from "./models/interfaces/IEquipmentOption";
import IOption from "./models/interfaces/IOption";

const rootElement = document.getElementById("root");

ReactDOM.render(
	<BrowserRouter>
		<ThemeSelector>
			<App />
		</ThemeSelector>
	</BrowserRouter>,
	rootElement
);

(window as any).componentsRenderer = {
    clearReactDomNode: () => {
        if (rootElement) {
            ReactDOM.unmountComponentAtNode(rootElement);
        }
    },
    renderEquipmentOptions: async (instance: any,
        modelId: number,
        divisionId: number,
        popupTitle: string,
        selectedOptions: IEquipmentOption[],
        excludedOptionIds: string | null,
        onOkClick: any,
        onCancelClick: any
    ) => {
        const okClick = (options: IEquipmentOption[]) => { 
            (window as any).componentsRenderer.clearReactDomNode();
            instance.invokeMethodAsync(onOkClick, options);
        };
        const cancelClick = () => {
            (window as any).componentsRenderer.clearReactDomNode();
            instance.invokeMethodAsync(onCancelClick);
        };
        let defaultAndSelectedOptions = [] as IOption[];
        const optionsData = await OptionUtils.loadOptions(
            modelId,
            divisionId,
            selectedOptions ?? [],
            excludedOptionIds
        );
        defaultAndSelectedOptions = optionsData.selected;
        const selectedOptionQuantities = OptionUtils.getSelectedOptionQuantities(defaultAndSelectedOptions);
        ReactDOM.render(<EquipmentOptions
            show
            title={popupTitle}
            id={modelId}
            divisionId={divisionId}
            selectedOptionList={selectedOptionQuantities}
            excludedOptionIds={excludedOptionIds}
            onOkClick={okClick}
            onCancelClick={cancelClick}
        />, rootElement);
    }
}

serviceWorker.unregister();

