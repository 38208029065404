/* eslint-disable no-underscore-dangle */
import * as React from "react";
import { Link } from "react-router-dom";

import Button from "../helpers/Button";
import Checkbox from "../helpers/Checkbox";
import ConfirmationMessage from "../helpers/ConfirmationMessage";
import FloorTypesButton from "../FloorTypes/FloorTypesButton";
import FormField from "../helpers/FormField";
import Grid from "../helpers/Grid";
import Measurement from "../helpers/Measurement";
import TextBox from "../helpers/TextBox";

import { measure, hasValue, getNumericValue } from "../../types/measure";
import UnitOfMeasure from "../../models/helpers/UnitOfMeasure";

import BoxLocation from "../../enums/BoxLocation";
import BoxType from "../../enums/BoxType";
import FloorType from "../../enums/FloorType";
import MeasurementType from "../../enums/MeasurementType";

import IFormState from "../../models/interfaces/IFormState";
import ISystem from "../../models/interfaces/ISystem";

import useTranslationContext from "../../hooks/useTranslationContext";

import "./LoadCalculator.css";

const getLoadPercentageButtonLabel = (
    buttonLabel: string,
    load: measure,
    totalLoad: measure
): string => {
    if (hasValue(load)
        && hasValue(totalLoad)
    ) {
        const percentage = UnitOfMeasure.getDisplayValue((getNumericValue(load) / getNumericValue(totalLoad)) * 100, 0);

        return `${buttonLabel} ${percentage}${UnitOfMeasure.getPercentUnitLabel()}`;
    }

    return buttonLabel;
};

interface LoadCalculatorComponentProps {
    isBoxFreezer: boolean;
    formState: IFormState<ISystem>;
    onInputChange: any;
    onMeasurementChange: any;
    onFloorTypeChange: any;
    onBoxLocationChange: any;
    onChangeBoxApplicationButtonClick: any;
    onValidate: any;
    onWallLoadButtonClick: any;
    onCoolingLoadButtonClick: any;
    onSaveButtonClick: any;
    onCancelButtonClick: any;
    onPrintPreviewButtonClick: any;
}

const LoadCalculatorComponent: React.FunctionComponent<LoadCalculatorComponentProps> = (
    props: LoadCalculatorComponentProps
) => {

    const { translations } = useTranslationContext();

    const [showCancelConfirmation, setShowCancelConfirmation] = React.useState(false);

    const cancelConfirmationMessage = {
		title: "Confirmation",
		message: <div>Are you sure you want to cancel?</div>,
		onYesClick: () => {
            setShowCancelConfirmation(false);
            if (props.onCancelButtonClick) {
                props.onCancelButtonClick();
            }
        },
		onNoClick: () => {
            setShowCancelConfirmation(false);
        }
    };

    const equipmentGridView = () => {
        const equipment = [];
        
        if (props.formState.actualValues.equipmentSelection.selectedCondenserUnit) {
            const condenserUnit: { [key: string]: any } = {};
            condenserUnit[translations.qTY] = props.formState.actualValues.equipmentSelection.condenserUnitCount;
            condenserUnit[translations.modelNumber] = props.formState.actualValues.equipmentSelection.selectedCondenserUnit.partNumber;
            condenserUnit[translations.descriptions] = `${props.formState.actualValues.equipmentSelection.selectedCondenserUnit.description}|${props.formState.actualValues.equipmentSelection.equipmentType}`;
            condenserUnit[translations.voltage] = props.formState.actualValues.equipmentSelection.selectedCondenserUnit.text;
            condenserUnit[translations.options] = props.formState.actualValues.equipmentSelection.selectedCondenserOptionList.length;

            equipment.push(condenserUnit);
        }

        if (props.formState.actualValues.equipmentSelection.selectedEvaporator) {
            const evaporatorUnit: { [key: string]: any } = {};
            evaporatorUnit[translations.qTY] = props.formState.actualValues.equipmentSelection.evaporatorUnitCount;
            evaporatorUnit[translations.modelNumber] = props.formState.actualValues.equipmentSelection.selectedEvaporator.partNumber;
            evaporatorUnit[translations.descriptions] = `${props.formState.actualValues.equipmentSelection.selectedEvaporator.description}|Evaporator`;
            evaporatorUnit[translations.voltage] = props.formState.actualValues.equipmentSelection.selectedEvaporator.text;
            evaporatorUnit[translations.options] = props.formState.actualValues.equipmentSelection.selectedEvaporatorOptionList.length;
            
            equipment.push(evaporatorUnit);
        }

        return equipment;
    };

    if (!props.formState.actualValues || !props.formState.defaultValues) {
        return null;
    }

    return (
        <div id="load-calculator" className="container">
            <div className="label is-small header">
                {translations.systemDetails}
            </div>
            <div className="columns">
                <div className="column">
                    <FormField label={translations.systemName}>
                        <TextBox 
                            name="systemName"
                            value={props.formState.actualValues.systemName ?? ""}
                            onChange={props.onInputChange}
                        />
                    </FormField>
                    <FormField label={translations.description}>
                        <TextBox 
                            name="systemDescription"
                            value={props.formState.actualValues.systemDescription ?? ""}
                            onChange={props.onInputChange}
                        />
                    </FormField>
                </div>
            </div>
            <div className="label is-small header">
                {translations.designRequirements}
            </div>
            <div className="columns">
                <div className="column">
                    <FormField label={translations.boxApplication}>
                        <div className="field has-addons">
                            <div className="control" style={{ width: "100%" }}>
                                <TextBox 
                                    name="boxApplicationName"
                                    value={props.formState.actualValues.boxApplicationName ?? ""}
                                    readOnly
                                />
                            </div>
                            <div className="control">
                                <Button
                                    name="change-box"
                                    label="Change"
                                    className="button is-light"
                                    onClick={props.onChangeBoxApplicationButtonClick}
                                />
                            </div>
                        </div>
                    </FormField>
                    <FormField label="">
                        <div
                            className="columns"
                            id="box-attributes"
                            style={{ width: "100%" }}
                        >
                            <div className="column is-half">
                                <FormField label={`${translations.boxInteriorTemperature} (${UnitOfMeasure.getTemperatureUnitLabel()})`}>
                                    <Measurement
                                        name="interiorTemperature"
                                        defaultValue={props.formState.defaultValues.interiorTemperature}
                                        actualValue={props.formState.actualValues.interiorTemperature}
                                        decimals={1}
                                        onChange={props.onMeasurementChange}
                                        type={MeasurementType.Temperature}
                                        tooltip={translations.System_Designer_txtBox_Interior_Temp}
                                        onValidate={props.onValidate}
                                    />
                                </FormField>
                                <FormField label={`${translations.boxExteriorTemperature} (${UnitOfMeasure.getTemperatureUnitLabel()})`}>
                                    <Measurement
                                        name="exteriorTemperature"
                                        defaultValue={props.formState.defaultValues.exteriorTemperature}
                                        actualValue={props.formState.actualValues.exteriorTemperature}
                                        decimals={1}
                                        onChange={props.onMeasurementChange}
                                        type={MeasurementType.Temperature}
                                        tooltip={translations.System_Designer_txtBox_Exterior_Temp}
                                        onValidate={props.onValidate}
                                    />
                                </FormField>
                            </div>
                            <div className="column is-half">
                                <FormField label={translations.boxInteriorRelativeHumidity___}>
                                    <Measurement
                                        name="interiorHumidity"
                                        defaultValue={props.formState.defaultValues.interiorHumidity}
                                        actualValue={props.formState.actualValues.interiorHumidity}
                                        decimals={1}                                        
                                        onChange={props.onMeasurementChange}
                                        type={MeasurementType.PositiveDecimal}
                                        tooltip={translations.System_Designer_txtbox_InteriorRH}
                                        onValidate={props.onValidate}
                                    />
                                </FormField>
                                <FormField label={translations.boxExteriorRelativeHumidity___}>
                                    <Measurement
                                        name="exteriorHumidity"
                                        defaultValue={props.formState.defaultValues.exteriorHumidity}
                                        actualValue={props.formState.actualValues.exteriorHumidity}
                                        decimals={1}
                                        onChange={props.onMeasurementChange}
                                        type={MeasurementType.PositiveDecimal}
                                        tooltip={translations.System_Designer_txtbox_ExteriorRH}
                                        onValidate={props.onValidate}
                                    />
                                </FormField>
                            </div>
                        </div>
                    </FormField>
                </div>
            </div>
            <div className="label is-small header">{translations.quickBox}</div>
            <div className="columns">
                <div className="column">
                    {(props.formState.actualValues.boxType === BoxType.Undefined || props.formState.actualValues.boxType === BoxType.Rectangular) && (
                        <FormField label={translations.rectangularBoxDimensions}>
                            <div className="field-body dimensions">
                                <FormField label={`${translations.length} (${UnitOfMeasure.getDimensionUnitLabel()})`}>
                                    <Measurement
                                        name="boxLength"
                                        defaultValue={props.formState.defaultValues.boxLength}
                                        actualValue={props.formState.actualValues.boxLength}
                                        decimals={2}
                                        onChange={props.onMeasurementChange}
                                        tooltip={translations.System_Designer_txtbox_BoxLength1}
                                        type={MeasurementType.Dimension}
                                        onValidate={props.onValidate}
                                    />
                                </FormField>
                                <FormField label={`${translations.depth} (${UnitOfMeasure.getDimensionUnitLabel()})`}>
                                    <Measurement
                                        name="boxDepth"
                                        defaultValue={props.formState.defaultValues.boxDepth}
                                        actualValue={props.formState.actualValues.boxDepth}
                                        decimals={2}
                                        onChange={props.onMeasurementChange}
                                        tooltip={translations.System_Designer_txtbox_BoxDepth1}
                                        type={MeasurementType.Dimension}
                                        onValidate={props.onValidate}
                                    />
                                </FormField>
                                <FormField label={`${translations.height} (${UnitOfMeasure.getDimensionUnitLabel()})`}>
                                    <Measurement
                                        name="boxHeight"
                                        defaultValue={props.formState.defaultValues.boxHeight}
                                        actualValue={props.formState.actualValues.boxHeight}
                                        decimals={2}
                                        onChange={props.onMeasurementChange}
                                        tooltip={translations.System_Designer_txtbox_BoxHeight}
                                        type={MeasurementType.Dimension}
                                        onValidate={props.onValidate}
                                    />
                                </FormField>
                            </div>
                        </FormField>
                    )}
                    <FormField label={translations.floorType}>
                        <FloorTypesButton
                            isFreezer={props.isBoxFreezer}
                            selected={props.formState.actualValues.floorType ?? FloorType.OnGrade}
                            onChange={props.onFloorTypeChange}
                        />
                    </FormField>
                    <FormField label={translations.boxLocation}>
                        <>
                            <Checkbox
                                name="boxLocation"
                                label={translations.indoors}
                                value={BoxLocation.Indoors}
                                isChecked={props.formState.actualValues.boxLocation === BoxLocation.Indoors}
                                onChange={props.onBoxLocationChange}
                            />
                            <Checkbox
                                name="boxLocation"
                                label={translations.outdoors}
                                value={BoxLocation.OutdoorsWarm}
                                isChecked={props.formState.actualValues.boxLocation === BoxLocation.OutdoorsWarm}
                                onChange={props.onBoxLocationChange}
                            />
                            <Checkbox
                                name="boxLocation"
                                label={translations.outdoors_ColdClimate_}
                                value={BoxLocation.OutdoorsCold}
                                isChecked={props.formState.actualValues.boxLocation === BoxLocation.OutdoorsCold}
                                onChange={props.onBoxLocationChange}
                            />
                        </>
                    </FormField>
                </div>
            </div>
            <div className="label is-small header">{translations.coolingLoads}</div>
            <div className="columns">
                <div className="column">
                    <FormField label=" ">
                        <div
                            className="columns"
                            id="cooling-loads"
                            style={{ width: "100%" }}
                        >
                            <div className="column is-half">
                                <FormField label={(
                                    <Button
                                        name="change-wallLoad"
                                        label={getLoadPercentageButtonLabel(translations.wallLoad, props.formState.actualValues.totalWallLoad, props.formState.actualValues.totalHourlyLoad)}
                                        className="button label is-light has-text-right"
                                        onClick={props.onWallLoadButtonClick}
                                        tooltip={translations.System_Designer_Button_Wall_Load}
                                    />
                                )}
                                >
                                    <Measurement
                                        name="totalWallLoad"
                                        defaultValue={props.formState.defaultValues.totalWallLoad}
                                        actualValue={props.formState.actualValues.totalWallLoad}
                                        decimals={0}
                                        onChange={props.onMeasurementChange}
                                        type={MeasurementType.Rating}
                                        onValidate={props.onValidate}
                                    />
                                </FormField>
                                <FormField label={(
                                    <Button
                                        name="change-infiltrationLoad"
                                        label={getLoadPercentageButtonLabel(translations.infiltrationLoad, props.formState.actualValues.totalInfiltrationLoad, props.formState.actualValues.totalHourlyLoad)}
                                        className="button label is-light has-text-right"
                                        tooltip={translations.System_Designer_Button_Infiltration_Load}
                                        onClick={() => { props.onCoolingLoadButtonClick("/infiltrationload"); }}
                                    />
                                )}
                                >
                                    <Measurement
                                        name="totalInfiltrationLoad"
                                        defaultValue={props.formState.defaultValues.totalInfiltrationLoad}
                                        actualValue={props.formState.actualValues.totalInfiltrationLoad}
                                        decimals={0}
                                        onChange={props.onMeasurementChange}
                                        type={MeasurementType.Rating}
                                        onValidate={props.onValidate}
                                    />
                                </FormField>
                                <FormField label={`${translations.safetyFactor} (${UnitOfMeasure.getPercentUnitLabel()})`}>
                                    <Measurement
                                        name="safetyFactor"
                                        defaultValue={props.formState.defaultValues.safetyFactor}
                                        actualValue={props.formState.actualValues.safetyFactor}
                                        decimals={2}
                                        onChange={props.onMeasurementChange}
                                        type={MeasurementType.PositiveDecimal}
                                        onValidate={props.onValidate}
                                    />
                                </FormField>
                                <FormField label={`${translations.systemRun_Time} ${translations.hours}`}>
                                    <Measurement
                                        name="runTimeHours"
                                        defaultValue={props.formState.defaultValues.runTimeHours}
                                        actualValue={props.formState.actualValues.runTimeHours}
                                        decimals={2}
                                        onChange={props.onMeasurementChange}
                                        type={MeasurementType.PositiveDecimal}
                                        tooltip={translations.System_Designer_txtRun_Time}
                                        onValidate={props.onValidate}
                                    />
                                </FormField>
                            </div>
                            <div className="column is-half">
                                <FormField label={(
                                    <Button
                                        name="change-productLoad"
                                        label={getLoadPercentageButtonLabel(translations.productLoad, props.formState.actualValues.totalProductLoad, props.formState.actualValues.totalHourlyLoad)}
                                        className="button label is-light has-text-right"
                                        tooltip={translations.System_Designer_Button_Product_Load}
                                        onClick={() => { props.onCoolingLoadButtonClick("/productload"); }}
                                    />
                                )}
                                >
                                    <Measurement
                                        name="totalProductLoad"
                                        defaultValue={props.formState.defaultValues.totalProductLoad}
                                        actualValue={props.formState.actualValues.totalProductLoad}
                                        decimals={0}
                                        onChange={props.onMeasurementChange}
                                        type={MeasurementType.Rating}
                                        onValidate={props.onValidate}
                                    />
                                </FormField>
                                <FormField label={(
                                    <Button
                                        name="change-miscellaneousLoad"
                                        label={getLoadPercentageButtonLabel(translations.miscellaneousLoad, props.formState.actualValues.totalMiscLoad, props.formState.actualValues.totalHourlyLoad)}
                                        className="button label is-light has-text-right"
                                        tooltip={translations.System_Designer_Button_Miscellaneous_Load}
                                        onClick={() => { props.onCoolingLoadButtonClick("/miscload"); }}
                                    />
                                )}
                                >
                                    <Measurement
                                        name="totalMiscLoad"
                                        defaultValue={props.formState.defaultValues.totalMiscLoad}
                                        actualValue={props.formState.actualValues.totalMiscLoad}
                                        decimals={0}
                                        onChange={props.onMeasurementChange}
                                        type={MeasurementType.Rating}
                                        onValidate={props.onValidate}
                                    />
                                </FormField>
                                <FormField label={`${translations.totalHourlyLoad} (${UnitOfMeasure.getRatingUnitLabel()})`}>
                                    <Measurement
                                        name="totalHourlyLoad"
                                        defaultValue={props.formState.defaultValues.totalHourlyLoad}
                                        actualValue={props.formState.actualValues.totalHourlyLoad}
                                        decimals={0}
                                        onChange={props.onMeasurementChange}
                                        type={MeasurementType.Rating}
                                        tooltip={translations.System_Designer_txtTotal_Hourly_Load}
                                        onValidate={props.onValidate}
                                    />
                                </FormField>
                                <FormField label={`${translations.totalLoadRequired} (${UnitOfMeasure.getRatingUnitLabel()})`}>
                                    <Measurement
                                        name="totalLoadRequired"
                                        defaultValue={props.formState.defaultValues.totalLoadRequired}
                                        actualValue={props.formState.actualValues.totalLoadRequired}
                                        decimals={0}
                                        onChange={props.onMeasurementChange}
                                        type={MeasurementType.Rating}
                                        onValidate={props.onValidate}
                                    />
                                </FormField>
                                <FormField label={`${translations.capacityRequired} (${UnitOfMeasure.getRatingUnitLabel()})`}>
                                    <Measurement
                                        name="capacityRequired"
                                        defaultValue={props.formState.defaultValues.capacityRequired}
                                        actualValue={props.formState.actualValues.capacityRequired}
                                        decimals={0}
                                        onChange={props.onMeasurementChange}
                                        type={MeasurementType.Rating}
                                        onValidate={props.onValidate}
                                    />
                                </FormField>
                            </div>
                        </div>
                    </FormField>
                </div>
            </div>
            <div className="label is-small header">
                {translations.equipmentDetails}
            </div>
            <div className="columns">
                <div className="column">
                    <FormField label={(
                        <Link to="/selectequipment">
                            <Button
                                name="select-equipment"
                                label={translations.selectEquipment}
                                className="button label is-light is-fullwidth"
                            />
                        </Link>
                    )}
                    >
                        <>
                            <Grid 
                                dataSource={equipmentGridView()} 
                                selectedRow={-1}
                            />
                            <br />
                            <div className="field-body balance">
                                <FormField label="TD">
                                    <span className="is-small">
                                        {props.formState.actualValues.equipmentSelection.tdBalance 
                                            ? `${UnitOfMeasure.getTemperatureRelativeDisplayValue(props.formState.actualValues.equipmentSelection.tdBalance, 1)} ${UnitOfMeasure.getTemperatureUnitLabel()}`
                                            : ""}
                                    </span>
                                </FormField>
                                <FormField label={translations.sST}>
                                    <span className="is-small">
                                        {props.formState.actualValues.equipmentSelection.sstBalance 
                                            ? `${UnitOfMeasure.getTemperatureDisplayValue(props.formState.actualValues.equipmentSelection.sstBalance, 1)} ${UnitOfMeasure.getTemperatureUnitLabel()}`
                                            : ""}
                                    </span>
                                </FormField>
                                <FormField label={translations.systemCapacity}>
                                    <span className="is-small">
                                        {props.formState.actualValues.equipmentSelection.totalSystemCapacity 
                                            ? `${UnitOfMeasure.getRatingDisplayValue(props.formState.actualValues.equipmentSelection.totalSystemCapacity, 0)} ${UnitOfMeasure.getRatingUnitLabel()}`
                                            : ""}
                                    </span>
                                </FormField>
                            </div>
                        </>
                    </FormField>
                </div>
            </div>
            <div className="level">
                <div className="level-left">
                    <div className="level-item">
                        <Button
                            name="cancel-loadcalc"
                            label={translations.cancel}
                            className="button is-primary"
                            onClick={() => { setShowCancelConfirmation(true); }}
                        />
                    </div>
                    <div className="level-item">
                        <Button
                            name="print-preview"
                            label={translations.print}
                            className="button is-primary"
                            tooltip={translations.System_Designer_Button_Preview}
                            onClick={props.onPrintPreviewButtonClick}
                        />
                    </div>
                </div>
                <div className="level-right">
                    <div className="level-item">
                        <Button
                            name="save-loadcalc"
                            label={translations.save}
                            className="button is-link"
                            onClick={props.onSaveButtonClick}
                        />
                    </div>
                </div>
            </div>
            <ConfirmationMessage
                title={cancelConfirmationMessage.title}
                message={cancelConfirmationMessage.message}
                onYesClick={cancelConfirmationMessage.onYesClick}
                onNoClick={cancelConfirmationMessage.onNoClick}
                onCancelClick={cancelConfirmationMessage.onNoClick}
                show={showCancelConfirmation}
            />
        </div>
    );
};

export default LoadCalculatorComponent;