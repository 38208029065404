import * as React from "react";

import Button from "../helpers/Button";
import BoxApplications from "./BoxApplications";
import FullScreenModal from "../helpers/FullScreenModal";

import useTranslationContext from "../../hooks/useTranslationContext";

interface ChangeBoxApplicationProps {
	show: boolean;	
	onOkClick: any;
	onCancelClick: any;
}

const ChangeBoxApplication: React.FunctionComponent<ChangeBoxApplicationProps> = (
    props: ChangeBoxApplicationProps
) => {
    
    const { translations } = useTranslationContext();

    const [selectedApplicationId, setSelectedApplicationId] = React.useState(-1);
    
    const handleSelectApplicationId = (applicationId: number) => {
		setSelectedApplicationId(applicationId);
    };

    const handleSubmitChangeApplication = () => {
        if (props.onOkClick) {
            props.onOkClick(selectedApplicationId);
        }
    };

    const handleSubmitSelectedApplicationId = (applicationId: number) => {
        handleSelectApplicationId(applicationId);
        handleSubmitChangeApplication();
    };

    const body = (
        <BoxApplications 
            selectedId={selectedApplicationId}
            onClick={handleSelectApplicationId} 
            onDoubleClick={handleSubmitSelectedApplicationId} 
        />
	);

    const footer = (
		<>
			<Button
				name="submit-changebox"
                label={translations.oK}
                className="button is-link"
                disabled={selectedApplicationId < 0}
                onClick={handleSubmitChangeApplication}
			/>
            <Button
				name="cancel-changebox"
                label={translations.cancel}
                className="button is-primary"
                onClick={props.onCancelClick}
            />
		</>
	);
    
    return (
		<FullScreenModal
			show={props.show}
			title=""
			body={body}
			footer={footer}
			onCancelClick={props.onCancelClick}
		/>
	);
};

export default ChangeBoxApplication;
