import IOption from "../../models/interfaces/IOption";
import IOptionGroup from "../../models/interfaces/IOptionGroup";
import IOptionPackageItem from "../../models/interfaces/IOptionPackageItem";
import IOptionRule from "../../models/interfaces/IOptionRule";
import IOptionConfigurationSet from "../../models/interfaces/IOptionConfigurationSet";
import IOptionConfigurationVariant from "../../models/interfaces/IOptionConfigurationVariant";
import IEquipmentOption from "../../models/interfaces/IEquipmentOption";
import { Config } from "../../Constants";
import StorageFacade from "../../services/StorageFacade";

export const getSelectedOptionQuantities = (
	selectedOptions: IOption[]
): IEquipmentOption[] => {
	return selectedOptions.map(o => {
		let isPackageOption = o.isPackage;
		const selectedPackage = selectedOptions.find(a => a.isPackage);
		if (selectedPackage) {
			const packageItems = selectedPackage.packageOptions;
			if (packageItems) {
				isPackageOption = o.isPackage || packageItems.findIndex(a => a.optionId === o.optionId) > -1;
			}
		}

		return {
			id: o.optionId,
			classOptionId: o.classOptionId,
			quantity: o.selectedQuantity,
			groupName: o.groupName,
			isPackage: o.isPackage,
			isPackageItem: isPackageOption,
			isRequired: o.isRequired,
			name: o.name,
			description: o.description,
			isSeparatePrice: o.isSeparatePrice,
			isLineItem: o.isLineItem,
			partNumber: o.partNumber,
			optionOrder: o.optionOrder,
			subOptionOrder: o.subOptionOrder,
            isAssembly: o.isAssembly,
            state: o.state
		};
	});
};

export const getSelectedRegularOptionIds = (selected: IOption[]) => {
	return (
		selected &&
		selected
			.filter(o => !o.isAddOn && !o.isPackage)
			.map(o => o.optionId)
	);
};

export const updateOptionList = (
	selected: IOption[],
	option: IOption
) => {
	const selectedOptions = [...selected];

	// Add the option to the array if it is selected and if it does not exist in the array yet.
	// Remove the option from the array if it is not selected and it exists in the array.

	if (!selectedOptions.find(s => s.optionId === option.optionId) 
		&& (
			(option.isChecked && !option.allowMultiples) ||
			(option.selectedQuantity > 0 && option.allowMultiples)
		)
	) {
		selectedOptions.push(option);
	}
	else { 
		const optionIndex = selectedOptions.findIndex(s => s.optionId === option.optionId);
		if (optionIndex > -1
			&& (
				(!option.isChecked && !option.allowMultiples) ||
				(option.selectedQuantity === 0 && option.allowMultiples)
			)
		) {
			selectedOptions.splice(optionIndex, 1);
		}
	}

	return selectedOptions;
};

export const checkPackageMatch = (
	groups: IOptionGroup[],
	selected: IOption[]
) => {
	let packageMatch = null as IOption | null;

	// Check if all non-add-on selected options exactly match a package options collection.

	if (!selected.some(o => o.selectedQuantity > 1)) {
		// Check if there is a selected quantity that is more than 1. If there is, this will not match a package.

		const nonAddOnSelectedOptionIds = getSelectedRegularOptionIds(selected);

		let hasPackageMatch = false;
		let packageMatchOptionIds = [] as number[];
		
		groups.forEach(g => {
			g.options.forEach(o => {
				if (o.isPackage) {
					const includedOptionIds = o.packageOptions
						? o.packageOptions
							.filter(
								po => po.state === 1 || po.state === 3 || po.state === 5
							)
							.map(po => po.optionId)
						: ([] as number[]);

					let currentPackageMatches = includedOptionIds.every(
						po => nonAddOnSelectedOptionIds.indexOf(po) > -1
					);

					// A package cannot have other non-add-on options selected with it if it is a stand-alone package.
					if (o.isStandAlone && includedOptionIds.length !== nonAddOnSelectedOptionIds.length) {
						currentPackageMatches = false;
					}

					if (currentPackageMatches) {
						// If it is a match, select and highlight the package.
						o.isChecked = true;
						o.selectedQuantity = 1;
						o.isCurrentPackageOption = true;

						hasPackageMatch = true;
						packageMatchOptionIds = includedOptionIds;
						packageMatch = o;
					}
					else {
						o.isChecked = false;
						o.selectedQuantity = 0;
						o.isCurrentPackageOption = false;
					}
				}

				selected = updateOptionList(
					selected,
					o
				);
			});
		});

		if (hasPackageMatch) {
			//  If it is a match, highlight all package options.
			groups.forEach(g => {
				g.options.forEach(o => {
					if (packageMatchOptionIds.indexOf(o.optionId) > -1) {
						o.isCurrentPackageOption = true;
					}
				});
			});
		}
	}

	return {
		groups,
		selected,
		packageMatch
	};
};

export const checkVariantStrictMatch = (
	variant: IOptionConfigurationVariant,
	selected: IOption[]
) => {
	const includedOptionIds = variant.options
		? variant.options
				.filter(
					po => po.state === 1 || po.state === 3 || po.state === 5
				)
				.map(po => po.optionId)
		: ([] as number[]);

	const excludedOptionIds = variant.options
		? variant.options
				.filter(
					po => po.state === 2 || po.state === 4
				)
				.map(po => po.optionId)
		: ([] as number[]);

	return (includedOptionIds.every(po => selected.map(so => so.optionId).indexOf(po) > -1) // if all required, included and recommended are selected
		&& excludedOptionIds.every(po => selected.map(so => so.optionId).indexOf(po) < 0)); // and if all excluded and non-recommended are unselected
};

export const checkVariantRequiredOnlyMatch = (
	variant: IOptionConfigurationVariant,
	selected: IOption[]
) => {
	const includedOptionIds = variant.options
		? variant.options
				.filter(
					po => po.state === 1 || po.state === 5
				)
				.map(po => po.optionId)
		: ([] as number[]);

	const excludedOptionIds = variant.options
		? variant.options
				.filter(
					po => po.state === 2
				)
				.map(po => po.optionId)
		: ([] as number[]);

	return (includedOptionIds.every(po => selected.map(so => so.optionId).indexOf(po) > -1) // if all required/included are selected
		&& excludedOptionIds.every(po => selected.map(so => so.optionId).indexOf(po) < 0)); // and if all excluded are unselected
};

export const validateSelectedConfigurations = (
	groups: IOptionGroup[],
	selected: IOption[]
) => {
	let selectionHasError = false;

	// Every time the selected options are updated,
	//  validate the selected configuration variants if there are no conflicts.

	if (selected && selected.length > 0) {

		groups.forEach(g => {
			g.options.forEach(o => {
				o.hasError = false;
				o.hasWarning = false;
				if (o.hasConfigurationSet && o.isChecked) {
					// Check if there is a selected variant.
					if (o.configurationSets[0].variants.find(v => v.isSelected)) {
						o.configurationSets[0].variants.forEach(v => {
							if (v.isSelected) {
								if (checkVariantStrictMatch(v, selected)) {

									// Check if all variant options are exclusions and that all are unselected.
									// If yes, unselect this variant, and unselect the option parent of this variant.

									// const areAllOptionsExcluded = v.options.every(vo => vo.state === 2 || vo.state === 4);
									// if (areAllOptionsExcluded) {
									// 	v.isSelected = false;
									// 	o.isChecked = false;
									// }
								}
								else {

									// If the variant does not match any of the selections, check other variants.
									// If another variant matches, unselect this variant, and select the other, and set hasError to false.

									let otherVariantMatch = false;

									o.configurationSets[0].variants.forEach(ov => {
										if (!ov.isSelected) {
											if (!otherVariantMatch 
												&& checkVariantStrictMatch(ov, selected)) {
												otherVariantMatch = true;
												ov.isSelected = true;
												v.isSelected = false;
											}
										}
									});

									if (!otherVariantMatch) {

										// If there is still no variant match, check if current variant has all excluded options and that all are unselected.
										// If yes, unselect this variant, and unselect the option parent of this variant.

										const areAllOptionsExcluded = v.options.every(vo => vo.state === 2 || vo.state === 4);
										if (areAllOptionsExcluded) {
											v.isSelected = false;
											o.isChecked = false;
											o.selectedQuantity = 0;
										}
										else if (checkVariantRequiredOnlyMatch(v, selected)) {

											// If a strict match is not found, but at least all required/included are selected and all excluded are not selected,
											// show a warning
											o.hasWarning = true;
										}
										else {

											// Check other variants.

											let otherVariantRequiredOnlyMatch = false;

											o.configurationSets[0].variants.forEach(ov => {
												if (!ov.isSelected) {
													if (!otherVariantRequiredOnlyMatch 
														&& checkVariantRequiredOnlyMatch(ov, selected)
													) {
														otherVariantRequiredOnlyMatch = true;
														ov.isSelected = true;
														v.isSelected = false;
														o.hasWarning = true;
													}
												}
											});

											// If still no match, show error.
											if (!otherVariantRequiredOnlyMatch) {
												o.hasError = true;
												selectionHasError = true;
											}
										}
									}
								}
							}
						});
					}
					else {
						// if option with config is checked but there is no variant selected, find a variant match
						
						let variantMatch = false;

						o.configurationSets[0].variants.forEach(ov => {
							if (!variantMatch 
								&& checkVariantStrictMatch(ov, selected)
							) {
								variantMatch = true;
								ov.isSelected = true;
							}
						});

						if (!variantMatch) {

							// Check other variants.

							let variantRequiredOnlyMatch = false;

							o.configurationSets[0].variants.forEach(ov => {
								if (!variantRequiredOnlyMatch 
									&& checkVariantRequiredOnlyMatch(ov, selected)
								) {
									variantRequiredOnlyMatch = true;
									ov.isSelected = true;
									o.hasWarning = true;
								}
							});

							// If still no match, show error.
							if (!variantRequiredOnlyMatch)
							{
								o.hasError = true;
								selectionHasError = true;
							}
						}
					}
				}

				selected = updateOptionList(
					selected,
					o
				);
			});
		});
	}

	return {
		groups,
		selected,
		selectionHasError
	};
};

export const checkRules = (
	selectedOptionId: number,
	rules: IOptionRule[]
) => {
	for (let i = 0; i < rules.length; i += 1) {
		if (
			(rules[i].ruleTypeId === 1 || rules[i].ruleTypeId === 2) && // 1-Warning or 2-Error
			rules[i].optionIdList.indexOf(selectedOptionId) > -1
		) {
			return {
				type: rules[i].ruleTypeId,
				title: rules[i].messageTitle,
				body: rules[i].messageBody
			};
		}
	}

	return null;
};

export const updateOption = (
	groups: IOptionGroup[],
	currentPackage: IOption | null,
	currentlySelectedOptions: IOption[],
	isCurrentPackageCollectionModified: boolean,
	selectedOption: IOption,
	isChecked: boolean,    
	rules: IOptionRule[]
) => {
	const optionGroups = [...groups];
	let currentSelectedOptions = [...currentlySelectedOptions];

	// Check if there is an error.
	let warningMessage = null;
	let errorMessage = null;

	if (isChecked) {
		const ruleMessage = checkRules(selectedOption.optionId, rules);
		if (ruleMessage?.type === 1) { // Warning -> Can proceed with option selection
			warningMessage = {
				title: ruleMessage.title,
				body: ruleMessage.body
			};
		}
		else if (ruleMessage?.type === 2) { // Error -> Option cannot be selected.
			errorMessage = {
				title: ruleMessage.title,
				body: ruleMessage.body
			};
		}
	}

	if (errorMessage === null) {
		// Check if there is a selected package.
		// If it is a stand-alone package, and a new option is added,
		//  uncheck the selected package,
		//  and remove the yellow highlights.
		// If the selected option is an add-on option,
		//  the selected package and its options stay selected and highlighted,
		//  and currently selected options in the same group stay selected.
		// There should only be one option selected in the same group
		//  unless the others are add-on options.
		// When there is no selected package,
		//  but all selected options belong to a package,
		//  automatically select that package.

		if (!selectedOption.isAddOn) {
			isCurrentPackageCollectionModified = true; // Check if  newly added/removed option is not an add-on.
		}

		optionGroups.forEach(g => {
			g.options.forEach(o => {
				if (g.groupName === selectedOption.groupName) {
					// options within the group
					if (o.optionId === selectedOption.optionId) {
						o.isChecked = isChecked; // Check/uncheck selected option.
						o.selectedQuantity = isChecked ? 1 : 0;
                        o.state = selectedOption.state;                        
					} else if (isChecked && selectedOption.isExclusive) {
						o.isChecked = false; // Everything else is unchecked.
						o.selectedQuantity = 0;
					}
				}

				if (isCurrentPackageCollectionModified) {
					if (currentPackage && o.optionId === currentPackage.optionId) {
						o.isChecked = false; // Uncheck the package.
						o.selectedQuantity = 0;
					}
					o.isCurrentPackageOption = false; // Remove all highlights.
				}

				currentSelectedOptions = updateOptionList(
					currentSelectedOptions,
					o
				);
			});
		});
	}

	return {
		groups: optionGroups,
		selected: currentSelectedOptions,
		isCurrentPackageCollectionModified,
		warningMessage,
		errorMessage
	};
};

export const updateQuantityOption = (
	groups: IOptionGroup[],
	currentPackage: IOption | null,
	currentlySelectedOptions: IOption[],
	isCurrentPackageCollectionModified: boolean,
	selectedOption: IOption,
	quantity: number,
	rules: IOptionRule[]
) => {
	const optionGroups = [...groups];
	let currentSelectedOptions = [...currentlySelectedOptions];

	// Check if there is an error.
	let warningMessage = null;
	let errorMessage = null;

	if (quantity > 0) {
		const ruleMessage = checkRules(selectedOption.optionId, rules);
		if (ruleMessage?.type === 1) { // Warning -> Can proceed with option selection
			warningMessage = {
				title: ruleMessage.title,
				body: ruleMessage.body
			};
		}
		else if (ruleMessage?.type === 2) { // Error -> Option cannot be selected.
			errorMessage = {
				title: ruleMessage.title,
				body: ruleMessage.body
			};
		}
	}

	if (errorMessage === null) {
		// Check if there is a selected package.
		// If it is a stand-alone package, and a new option is added,
		//  uncheck the selected package,
		//  and remove the yellow highlights.
		// If the selected option is an add-on option,
		//  the selected package and its options stay selected and highlighted,
		//  and currently selected options in the same group stay selected.
		// There should only be one option selected in the same group
		//  unless the others are add-on options.
		// When there is no selected package,
		//  but all selected options belong to a package,
		//  automatically select that package.

		isCurrentPackageCollectionModified = !selectedOption.isAddOn; // Check if package is currently selected and the newly added/removed option is not an add-on.

		optionGroups.forEach(g => {
			g.options.forEach(o => {
				if (g.groupName === selectedOption.groupName) {
					// options within the group
					if (o.optionId === selectedOption.optionId) {
						o.selectedQuantity = quantity; // Set the textbox.
						o.isChecked = quantity > 0;
					} else if (selectedOption.isExclusive) {
						o.selectedQuantity = 0; // Everything else is 0.
						o.isChecked = false;
						o.isCurrentPackageOption = false;
					}
				}

				if (isCurrentPackageCollectionModified) {
					if (currentPackage && o.optionId === currentPackage.optionId) {
						o.isChecked = false; // Uncheck the package.
						o.selectedQuantity = 0;
					}
					o.isCurrentPackageOption = false; // Remove all highlights.
				}

				currentSelectedOptions = updateOptionList(currentSelectedOptions, o);
			});
		});
	}

	return {
		groups: optionGroups,
		selected: currentSelectedOptions,
		isCurrentPackageCollectionModified,
		warningMessage,
		errorMessage
	};
};

export const getOptionById = (
	optionGroups: IOptionGroup[],
	optionId: number
) => {
	const groups = [...optionGroups];

	for (let g = 0; g < groups.length; g += 1) {
		for (let o = 0; o < groups[g].options.length; o += 1) {
			if (groups[g].options[o].optionId === optionId) {
				return groups[g].options[o];
			}
		}
	}

	return null;
};

export const setVariantOptions = (
	optionGroups: IOptionGroup[],
	optionId: number, 
	variant: IOptionConfigurationVariant
) => {
	const groups = [...optionGroups];

	groups.forEach(g => {
		g.options.forEach(o => {
			if (o.optionId === optionId) {
				o.configurationSets[0].variants.forEach(v => {
					v.isSelected = v.index === variant.index;
				});
			}
		});
	});

	const variantOptions = [];

	// check selected option
	const option = getOptionById(groups, optionId);
	if (option) {
		variantOptions.push({
			option,
			isChecked: true,
			state: option.state
		});
	}

	// select/deselect other options
	variant.options.forEach(o => {
		const variantOption = getOptionById(groups, o.optionId);
		if (variantOption) {
			if (o.state === 1 || o.state === 3 || o.state === 5) {
				variantOptions.push({
					option: variantOption,
					isChecked: true,
                    state: o.state
				});
			} else {
				variantOptions.push({
					option: variantOption,
					isChecked: false,
                    state: o.state
				});
			}
		}
	});

	return {
		groups,
		variantOptions
	};
};

export const getAvailableOptions = async (
	divisionId: number,
    class9Id: number,
    selectedOptions: IEquipmentOption[],
    excludedOptionIds: string | null
) => {	
    let getOptionsUrl = `${Config.API_URL}/equipment/${class9Id}/options?divisionId=${divisionId}`;

    if (excludedOptionIds) {
        getOptionsUrl += `&exclude=${excludedOptionIds}`;
    }

	if (StorageFacade.quote?.PriceBookId) {
		getOptionsUrl += `&priceBookId=${StorageFacade.quote?.PriceBookId}`;
	}

	const optionsResponse = await fetch(getOptionsUrl);

	const optionsJsonData = await optionsResponse.json();

	const groupOptions = (options: IOption[]) => {
		return options.reduce((groups: any, option) => {
			(groups[option.groupName] = groups[option.groupName] || []) // eslint-disable-line no-param-reassign
				.push(option);
			return groups;
		}, {});
	};

	const groupedData = groupOptions(optionsJsonData);

	const selected = [] as IOption[];

	let optionGroups: IOptionGroup[] = Object.keys(
		groupedData
	).map(key => {
		const keyOptions: IOption[] = groupedData[key];

		const keyHasEmptyOption: boolean =
			keyOptions.length === 1 && keyOptions[0].name.trim() === "";

		keyOptions.forEach(o => {
			let selectedQuantity = 1;

			const selectedOptionIndex = selectedOptions.findIndex(so => so.id === o.optionId);
			const isPreviouslySelected = selectedOptionIndex > -1;

			if (isPreviouslySelected) {
				selected.push(o);
				selectedQuantity = selectedOptions[selectedOptionIndex].quantity;
                o.isRequired = o.isRequired || selectedOptions[selectedOptionIndex].isRequired;
                o.state = selectedOptions[selectedOptionIndex].state;
			}

			o.name = keyHasEmptyOption ? o.groupName : o.name;
			o.isBold = keyHasEmptyOption;
			o.isChecked =
				o.isGroupDefault || o.isRequired || isPreviouslySelected;
			o.isCurrentPackageOption = o.isPackage && o.isChecked;
			o.selectedQuantity = o.isChecked
				? selectedQuantity
				: 0;
		});

		let groupSelectedQuantity = 0;
		if (keyOptions.some(o => o.isListOption)) {
			const selectedItemIndex = keyOptions.findIndex(ko => ko.isChecked);
			if (selectedItemIndex > -1) {
				groupSelectedQuantity = keyOptions[selectedItemIndex].selectedQuantity;
			}
		}

		return {
			groupName: key,
			options: keyOptions,
			isVisible: !keyHasEmptyOption,
			isListOption: keyOptions.some(o => o.isListOption), // The group becomes a dropdownlist if at least one option is a list option.
			allowMultiples: keyOptions.some(
				o => o.isListOption && o.allowMultiples
			), // Show a numeric textbox beside the dropdownlist.
			selectedQuantity: groupSelectedQuantity
		};
	});

	const checkedData = checkPackageMatch(optionGroups, selected);
	if (checkedData) {
		optionGroups = checkedData.groups;
	}

	return optionGroups;
};

export const loadOptions = async (
	id: number,
	divisionId: number,
    selectedOptionList: IEquipmentOption[],
    excludedOptionIds: string | null
) => {
	let packageMatch = null;
	let hasError = false;

    let getOptionsUrl = `${Config.API_URL}/equipment/${id}/options?divisionId=${divisionId}`;

    if (excludedOptionIds) {
        getOptionsUrl += `&exclude=${excludedOptionIds}`;
    }

	if (StorageFacade.quote?.PriceBookId) {
		getOptionsUrl += `&priceBookId=${StorageFacade.quote?.PriceBookId}`;
	}

	const optionsResponse = await fetch(getOptionsUrl);

	const optionsJsonData = await optionsResponse.json();

	const groupOptions = (options: IOption[]) => {
		return options.reduce((groups: any, option) => {
			(groups[option.groupName] = groups[option.groupName] || []) // eslint-disable-line no-param-reassign
				.push(option);
			return groups;
		}, {});
	};

	const groupedData = groupOptions(optionsJsonData);

	let selected = [] as IOption[];

	let optionGroups: IOptionGroup[] = Object.keys(
		groupedData
	).map(key => {
		const keyOptions: IOption[] = groupedData[key];

		const keyHasEmptyOption: boolean =
			keyOptions.length === 1 && keyOptions[0].name.trim() === "";

		keyOptions.forEach(o => {
			let selectedQuantity = 1;

			const selectedOptionIndex = selectedOptionList.findIndex(so => so.id === o.optionId);
			const isPreviouslySelected = selectedOptionIndex > -1;

			if (isPreviouslySelected) {
				selected.push(o);
				selectedQuantity = selectedOptionList[selectedOptionIndex].quantity;
                o.isRequired = o.isRequired || selectedOptionList[selectedOptionIndex].isRequired;
                o.state = selectedOptionList[selectedOptionIndex].state;
			}

			o.name = keyHasEmptyOption ? o.groupName : o.name;
			o.isBold = keyHasEmptyOption;
			o.isChecked = 
				o.isGroupDefault || o.isRequired || isPreviouslySelected;								
			o.isCurrentPackageOption = o.isPackage && o.isChecked;
			o.hasConfigurationSet =
				o.configurationSets && o.configurationSets.length > 0;
			o.selectedQuantity = o.isChecked 
				? selectedQuantity
				: 0;

			if (o.isPackage && o.isGroupDefault) {
				packageMatch = o;
			}
		});
		
		let groupSelectedQuantity = 0;
		if (keyOptions.some(o => o.isListOption && o.allowMultiples)) {
			const selectedItemIndex = keyOptions.findIndex(ko => ko.isChecked);
			if (selectedItemIndex > -1) {
				groupSelectedQuantity = keyOptions[selectedItemIndex].selectedQuantity;
			}
		}

		return {
			groupName: key,
			options: keyOptions,
			isVisible: !keyHasEmptyOption,
			isListOption: keyOptions.some(o => o.isListOption), // The group becomes a dropdownlist if at least one option is a list option.
			allowMultiples: keyOptions.some(
				o => o.isListOption && o.allowMultiples
			), // Show a numeric textbox beside the dropdownlist.
			selectedQuantity: groupSelectedQuantity
		};
	});
	
	const checkedData = checkPackageMatch(optionGroups, selected);
	if (checkedData) {
		optionGroups = checkedData.groups;
		selected = checkedData.selected;

		if (checkedData.packageMatch) {
			packageMatch = checkedData.packageMatch;
		}
	}

	if (selected.length > 0) {
		const validatedData = validateSelectedConfigurations(optionGroups, selected);
		if (validatedData) {
			optionGroups = validatedData.groups;
			selected = validatedData.selected;
			hasError = validatedData.selectionHasError;
		}
	}

	const getRulesUrl = `${Config.API_URL}/equipment/${id}/optionselectionrules?divisionId=${divisionId}`;

	const optionRulesResponse = await fetch(getRulesUrl);

	const selectionRules = await optionRulesResponse.json();

	return {
		optionGroups,
		packageMatch,
		selected,
		selectionRules,
		hasError
	};
};

export const selectPackage = (
	optionGroups: IOptionGroup[],
	selectedOption: IOption
) => {
	let hasError = false;

	// When a package is selected,
	//  check all included options,
	//  and uncheck the rest,
	//  except for required/disabled options
	//  as well as add-on options.

	let groups = [...optionGroups];
	let selected = [] as IOption[];	

	const includedOptions =
		selectedOption.packageOptions &&
		selectedOption.packageOptions.filter(
			o => o.state === 1 || o.state === 3 || o.state === 5
		);

	groups.forEach(g => {
		g.options.forEach(o => {
			o.isChecked =
				(includedOptions &&
					includedOptions.map(po => po.optionId).indexOf(o.optionId) > -1) ||
				o.optionId === selectedOption.optionId ||
				o.isRequired ||
				(o.isAddOn && o.isChecked);

			o.selectedQuantity = o.isChecked ? 1 : 0;

			o.isCurrentPackageOption =
				(includedOptions &&
					includedOptions.map(po => po.optionId).indexOf(o.optionId) > -1) ||
				o.optionId === selectedOption.optionId;

			selected = updateOptionList(selected, o);
		});
	});

	const validatedData = validateSelectedConfigurations(groups, selected);
	if (validatedData) {
		groups = validatedData.groups;
		selected = validatedData.selected;
		hasError = validatedData.selectionHasError;
	}

	return {
		selectedOption,
		groups,
		selected,
		hasError
	};
};

export const unselectPackage = (
	optionGroups: IOptionGroup[],
	selectedOption: IOption
) => {
	let hasError = false;

	// When a package is unselected,
	//  unselect all included options

	let groups = [...optionGroups];
	let selected = [] as IOption[];

	const includedOptions =
		selectedOption.packageOptions &&
		selectedOption.packageOptions.filter(
			o => o.state === 1 || o.state === 3 || o.state === 5
		);

	groups.forEach(g => {
		g.options.forEach(o => {
			if (
				(includedOptions &&
					includedOptions.map(po => po.optionId).indexOf(o.optionId) > -1) ||
				o.optionId === selectedOption.optionId
			) {
				o.isChecked = false;
				o.selectedQuantity = 0;
				o.isCurrentPackageOption = false;
			}

			selected = updateOptionList(selected, o);
		});
	});

	const validatedData = validateSelectedConfigurations(groups, selected);
	if (validatedData) {
		groups = validatedData.groups;
		selected = validatedData.selected;
		hasError = validatedData.selectionHasError;
	}

	return {
		groups,
		selected,
		hasError
	};
};
