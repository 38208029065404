/* eslint-disable no-underscore-dangle */
import * as React from "react";

import ConfigurationService from "../../services/ConfigurationService";
import IEquipmentOption from "../../models/interfaces/IEquipmentOption";
import IEquipmentState from "../../models/interfaces/IEquipmentState";
import IEquipmentUnitAttributes from "../../models/interfaces/IEquipmentUnitAttributes";
import IEquipmentUnitItem from "../../models/interfaces/IEquipmentUnitItem";
import IOptionGroup from "../../models/interfaces/IOptionGroup";

import useLoadingContext from "../../hooks/useLoadingContext";
import useTranslationContext from "../../hooks/useTranslationContext";

import * as OptionUtils from "../EquipmentOptions/EquipmentOptions.utils";

const table = {
	border: "1px solid black"
} as React.CSSProperties;

interface ModelOptionsProps {
	sectionId: string;
	divisionId: number;
	jobName: string;
	customerName: string;
	myName: string;
	equipmentInfo: IEquipmentState;
	isCondensingUnit: boolean;
	selectedUnitItem: IEquipmentUnitItem | null;
    selectedOptionList: IEquipmentOption[];
    excludedOptionIds: string | null;
	selectedUnitAttributes: IEquipmentUnitAttributes | null;
}

const ModelOptions: React.FunctionComponent<ModelOptionsProps> = (props: ModelOptionsProps) => {

	const [availableOptions, setAvailableOptions] = React.useState([] as IOptionGroup[]);

	const { showLoading, hideLoading } = useLoadingContext();
	const { translations } = useTranslationContext();

	React.useEffect(()=>{
		const loadData = async () => {
			if (props.selectedUnitItem && props.selectedUnitItem.class9ID) {
				try {
					showLoading();

					const options = await OptionUtils.getAvailableOptions(
						props.divisionId,
						props.selectedUnitItem.class9ID,
                        props.selectedOptionList,
                        props.excludedOptionIds);
					setAvailableOptions(options);

					hideLoading();
				}
				catch (error: any) {
					console.log(`An error was encountered while loading the options. ${error.message ?? ""}`, error);
					hideLoading();
				}
			}
		};

		loadData();
	},[
		props.divisionId,
		props.selectedUnitItem,
		props.selectedOptionList
	]);

	return (
		<div id={props.sectionId} style={{width:"1024px"}}>
			<table
				className="table is-bordered is-narrow is-fullwidth no-margin-table"
				style={table}
			>
				<tbody>
					<tr>
						<td style={{ textAlign: "center", fontWeight: "bold" }} className="two-columns">
							<table className="table is-borderless" style={{ width: "100%", border: "0" }}>
								<tbody>
									<tr>
										<td>
											<img
												src={ConfigurationService.getDivisionLogo(props.divisionId)}
												alt={ConfigurationService.getDivisionLogoAltString(props.divisionId)}
												style={{ width: "160px", marginTop: "10px" }}
											/>
										</td>
										<td>
											<div style={{fontSize: "13px", fontWeight:"bold"}}>NATIONAL REFERIGERATION AND</div>
											<div style={{fontSize: "13px", fontWeight:"bold"}}>AIR CONDITIONING CANADA CORP.</div>
											<div style={{fontSize: "11px", fontWeight:"normal"}}>
												159 ROY BLVD. PO BOX 2020
											</div>
											<div style={{fontSize: "11px", fontWeight:"normal"}}>
												BRANTFORD ON
											</div>
											<div style={{fontSize: "11px", fontWeight:"normal"}}>
												CANADA N3T 5Y6
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
						<td style={{ textAlign: "center", fontWeight: "bold" }} className="two-columns">
							<div style={{ marginTop: "10px"}}>
								<div style={{fontSize: "21px", marginTop: "5px"}}>
									{props.selectedUnitItem?.partNumber}
								</div>
								<div style={{fontSize: "14px"}}>
									{props.selectedUnitItem?.description}
									<br />
									{props.isCondensingUnit ? props.equipmentInfo.equipmentType : "Evaporator"}
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<table className="table is-narrow is-fullwidth no-margin-table" style={table}>
				<tbody>
					<tr>
						<td style={{ textAlign: "right", borderRight: "0"}}>PURCHASER:</td>
						<td style={{ borderLeft: "0", borderRight: "0", textAlign: "left"}}><b>{props.customerName}</b></td>
						<td style={{ textAlign: "right", borderRight: "0", borderLeft: "0"}}>SUBMITTED BY:</td>
						<td style={{ borderLeft: "0", textAlign: "left"}}><b>{props.myName}</b></td>
					</tr>
					<tr>
						<td style={{ textAlign: "right", borderRight: "0"}}>PROJECT:</td>
						<td style={{ borderLeft: "0", borderRight: "0", textAlign: "left"}}><b>{props.jobName}</b></td>
						<td style={{ textAlign: "right", borderRight: "0", borderLeft: "0"}}>DATE:</td>
						<td style={{ borderLeft: "0", textAlign: "left"}}><b>{new Date().toLocaleDateString ()}</b></td>
					</tr>
					<tr>
						<td style={{ textAlign: "right", borderRight: "0"}}>ORDER #:</td>
						<td style={{ borderLeft: "0", borderRight: "0", textAlign: "left"}}><b>-A00</b></td>
						<td style={{ textAlign: "right", borderRight: "0", borderLeft: "0"}}>ITEM #:</td>
						<td style={{ borderLeft: "0", textAlign: "left"}}><b>0</b></td>
					</tr>
					<tr>
						<td style={{ textAlign: "right", borderRight: "0"}}>QUOTE #:</td>
						<td style={{ borderLeft: "0", borderRight: "0", textAlign: "left"}}>&nbsp;</td>
						<td style={{ textAlign: "right", borderRight: "0", borderLeft: "0"}}>ID #:</td>
						<td style={{ borderLeft: "0", textAlign: "left"}}>{props.selectedUnitAttributes?.taggingId}</td>
					</tr>
					<tr>
						<td style={{ textAlign: "right", borderRight: "0"}}>PURCHASER&apos;S PO#:</td>
						<td style={{ borderLeft: "0", borderRight: "0", textAlign: "left"}}>&nbsp;</td>
						<td style={{ textAlign: "right", borderRight: "0", borderLeft: "0"}}>TAGGING:</td>
						<td style={{ borderLeft: "0", textAlign: "left"}}>{props.selectedUnitAttributes?.tagging}</td>
					</tr>
				</tbody>
			</table>
			{
				availableOptions && (
					<table className="table is-narrow is-fullwidth no-margin-table" style={table}>					
						<tbody>	
							<tr>
								<td className="header2">
										{translations.sELECTEDOPTIONDESCRIPTIONS}
								</td>
							</tr>	
							<tr>
								<td className="option-details-columns" style={{borderLeft: "0", borderTop: "0", paddingBottom: "3px"}}>
									<ul style={{listStyleType: "none"}}>
										{ 
											availableOptions.map(optionGroup => {
												return optionGroup.options.map(option => {
													if (option.isChecked) {
														return (
															<li key={option.optionId} style={{fontSize:"13px", lineHeight:"17px"}}>
																<b>
																	<u>
																		{optionGroup.groupName}
																		{optionGroup.groupName === option.name ? "" : (`, ${option.name}`)}
																	</u>
																</b>
																<p>{option.description}</p>
															</li>
														);
													}
													return null;
												});
											})
										}
									</ul>								
								</td>	
							</tr>
							<tr>
								<td colSpan={2}>
									{translations.approvalOfThisDrawingSignifiesThatTheEquipmentIsAcceptableUnderTheProvisionOfTheJobSpecifications_AnyChangeMadeHereonByAnyPersonWhomsoeverSubjectToAcceptanceByNATIONALREFRIGERATIONAtItsHomeOffice_}
								</td>
							</tr>
						</tbody>
					</table>
				)
			}	
		</div>
	);
};

export default ModelOptions;
