import * as React from "react";

import Checkbox from "../helpers/Checkbox";
import DropDownList from "../helpers/DropDownList";
import FormField from "../helpers/FormField";
import Measurement from "../helpers/Measurement";

import { dictionary } from "../../types/dictionary";

import MeasurementType from "../../enums/MeasurementType";

import UnitOfMeasure from "../../models/helpers/UnitOfMeasure";

import IFormState from "../../models/interfaces/IFormState";
import ISystem from "../../models/interfaces/ISystem";

import useTranslationContext from "../../hooks/useTranslationContext";

const equipmentTypes: dictionary<string> = [
	{ key: "Condensing Units-Air", value: "Air Cooled Condensing Unit" },
	{ key: "Condensing Units-Wtr", value: "Water Cooled Condensing Unit" },
	{ key: "Compressor Unit", value: "Compressor" }
];

export const refrigerants: dictionary<string> = [
	{ key: "4", value: "R404A" },
	{ key: "13", value: "R407A" },
	{ key: "9", value: "R407C" },
	{ key: "14", value: "R448A" },
	{ key: "5", value: "R507" },
	{ key: "15", value: "R449A" },
	{ key: "1", value: "R134a" },
	{ key: "2", value: "R22" },
	{ key: "10", value: "R410A" },
    { key: "16", value: "CO2" }
];

const siteLineFrequencies: dictionary<string> = [
	{ key: "60", value: "60 Hz" },
	{ key: "50", value: "50 Hz" }
];

interface EquipmentFiltersProps {
    isEquipmentOnly: boolean;
    requireAwef: boolean;
    formState: IFormState<ISystem>;
    isCUAmbientTempVisible: boolean;
    isCondensingTempVisible: boolean;
    onDropDownChange: any;
    onCheckboxChange: any;
    onMeasurementChange: any;
    onValidateSystemField: any;
    onValidateEquipmentField: any;
}

const EquipmentFilters: React.FunctionComponent<EquipmentFiltersProps> = (
    props: EquipmentFiltersProps
) => {

    const { translations } = useTranslationContext();

    if (!props.formState.actualValues.equipmentSelection) {
        return null;
    }

    return (
        <div id="equipment-filters">
            <div className="columns parameters">
                <div className="column">
                    <Checkbox 
                        name="requireAwef"
                        label="Show AWEF-compliant equipment only"
                        value="1"
                        isChecked={props.requireAwef}
                        onChange={props.onCheckboxChange}
                    />
                </div>
            </div>
            <div className="columns parameters">
                <div className="column is-one-third">
                    <FormField label={translations.equipmentType}>
                        <DropDownList
                            name="equipmentType"
                            className="width200"
                            value={props.formState.actualValues.equipmentSelection.equipmentType ?? equipmentTypes[0].value}
                            dataSource={equipmentTypes}
                            onChange={props.onDropDownChange}
                        />
                    </FormField>
                </div>
                <div className="column">
                    <FormField label={translations.refrigerant}>
                        <DropDownList
                            name="refrigerant"
                            value={props.formState.actualValues.equipmentSelection.refrigerant?.toString() ?? refrigerants[0].value}
                            dataSource={refrigerants}
                            onChange={props.onDropDownChange}
                        />
                    </FormField>
                </div>
                <div className="column">
                    <FormField label={translations.siteLineFrequency}>
                        <DropDownList
                            name="siteLineFrequency"
                            value={props.formState.actualValues.equipmentSelection.siteLineFrequency?.toString() ?? siteLineFrequencies[0].value}
                            dataSource={siteLineFrequencies}
                            onChange={props.onDropDownChange}
                        />
                    </FormField>
                </div>
            </div>
            <div className="columns parameters">
                <div className="column is-one-third">
                    <FormField label={`${translations.interiorTemperature} (${UnitOfMeasure.getTemperatureUnitLabel()})`}>
                        <Measurement
                            name="interiorTemperature"
                            defaultValue={props.formState.defaultValues.interiorTemperature}
                            actualValue={props.formState.actualValues.interiorTemperature}
                            onChange={props.onMeasurementChange}
                            type={MeasurementType.Temperature}
                            decimals={1}
                        />
                    </FormField>
                    {!props.isEquipmentOnly && (
                        <FormField label={`${translations.safetyFactor} (${UnitOfMeasure.getPercentUnitLabel()})`}>
                            <Measurement
                                name="safetyFactor"
                                defaultValue={props.formState.defaultValues.safetyFactor}
                                actualValue={props.formState.actualValues.safetyFactor}
                                onChange={props.onMeasurementChange}
                                type={MeasurementType.PositiveDecimal}
                                decimals={2}
                            />
                        </FormField>
                    )}
                    {!props.isEquipmentOnly && (
                        <FormField label={`${translations.systemRunTime} (${translations.hours})`}>
                            <Measurement
                                name="runTimeHours"
                                defaultValue={props.formState.defaultValues.runTimeHours}
                                actualValue={props.formState.actualValues.runTimeHours}
                                onChange={props.onMeasurementChange}
                                decimals={2}
                                type={MeasurementType.PositiveDecimal}
                                onValidate={props.onValidateSystemField}
                            />
                        </FormField>
                    )}
                    {props.isEquipmentOnly && (
                        <FormField label={`${translations.designTD} (${UnitOfMeasure.getTemperatureUnitLabel()})`}>
                            <Measurement
                                name="designTd"
                                defaultValue={props.formState.defaultValues.equipmentSelection.designTd}
                                actualValue={props.formState.actualValues.equipmentSelection.designTd}
                                onChange={props.onMeasurementChange}
                                type={MeasurementType.TemperatureRelative}
                                decimals={1}
                            />
                        </FormField>
                    )}
                </div>
                <div className="column">
                    {!props.isEquipmentOnly && (
                        <FormField label={`${translations.totalHourlyLoad} (${UnitOfMeasure.getRatingUnitLabel()})`}>
                            <Measurement
                                name="totalHourlyLoad"
                                defaultValue={props.formState.defaultValues.totalHourlyLoad}
                                actualValue={props.formState.actualValues.totalHourlyLoad}
                                onChange={props.onMeasurementChange}
                                type={MeasurementType.Rating}
                                decimals={0}
                            />
                        </FormField>
                    )}
                    {!props.isEquipmentOnly && (
                        <FormField label={`${translations.hourlyLoadRequired} (${UnitOfMeasure.getRatingUnitLabel()})`}>
                            <Measurement
                                name="totalLoadRequired"
                                defaultValue={props.formState.defaultValues.totalLoadRequired}
                                actualValue={props.formState.actualValues.totalLoadRequired}
                                onChange={props.onMeasurementChange}
                                type={MeasurementType.Rating}
                                decimals={0}
                            />
                        </FormField>
                    )}
                    <FormField label={`${translations.capacityRequired} (${UnitOfMeasure.getRatingUnitLabel()})`}>
                        <Measurement
                            name="capacityRequired"
                            defaultValue={props.formState.defaultValues.capacityRequired}
                            actualValue={props.formState.actualValues.capacityRequired}
                            onChange={props.onMeasurementChange}
                            type={MeasurementType.Rating}
                            decimals={0}
                        />
                    </FormField>
                    {props.isEquipmentOnly && (
                        <FormField label={`${translations.suctionLineLoss} (${UnitOfMeasure.getTemperatureUnitLabel()})`}>
                            <Measurement
                                name="suctionLineLoss"
                                defaultValue={props.formState.defaultValues.equipmentSelection.suctionLineLoss}
                                actualValue={props.formState.actualValues.equipmentSelection.suctionLineLoss}
                                onChange={props.onMeasurementChange}
                                type={MeasurementType.TemperatureRelative}
                                decimals={1}
                                onValidate={props.onValidateEquipmentField}
                            />
                        </FormField>
                    )}
                </div>
                <div className="column">
                    {props.isCUAmbientTempVisible && (
                        <FormField label={`${translations.condensingUnitAmbientTemperature} (${UnitOfMeasure.getTemperatureUnitLabel()})`}>
                            <Measurement
                                name="condensingUnitAmbientTemperature"
                                defaultValue={props.formState.defaultValues.equipmentSelection.condensingUnitAmbientTemperature}
                                actualValue={props.formState.actualValues.equipmentSelection.condensingUnitAmbientTemperature}
                                onChange={props.onMeasurementChange}
                                type={MeasurementType.PositiveTemperature}
                                decimals={1}
                            />
                        </FormField>
                    )}
                    {props.isCondensingTempVisible && (
                        <FormField label={`${translations.condensingTemperature} (${UnitOfMeasure.getTemperatureUnitLabel()})`}>
                            <Measurement
                                name="condensingTemperature"
                                defaultValue={props.formState.defaultValues.equipmentSelection.condensingTemperature}
                                actualValue={props.formState.actualValues.equipmentSelection.condensingTemperature}
                                onChange={props.onMeasurementChange}
                                type={MeasurementType.PositiveTemperature}
                                decimals={1}
                            />
                        </FormField>
                    )}
                    {!props.isEquipmentOnly && (
                        <FormField label={`${translations.designTD} (${UnitOfMeasure.getTemperatureUnitLabel()})`}>
                            <Measurement
                                name="designTd"
                                defaultValue={props.formState.defaultValues.equipmentSelection.designTd}
                                actualValue={props.formState.actualValues.equipmentSelection.designTd}
                                onChange={props.onMeasurementChange}
                                type={MeasurementType.TemperatureRelative}
                                decimals={1}
                            />
                        </FormField>
                    )}
                    {!props.isEquipmentOnly && (
                        <FormField label={`${translations.suctionLineLoss} (${UnitOfMeasure.getTemperatureUnitLabel()})`}>
                            <Measurement
                                name="suctionLineLoss"
                                defaultValue={props.formState.defaultValues.equipmentSelection.suctionLineLoss}
                                actualValue={props.formState.actualValues.equipmentSelection.suctionLineLoss}
                                onChange={props.onMeasurementChange}
                                type={MeasurementType.TemperatureRelative}
                                decimals={1}
                                onValidate={props.onValidateEquipmentField}
                            />
                        </FormField>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EquipmentFilters;