import * as React from "react";
import IMessage from "../models/interfaces/IMessage";

const ErrorContext = React.createContext({
    errorMessages: [] as IMessage[],
    showError: (message: IMessage) => {},
    showErrorList: (messages: IMessage[], reset: boolean) => {},
    hideError: () => {}
});

export default ErrorContext;