import * as React from "react";
// @ts-ignore
import { Modal as BulmaModal } from "react-bulma-components";

import "./Modal.css";

export interface ModalProps {
	show: boolean;
	title: string;
	body: React.ReactNode;
    footer?: React.ReactNode;
    width?: number;
	onCancelClick: any;
}

const Modal: React.FunctionComponent<ModalProps> = ({
	show,
	title,
	body,
    footer,
    width,
	onCancelClick
}: ModalProps) => {

    const modalCardWidthStyle = {
        width: width ? `${width}px` : ""
    };

    return (
		<div>
            <BulmaModal 
                show={show}
                onClose={onCancelClick}
            >
                <div className="modal is-active">
                    <div className="modal-background" />
                    <div className="modal-card" style={modalCardWidthStyle}>
                        <header className="modal-card-head">
                            <p className="modal-card-title no-flex-shrink">
                                {title}
                            </p>
                            <button
                                type="button"
                                name="close"
                                className="delete"
                                aria-label="close"
                                onClick={onCancelClick}
                            />
                        </header>
                        <section className="modal-card-body">
                            {body}
                        </section>
                        {footer && (
                            <footer className="modal-card-foot buttons is-right">
                                {footer}
                            </footer>
                        )}
                    </div>
                </div>
            </BulmaModal>
		</div>
	);
};

export default Modal;