/* eslint-disable no-underscore-dangle */
import * as React from "react";

import { getNumericValue, measure } from "../../types/measure";

import UnitOfMeasure from "../../models/helpers/UnitOfMeasure";

import ConfigurationService from "../../services/ConfigurationService";
import CertifiedPrintService from "../../services/CertifiedPrintService";
import StorageFacade from "../../services/StorageFacade";

import IEquipmentOption from "../../models/interfaces/IEquipmentOption";
import IEquipmentState from "../../models/interfaces/IEquipmentState";
import IEquipmentUnitAttributes from "../../models/interfaces/IEquipmentUnitAttributes";
import IEquipmentUnitItem from "../../models/interfaces/IEquipmentUnitItem";

import useLoadingContext from "../../hooks/useLoadingContext";
import useTranslationContext from "../../hooks/useTranslationContext";

import { refrigerants } from "../EquipmentFilters/EquipmentFilters";

import "./Print.css";

const table = {
	border: "1px solid black"
} as React.CSSProperties;

interface ModelDrawingProps {	
	sectionId: string;
	divisionId: number;
	jobName: string;
	customerName: string;
	myName: string;
	interiorTemperature: measure;
	equipmentInfo: IEquipmentState;
	isCondensingUnit: boolean;
	selectedUnitItem: IEquipmentUnitItem | null;
	selectedOptionList: IEquipmentOption[];
    selectedUnitAttributes: IEquipmentUnitAttributes | null;
    isPrintSingleEquipment: boolean;
    capacityHasError: boolean;
    quoteFormInput: any;
    itemNumber: number | null;
    isForACCD: boolean;
}

const ModelDrawing: React.FunctionComponent<ModelDrawingProps> = (props: ModelDrawingProps) => {	

	const [cp, setCP] = React.useState({} as any);
	
	const [lblLiquid, setLblLquid] = React.useState("");
	const [lblHeaterCompressor, setLblHeaterCompressor] = React.useState("");
	const [lblAirFlowRating, setLblAirFlowRating] = React.useState("");
	const [lblAirFlowRatingUnit, setLblAirFlowRatingUnit] = React.useState("");
	const [lblLRA, setLblLRA] = React.useState("");
    const [lblRLAAmps, setLblRLAAmps] = React.useState("");
    const [lblRefs, setLblRefs] = React.useState("" as string | undefined);

	const [approvalImages, setApprovalImages] = React.useState([] as Array<any>);

	const { showLoading, hideLoading } = useLoadingContext();
	const { translations } = useTranslationContext();

	const [circuitRows, setCircuitRows] = React.useState([] as Array<any>);

	const getDimensionFraction = (imperialValue: number): string => {
		const value = UnitOfMeasure.getSmallerDimensionDisplayValue(imperialValue);
		if (value !== undefined) {
			if (StorageFacade.UsesMetricSystem) {
				return `${UnitOfMeasure.getDisplayValue(+value, 0)} ${UnitOfMeasure.getSmallerDimensionUnitLabel()}` ?? "";
			}

			const fraction = UnitOfMeasure.formatFraction(+value);
			if (fraction.trim().length > 0) {
				return `${fraction} ${UnitOfMeasure.getSmallerDimensionUnitLabel()}`;
			}
		}
		return "";
	};

	const getWeightLabel = (imperialValue: number): string => {
		if (imperialValue === undefined) {
			return "";
		}
		return `${UnitOfMeasure.getWeightDisplayValue(imperialValue, 0)} ${UnitOfMeasure.getWeightUnitLabel()}`;
	};

	function getLiquidLabel(class0Id:number):string{
		if (class0Id === 8 || class0Id === 1) {
			return translations.dISTRIBUTOR;
		}
		return translations.lIQUID;
	}

	function getLblHeaterCompressor(class0Id:number):string {
		if (class0Id === 1 || class0Id === 4 ||
			class0Id === 5 || class0Id === 6 ||
			class0Id === 7 || class0Id === 8
		) {
			return translations.hEATERS;
		}
		return translations.cOMPRESSOR;
	}

	function getLblAirFlowRating(class0Id:number):string {
		if (class0Id === 1 || class0Id === 4 ||
			class0Id === 6 ||
			class0Id === 7 || class0Id === 8
		) {
			return translations.aIRFLOW;
		}
		return translations.rATING;
	}

	function getLblAirFlowRatingUnit(class0Id:number):string {
		if (class0Id === 1 || class0Id === 4 ||
			class0Id === 6 ||
			class0Id === 7 || class0Id === 8
		) {
			return UnitOfMeasure.getAirFlowUnitLabel();
		}
		return "";
	}

	function getLblAMPSRla(class0Id:number):string {
		if (class0Id === 2 || class0Id === 3) {
			return "RLA";
		}
		return "AMPS";
	}

	function getLblLRA(class0Id:number):string {
		if (class0Id === 2 || class0Id === 3) {
			return "LRA";
		}
		return "";
	}	

	function getTemp1Label(class0Id: number): string {
		if (class0Id === 2 || class0Id === 3 || class0Id === 9) {
			return translations.suctionTemp;
		}
		if (class0Id === 1) {
			return translations.evapTemperature;
		}
		if (class0Id === 4) {
			return translations.condensingTemp;
		}
		return "";
	}

	function getTemp2Label(class0Id: number): string {
		if (class0Id === 2 || class0Id === 4 ) {
			return translations.ambientTemp;
		}
		if (class0Id === 1) { 
			return translations.boxTemperature; 
		}
        if (class0Id === 3 || class0Id === 9) {
			return translations.condensingTemp;
		}
		//if (class0Id === 9) {
		//	return "Fluid Temp.";            
		//}
		return "";
    }

    function getTemp1Value(class0Id: number, equipmentInfo: IEquipmentState): number {        

        if (class0Id === 2 || class0Id === 3 || class0Id === 9) {
            return (equipmentInfo?.interiorTemperature ?? 0) - (equipmentInfo?.designTd ?? 0) - (equipmentInfo?.suctionLineLoss??0);
        }
        if (class0Id === 1) {
            return (equipmentInfo?.interiorTemperature ?? 0) - (equipmentInfo?.designTd ?? 0);
        }
        if (class0Id === 4) {
            return (equipmentInfo?.condensingTemperature ?? 0);
        }
        return 0;
    }

    function getTemp2Value(class0Id: number, equipmentInfo: IEquipmentState): number {
        if (class0Id === 2 || class0Id === 4) {
            return (equipmentInfo?.condensingUnitAmbientTemperature ?? 0);
        }
        if (class0Id === 1) {
            return (equipmentInfo?.interiorTemperature ?? 0);
        }
        if (class0Id === 3 || class0Id === 9) {
            return (equipmentInfo?.condensingTemperature ?? 0);
        }
        
        return 0;
    }

	function loadApprovalImg(approvalImgNumber: number) {
		approvalImages.length = 0;
		for (let i = 1; i < 10; i += 1) {
			// eslint-disable-next-line no-bitwise
			if ((approvalImgNumber & (2 ** (i-1))) > 0) {
				if (i >= 1) {
					approvalImages.push(ConfigurationService.getApprovalImageByNum(i, false));
				}
			}
		}
		setApprovalImages([]);
		setApprovalImages(approvalImages);
	}

    React.useEffect(() => {

        let selectedRefrigerant: any;

        if (props.equipmentInfo && props.equipmentInfo.refrigerant) {
            setLblRefs(refrigerants.find(a => a.key === props.equipmentInfo.refrigerant?.toString())?.value);
            selectedRefrigerant = props.equipmentInfo.refrigerant;
        }
        else if (props.selectedUnitItem && props.selectedUnitItem.refrigerants) {
            // do something
            let refs: string = "";
            props.selectedUnitItem.refrigerants.forEach(ref => {
                if (refs === "")
                    refs = refs + ref.RefrigerantName;
                else
                    refs = refs + ", " + ref.RefrigerantName;
            });
            setLblRefs(refs);
        }  
        else {
            let refs: string = "";
            refrigerants.forEach(item => {
                if (refs === "")
                    refs = refs + item.value;
                else
                    refs = refs + ", " + item.value;

                selectedRefrigerant = item.key;
            });
            setLblRefs(refs);
        }

        props.equipmentInfo.refrigerant = selectedRefrigerant;
                
        if (props.selectedUnitItem) {
			//showLoading();        
			try {
			CertifiedPrintService.getCertifiedPrintValues(
				props.equipmentInfo,
				props.selectedUnitItem,
				props.selectedOptionList,
				data => {                
					setCP(data);
					setLblLquid(getLiquidLabel(props.selectedUnitItem?.class0ID??2));
					setLblHeaterCompressor(getLblHeaterCompressor(props.selectedUnitItem?.class0ID??2));
					setLblAirFlowRating(getLblAirFlowRating(props.selectedUnitItem?.class0ID??2));
					setLblAirFlowRatingUnit(getLblAirFlowRatingUnit(props.selectedUnitItem?.class0ID??2));
					setLblRLAAmps(getLblAMPSRla(props.selectedUnitItem?.class0ID??2));
					setLblLRA(getLblLRA(props.selectedUnitItem?.class0ID??2));
					loadApprovalImg(data.Approvals);

					const _lblLRA = getLblLRA(props.selectedUnitItem?.class0ID??2);

					const circuits: Array<any> = [];
					if (data.VoltageInfo && data.VoltageInfo.length > 0) {
						let cur: number = 1;
						data.VoltageInfo.forEach((va: any, i: number) => {
							const key = i;
							if (va.Circuit_No && va.Circuit_No >= cur){
								
								if (_lblLRA !== "")
									circuits.push(
										<tr key={key}>
											<td>{va.Fan_Count}</td>
											<td>
												{va.Fan_Power}
												{cp.Fan_Units}
											</td>
											<td>{UnitOfMeasure.numberRound(va.Fan_FLA, 1)}</td>
											<td>{va.Circuit_Type}</td>
											<td>{va.Circuit_Count}</td>
											<td>{UnitOfMeasure.numberRound(va.RLA, 1)}</td>
											<td>{UnitOfMeasure.numberRound(va.LRA, 1)}</td>
											<td>{UnitOfMeasure.numberRound(va.FLA, 1)}</td>
											<td>{UnitOfMeasure.numberRound(va.KW, 1)}</td>
											<td>{UnitOfMeasure.numberRound(va.MCA, 1)}</td>
											<td>{va.MOP}</td>							
										</tr>);
								else
									circuits.push(
										<tr key={key}>
											<td>{va.Fan_Count}</td>
											<td>
												{UnitOfMeasure.numberRound(va.Fan_Power, 2)}
                                                &nbsp;
												{va.Fan_Units}
											</td>
											<td>{UnitOfMeasure.numberRound(va.Fan_FLA, 1)}</td>
											<td>{va.Circuit_Type}</td>
											<td>{va.Circuit_Count}</td>
											<td>{UnitOfMeasure.numberRound(va.RLA, 1)}</td>									
											<td>{UnitOfMeasure.numberRound(va.FLA, 1)}</td>
											<td>{UnitOfMeasure.numberRound(va.KW, 1)}</td>
											<td>{UnitOfMeasure.numberRound(va.MCA, 1)}</td>
											<td>{va.MOP}</td>							
										</tr>);

							}
							
							cur += 1;
						});						
					}
					setCircuitRows([]);
					setCircuitRows(circuits);

					//hideLoading();
				});
			}
			catch (error: any) {
				console.log(`An error was encountered while loading data. ${error.message ?? ""}`, error);
				//hideLoading();
			}
		}
	},[
		props.equipmentInfo,
		props.selectedUnitItem, 
		props.selectedOptionList
	]);

	const getSound = () => {
		if (cp.Noise === undefined || Number.isNaN(cp.Noise)) {
			if (cp.ratingNoise === undefined || Number.isNaN(cp.ratingNoise)) {
				return "";
			}
			return cp.ratingNoise;
		}
		return cp.Noise;
	}

	return (
		<div id={props.sectionId} style={{width:"1070px"}} >
			<table
				className="table is-bordered is-narrow is-fullwidth no-margin-table"
				style={table}
			>
				<tbody>
					<tr>
						<td style={{ textAlign: "center", fontWeight: "bold" }} className="two-columns">								
							<table className="table is-borderless" style={{ width: "100%", border: "0" }}>
								<tbody>
									<tr>
										<td>
											<img
												src={ConfigurationService.getDivisionLogo(props.divisionId)}
												alt={ConfigurationService.getDivisionLogoAltString(props.divisionId)}
												style={{ width: "160px", marginTop: "10px" }}
											/>
										</td>
										<td>
											<div style={{fontSize: "13px", fontWeight:"bold"}}>NATIONAL REFERIGERATION AND</div> 
											<div style={{fontSize: "13px", fontWeight:"bold"}}>AIR CONDITIONING CANADA CORP.</div>
											<div style={{fontSize: "11px", fontWeight:"normal"}}>
												159 ROY BLVD. PO BOX 2020
											</div>
											<div style={{fontSize: "11px", fontWeight:"normal"}}>
												BRANTFORD ON
											</div>
											<div style={{fontSize: "11px", fontWeight:"normal"}}>
												CANADA N3T 5Y6
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
						<td style={{ textAlign: "center", fontWeight: "bold" }} className="two-columns" colSpan={2}>
							<div style={{ marginTop: "10px"}}>
								<div style={{fontSize: "21px", marginTop: "5px"}}>{props.selectedUnitItem?.partNumber}</div>
								<div style={{fontSize: "14px"}}>
									{props.selectedUnitItem?.description}
									<br />                                    
                                    {props.isPrintSingleEquipment === false ? (props.isCondensingUnit && props.equipmentInfo ? props.equipmentInfo.equipmentType : "Evaporator") : ""}
                                    {props.isPrintSingleEquipment ? props.selectedUnitItem?.type : ""}
								</div>
							</div>
						</td>
					</tr>							
				</tbody>
			</table>
			<table className="table is-narrow is-fullwidth no-margin-table" style={table}>
				<tbody>
					<tr>
						<td style={{ textAlign: "right", borderRight: "0"}}>PURCHASER:</td>
						<td style={{ borderLeft: "0", borderRight: "0", textAlign: "left"}}><b>{props.isPrintSingleEquipment?props.quoteFormInput?.Purchaser:props.customerName}</b></td>
						<td style={{ textAlign: "right", borderRight: "0", borderLeft: "0"}}>SUBMITTED BY:</td>
						<td style={{ borderLeft: "0", textAlign: "left"}}><b>{props.myName}</b></td>
					</tr>
					<tr>
						<td style={{ textAlign: "right", borderRight: "0"}}>PROJECT:</td>
						<td style={{ borderLeft: "0", borderRight: "0", textAlign: "left"}}><b>{props.isPrintSingleEquipment ? props.quoteFormInput?.Project:props.jobName}</b></td>
						<td style={{ textAlign: "right", borderRight: "0", borderLeft: "0"}}>DATE:</td>
						<td style={{ borderLeft: "0", textAlign: "left"}}><b>{new Date().toLocaleDateString ()}</b></td>
					</tr>
					<tr>
						<td style={{ textAlign: "right", borderRight: "0"}}>ORDER #:</td>
						<td style={{ borderLeft: "0", borderRight: "0", textAlign: "left"}}><b>-A00</b></td>
						<td style={{ textAlign: "right", borderRight: "0", borderLeft: "0"}}>ITEM #:</td>
                        <td style={{ borderLeft: "0", textAlign: "left" }}><b>{props.itemNumber}</b></td>
					</tr>
					<tr>
						<td style={{ textAlign: "right", borderRight: "0"}}>QUOTE #:</td>
						<td style={{ borderLeft: "0", borderRight: "0", textAlign: "left"}}>{props.isPrintSingleEquipment ? props.quoteFormInput?.QuoteNumber : ""}&nbsp;</td>
						<td style={{ textAlign: "right", borderRight: "0", borderLeft: "0"}}>ID #:</td>
						<td style={{ borderLeft: "0", textAlign: "left"}}>{props.selectedUnitAttributes?.taggingId}</td>
					</tr>
					<tr>
						<td style={{ textAlign: "right", borderRight: "0"}}>PURCHASER&apos;S PO#:</td>
						<td style={{ borderLeft: "0", borderRight: "0", textAlign: "left"}}>{props.isPrintSingleEquipment ? props.quoteFormInput?.PO : ""}&nbsp;</td>
						<td style={{ textAlign: "right", borderRight: "0", borderLeft: "0"}}>TAGGING:</td>
						<td style={{ borderLeft: "0", textAlign: "left"}}>{props.selectedUnitAttributes?.tagging}</td>
					</tr>
				</tbody>
			</table>
			<table className="table is-narrow-cente is-fullwidth no-margin-table drawing" style={table}>
				<tbody>
					<tr>
						<td>
							<div>
								<b>{translations?.dimensionsShownAreForStandardUnitLessOptions_?.toUpperCase()}</b>
							</div>
							<div>
								<img src={ConfigurationService.getModelDrawings(cp.Drawing_File)} style={{ width: "600px", height: "auto"}} alt="" />
							</div>
						</td>
					</tr>
				</tbody>
			</table>

			<table className="table is-narrow-cente is-fullwidth no-margin-table" style={table}>
				<tbody>
					<tr>
						<td colSpan={4}><b>{translations?.dimensions?.toUpperCase()}</b></td>
						<td colSpan={4}><b>{`FIELD ${translations?.cONNECTIONS?.toUpperCase()}`}</b></td>
						<td colSpan={2}><b>{translations.oTHER}</b></td>
					</tr>
					<tr>
						<td>{translations.dIMENSIONA}</td>							
						{
							(cp.Dim_A?<td>{getDimensionFraction(cp.Dim_A)}</td>:<td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>)
						}
						<td>{translations.dIMENSIONE}</td>
						{
							(cp.Dim_E?<td>{getDimensionFraction(cp.Dim_E)}</td>:<td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>)
						}
						<td>{lblLiquid}</td>							
						{
							(cp.Con_Liquid?<td>{getDimensionFraction(cp.Con_Liquid)}</td>:<td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>)
						}
						<td>{translations.pANLOOP}</td>								
						{
							(cp.Con_PanLoop?<td>{getDimensionFraction(cp.Con_PanLoop)}</td>:<td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>)
						}
						<td>{translations.sHIPPINGWEIGHT}</td>																	
						{
							(cp.Ship_Weight?<td>{getWeightLabel(cp.Ship_Weight)}</td>:<td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>)
						}
					</tr>
					<tr>
						<td>{translations.dIMENSIONB}</td>
						<td>{getDimensionFraction(cp.Dim_B)}</td>
						<td>{translations.dIMENSIONF}</td>
						<td>{getDimensionFraction(cp.Dim_F)}</td>

						<td>{translations.sUCTION}</td>
						<td>{getDimensionFraction(cp.Con_Suction)}</td>
						<td>{translations.hOTGASSIDEPORT}</td>
						<td>{getDimensionFraction(cp.Con_HGSide)}</td>

						<td>{`${translations?.refrigerant?.toUpperCase()} ${translations.cHARGE}`}</td>
						<td>{getWeightLabel(cp.Charge)}</td>
					</tr>
					<tr>
						<td>{translations.dIMENSIONC}</td>
						<td>{getDimensionFraction(cp.Dim_C)}</td>
						<td>{translations.dIMENSIONG}</td>
						<td>{getDimensionFraction(cp.Dim_G)}</td>

						<td>{translations.dRAIN}</td>
						<td>{getDimensionFraction(cp.Con_Drain)}</td>
						<td>{translations.hOTGASINLET}</td>
						<td>{getDimensionFraction(cp.HGInlet)}</td>

						<td>{`RECEIVER ${translations.cAPACITY}`}</td>
						<td>{getWeightLabel(cp.Receiver_Capacity)}</td>
					</tr>
					<tr>
						<td>{translations.dIMENSIOND}</td>
						<td>{getDimensionFraction(cp.Dim_D)}</td>
						<td>{translations.dIMENSIONH}</td>
						<td>{getDimensionFraction(cp.Dim_H)}</td>

						<td>{translations.wATER}</td>
						<td>{getDimensionFraction(cp.Con_Water)}</td>
						<td>{translations.hOTGASOUTLET}</td>
						<td>{getDimensionFraction(cp.HGOutlet)}</td>

						<td>AWEF COMPLIANT</td>
						<td>{props.selectedUnitItem?.awefCompliant ? "YES" : "NO"}</td>
					</tr>		
					<tr>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{translations.dISCHARGE}</td>
						<td>{getDimensionFraction(cp.Con_Discharge)}</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>			
					<tr>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>																
				</tbody>
			</table>											

				
			<table className="table is-narrow-cente is-fullwidth no-margin-table" style={table}>					
				<tbody>	
					<tr>
						<td className="header2">{translations.voltage}</td>
						<td className="header2">{props.isForACCD === false? translations.sYSTEMREFRIGERANT : ''}</td>
						<td className="header2">{lblAirFlowRating}</td>
						<td className="header2">{ getTemp1Label(props.selectedUnitItem?.class0ID??2)}</td>
						<td className="header2">{ getTemp2Label(props.selectedUnitItem?.class0ID??2)}</td>
						<td className="header2">{props.isForACCD === false? translations.cAPACITY : ''}</td>
					</tr>
					<tr>
						<td>{props.selectedUnitItem?.text}</td>
                        <td>{props.isForACCD === false?lblRefs:''}</td>
						<td>{cp.rating ? `${UnitOfMeasure.getAirFlowDisplayValue(cp.rating, 1)} ${lblAirFlowRatingUnit}` : ""}</td>
                        <td>{getTemp1Value(props.selectedUnitItem?.class0ID ?? 2, props.equipmentInfo) !== (undefined || null || 0) ? `${UnitOfMeasure.getTemperatureDisplayValue(getTemp1Value(props.selectedUnitItem?.class0ID ?? 2, props.equipmentInfo), 1)} ${UnitOfMeasure.getTemperatureUnitLabel()}`: ''}</td>
                        <td>{getTemp2Value(props.selectedUnitItem?.class0ID ?? 2, props.equipmentInfo) !== (undefined || null || 0) ? `${UnitOfMeasure.getTemperatureDisplayValue(getTemp2Value(props.selectedUnitItem?.class0ID ?? 2, props.equipmentInfo), 1)} ${UnitOfMeasure.getTemperatureUnitLabel()}`:''}</td>
                        <td>{props.isForACCD === false ? (!props.capacityHasError && props.selectedUnitItem?.capacityPerUnit?`${UnitOfMeasure.getRatingDisplayValue(props.selectedUnitItem?.capacityPerUnit, 0)} ${UnitOfMeasure.getRatingUnitLabel()}`:'ERROR') : ''}</td>
					</tr>
				</tbody>
			</table>	
			<table className="table is-narrow-cente is-fullwidth no-margin-table" style={table}>					
				<tbody>	
					<tr>
						<td className="header2" colSpan={3}>{translations.fans}</td>
						<td className="header2" colSpan={lblLRA === "" ? 3 : 4}>{lblHeaterCompressor}</td>
						<td className="header2" colSpan={4}>{translations.cIRCUITTOTAL}</td>							
					</tr>
					<tr>
						<td className="header2">{translations.qTY}</td>
						<td className="header2">{translations.power}</td>
						<td className="header2">{translations.fLA_FAN}</td>
						<td className="header2">{translations.type}</td>
						<td className="header2">{translations.qTY}</td>
						<td className="header2">{lblRLAAmps}</td>
						{ lblLRA !== "" && <td className="header2">{lblLRA}</td> }
						<td className="header2">{translations.aMPS}</td>
						<td className="header2">{translations.wATTS}</td>
						<td className="header2">MCA†</td>
						<td className="header2">MOP‡</td>
					</tr>
					{circuitRows}
				</tbody>
			</table>	
			<table style={{border: 0, width: "100%"}} className="completely-no-margin-table-no-padding">
				<tbody>
					<tr>
						<td style={{ width: "90%" }}>
							<table className="table is-narrow-cente is-fullwidth no-margin-table" style={table}>
								<tbody>
									<tr>
										<td className="header2">{lblLiquid}</td>
										<td>{getDimensionFraction(cp.Con_Liquid)}</td>
										<td className="header2">{translations.sOUND}</td>
										<td>{getSound()}</td>
										<td className="header2">REC CAPACITY</td>
										<td>{getWeightLabel(cp.Receiver_Capacity)}</td>
										<td rowSpan={2} style={{verticalAlign:"middle", width:"90px"}}><b>{`${translations.aPPROVALS}:`}</b></td>
										{														
											approvalImages.length > 0 && 
											approvalImages.map((x: any, i: number) => {
												const key = i;
												return <td key={key} rowSpan={2} style={{verticalAlign:"middle"}}><img src={x} height="35px" width="35px" alt="" /></td>;
											})
										}											
									</tr>
									<tr>
										<td className="header2">{translations.sUCTION}</td>
										<td>{getDimensionFraction(cp.Con_Suction)}</td>
										<td className="header2">{translations.wEIGHT}</td>
										<td>{getWeightLabel(cp.Ship_Weight)}</td>
										<td className="header2">REF CHARGE</td>
										<td>{getWeightLabel(cp.Charge)}</td>											
									</tr>										
								</tbody>				
							</table>
							<table className="table is-narrow is-fullwidth no-margin-table" style={table}>
								<tbody>
									<tr>
										<td colSpan={7} className="aligh-left">
											<table style={{border:"none"}}>
												<tbody>
													<tr>
														<td style={{border:"none"}}>
															<u>{translations.notes}</u>
															:
														</td>
														<td style={{fontWeight:"bold", border:"none"}}>
															{
																cp.notes && 
																cp.notes.map((x: any, i: number) => {
																	const key = i;
																	return (
																		<p key={key}>{x}</p>
																	);
																})
															}
															{props.selectedUnitAttributes?.notes}
														</td>
													</tr>
												</tbody>
											</table>
										</td>
									</tr>
								</tbody>
							</table>
						</td>							
					</tr>						
				</tbody>
			</table>
			<table className="table is-narrow is-fullwidth no-margin-table" style={table}>
				<tbody>
					<tr>
						<td style={{width:"70%", lineHeight:"30px"}} className="aligh-left"><b>{translations.aPPROVEDBY_}</b></td>
						<td style={{width:"30%", lineHeight:"30px"}} className="aligh-left"><b>{translations.dATE_}</b></td>
					</tr>
					<tr>
						<td colSpan={2} style={{fontSize: "12px", lineHeight: "15px"}}>
							{translations.nATIONALREFRIGERATIONWillFurnishEquipmentInAccordanceWithThisDrawingAndSpecifications_AndSubjectToItsPublishedWarranty_ApprovalOfThisDrawingSignifiesThatTheEquipmentIsAcceptableUnderTheProvisionOfTheJobSpecifications_AnyChangeMadeHereonByAnyPersonWhomsoeverSubjectToAcceptanceByNATIONALREFRIGERATIONAtItsHomeOffice_}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default ModelDrawing;
