import * as React from "react";

import ISystem from "../models/interfaces/ISystem";

import ConfigurationService from "../services/ConfigurationService";
import UnitOfMeasure from "../models/helpers/UnitOfMeasure";
import EquipmentByIdRequest from "../services/requests/EquipmentByIdRequest";
import LoadCalculationService from "../services/LoadCalculationService";
import ModelSelectionService from "../services/ModelSelectionService";
import StorageFacade from "../services/StorageFacade";

import useApplicationContext from "./useApplicationContext";
import useBoxContext from "./useBoxContext";
import useBoxMaterialsContext from "./useBoxMaterialsContext";
import useLoadingContext from "./useLoadingContext";
import useSystemState from "./useSystemState";
import IMaterial from "../models/interfaces/IMaterial";

const useProjectSystems = () => {

    const { showLoading, hideLoading } = useLoadingContext();
    const { getSystemValues, recalculateTotalLoads } = useSystemState();

    const [currentSystem, setCurrentSystem] = React.useState(null as ISystem | null);
    const [tempProjectSystems, setTempProjectSystems] = React.useState([] as ISystem[]);
    const [projectSystems, setProjectSystems] = React.useState(null as ISystem[] | null);
    const [baseBoxMaterials, setBaseBoxMaterials] = React.useState([] as IMaterial[]);

    const { isAppDataLoaded, appData } = useApplicationContext();
    const { loadBox, boxData, isBoxDataLoaded } = useBoxContext();
    const { isMaterialListLoaded, boxMaterials, updateBoxMaterials } = useBoxMaterialsContext();

    const getProjectSystemByIndex = (index: number) => {
        const storeSystems: ISystem[] | null = StorageFacade.project?.Systems;

        if (storeSystems && storeSystems.length > index) {
            const system = storeSystems[index];
            setCurrentSystem(system); // this will trigger useEffect function with "currentSystem" dependency below

            // add any composite constructions from storage to the box materials collection
            if (system.wallLoad?.constructions
                && system.wallLoad.constructions.length > 0
            ) {
                const materials = [
                    ...baseBoxMaterials,
                    ...system.wallLoad.constructions
                ];

                updateBoxMaterials(materials);
            }

            const systemBoxId = system.boxApplicationId;
            if (systemBoxId) {
                loadBox(systemBoxId); // this will trigger useEffect function with "boxId" and "isBoxDataLoaded" dependencies below
            }
        }
    };

    React.useEffect(() => {
        if (StorageFacade.project?.Systems.length === tempProjectSystems.length) {
            // use a tempProjectSystems state to temporarily store project systems that are loaded one by one
            // set the state projectSystems only when all project systems have been loaded into tempProjectSystems
            // so that it renders the PrintMultiple component one time only
            setProjectSystems(tempProjectSystems);
        }
    }, [tempProjectSystems]);

    React.useEffect(() => {
        if (isAppDataLoaded
            && isMaterialListLoaded
        ) {
            // store box materials collection from the web service to a state variable
            // so that we can reset the collection for each system before we update it with composite construction
            setBaseBoxMaterials(boxMaterials);

            // load the first system
            // when that is done, load the next
            // until all systems have been loaded
            getProjectSystemByIndex(0);
        }
    }, [
        isAppDataLoaded,
        isMaterialListLoaded
    ]);

    React.useEffect(() => {
        const getProjectSystem = async (
            storeSystem: ISystem
        ) => {

            const boxApplicationData = await ConfigurationService.getApplicationByIdAsync(storeSystem.boxApplicationId ?? 0);
            boxApplicationData.isBoxFreezer = boxApplicationData.isFreezer === "Y";

            // get default equipment parameters
            const defaultCondensingUnitAmbientTemperature = appData.EQUIPMENT?.CondensorAmbient?.value;
            const defaultSuctionLineLoss = appData.EQUIPMENT?.LineLoss?.Default?.value;
            const defaultDesignTd = (boxApplicationData.rH_TD < 0)
                ? 0 - boxApplicationData.rH_TD
                : UnitOfMeasure.TDatRH(boxApplicationData.rH_TD);

            if (storeSystem.equipmentSelection.selectedCondenserUnit?.class9ID) {
                // get condensing equipment details
                const request: EquipmentByIdRequest = {
                    divisionId: +StorageFacade.divisionId,
                    interiorTemp: storeSystem.equipmentSelection.interiorTemperature,
                    refrigerantFluidId: storeSystem.equipmentSelection.refrigerant ?? 0,
                    ambientTemp: storeSystem.equipmentSelection.condensingUnitAmbientTemperature ?? defaultCondensingUnitAmbientTemperature,
                    suctionLineLoss: storeSystem.equipmentSelection.suctionLineLoss ?? defaultSuctionLineLoss,
                    td: storeSystem.equipmentSelection.designTd ?? defaultDesignTd,
                    selectedOptions: storeSystem.equipmentSelection.selectedCondenserOptionList.map(o => o.id).join(",")
                };
            
                const equipmentDetails = await ModelSelectionService.getEquipmentByIdAsync(
                    storeSystem.equipmentSelection.selectedCondenserUnit.class9ID,
                    request);
            
                const equipmentUnit = {
                    ...storeSystem.equipmentSelection.selectedCondenserUnit,
                    capacityPerUnit: equipmentDetails.capacityPerUnit,
                    description: equipmentDetails.description,
                    features: equipmentDetails.features,
                    requiredOptions: equipmentDetails.requiredOptions,
                    class0ID: equipmentDetails.class0ID,
                    class8ID: equipmentDetails.class8ID,
                    class9ID: equipmentDetails.class9ID,
                    awefCompliant: equipmentDetails.awefCompliant,
                    warnings: [...equipmentDetails.warnings ?? []]
                }

                storeSystem = {
                    ...storeSystem,
                    equipmentSelection: {
                        ...storeSystem.equipmentSelection,
                        selectedCondenserUnit: equipmentUnit
                    }
                };
            }

            if (storeSystem.equipmentSelection.selectedEvaporator?.class9ID) {
                // get evaporator equipment details
                const request: EquipmentByIdRequest = {
                    divisionId: +StorageFacade.divisionId,
                    interiorTemp: storeSystem.equipmentSelection.interiorTemperature,
                    refrigerantFluidId: storeSystem.equipmentSelection.refrigerant ?? 0,
                    ambientTemp: storeSystem.equipmentSelection.condensingUnitAmbientTemperature ?? defaultCondensingUnitAmbientTemperature,
                    suctionLineLoss: storeSystem.equipmentSelection.suctionLineLoss ?? defaultSuctionLineLoss,
                    td: storeSystem.equipmentSelection.designTd ?? defaultDesignTd,
                    selectedOptions: storeSystem.equipmentSelection.selectedEvaporatorOptionList.map(o => o.id).join(",")
                };
            
                const equipmentDetails = await ModelSelectionService.getEquipmentByIdAsync(
                    storeSystem.equipmentSelection.selectedEvaporator.class9ID,
                    request);
            
                const equipmentUnit = {
                    ...storeSystem.equipmentSelection.selectedEvaporator,
                    capacityPerUnit: equipmentDetails.capacityPerUnit,
                    description: equipmentDetails.description,
                    features: equipmentDetails.features,
                    requiredOptions: equipmentDetails.requiredOptions,
                    class0ID: equipmentDetails.class0ID,
                    class8ID: equipmentDetails.class8ID,
                    class9ID: equipmentDetails.class9ID,
                    awefCompliant: equipmentDetails.awefCompliant,
                    warnings: [...equipmentDetails.warnings ?? []]
                }

                storeSystem = {
                    ...storeSystem,
                    equipmentSelection: {
                        ...storeSystem.equipmentSelection,
                        selectedEvaporator: equipmentUnit
                    }
                };
            }

            let systemValues = getSystemValues(boxApplicationData, storeSystem);

            const loads = await LoadCalculationService.fetchLoadsAsync(
                storeSystem.totalMiscLoad,
                storeSystem.totalInfiltrationLoad,
                storeSystem.totalProductLoad,
                systemValues.boxApplicationId ?? 0,
                systemValues.wallLoad.floorArea,
                systemValues.wallLoad.volume,
                systemValues.boxDesign.totalInfiltrationLoad,
                systemValues.exteriorTemperature,
                systemValues.exteriorHumidity,
                systemValues.interiorTemperature,
                systemValues.interiorHumidity,
                storeSystem.miscLoad,
                storeSystem.infiltrationLoad,
                storeSystem.productLoad);

            systemValues.totalMiscLoad = loads.totalMiscLoad;
            systemValues.totalInfiltrationLoad = loads.totalInfiltrationLoad;
            systemValues.totalProductLoad = loads.totalProductLoad;

            systemValues.miscLoad = loads.miscLoadItems;
            systemValues.infiltrationLoad = loads.infiltrationLoadItems;
            systemValues.productLoad = loads.productLoadItems;

            systemValues = recalculateTotalLoads(systemValues);

            return systemValues;
        };

        const loadCurrentProjectSystem = async (currentSystem: ISystem) => {
            showLoading();

            const projectSystem = await getProjectSystem(currentSystem); // query and set all properties of the project system
            setTempProjectSystems([...tempProjectSystems, projectSystem]); // push to the temporary project system state

            getProjectSystemByIndex(tempProjectSystems.length + 1); // get the next system in the store

            hideLoading();
        };

        if (isBoxDataLoaded 
            && currentSystem
            && boxData.id == currentSystem.boxApplicationId
        ) {
            loadCurrentProjectSystem(currentSystem);
        }
    }, [
        isBoxDataLoaded,
        boxData,
        currentSystem
    ]);

    return {
        projectSystems
    };
};

export default useProjectSystems;