class HelperService {
	public static getQueryVariable(variable: string) {
		const query = window.location.search.substring(1);
		// console.log(query); // "app=article&act=news_content&aid=160990"
		const vars = query.split("&");

		for (let i = 0; i < vars.length; i = i + 1) {
			const pair = vars[i].split("=");
			// console.log(pair); // [ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
			if (pair[0] === variable) {
				return pair[1];
			}
		}
		return "";
	}

	public static callAPI(url: string, callback: (data: any) => void) {
		fetch(url)
			.then(response => response.text())
			.then(text => {
				const data = text.length ? JSON.parse(text) : {};
				callback(data);
			})
			.catch(error => {
				console.log(error);
			});
	}

	public static callAPIPost(url: string, dataToPost: any, callback: (data: any) => void) {
		fetch(url, {
            method: "POST",
            body: JSON.stringify(dataToPost),
            headers: {
              "Content-Type": "application/json"
            }
          })
			.then(response => response.text())
			.then(text => {
				const data = text.length ? JSON.parse(text) : {};
				callback(data);
			})
			.catch(error => {
				console.log(error);
			});
    }
	
}

export default HelperService;
